import React, { useEffect, useState, Component, Fragment } from "react";
import { useNavigate } from "react-router-dom";
import { PageLinks } from "../common/Constants";
import Header from "../common/Header";
import {
  getDisplayData,
  getFormattedLocalDateTime,
  getIsLogin,
  numberWithCommas,
} from "../common/Utilities";
import { ServerAPI } from "../common/ServerAPI";
import toast from "react-hot-toast";
import DataTable from "react-data-table-component";
import { APIData } from "../common/DataTypes";
import moment from "moment";

const Dashboard: React.FC = () => {
  const navigate = useNavigate();
  const [statusArray, setStatusArray] = useState([]);
  const [orderList, setOrderList] = useState<APIData.CustomerOrderList[]>([]);
  // const [bondSelectOption, setBondSelectOption] = useState(0);

  useEffect(() => {
    getIsLogin().then((status) => {
      console.log(status);
      if (!status) {
        navigate(PageLinks.LOGIN);
      }
    });
    loadOrderList();
    ServerAPI.viewInsights()
      .then((res) => {
        try {
          setStatusArray(res.data[0]);
        } catch (error) {
          toast.error(res.error);
        }
      })
      .finally(() => {});
  }, []);
  const loadOrderList = () => {
    // setShowPreloader(true);
    ServerAPI.getCustomerOrderList(10, "", "", "", "", "")
      .then((res) => {
        try {
          setOrderList(res.data[0]?.orders);
          console.log(
            "🚀 ~ file: CustomerOrderList.tsx:171 ~ .then ~ res:",
            res
          );
          //   setTotalCount(res.total_records);
        } catch (error) {
          toast.error(res.error);
        }
      })
      .finally(() => {
        // setShowPreloader(false);
      });
  };
  let columns: any = [
    // {
    //   name: "Sr.No",
    //   width: "90px",
    //   cell: (row: any, index: any) => startSerialNo + index + 1, //RDT provides index by default
    // },

    {
      name: "Order No",
      selector: (row: any) => (
        <b className={`ord-title`}>{getDisplayData(row.order_number)}</b>
      ),
      sortable: true,
      width: "200px",
    },
    {
      name: "Order Date & Time",
      selector: (row: any) => row.order_datetime,
      sortable: true,
      width: "200px",
      format: (row: any) =>
        moment(row.order_datetime).format("DD-MM-YYYY,  hh:mm A"),
    },
    {
      name: "Customer Name",
      selector: (row: any) => getDisplayData(row?.personal?.personal_fullname),
      sortable: true,
      width: "250px",
      wrap: true,
    },

    {
      name: "Bond / IPO Name",
      selector: (row: any) => getDisplayData(row?.security?.security_name),
      sortable: true,
      width: "400px",
    },
    {
      name: "ISIN Number",
      selector: (row: any) => getDisplayData(row.security?.security_isin),
      sortable: true,
      width: "200px",
    },
    // {
    //   name: "Coupon Rate (%)",
    //   selector: (row: any) =>
    //     getDisplayData(row.security?.security_coupon_rate),
    //   sortable: true,
    // },
    {
      name: "Maturity Date",
      selector: (row: any) =>
        getFormattedLocalDateTime(row.security?.security_maturity_date),
      sortable: true,
      width: "200px",
      // format: (row: any) =>
      //   moment(row.security?.security_maturity_date).format(
      //     "DD-MM-YYYY,  hh:mm A"
      //   ),
    },
    // {
    //   name: "Price",
    //   selector: (row: any) => numberWithCommas(row.order_price),
    //   sortable: true,
    // },
    {
      name: "Exchange",
      selector: (row: any) => (
        <b className={`${row.exchange?.exchange_name}-color`}>
          {getDisplayData(row.exchange?.exchange_name)}
        </b>
      ),
      sortable: true,
      width: "250px",
    },
    {
      name: "No. Of Quantity",
      selector: (row: any) => getDisplayData(row?.order_quantity),
      sortable: true,
      width: "250px",
    },
    {
      name: "Total Amount",
      selector: (row: any) => numberWithCommas(row?.order_value),
      sortable: true,
      width: "150px",
    },

    {
      name: "Order Status",
      selector: (row: any) => (
        <b
          className={`${
            row?.order_status === "Order Accepted"
              ? "success-capsule"
              : "failed-capsule"
          }`}
        >
          {getDisplayData(row?.order_status)}
        </b>
      ),
      sortable: true,
      width: "200px",
    },
    {
      name: "Action",
      cell: (row: any) => [
        <td className="city-action-rable-row ">
          {/* {JSON.stringify(row?.security?.security_type_id)} */}
          <i
            className="fas fa-eye table-icons pe-1"
            onClick={() => {
              // console.log("hit ", BondTypes.IPO);
              // if (row.order_type === BondTypes.IPO) {
              if (row?.security?.security_type_id === 1) {
                navigate(PageLinks.IPO_ORDER_DETAIL_VIEW, {
                  state: {
                    type: 1,
                    // type: row.order_type,
                    id: row.order_id,
                    path: "customer_book",
                  },
                });
              } else if (row?.security?.security_type_id === 2) {
                // } else if (row.order_type === BondTypes.ADminBond) {
                navigate(PageLinks.BOND_ORDER_DETAIL_VIEW, {
                  state: {
                    type: row.order_type,
                    id: row.order_id,
                    path: "customer_book",
                  },
                });
              } else if (row?.security?.security_type_id === 3) {
                // } else if (row.order_type === BondTypes.SGB) {
                navigate(PageLinks.SGB_ORDER_DETAIL_VIEW, {
                  state: {
                    type: 3,
                    // type: row.order_type,
                    id: row.order_id,
                    path: "customer_book",
                  },
                });
              }
            }}
          ></i>
          {/* {row.order_type != 4 ? (
            <img
              src="../../assets/images/download.svg"
              style={{ width: "15px", margin: "0px 5px", cursor: "pointer" }}
              onClick={() => getDownloadFile(row.order_pdf)}
            ></img>
          ) : (
            ""
          )} */}
        </td>,
      ],
    },
  ];
  const customStyles = {
    headCells: {
      style: {
        backgroundColor: "#A27B1E",
        color: "white",
        fontSize: "14px",
      },
    },
  };
  return (
    <div id="layout-wrapper" className="pace-done" data-layout="horizontal">
      <Header />
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="row mt-5">
              <div className="col-12">
                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                  {/* <h4 className="mb-sm-0 font-size-18">Dashboard</h4> */}

                  {/* <div className="page-title-right">
                                        <ol className="breadcrumb m-0">
                                            <li className="breadcrumb-item"><a href="javascript: void(0);">Trustmony</a></li>
                                            <li className="breadcrumb-item active">Dashboard</li>
                                        </ol>
                                    </div> */}
                </div>
              </div>
            </div>
            <div className="row">
              {statusArray &&
                Object.entries(statusArray).map(([key, value], idx) => {
                  // Dynamic color assignment based on value or key
                  const getStatusColor = (key: any, value: any) => {
                    if (key.includes("failed") || key.includes("rejected")) {
                      return "#E74C3C"; // Red for errors or failures
                    } else if (key.includes("expired")) {
                      return "#F39C12"; // Orange for warnings
                    } else if (
                      key.includes("accepted") ||
                      key.includes("settled")
                    ) {
                      return "#2ECC71"; // Green for success
                    } else if (
                      key.includes("pending") ||
                      key.includes("processing")
                    ) {
                      return "#3498DB"; // Blue for in-progress
                    }
                    return "#8E44AD"; // Default color (purple)
                  };

                  const valueColor = getStatusColor(key, value);

                  return (
                    <div className="col-xl-3 col-md-6" key={idx}>
                      <div
                        className="card card-h-100"
                        style={{
                          borderRadius: "12px",
                          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                          transition:
                            "transform 0.2s ease, box-shadow 0.2s ease",
                          overflow: "hidden",
                          backgroundColor: "#fff", // Neutral background for card
                        }}
                        onMouseEnter={(e) => {
                          e.currentTarget.style.transform = "translateY(-10px)";
                          e.currentTarget.style.boxShadow =
                            "0 8px 16px rgba(0, 0, 0, 0.2)";
                        }}
                        onMouseLeave={(e) => {
                          e.currentTarget.style.transform = "translateY(0)";
                          e.currentTarget.style.boxShadow =
                            "0 4px 8px rgba(0, 0, 0, 0.1)";
                        }}
                      >
                        <div
                          className="card-body"
                          style={{ textAlign: "center" }}
                        >
                          <div className="row align-items-center">
                            <div className="col-12">
                              <span
                                className={`today-status-color-${
                                  idx + 1
                                } mb-3 lh-2 d-block text-truncate`}
                                style={{
                                  fontSize: "16px",
                                  fontWeight: "500",
                                  textTransform: "capitalize",
                                  color: "#555", // Neutral color for the key text
                                }}
                              >
                                {key
                                  .replace(/_/g, " ")
                                  .charAt(0)
                                  .toUpperCase() +
                                  key.replace(/_/g, " ").slice(1)}
                              </span>
                              <h4
                                className="mb-3"
                                style={{
                                  fontSize: "28px",
                                  fontWeight: "bold",
                                  color: valueColor, // Dynamic color for value
                                }}
                              >
                                {value}
                              </h4>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>

            {/* <div className="row">
              <div className="col-xl-5">
                <div className="card card-h-100">
                  <div className="card-body">
                    <div className="d-flex flex-wrap align-items-center mb-4">
                      <h5 className="card-title me-2">Wallet Balance</h5>
                      <div className="ms-auto">
                        <div>
                          <button
                            type="button"
                            className="btn btn-soft-secondary btn-sm"
                          >
                            ALL
                          </button>
                          <button
                            type="button"
                            className="btn btn-soft-primary btn-sm"
                          >
                            1M
                          </button>
                          <button
                            type="button"
                            className="btn btn-soft-secondary btn-sm"
                          >
                            6M
                          </button>
                          <button
                            type="button"
                            className="btn btn-soft-secondary btn-sm active"
                          >
                            1Y
                          </button>
                        </div>
                      </div>
                    </div>

                    <div className="row align-items-center">
                      <div className="col-sm">
                        <div
                          id="wallet-balance"
                          data-colors='["#777aca", "#5156be", "#a8aada"]'
                          className="apex-charts"
                        ></div>
                      </div>
                      <div className="col-sm align-self-center">
                        <div className="mt-4 mt-sm-0">
                          <div>
                            <p className="mb-2">
                              <i className="mdi mdi-circle align-middle font-size-10 me-2 text-success"></i>{" "}
                              Bitcoin
                            </p>
                            <h6>
                              0.4412 BTC ={" "}
                              <span className="text-muted font-size-14 fw-normal">
                                $ 4025.32
                              </span>
                            </h6>
                          </div>

                          <div className="mt-4 pt-2">
                            <p className="mb-2">
                              <i className="mdi mdi-circle align-middle font-size-10 me-2 text-primary"></i>{" "}
                              Ethereum
                            </p>
                            <h6>
                              4.5701 ETH ={" "}
                              <span className="text-muted font-size-14 fw-normal">
                                $ 1123.64
                              </span>
                            </h6>
                          </div>

                          <div className="mt-4 pt-2">
                            <p className="mb-2">
                              <i className="mdi mdi-circle align-middle font-size-10 me-2 text-info"></i>{" "}
                              Litecoin
                            </p>
                            <h6>
                              35.3811 LTC ={" "}
                              <span className="text-muted font-size-14 fw-normal">
                                $ 2263.09
                              </span>
                            </h6>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-7">
                <div className="row">
                  <div className="col-xl-8">
                    <div className="card card-h-100">
                      <div className="card-body">
                        <div className="d-flex flex-wrap align-items-center mb-4">
                          <h5 className="card-title me-2">Invested Overview</h5>
                          <div className="ms-auto">
                            <select className="form-select form-select-sm">
                              <option value="MAY" selected={true}>
                                May
                              </option>
                              <option value="AP">April</option>
                              <option value="MA">March</option>
                              <option value="FE">February</option>
                              <option value="JA">January</option>
                              <option value="DE">December</option>
                            </select>
                          </div>
                        </div>

                        <div className="row align-items-center">
                          <div className="col-sm">
                            <div
                              id="invested-overview"
                              data-colors='["#5156be", "#34c38f"]'
                              className="apex-charts"
                            ></div>
                          </div>
                          <div className="col-sm align-self-center">
                            <div className="mt-4 mt-sm-0">
                              <p className="mb-1">Invested Amount</p>
                              <h4>$ 6134.39</h4>

                              <p className="text-muted mb-4">
                                {" "}
                                + 0.0012.23 ( 0.2 % ){" "}
                                <i className="mdi mdi-arrow-up ms-1 text-success"></i>
                              </p>

                              <div className="row g-0">
                                <div className="col-6">
                                  <div>
                                    <p className="mb-2 text-muted text-uppercase font-size-11">
                                      Income
                                    </p>
                                    <h5 className="fw-medium">$ 2632.46</h5>
                                  </div>
                                </div>
                                <div className="col-6">
                                  <div>
                                    <p className="mb-2 text-muted text-uppercase font-size-11">
                                      Expenses
                                    </p>
                                    <h5 className="fw-medium">-$ 924.38</h5>
                                  </div>
                                </div>
                              </div>

                              <div className="mt-2">
                                <a href="#" className="btn btn-primary btn-sm">
                                  View more{" "}
                                  <i className="mdi mdi-arrow-right ms-1"></i>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-4">
                    <div className="card bg-primary text-white shadow-primary card-h-100">
                      <div className="card-body p-0">
                        <div
                          id="carouselExampleCaptions"
                          className="carousel slide text-center widget-carousel"
                          data-bs-ride="carousel"
                        >
                          <div className="carousel-inner">
                            <div className="carousel-item active">
                              <div className="text-center p-4">
                                <i className="mdi mdi-bitcoin widget-box-1-icon"></i>
                                <div className="avatar-md m-auto">
                                  <span className="avatar-title rounded-circle bg-soft-light text-white font-size-24">
                                    <i className="mdi mdi-currency-btc"></i>
                                  </span>
                                </div>
                                <h4 className="mt-3 lh-base fw-normal text-white">
                                  <b>Bitcoin</b> News
                                </h4>
                                <p className="text-white-50 font-size-13">
                                  {" "}
                                  Bitcoin prices fell sharply amid the global
                                  sell-off in equities. Negative news over the
                                  past week has dampened sentiment for bitcoin.{" "}
                                </p>
                                <button
                                  type="button"
                                  className="btn btn-light btn-sm"
                                >
                                  View details{" "}
                                  <i className="mdi mdi-arrow-right ms-1"></i>
                                </button>
                              </div>
                            </div>
                            <div className="carousel-item">
                              <div className="text-center p-4">
                                <i className="mdi mdi-ethereum widget-box-1-icon"></i>
                                <div className="avatar-md m-auto">
                                  <span className="avatar-title rounded-circle bg-soft-light text-white font-size-24">
                                    <i className="mdi mdi-ethereum"></i>
                                  </span>
                                </div>
                                <h4 className="mt-3 lh-base fw-normal text-white">
                                  <b>ETH</b> News
                                </h4>
                                <p className="text-white-50 font-size-13">
                                  {" "}
                                  Bitcoin prices fell sharply amid the global
                                  sell-off in equities. Negative news over the
                                  past week has dampened sentiment for bitcoin.{" "}
                                </p>
                                <button
                                  type="button"
                                  className="btn btn-light btn-sm"
                                >
                                  View details{" "}
                                  <i className="mdi mdi-arrow-right ms-1"></i>
                                </button>
                              </div>
                            </div>
                            <div className="carousel-item">
                              <div className="text-center p-4">
                                <i className="mdi mdi-litecoin widget-box-1-icon"></i>
                                <div className="avatar-md m-auto">
                                  <span className="avatar-title rounded-circle bg-soft-light text-white font-size-24">
                                    <i className="mdi mdi-litecoin"></i>
                                  </span>
                                </div>
                                <h4 className="mt-3 lh-base fw-normal text-white">
                                  <b>Litecoin</b> News
                                </h4>
                                <p className="text-white-50 font-size-13">
                                  {" "}
                                  Bitcoin prices fell sharply amid the global
                                  sell-off in equities. Negative news over the
                                  past week has dampened sentiment for bitcoin.{" "}
                                </p>
                                <button
                                  type="button"
                                  className="btn btn-light btn-sm"
                                >
                                  View details{" "}
                                  <i className="mdi mdi-arrow-right ms-1"></i>
                                </button>
                              </div>
                            </div>
                          </div>
                          <div className="carousel-indicators carousel-indicators-rounded">
                            <button
                              type="button"
                              data-bs-target="#carouselExampleCaptions"
                              data-bs-slide-to="0"
                              className="active"
                              aria-current="true"
                              aria-label="Slide 1"
                            ></button>
                            <button
                              type="button"
                              data-bs-target="#carouselExampleCaptions"
                              data-bs-slide-to="1"
                              aria-label="Slide 2"
                            ></button>
                            <button
                              type="button"
                              data-bs-target="#carouselExampleCaptions"
                              data-bs-slide-to="2"
                              aria-label="Slide 3"
                            ></button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-xl-8">
                <div className="card">
                  <div className="card-body">
                    <div className="d-flex flex-wrap align-items-center mb-4">
                      <h5 className="card-title me-2">Market Overview</h5>
                      <div className="ms-auto">
                        <div>
                          <button
                            type="button"
                            className="btn btn-soft-primary btn-sm"
                          >
                            ALL
                          </button>
                          <button
                            type="button"
                            className="btn btn-soft-secondary btn-sm"
                          >
                            1M
                          </button>
                          <button
                            type="button"
                            className="btn btn-soft-secondary btn-sm"
                          >
                            6M
                          </button>
                          <button
                            type="button"
                            className="btn btn-soft-secondary btn-sm active"
                          >
                            1Y
                          </button>
                        </div>
                      </div>
                    </div>

                    <div className="row align-items-center">
                      <div className="col-xl-8">
                        <div>
                          <div
                            id="market-overview"
                            data-colors='["#5156be", "#34c38f"]'
                            className="apex-charts"
                          ></div>
                        </div>
                      </div>
                      <div className="col-xl-4">
                        <div className="p-4">
                          <div className="mt-0">
                            <div className="d-flex align-items-center">
                              <div className="avatar-sm m-auto">
                                <span className="avatar-title rounded-circle bg-soft-light text-dark font-size-16">
                                  1
                                </span>
                              </div>
                              <div className="flex-grow-1 ms-3">
                                <span className="font-size-16">
                                  Coinmarketcap
                                </span>
                              </div>

                              <div className="flex-shrink-0">
                                <span className="badge rounded-pill badge-soft-success font-size-12 fw-medium">
                                  +2.5%
                                </span>
                              </div>
                            </div>
                          </div>

                          <div className="mt-3">
                            <div className="d-flex align-items-center">
                              <div className="avatar-sm m-auto">
                                <span className="avatar-title rounded-circle bg-soft-light text-dark font-size-16">
                                  2
                                </span>
                              </div>
                              <div className="flex-grow-1 ms-3">
                                <span className="font-size-16">Binance</span>
                              </div>

                              <div className="flex-shrink-0">
                                <span className="badge rounded-pill badge-soft-success font-size-12 fw-medium">
                                  +8.3%
                                </span>
                              </div>
                            </div>
                          </div>

                          <div className="mt-3">
                            <div className="d-flex align-items-center">
                              <div className="avatar-sm m-auto">
                                <span className="avatar-title rounded-circle bg-soft-light text-dark font-size-16">
                                  3
                                </span>
                              </div>
                              <div className="flex-grow-1 ms-3">
                                <span className="font-size-16">Coinbase</span>
                              </div>

                              <div className="flex-shrink-0">
                                <span className="badge rounded-pill badge-soft-danger font-size-12 fw-medium">
                                  -3.6%
                                </span>
                              </div>
                            </div>
                          </div>

                          <div className="mt-3">
                            <div className="d-flex align-items-center">
                              <div className="avatar-sm m-auto">
                                <span className="avatar-title rounded-circle bg-soft-light text-dark font-size-16">
                                  4
                                </span>
                              </div>
                              <div className="flex-grow-1 ms-3">
                                <span className="font-size-16">Yobit</span>
                              </div>

                              <div className="flex-shrink-0">
                                <span className="badge rounded-pill badge-soft-success font-size-12 fw-medium">
                                  +7.1%
                                </span>
                              </div>
                            </div>
                          </div>

                          <div className="mt-3">
                            <div className="d-flex align-items-center">
                              <div className="avatar-sm m-auto">
                                <span className="avatar-title rounded-circle bg-soft-light text-dark font-size-16">
                                  5
                                </span>
                              </div>
                              <div className="flex-grow-1 ms-3">
                                <span className="font-size-16">Bitfinex</span>
                              </div>

                              <div className="flex-shrink-0">
                                <span className="badge rounded-pill badge-soft-danger font-size-12 fw-medium">
                                  -0.9%
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className="mt-4 pt-2">
                            <a href="" className="btn btn-primary w-100">
                              See All Balances{" "}
                              <i className="mdi mdi-arrow-right ms-1"></i>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-xl-4">
                <div className="card">
                  <div className="card-body">
                    <div className="d-flex flex-wrap align-items-center mb-4">
                      <h5 className="card-title me-2">Sales by Locations</h5>
                      <div className="ms-auto">
                        <div className="dropdown">
                          <a
                            className="dropdown-toggle text-reset"
                            href="#"
                            id="dropdownMenuButton1"
                            data-bs-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                            <span className="text-muted font-size-12">
                              Sort By:
                            </span>{" "}
                            <span className="fw-medium">
                              World<i className="mdi mdi-chevron-down ms-1"></i>
                            </span>
                          </a>

                          <div
                            className="dropdown-menu dropdown-menu-end"
                            aria-labelledby="dropdownMenuButton1"
                          >
                            <a className="dropdown-item" href="#">
                              USA
                            </a>
                            <a className="dropdown-item" href="#">
                              Russia
                            </a>
                            <a className="dropdown-item" href="#">
                              Australia
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div
                      id="sales-by-locations"
                      data-colors='["#5156be"]'
                      style={{ height: "250px" }}
                    ></div>

                    <div className="px-2 py-2">
                      <p className="mb-1">
                        USA <span className="float-end">75%</span>
                      </p>
                      <div className="progress mt-2" style={{ height: "6px" }}>
                        <div
                          className="progress-bar progress-bar-striped bg-primary"
                          role="progressbar"
                          style={{ width: "75%" }}
                          aria-aria-valuemin="0"
                          aria-aria-valuemax="75"
                        ></div>
                      </div>

                      <p className="mt-3 mb-1">
                        Russia <span className="float-end">55%</span>
                      </p>
                      <div className="progress mt-2" style={{ height: "6px" }}>
                        <div
                          className="progress-bar progress-bar-striped bg-primary"
                          role="progressbar"
                          style={{ width: "55%" }}
                          aria-aria-valuemin="0"
                          aria-aria-valuemax="55"
                        ></div>
                      </div>

                      <p className="mt-3 mb-1">
                        Australia <span className="float-end">85%</span>
                      </p>
                      <div className="progress mt-2" style={{ height: "6px" }}>
                        <div
                          className="progress-bar progress-bar-striped bg-primary"
                          role="progressbar"
                          style={{ width: "85%" }}
                          aria-aria-valuemin="0"
                          aria-aria-valuemax="85"
                        ></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}

            <div className="row">
              {/* <div className="col-xl-6">
                <div className="card">
                  <div className="card-header align-items-center d-flex">
                    <h4 className="card-title mb-0 flex-grow-1">Trading</h4>
                    <div className="flex-shrink-0">
                      <ul
                        className="nav nav-tabs-custom card-header-tabs"
                        role="tablist"
                      >
                        <li className="nav-item">
                          <a
                            className="nav-link active"
                            data-bs-toggle="tab"
                            href="#buy-tab"
                            role="tab"
                          >
                            Buy
                          </a>
                        </li>
                        <li className="nav-item">
                          <a
                            className="nav-link"
                            data-bs-toggle="tab"
                            href="#sell-tab"
                            role="tab"
                          >
                            Sell
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div className="card-body">
                    <div className="tab-content">
                      <div
                        className="tab-pane active"
                        id="buy-tab"
                        role="tabpanel"
                      >
                        <div className="float-end ms-2">
                          <h5 className="font-size-14">
                            <i className="bx bx-wallet text-primary font-size-16 align-middle me-1"></i>{" "}
                            <a
                              href="#!"
                              className="text-reset text-decoration-underline"
                            >
                              $4335.23
                            </a>
                          </h5>
                        </div>
                        <h5 className="font-size-14 mb-4">Buy Coins</h5>
                        <div>
                          <div className="form-group mb-3">
                            <label>Payment method :</label>
                            <select className="form-select">
                              <option>Direct Bank Payment</option>
                              <option>Credit / Debit Card</option>
                              <option>Paypal</option>
                              <option>Payoneer</option>
                              <option>Stripe</option>
                            </select>
                          </div>

                          <div>
                            <label>Add Amount :</label>
                            <div className="input-group mb-3">
                              <label className="input-group-text">Amount</label>
                              <select
                                className="form-select"
                                style={{ maxWidth: "90px" }}
                              >
                                <option value="BT" selected>
                                  BTC
                                </option>
                                <option value="ET">ETH</option>
                                <option value="LT">LTC</option>
                              </select>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="0.00121255"
                              />
                            </div>

                            <div className="input-group mb-3">
                              <label className="input-group-text">Price</label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="$58,245"
                              />
                              <label className="input-group-text">$</label>
                            </div>

                            <div className="input-group mb-3">
                              <label className="input-group-text">Total</label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="$36,854.25"
                              />
                            </div>
                          </div>

                          <div className="text-center">
                            <button
                              type="button"
                              className="btn btn-success w-md"
                            >
                              Buy Coin
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="tab-pane" id="sell-tab" role="tabpanel">
                        <div className="float-end ms-2">
                          <h5 className="font-size-14">
                            <i className="bx bx-wallet text-primary font-size-16 align-middle me-1"></i>{" "}
                            <a
                              href="#!"
                              className="text-reset text-decoration-underline"
                            >
                              $4235.23
                            </a>
                          </h5>
                        </div>
                        <h5 className="font-size-14 mb-4">Sell Coins</h5>

                        <div>
                          <div className="form-group mb-3">
                            <label>Wallet ID :</label>
                            <input
                              type="email"
                              className="form-control"
                              placeholder="1cvb254ugxvfcd280ki"
                            />
                          </div>

                          <div>
                            <label>Add Amount :</label>
                            <div className="input-group mb-3">
                              <label className="input-group-text">Amount</label>

                              <select
                                className="form-select"
                                style={{ maxWidth: "90px" }}
                              >
                                <option value="BT" selected={true}>
                                  BTC
                                </option>
                                <option value="ET">ETH</option>
                                <option value="LT">LTC</option>
                              </select>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="0.00121255"
                              />
                            </div>

                            <div className="input-group mb-3">
                              <label className="input-group-text">Price</label>

                              <input
                                type="text"
                                className="form-control"
                                placeholder="$23,754.25"
                              />

                              <label className="input-group-text">$</label>
                            </div>

                            <div className="input-group mb-3">
                              <label className="input-group-text">Total</label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="$6,852.41"
                              />
                            </div>
                          </div>

                          <div className="text-center">
                            <button
                              type="button"
                              className="btn btn-danger w-md"
                            >
                              Sell Coin
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
              <div className="col-xl-12">
                <div className="card">
                  <div className="card-header align-items-center d-flex">
                    <h4 className="card-title mb-0 flex-grow-1">
                      Recent Deals
                    </h4>
                    <div className="flex-shrink-0">
                      <ul
                        className="nav justify-content-end nav-tabs-custom rounded card-header-tabs"
                        role="tablist"
                      >
                        <button
                          className="nav-link active h-10"
                          data-bs-toggle="tab"
                          role="tab"
                          style={{
                            borderRadius: "8px", // Add border-radius for rounded corners
                            border: "1px solid #A27B1E", // Optional: add a border color
                            backgroundColor: "#F9F4E7", // Light background color
                            color: "#A27B1E", // Text color
                            padding: "8px 16px", // Add padding for better spacing
                            fontWeight: "bold", // Make text bold
                            transition: "all 0.3s ease", // Smooth transition for hover effect
                            cursor: "pointer", // Pointer cursor on hover
                          }}
                          onClick={() => {
                            navigate(PageLinks.CUSTOMER_BOOK);
                          }}
                        >
                          View All
                        </button>

                        {/* <li className="nav-item">
                          <a
                            className="nav-link"
                            data-bs-toggle="tab"
                            href="#transactions-buy-tab"
                            role="tab"
                          >
                            Buy
                          </a>
                        </li>
                        <li className="nav-item">
                          <a
                            className="nav-link"
                            data-bs-toggle="tab"
                            href="#transactions-sell-tab"
                            role="tab"
                          >
                            Sell
                          </a>
                        </li> */}
                      </ul>
                    </div>
                  </div>

                  <div className="card-body px-0">
                    <DataTable
                      data={orderList}
                      columns={columns}
                      noHeader
                      defaultSortFieldId={"customer_id"}
                      sortIcon={<i className="fas fa-sort"></i>}
                      defaultSortAsc={true}
                      highlightOnHover
                      dense
                      customStyles={customStyles}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Dashboard;
