import Pagination from "@mui/material/Pagination";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
// import DatePicker from 'react-date-picker';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import DataTable from "react-data-table-component";
import { toast } from "react-hot-toast";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppStateAPI } from "../common/AppStateAPI";
import { BondType, Depository, PageLinks } from "../common/Constants";
import { APIData } from "../common/DataTypes";
import Footer from "../common/Footer";
import Header from "../common/Header";
import { InitialData } from "../common/InitialValue";
import * as Utilities from "../common/Utilities";
import { ServerAPI } from "../common/ServerAPI";
import {
  getDisplayData,
  getFormattedLocalDateTime,
  numberWithCommas,
  getDownloadFileXsl,
} from "../common/Utilities";
import ConfirmationModal from "../modals/Confirmation-modal";
import SimpleModal from "../modals/SimpleModal";

var startSerialNo = 0;
const BondList: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const passing_value = location.state;
  const [preData, setPreData] = useState(passing_value);
  const [bondSelectOption, setBondSelectOption] = useState("all");
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [buttonText, setButtonText] = useState("Upload ISIN");
  const fileInputRef = useRef<HTMLInputElement>(null);

  // const [value, onChange] = useState(new Date());
  const [toData, setToData] = useState<null | Date>();
  const [fromDate, setFromDate] = useState<null | Date>();
  const [open, setOpen] = useState(false);
  const [open_modal, setOpenModal] = useState(false);
  const [bondList, setbondList] = useState<APIData.BondList[]>([]);
  const [totalCount, setTotalCount] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);
  const [user_id, setUserId] = useState();
  const [view_content, setViewContent] = useState();
  const [search_text, setSearchText] = useState("");
  const { setShowPreloader } = useAppStateAPI();
  const searchref = React.createRef();
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const pageCount = Math.ceil(totalCount / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const currPage = currentPage ? currentPage : 1;
  startSerialNo = currPage * 10 - 10;
  let columns: any = [
    {
      name: "Sr.No",
      cell: (row: any, index: any) => startSerialNo + index + 1, //RDT provides index by default
      width: "90px",
    },

    {
      name: "Bond Name",
      selector: (row: any) => getDisplayData(row.security_name),
      sortable: true,
      width: "400px",
      wrap: true,
    },
    {
      name: "Issuer Name",
      selector: (row: any) => getDisplayData(row?.issuer?.security_issuer_name),
      sortable: true,
      width: "400px",
      wrap: true,
    },
    {
      name: "ISIN Number",
      selector: (row: any) => getDisplayData(row.security_isin),
      sortable: true,
      width: "300px",
      wrap: true,
    },

    {
      name: "Face Value",
      selector: (row: any) => numberWithCommas(row.security_face_value),
      sortable: true,
      width: "150px",
      wrap: true,
    },
    {
      name: "Maturity Date",
      selector: (row: any) =>
        row.security_maturity_date == null
          ? "--"
          : Utilities.getFormattedLocalDate(row.security_maturity_date),
      sortable: true,
      width: "200px",
      wrap: true,
    },
    {
      name: "Call Date",
      selector: (row: any) =>
        row.security_call_date == null
          ? "--"
          : Utilities.getFormattedLocalDate(row.security_call_date),
      sortable: true,
      width: "200px",
    },

    {
      name: "Action",
      selector: (row: any) => row.listing,
      cell: (row: any) => [
        <td className="city-action-rable-row ">
          <i
            className="fas fa-eye table-icons pe-1"
            onClick={() =>
              navigate(
                (row.security_type_id === BondType?.IPO
                  ? PageLinks.IPO_DETAIL
                  : "") ||
                  (row.security_type_id === BondType?.ADminBond
                    ? PageLinks.BOND_DETAIL
                    : "") ||
                  (row.security_type_id === BondType?.ExternalAPIBond
                    ? PageLinks.BOND_DETAIL
                    : "") ||
                  (row.security_type_id === BondType?.SGB ? "" : ""),
                {
                  state:
                    row.security_type_id === BondType?.IPO
                      ? row.security_id
                      : row.security_id,
                }
              )
            }
          ></i>
        </td>,
      ],
    },
  ];
  const customStyles = {
    headCells: {
      style: {
        backgroundColor: "#A27B1E",
        color: "white",
        fontWeight: "bold",
      },
    },
  };
  const handlePageChange = (page: any) => {
    setCurrentPage(page);
    setPageNumber(page);
  };
  const resetfilter = () => {
    setFromDate(null);
    setToData(null);
  };
  useEffect(() => {
    loadBondList();
  }, [
    pageNumber,
    search_text,
    toData,
    fromDate,
    preData,
    open,
    bondSelectOption,
  ]);

  const loadBondList = () => {
    setShowPreloader(true);
    ServerAPI.bondList(
      pageNumber,
      search_text,
      toData,
      fromDate,
      bondSelectOption
    )
      .then((res) => {
        try {
          setbondList(res?.data[0]?.securities);
          setTotalCount(res?.data[0]?.pagination?.total_records);
          // console.log(res.bonds_list.filter((e: any) => e.bond_type == 1))
        } catch (error) {
          toast.error(res.error);
        }
      })
      .finally(() => {
        setShowPreloader(false);
      });
  };
  const handelClickXsl = () => {
    setShowPreloader(true);
    getDownloadFileXsl(
      "https://trust-api.trustmony.com/admin/api/v1/securities-xl"
    );
    setShowPreloader(false);
  };
  const handleClickOpen = () => {};

  const handleClose = (e: any) => {
    setOpen(e);
    // suspended_list();
  };

  const handleClose_modal = (e: any) => {
    setOpenModal(e);
    loadBondList();
  };

  const activate_user = (id: any) => {
    setOpenModal(true);
    setUserId(id);
  };

  const view_more_content = (e: any) => {
    setViewContent(e);
    setOpen(true);
  };

  const removeSelectedFile = () => {
    setSelectedFile(null);
    setButtonText("Upload ISIN");
  };

  console.log("selectedFile", selectedFile);
  const handleUploadClick = () => {
    if (selectedFile) {
      // Perform API call
      setShowPreloader(true);
      ServerAPI.uploadExcelForISIN(selectedFile)
        .then((res) => {
          if (res.status === 200) {
            // Handle success
            toast.success(res.message);
            setSelectedFile(null); // Clear the selected file
            setButtonText("Upload ISIN");
          } else {
            // Handle success
            toast.error(res.message);
          }
        })
        .finally(() => {
          setShowPreloader(false);
        });
    } else {
      // Open dialog
      fileInputRef.current?.click();
    }
  };

  const handleDownloadClick = async () => {
    const persistAuthString = await Utilities.getAuthString();
    try {
      const response = await fetch(
        `https://v2-trust-api.trustmony.com/api/v2/admin/securities/sync`,
        {
          method: "GET",
          headers: {
            Authorization: persistAuthString,
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to fetch the file.");
      }

      // Retrieve Content-Disposition header to extract filename if provided
      const contentDisposition = response.headers.get("Content-Disposition");
      console.log("Content-Disposition:", contentDisposition);

      let filename = "Sample.xlsx"; // Default filename
      if (contentDisposition) {
        const match = contentDisposition.match(/filename="(.+?)"/);
        if (match && match[1]) {
          filename = match[1];
        }
      }

      // Convert response to ArrayBuffer
      const arrayBuffer = await response.arrayBuffer();

      // Create a Blob from the ArrayBuffer
      const blob = new Blob([arrayBuffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = URL.createObjectURL(blob);

      // Trigger file download
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", filename); // Use the extracted or default filename
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {
      console.error("Error fetching the file:", error);
    } finally {
      // Cleanup or additional actions
    }
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files && files.length > 0) {
      const file = files[0];
      const validMimeTypes = [
        "application/vnd.ms-excel", // .xls
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", // .xlsx
      ];

      if (!validMimeTypes.includes(file.type)) {
        toast.error("Unsupported file format. Please upload an Excel file.");
        setSelectedFile(null); // Reset selected file if invalid
        setButtonText("Upload ISIN");
        event.target.value = ""; // Reset file input
        return;
      }

      // If valid file, update state and button text
      setSelectedFile(file);
      setButtonText("Submit");
      console.log("Selected file:", file);
    }

    // Reset file input to allow reselecting the same file
    event.target.value = "";
  };

  const CustomInput = React.forwardRef((props: any, ref: any) => {
    return (
      <div className="datePickerStyle">
        <label onClick={props.onClick} ref={ref}>
          {props.value || props.placeholder}
        </label>
        <img
          alt="icon"
          src="../../assets/images/calender-icon.svg"
          onClick={props.onClick}
          ref={ref}
        />
      </div>
    );
  });

  const CustomSearchInput = React.forwardRef((props: any, ref: any) => {
    return (
      <div
        className="d-flex justify-content-start align-items-center"
        ref={ref}
      >
        <h4 className="card-title text-end p-2 m-0">Search :</h4>
        <form className="app-search card-title p-2 m-0">
          <div className="position-relative">
            <input
              type="text"
              className="select-style"
              onChange={(e) => setSearchText(e.target.value)}
            />
          </div>
        </form>
      </div>
    );
  });

  return (
    // <div>
    <div>
      <div id="layout-wrapper">
        <Header />
        <div className="main-content py-4">
          <div className="page-content">
            <div className="container-fluid">
              <div className="row">
                <div className="col-12">
                  <div className="page-title-box d-sm-flex align-items-center justify-content-between pt-4 pb-0">
                    {/* <h4 className="mb-sm-0 font-size-18 "> Suspend Users</h4> */}
                    <div className="page-title-right">
                      <ol className="breadcrumb ">
                        {/* <li className="breadcrumb-item">
                          <a style={{ color: "#90929E" }}>Trustmony</a>
                        </li> */}
                        <li className="breadcrumb-item">
                          <a> Master </a>
                        </li>
                        <li className="breadcrumb-item active ">Bonds</li>
                      </ol>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <div className="card">
                    <div className="card-body col-sm-12 col-md-12 col-lg-12 w-100">
                      {/* <div className="card-header col-xl-12 w-100"> */}
                      <div
                        className="row d-flex align-items-center"
                        style={{ paddingBottom: "10px" }}
                      >
                        <div className="col-xl-4">
                          <div className="d-flex justify-content-start align-items-center">
                            <h4 className="card-title text-end p-2 m-0">
                              Search :
                            </h4>
                            <form className="app-search card-title p-2 m-0">
                              <div className="position-relative">
                                <input
                                  type="text"
                                  className="select-style-search"
                                  onChange={(e) =>
                                    setSearchText(e.target.value)
                                  }
                                  // placeholder="Bond Name, Issuer Name ..."
                                />
                              </div>
                            </form>
                          </div>
                        </div>

                        <div className="col-xl-8 text-end p-0">
                          <div className="d-flex justify-content-end align-items-center">
                            <form className="app-search card-title p-1 m-0 w-100">
                              <div className="position-relative ">
                                <select
                                  className="select-style"
                                  name="bond_type"
                                  onChange={(e) =>
                                    setBondSelectOption(e.target.value)
                                  }
                                >
                                  <option value="all">All</option>
                                  <option value="IPO">IPO</option>
                                  <option value="BOND">Bond</option>
                                  {/* <option value="3">External Bond</option> */}
                                </select>
                              </div>
                            </form>
                            <button
                              className="xl_sheet"
                              onClick={handelClickXsl}
                            >
                              Download{" "}
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="15"
                                height="15"
                                viewBox="0 0 84.227 95.107"
                                className="xsl_icon"
                              >
                                <defs>
                                  <clipPath id="clip-path">
                                    <rect
                                      id="Rectangle_3009"
                                      data-name="Rectangle 3009"
                                      width="84.227"
                                      height="95.107"
                                    />
                                  </clipPath>
                                </defs>
                                <g
                                  id="Group_10650"
                                  data-name="Group 10650"
                                  transform="translate(-0.667 0.247)"
                                >
                                  <g
                                    id="Group_10649"
                                    data-name="Group 10649"
                                    transform="translate(0.667 -0.247)"
                                    clip-path="url(#clip-path)"
                                  >
                                    <path
                                      id="Path_21458"
                                      data-name="Path 21458"
                                      d="M78.9,34.019H76.614V23a1.791,1.791,0,0,0-.019-.208,1.83,1.83,0,0,0-.443-1.207L57.814.638C57.808.632,57.8.631,57.8.625a1.851,1.851,0,0,0-.371-.311c-.04-.027-.08-.049-.122-.073a1.956,1.956,0,0,0-.368-.155c-.035-.009-.066-.023-.1-.032A1.87,1.87,0,0,0,56.409,0H11.344a3.733,3.733,0,0,0-3.73,3.73V34.018H5.331A5.331,5.331,0,0,0,0,39.349V67.071A5.332,5.332,0,0,0,5.331,72.4H7.614V91.377a3.734,3.734,0,0,0,3.73,3.73h61.54a3.735,3.735,0,0,0,3.73-3.73V72.4H78.9a5.332,5.332,0,0,0,5.33-5.33V39.35a5.329,5.329,0,0,0-5.33-5.331M11.344,3.73h43.2V22.812a1.865,1.865,0,0,0,1.865,1.865H72.883v9.343H11.344ZM53.192,61.185v5.409H35.4V38.109h6.468V61.185ZM7.381,66.593l8.242-14.411L7.676,38.109h7.4l2.493,5.2c.846,1.732,1.48,3.128,2.157,4.734h.083c.678-1.818,1.226-3.086,1.944-4.734l2.409-5.2h7.354l-8.031,13.9,8.454,14.58H24.5L21.92,61.438c-1.056-1.987-1.733-3.465-2.535-5.114H19.3c-.592,1.649-1.309,3.129-2.2,5.114l-2.367,5.156H7.381Zm65.5,23.775H11.344V72.4h61.54V90.367Zm-8.916-23.35a18.641,18.641,0,0,1-8.07-1.734l1.308-5.325a16.452,16.452,0,0,0,7.144,1.776c2.959,0,4.522-1.226,4.522-3.085,0-1.776-1.352-2.79-4.776-4.016-4.733-1.649-7.819-4.269-7.819-8.412,0-4.861,4.057-8.579,10.779-8.579a17.48,17.48,0,0,1,7.268,1.437l-1.437,5.2a13.531,13.531,0,0,0-5.959-1.353c-2.789,0-4.141,1.267-4.141,2.748,0,1.817,1.6,2.621,5.282,4.016,5.029,1.859,7.4,4.478,7.4,8.494,0,4.777-3.678,8.834-11.5,8.834"
                                    />
                                  </g>
                                </g>
                              </svg>
                            </button>

                            <form
                              className="app-search card-title p-1 m-0 w-100"
                              style={{ fontSize: "0.78rem" }}
                            >
                              <div
                                className="position-relative btn-suspend d-flex align-items-center"
                                onClick={() =>
                                  navigate(PageLinks.IPO_ADD, {
                                    state: { mode: "add" },
                                  })
                                }
                              >
                                <a
                                  className="text-white"
                                  style={{ fontSize: "12px" }}
                                >
                                  <i
                                    className="fa fa-plus-circle mx-1"
                                    aria-hidden="true"
                                  ></i>
                                  Add New IPO
                                </a>
                              </div>
                            </form>
                            <form className="app-search card-title p-1 m-0 w-100">
                              <div className="position-relative">
                                {/* <DatePicker className="date-picker-input" maxDate={new Date()} clearIcon={null} closeCalendar={true} name="dob" format="dd/MM/yyyy" dayPlaceholder="DD" monthPlaceholder="MM" yearPlaceholder="YYYY" onChange={setFromDate} value={fromDate}
                                                                    calendarIcon={
                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="18" viewBox="0 0 69.398 79.312">
                                                                            <path id="Path_23208" data-name="Path 23208" d="M377.526,17.349V71.877a7.438,7.438,0,0,1-7.435,7.435H315.565a7.438,7.438,0,0,1-7.437-7.435V17.349a7.438,7.438,0,0,1,7.437-7.435H323V1.859A1.864,1.864,0,0,1,324.859,0H326.1a1.864,1.864,0,0,1,1.859,1.859V9.914H357.7V1.859A1.864,1.864,0,0,1,359.557,0H360.8a1.864,1.864,0,0,1,1.859,1.859V9.914h7.435a7.438,7.438,0,0,1,7.435,7.435m-64.441,0v7.437h59.484V17.349a2.486,2.486,0,0,0-2.478-2.478H315.565a2.487,2.487,0,0,0-2.48,2.478m59.484,54.528V29.743H313.085V71.877a2.486,2.486,0,0,0,2.48,2.478h54.526a2.485,2.485,0,0,0,2.478-2.478m-47.71-22.306A1.864,1.864,0,0,1,323,47.712v-6.2a1.864,1.864,0,0,1,1.859-1.859h6.2a1.864,1.864,0,0,1,1.859,1.859v6.2a1.864,1.864,0,0,1-1.859,1.859Zm0,14.871A1.864,1.864,0,0,1,323,62.583v-6.2a1.864,1.864,0,0,1,1.859-1.859h6.2a1.864,1.864,0,0,1,1.859,1.859v6.2a1.864,1.864,0,0,1-1.859,1.859ZM339.73,49.571a1.864,1.864,0,0,1-1.859-1.859v-6.2a1.864,1.864,0,0,1,1.859-1.859h6.2a1.864,1.864,0,0,1,1.859,1.859v6.2a1.864,1.864,0,0,1-1.859,1.859Zm0,14.871a1.864,1.864,0,0,1-1.859-1.859v-6.2a1.864,1.864,0,0,1,1.859-1.859h6.2a1.864,1.864,0,0,1,1.859,1.859v6.2a1.864,1.864,0,0,1-1.859,1.859ZM354.6,49.571a1.864,1.864,0,0,1-1.859-1.859v-6.2a1.864,1.864,0,0,1,1.859-1.859h6.2a1.864,1.864,0,0,1,1.859,1.859v6.2a1.864,1.864,0,0,1-1.859,1.859Zm0,14.871a1.864,1.864,0,0,1-1.859-1.859v-6.2a1.864,1.864,0,0,1,1.859-1.859h6.2a1.864,1.864,0,0,1,1.859,1.859v6.2a1.864,1.864,0,0,1-1.859,1.859Z" transform="translate(-308.128)" />
                                                                        </svg>
                                                                    } /> */}

                                <DatePicker
                                  selected={fromDate}
                                  closeOnScroll={true}
                                  onChange={(date: Date) => setFromDate(date)}
                                  placeholderText="DD/MM/YYYY"
                                  className="date-picker-style"
                                  peekNextMonth
                                  showMonthDropdown
                                  showYearDropdown
                                  dropdownMode="select"
                                  customInput={<CustomInput />}
                                />
                              </div>
                            </form>
                            <h4 className="card-title p-0 m-0">TO</h4>
                            <form className="app-search card-title p-1 m-0 w-100">
                              <div className="position-relative">
                                {/* <DatePicker className="date-picker-input" maxDate={new Date()} clearIcon={null} closeCalendar={false} name="dob" format="dd/MM/yyyy" dayPlaceholder="DD" monthPlaceholder="MM" yearPlaceholder="YYYY" onChange={setToData} value={toData}
                                                                    calendarIcon={
                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="18" viewBox="0 0 69.398 79.312">
                                                                            <path id="Path_23208" data-name="Path 23208" d="M377.526,17.349V71.877a7.438,7.438,0,0,1-7.435,7.435H315.565a7.438,7.438,0,0,1-7.437-7.435V17.349a7.438,7.438,0,0,1,7.437-7.435H323V1.859A1.864,1.864,0,0,1,324.859,0H326.1a1.864,1.864,0,0,1,1.859,1.859V9.914H357.7V1.859A1.864,1.864,0,0,1,359.557,0H360.8a1.864,1.864,0,0,1,1.859,1.859V9.914h7.435a7.438,7.438,0,0,1,7.435,7.435m-64.441,0v7.437h59.484V17.349a2.486,2.486,0,0,0-2.478-2.478H315.565a2.487,2.487,0,0,0-2.48,2.478m59.484,54.528V29.743H313.085V71.877a2.486,2.486,0,0,0,2.48,2.478h54.526a2.485,2.485,0,0,0,2.478-2.478m-47.71-22.306A1.864,1.864,0,0,1,323,47.712v-6.2a1.864,1.864,0,0,1,1.859-1.859h6.2a1.864,1.864,0,0,1,1.859,1.859v6.2a1.864,1.864,0,0,1-1.859,1.859Zm0,14.871A1.864,1.864,0,0,1,323,62.583v-6.2a1.864,1.864,0,0,1,1.859-1.859h6.2a1.864,1.864,0,0,1,1.859,1.859v6.2a1.864,1.864,0,0,1-1.859,1.859ZM339.73,49.571a1.864,1.864,0,0,1-1.859-1.859v-6.2a1.864,1.864,0,0,1,1.859-1.859h6.2a1.864,1.864,0,0,1,1.859,1.859v6.2a1.864,1.864,0,0,1-1.859,1.859Zm0,14.871a1.864,1.864,0,0,1-1.859-1.859v-6.2a1.864,1.864,0,0,1,1.859-1.859h6.2a1.864,1.864,0,0,1,1.859,1.859v6.2a1.864,1.864,0,0,1-1.859,1.859ZM354.6,49.571a1.864,1.864,0,0,1-1.859-1.859v-6.2a1.864,1.864,0,0,1,1.859-1.859h6.2a1.864,1.864,0,0,1,1.859,1.859v6.2a1.864,1.864,0,0,1-1.859,1.859Zm0,14.871a1.864,1.864,0,0,1-1.859-1.859v-6.2a1.864,1.864,0,0,1,1.859-1.859h6.2a1.864,1.864,0,0,1,1.859,1.859v6.2a1.864,1.864,0,0,1-1.859,1.859Z" transform="translate(-308.128)" />
                                                                        </svg>
                                                                    } /> */}
                                <DatePicker
                                  selected={toData}
                                  closeOnScroll={true}
                                  onChange={(date: Date) => setToData(date)}
                                  placeholderText="DD/MM/YYYY"
                                  className="date-picker-style"
                                  peekNextMonth
                                  showMonthDropdown
                                  showYearDropdown
                                  dropdownMode="select"
                                  customInput={<CustomInput />}
                                />
                              </div>
                            </form>
                            <button
                              onClick={resetfilter}
                              className=" btn-suspend text-white"
                            >
                              Reset
                            </button>
                          </div>
                        </div>
                      </div>
                      <div>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "1rem",
                          }}
                        >
                          {/* File Review Section */}
                          {selectedFile && (
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                gap: "0.5rem",
                              }}
                            >
                              <span
                                style={{
                                  color: "#A27B1E",
                                  fontWeight: "bold",
                                }}
                              >
                                {selectedFile.name}
                              </span>
                              <button
                                onClick={removeSelectedFile}
                                style={{
                                  background: "transparent",
                                  border: "none",
                                  color: "red",
                                  fontSize: "1.2rem",
                                  cursor: "pointer",
                                }}
                                title="Remove File"
                              >
                                ✖
                              </button>
                            </div>
                          )}

                          {/* Upload Button */}
                          <button
                            className="xl_sheet mb-2"
                            style={{
                              backgroundColor: "#A27B1E",
                              color: "white",
                              borderRadius: "5px",
                            }}
                            onClick={handleUploadClick}
                          >
                            {buttonText}
                            <i
                              className="fa fa-upload mx-1"
                              aria-hidden="true"
                            ></i>
                          </button>
                          <a
                            href="#"
                            // className="xl_sheet mb-2"
                            style={{
                              display: "inline-flex", // Adjusted for a typical inline behavior for links
                              alignItems: "center",
                              textDecoration: "underline", // Ensures the underline appears
                              color: "#A27B1E",
                              fontWeight: "bold",
                            }}
                            onClick={(e) => {
                              e.preventDefault();
                              handleDownloadClick();
                            }}
                          >
                            Download Sample Excel
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="15"
                              height="15"
                              viewBox="0 0 84.227 95.107"
                              className="xsl_icon"
                              fill="#A27B1E"
                              style={{ marginLeft: "8px" }}
                            >
                              <defs>
                                <clipPath id="clip-path">
                                  <rect
                                    id="Rectangle_3009"
                                    data-name="Rectangle 3009"
                                    width="84.227"
                                    height="95.107"
                                  />
                                </clipPath>
                              </defs>
                              <g
                                id="Group_10650"
                                data-name="Group 10650"
                                transform="translate(-0.667 0.247)"
                              >
                                <g
                                  id="Group_10649"
                                  data-name="Group 10649"
                                  transform="translate(0.667 -0.247)"
                                  clipPath="url(#clip-path)"
                                >
                                  <path
                                    id="Path_21458"
                                    data-name="Path 21458"
                                    d="M78.9,34.019H76.614V23a1.791,1.791,0,0,0-.019-.208,1.83,1.83,0,0,0-.443-1.207L57.814.638C57.808.632,57.8.631,57.8.625a1.851,1.851,0,0,0-.371-.311c-.04-.027-.08-.049-.122-.073a1.956,1.956,0,0,0-.368-.155c-.035-.009-.066-.023-.1-.032A1.87,1.87,0,0,0,56.409,0H11.344a3.733,3.733,0,0,0-3.73,3.73V34.018H5.331A5.331,5.331,0,0,0,0,39.349V67.071A5.332,5.332,0,0,0,5.331,72.4H7.614V91.377a3.734,3.734,0,0,0,3.73,3.73h61.54a3.735,3.735,0,0,0,3.73-3.73V72.4H78.9a5.332,5.332,0,0,0,5.33-5.33V39.35a5.329,5.329,0,0,0-5.33-5.331M11.344,3.73h43.2V22.812a1.865,1.865,0,0,0,1.865,1.865H72.883v9.343H11.344ZM53.192,61.185v5.409H35.4V38.109h6.468V61.185ZM7.381,66.593l8.242-14.411L7.676,38.109h7.4l2.493,5.2c.846,1.732,1.48,3.128,2.157,4.734h.083c.678-1.818,1.226-3.086,1.944-4.734l2.409-5.2h7.354l-8.031,13.9,8.454,14.58H24.5L21.92,61.438c-1.056-1.987-1.733-3.465-2.535-5.114H19.3c-.592,1.649-1.309,3.129-2.2,5.114l-2.367,5.156H7.381Zm65.5,23.775H11.344V72.4h61.54V90.367Zm-8.916-23.35a18.641,18.641,0,0,1-8.07-1.734l1.308-5.325a16.452,16.452,0,0,0,7.144,1.776c2.959,0,4.522-1.226,4.522-3.085,0-1.776-1.352-2.79-4.776-4.016-4.733-1.649-7.819-4.269-7.819-8.412,0-4.861,4.057-8.579,10.779-8.579a17.48,17.48,0,0,1,7.268,1.437l-1.437,5.2a13.531,13.531,0,0,0-5.959-1.353c-2.789,0-4.141,1.267-4.141,2.748,0,1.817,1.6,2.621,5.282,4.016,5.029,1.859,7.4,4.478,7.4,8.494,0,4.777-3.678,8.834-11.5,8.834"
                                  />
                                </g>
                              </g>
                            </svg>
                          </a>
                        </div>

                        {/* Hidden file input */}
                        <input
                          type="file"
                          ref={fileInputRef}
                          style={{ display: "none" }}
                          onChange={handleFileChange}
                        />
                      </div>
                      <div className="table-rep-plugin">
                        <div
                          className="table-responsive "
                          data-pattern="priority-columns"
                        >
                          {/* <table id="tech-companies-1" className="table table table-bordered">
                                                        <thead className="city-table">
                                                            <tr>
                                                                <th>Sr.No</th>
                                                                <th>Bond Name</th>
                                                                <th>Issuer Name</th>
                                                                {/* <th>Instrument Type</th> */}
                          {/* <th>ISIN Number</th>
                                                                <th>Face Value</th>
                                                                <th>Maturity Date</th>
                                                                <th>Call Date</th>
                                                                <th colSpan={3} data-priority="3" className="">Action</th>
                                                            </tr>
                                                        </thead>
                                                        {
                                                            bondList != undefined && bondList.length > 0 ?
                                                                <>
                                                                    {
                                                                        bondList.map((res: APIData.BondList, i: number) => (
                                                                            <tbody key={i}>
                                                                                <tr >
                                                                                    <td> {startSerialNo + i + 1} </td>
                                                                                    <td>{getDisplayData(res.bond_name)}</td>
                                                                                    <td>{getDisplayData(res.bond_issuer_name)}</td> */}
                          {/* <td>{getDisplayData(res.bond_nature_of_instrument)}</td> */}
                          {/* <td>{getDisplayData(res.bond_isin_number)}</td>
                                                                                    <td>{numberWithCommas(res.bond_face_value)}</td>
                                                                                    <td>{getFormattedLocalDateTime(res.bond_maturity_date)}</td>
                                                                                    <td>{getFormattedLocalDateTime(res.bond_created_at)}</td>
                                                                                    <td className="city-action-rable-row ">
                                                                                        <i className="fas fa-eye table-icons pe-1" onClick={() =>
                                                                                            navigate(
                                                                                                (res.bond_type == BondType.IPO ? PageLinks.IPO_DETAIL : "") ||
                                                                                                (res.bond_type == BondType.ADminBond ? PageLinks.BOND_DETAIL : "") ||
                                                                                                (res.bond_type == BondType.ExternalAPIBond ? PageLinks.BOND_DETAIL : "") ||
                                                                                                (res.bond_type == BondType.SGB ? "" : "")
                                                                                                , {
                                                                                                    state:
                                                                                                        res.bond_type == BondType.IPO ? res.bond_id : res.bond_isin_number
                                                                                                })}></i>
                                                                                    </td>
                                                                                </tr>
                                                                            </tbody>
                                                                        ))
                                                                    }
                                                                </>
                                                                : <tbody>
                                                                    <tr className='py-4'>
                                                                        <td className='text-center py-5' colSpan={11}>No Data Found.</td>
                                                                    </tr>
                                                                </tbody>
                                                        } */}
                          {/* <>
                                                            <tbody>
                                                                <tr >
                                                                    <td>01</td>
                                                                    <td>CREDITACCESS GRAMEEN LIMITED</td>
                                                                    <td>CREDITACCESS GRAMEEN LIMITED</td>
                                                                    <td>Non Perpetual</td>
                                                                    <td>AHFL29SEP18</td>
                                                                    <td>1000.00</td>
                                                                    <td>29 Sep 2021</td>
                                                                    <td>27 Dec 2022</td>

                                                                    <td className="city-action-rable-row ">
                                                                        <i className="fas fa-eye table-icons pe-1"
                                                                            onClick={() => navigate(PageLinks.BOND_DETAIL)}
                                                                        ></i>

                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" className='pb-2 cursor-pointer' height="22" viewBox="0 0 73.281 58.625"
                                                                        onClick={() => activate_user(res.user_specific_id)} 
                                                                        >
                                                                            <path id="Path_22594" data-name="Path 22594" d="M-1807.294,904.684v2.931a5.5,5.5,0,0,1-5.5,5.5h-40.3a5.5,5.5,0,0,1-5.5-5.5v-2.931a15.394,15.394,0,0,1,15.39-15.389c3.3,0,4.866,1.832,10.259,1.832s6.962-1.832,10.259-1.832A15.393,15.393,0,0,1-1807.294,904.684Zm-5.5,0a9.909,9.909,0,0,0-9.893-9.893c-1.683,0-4.339,1.832-10.259,1.832-5.874,0-8.588-1.832-10.259-1.832a9.911,9.911,0,0,0-9.894,9.893v2.931h40.3Zm-36.641-33.709a16.494,16.494,0,0,1,16.489-16.489,16.493,16.493,0,0,1,16.488,16.489,16.493,16.493,0,0,1-16.488,16.488A16.493,16.493,0,0,1-1849.431,870.975Zm5.5,0a11.01,11.01,0,0,0,10.993,10.992,11.01,11.01,0,0,0,10.992-10.992,11.01,11.01,0,0,0-10.992-10.993A11.011,11.011,0,0,0-1843.935,870.975Zm58.282,3.641-14.839,14.919a1.168,1.168,0,0,1-1.672,0l-8.45-8.507a1.2,1.2,0,0,1,0-1.683l2.2-2.2a1.169,1.169,0,0,1,1.672,0l5.428,5.462,11.8-11.874a1.169,1.169,0,0,1,1.672,0l2.187,2.2A1.2,1.2,0,0,1-1785.653,874.616Z" transform="translate(1858.591 -854.486)" />
                                                                        </svg>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </> */}
                          {/* </table> */}
                          <DataTable
                            data={bondList}
                            columns={columns}
                            noHeader
                            defaultSortFieldId={"customer_id"}
                            sortIcon={<i className="fas fa-sort"></i>}
                            defaultSortAsc={true}
                            highlightOnHover
                            dense
                            customStyles={customStyles}
                          />
                        </div>
                      </div>
                      <div className="row mt-3">
                        <div className="col-sm-12 col-md-5">
                          {totalCount > 10 ? (
                            <div
                              className="dataTables_info"
                              id="datatable_info"
                              role="status"
                              aria-live="polite"
                            >
                              Showing {bondList.length} of {totalCount} entries
                            </div>
                          ) : (
                            <div
                              className="dataTables_info"
                              id="datatable_info"
                              role="status"
                              aria-live="polite"
                            >
                              Showing {totalCount === 0 ? 0 : totalCount}{" "}
                              entries
                            </div>
                          )}
                        </div>
                        <div className="col-sm-12 col-md-7">
                          <div
                            className="dataTables_paginate paging_simple_numbers float-end mb-3"
                            id="datatable_paginate"
                          >
                            <Pagination
                              count={Math.ceil(totalCount / 10)}
                              onChange={(event: any, value: any) => {
                                setPageNumber(Number(value));
                                setCurrentPage(Number(value));
                              }}
                              variant="outlined"
                              color="primary"
                              shape="rounded"
                              className="pagination-style"
                            />
                          </div>
                        </div>
                      </div>

                      {/* </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
      <SimpleModal
        contents={view_content}
        handleOpen={() => handleClickOpen}
        openModal={open}
        handleClose={(e: any) => handleClose(e)}
      />
      {/* <ConfirmationModal contents="Are you sure you want to activate the user/account?" details_datas={user_id} openModal={open_modal} handleClose={(e: any) => handleClose_modal(e)} currentPage="suspend-user-list" /> */}
    </div>
  );
};

export default BondList;
