import AppNavigate from "./AppNavigate";
import { AppStateProvider } from "./common/AppState";
import AppToast from "./components/Toaster";

function App() {
  return (
    <div className="App">
      <AppToast />
      <AppStateProvider>
        <AppNavigate />
      </AppStateProvider>
    </div>
  );
}

export default App;
