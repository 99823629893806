import Pagination from '@mui/material/Pagination';
import { Field, FieldArray, Form, Formik, FormikProps } from 'formik';
import moment from 'moment';
import React, { Fragment, useCallback, useEffect, useRef, useState } from 'react';
// import DatePicker from 'react-date-picker';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useAppStateAPI } from '../common/AppStateAPI';
import { BondIPOStatus, Depository, NCDDetailsList, PageLinks, SGBUpcomingStatus, ShortTermList, Strings, wysiwygToolbar } from '../common/Constants';
import Footer from '../common/Footer';
import Header from '../common/Header';
import { ServerAPI } from '../common/ServerAPI';
import ConfirmationModal from '../modals/Confirmation-modal';
import SimpleModal from '../modals/SimpleModal';
import * as Yup from 'yup';
import { Grid, Input, SvgIcon } from '@mui/material';
import FormField from './form_items/FormField';
import { APIData } from '../common/DataTypes';
import dayjs, { Dayjs } from 'dayjs';
import TextField from '@mui/material/TextField';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { EditorState, convertToRaw, convertFromHTML, ContentState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import { InitialData } from '../common/InitialValue';
import BondDetails from './BondDetails';
import toast from 'react-hot-toast';
import { DropZone, getURLExtension, localStringToIST } from '../common/Utilities';
import { useDropzone } from 'react-dropzone';

var dmp: string = '';
var dmpIndex: number = 0;


const SGBAdd: React.FC = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const passing_value = location.state;
    const [preData, setPreData] = useState(passing_value)

    // const [value, onChange] = useState(new Date());
    const [toData, setToData] = useState<null | Date>();
    const [fromDate, setFromDate] = useState<null | Date>();
    const [open, setOpen] = useState(false);
    const [open_modal, setOpenModal] = useState(false)
    const [userList, setUserList] = useState<any[]>([]);
    const [totalCount, setTotalCount] = useState(0);
    const [pageNumber, setPageNumber] = useState(1);
    const [user_id, setUserId] = useState()
    const [view_content, setViewContent] = useState();
    const [search_text, setSearchText] = useState('');
    const { setShowPreloader } = useAppStateAPI();
    const [formikInitialData, setFormikInitialData] = useState<APIData.SGBAdd>(InitialData.SGBAdd);

    const [addDisclamer, setAddDisclamer] = useState<any | undefined>(EditorState.createEmpty());
    const formikRef = useRef<FormikProps<APIData.SGBAdd>>(null);
    const [uploadFeild, setUploadFeild] = useState<{ feild_type: string, inx: number }>({ feild_type: '', inx: 0 });
    const [bondID, setBondID] = useState(0);
    const [prevDate, setPrevDate] = useState<any>();
    const [issueDateCal, setissueDateCal] = useState<any>();

    const [allotmentDate, setAllotmentDate] = useState<null | Date>();

    useEffect(() => {
        dmp = uploadFeild.feild_type;
        dmpIndex = uploadFeild.inx;
    }, [uploadFeild])

    const onDisclamerEditorStateChange = (editorState: any) => {
        const rawContentState = convertToRaw(editorState.getCurrentContent());
        const htmlDescription = draftToHtml(rawContentState);
        const htmlResult = htmlDescription.replace(/(<([^>]+)>)/ig, '');
        setAddDisclamer(editorState);
        console.log(editorState);
        console.log(rawContentState);
        console.log(htmlDescription);
        console.log(htmlResult);
        // setAddDisclamerText(htmlResult);
        setFormikInitialData(({ ...formikInitialData, bond_disclaimer: htmlResult }));
    };
    function DateIcon() { return (<SvgIcon viewBox="0 0 72.872 83.283" style={{ padding: "2px" }}><path id="Path_27181" data-name="Path 27181" d="M-1078.15,955.569v7.808h-72.872v-7.808a7.809,7.809,0,0,1,7.808-7.808h7.807v-7.808a2.61,2.61,0,0,1,2.6-2.6h5.205a2.61,2.61,0,0,1,2.6,2.6v7.808h20.82v-7.808a2.609,2.609,0,0,1,2.6-2.6h5.2a2.609,2.609,0,0,1,2.6,2.6v7.808h7.808A7.81,7.81,0,0,1-1078.15,955.569Zm-72.872,13.013h72.872v44.243a7.811,7.811,0,0,1-7.808,7.809h-57.256a7.81,7.81,0,0,1-7.808-7.809Zm10.41,18.869a1.957,1.957,0,0,0,1.952,1.952h6.506a1.957,1.957,0,0,0,1.952-1.952v-6.507a1.957,1.957,0,0,0-1.952-1.952h-6.506a1.957,1.957,0,0,0-1.952,1.952Zm0,20.82a1.957,1.957,0,0,0,1.952,1.952h6.506a1.957,1.957,0,0,0,1.952-1.952v-6.506a1.958,1.958,0,0,0-1.952-1.952h-6.506a1.958,1.958,0,0,0-1.952,1.952Zm20.821-20.82a1.957,1.957,0,0,0,1.952,1.952h6.506a1.957,1.957,0,0,0,1.952-1.952v-6.507a1.957,1.957,0,0,0-1.952-1.952h-6.506a1.957,1.957,0,0,0-1.952,1.952Zm0,20.82a1.957,1.957,0,0,0,1.952,1.952h6.506a1.957,1.957,0,0,0,1.952-1.952v-6.506a1.958,1.958,0,0,0-1.952-1.952h-6.506a1.958,1.958,0,0,0-1.952,1.952Zm20.82-20.82a1.957,1.957,0,0,0,1.952,1.952h6.506a1.957,1.957,0,0,0,1.952-1.952v-6.507a1.957,1.957,0,0,0-1.952-1.952h-6.506a1.957,1.957,0,0,0-1.952,1.952Zm0,20.82a1.957,1.957,0,0,0,1.952,1.952h6.506a1.957,1.957,0,0,0,1.952-1.952v-6.506a1.958,1.958,0,0,0-1.952-1.952h-6.506a1.958,1.958,0,0,0-1.952,1.952Z" transform="translate(1151.022 -937.351)" /></SvgIcon>); }
    // const [selectedItems, setSelectedItems] = React.useState<any[]>([]);
    // const [allotmentDate, setAllotmentDate] = React.useState<Dayjs | null>(dayjs(moment().format('YYYY-MM-DDTHH:mm:ss')));
    const [issueOpeningDate, setIssueOpeningDate] = React.useState<Dayjs | null>(dayjs(moment().format('YYYY-MM-DDTHH:mm:ss')));
    const [issueClosingDate, setIssueClosingDate] = React.useState<Dayjs | null>(dayjs(moment().format('YYYY-MM-DDTHH:mm:ss')));
    const [nextInterestPaymentDate, setNextInterestPaymentDate] = React.useState<Dayjs | null>(dayjs(moment().format('YYYY-MM-DDTHH:mm:ss')));
    const [maturityAfterDate, setMaturityAfterDate] = React.useState<Dayjs | null>(dayjs(moment().format('YYYY-MM-DDTHH:mm:ss')));
    const [DMATBookCloserDate, setDMATBookCloserDate] = React.useState<Dayjs | null>(dayjs(moment().format('YYYY-MM-DDTHH:mm:ss')));
    const [couponDetailDate, setCouponDetailDate] = React.useState<Dayjs | null>(dayjs(moment().format('YYYY-MM-DDTHH:mm:ss')));

    // const handleChangeAllotmentDate = (newValue: Dayjs | null) => {
    //     setAllotmentDate(newValue);
    //     setFormikInitialData(({ ...formikInitialData, bond_allotment_date: newValue ? newValue?.format('YYYY-MM-DD').toString() : '' }));
    // };
    const handleChangeOpeningDate = (newValue: Dayjs | null) => {
        setissueDateCal(newValue?.format('MM/DD/YYYY').toString())
        setIssueOpeningDate(newValue);
        setFormikInitialData(({ ...formikInitialData, bond_opening_date: newValue ? newValue?.format('YYYY-MM-DD').toString() : '' }));
    };
    const handleChangeClosingDate = (newValue: Dayjs | null) => {
        setIssueClosingDate(newValue);
        setFormikInitialData(({ ...formikInitialData, bond_closing_date: newValue ? newValue?.format('YYYY-MM-DD').toString() : '' }));
    };

    const handleChangeInterestPaymentDate = (newValue: Dayjs | null) => {
        setNextInterestPaymentDate(newValue);
        setFormikInitialData(({ ...formikInitialData, bonds_next_interest_payment_date: newValue ? newValue?.format('YYYY-MM-DD').toString() : '' }));
    };

    const handleChangeMaturityAfterDate = (newValue: Dayjs | null) => {
        setMaturityAfterDate(newValue);
        setFormikInitialData(({ ...formikInitialData, bond_maturity_date: newValue ? newValue?.format('YYYY-MM-DD').toString() : '' }));
    };

    const handleChangeDMATBookDate = (newValue: Dayjs | null) => {
        setDMATBookCloserDate(newValue);
        setFormikInitialData(({ ...formikInitialData, bond_dmat_book_closer_date: newValue ? newValue?.format('YYYY-MM-DD').toString() : '' }));
    };

    const handleChangeCouponDetailDate = (newValue: Dayjs | null) => {
        setCouponDetailDate(newValue);
        setFormikInitialData(({ ...formikInitialData, bond_coupon_date: newValue ? newValue?.format('YYYY-MM-DD').toString() : '' }));
    };

    const handleAllotmentDate = (value: Date | null) => {
        console.log(value);
        var test: any = moment(value).format('YYYY-MM-DD').toString()
        console.log(test);
        console.log(moment(test).format("ddd MMM DD YYYY h:mm:ss zZZ"));
        // var filtered:Date = moment(test).format("ddd MMM DD YYYY h:mm:ss zZZ")
        var dateObj = new Date(moment(test).format("ddd MMM DD YYYY h:mm:ss zZZ"));
        console.log(dateObj);
        setAllotmentDate(dateObj);
        setFormikInitialData(({ ...formikInitialData, bond_allotment_date: value ? moment(value).format('YYYY-MM-DD').toString() : '' }));
    };

    useEffect(() => {
        loadBrandTags();
        loadEligibleInvestor();
        if (location.state.mode && location.state.mode == 'edit') {
            loadIntitalSGB(location.state.id);
        }
    }, [])

    const loadIntitalSGB = (id: string) => {
        if (id) {
            setShowPreloader(true);
            ServerAPI.getSGBDetail(id).then((res) => {
                console.log(res);
                setBondID(res.bond_id);
                setFormikInitialData(({ ...res }));
                // setAddDisclamer(res.bond_disclaimer);
            }).finally(() => {
                setShowPreloader(false)
            })
        }
    }

    const loadBrandTags = () => {
        setShowPreloader(true);
        ServerAPI.getBrandTagsList().then((res) => {
            if (formikRef.current?.values) {
                var currValues: APIData.SGBAdd = ({ ...formikRef.current.values });
                if ((currValues.bond_brand_tags).length == 0) {
                    res.brand_tags.map((tags: APIData.BondBrandTags, index: number) => {
                        currValues.bond_brand_tags.push({
                            bond_brand_tag_available: tags.bond_brand_tag_available,
                            brand_tag_id: tags.brand_tag_id,
                            brand_tag_name: tags.brand_tag_name,
                            bond_brand_tag_id: null,
                            brand_tag_status: tags.brand_tag_status
                        })
                    })
                    setFormikInitialData(({ ...formikInitialData }))
                }
            }
        }).finally(() => {
            setShowPreloader(false);
        })
    }

    const loadEligibleInvestor = () => {
        setShowPreloader(true);
        ServerAPI.getEligibleInvestorList().then((res) => {
            if (formikRef.current?.values) {
                var currValues: APIData.SGBAdd = ({ ...formikRef.current.values });
                if ((currValues.bond_eligible_investors).length == 0) {
                    res.eligible_investors.map((inv: APIData.BondEligibleInvestors, index: number) => {
                        currValues.bond_eligible_investors.push({
                            eligible_investors_available:inv.eligible_investors_available,
                            eligible_investor_id: inv.eligible_investor_id,
                            eligible_investor_name: inv.eligible_investor_name,
                            bond_eligible_investor_id: null,
                eligible_investor_status: inv.eligible_investor_status

                        })
                    })
                    setFormikInitialData(({ ...formikInitialData }))
                }
            }
        }).finally(() => {
            setShowPreloader(false);
        })

    }

    const updateBrandTags = (checked: boolean, index: number) => {
        console.log(checked)
        console.log(index)
        if (formikRef.current?.values) {
            var currValues: APIData.SGBAdd = ({ ...formikRef.current.values });
            currValues.bond_brand_tags.map((tags: APIData.BondBrandTags) => {
                if (currValues.bond_brand_tags.filter((e, i) => i == index).length == 1) {
                    currValues.bond_brand_tags[index].bond_brand_tag_available = checked == true ? index + 1 : null;
                    // currValues.bond_brand_tags[index].brand_tag_checked = !checked
                }
            })
            setFormikInitialData(({ ...formikInitialData }))
        }
    }
    const updateEligibleTags = (checked: boolean, index: number) => {
        console.log(checked)
        if (formikRef.current?.values) {
            var currValues: APIData.SGBAdd = ({ ...formikRef.current.values });
            currValues.bond_eligible_investors.map((tags: APIData.BondEligibleInvestors) => {
                if (currValues.bond_eligible_investors.filter((e, i) => i == index).length == 1) {
                    currValues.bond_eligible_investors[index].eligible_investors_available = checked == true ? index + 1 : null;
                    // currValues.bond_brand_tags[index].brand_tag_checked = !checked
                }
            })
            setFormikInitialData(({ ...formikInitialData }))
        }
    }


    useEffect(() => {
        // suspenist();
    }, [pageNumber, search_text, toData, fromDate, preData, open])


    const suspended_list = () => {
        setShowPreloader(true);
        ServerAPI.SuspentedUserList(pageNumber, search_text, toData, fromDate).then((res) => {
            setUserList(res.datas);
            setTotalCount(res.total_count);

        }).finally(() => {
            setShowPreloader(false);
        })
    }

    const CustomInput = React.forwardRef((props: any, ref: any) => {
        return (
            <div className='datePickerInputFeildStyle'>
                <label onClick={props.onClick} ref={ref}>
                    {props.value || props.placeholder}
                </label>
                <img src='../../assets/images/calender-icon.svg' onClick={props.onClick} ref={ref} />
            </div>
        );
    });

    /* Exce */
    const onDrop = useCallback((acceptedFiles: File[], fileRejections: any[]) => {
        acceptedFiles.map((file: any) => {
            loadUploadedfile(file);
        });
        fileRejections.forEach((file: any) => {
            file.errors.forEach((err: any) => {
                if (err.code === "dimension") {
                    toast.error(`Error: ${err.message}`);
                }
            });
        });

    }, [])
    const { getRootProps, getInputProps } = useDropzone(
        {
            onDrop,
            multiple: true
        }
    );

    const loadUploadedfile = (file: any) => {
        setShowPreloader(true);
        ServerAPI.setUploadedFile(file,null).then(response => {
            if (response.filename != undefined && response.filename != "") {
                console.log(dmp, dmpIndex);
                if (formikRef.current?.values) {
                    var currValues: APIData.SGBAdd = (formikRef.current.values);
                    if (dmp != undefined && dmp == 'bond_logo') {
                        currValues.bond_logo = response.filename;
                    } else if (dmp != undefined && dmp == 'bonds_banner_content_image') {
                        currValues.bonds_banner_content_image = response.filename;
                    } else if (dmp != undefined && dmp == 'bond_issue_document') {
                        currValues.bond_issue_document = response.filename;
                    } else if (dmp != undefined && dmp == 'bond_benefits_icon') {
                        currValues.bond_benefits[dmpIndex].bond_benefits_icon = response.filename;
                    }
                    console.log(currValues)
                    setFormikInitialData(e => ({ ...e, currValues }))
                }
            }
        }).finally(() => {
            setShowPreloader(false);
        });
    }

    const handleCalculateDay = (value: any, index: number) => {
        console.log(index);
        if (formikInitialData.bond_opening_date != undefined) {
            if (value != undefined && index == 0) {
                const date1: any = new Date(formikInitialData.bond_opening_date);
                const date2: any = new Date(value);
                const diffTime = Math.abs(date2 - date1);
                const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
                setPrevDate(value);
                return diffDays
            } else {
                const date1: any = new Date(prevDate);
                const date2: any = new Date(value);
                const diffTime = Math.abs(date2 - date1);
                const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
                setPrevDate(value);
                return diffDays
            }
        } else {
            toast.error('Please select the issue date')
        }
    }

    const addInterestCal = () => {
        if (formikRef.current?.values) {
            var currValues: APIData.SGBAdd = deepCopyIPO(formikRef.current.values);
            if (currValues.bond_cashflow_details.filter((e) => e.bond_cashflow_amount == 0 || e.bond_cashflow_days == '' || e.bond_cashflow_type == 0).length == 0) {
                currValues.bond_cashflow_details.push({ bond_cashflow_date: new Date, bond_cashflow_amount: 0, bond_cashflow_days: '', bond_cashflow_type: 0 });
            } else {
                toast.error('Please fill the feild');
            }
            setFormikInitialData((currValues));
        }
    }

    const removeInterestCal = (removeIndex: any) => {
        if (formikRef.current?.values) {
            var currValues: APIData.SGBAdd = deepCopyIPO(formikRef.current.values);
            let paramValues = currValues.bond_cashflow_details.filter((paramValue, index) => index !== removeIndex);
            setFormikInitialData({ ...formikInitialData, bond_cashflow_details: paramValues });
        }
    }

    const addBannnerContentKey = () => {
        if (formikRef.current?.values) {
            var currValues: APIData.SGBAdd = deepCopyIPO(formikRef.current.values);
            if (currValues.bond_highlights.filter((e) => e.bond_highlights_title == '').length == 0) {
                currValues.bond_highlights.push({ bond_highlights_title: '' });
            } else {
                toast.error('Please fill the feild');
            }
            setFormikInitialData(currValues);
        }
    }

    const removeBannerContentKey = (removeIndex: any) => {
        if (formikRef.current?.values) {
            var currValues: APIData.SGBAdd = deepCopyIPO(formikRef.current.values);
            let paramValues = currValues.bond_highlights.filter((paramValue, index) => index !== removeIndex);
            setFormikInitialData({ ...formikInitialData, bond_highlights: paramValues });
        }
    }

    const addBenefits = () => {
        if (formikRef.current?.values) {
            var currValues: APIData.SGBAdd = deepCopyIPO(formikRef.current.values);
            if (currValues.bond_benefits.filter((e) => e.bond_benefits_title == '' || e.bond_benefits_icon == '' || e.bond_benefits_description == '').length == 0) {
                currValues.bond_benefits.push({ bond_benefits_title: '', bond_benefits_icon: '', bond_benefits_description: '' });
            } else {
                toast.error('Please fill the feild');
            }
            setFormikInitialData(currValues);
        }
    }

    const removeBenefits = (removeIndex: any) => {
        if (formikRef.current?.values) {
            var currValues: APIData.SGBAdd = deepCopyIPO(formikRef.current.values);
            let paramValues = currValues.bond_benefits.filter((paramValue, index) => index !== removeIndex);
            setFormikInitialData({ ...formikInitialData, bond_benefits: paramValues });
        }
    }

    const addUpcomingIssueList = () => {
        if (formikRef.current?.values) {
            var currValues: APIData.SGBAdd = deepCopyIPO(formikRef.current.values);
            if (currValues.bond_upcoming_issues.filter((e) => e.bond_upcoming_issues_name == '').length == 0) {
                currValues.bond_upcoming_issues.push({ bond_upcoming_issues_name: '', bond_upcoming_issues_subscription_start_date: '', bond_upcoming_issues_subscription_end_date: '', bond_upcoming_issues_allotment_date: '' });
            } else {
                toast.error('Please fill the feild');
            }
            setFormikInitialData(currValues);
        }
    }

    // const removeUpcomingIssue = (removeIndex: any) => {
    //     if (formikRef.current?.values) {
    //         var currValues: APIData.SGBAdd = deepCopyIPO(formikRef.current.values);
    //         let paramValues = currValues.bond_upcoming_issues.filter((paramValue, index) => index !== removeIndex);
    //         setFormikInitialData({ ...formikInitialData, bond_upcoming_issues: paramValues });
    //     }
    // }

    const onClearUpcomingIssue = () => {
        setFormikInitialData(({ ...formikInitialData, bond_upcoming_issues: [] }));
    }

    const deepCopyIPO = (SGB: APIData.SGBAdd) => {
        let returnSGBAdd: APIData.SGBAdd = { ...SGB };
        if (SGB.bond_cashflow_details !== undefined) {
            returnSGBAdd.bond_cashflow_details = [...SGB.bond_cashflow_details];
        } else {
            returnSGBAdd.bond_cashflow_details = [];
        }
        if (SGB.bond_highlights !== undefined) {
            returnSGBAdd.bond_highlights = [...SGB.bond_highlights];
        } else {
            returnSGBAdd.bond_highlights = [];
        }
        if (SGB.bond_benefits !== undefined) {
            returnSGBAdd.bond_benefits = [...SGB.bond_benefits];
        } else {
            returnSGBAdd.bond_benefits = [];
        }
        if (SGB.bond_upcoming_issues !== undefined) {
            returnSGBAdd.bond_upcoming_issues = [...SGB.bond_upcoming_issues];
        } else {
            returnSGBAdd.bond_upcoming_issues = [];
        }
        return returnSGBAdd;
    }

    const yupSchema = Yup.object().shape({
        bond_issuer_name: Yup.string().required('Issuer Name is required'),
        bond_isin_number: Yup.string().required('Issuer ISIN Number is required'),
        bond_category_institutional: Yup.number().max(99, 'Institutional cannot be more than 2 digits').typeError('you must specify a number').test('not-zero', 'Number must not be zero', value => value !== 0),
        bond_category_non_institutional: Yup.number().max(99, 'Non Institutional cannot be more than 2 digits').typeError('you must specify a number').test('not-zero', 'Number must not be zero', value => value !== 0),
        bond_category_hni: Yup.number().max(99, 'HNI cannot be more than 2 digits').typeError('you must specify a number').test('not-zero', 'Number must not be zero', value => value !== 0),
        bond_category_retail: Yup.number().max(99, 'Retail cannot be more than 2 digits').typeError('you must specify a number').test('not-zero', 'Number must not be zero', value => value !== 0),
    });

    const submitData = async (data: any, setSubmitting: any) => {
        console.log(data);
        if (location.state.mode == 'edit') {
            setShowPreloader(true);
            ServerAPI.putSGB(data).then((res) => {
                toast.success(res.message);
                navigate(PageLinks.SGB_DETAIL, { state: res.bond_isin_number });
                setSubmitting(false);
            }).finally(() => {
                setShowPreloader(false);
            })
        } else {
            setShowPreloader(true);
            ServerAPI.postSGB(data).then((res) => {
                toast.success(res.message);
                navigate(PageLinks.SGB_DETAIL, { state: res.bond_isin_number });
                setSubmitting(false);
            }).finally(() => {
                setShowPreloader(false);
            })
        }
    }


    return (
        <div>
            <div id="layout-wrapper">
                <Header />
                <div className="main-content py-4">
                    <div className="page-content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-12">
                                    <div className="page-title-box d-sm-flex align-items-center justify-content-between pt-4 pb-0">
                                        <div className="page-title-right">
                                            <ol className="breadcrumb ">
                                                {/* <li className="breadcrumb-item"><a style={{ color: "#90929E" }}>Trustmony</a></li> */}
                                                <li className="breadcrumb-item" onClick={() => navigate(PageLinks.SGB_LIST)}><a>  Sovereign Gold Bond  </a></li>
                                                <li className="breadcrumb-item active ">{location.state?.mode != undefined && location.state.mode == 'edit' ? 'Edit' : 'Add'} Sovereign Gold Bond </li>
                                            </ol>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <div className="card">
                                        <div className="card-body col-sm-12 col-md-12 col-lg-12 w-100">
                                            <Formik
                                                validationSchema={yupSchema}
                                                enableReinitialize={true}
                                                validateOnChange={false}
                                                initialValues={formikInitialData}
                                                innerRef={formikRef}
                                                onSubmit={(values, { setSubmitting }) => {
                                                    // values.bond_allotment_date = location.state.mode != 'edit' ? values.bond_allotment_date?.format('YYYY-MM-DD').toString() : values.bond_allotment_date
                                                    // values.bond_opening_date = location.state.mode != 'edit' ? values.bond_opening_date?.format('YYYY-MM-DD').toString() : values.bond_opening_date
                                                    // values.bond_closing_date = location.state.mode != 'edit' ? values.bond_closing_date?.format('YYYY-MM-DD').toString() : values.bond_closing_date
                                                    // values.bonds_next_interest_payment_date = location.state.mode != 'edit' ? values.bonds_next_interest_payment_date?.format('YYYY-MM-DD').toString() : values.bonds_next_interest_payment_date
                                                    // values.bond_maturity_date = location.state.mode != 'edit' ? values.bond_maturity_date?.format('YYYY-MM-DD').toString() : values.bond_maturity_date
                                                    // values.bond_dmat_book_closer_date = location.state.mode != 'edit' ? values.bond_dmat_book_closer_date?.format('YYYY-MM-DD').toString() : values.bond_dmat_book_closer_date
                                                    // values.bond_coupon_date = location.state.mode != 'edit' ? values.bond_coupon_date?.format('YYYY-MM-DD').toString() : values.bond_coupon_date
                                                    // var cashFlow: APIData.BondCashflowDetails[] = [];
                                                    // values.bond_cashflow_details != undefined && values.bond_cashflow_details.map((flow: APIData.BondCashflowDetails) => {
                                                    //     cashFlow.push({
                                                    //         bond_cashflow_amount: flow.bond_cashflow_amount,
                                                    //         bond_cashflow_date: location.state.mode != 'edit' ? flow.bond_cashflow_date?.format('YYYY-MM-DD').toString() : flow.bond_cashflow_date,
                                                    //         bond_cashflow_days: flow.bond_cashflow_days,
                                                    //         bond_cashflow_type: flow.bond_cashflow_type
                                                    //     })
                                                    // })
                                                    // values.bond_cashflow_details = cashFlow;
                                                    // var upcomingIssue: APIData.bond_upcoming_issues[] = [];
                                                    // values.bond_upcoming_issues != undefined && values.bond_upcoming_issues.map((upcoming: APIData.bond_upcoming_issues) => {
                                                    //     upcomingIssue.push({
                                                    //         bond_upcoming_issues_allotment_date: location.state.mode != 'edit' ? upcoming.bond_upcoming_issues_allotment_date?.format('YYYY-MM-DD').toString() : upcoming.bond_upcoming_issues_allotment_date,
                                                    //         bond_upcoming_issues_name: upcoming.bond_upcoming_issues_name,
                                                    //         bond_upcoming_issues_subscription_end_date: location.state.mode != 'edit' ? upcoming.bond_upcoming_issues_subscription_end_date?.format('YYYY-MM-DD').toString() : upcoming.bond_upcoming_issues_subscription_end_date,
                                                    //         bond_upcoming_issues_subscription_start_date: location.state.mode != 'edit' ? upcoming.bond_upcoming_issues_subscription_start_date?.format('YYYY-MM-DD').toString() : upcoming.bond_upcoming_issues_subscription_start_date
                                                    //     })
                                                    // })
                                                    // values.bond_upcoming_issues = upcomingIssue;
                                                    values.bond_id = location.state.mode == 'edit' ? bondID : 0;
                                                    if (values.bond_maturity_date == moment().format('YYYY-MM-DD')) {
                                                        toast.error('Please select the date greater than today for maturity date');
                                                        setSubmitting(true);
                                                    } else {
                                                        submitData(values, setSubmitting);
                                                    }
                                                }}
                                            >{(formikProps) => {
                                                return (
                                                    <Form onSubmit={formikProps.handleSubmit}>
                                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                            <div className="row">
                                                                <div className="card" style={{ borderTopLeftRadius: "10px", borderTopRightRadius: "10px", paddingTop: "10px", paddingBottom: "20px" }}>
                                                                    <div className="card-header d-flex justify-content-between">
                                                                        <h5 className="m-0 header"> {location.state?.mode != undefined && location.state.mode == 'edit' ? 'Edit' : 'Add'} New Sovereign Gold Bond  </h5>
                                                                        <div className="form-check form-switch mx-2">
                                                                            <label className="form-check-label" htmlFor="distributorSubTypeChecked">Disable/Enable</label>
                                                                            <input className="form-check-input " type="checkbox" role="switch" id="distributorSubTypeChecked"
                                                                                onChange={(e) => { setFormikInitialData(({ ...formikInitialData, bond_status: e.target.checked == true ? 1 : 0 })) }}
                                                                                checked={formikProps.values?.bond_status == BondIPOStatus.Active ? true : false} />
                                                                        </div>
                                                                    </div>
                                                                    <div className="card-body">
                                                                        <Grid direction="row" container spacing={2}>
                                                                            <Grid item xs={4}>
                                                                                <div className='d-grid'>
                                                                                    <label htmlFor="bond_issuer_name" className='bond-label'>Issuer Name</label>
                                                                                    <input id="bond_issuer_name Name" name="bond_issuer_name" type="text" className='bond-input'
                                                                                        onChange={e => setFormikInitialData(({ ...formikInitialData, bond_issuer_name: e.target.value }))}
                                                                                        value={formikProps.values?.bond_issuer_name} />
                                                                                    {formikProps.errors.bond_issuer_name ? (<div style={{ color: "#FF0000" }}>{formikProps.errors.bond_issuer_name}</div>) : null}
                                                                                </div>
                                                                            </Grid>
                                                                            <Grid item xs={4}>
                                                                                <div className='d-grid'>
                                                                                    <label htmlFor="bond_name" className='bond-label'>Bond Name</label>
                                                                                    <input id="bond_name" name="bond_name" type="text" className='bond-input'
                                                                                        onChange={e => setFormikInitialData(({ ...formikInitialData, bond_name: e.target.value }))}
                                                                                        value={formikProps.values?.bond_name} />
                                                                                </div>
                                                                            </Grid>
                                                                            <Grid item xs={4}>
                                                                                <div className='d-grid'>
                                                                                    <label htmlFor="Nature of Instrument" className='bond-label'>Nature of Instrument</label>
                                                                                    <select id="Nature of Instrument" name="bond_nature_of_instrument"
                                                                                        onChange={e => { setFormikInitialData(({ ...formikInitialData, bond_nature_of_instrument: parseInt(e.target.value) })); }}
                                                                                        value={formikProps.values.bond_nature_of_instrument}
                                                                                        className='bond-input-select'
                                                                                    >
                                                                                        <option value={0}>Select</option>
                                                                                        <option value={1}>Taxable</option>
                                                                                        <option value={2}>Tax Free</option>
                                                                                    </select>
                                                                                </div>
                                                                            </Grid>

                                                                            <Grid item xs={4}>
                                                                                <div className='d-grid'>
                                                                                    <label htmlFor="bond_govt_guranatee" className='bond-label'>Govt. Guarantee</label>
                                                                                    <select id="bond_govt_guranateet" name="bond_govt_guranatee"
                                                                                        onChange={e => { setFormikInitialData(({ ...formikInitialData, bond_govt_guranatee: parseInt(e.target.value) })); }}
                                                                                        value={formikProps.values.bond_govt_guranatee}
                                                                                        className='bond-input-select'
                                                                                    >
                                                                                        <option value={0}>Select</option>
                                                                                        <option value={1}>Yes</option>
                                                                                        <option value={2}>NO</option>
                                                                                    </select>
                                                                                </div>
                                                                            </Grid>
                                                                            <Grid item xs={4}>
                                                                                <div className='d-grid'>
                                                                                    <label htmlFor="bond_issuer_isin_number" className='bond-label'>ISIN Number</label>
                                                                                    <input id="bond_issuer_isin_number" name="bond_issuer_isin_number" type="text" className='bond-input'
                                                                                        onChange={e => setFormikInitialData(({ ...formikInitialData, bond_isin_number: e.target.value }))}
                                                                                        value={formikProps.values?.bond_isin_number} />
                                                                                    {formikProps.errors.bond_isin_number ? (<div style={{ color: "#FF0000" }}>{formikProps.errors.bond_isin_number}</div>) : null}
                                                                                </div>
                                                                            </Grid>
                                                                            <Grid item xs={4}>
                                                                                <div className='d-grid'>
                                                                                    <label htmlFor="bond_face_value" className='bond-label'>Face Value (₹)</label>
                                                                                    <input id="bond_face_value" name="bond_face_value" type="number" className='bond-input'
                                                                                        onChange={e => setFormikInitialData(({ ...formikInitialData, bond_face_value: parseInt(e.target.value) }))}
                                                                                        value={formikProps.values?.bond_face_value} />
                                                                                </div>
                                                                            </Grid>
                                                                            <Grid item xs={4}>
                                                                                <div className='d-grid'>
                                                                                    <label htmlFor="bond_issue_price" className='bond-label'>issue Price (₹)</label>
                                                                                    <input id="bond_issue_price" name="bond_issue_price" type="number" className='bond-input'
                                                                                        onChange={e => setFormikInitialData(({ ...formikInitialData, bond_issue_price: parseInt(e.target.value) }))}
                                                                                        value={formikProps.values?.bond_issue_price} />
                                                                                </div>
                                                                            </Grid>
                                                                            <Grid item xs={4}>
                                                                                <div className='d-grid'>
                                                                                    <label htmlFor="bonds_price_per_gram" className='bond-label'>Price per Gram (₹)</label>
                                                                                    <input id="bonds_price_per_gram" name="bonds_price_per_gram" type="number" className='bond-input'
                                                                                        onChange={e => setFormikInitialData(({ ...formikInitialData, bonds_price_per_gram: parseInt(e.target.value) }))}
                                                                                        value={formikProps.values?.bonds_price_per_gram} />
                                                                                </div>
                                                                            </Grid>
                                                                            <Grid item xs={4}>
                                                                                <div className='d-grid'>
                                                                                    <label htmlFor="bond_purchase_limit" className='bond-label'>Purchase Limit (In years)</label>
                                                                                    <select id="bond_purchase_limit" name="bond_purchase_limit"
                                                                                        onChange={e => { setFormikInitialData(({ ...formikInitialData, bond_purchase_limit: parseInt(e.target.value) })); }}
                                                                                        value={formikProps.values.bond_purchase_limit}
                                                                                        className='bond-input-select'
                                                                                    >
                                                                                        <option value={0}>Select</option>
                                                                                        <option value={1}>1 Year</option>
                                                                                        <option value={2}>5 Year</option>
                                                                                        <option value={3}>10 Year</option>
                                                                                    </select>
                                                                                </div>
                                                                            </Grid>
                                                                            <Grid item xs={4}>
                                                                                <div className='d-grid'>
                                                                                    <label htmlFor="bond_coupon_rate" className='bond-label'>Coupon Rate (Returns %)</label>
                                                                                    <input id="bond_coupon_rate" name="bond_coupon_rate" type="text" className='bond-input'
                                                                                        onChange={e => setFormikInitialData(({ ...formikInitialData, bond_coupon_rate: e.target.value }))}
                                                                                        value={formikProps.values?.bond_coupon_rate} />
                                                                                </div>
                                                                            </Grid>
                                                                            <Grid item xs={4}>
                                                                                <div className='d-grid'>
                                                                                    <label htmlFor="bonds_yeild" className='bond-label'>Yeild (%)</label>
                                                                                    <input id="bonds_yeild" name="bonds_yeild" type="number" className='bond-input'
                                                                                        onChange={e => setFormikInitialData(({ ...formikInitialData, bonds_yeild: parseInt(e.target.value) }))}
                                                                                        value={formikProps.values?.bonds_yeild} />
                                                                                </div>
                                                                            </Grid>

                                                                            <Grid item xs={4}>
                                                                                <div className='d-grid'>
                                                                                    <label htmlFor="bond_allotment_date" className='bond-label'>Allotment Date</label>
                                                                                    <DatePicker
                                                                                        selected={localStringToIST(formikProps.values.bond_allotment_date)}
                                                                                        dateFormat="dd/MM/yyyy"
                                                                                        closeOnScroll={true}
                                                                                        onChange={(date: Date) => { setFormikInitialData(({ ...formikInitialData, bond_allotment_date: moment(date).format('YYYY-MM-DD').toString() })); }}
                                                                                         placeholderText="DD/MM/YYYY"
                                                                                        className='date-picker-style'
                                                                                        peekNextMonth
                                                                                        showMonthDropdown
                                                                                        showYearDropdown
                                                                                        dropdownMode="select"
                                                                                        customInput={(<CustomInput />)}
                                                                                    />
                                                                                </div>
                                                                            </Grid>
                                                                            <Grid item xs={4}>
                                                                                <div className='d-grid'>
                                                                                    <label htmlFor="bond_opening_date" className='bond-label'>Issue Opening Date</label>
                                                                                    <DatePicker
                                                                                        selected={localStringToIST(formikProps.values.bond_opening_date)}
                                                                                        dateFormat="dd/MM/yyyy"
                                                                                        closeOnScroll={true}
                                                                                        onChange={(date: Date) => { setFormikInitialData(({ ...formikInitialData, bond_opening_date: moment(date).format('YYYY-MM-DD').toString() })); }}
                                                                                         placeholderText="DD/MM/YYYY"
                                                                                        className='date-picker-style'
                                                                                        peekNextMonth
                                                                                        showMonthDropdown
                                                                                        showYearDropdown
                                                                                        dropdownMode="select"
                                                                                        customInput={(<CustomInput />)}
                                                                                    />
                                                                                </div>
                                                                            </Grid>
                                                                            <Grid item xs={4}>
                                                                                <div className='d-grid'>
                                                                                    <label htmlFor="bond_closing_date" className='bond-label'>Issue Closing Date</label>
                                                                                    <DatePicker
                                                                                        selected={localStringToIST(formikProps.values.bond_closing_date)}
                                                                                        dateFormat="dd/MM/yyyy"
                                                                                        closeOnScroll={true}
                                                                                        onChange={(date: Date) => { setFormikInitialData(({ ...formikInitialData, bond_closing_date: moment(date).format('YYYY-MM-DD').toString() })); }}
                                                                                         placeholderText="DD/MM/YYYY"
                                                                                        className='date-picker-style'
                                                                                        peekNextMonth
                                                                                        showMonthDropdown
                                                                                        showYearDropdown
                                                                                        dropdownMode="select"
                                                                                        customInput={(<CustomInput />)}
                                                                                    />
                                                                                </div>
                                                                            </Grid>
                                                                            <Grid item xs={4}>
                                                                                <div className='d-grid'>
                                                                                    <label htmlFor="bonds_next_interest_payment_date" className='bond-label'>Next Interest Payment Date</label>
                                                                                    <DatePicker
                                                                                        selected={localStringToIST(formikProps.values.bonds_next_interest_payment_date)}
                                                                                        dateFormat="dd/MM/yyyy"
                                                                                        closeOnScroll={true}
                                                                                        onChange={(date: Date) => { setFormikInitialData(({ ...formikInitialData, bonds_next_interest_payment_date: moment(date).format('YYYY-MM-DD').toString() })); }}
                                                                                         placeholderText="DD/MM/YYYY"
                                                                                        className='date-picker-style'
                                                                                        peekNextMonth
                                                                                        showMonthDropdown
                                                                                        showYearDropdown
                                                                                        dropdownMode="select"
                                                                                        customInput={(<CustomInput />)}
                                                                                    />
                                                                                </div>
                                                                            </Grid>
                                                                            <Grid item xs={4}>
                                                                                <div className='d-grid'>
                                                                                    <label htmlFor="bond_maturity_date" className='bond-label'>Maturity after</label>
                                                                                    <DatePicker
                                                                                        selected={localStringToIST(formikProps.values.bond_maturity_date)}
                                                                                        dateFormat="dd/MM/yyyy"
                                                                                        closeOnScroll={true}
                                                                                        onChange={(date: Date) => { setFormikInitialData(({ ...formikInitialData, bond_maturity_date: moment(date).format('YYYY-MM-DD').toString() })); }}
                                                                                         placeholderText="DD/MM/YYYY"
                                                                                        className='date-picker-style'
                                                                                        peekNextMonth
                                                                                        showMonthDropdown
                                                                                        showYearDropdown
                                                                                        dropdownMode="select"
                                                                                        customInput={(<CustomInput />)}
                                                                                    />
                                                                                </div>
                                                                            </Grid>

                                                                            <Grid item xs={4}>
                                                                                <div className='d-grid'>
                                                                                    <label htmlFor="exit_option_available" className='bond-label'>Exit Option Available</label>
                                                                                    <select id="exit_option_available" name="exit_option_available"
                                                                                        onChange={e => { setFormikInitialData(({ ...formikInitialData, exit_option_available: parseInt(e.target.value) })); }}
                                                                                        value={formikProps.values.exit_option_available}
                                                                                        className='bond-input-select'
                                                                                    >
                                                                                        <option value={0}>Select</option>
                                                                                        <option value={1}>Yes</option>
                                                                                        <option value={2}>No</option>
                                                                                    </select>
                                                                                </div>
                                                                            </Grid>

                                                                            <Grid item xs={4}>
                                                                                <div className='d-grid'>
                                                                                    <label htmlFor="bond_exchange" className='bond-label'>Exchange</label>
                                                                                    <select id="bond_exchange" name="bond_exchange"
                                                                                        onChange={e => { setFormikInitialData(({ ...formikInitialData, bond_exchange: e.target.value })); }}
                                                                                        value={formikProps.values.bond_exchange}
                                                                                        className='bond-input-select'
                                                                                    >
                                                                                        <option value={0}>Select</option>
                                                                                        <option value={1}>BSE</option>
                                                                                        <option value={2}>NSE</option>
                                                                                        <option value={3}>Both BSE & NSE</option>
                                                                                    </select>
                                                                                </div>
                                                                            </Grid>

                                                                            <Grid item xs={4}>
                                                                                <div className='d-grid'>
                                                                                    <label htmlFor="bonds_banner_rcb_notice_link" className='bond-label'>RBI Notice Link</label>
                                                                                    <input id="bonds_banner_rcb_notice_link" name="bonds_banner_rcb_notice_link" type="text" className='bond-input-upload-link'
                                                                                        onChange={e => setFormikInitialData(({ ...formikInitialData, bonds_banner_rcb_notice_link: e.target.value }))}
                                                                                        value={formikProps.values?.bonds_banner_rcb_notice_link}
                                                                                    />
                                                                                </div>
                                                                            </Grid>
                                                                            <Grid item xs={4}>
                                                                                <div className='d-grid'>
                                                                                    <label htmlFor="bond_logo" className='bond-label'>Upload Bond Logo</label>
                                                                                    <div {...getRootProps()}>
                                                                                        <input {...getInputProps()} />
                                                                                        {
                                                                                            <label className="bond-input-upload-file" onClick={() => setUploadFeild(e => ({ ...e, feild_type: 'bond_logo' }))}>{getURLExtension(formikRef.current?.values.bond_logo, 'Upload Bond Logo')}</label>
                                                                                        }
                                                                                    </div>
                                                                                </div>
                                                                            </Grid>
                                                                            {/* <Grid item xs={12}>
                                                                                <div className='d-grid'>
                                                                                    <label htmlFor="bond_about_this_ipo" className='bond-label'>About this</label>
                                                                                    <input id="bond_about_this_ipo" name="bond_about_this_ipo" type="text" className='bond-input'
                                                                                        onChange={e => setFormikInitialData(({ ...formikInitialData, bond_about_this_ipo: e.target.value }))}
                                                                                        value={formikProps.values?.bond_about_this_ipo} />
                                                                                </div>
                                                                            </Grid> */}

                                                                            <Grid item xs={4}>
                                                                                <div className='d-grid'>
                                                                                    <label htmlFor="bond_security_type" className='bond-label'>Security Type</label>
                                                                                    <select id="bond_security_type" name="bond_security_type"
                                                                                        onChange={e => { setFormikInitialData(({ ...formikInitialData, bond_security_type: parseInt(e.target.value) })); }}
                                                                                        value={formikProps.values.bond_security_type}
                                                                                        className='bond-input-select bond-input-disabled' disabled
                                                                                    >
                                                                                        <option value={0}>Select</option>
                                                                                        <option value={1}>Private Sector Bond (Non Convertible Debentures)</option>
                                                                                        <option value={2}>PSU Bond/ Government Security </option>
                                                                                        <option value={3}>Fixed Deposit</option>
                                                                                        <option value={4}>54 EC Capital Gains Bonds</option>
                                                                                        <option value={5}>RBI Sovereign Gold BondFloating Rate Savings Bonds</option>
                                                                                        <option value={6}>Corporate Fixed Deposit</option>
                                                                                    </select>
                                                                                </div>
                                                                            </Grid>
                                                                            <Grid item xs={4}>
                                                                                <div className='d-grid'>
                                                                                    <label htmlFor="bond_st" className='bond-label'>ST</label>
                                                                                    <input id="bond_st" name="bond_st" type="number" className='bond-input bond-input-disabled' disabled
                                                                                        onChange={e => setFormikInitialData(({ ...formikInitialData, bond_st: parseInt(e.target.value) }))}
                                                                                        value={formikProps.values?.bond_st} />
                                                                                </div>
                                                                            </Grid>
                                                                            <Grid item xs={4}>
                                                                                <div className='d-grid'>
                                                                                    <label htmlFor="bond_security_type_code" className='bond-label'>Security Type Code</label>
                                                                                    <input id="bond_security_type_code" name="bond_security_type_code" type="text" className='bond-input bond-input-disabled' disabled
                                                                                        onChange={e => setFormikInitialData(({ ...formikInitialData, bond_security_type_code: e.target.value }))}
                                                                                        value={formikProps.values?.bond_security_type_code} />
                                                                                </div>
                                                                            </Grid>
                                                                            <Grid item xs={4}>
                                                                                <div className='d-grid'>
                                                                                    <label htmlFor="bond_security_code" className='bond-label'>Security Code</label>
                                                                                    <input id="bond_security_code" name="bond_security_code" type="text" className='bond-input bond-input-disabled' disabled
                                                                                        onChange={e => setFormikInitialData(({ ...formikInitialData, bond_security_code: e.target.value }))}
                                                                                        value={formikProps.values?.bond_security_code} />
                                                                                </div>
                                                                            </Grid>
                                                                            <Grid item xs={4}>
                                                                                <div className='d-grid'>
                                                                                    <label htmlFor="bond_interest_type" className='bond-label'>Interest Type</label>
                                                                                    <select id="bond_interest_type" name="bond_interest_type"
                                                                                        onChange={e => { setFormikInitialData(({ ...formikInitialData, bond_interest_type: e.target.value })); }}
                                                                                        value={formikProps.values.bond_interest_type}
                                                                                        className='bond-input-select bond-input-disabled' disabled
                                                                                    >
                                                                                        <option value={0}>Select</option>
                                                                                        <option value={1}>Interest 1</option>
                                                                                        <option value={2}>Interest 2</option>
                                                                                        <option value={3}>Interest 3</option>
                                                                                    </select>
                                                                                </div>
                                                                            </Grid>
                                                                            <Grid item xs={4}>
                                                                                <div className='d-grid'>
                                                                                    <label htmlFor="Nature of Instrument" className='bond-label'>Interest Calculate by</label>
                                                                                    <select id="Nature of Instrument" name="email"
                                                                                        //    onChange={e => { setFormikInitialData(({ ...formikInitialData, cal: e.target.value })); }}
                                                                                        //    value={formikProps.values.bond_purchase_limit}
                                                                                        className='bond-input-select bond-input-disabled' disabled
                                                                                    >
                                                                                        <option value={0}>Select</option>
                                                                                        <option value={1}>Ratting</option>
                                                                                        <option value={2}>Amount</option>
                                                                                        <option value={3}>Date</option>
                                                                                    </select>
                                                                                </div>
                                                                            </Grid>
                                                                            <Grid item xs={4}>
                                                                                <div className='d-grid'>
                                                                                    <label htmlFor="bond_calc_int_on_holidays" className='bond-label'>Calc Int on Holiday</label>
                                                                                    <select id="bond_calc_int_on_holidays" name="bond_calc_int_on_holidays"
                                                                                        onChange={e => { setFormikInitialData(({ ...formikInitialData, bond_calc_int_on_holidays: parseInt(e.target.value) })); }}
                                                                                        value={formikProps.values.bond_calc_int_on_holidays}
                                                                                        className='bond-input-select bond-input-disabled' disabled
                                                                                    >
                                                                                        <option value={0}>Select</option>
                                                                                        <option value={1}>Yes</option>
                                                                                        <option value={2}>No</option>
                                                                                    </select>
                                                                                </div>
                                                                            </Grid>
                                                                            <Grid item xs={4}>
                                                                                <div className='d-grid'>
                                                                                    <label htmlFor="bond_int_on_maturity" className='bond-label'>Calc Int on Maturity</label>
                                                                                    <select id="bond_int_on_maturity" name="bond_int_on_maturity"
                                                                                        onChange={e => { setFormikInitialData(({ ...formikInitialData, bond_int_on_maturity: parseInt(e.target.value) })); }}
                                                                                        value={formikProps.values.bond_int_on_maturity}
                                                                                        className='bond-input-select bond-input-disabled' disabled
                                                                                    >
                                                                                        <option value={0}>Select</option>
                                                                                        <option value={1}>Yes</option>
                                                                                        <option value={2}>No</option>
                                                                                    </select>
                                                                                </div>
                                                                            </Grid>
                                                                            <Grid item xs={4}>
                                                                                <div className='d-grid'>
                                                                                    <label htmlFor="bond_listing" className='bond-label'>Listing</label>
                                                                                    <select id="bond_listing" name="bond_listing"
                                                                                        onChange={e => { setFormikInitialData(({ ...formikInitialData, bond_listing: parseInt(e.target.value) })); }}
                                                                                        value={formikProps.values.bond_listing}
                                                                                        className='bond-input-select bond-input-disabled' disabled
                                                                                    >
                                                                                        <option value={0}>Select</option>
                                                                                        <option value={1}>Yes</option>
                                                                                        <option value={2}>No</option>
                                                                                    </select>
                                                                                </div>
                                                                            </Grid>
                                                                            <Grid item xs={4}>
                                                                                <div className='d-grid'>
                                                                                    <label htmlFor="bond_dmat_book_closer_date" className='bond-label'>DMAT Book Closer Date</label>
                                                                                    <DatePicker
                                                                                        selected={localStringToIST(formikProps.values.bond_dmat_book_closer_date)}
                                                                                        dateFormat="dd/MM/yyyy"
                                                                                        closeOnScroll={true}
                                                                                        onChange={(date: Date) => { setFormikInitialData(({ ...formikInitialData, bond_dmat_book_closer_date: moment(date).format('YYYY-MM-DD').toString() })); }}
                                                                                         placeholderText="DD/MM/YYYY"
                                                                                        className='date-picker-style'
                                                                                        peekNextMonth
                                                                                        showMonthDropdown
                                                                                        showYearDropdown
                                                                                        dropdownMode="select"
                                                                                        customInput={(<CustomInput />)}
                                                                                    />
                                                                                </div>
                                                                            </Grid>

                                                                        </Grid>
                                                                    </div>
                                                                </div>
                                                                <br />
                                                            </div>
                                                            <div className="row">
                                                                <div className="card" style={{ borderTopLeftRadius: "10px", borderTopRightRadius: "10px", paddingTop: "10px", paddingBottom: "20px" }}>
                                                                    <div className="card-header">
                                                                        <h5 className="m-0 header"> Category Details </h5>
                                                                    </div>
                                                                    <div className="card-body">
                                                                        <Grid direction="row" container spacing={2}>
                                                                            <Grid item xs={3}>
                                                                                <div className='d-grid'>
                                                                                    <label htmlFor="bond_category_institutional" className='bond-label'>I - Institutional</label>
                                                                                    <input id="bond_category_institutional" name="bond_category_institutional" type="number" className='bond-input'
                                                                                        onChange={e => setFormikInitialData(({ ...formikInitialData, bond_category_institutional: parseInt(e.target.value) }))}
                                                                                        value={formikProps.values?.bond_category_institutional}
                                                                                        onBlur={formikProps.handleBlur} />
                                                                                    {formikProps.touched.bond_category_institutional && formikProps.errors.bond_category_institutional ?
                                                                                        (<div style={{ color: "#FF0000" }}>{(formikProps.errors.bond_category_institutional).toString()}</div>)
                                                                                        : null}
                                                                                </div>
                                                                            </Grid>
                                                                            <Grid item xs={3}>
                                                                                <div className='d-grid'>
                                                                                    <label htmlFor="bond_category_non_institutional" className='bond-label'>II - Non-Institutional</label>
                                                                                    <input id="bond_category_non_institutional" name="bond_category_non_institutional" type="number" className='bond-input'
                                                                                        onChange={e => setFormikInitialData(({ ...formikInitialData, bond_category_non_institutional: parseInt(e.target.value) }))}
                                                                                        value={formikProps.values?.bond_category_non_institutional}
                                                                                        onBlur={formikProps.handleBlur} />
                                                                                    {formikProps.touched.bond_category_non_institutional && formikProps.errors.bond_category_non_institutional ?
                                                                                        (<div style={{ color: "#FF0000" }}>{(formikProps.errors.bond_category_non_institutional).toString()}</div>)
                                                                                        : null}
                                                                                </div>
                                                                            </Grid>
                                                                            <Grid item xs={3}>
                                                                                <div className='d-grid'>
                                                                                    <label htmlFor="bond_category_hni" className='bond-label'>III - HNI</label>
                                                                                    <input id="bond_category_hni" name="bond_category_hni" type="number" className='bond-input'
                                                                                        onChange={e => setFormikInitialData(({ ...formikInitialData, bond_category_hni: parseInt(e.target.value) }))}
                                                                                        value={formikProps.values?.bond_category_hni}
                                                                                        onBlur={formikProps.handleBlur} />
                                                                                    {formikProps.touched.bond_category_hni && formikProps.errors.bond_category_hni ?
                                                                                        (<div style={{ color: "#FF0000" }}>{(formikProps.errors.bond_category_hni).toString()}</div>)
                                                                                        : null}
                                                                                </div>
                                                                            </Grid>
                                                                            <Grid item xs={3}>
                                                                                <div className='d-grid'>
                                                                                    <label htmlFor="bond_category_retail" className='bond-label'>IV - Retail</label>
                                                                                    <input id="bond_category_retail" name="bond_category_retail" type="number" className='bond-input'
                                                                                        onChange={e => setFormikInitialData(({ ...formikInitialData, bond_category_retail: parseInt(e.target.value) }))}
                                                                                        value={formikProps.values?.bond_category_retail}
                                                                                        onBlur={formikProps.handleBlur} />
                                                                                    {formikProps.touched.bond_category_retail && formikProps.errors.bond_category_retail ?
                                                                                        (<div style={{ color: "#FF0000" }}>{(formikProps.errors.bond_category_retail).toString()}</div>)
                                                                                        : null}
                                                                                </div>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="row">
                                                                <div className="card" style={{ borderTopLeftRadius: "10px", borderTopRightRadius: "10px", paddingTop: "10px", paddingBottom: "20px" }}>
                                                                    <div className="card-header">
                                                                        <h5 className="m-0 header"> Coupon Details </h5>
                                                                    </div>
                                                                    <div className="card-body">
                                                                        <Grid direction="row" container spacing={2}>
                                                                            <Grid item xs={3}>
                                                                                {/* <div className='d-grid'>
                                                                                    <label htmlFor="bond_coupon_date" className='bond-label'>Coupon Date</label>
                                                                                    <input id="bond_coupon_date" name="bond_coupon_date" type="text" className='bond-input'
                                                                                        onChange={e => setFormikInitialData(({ ...formikInitialData, bond_coupon_date: e.target.value }))}
                                                                                        value={formikProps.values?.bond_coupon_date!} />
                                                                                </div> */}
                                                                                <div className='d-grid'>
                                                                                    <label htmlFor="bond_coupon_date" className='bond-label'>Coupon Date</label>
                                                                                    {/* <DesktopDatePicker
                                                                                        inputFormat="DD/MM/YYYY"
                                                                                        value={couponDetailDate}
                                                                                        onChange={handleChangeCouponDetailDate}
                                                                                        renderInput={(params: any) => <TextField {...params} />}
                                                                                        className="date-picker-input-style"
                                                                                        components={{
                                                                                            OpenPickerIcon: DateIcon
                                                                                        }}
                                                                                    /> */}
                                                                                    <DatePicker
                                                                                        selected={localStringToIST(formikProps.values.bond_coupon_date)}
                                                                                        dateFormat="dd/MM/yyyy"
                                                                                        closeOnScroll={true}
                                                                                        onChange={(date: Date) => { setFormikInitialData(({ ...formikInitialData, bond_coupon_date: moment(date).format('YYYY-MM-DD').toString() })); }}
                                                                                         placeholderText="DD/MM/YYYY"
                                                                                        className='date-picker-style'
                                                                                        peekNextMonth
                                                                                        showMonthDropdown
                                                                                        showYearDropdown
                                                                                        dropdownMode="select"
                                                                                        customInput={(<CustomInput />)}
                                                                                    />
                                                                                </div>
                                                                            </Grid>
                                                                            <Grid item xs={3}>
                                                                                <div className='d-grid'>
                                                                                    <label htmlFor="bond_coupon_amount" className='bond-label'>Coupon Amount</label>
                                                                                    <input id="bond_coupon_amount" name="bond_coupon_amount" type="number" className='bond-input'
                                                                                        onChange={e => setFormikInitialData(({ ...formikInitialData, bond_coupon_amount: parseInt(e.target.value) }))}
                                                                                        value={formikProps.values?.bond_coupon_amount} />
                                                                                </div>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="card" style={{ borderTopLeftRadius: "10px", borderTopRightRadius: "10px", paddingTop: "10px", paddingBottom: "20px" }}>
                                                                    <div className="card-header d-flex justify-content-between">
                                                                        <h5 className="m-0 header"> Interest Calculate by Yearly </h5>
                                                                        <div className='bond-add-btn' onClick={() => addInterestCal()}>
                                                                            <a className='text-white'>
                                                                                <i className="fa fa-plus-circle mx-2" aria-hidden="true"></i>
                                                                                Add
                                                                            </a>
                                                                        </div>
                                                                    </div>
                                                                    <div className="card-body">
                                                                        {
                                                                            formikProps.values?.bond_cashflow_details != undefined && formikProps.values?.bond_cashflow_details.length > 0 ? formikProps.values?.bond_cashflow_details.map((cash, index) => {
                                                                                return (
                                                                                    <Grid direction="row" container spacing={2}>
                                                                                        <Grid item xs={2.5}>
                                                                                            {/* <div className='d-grid'>
                                                                                                <label htmlFor="bond_call_detail_datetime" className='bond-label'>Interest Date</label>
                                                                                                <input id="bond_call_detail_datetime" name="bond_call_detail_datetime" type="text" className='bond-input'
                                                                                                    value={cash.bond_cashflow_date}
                                                                                                    onChange={e => { cash.bond_cashflow_date = e.target.value; setFormikInitialData(({ ...formikInitialData })) }}
                                                                                                />
                                                                                            </div> */}
                                                                                            <div className='d-grid'>
                                                                                                <label htmlFor="bond_cashflow_date" className='bond-label'>Interest Date</label>
                                                                                                {/* <DesktopDatePicker
                                                                                                    inputFormat="DD/MM/YYYY"
                                                                                                    value={cash.bond_cashflow_date}
                                                                                                    onChange={e => {
                                                                                                        cash.bond_cashflow_date = e?.format('MM/DD/YYYY').toString();
                                                                                                        setFormikInitialData(({ ...formikInitialData }));
                                                                                                        const res = handleCalculateDay(e ? e?.format('MM/DD/YYYY').toString() : '', index);
                                                                                                        console.log(res)
                                                                                                        if (res) {
                                                                                                            cash.bond_cashflow_days = res + '';
                                                                                                        }
                                                                                                    }}
                                                                                                    renderInput={(params: any) => <TextField {...params} />}
                                                                                                    className="date-picker-input-style"
                                                                                                    components={{
                                                                                                        OpenPickerIcon: DateIcon
                                                                                                    }}
                                                                                                /> */}
                                                                                                <DatePicker
                                                                                                    selected={localStringToIST(cash.bond_cashflow_date)}
                                                                                                    dateFormat="dd/MM/yyyy"
                                                                                                    closeOnScroll={true}
                                                                                                    onChange={(date: Date) => {
                                                                                                        cash.bond_cashflow_date = moment(date).format('YYYY-MM-DD').toString();
                                                                                                        setFormikInitialData(({ ...formikInitialData }));
                                                                                                        const res = handleCalculateDay(date ? moment(date).format('MM/DD/YYYY').toString() : '', index);
                                                                                                        console.log(res)
                                                                                                        if (res) {
                                                                                                            cash.bond_cashflow_days = res + '';
                                                                                                        }
                                                                                                    }}
                                                                                                     placeholderText="DD/MM/YYYY"
                                                                                                    className='date-picker-style'
                                                                                                    peekNextMonth
                                                                                                    showMonthDropdown
                                                                                                    showYearDropdown
                                                                                                    dropdownMode="select"
                                                                                                    customInput={(<CustomInput />)}
                                                                                                />
                                                                                            </div>
                                                                                        </Grid>
                                                                                        <Grid item xs={2.5}>
                                                                                            <div className='d-grid'>
                                                                                                <label htmlFor="bond_cashflow_type" className='bond-label'>Interest Rate</label>
                                                                                                <input id="bond_cashflow_type" name="bond_cashflow_type" type="number" className='bond-input'
                                                                                                    value={cash.bond_cashflow_type}
                                                                                                    onChange={e => { cash.bond_cashflow_type = parseInt(e.target.value); setFormikInitialData(({ ...formikInitialData })) }}
                                                                                                />
                                                                                            </div>
                                                                                        </Grid>
                                                                                        <Grid item xs={2.5}>
                                                                                            <div className='d-grid'>
                                                                                                <label htmlFor="bond_cashflow_amount" className='bond-label'>Interest Amount</label>
                                                                                                <input id="bond_cashflow_amount" name="bond_cashflow_amount" type="number" className='bond-input'
                                                                                                    value={cash.bond_cashflow_amount}
                                                                                                    onChange={e => { cash.bond_cashflow_amount = parseInt(e.target.value); setFormikInitialData(({ ...formikInitialData })) }}
                                                                                                />
                                                                                            </div>
                                                                                        </Grid>
                                                                                        <Grid item xs={2.5}>
                                                                                            <div className='d-grid'>
                                                                                                <label htmlFor="bond_cashflow_days" className='bond-label'>No. of Days</label>
                                                                                                <input id="bond_cashflow_days" name="bond_cashflow_days" type="number" className='bond-input'
                                                                                                    value={cash.bond_cashflow_days}
                                                                                                    onChange={e => { cash.bond_cashflow_days = e.target.value; setFormikInitialData(({ ...formikInitialData })) }}
                                                                                                />
                                                                                            </div>
                                                                                        </Grid>
                                                                                        <div className='d-flex align-items-center'>
                                                                                            <div className='bond-add-btn-minus' style={{ cursor: "pointer" }} onClick={() => removeInterestCal(index)}>
                                                                                                <a ><span>-</span></a>
                                                                                            </div>
                                                                                        </div>
                                                                                    </Grid>
                                                                                )
                                                                            }) : ""
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="row">
                                                                <div className="card" style={{ borderTopLeftRadius: "10px", borderTopRightRadius: "10px", paddingTop: "10px", paddingBottom: "20px" }}>
                                                                    <div className="card-header d-flex justify-content-between">
                                                                        <h5 className="m-0 header"> Investment Purpose Categories </h5>
                                                                    </div>
                                                                    <div className="card-body">
                                                                        <div className="text-center">
                                                                            <div className="row row-cols-12">
                                                                                {
                                                                                    formikProps.values?.bond_brand_tags != undefined && formikProps.values?.bond_brand_tags.length > 0 ?
                                                                                        <>
                                                                                            {
                                                                                                formikProps.values?.bond_brand_tags.map((brand_tags, index) => {
                                                                                                    return (
                                                                                                        <div className={`col-3 brand-tag${brand_tags.bond_brand_tag_available != null ? '-active' : ''} px-1 py-2 mx-2 my-2 fs-6 text-center d-flex align-items-center justify-content-center`}
                                                                                                            style={{ cursor: "pointer" }}
                                                                                                            onClick={() =>
                                                                                                                updateBrandTags(brand_tags.bond_brand_tag_available != null ? false : true, index)
                                                                                                            }
                                                                                                        >{brand_tags.brand_tag_name}</div>
                                                                                                    )
                                                                                                })

                                                                                            }
                                                                                        </>
                                                                                        : ''
                                                                                }
                                                                            </div>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="card" style={{ borderTopLeftRadius: "10px", borderTopRightRadius: "10px", paddingTop: "10px", paddingBottom: "20px" }}>
                                                                    <div className="card-header d-flex justify-content-between">
                                                                        <h5 className="m-0 header"> Eligible Investors </h5>
                                                                    </div>
                                                                    <div className="card-body">
                                                                        <div className="text-center">
                                                                            {
                                                                                formikProps.values?.bond_eligible_investors != undefined && formikProps.values?.bond_eligible_investors.length > 0 ?
                                                                                    <div className="row row-cols-12 ">
                                                                                        {
                                                                                            formikProps.values?.bond_eligible_investors.map((eligible, index) => {
                                                                                                return (
                                                                                                     <>
                                                {eligible?.eligible_investor_status == 1 &&
                                                                                                    <div className={`col-3 brand-tag${eligible.eligible_investors_available != null ? '-active' : ''} px-1 py-2 mx-2 my-2 fs-6 text-center d-flex align-items-center justify-content-center`}
                                                                                                        style={{ cursor: "pointer" }}
                                                                                                        onClick={() =>
                                                                                                            updateEligibleTags(eligible.eligible_investors_available != null ? false : true, index)
                                                                                                        }
                                                                                                    >{eligible.eligible_investor_name}</div>
                                                                                            }</>
                                                                                                )
                                                                                            })
                                                                                        }
                                                                                    </div>
                                                                                    : ''
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="card" style={{ borderTopLeftRadius: "10px", borderTopRightRadius: "10px", paddingTop: "10px", paddingBottom: "20px" }}>
                                                                    <div className="card-header d-flex justify-content-between">
                                                                        <h5 className="m-0 header"> About This Bond</h5>
                                                                    </div>
                                                                    <div className="card-body">
                                                                        <div className="text-left">
                                                                            <div className="d-grid">
                                                                                <textarea
                                                                                    id="bond_about_this_ipo"
                                                                                    name="bond_about_this_ipo"
                                                                                    rows={4} cols={50} maxLength={100} placeholder="100 words max*"
                                                                                    className="bond-input"
                                                                                    onChange={e => setFormikInitialData(({ ...formikInitialData, bond_about_this_ipo: e.target.value }))} 
                                                                                    value={formikProps.values?.bond_about_this_ipo}
                                                                                />
                                                                            </div>
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="card" style={{ borderTopLeftRadius: "10px", borderTopRightRadius: "10px", paddingTop: "10px", paddingBottom: "20px" }}>
                                                                    <div className="card-header">
                                                                        <h5 className="m-0 header"> Banner Content </h5>
                                                                    </div>
                                                                    <div className="card-body">
                                                                        <Grid direction="row" container spacing={2}>
                                                                            <Grid item xs={4}>
                                                                                <div className='d-grid'>
                                                                                    <label htmlFor="bonds_banner_content_title" className='bond-label'>Title Text</label>
                                                                                    <input id="bonds_banner_content_title" name='bonds_banner_content_title' type="text" className='bond-input'
                                                                                        onChange={e => setFormikInitialData(({ ...formikInitialData, bonds_banner_content_title: e.target.value }))}
                                                                                        value={formikProps.values?.bonds_banner_content_title} />
                                                                                </div>
                                                                            </Grid>
                                                                            <Grid item xs={4}>
                                                                                <div className='d-grid'>
                                                                                    <label htmlFor="bonds_banner_content_sub_title" className='bond-label'>Sub Title Text</label>
                                                                                    <input id="bonds_banner_content_sub_title" name='bonds_banner_content_sub_title' type="text" className='bond-input'
                                                                                        onChange={e => setFormikInitialData(({ ...formikInitialData, bonds_banner_content_sub_title: e.target.value }))}
                                                                                        value={formikProps.values?.bonds_banner_content_sub_title} />
                                                                                </div>
                                                                            </Grid>
                                                                            <Grid item xs={4}>
                                                                                <div className='d-grid'>
                                                                                    <label htmlFor="bonds_banner_content_image" className='bond-label'>Upload Banner image (w1920 x h580)*</label>
                                                                                    <div {...getRootProps()}>
                                                                                        <input {...getInputProps()} />
                                                                                        {
                                                                                            <label className="bond-input-upload-file" style={{ margin: "0px" }} onClick={() => setUploadFeild(e => ({ ...e, feild_type: 'bonds_banner_content_image' }))}>{getURLExtension(formikRef.current?.values.bonds_banner_content_image, 'Upload Banner Content')}</label>
                                                                                        }
                                                                                    </div>
                                                                                    <p style={{ margin: "0px", fontSize: "12px", paddingTop: "05px" }}>*Banner image left side 60% free space needed.</p>
                                                                                </div>
                                                                            </Grid>
                                                                        </Grid>
                                                                        <div className="card-header d-flex py-2 px-0 align-items-center">
                                                                            <h5 className="m-0 header"> Key Highlights </h5>
                                                                            <div className='bond-add-btn' style={{ marginLeft: "10px" }} onClick={() => addBannnerContentKey()}>
                                                                                <a className='text-white'>
                                                                                    <i className="fa fa-plus-circle mx-2" aria-hidden="true"></i>
                                                                                    Add
                                                                                </a>
                                                                            </div>
                                                                        </div>
                                                                        <div className="card-body py-3 px-0">
                                                                            <Grid direction="row" container spacing={2}>
                                                                                {
                                                                                    formikProps.values?.bond_highlights.map((highlight, index) => {
                                                                                        return (
                                                                                            <>
                                                                                                <Grid item xs={10}>
                                                                                                    <div className='d-grid'>
                                                                                                        <input id="bond_highlights" name="bond_highlights" type="text" className='bond-input'
                                                                                                            value={highlight.bond_highlights_title}
                                                                                                            onChange={e => { highlight.bond_highlights_title = e.target.value; setFormikInitialData(({ ...formikInitialData })) }}
                                                                                                        />
                                                                                                    </div>
                                                                                                </Grid>
                                                                                                <div className='d-flex align-items-center'>
                                                                                                    <div className='sgb-banner-key-btn-minus'>
                                                                                                        <a onClick={() => removeBannerContentKey(index)}><span>-</span></a>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </>
                                                                                        )
                                                                                    })
                                                                                }
                                                                            </Grid>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="row">
                                                                <div className="card" style={{ borderTopLeftRadius: "10px", borderTopRightRadius: "10px", paddingTop: "10px", paddingBottom: "20px" }}>
                                                                    <div className="card-header d-flex justify-content-between">
                                                                        <h5 className="m-0 header"> Sovereign Gold Bond Benefits </h5>
                                                                        <div className='bond-add-btn' onClick={() => addBenefits()} >
                                                                            <a className='text-white'>
                                                                                <i className="fa fa-plus-circle mx-2" aria-hidden="true"></i>
                                                                                Add
                                                                            </a>
                                                                        </div>
                                                                    </div>
                                                                    <div className="card-body">
                                                                        <Grid direction="row" container spacing={2}>
                                                                            {
                                                                                formikProps.values?.bond_benefits.map((benefit, index) => {
                                                                                    return (
                                                                                        <>
                                                                                            <Grid item xs={11}>
                                                                                                <div className='d-flex'>
                                                                                                    <Grid item xs={6}>
                                                                                                        <div className='d-grid'>
                                                                                                            <label htmlFor="bond_benefits_title" className='bond-label'>Title Text</label>
                                                                                                            <input id="bond_benefits_title" name="bond_benefits_title" type="text" className='bond-input'
                                                                                                                value={benefit.bond_benefits_title!}
                                                                                                                onChange={e => { benefit.bond_benefits_title = e.target.value; setFormikInitialData(({ ...formikInitialData })) }}
                                                                                                            />
                                                                                                        </div>
                                                                                                    </Grid>
                                                                                                    <Grid item xs={6}>
                                                                                                        <div className='d-grid px-2'>
                                                                                                            <label htmlFor="bond_benefits_icon" className='bond-label'>Upload Icon</label>
                                                                                                            <div {...getRootProps()}>
                                                                                                                <input {...getInputProps()} />
                                                                                                                {
                                                                                                                    <label className="bond-input-upload-file" onClick={() => setUploadFeild(e => ({ ...e, feild_type: 'bond_benefits_icon', inx: index }))}>{getURLExtension(benefit.bond_benefits_icon, 'Upload Benefit Logo')}</label>
                                                                                                                }
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </Grid>
                                                                                                </div>
                                                                                                <Grid item xs={12}>
                                                                                                    <div className='d-grid'>
                                                                                                        <label htmlFor="bond_benefits_description" className='bond-label'>Description</label>
                                                                                                        <input id="bond_benefits_description" name="bond_benefits_description" type="text" className='bond-input'
                                                                                                            value={benefit.bond_benefits_description}
                                                                                                            onChange={e => { benefit.bond_benefits_description = e.target.value; setFormikInitialData(({ ...formikInitialData })) }}
                                                                                                        />
                                                                                                    </div>
                                                                                                </Grid>
                                                                                            </Grid>
                                                                                            <Grid item xs={1}>
                                                                                                <div className='d-flex align-items-center'>
                                                                                                    <div className='sgb-benefit-btn-minus'>
                                                                                                        <a onClick={() => removeBenefits(index)}><span>-</span></a>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </Grid>
                                                                                        </>
                                                                                    )
                                                                                })
                                                                            }
                                                                        </Grid>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="row">
                                                                <div className="card" style={{ borderTopLeftRadius: "10px", borderTopRightRadius: "10px", paddingTop: "10px", paddingBottom: "20px" }}>
                                                                    <div className="card-header">
                                                                        <h5 className="m-0 header"> Upload ISSUE DOCUMENTS </h5>
                                                                    </div>
                                                                    <div className="card-body">
                                                                        <Grid direction="row" container spacing={2}>
                                                                            <Grid item xs={5}>
                                                                                <div className='d-grid px-2'>
                                                                                    <div {...getRootProps()}>
                                                                                        <input {...getInputProps()} />
                                                                                        {
                                                                                            <label className="bond-input-upload-file" onClick={() => setUploadFeild(e => ({ ...e, feild_type: 'bond_issue_document' }))}>{getURLExtension(formikRef.current?.values.bond_issue_document, 'Upload issue document')}</label>
                                                                                        }
                                                                                    </div>
                                                                                </div>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="row">
                                                                <div className="card" style={{ borderTopLeftRadius: "10px", borderTopRightRadius: "10px", paddingTop: "10px", paddingBottom: "20px" }}>
                                                                    <div className="card-header d-flex justify-content-between align-items-center">
                                                                        <div className='d-flex justify-content-start'>
                                                                            <h5 className="m-0 header"> Upcoming issues </h5>
                                                                        </div>
                                                                        <div className='d-flex justify-content-end align-items-center '>
                                                                            <div className="form-check form-switch mx-2">
                                                                                <label className="form-check-label" htmlFor="flexSwitchCheckChecked">Disable/Enable</label>
                                                                                <input className="form-check-input " type="checkbox" role="switch" id="flexSwitchCheckChecked"
                                                                                    onChange={(e) => { setFormikInitialData(({ ...formikInitialData, bond_upcoming_issues_status: e.target.checked == true ? 1 : 0 })) }}
                                                                                    checked={formikProps.values?.bond_upcoming_issues_status == SGBUpcomingStatus.Active ? true : false}
                                                                                />
                                                                            </div>
                                                                            <div className='bond-add-btn' onClick={() => addUpcomingIssueList()}>
                                                                                <a className='text-white'>
                                                                                    <i className="fa fa-plus-circle mx-2" aria-hidden="true"></i>
                                                                                    Add
                                                                                </a>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="card-body">
                                                                        <div className="table-responsive " data-pattern="priority-columns">
                                                                            <table id="tech-companies-1" className="table table table-bordered">
                                                                                <thead className="city-table">
                                                                                    <tr>
                                                                                        <th>Sr. No</th>
                                                                                        <th>Issue Name</th>
                                                                                        <th>Subscription Dates</th>
                                                                                        <th>Allotment Date</th>
                                                                                    </tr>
                                                                                </thead>
                                                                                {
                                                                                    formikRef.current?.values.bond_upcoming_issues != undefined && formikRef.current?.values.bond_upcoming_issues.length > 0 ?
                                                                                        <tbody>
                                                                                            {
                                                                                                formikRef.current?.values.bond_upcoming_issues.map((upcoming: APIData.bond_upcoming_issues, inx: number) => {
                                                                                                    return (
                                                                                                        <tr>
                                                                                                            <td>{inx + 1}</td>
                                                                                                            <td>
                                                                                                                <input id="bond_upcoming_issues_name" name="bond_upcoming_issues_name" type="text" className='bond-input'
                                                                                                                    value={upcoming.bond_upcoming_issues_name!}
                                                                                                                    onChange={e => { upcoming.bond_upcoming_issues_name = e.target.value; setFormikInitialData(({ ...formikInitialData })) }}
                                                                                                                />
                                                                                                            </td>
                                                                                                            <td>
                                                                                                                <div className='d-flex align-items-center justify-content-between'>
                                                                                                                    {/* <div className='d-grid'> */}
                                                                                                                    {/* <DesktopDatePicker
                                                                                                                            inputFormat="DD/MM/YYYY"
                                                                                                                            value={upcoming.bond_upcoming_issues_subscription_start_date}
                                                                                                                            onChange={e => { upcoming.bond_upcoming_issues_subscription_start_date = e?.format('YYYY-MM-DD').toString(); setFormikInitialData(({ ...formikInitialData })) }}
                                                                                                                            renderInput={(params: any) => <TextField {...params} />}
                                                                                                                            className="date-picker-input-style"
                                                                                                                            components={{
                                                                                                                                OpenPickerIcon: DateIcon
                                                                                                                            }}
                                                                                                                        /> */}
                                                                                                                    <DatePicker
                                                                                                                        selected={localStringToIST(upcoming.bond_upcoming_issues_subscription_start_date)}
                                                                                                                        dateFormat="dd/MM/yyyy"
                                                                                                                        closeOnScroll={true}
                                                                                                                        onChange={(date: Date) => { upcoming.bond_upcoming_issues_subscription_start_date = moment(date).format('YYYY-MM-DD').toString(); setFormikInitialData(({ ...formikInitialData })); }}
                                                                                                                         placeholderText="DD/MM/YYYY"
                                                                                                                        className='date-picker-style'
                                                                                                                        peekNextMonth
                                                                                                                        showMonthDropdown
                                                                                                                        showYearDropdown
                                                                                                                        dropdownMode="select"
                                                                                                                        customInput={(<CustomInput />)}
                                                                                                                    />
                                                                                                                    {/* </div> */}
                                                                                                                    <h4 className="card-title p-2 m-0">TO</h4>
                                                                                                                    {/* <div className='d-grid'> */}
                                                                                                                    {/* <DesktopDatePicker
                                                                                                                            inputFormat="DD/MM/YYYY"
                                                                                                                            value={upcoming.bond_upcoming_issues_subscription_end_date}
                                                                                                                            onChange={e => { upcoming.bond_upcoming_issues_subscription_end_date = e?.format('YYYY-MM-DD').toString(); setFormikInitialData(({ ...formikInitialData })) }}
                                                                                                                            renderInput={(params: any) => <TextField {...params} />}
                                                                                                                            className="date-picker-input-style"
                                                                                                                            components={{
                                                                                                                                OpenPickerIcon: DateIcon
                                                                                                                            }}
                                                                                                                        /> */}
                                                                                                                    <DatePicker
                                                                                                                        selected={localStringToIST(upcoming.bond_upcoming_issues_subscription_end_date)}
                                                                                                                        dateFormat="dd/MM/yyyy"
                                                                                                                        closeOnScroll={true}
                                                                                                                        onChange={(date: Date) => { upcoming.bond_upcoming_issues_subscription_end_date = moment(date).format('YYYY-MM-DD').toString(); setFormikInitialData(({ ...formikInitialData })); }}
                                                                                                                         placeholderText="DD/MM/YYYY"
                                                                                                                        className='date-picker-style'
                                                                                                                        peekNextMonth
                                                                                                                        showMonthDropdown
                                                                                                                        showYearDropdown
                                                                                                                        dropdownMode="select"
                                                                                                                        customInput={(<CustomInput />)}
                                                                                                                    />
                                                                                                                    {/* </div> */}
                                                                                                                </div>
                                                                                                            </td>
                                                                                                            <td>
                                                                                                                <div>
                                                                                                                    <div className='d-grid'>
                                                                                                                        {/* <DesktopDatePicker
                                                                                                                            inputFormat="DD/MM/YYYY"
                                                                                                                            value={upcoming.bond_upcoming_issues_allotment_date}
                                                                                                                            onChange={e => { upcoming.bond_upcoming_issues_allotment_date = e?.format('YYYY-MM-DD').toString(); console.log(e); setFormikInitialData(({ ...formikInitialData })) }}
                                                                                                                            renderInput={(params: any) => <TextField {...params} />}
                                                                                                                            className="date-picker-input-style"
                                                                                                                            components={{
                                                                                                                                OpenPickerIcon: DateIcon
                                                                                                                            }}
                                                                                                                        /> */}
                                                                                                                        <DatePicker
                                                                                                                            selected={localStringToIST(upcoming.bond_upcoming_issues_allotment_date)}
                                                                                                                            dateFormat="dd/MM/yyyy"
                                                                                                                            closeOnScroll={true}
                                                                                                                            onChange={(date: Date) => { upcoming.bond_upcoming_issues_allotment_date = moment(date).format('YYYY-MM-DD').toString(); setFormikInitialData(({ ...formikInitialData })); }}
                                                                                                                             placeholderText="DD/MM/YYYY"
                                                                                                                            className='date-picker-style'
                                                                                                                            peekNextMonth
                                                                                                                            showMonthDropdown
                                                                                                                            showYearDropdown
                                                                                                                            dropdownMode="select"
                                                                                                                            customInput={(<CustomInput />)}
                                                                                                                        />
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </td>
                                                                                                        </tr>
                                                                                                    )
                                                                                                })
                                                                                            }
                                                                                        </tbody>
                                                                                        : <tbody>
                                                                                            <div className='d-flex justify-content-center align-items-center'>
                                                                                                <h5>No Data Available</h5>
                                                                                            </div>
                                                                                        </tbody>
                                                                                }
                                                                            </table>
                                                                        </div>
                                                                    </div>
                                                                    {/* <div className='row text-end my-3'>
                                                                        <div className=''>
                                                                            <a className='cancel-btn mx-3' onClick={() => onClearUpcomingIssue()}>Clear</a>
                                                                            <a className='save-btn'>
                                                                                Save
                                                                            </a>
                                                                        </div>
                                                                    </div> */}
                                                                </div>
                                                            </div>

                                                            <div className="row">
                                                                <div className="card" style={{ borderTopLeftRadius: "10px", borderTopRightRadius: "10px", paddingTop: "10px", paddingBottom: "20px" }}>
                                                                    <div className="card-header">
                                                                        <h5 className="m-0 header"> Disclaimer </h5>
                                                                    </div>
                                                                    <div className="card-body">
                                                                        <Editor
                                                                            editorState={addDisclamer}
                                                                            toolbarClassName="toolbar_form-control"
                                                                            wrapperClassName="editor_form-control_feedDetail"
                                                                            editorClassName="editor_textFeild_form-control"
                                                                            onEditorStateChange={(e: any) => onDisclamerEditorStateChange(e)}
                                                                            placeholder="Write a Disclamer..."
                                                                            toolbar={wysiwygToolbar}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <Grid direction="row" container spacing={2}>
                                                                <Grid item xs={4}>
                                                                    <div className='d-grid'>
                                                                        <label htmlFor="Bond Name" className='bond-label'>Terms & Conditions Link</label>
                                                                        <input id="Bond Name" name="lastName" type="text" className='bond-input-upload-link'
                                                                            onChange={e => setFormikInitialData(({ ...formikInitialData, bond_term_condition_link: e.target.value }))}
                                                                            value={formikProps.values?.bond_term_condition_link}
                                                                        // onChange={e => { formikProps.values?.bond_term_condition_link = e.target.value; setFormikInitialData(({ ...formikInitialData })) }}
                                                                        />
                                                                    </div>
                                                                </Grid>
                                                            </Grid>

                                                            <div className='row text-end my-3 py-5 '>
                                                                <div className=''>
                                                                    <a className='cancel-btn mx-3' onClick={() => navigate(-1)} >Cancel</a>
                                                                    <button type='submit' className='save-btn'>
                                                                        {location.state.mode == 'edit' ? 'Update' : 'Save'}
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </LocalizationProvider>
                                                    </Form>
                                                )
                                            }}
                                            </Formik>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </div >
    );
}

export default SGBAdd;

