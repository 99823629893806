import React, { useEffect, useState } from "react";
import Header from "../common/Header";
import DatePicker from "react-date-picker";
import Footer from "../common/Footer";
import DataTable from "react-data-table-component";
import toast from "react-hot-toast";
import { useAppStateAPI } from "../common/AppStateAPI";
import { ServerAPI } from "../common/ServerAPI";
import { Pagination } from "@mui/material";
import {
  getCustomFormattedLocalDate,
  getDisplayData,
} from "../common/Utilities";

const IPOCategoryList = () => {
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const { setShowPreloader } = useAppStateAPI();
  const [totalCount, setTotalCount] = useState(10);
  const [holidayList, setHolidayList] = useState<any[]>([]);
  const itemsPerPage = 10;
  const pageCount = Math.ceil(totalCount / itemsPerPage);
  const [pageNumber, setPageNumber] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [bondList, setbondList] = useState<any>([]);
  const [payload, setPayload] = useState<any>({
    category_id: null,
    security_id: null,
    category_name: "",
    category_code: "",
  });
  const customStyles = {
    headCells: {
      style: {
        backgroundColor: "#A27B1E",
        color: "white",
        fontSize: "14px",
      },
    },
  };
  const columns = [
    {
      name: "S.No",
      selector: (row: any, index: any) => index + 1,
      sortable: true,
    },
    {
      name: "Category Name",
      selector: (row: any) => getDisplayData(row.category_name),
      sortable: true,
    },
    {
      name: "Category Code",
      selector: (row: any) => getDisplayData(row.category_code),
      sortable: true,
    },
    {
      name: "Security Name",
      selector: (row: any) => getDisplayData(row.security.security_name),
      sortable: true,
      width: "500px",
    },
    {
      name: "Action",
      cell: (row: any) => [
        <td className="city-action-rable-row ">
          <i
            className="fas fa-trash table-icons pe-1"
            onClick={() => handleDeleteClick(row?.category_id)} // Call the delete handler here
            style={{ cursor: "pointer", color: "black" }}
          ></i>
          <i
            className="fas fa-pencil-alt table-icons pe-1 mx-2"
            onClick={() => handleEditClick(row)} // Call the edit handler here
            style={{ cursor: "pointer" }}
          ></i>
        </td>,
      ],
    },
  ];
  const handlePageChange = (page: any) => {
    setCurrentPage(page);
    setPageNumber(page);
  };
  useEffect(() => {
    loadHolidayList();
  }, [pageNumber]);
  const loadHolidayList = () => {
    setShowPreloader(true);
    ServerAPI.ipoCategoryList(pageNumber, "")
      .then((res) => {
        try {
          setHolidayList(res.data[0].categories);
          setTotalCount(res.data[0].pagination.total_records);
        } catch (error) {
          toast.error(res.error);
        }
      })
      .finally(() => {
        setShowPreloader(false);
      });
  };
  const syncHoliday = () => {
    setShowPreloader(true);
    ServerAPI.addOrUpdateIPOCategory(payload)
      .then((res) => {
        if (res.status === 200) {
          toast.success(res.message);
          loadHolidayList();
        } else {
          // Handle success
          toast.error(res.message);
        }
      })
      .finally(() => {
        setShowPreloader(false);
      });
  };
  useEffect(() => {
    ServerAPI.IpoBondList("", "", "", "", "IPO_ONLY")
      .then((res) => {
        try {
          setbondList(res?.data[0]?.securities);
          // setTotalCount(res?.data[0]?.pagination?.total_records);
          // console.log(res.bonds_list.filter((e: any) => e.bond_type == 1))
        } catch (error) {
          toast.error(res.error);
        }
      })
      .finally(() => {
        setShowPreloader(false);
      });
  }, []);
  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    setPayload((prevPayload: any) => ({
      ...prevPayload,
      [name]: value,
    }));
  };

  // Clear fields
  const handleClear = () => {
    setPayload({
      category_id: null,
      security_id: null,
      category_name: "",
      category_code: "",
    });
  };
  const handleEditClick = (row: any) => {
    setPayload({
      category_id: row.category_id,
      security_id: row.security_id,
      category_name: row.category_name,
      category_code: row.category_code,
    });
  };
  const handleDeleteClick = (categoryId: number) => {
    // console.log("Deleted Category ID:", categoryId);
    setShowPreloader(true);
    ServerAPI.deletIPOCategory(categoryId)
      .then((res) => {
        try {
          toast.success(res.message);
          loadHolidayList();
        } catch (error) {
          toast.error(res.error);
        }
      })
      .finally(() => {
        setShowPreloader(false);
      });
  };
  return (
    <div>
      <div id="layout-wrapper">
        <Header />
        <div className="main-content py-4">
          <div className="page-content">
            <div className="container-fluid">
              <div className="row">
                <div className="col-12">
                  <div className="page-title-box d-sm-flex align-items-center justify-content-between pt-4 pb-0">
                    {/* <h4 className="mb-sm-0 font-size-18 "> Suspend Users</h4> */}
                    <div className="page-title-right">
                      <ol className="breadcrumb ">
                        {/* <li className="breadcrumb-item">
                        <a style={{ color: "#90929E" }}>Trustmony</a>
                      </li> */}
                        <li className="breadcrumb-item">
                          <a> Master </a>
                        </li>
                        <li className="breadcrumb-item active ">
                          IPO Category Master
                        </li>
                      </ol>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <div className="card">
                    <div className="card-body col-sm-12 col-md-12 col-lg-12 w-100">
                      {/* <div className="card-header col-xl-12 w-100"> */}
                      <div
                        className="row d-flex align-items-center"
                        style={{ paddingBottom: "10px" }}
                      >
                        <div className="col-xl-10">
                          <div className="d-flex justify-content-start align-items-center">
                            {/* Category Name */}
                            <h4 className="card-title text-end p-2 m-0">
                              Category Name:
                            </h4>
                            <div className="position-relative">
                              <input
                                type="text"
                                name="category_name"
                                value={payload.category_name}
                                onChange={handleInputChange}
                                className="bond-input"
                              />
                            </div>

                            {/* Category Code */}
                            <h4 className="card-title text-end p-2 m-0">
                              Category Code:
                            </h4>
                            <div className="position-relative">
                              <input
                                type="text"
                                name="category_code"
                                value={payload.category_code}
                                onChange={handleInputChange}
                                className="bond-input"
                              />
                            </div>

                            {/* Security */}
                            <h4 className="card-title text-end p-2 m-0">
                              Security:
                            </h4>
                            <div className="position-relative">
                              <select
                                id="security"
                                name="security_id"
                                value={payload.security_id || ""}
                                onChange={handleInputChange}
                                className="bond-input-select"
                              >
                                <option value="" disabled>
                                  Select security
                                </option>
                                {bondList?.map((val: any) => (
                                  <option
                                    key={val?.security_id}
                                    value={val?.security_id}
                                  >
                                    {val?.security_name}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>
                        </div>

                        {/* Save and Clear Buttons */}
                        <div className="col-xl-2 text-end p-0">
                          <div className="d-flex justify-content-end align-items-center">
                            <div>
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  gap: "1rem",
                                }}
                              >
                                {/* Save Button */}
                                <button
                                  className="xl_sheet mb-2"
                                  style={{
                                    backgroundColor: "#A27B1E",
                                    color: "white",
                                    borderRadius: "5px",
                                  }}
                                  onClick={syncHoliday}
                                >
                                  Save
                                </button>

                                {/* Clear Button */}
                                <button
                                  className="xl_sheet mb-2"
                                  style={{
                                    backgroundColor: "#A27B1E",
                                    color: "white",
                                    borderRadius: "5px",
                                  }}
                                  onClick={handleClear}
                                >
                                  Clear
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="table-rep-plugin">
                        <div
                          className="table-responsive "
                          data-pattern="priority-columns"
                        >
                          <DataTable
                            data={holidayList}
                            columns={columns}
                            noHeader
                            defaultSortFieldId="security_holiday_calendar_id"
                            sortIcon={<i className="fas fa-sort"></i>}
                            defaultSortAsc={true}
                            highlightOnHover
                            dense
                            customStyles={customStyles}
                          />
                          <div className="pagination">
                            <button
                              className="pagination-btn"
                              disabled={currentPage === 1 ? true : false}
                              onClick={() => handlePageChange(currentPage - 1)}
                            >
                              <i
                                className={
                                  currentPage === 1
                                    ? "disabled fa fa-chevron-left"
                                    : "fa fa-chevron-left"
                                }
                              ></i>
                            </button>
                            {Array.from(Array(pageCount), (val, index) => (
                              <>
                                <button
                                  key={index}
                                  onClick={() => handlePageChange(index + 1)}
                                  className={
                                    currentPage === index + 1
                                      ? "active pagination-btn"
                                      : "pagination-btn"
                                  }
                                >
                                  {index + 1}
                                </button>
                              </>
                            ))}
                            <button
                              className="pagination-btn"
                              disabled={
                                currentPage > pageCount - 1 ? true : false
                              }
                              onClick={() => handlePageChange(currentPage + 1)}
                            >
                              <i
                                className={
                                  currentPage > pageCount - 1
                                    ? "disabled fa fa-chevron-right"
                                    : "fa fa-chevron-right"
                                }
                              ></i>
                            </button>
                          </div>
                        </div>
                        {/* <div className="row mt-2">
                          <div className="col-sm-12 col-md-5">
                            {totalCount > 10 ? (
                              <div
                                className="dataTables_info"
                                id="datatable_info"
                                role="status"
                                aria-live="polite"
                              >
                                Showing {holidayList?.length} of {totalCount}{" "}
                                entries
                              </div>
                            ) : (
                              <div
                                className="dataTables_info"
                                id="datatable_info"
                                role="status"
                                aria-live="polite"
                              >
                                Showing {totalCount == 0 ? 0 : totalCount}{" "}
                                entries
                              </div>
                            )}
                          </div>
                          <div className="col-sm-12 col-md-7">
                            <div className="dataTables_paginate paging_simple_numbers float-end mb-3" id="datatable_paginate">
                              <Pagination
                                count={Math.ceil(totalCount / 10)}
                                onChange={(event: any, value: any) => {
                                  setPageNumber(Number(value));
                                  setCurrentPage(Number(value));
                                }}
                                variant="outlined"
                                color="primary"
                                shape="rounded"
                                className="pagination-style"
                              />
                            </div>
                          </div>
                        </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
      {/* <SimpleModal
        contents={view_content}
        handleOpen={() => handleClickOpen}
        openModal={open}
        handleClose={(e: any) => handleClose(e)}
      /> */}
      {/* <ConfirmationModal contents="Are you sure you want to activate the user/account?" details_datas={user_id} openModal={open_modal} handleClose={(e: any) => handleClose_modal(e)} currentPage="suspend-user-list" /> */}
    </div>
  );
};

export default IPOCategoryList;
