import React, { useEffect, useState } from "react";
import Footer from "./Footer";
import Header from "./Header";
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import EnhancedTable from "./DataTable";
import EnhancedTableIsin from "./DataTableIsinDetails";
import { ServerAPI } from "./ServerAPI";
import { useNavigate, useParams } from "react-router-dom";

const IsindetailComponent = () => {
  const [orderData, setOrderData] = useState<any[]>([]);
  const { id, batch_no } = useParams();
  // console.log("params ", params?.id);
  const navigate = useNavigate();
  useEffect(() => {
    ServerAPI.ISINOrderHistorySpecific(id).then((res: any) => {
      setOrderData(res.data);
      console.log(res?.data?.[0]);
    });
  }, [id]);
  return (
    <>
      <div id="layout-wrapper">
        <Header />
        <div className="main-content py-4">
          <div className="page-content">
            <div className="container-fluid">
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: "5%",
                }}
              >
                <div>
                  <h2>{`Order Details Batch:${batch_no}`}</h2>
                </div>
                <div className="cursor-pointer" onClick={() => navigate(-1)}>
                  <p className="mb-0 active">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="14"
                      height="18"
                      viewBox="0 0 51.303 50.003"
                    >
                      <path
                        id="Path_22590"
                        fill="#A27B1E"
                        data-name="Path 22590"
                        d="M-1748.422,907.993a2.738,2.738,0,0,1-3.882,0l-22.259-22.248a2.736,2.736,0,0,1,0-3.882L-1752.3,859.6a2.738,2.738,0,0,1,3.882,0l2.542,2.542a2.751,2.751,0,0,1-.046,3.928l-13.8,13.145h32.908a2.741,2.741,0,0,1,2.748,2.748v3.664a2.742,2.742,0,0,1-2.748,2.748h-32.908l13.8,13.144a2.732,2.732,0,0,1,.046,3.928Z"
                        transform="translate(1775.371 -858.797)"
                      />
                    </svg>
                    <span> Back </span>
                  </p>
                </div>
              </div>
              <div
                className="card my-5"
                style={{
                  borderTopLeftRadius: "10px",
                  borderTopRightRadius: "10px",
                  paddingTop: "10px",
                }}
              >
                <div className="card-body">
                  <div className="row my-2">
                    <EnhancedTableIsin data={orderData} batch_no={batch_no}/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default IsindetailComponent;
