import React, { useEffect, useState } from "react";
import Header from "../common/Header";
import DatePicker from "react-date-picker";
import Footer from "../common/Footer";
import DataTable from "react-data-table-component";
import toast from "react-hot-toast";
import { useAppStateAPI } from "../common/AppStateAPI";
import { ServerAPI } from "../common/ServerAPI";
import { Pagination } from "@mui/material";
import { getCustomFormattedLocalDate } from "../common/Utilities";

const HolidayList = () => {
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const { setShowPreloader } = useAppStateAPI();
  const [totalCount, setTotalCount] = useState(10);
  const [holidayList, setHolidayList] = useState<any[]>([]);
  const customStyles = {
    headCells: {
      style: {
        backgroundColor: "#A27B1E",
        color: "white",
        fontSize: "14px",
      },
    },
  };
  const columns = [
    {
      name: "S.No",
      selector: (row: any, index: any) => index + 1,
      sortable: true,
    },
    {
      name: "Date",
      selector: (row: any) =>
        getCustomFormattedLocalDate(row.security_holiday_calendar_date),
      sortable: true,
    },
    {
      name: "Day",
      selector: (row: any) => {
        const date = new Date(row.security_holiday_calendar_date);
        return date.toLocaleDateString("en-US", { weekday: "long" }); // e.g., "Saturday"
      },
      sortable: true,
    },
    {
      name: "Status",
      selector: (row: any) =>
        row.security_holiday_calendar_status ? "Active" : "Inactive",
      sortable: true,
    },
    {
      name: "Weekend",
      selector: (row: any) =>
        row.security_holiday_calendar_weekend ? "Yes" : "No",
      sortable: true,
    },
  ];
  useEffect(() => {
    loadHolidayList();
  }, []);
  const loadHolidayList = () => {
    setShowPreloader(true);
    ServerAPI.holidayList(10)
      .then((res) => {
        try {
          setHolidayList(res);
        } catch (error) {
          toast.error(res.error);
        }
      })
      .finally(() => {
        setShowPreloader(false);
      });
  };
  const syncHoliday = () => {
    setShowPreloader(true);
    ServerAPI.syncHolidayApi()
      .then((res) => {
        if (res.status === 200) {
          toast.success(res.message);
          loadHolidayList();
        } else {
          // Handle success
          toast.error(res.message);
        }
      })
      .finally(() => {
        setShowPreloader(false);
      });
  };
  return (
    <div>
      <div id="layout-wrapper">
        <Header />
        <div className="main-content py-4">
          <div className="page-content">
            <div className="container-fluid">
              <div className="row">
                <div className="col-12">
                  <div className="page-title-box d-sm-flex align-items-center justify-content-between pt-4 pb-0">
                    {/* <h4 className="mb-sm-0 font-size-18 "> Suspend Users</h4> */}
                    <div className="page-title-right">
                      <ol className="breadcrumb ">
                        {/* <li className="breadcrumb-item">
                        <a style={{ color: "#90929E" }}>Trustmony</a>
                      </li> */}
                        <li className="breadcrumb-item">
                          <a> Master </a>
                        </li>
                        <li className="breadcrumb-item active ">
                          Calendar Master
                        </li>
                      </ol>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <div className="card">
                    <div className="card-body col-sm-12 col-md-12 col-lg-12 w-100">
                      {/* <div className="card-header col-xl-12 w-100"> */}
                      <div
                        className="row d-flex align-items-center"
                        style={{ paddingBottom: "10px" }}
                      >
                        <div className="col-xl-4">
                          <div className="d-flex justify-content-start align-items-center">
                            {/* <h4 className="card-title text-end p-2 m-0">
                              Search :
                            </h4>
                            <form className="app-search card-title p-2 m-0">
                              <div className="position-relative">
                                <input
                                  type="text"
                                  className="select-style-search"
                                  // onChange={(e) =>
                                  //   setSearchText(e.target.value)
                                  // }
                                  placeholder="search here..."
                                />
                              </div>
                            </form> */}
                          </div>
                        </div>

                        <div className="col-xl-8 text-end p-0">
                          <div className="d-flex justify-content-end align-items-center">
                            <div>
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  gap: "1rem",
                                }}
                              >
                                {/* Upload Button */}
                                <button
                                  className="xl_sheet mb-2"
                                  style={{
                                    backgroundColor: "#A27B1E",
                                    color: "white",
                                    borderRadius: "5px",
                                  }}
                                  onClick={syncHoliday}
                                >
                                  Sync
                                  <i
                                    className="fa fa-sync mx-1"
                                    aria-hidden="true"
                                  ></i>
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="table-rep-plugin">
                        <div
                          className="table-responsive "
                          data-pattern="priority-columns"
                        >
                          <DataTable
                            data={holidayList}
                            columns={columns}
                            noHeader
                            defaultSortFieldId="security_holiday_calendar_id"
                            sortIcon={<i className="fas fa-sort"></i>}
                            defaultSortAsc={true}
                            highlightOnHover
                            dense
                            customStyles={customStyles}
                          />
                        </div>
                        {/* <div className="row mt-3">
                          <div className="col-sm-12 col-md-5">
                            {totalCount > 10 ? (
                              <div
                                className="dataTables_info"
                                id="datatable_info"
                                role="status"
                                aria-live="polite"
                              >
                                Showing {holidayList.length} of {totalCount}{" "}
                                entries
                              </div>
                            ) : (
                              <div
                                className="dataTables_info"
                                id="datatable_info"
                                role="status"
                                aria-live="polite"
                              >
                                Showing{" "}
                                {holidayList?.length === 0
                                  ? 0
                                  : holidayList?.length}{" "}
                                entries
                              </div>
                            )}
                          </div>
                          <div className="col-sm-12 col-md-7">
                            <div
                              className="dataTables_paginate paging_simple_numbers float-end mb-3"
                              id="datatable_paginate"
                            >
                              <Pagination
                                count={Math.ceil(totalCount / 10)}
                                onChange={(event: any, value: any) => {
                                  // setPageNumber(Number(value));
                                  // setCurrentPage(Number(value));
                                }}
                                variant="outlined"
                                color="primary"
                                shape="rounded"
                                className="pagination-style"
                              />
                            </div>
                          </div>
                        </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
      {/* <SimpleModal
        contents={view_content}
        handleOpen={() => handleClickOpen}
        openModal={open}
        handleClose={(e: any) => handleClose(e)}
      /> */}
      {/* <ConfirmationModal contents="Are you sure you want to activate the user/account?" details_datas={user_id} openModal={open_modal} handleClose={(e: any) => handleClose_modal(e)} currentPage="suspend-user-list" /> */}
    </div>
  );
};

export default HolidayList;
