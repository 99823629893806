import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Grid,
} from "@mui/material";
import React, { useState, useEffect, useRef } from "react";
import toast from "react-hot-toast";
import { Form, useNavigate } from "react-router-dom";
import { PageLinks } from "../common/Constants";
import ConfirmationModal from "./Confirmation-modal";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import DatePicker from "react-datepicker";
import { APIData } from "../common/DataTypes";
import { InitialData } from "../common/InitialValue";
import { Formik, FormikProps } from "formik";
import { ServerAPI } from "../common/ServerAPI";
import * as Yup from "yup";
import { useAppStateAPI } from "../common/AppStateAPI";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import moment from "moment";

interface modalDatas {
  openModal?: any;
  handleClose?: any;
  handleOpen?: any;
  loadData?: any;
  listReload?: any;
}

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 350,
  bgcolor: "background.paper",
  border: "2px solid #fff",
  boxShadow: 24,
  p: 2,
  borderRadius: 3,
};
const SecuritiesInventory: React.FC<modalDatas> = ({
  handleOpen,
  openModal,
  handleClose,
  loadData,
  listReload,
}) => {
  // console.log("🚀 ~ loadData11:", loadData);
  const [open, setOpen] = useState(false);
  const [open_modal, setOpenModal] = useState(false);
  const { setShowPreloader, setActiveDealerTab } = useAppStateAPI();
  const navigate = useNavigate();
  const [InverntoryQty, setInverntoryQty] = useState<any>(0);
  const formikRef = useRef<FormikProps<APIData.CustomerEnquire>>(null);
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({});
  useEffect(() => {
    if (openModal === true) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [openModal]);

  const handleCloses = () => {
    setOpen(false);
    // setOpenModal(false)
    handleClose(false);
  };
  const handleClosed = (e: any) => {
    setOpenModal(e);
    setOpen(e);
    handleClose(e);
  };

  const updateInventory = () => {
    setShowPreloader(true);
    ServerAPI.updateAddInventory(loadData, InverntoryQty)
      .then((res) => {
        console.log("response from then:", res);
        toast.success(res?.message);
        setActiveDealerTab("active_bonds");
        handleClose(false);
        listReload("active_bonds");

        // try {
        //   ServerAPI.ActiveBond(loadData.security_id, 1)
        //     .then((res: any) => {
        //       setActiveDealerTab("active_bonds");
        //       handleClose(false);
        //       listReload("active_bonds");
        //       toast.success(res.message);
        //     })
        //     .finally(() => {
        //       setShowPreloader(false);
        //     });
        //   setOpen(false);
        //   handleClose(false);
        // } catch (error) {
        //   console.log("🚀 ~ .then ~ error:", error);
        //   toast.error(res.error);
        // }
      })
      .finally(() => {
        setShowPreloader(false);
      });
  };

  return (
    <div className="dialog">
      <Modal
        open={open}
        className="dialog-box"
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Box sx={style}>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            className="bond_enquiry_details"
          >
            <form onSubmit={handleSubmit(updateInventory)}>
              <div className="col-lg-12">
                <label htmlFor="quantity" className="bond-label">
                  Quantity
                </label>

                <input
                  type="text"
                  className="bond-input"
                  onChange={(e: any) => {
                    setInverntoryQty(e.target.value);
                  }}
                  name="quantity"
                  id="quantity"
                  placeholder="Enter quantity"
                  required
                />
                <div className="d-flex mt-3 justify-content-center">
                  <a
                    className="cancel-btn mx-1 px-3 ms-3"
                    onClick={() => handleCloses()}
                  >
                    Cancel
                  </a>
                  <button type="submit" className=" save-btn px-3">
                    Update
                  </button>
                </div>
              </div>
            </form>
          </Typography>
        </Box>
      </Modal>
    </div>
  );
};

export default SecuritiesInventory;
