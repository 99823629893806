import * as React from "react";
import { alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import DeleteIcon from "@mui/icons-material/Delete";
import FilterListIcon from "@mui/icons-material/FilterList";
import { visuallyHidden } from "@mui/utils";
import { getCustomFormattedLocalDate, getDisplayData, getFormattedLocalDateTime } from "./Utilities";

const defaultRows = [
  {
    id: 1,
    orderId: "2024258195",
    orderType: "Normal Order",
    amount: "\u20b9 402,863.01",
    units: 4,
    price: "\u20b9 100",
    settlementDate: "24-July-2024",
    dealDate: "23-July-2024",
    orderDateTime: "23-July-2024 03:45:27 PM",
    orderStatus: "Success",
    rfqStatus: "NA",
  },
  {
    id: 2,
    orderId: "2024256772",
    orderType: "Normal Order",
    amount: "\u20b9 100,715.75",
    units: 1,
    price: "\u20b9 100",
    settlementDate: "24-July-2024",
    dealDate: "23-July-2024",
    orderDateTime: "23-July-2024 03:49:01 PM",
    orderStatus: "Success",
    rfqStatus: "RFQ Added",
  },
];

function EnhancedDetailTable({ data = defaultRows }) {
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("orderId");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const rows = data;

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  // const handleSelectAllClick = (event) => {
  //   if (event.target.checked) {
  //     const newSelected = rows?.map((n) => n.id);
  //     setSelected(newSelected);
  //     return;
  //   }
  //   setSelected([]);
  // };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const visibleRows = React.useMemo(
    () =>
      [...rows]
        .sort((a, b) => {
          const valueA = a[orderBy] ?? "";
          const valueB = b[orderBy] ?? "";

          if (valueA < valueB) {
            return order === "asc" ? -1 : 1;
          }
          if (valueA > valueB) {
            return order === "asc" ? 1 : -1;
          }
          return 0;
        })
        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage),
    [order, orderBy, page, rowsPerPage, rows]
  );

  return (
    <Box sx={{ width: "100%" }}>
      <Paper sx={{ width: "100%", mb: 2 }}>
        <Toolbar>
          <Typography
            sx={{ flex: "1 1 100%" }}
            variant="h6"
            id="tableTitle"
            component="div"
          >
            Order Details
          </Typography>
        </Toolbar>
        <TableContainer>
          <Table>
            <TableHead sx={{ backgroundColor: "#A27B1E"}}> 
              <TableRow >
                <TableCell sx={{color:"white"}}>Order ID</TableCell>
                <TableCell sx={{color:"white"}}>Order Type</TableCell>
                <TableCell sx={{color:"white"}}>Amount Unit(s)</TableCell>
                <TableCell sx={{color:"white"}}>Price</TableCell>
                <TableCell sx={{color:"white"}}>Settlement Date</TableCell>
                <TableCell sx={{color:"white"}}>Order Status</TableCell>
                <TableCell sx={{color:"white"}}>Deal Date</TableCell>
                <TableCell sx={{color:"white"}}>Order Date & Time</TableCell>
                <TableCell sx={{color:"white"}}>RFQ Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {visibleRows?.map((row) => {
                const isItemSelected = selected?.includes(row.id);
                const labelId = `enhanced-table-checkbox-${row.id}`;

                return (
                  <TableRow
                    hover
                    // onClick={(event) => handleClick(event, row.id)}
                    // role="checkbox"
                    // aria-checked={isItemSelected}
                    // tabIndex={-1}
                    // key={row.id}
                    // selected={isItemSelected}
                  >
                    {/* <TableCell padding="checkbox">
                      <Checkbox
                        color="primary"
                        checked={isItemSelected}
                        inputProps={{ "aria-labelledby": labelId }}
                      />
                    </TableCell> */}
                    <TableCell>{row?.order_id}</TableCell>
                    <TableCell>
                      {row?.trade?.trade_name}
                    </TableCell>
                    <TableCell>{row?.order_quantity}</TableCell>
                    <TableCell>{row?.order_price}</TableCell>
                    <TableCell>{getCustomFormattedLocalDate(row?.order_settlement_date)}</TableCell>
                    <TableCell>{  <b
                              className={`${
                                row?.order_status_uid === "ORD_RFQ_ACC" ||
                                row?.order_status_uid === "ORD_ACC" ||
                                row?.order_status_uid === "ORD_RFQ_ACC"
                                  ? "success-capsule"
                                  : "failed-capsule"
                              }`}
                            >
                              {getDisplayData(row?.order_status)}
                            </b>}</TableCell>
                    <TableCell>{getCustomFormattedLocalDate(row?.order_deal_date)}</TableCell>
                    <TableCell>{getFormattedLocalDateTime(row?.order_created_at)}</TableCell>
                    {/* <TableCell>{row?.order_status}</TableCell> */}
                   
                    <TableCell sx={{ color: row?.order_status_uid === 'ORD_RFQ_ACC' 
                                               ? '#1EE0AC' 
                                               : row?.order_status_uid === 'ORD_RFQ_REJ' 
                                                 ? '#E85347' 
                                                 : 'inherit' }}>
                       {row?.order_status_uid === 'ORD_RFQ_ACC' 
                         ? "Accepted" 
                         : row?.order_status_uid === 'ORD_RFQ_REJ' 
                           ? "Rejected" 
                           : "--"}
                     </TableCell>

                  </TableRow>
                );
              })}
              {emptyRows > 0 && (
                <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {/* Parent container (e.g., TableFooter) */}
<div style={{ display: 'flex', justifyContent: 'flex-end' }}>
  <TablePagination
    rowsPerPageOptions={[5, 10, 15]}
    component="div"
    count={rows.length}
    rowsPerPage={rowsPerPage}
    page={page}
    onPageChange={handleChangePage}
    onRowsPerPageChange={handleChangeRowsPerPage}
    sx={{
      '& .MuiTablePagination-toolbar': {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end', // Right-align all elements
        padding: 0,
        minHeight: 'auto',
      },
      '& .MuiTablePagination-spacer': {
        flex: 'none', // Disable default spacer behavior
      },
      '& .MuiTablePagination-actions': {
        marginLeft: 1, // Add small spacing between elements
      },
      '& .MuiTablePagination-selectLabel': {
        margin: 0,
        marginRight: 1, // Add spacing between label and dropdown
      },
      '& .MuiTablePagination-displayedRows': {
        margin: 0,
        marginLeft: 1, // Add spacing between dropdown and page info
      }
    }}
  />
</div>
      </Paper>
      {/* <FormControlLabel
        control={<Switch checked={dense} onChange={handleChangeDense} />}
        label="Dense padding"
      /> */}
    </Box>
  );
}

export default EnhancedDetailTable;
