import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { PageLinks } from "../common/Constants";
import ConfirmationModal from "./Confirmation-modal";
import { useAppStateAPI } from "../common/AppStateAPI";

interface modalDatas {
  openModal?: any;
  handleClose?: any;
  handleOpen?: any;
  userId?: any;
  // currentPage?:any
  // handleOpen
  reload?: any;
  account_status_detail?: any;
  remarkList?: any;
}

const AccountStatus: React.FC<modalDatas> = ({
  handleOpen,
  userId,
  openModal,
  handleClose,
  reload,
  account_status_detail,
  remarkList,
}) => {
  const [open, setOpen] = useState(false);
  const [open_modal, setOpenModal] = useState(false);
  const { setUpdateAccountStatus } = useAppStateAPI();
  const [all_datas, setAllDatas] = useState({
    // userId:userId,
    user_status: null,
    account_status_reason: null,
    account_status_remarks: "",
  });
  // const [select_status, setSelectStatus] = useState<any>(1)

  useEffect(() => {
    if (openModal === true) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [openModal]);

  useEffect(() => {
    setAllDatas((prev) => ({
      ...prev, // Keeps the existing values
      user_status: account_status_detail?.user_status || prev.user_status,
      account_status_reason:
        account_status_detail?.account_status_reason ||
        prev.account_status_reason,
      account_status_remarks:
        account_status_detail?.account_status_remarks ||
        prev.account_status_remarks,
    }));
  }, [userId]);

  const handleCloses = () => {
    setOpen(false);
    // setOpenModal(false)
    setAllDatas({
      user_status: null,
      account_status_reason: null,
      account_status_remarks: "",
    });
    handleClose(false);
  };
  const handleClosed = (e: any) => {
    setOpenModal(e);
    setOpen(e);
    handleClose(e);
    setAllDatas({
      user_status: null,
      account_status_reason: null,
      account_status_remarks: "",
    });
  };

  // useEffect(()=>{
  //     console.log(all_datas);

  // },[all_datas])

  const update_status = (e: any) => {
    setAllDatas({
      ...all_datas,
      [e.target.name]: e.target.value,
      account_status_reason: e.target.value,
    });
  };
  // const update_status = (e: any) => {
  //   const { name, value } = e.target;
  //   setAllDatas((prev) => ({
  //     ...prev,
  //     [name]: value,
  //     account_status_reason:
  //       name === "user_status" ? value : prev.account_status_reason, // Prevent overwriting reason
  //   }));
  // };

  const handleRemarkInput = (e: any) => {
    setAllDatas({
      ...all_datas,
      account_status_remarks: e.target.value,
    });
  };

  console.log("all_datas", all_datas);

  const save_modal = () => {
    if (all_datas?.user_status == null) {
      alert("Please select the status");
    } else {
      setOpen(false);
      setOpenModal(true);
      handleClose(false);
    }
  };

  const handleReload = (e: any) => {
    if (e === "success") {
      setUpdateAccountStatus(true);
      setAllDatas({
        user_status: null,
        account_status_reason: null,
        account_status_remarks: "",
      });
      reload(true);
    }
  };

  return (
    <div className="dialog">
      <Dialog
        open={open}
        className="dialog-box"
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" className="model-title">
          <div className="modal-box-title">
            <p className="m-0 quicksand-medium">Account Status</p>
            <p className="m-0" onClick={handleCloses}>
              <i
                className="fa fa-times cursor-pointer font-style"
                aria-hidden="true"
              ></i>
            </p>
          </div>
        </DialogTitle>
        <hr />

        <DialogContent>
          <div className="col-lg-12 col-md-12 col-sm-12">
            <form>
              <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12 mb-2">
                  <label className="quicksand-medium">Status</label>
                  <select
                    className="select-style"
                    value={all_datas?.user_status || ""}
                    name="user_status"
                    onChange={(e) => update_status(e)}
                  >
                    <option value={0}>Inprogress</option>
                    <option value={1}>Active</option>
                    <option value={2}>Reject</option>
                    <option value={3}>Suspend</option>
                    <option value={10}>Pending</option>
                    <option value={4}>Close</option>
                  </select>
                </div>
                <div className="col-lg-12 col-md-12 col-sm-12 mb-2">
                  <label className="quicksand-medium">Select Reason</label>
                  <select
                    className="select-style"
                    value={all_datas?.account_status_reason || ""}
                    placeholder="Select From drop down"
                    name="account_status_reason"
                    onChange={(e) => update_status(e)}
                  >
                    {/* <option value={0}> Registration completed</option>
                    <option value={1}>When the User completes his KYC</option>
                    <option value={2}>
                      {" "}
                      User KYC is not complete / User details are missing or not
                      up to the crite-ria
                    </option>
                    <option value={3}> Any malicious activity / fraud</option>
                    <option value={4}>
                      Admin can use this to permanently close users account
                    </option> */}
                    {remarkList?.map((remark: any) => {
                      return (
                        <option value={remark?.remarks_id}>
                          {remark?.remarks_name}
                        </option>
                      );
                    })}
                    <option value={"oth"}>Other</option>
                  </select>
                </div>

                <div
                  className={`col-lg-12 col-md-12 col-sm-12 ${
                    all_datas?.account_status_reason !== "oth"
                      ? "d-none"
                      : "d-block"
                  }`}
                >
                  <label className="quicksand-medium">Remarks</label>
                  <textarea
                    className=" form-control form-control-style"
                    name="account_status_remarks"
                    onChange={(e) => handleRemarkInput(e)}
                    rows={4}
                    cols={50}
                    maxLength={100}
                    placeholder="100 words max*"
                  />
                </div>
                <div className="col-lg-12 col-md-12 col-sm-12 mx-3 my-3 py-2 px-2 d-flex justify-content-around align-items-center">
                  <a className="cancel-btn" onClick={handleCloses}>
                    Cancel
                  </a>
                  <a className="save-btn" onClick={() => save_modal()}>
                    Submit
                  </a>
                </div>
              </div>
            </form>
          </div>
        </DialogContent>
      </Dialog>
      <ConfirmationModal
        contents="Are you sure you want to confirm this?"
        details_datas={all_datas}
        currentPage="update-status"
        openModal={open_modal}
        handleClose={(e: any) => handleClosed(e)}
        user_id={userId}
        listReload={(e: any) => handleReload(e)}
      />
    </div>
  );
};

export default AccountStatus;
