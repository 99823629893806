import Pagination from "@mui/material/Pagination";
import { Field, Form, Formik, FormikProps } from "formik";
import moment from "moment";
import React, { useCallback, useEffect, useRef, useState } from "react";
// import DatePicker from 'react-date-picker';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useAppStateAPI } from "../common/AppStateAPI";
import {
  BondIPOStatus,
  Depository,
  NCDDetailsList,
  PageLinks,
  NCDStatus,
  Strings,
  wysiwygToolbar,
} from "../common/Constants";
import Footer from "../common/Footer";
import Header from "../common/Header";
import { ServerAPI } from "../common/ServerAPI";
import ConfirmationModal from "../modals/Confirmation-modal";
import SimpleModal from "../modals/SimpleModal";
import * as Yup from "yup";
import { Grid, Input, SvgIcon, Box } from "@mui/material";
import FormField from "./form_items/FormField";
import { APIData, FormDataTypes } from "../common/DataTypes";
import dayjs, { Dayjs } from "dayjs";
import TextField from "@mui/material/TextField";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import {
  EditorState,
  convertToRaw,
  convertFromRaw,
  convertFromHTML,
  ContentState,
} from "draft-js";
import draftToHtml from "draftjs-to-html";
import { InitialData } from "../common/InitialValue";
import toast from "react-hot-toast";
import {
  frameSelectOptions,
  getURLExtension,
  localStringToIST,
} from "../common/Utilities";
import { useDropzone } from "react-dropzone";

const BondAdd = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const passing_value = location.state;
  const [preData, setPreData] = useState(passing_value);

  // const [selectedArray, setSelectedArray] = useState([]);

  // Handle tab selection
  const handleTabClick = (item: any) => {
    setFormikInitialData((prevData) => {
      // Retrieve the current security_debt_quality array or initialize it as empty
      const currentQuality = prevData.security_debt_quality || [];

      // Check if the item is already in the array
      const isSelected = currentQuality.some(
        (quality: any) => quality.debt_quality_id === item.debt_quality_id
      );

      // Remove unwanted fields and rename debt_quality_status to security_debt_quality_status
      const {
        debt_quality_description,
        debt_quality_icon,
        debt_quality_name,
        debt_quality_status,
        debt_quality, // This is the object we need to remove if it exists
        ...filteredItem
      } = item;

      // Rename debt_quality_status to security_debt_quality_status
      const updatedItem = {
        ...filteredItem,
        security_debt_quality_status: debt_quality_status, // Renaming here
      };

      // Add or remove the item based on selection
      const updatedQuality = isSelected
        ? currentQuality.filter(
            (quality: any) => quality.debt_quality_id !== item.debt_quality_id
          )
        : [...currentQuality, updatedItem];

      // Return the updated formikInitialData
      return {
        ...prevData,
        security_debt_quality: updatedQuality,
      };
    });
  };

  // const [value, onChange] = useState(new Date());
  const [toData, setToData] = useState<null | Date>();
  const [fromDate, setFromDate] = useState<null | Date>();
  const [open, setOpen] = useState(false);
  const [open_modal, setOpenModal] = useState(false);
  const [userList, setUserList] = useState<any[]>([]);
  const [totalCount, setTotalCount] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [user_id, setUserId] = useState();
  const [view_content, setViewContent] = useState();
  const [search_text, setSearchText] = useState("");
  const { setShowPreloader } = useAppStateAPI();

  const fileInputRef = useRef<HTMLInputElement>(null);
  const fileInputRefLogo = useRef<HTMLInputElement>(null);
  const fileInputRefRatingCertificate = useRef<HTMLInputElement>(null);
  const [formikInitialData, setFormikInitialData] = useState<APIData.BondAdd>(
    InitialData.BondAdd
  );
  const [NDCList, setNDCList] = useState<any[]>(NCDDetailsList);
  const [uploadFeild, setUploadFeild] = useState<{
    feild_type: string;
    inx: number;
  }>({ feild_type: "", inx: 0 });

  const [addIssuerAbout, setAddIssuerAbout] = useState<any | undefined>(
    EditorState.createEmpty()
  );
  const [addComments, setAddComments] = useState<any | undefined>(
    EditorState.createEmpty()
  );
  const [addComments_text, setAddComments_text] = useState("");
  const formikRef = useRef<FormikProps<APIData.BondAdd>>(null);
  const [addKeyHeighlights, setAddKeyHeighlights] = useState<any | undefined>(
    EditorState.createEmpty()
  );
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [content, setContent] = useState<any>();
  const [bondID, setBondID] = useState(0);
  const [bondRatingOptions, setBondRatingOptions] = useState<
    FormDataTypes.SelectOption[]
  >([]);
  const [prevDate, setPrevDate] = useState<any>();
  const [dynamicText, setDynamicText] = useState("");
  const [Highlight, setHighlight] = useState("");
  const [about, setAbout] = useState<any>();
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [removedDocuments, setRemovedDocuments] = useState<string[]>([]);

  const onDrop = useCallback((acceptedFiles: File[], fileRejections: any[]) => {
    acceptedFiles.map((file: any) => {
      loadUploadedfile(file);
    });
    fileRejections.forEach((file: any) => {
      file.errors.forEach((err: any) => {
        if (err.code === "dimension") {
          toast.error(`Error: ${err.message}`);
        }
      });
    });
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    multiple: true,
  });

  const loadUploadedfile = (file: any) => {
    setShowPreloader(true);
    // ServerAPI.setUploadedFile(file);
    // .then((response) => {
    //   if (response.filename != undefined && response.filename != "") {
    //     console.log(dmp);
    //     if (formikRef.current?.values) {
    //       var currValues: APIData.BondAdd = formikRef.current.values;
    //       if (dmp != undefined && dmp == "bond_logo") {
    //         currValues.bond_logo = response.filename;
    //       } else if (dmp !== undefined && dmp == "bond_issuer_documents") {
    //         currValues.bond_issuer_details[0].bond_issuer_documents =
    //           response.filename;
    //       } else if (dmp !== undefined && dmp == "bond_rating_certificate") {
    //         currValues.bond_rating_details[dmpIndex].bond_rating_certificate =
    //           response.filename;
    //       }
    //       setFormikInitialData((e) => ({ ...e, currValues }));
    //     }
    //   }
    // })
    // .finally(() => {
    //   setShowPreloader(false);
    // });
  };

  useEffect(() => {
    if (location.state.mode == "edit") {
      // console.log("asdsddfsfdfd",location.state);

      loadBondDetail(location.state.id);
      // loadIntitalBOND(location.state.id);
      // loadBondRatingList();
    }
    // if (location.state.mode == "add") {
    //   loadBrandTags();
    //   loadEligibleInvestor();
    // }
  }, []);

  const loadBondDetail = (isin_number: string) => {
    if (isin_number !== "") {
      setShowPreloader(true);
      ServerAPI.bondDetail(isin_number)
        .then((res) => {
          if (res?.data && res.data[0]) {
            const bondDetails = res.data[0];
            const initialHTMLContent =
              bondDetails?.issuer?.security_issuer_highlight ?? "";

            // Convert HTML to Draft.js ContentState
            const blocksFromHTML = convertFromHTML(initialHTMLContent);
            const contentState = ContentState.createFromBlockArray(
              blocksFromHTML.contentBlocks
            );

            // Set the initial editor state
            setEditorState(EditorState.createWithContent(contentState));

            // Set form values based on the response
            setFormikInitialData({
              security_id: bondDetails.security_id ?? null,
              security_name: bondDetails.security_name ?? null,
              security_isin: bondDetails.security_isin ?? null,
              security_logo: bondDetails.security_logo ?? null,
              security_face_value: bondDetails.security_face_value ?? null,
              security_rating: bondDetails.security_rating ?? null,
              security_rating_agency:
                bondDetails.security_rating_agency ?? null,
              security_call_date: bondDetails.security_call_date ?? null,
              security_put_date: bondDetails.security_put_date ?? null,
              security_maturity_date:
                bondDetails.security_maturity_date ?? null,
              security_coupon_rate: bondDetails.security_coupon_rate ?? null,
              security_issue_price: bondDetails.security_issue_price ?? null,
              security_issue_date: bondDetails.security_issue_date ?? null,
              security_first_interest_date:
                bondDetails.security_first_interest_date ?? null,
              security_noi: bondDetails.security_noi ?? null,
              security_status: bondDetails.security_status ?? null,
              security_type_id: bondDetails.security_type_id ?? null,
              security_interest_frequency_id:
                bondDetails.security_interest_frequency_id ?? null,
              security_norm_compound_flag:
                bondDetails.security_norm_compound_flag ?? null, // Ensure this property is included
              issuer: {
                security_issuer_id:
                  bondDetails.issuer.security_issuer_id ?? null,
                security_issuer_name:
                  bondDetails.issuer.security_issuer_name ?? null,
                security_issuer_logo:
                  bondDetails.issuer.security_issuer_logo ?? null,
                security_issuer_industry:
                  bondDetails.issuer.security_issuer_industry ?? null,
                security_issuer_founded_year:
                  bondDetails.issuer.security_issuer_founded_year ?? null,
                security_issuer_head_office:
                  bondDetails.issuer.security_issuer_head_office ?? null,
                security_issuer_ownership:
                  bondDetails.issuer.security_issuer_ownership ?? null,
                security_issuer_sector:
                  bondDetails.issuer.security_issuer_sector ?? null,
                security_issuer_types:
                  bondDetails.issuer.security_issuer_types ?? null,
                security_issuer_cnt_authority:
                  bondDetails.issuer.security_issuer_cnt_authority ?? null,
                security_issuer_highlight:
                  bondDetails.issuer.security_issuer_highlight ?? null,
                security_issuer_type_id:
                  bondDetails.issuer.security_issuer_type_id ?? null,
                issuer_document: bondDetails.issuer.issuer_document ?? null,

                security_issuer_tax_free:
                  bondDetails.issuer.security_issuer_tax_free ?? null,
                security_issuer_seniority:
                  bondDetails.issuer.security_issuer_seniority ?? null,
                security_issuer_moi:
                  bondDetails.issuer.security_issuer_moi ?? null,
                security_issuer_debenture_trustee:
                  bondDetails.issuer.security_issuer_debenture_trustee ?? null,
                exchange_id: bondDetails.issuer.exchange_id ?? null,
                security_issuer_coupon_type:
                  bondDetails.issuer.security_issuer_coupon_type ?? null,
              },
              security_debt_quality: bondDetails.security_debt_quality ?? null,
            });
          }
        })
        .finally(() => {
          setShowPreloader(false);
        });
    }
  };

  const debtQualityData = [
    {
      debt_quality_description: "Default Description",
      debt_quality_icon: null,
      debt_quality_id: 1,
      debt_quality_name: "Security",
      debt_quality_status: true,
    },
    {
      debt_quality_description: "Default Description",
      debt_quality_icon: null,
      debt_quality_id: 2,
      debt_quality_name: "Listed",
      debt_quality_status: true,
    },
    {
      debt_quality_description: "Default Description",
      debt_quality_icon: null,
      debt_quality_id: 3,
      debt_quality_name: "Seniority",
      debt_quality_status: true,
    },
  ];

  const handleTextareaChange = (
    debtQualityId: number,
    newDescription: string
  ) => {
    // Update the local state (formikInitialData)
    const updatedDebtQuality = formikInitialData.security_debt_quality.map(
      (item: any) =>
        item.debt_quality_id === debtQualityId
          ? { ...item, debt_quality_description: newDescription }
          : item
    );

    // Update the formikInitialData state
    setFormikInitialData({
      ...formikInitialData,
      security_debt_quality: updatedDebtQuality,
    });
  };

  const handleFileChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    uploadDocFor: any
  ) => {
    // console.log("sdffsdfsf", uploadDocFor);

    const files = event.target.files;

    if (files && files.length > 0) {
      const file = files[0];

      // Log the selected file details
      setSelectedFile(file);
      handleUploadClick(file, uploadDocFor);
    }

    // Reset file input to allow reselecting the same file
    event.target.value = "";
  };

  const handleUploadClick = (file: File | null, uploadDocFor: any) => {
    // console.log("sdfsfsdfsd", uploadDocFor);

    if (file) {
      // Perform API call
      setShowPreloader(true);

      ServerAPI.uploadIssuerFiles(file)
        .then((res) => {
          if (res.status === 200) {
            // Handle success
            console.log("File uploaded successfully, URL:", res.data[0].url);
            if (uploadDocFor === "issuerDoc") {
              // Filter out documents that were marked as removed
              const updatedIssuerDocuments = [
                ...formikInitialData.issuer.issuer_document.filter(
                  (doc: any) =>
                    !removedDocuments.includes(
                      doc.security_issuer_document_file
                    )
                ),
                {
                  security_issuer_document_name: null,
                  security_issuer_document_file: res.data[0].url, // Add the new document
                  security_issuer_document_id: null,
                  security_issuer_id: null,
                  security_issuer_document_status: false,
                },
              ];

              // Update the state with the modified issuer documents
              setFormikInitialData((prevState: any) => ({
                ...prevState,
                issuer: {
                  ...prevState.issuer,
                  issuer_document: updatedIssuerDocuments,
                },
              }));
            }
            if (uploadDocFor == "security_logo") {
              setFormikInitialData((prevState) => ({
                ...prevState,
                security_logo: res.data[0].url,
              }));
            }
            if (uploadDocFor == "rating_certificate") {
              setFormikInitialData((prevState) => ({
                ...prevState,
                security_logo: res.data[0].url,
              }));
            }

            // Get existing documents and filter out the removed ones

            setSelectedFile(null); // Clear the selected file
            // toast.success("File uploaded successfully.");
          } else {
            // Handle error responses
            toast.error(
              res.message || "An error occurred while uploading the file."
            );
          }
        })
        .catch((err) => {
          // Handle exceptions
          const errorMessage = err?.message || "Upload failed.";
          toast.error(errorMessage);
        })
        .finally(() => {
          setShowPreloader(false);
        });
    } else {
      // Open dialog if no file is provided
      if (uploadDocFor == "issuerDoc") {
        fileInputRef.current?.click();
      }
      if (uploadDocFor == "security_logo") {
        fileInputRefLogo.current?.click();
      }
      if (uploadDocFor == "rating_certificate") {
        fileInputRefRatingCertificate.current?.click();
      }
    }
  };

  return (
    <div>
      <div id="layout-wrapper">
        <Header />
        <div className="main-content py-4">
          <div className="page-content">
            <div className="container-fluid">
              <div className="row">
                <div className="col-12">
                  <div className="page-title-box d-sm-flex align-items-center justify-content-between pt-4 pb-0">
                    <div className="page-title-right">
                      <ol className="breadcrumb ">
                        <li className="breadcrumb-item">
                          {/* <a style={{ color: "#90929E" }}>Trustmony</a> */}
                        </li>
                        <li className="breadcrumb-item">
                          <a> Master </a>
                        </li>
                        <li className="breadcrumb-item active ">Bond Edit</li>
                      </ol>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <div className="card">
                    <div className="card-body col-sm-12 col-md-12 col-lg-12 w-100">
                      <Formik
                        enableReinitialize={true}
                        initialValues={formikInitialData}
                        validateOnChange={false}
                        onSubmit={(
                          values,
                          { setSubmitting, resetForm, setFieldValue }
                        ) => {}}
                      >
                        {(formikProps) => {
                          return (
                            <Form onSubmit={formikProps.handleSubmit}>
                              <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <div className="row">
                                  <div
                                    className="card"
                                    style={{
                                      borderTopLeftRadius: "10px",
                                      borderTopRightRadius: "10px",
                                      paddingTop: "10px",
                                      paddingBottom: "20px",
                                    }}
                                  >
                                    {/* <div className="card-header d-flex justify-content-between">
                <h5 className="m-0 header">
                  Edit Bond
                </h5>
              </div> */}
                                    <div className="card-header d-flex justify-content-between">
                                      <h5 className="m-0 header">
                                        {" "}
                                        {location.state?.mode != undefined &&
                                        location.state.mode == "edit"
                                          ? "Edit"
                                          : "Add"}{" "}
                                        New Bond{" "}
                                      </h5>
                                      <div className="form-check form-switch mx-2">
                                        <label
                                          className="form-check-label"
                                          htmlFor="distributorSubTypeChecked"
                                        >
                                          Disable/Enable
                                        </label>
                                        <input
                                          className="form-check-input "
                                          type="checkbox"
                                          role="switch"
                                          id="distributorSubTypeChecked"
                                          onChange={(e) => {
                                            setFormikInitialData({
                                              ...formikInitialData,
                                              security_status:
                                                e.target.checked == true
                                                  ? 1
                                                  : 0,
                                            });
                                          }}
                                          checked={
                                            formikProps.values
                                              ?.security_status ==
                                            BondIPOStatus.Active
                                              ? true
                                              : false
                                          }
                                        />
                                      </div>
                                    </div>
                                    <div className="card-body">
                                      <Grid container spacing={2}>
                                        {/* Security Type */}
                                        <Grid item xs={4}>
                                          <div className="d-grid">
                                            <label
                                              htmlFor="security_type_id"
                                              className="bond-label"
                                            >
                                              Security Type
                                            </label>
                                            <select
                                              disabled
                                              id="security_type_id"
                                              name="security_type_id"
                                              value={
                                                formikProps.values
                                                  .security_type_id || ""
                                              }
                                              onChange={(e) =>
                                                setFormikInitialData(
                                                  (prevState: any) => ({
                                                    ...prevState,
                                                    security_type_id:
                                                      e.target.value, // Update the specific field with the input value
                                                  })
                                                )
                                              }
                                              className="bond-input-select"
                                            >
                                              <option value="" disabled>
                                                Select Security Type
                                              </option>
                                              <option value="1">IPO</option>
                                              <option value="2">Bond</option>
                                              <option value="3">SGB</option>
                                            </select>
                                          </div>
                                        </Grid>

                                        <Grid item xs={4}>
                                          <div className="d-grid">
                                            <label
                                              htmlFor="security_name"
                                              className="bond-label"
                                            >
                                              ISIN
                                            </label>
                                            <input
                                              disabled
                                              id="security_name"
                                              name="security_name"
                                              type="text"
                                              className="bond-input"
                                              value={
                                                formikProps.values
                                                  .security_isin || ""
                                              }
                                              // onChange={(e) =>
                                              //   setFormikInitialData(
                                              //     (prevState: any) => ({
                                              //       ...prevState,
                                              //       security_name:
                                              //         e.target.value, // Update the specific field with the input value
                                              //     })
                                              //   )
                                              // }
                                            />
                                          </div>
                                        </Grid>
                                        <Grid item xs={4}>
                                          <div className="d-grid">
                                            <label
                                              htmlFor="security_name"
                                              className="bond-label"
                                            >
                                              Security Name
                                            </label>
                                            <input
                                              id="security_name"
                                              name="security_name"
                                              type="text"
                                              className="bond-input"
                                              value={
                                                formikProps.values
                                                  .security_name || ""
                                              }
                                              onChange={(e) =>
                                                setFormikInitialData(
                                                  (prevState: any) => ({
                                                    ...prevState,
                                                    security_name:
                                                      e.target.value, // Update the specific field with the input value
                                                  })
                                                )
                                              }
                                            />
                                          </div>
                                        </Grid>

                                        <Grid item xs={4}>
                                          <div className="d-grid">
                                            <label
                                              htmlFor="security_noi"
                                              className="bond-label"
                                            >
                                              Nature of Instrument
                                            </label>
                                            {/* <input
                                              id="security_noi"
                                              name="security_noi"
                                              type="text"
                                              className="bond-input"
                                              value={
                                                formikProps.values
                                                  .security_noi || ""
                                              }
                                              onChange={(e) =>
                                                setFormikInitialData(
                                                  (prevState: any) => ({
                                                    ...prevState,
                                                    security_noi:
                                                      e.target.value, // Update the specific field with the input value
                                                  })
                                                )
                                              }
                                            /> */}
                                            <select
                                              id="security_noi"
                                              name="security_noi"
                                              className="bond-input"
                                              value={
                                                formikProps.values
                                                  .security_noi || ""
                                              }
                                              onChange={(e) =>
                                                setFormikInitialData(
                                                  (prevState: any) => ({
                                                    ...prevState,
                                                    security_noi:
                                                      e.target.value, // Update the specific field with the input value
                                                  })
                                                )
                                              }
                                            >
                                              <option value="" disabled>
                                                Select Nature of Instrument
                                              </option>
                                              <option value="PERPETUAL">
                                                PERPETUAL
                                              </option>
                                              <option value="NON PERPETUAL">
                                                NON PERPETUAL
                                              </option>
                                            </select>
                                          </div>
                                        </Grid>

                                        {/* Face Value */}
                                        <Grid item xs={4}>
                                          <div className="d-grid">
                                            <label
                                              htmlFor="security_face_value"
                                              className="bond-label"
                                            >
                                              Face Value
                                            </label>
                                            <input
                                              id="security_face_value"
                                              name="security_face_value"
                                              type="number"
                                              value={
                                                formikProps.values
                                                  .security_face_value || ""
                                              }
                                              onChange={(e) =>
                                                setFormikInitialData(
                                                  (prevState: any) => ({
                                                    ...prevState,
                                                    security_face_value:
                                                      e.target.value, // Update the specific field with the input value
                                                  })
                                                )
                                              }
                                              className="bond-input"
                                            />
                                          </div>
                                        </Grid>

                                        {/* Issue Price */}
                                        <Grid item xs={4}>
                                          <div className="d-grid">
                                            <label
                                              htmlFor="security_issue_price"
                                              className="bond-label"
                                            >
                                              Issue Price
                                            </label>
                                            <input
                                              id="security_issue_price"
                                              name="security_issue_price"
                                              type="number"
                                              value={
                                                formikProps.values
                                                  .security_issue_price || ""
                                              }
                                              onChange={(e) =>
                                                setFormikInitialData(
                                                  (prevState: any) => ({
                                                    ...prevState,
                                                    security_issue_price:
                                                      e.target.value, // Update the specific field with the input value
                                                  })
                                                )
                                              }
                                              className="bond-input"
                                            />
                                          </div>
                                        </Grid>

                                        {/* Issue Date */}
                                        <Grid item xs={4}>
                                          <div className="d-grid">
                                            <label
                                              htmlFor="security_issue_date"
                                              className="bond-label"
                                            >
                                              Issue Date
                                            </label>
                                            <input
                                              id="security_issue_date"
                                              name="security_issue_date"
                                              type="date"
                                              value={
                                                formikProps.values
                                                  .security_issue_date || ""
                                              }
                                              onChange={(e) =>
                                                setFormikInitialData(
                                                  (prevState: any) => ({
                                                    ...prevState,
                                                    security_issue_date:
                                                      e.target.value, // Update the specific field with the input value
                                                  })
                                                )
                                              }
                                              className="bond-input"
                                            />
                                          </div>
                                        </Grid>

                                        {/* First Interest Date */}
                                        {/* <Grid item xs={4}>
                    <div className="d-grid">
                      <label htmlFor="security_first_interest_date" className="bond-label">
                        First Interest Date
                      </label>
                      <input
                        id="security_first_interest_date"
                        name="security_first_interest_date"
                        type="date"
                        value={formikProps.values.security_first_interest_date || ""}
                        onChange={formikProps.handleChange}
                        className="bond-input"
                      />
                    </div>
                  </Grid> */}

                                        {/* Book Closure Date */}
                                        <Grid item xs={4}>
                                          <div className="d-grid">
                                            <label
                                              htmlFor="security_book_closure_date"
                                              className="bond-label"
                                            >
                                              Book Closure Date
                                            </label>
                                            <input
                                              id="security_book_closure_date"
                                              name="security_book_closure_date"
                                              type="date"
                                              // value={formikProps.values.security_book_closure_date || ""}
                                              onChange={(e) =>
                                                setFormikInitialData(
                                                  (prevState: any) => ({
                                                    ...prevState,
                                                    security_book_closure_date:
                                                      e.target.value, // Update the specific field with the input value
                                                  })
                                                )
                                              }
                                              className="bond-input"
                                            />
                                          </div>
                                        </Grid>

                                        {/* Call Date */}
                                        <Grid item xs={4}>
                                          <div className="d-grid">
                                            <label
                                              htmlFor="security_call_date"
                                              className="bond-label"
                                            >
                                              Call Date
                                            </label>
                                            <input
                                              id="security_call_date"
                                              name="security_call_date"
                                              type="date"
                                              value={
                                                formikProps.values
                                                  .security_call_date || ""
                                              }
                                              onChange={(e) =>
                                                setFormikInitialData(
                                                  (prevState: any) => ({
                                                    ...prevState,
                                                    security_call_date:
                                                      e.target.value, // Update the specific field with the input value
                                                  })
                                                )
                                              }
                                              className="bond-input"
                                            />
                                          </div>
                                        </Grid>

                                        {/* Put Date */}
                                        <Grid item xs={4}>
                                          <div className="d-grid">
                                            <label
                                              htmlFor="security_put_date"
                                              className="bond-label"
                                            >
                                              Put Date
                                            </label>
                                            <input
                                              id="security_put_date"
                                              name="security_put_date"
                                              type="date"
                                              value={
                                                formikProps.values
                                                  .security_put_date || ""
                                              }
                                              onChange={(e) =>
                                                setFormikInitialData(
                                                  (prevState: any) => ({
                                                    ...prevState,
                                                    security_put_date:
                                                      e.target.value, // Update the specific field with the input value
                                                  })
                                                )
                                              }
                                              className="bond-input"
                                            />
                                          </div>
                                        </Grid>

                                        <Grid item xs={4}>
                                          <div className="d-grid">
                                            <label
                                              htmlFor="security_maturity_date"
                                              className="bond-label"
                                            >
                                              Maturity Date
                                            </label>
                                            <input
                                              id="security_maturity_date"
                                              name="security_maturity_date"
                                              type="date"
                                              className="bond-input"
                                              value={
                                                formikProps.values
                                                  .security_maturity_date || ""
                                              }
                                              onChange={(e) =>
                                                setFormikInitialData(
                                                  (prevState: any) => ({
                                                    ...prevState,
                                                    security_maturity_date:
                                                      e.target.value, // Update the specific field with the input value
                                                  })
                                                )
                                              }
                                            />
                                          </div>
                                        </Grid>

                                        <Grid item xs={4}>
                                          <div className="d-grid">
                                            <label
                                              htmlFor="security_coupon_rate"
                                              className="bond-label"
                                            >
                                              Coupon Rate
                                            </label>
                                            <input
                                              id="security_coupon_rate"
                                              name="security_coupon_rate"
                                              type="number"
                                              className="bond-input"
                                              value={
                                                formikProps.values
                                                  .security_coupon_rate || ""
                                              }
                                              onChange={(e) =>
                                                setFormikInitialData(
                                                  (prevState: any) => ({
                                                    ...prevState,
                                                    security_coupon_rate:
                                                      e.target.value, // Update the specific field with the input value
                                                  })
                                                )
                                              }
                                            />
                                          </div>
                                        </Grid>
                                        <Grid item xs={4}>
                                          <div className="d-grid">
                                            <label
                                              htmlFor="security_norm_compound_flag"
                                              className="bond-label"
                                            >
                                              Norm Compound Flag
                                            </label>
                                            <input
                                              id="security_norm_compound_flag"
                                              name="security_norm_compound_flag"
                                              type="text"
                                              className="bond-input"
                                              value={
                                                formikProps.values
                                                  .security_norm_compound_flag ||
                                                ""
                                              }
                                              onChange={(e) =>
                                                setFormikInitialData(
                                                  (prevState: any) => ({
                                                    ...prevState,
                                                    security_norm_compound_flag:
                                                      e.target.value, // Update the specific field with the input value
                                                  })
                                                )
                                              }
                                            />
                                          </div>
                                        </Grid>
                                        <Grid item xs={4}>
                                          <div className="d-grid">
                                            <label
                                              htmlFor="bond_issuer_details"
                                              className="bond-label"
                                            >
                                              Security Logo
                                            </label>
                                            <div>
                                              <label
                                                className="bond-input-upload-file"
                                                onClick={() =>
                                                  fileInputRefLogo.current?.click()
                                                } // Trigger file dialog on click
                                              >
                                                Upload Security Logo
                                              </label>
                                              <input
                                                type="file"
                                                ref={fileInputRefLogo}
                                                style={{ display: "none" }}
                                                onChange={(event) =>
                                                  handleFileChange(
                                                    event,
                                                    "security_logo"
                                                  )
                                                } // Attach the handleFileChange function
                                              />
                                            </div>
                                          </div>
                                        </Grid>
                                        {formikInitialData?.security_logo !=
                                          null &&
                                          formikInitialData?.security_logo !=
                                            "" && (
                                            <Grid item xs={4}>
                                              <div className="d-grid mt-4">
                                                <label
                                                  htmlFor={`view_logo`}
                                                  className="bond-label mr-5"
                                                >
                                                  View Logo
                                                  <i
                                                    className="fas fa-eye table-icons pe-1"
                                                    style={{
                                                      marginLeft: "10px",
                                                    }}
                                                    onClick={() => {
                                                      if (
                                                        formikInitialData?.security_logo
                                                      ) {
                                                        const link =
                                                          document.createElement(
                                                            "a"
                                                          );
                                                        link.href =
                                                          formikInitialData?.security_logo;
                                                        link.download = ""; // Optional: You can set a custom file name here
                                                        link.click();
                                                      }
                                                    }}
                                                  ></i>
                                                  <button
                                                    type="button"
                                                    className="btn"
                                                    style={{
                                                      background: "red",
                                                      color: "white",
                                                    }}
                                                    onClick={() => {
                                                      setFormikInitialData(
                                                        (prevState: any) => ({
                                                          ...prevState,
                                                          security_logo: null,
                                                        })
                                                      );
                                                    }}
                                                  >
                                                    Remove
                                                  </button>
                                                </label>
                                              </div>
                                            </Grid>
                                          )}
                                      </Grid>
                                    </div>
                                  </div>
                                </div>
                                <div className="row">
                                  <div
                                    className="card"
                                    style={{
                                      borderTopLeftRadius: "10px",
                                      borderTopRightRadius: "10px",
                                      paddingTop: "10px",
                                      paddingBottom: "20px",
                                    }}
                                  >
                                    <div className="card-header">
                                      <h5 className="m-0 header">
                                        {" "}
                                        Edit Issuer Details{" "}
                                      </h5>
                                    </div>
                                    <div className="card-body">
                                      {/* {formikProps?.values.bond_issuer_details.map(
                                        (issuer_details, index) => {
                                          return ( */}
                                      <>
                                        <Grid
                                          direction="row"
                                          container
                                          spacing={2}
                                        >
                                          <Grid item xs={4}>
                                            <div className="d-grid">
                                              <label
                                                htmlFor="security_issuer_type_id"
                                                className="bond-label"
                                              >
                                                Issue Type
                                              </label>
                                              <select
                                                id="security_issuer_type_id"
                                                name="security_issuer_type_id"
                                                className="bond-input-select"
                                                value={
                                                  formikProps.values.issuer
                                                    .security_issuer_type_id ||
                                                  ""
                                                }
                                                onChange={(e) =>
                                                  setFormikInitialData(
                                                    (prevState: any) => ({
                                                      ...prevState,
                                                      issuer: {
                                                        ...prevState.issuer, // Preserve existing properties in issuer
                                                        security_issuer_type_id:
                                                          e.target.value, // Update the specific field
                                                      },
                                                    })
                                                  )
                                                }
                                              >
                                                <option value="" disabled>
                                                  Select Issuer's Type
                                                </option>
                                                <option value="3">
                                                  DEBENTURES
                                                </option>
                                                <option value="4">BONDS</option>
                                                <option value="3">
                                                  STATE GOVERNMENT
                                                </option>
                                              </select>
                                            </div>
                                          </Grid>
                                          <Grid item xs={4}>
                                            <div className="d-grid">
                                              <label
                                                htmlFor="security_issuer_type_id"
                                                className="bond-label"
                                              >
                                                Issuer Type
                                              </label>
                                              <input
                                                id="security_issuer_type"
                                                name="security_issuer_type"
                                                type="text"
                                                className="bond-input"
                                                value={
                                                  formikProps.values.issuer
                                                    .security_issuer_types || ""
                                                }
                                                onChange={(e) =>
                                                  setFormikInitialData(
                                                    (prevState: any) => ({
                                                      ...prevState,
                                                      issuer: {
                                                        ...prevState.issuer, // Preserve existing properties in issuer
                                                        security_issuer_types:
                                                          e.target.value, // Update the specific field
                                                      },
                                                    })
                                                  )
                                                }
                                              />
                                            </div>
                                          </Grid>
                                          <Grid item xs={4}>
                                            <div className="d-grid">
                                              <label
                                                htmlFor="security_issuer_name"
                                                className="bond-label"
                                              >
                                                Issuer Name
                                              </label>
                                              <input
                                                id="security_issuer_name"
                                                name="security_issuer_name"
                                                type="text"
                                                className="bond-input"
                                                value={
                                                  formikProps.values.issuer
                                                    .security_issuer_name || ""
                                                }
                                                onChange={(e) =>
                                                  setFormikInitialData(
                                                    (prevState: any) => ({
                                                      ...prevState,
                                                      issuer: {
                                                        ...prevState.issuer, // Preserve existing properties in issuer
                                                        security_issuer_name:
                                                          e.target.value, // Update the specific field
                                                      },
                                                    })
                                                  )
                                                }
                                              />
                                            </div>
                                          </Grid>
                                          {/* <Grid item xs={4}>
                                                                                               <div className="d-grid">
                                                                                                 <label
                                                                                                   htmlFor="security_annual_revenue"
                                                                                                   className="bond-label"
                                                                                                 >
                                                                                                   Total Annual Revenue
                                                                                                 </label>
                                                                                                 <input
                                                                                                   id="security_annual_revenue"
                                                                                                   name="security_annual_revenue"
                                                                                                   type="text"
                                                                                                   className="bond-input"
                                                                                                   value={""}
                                                                                                   onChange={formikProps.handleChange}
                                                                                                 />
                                                                                               </div>
                                                                                             </Grid> */}
                                          <Grid item xs={4}>
                                            <div className="d-grid">
                                              <label
                                                htmlFor="security_issuer_founded_year"
                                                className="bond-label"
                                              >
                                                Year of Inception
                                              </label>
                                              <input
                                                id="security_issuer_founded_year"
                                                name="security_issuer_founded_year"
                                                type="number"
                                                className="bond-input"
                                                value={
                                                  formikProps.values.issuer
                                                    .security_issuer_founded_year ||
                                                  ""
                                                }
                                                onChange={(e) =>
                                                  setFormikInitialData(
                                                    (prevState: any) => ({
                                                      ...prevState,
                                                      issuer: {
                                                        ...prevState.issuer, // Preserve existing properties in issuer
                                                        security_issuer_founded_year:
                                                          e.target.value, // Update the specific field
                                                      },
                                                    })
                                                  )
                                                }
                                              />
                                            </div>
                                          </Grid>
                                          <Grid item xs={4}>
                                            <div className="d-grid">
                                              <label
                                                htmlFor="security_issuer_industry"
                                                className="bond-label"
                                              >
                                                Industry
                                              </label>
                                              <input
                                                id="security_issuer_industry"
                                                name="security_issuer_industry"
                                                type="text"
                                                className="bond-input"
                                                value={
                                                  formikProps.values.issuer
                                                    .security_issuer_industry ||
                                                  ""
                                                }
                                                onChange={(e) =>
                                                  setFormikInitialData(
                                                    (prevState: any) => ({
                                                      ...prevState,
                                                      issuer: {
                                                        ...prevState.issuer, // Preserve existing properties in issuer
                                                        security_issuer_industry:
                                                          e.target.value, // Update the specific field
                                                      },
                                                    })
                                                  )
                                                }
                                              />
                                            </div>
                                          </Grid>
                                          <Grid item xs={4}>
                                            <div className="d-grid">
                                              <label
                                                htmlFor="security_issuer_ownership"
                                                className="bond-label"
                                              >
                                                Ownership
                                              </label>
                                              <input
                                                id="security_issuer_ownership"
                                                name="security_issuer_ownership"
                                                type="text"
                                                className="bond-input"
                                                value={
                                                  formikProps.values.issuer
                                                    .security_issuer_ownership ||
                                                  ""
                                                }
                                                onChange={(e) =>
                                                  setFormikInitialData(
                                                    (prevState: any) => ({
                                                      ...prevState,
                                                      issuer: {
                                                        ...prevState.issuer, // Preserve existing properties in issuer
                                                        security_issuer_ownership:
                                                          e.target.value, // Update the specific field
                                                      },
                                                    })
                                                  )
                                                }
                                              />
                                            </div>
                                          </Grid>
                                          <Grid item xs={4}>
                                            <div className="d-grid">
                                              <label
                                                htmlFor="security_issuer_head_office"
                                                className="bond-label"
                                              >
                                                Head Office
                                              </label>
                                              <input
                                                id="security_issuer_head_office"
                                                name="security_issuer_head_office"
                                                type="text"
                                                className="bond-input"
                                                value={
                                                  formikProps.values.issuer
                                                    .security_issuer_head_office ||
                                                  ""
                                                }
                                                onChange={(e) =>
                                                  setFormikInitialData(
                                                    (prevState: any) => ({
                                                      ...prevState,
                                                      issuer: {
                                                        ...prevState.issuer, // Preserve existing properties in issuer
                                                        security_issuer_head_office:
                                                          e.target.value, // Update the specific field
                                                      },
                                                    })
                                                  )
                                                }
                                              />
                                            </div>
                                          </Grid>
                                          <Grid item xs={4}>
                                            <div className="d-grid">
                                              <label
                                                htmlFor="security_issuer_sector"
                                                className="bond-label"
                                              >
                                                Business Sector
                                              </label>
                                              <input
                                                id="security_issuer_sector"
                                                name="security_issuer_sector"
                                                type="text"
                                                className="bond-input"
                                                value={
                                                  formikProps.values.issuer
                                                    .security_issuer_sector ||
                                                  ""
                                                }
                                                onChange={(e) =>
                                                  setFormikInitialData(
                                                    (prevState: any) => ({
                                                      ...prevState,
                                                      issuer: {
                                                        ...prevState.issuer, // Preserve existing properties in issuer
                                                        security_issuer_sector:
                                                          e.target.value, // Update the specific field
                                                      },
                                                    })
                                                  )
                                                }
                                              />
                                            </div>
                                          </Grid>
                                          <Grid item xs={4}>
                                            <div className="d-grid">
                                              <label
                                                htmlFor="security_issuer_cnt_authority"
                                                className="bond-label"
                                              >
                                                Current MD/CEO
                                              </label>
                                              <input
                                                id="security_issuer_cnt_authority"
                                                name="security_issuer_cnt_authority"
                                                type="text"
                                                className="bond-input"
                                                value={
                                                  formikProps.values.issuer
                                                    .security_issuer_cnt_authority ||
                                                  ""
                                                }
                                                onChange={(e) =>
                                                  setFormikInitialData(
                                                    (prevState: any) => ({
                                                      ...prevState,
                                                      issuer: {
                                                        ...prevState.issuer, // Preserve existing properties in issuer
                                                        security_issuer_cnt_authority:
                                                          e.target.value, // Update the specific field
                                                      },
                                                    })
                                                  )
                                                }
                                              />
                                            </div>
                                          </Grid>

                                          <Grid item xs={4}>
                                            <div className="d-grid">
                                              <label
                                                htmlFor="security_issuer_tax_free"
                                                className="bond-label"
                                              >
                                                Tax Free
                                              </label>
                                              <input
                                                id="security_issuer_tax_free"
                                                name="security_issuer_tax_free"
                                                type="text"
                                                className="bond-input"
                                                value={
                                                  formikProps.values.issuer
                                                    .security_issuer_tax_free ||
                                                  ""
                                                }
                                                onChange={(e) =>
                                                  setFormikInitialData(
                                                    (prevState: any) => ({
                                                      ...prevState,
                                                      issuer: {
                                                        ...prevState.issuer, // Preserve existing properties in issuer
                                                        security_issuer_tax_free:
                                                          e.target.value, // Update the specific field
                                                      },
                                                    })
                                                  )
                                                }
                                              />
                                            </div>
                                          </Grid>
                                          <Grid item xs={4}>
                                            <div className="d-grid">
                                              <label
                                                htmlFor="security_issuer_seniority"
                                                className="bond-label"
                                              >
                                                Seniority
                                              </label>
                                              <input
                                                id="security_issuer_seniority"
                                                name="security_issuer_seniority"
                                                type="text"
                                                className="bond-input"
                                                value={
                                                  formikProps.values.issuer
                                                    .security_issuer_seniority ||
                                                  ""
                                                }
                                                onChange={(e) =>
                                                  setFormikInitialData(
                                                    (prevState: any) => ({
                                                      ...prevState,
                                                      issuer: {
                                                        ...prevState.issuer, // Preserve existing properties in issuer
                                                        security_issuer_seniority:
                                                          e.target.value, // Update the specific field
                                                      },
                                                    })
                                                  )
                                                }
                                              />
                                            </div>
                                          </Grid>
                                          <Grid item xs={4}>
                                            <div className="d-grid">
                                              <label
                                                htmlFor="security_issuer_moi"
                                                className="bond-label"
                                              >
                                                Original Mode of issue
                                              </label>
                                              <input
                                                id="security_issuer_moi"
                                                name="security_issuer_moi"
                                                type="text"
                                                className="bond-input"
                                                value={
                                                  formikProps.values.issuer
                                                    .security_issuer_moi || ""
                                                }
                                                onChange={(e) =>
                                                  setFormikInitialData(
                                                    (prevState: any) => ({
                                                      ...prevState,
                                                      issuer: {
                                                        ...prevState.issuer, // Preserve existing properties in issuer
                                                        security_issuer_moi:
                                                          e.target.value, // Update the specific field
                                                      },
                                                    })
                                                  )
                                                }
                                              />
                                            </div>
                                          </Grid>
                                          <Grid item xs={4}>
                                            <div className="d-grid">
                                              <label
                                                htmlFor="security_issuer_debenture_trustee"
                                                className="bond-label"
                                              >
                                                Name of debenture trustee
                                              </label>
                                              <input
                                                id="security_issuer_debenture_trustee"
                                                name="security_issuer_debenture_trustee"
                                                type="text"
                                                className="bond-input"
                                                value={
                                                  formikProps.values.issuer
                                                    .security_issuer_debenture_trustee ||
                                                  ""
                                                }
                                                onChange={(e) =>
                                                  setFormikInitialData(
                                                    (prevState: any) => ({
                                                      ...prevState,
                                                      issuer: {
                                                        ...prevState.issuer, // Preserve existing properties in issuer
                                                        security_issuer_debenture_trustee:
                                                          e.target.value, // Update the specific field
                                                      },
                                                    })
                                                  )
                                                }
                                              />
                                            </div>
                                          </Grid>
                                          <Grid item xs={4}>
                                            <div className="d-grid">
                                              <label
                                                htmlFor="exchange_id"
                                                className="bond-label"
                                              >
                                                Exchange
                                              </label>
                                              <select
                                                id="exchange_id"
                                                name="exchange_id"
                                                className="bond-input-select"
                                                value={
                                                  formikProps.values.issuer
                                                    .exchange_id || ""
                                                }
                                                onChange={(e) =>
                                                  setFormikInitialData(
                                                    (prevState: any) => ({
                                                      ...prevState,
                                                      issuer: {
                                                        ...prevState.issuer, // Preserve existing properties in issuer
                                                        exchange_id:
                                                          e.target.value, // Update the specific field
                                                      },
                                                    })
                                                  )
                                                }
                                              >
                                                <option value="" disabled>
                                                  Select Issuer's Exchange Type
                                                </option>
                                                <option value="1">BSE</option>
                                                <option value="2">NSE</option>
                                                <option value="3">
                                                  BSE & NSE
                                                </option>
                                              </select>
                                            </div>
                                          </Grid>
                                          <Grid item xs={4}>
                                            <div className="d-grid">
                                              <label
                                                htmlFor="security_issuer_coupon_type"
                                                className="bond-label"
                                              >
                                                Coupon Type
                                              </label>
                                              <select
                                                id="security_issuer_coupon_type"
                                                name="security_issuer_coupon_type"
                                                className="bond-input-select"
                                                value={
                                                  formikProps.values.issuer
                                                    .security_issuer_coupon_type ||
                                                  ""
                                                }
                                                onChange={(e) =>
                                                  setFormikInitialData(
                                                    (prevState: any) => ({
                                                      ...prevState,
                                                      issuer: {
                                                        ...prevState.issuer, // Preserve existing properties in issuer
                                                        security_issuer_coupon_type:
                                                          e.target.value, // Update the specific field
                                                      },
                                                    })
                                                  )
                                                }
                                              >
                                                <option value="" disabled>
                                                  Select Coupon Type
                                                </option>
                                                <option value="Fixed">
                                                  Fixed
                                                </option>
                                                <option value="Floating">
                                                  Floating
                                                </option>
                                              </select>
                                            </div>
                                          </Grid>
                                        </Grid>
                                      </>
                                      {/* );
                                        }
                                      )} */}
                                    </div>
                                  </div>
                                </div>
                                <div className="row">
                                  <div
                                    className="card"
                                    style={{
                                      borderTopLeftRadius: "10px",
                                      borderTopRightRadius: "10px",
                                      paddingTop: "10px",
                                      paddingBottom: "20px",
                                    }}
                                  >
                                    <div className="card-header d-flex justify-content-between">
                                      <h5 className="m-0 header">
                                        Issuer Documents
                                      </h5>
                                      <div>
                                        <label
                                          className="bond-input-upload-file"
                                          onClick={() =>
                                            fileInputRef.current?.click()
                                          } // Trigger file dialog on click
                                        >
                                          Upload New Issuer Document
                                        </label>
                                        <input
                                          type="file"
                                          ref={fileInputRef}
                                          style={{ display: "none" }}
                                          onChange={(event) =>
                                            handleFileChange(event, "issuerDoc")
                                          } // Attach the handleFileChange function
                                        />
                                      </div>
                                    </div>

                                    <div className="card-body">
                                      {formikProps?.values?.issuer
                                        ?.issuer_document &&
                                        Array.isArray(
                                          formikProps.values.issuer
                                            .issuer_document
                                        ) && (
                                          <table className="table">
                                            <thead>
                                              <tr>
                                                <th>Document Type</th>
                                                <th>Document Status</th>
                                                <th>Action</th>
                                              </tr>
                                            </thead>
                                            <tbody>
                                              {formikProps.values.issuer.issuer_document.map(
                                                (doc: any, index: number) => (
                                                  <tr key={index}>
                                                    {/* Document Type Dropdown */}
                                                    <td>
                                                      <select
                                                        id={`issuer_document_name_${index}`}
                                                        name="security_issuer_document_name"
                                                        className="form-control"
                                                        value={
                                                          doc.security_issuer_document_name ||
                                                          ""
                                                        }
                                                        onChange={(e) => {
                                                          const {
                                                            name,
                                                            value,
                                                          } = e.target;

                                                          formikProps.values.issuer.issuer_document[
                                                            index
                                                          ] = {
                                                            ...formikProps
                                                              .values.issuer
                                                              .issuer_document[
                                                              index
                                                            ],
                                                            [name]: value, // Update the name field
                                                          };

                                                          formikProps.setFieldValue(
                                                            "issuer.issuer_document",
                                                            formikProps.values
                                                              .issuer
                                                              .issuer_document
                                                          );
                                                        }}
                                                      >
                                                        <option
                                                          value=""
                                                          disabled
                                                        >
                                                          Select Document Type
                                                        </option>
                                                        <option value="IM">
                                                          IM
                                                        </option>
                                                        <option value="Prospectus">
                                                          Prospectus
                                                        </option>
                                                      </select>
                                                    </td>

                                                    {/* Document Status Toggle */}
                                                    <td>
                                                      <label className="switch">
                                                        <input
                                                          type="checkbox"
                                                          id={`security_issuer_document_status${index}`}
                                                          checked={
                                                            doc.security_issuer_document_status ||
                                                            false
                                                          }
                                                          onChange={(e) => {
                                                            const { checked } =
                                                              e.target;

                                                            formikProps.values.issuer.issuer_document[
                                                              index
                                                            ] = {
                                                              ...formikProps
                                                                .values.issuer
                                                                .issuer_document[
                                                                index
                                                              ],
                                                              security_issuer_document_status:
                                                                checked,
                                                            };

                                                            formikProps.setFieldValue(
                                                              "issuer.issuer_document",
                                                              formikProps.values
                                                                .issuer
                                                                .issuer_document
                                                            );
                                                          }}
                                                        />
                                                        <span className="slider round"></span>
                                                      </label>
                                                    </td>

                                                    {/* Action Column */}
                                                    <td>
                                                      <i
                                                        className="fas fa-eye table-icons"
                                                        style={{
                                                          cursor: "pointer",
                                                          marginRight: "10px",
                                                        }}
                                                        onClick={() => {
                                                          if (
                                                            doc.security_issuer_document_file
                                                          ) {
                                                            const link =
                                                              document.createElement(
                                                                "a"
                                                              );
                                                            link.href =
                                                              doc.security_issuer_document_file;
                                                            link.download = ""; // Optional: set a custom file name
                                                            link.click();
                                                          }
                                                        }}
                                                      ></i>

                                                      <i
                                                        className="fas fa-trash table-icons"
                                                        style={{
                                                          cursor: "pointer",
                                                          color: "red",
                                                        }}
                                                        onClick={() => {
                                                          const issuerDocuments =
                                                            formikProps.values
                                                              .issuer
                                                              ?.issuer_document;

                                                          if (
                                                            issuerDocuments &&
                                                            issuerDocuments[
                                                              index
                                                            ]
                                                          ) {
                                                            setRemovedDocuments(
                                                              (prev) => [
                                                                ...prev,
                                                                issuerDocuments[
                                                                  index
                                                                ]
                                                                  .security_issuer_document_file,
                                                              ]
                                                            );

                                                            issuerDocuments.splice(
                                                              index,
                                                              1
                                                            );

                                                            formikProps.setFieldValue(
                                                              "issuer.issuer_document",
                                                              issuerDocuments
                                                            );
                                                          }
                                                        }}
                                                      ></i>
                                                    </td>
                                                  </tr>
                                                )
                                              )}
                                            </tbody>
                                          </table>
                                        )}
                                    </div>
                                  </div>
                                </div>
                                <div className="row">
                                  <div>
                                    <div className="card-header d-flex justify-content-between">
                                      <h5 className="m-0 header">
                                        Issuer Debt Quality
                                      </h5>
                                    </div>
                                    <div className="card-body">
                                      <Box sx={{ width: "100%" }}>
                                        <Grid
                                          container
                                          direction="row"
                                          spacing={2}
                                        >
                                          {debtQualityData.map(
                                            (item, index) => (
                                              <Grid
                                                item
                                                xs={6}
                                                key={item.debt_quality_id}
                                                style={{
                                                  display: "flex",
                                                  flexDirection: "column",
                                                }}
                                              >
                                                <div>
                                                  {/* Label for Debt Quality Name */}
                                                  <label
                                                    style={{
                                                      fontWeight: "bold",
                                                      display: "block",
                                                      marginBottom: "5px",
                                                    }}
                                                  >
                                                    {item.debt_quality_name}
                                                  </label>

                                                  {/* Textarea for Debt Quality Description */}
                                                  <textarea
                                                    value={
                                                      formikInitialData.security_debt_quality?.find(
                                                        (selected: any) =>
                                                          selected.debt_quality_id ===
                                                          item.debt_quality_id
                                                      )
                                                        ?.debt_quality_description ||
                                                      item.debt_quality_description
                                                    }
                                                    onChange={(e) =>
                                                      handleTextareaChange(
                                                        item.debt_quality_id,
                                                        e.target.value
                                                      )
                                                    }
                                                    style={{
                                                      width: "100%",
                                                      marginTop: "5px",
                                                      height: "100px",
                                                      padding: "5px",
                                                      resize: "vertical",
                                                      borderRadius: "5px",
                                                    }}
                                                  />
                                                </div>
                                              </Grid>
                                            )
                                          )}
                                        </Grid>
                                      </Box>
                                    </div>
                                  </div>
                                </div>

                                <div className="row">
                                  <div
                                    className="card"
                                    style={{
                                      borderTopLeftRadius: "10px",
                                      borderTopRightRadius: "10px",
                                      paddingTop: "10px",
                                      paddingBottom: "20px",
                                    }}
                                  >
                                    <div className="card-header d-flex justify-content-between">
                                      <h5 className="m-0 header">
                                        {" "}
                                        Bond's Rating Details{" "}
                                      </h5>
                                    </div>
                                    <div className="card-body">
                                      <>
                                        <Grid
                                          direction="row"
                                          container
                                          spacing={2}
                                          justifyContent="center"
                                          alignItems="center"
                                        >
                                          <Grid item xs={3.5}>
                                            <div className="d-grid">
                                              <label
                                                htmlFor="security_rating_agency"
                                                className="bond-label"
                                              >
                                                Rating Agency Name
                                              </label>
                                              <input
                                                id="security_rating_agency"
                                                name="security_rating_agency"
                                                type="text"
                                                className="bond-input"
                                                value={
                                                  formikProps.values
                                                    .security_rating_agency ||
                                                  ""
                                                }
                                                onChange={(e) =>
                                                  setFormikInitialData(
                                                    (prevState: any) => ({
                                                      ...prevState,
                                                      security_rating_agency:
                                                        e.target.value, // Update the specific field with the input value
                                                    })
                                                  )
                                                }
                                              />
                                            </div>
                                          </Grid>
                                          <Grid item xs={3.5}>
                                            <div className="d-grid">
                                              <label
                                                htmlFor="security_rating"
                                                className="bond-label"
                                              >
                                                Rating
                                              </label>
                                              <input
                                                id="security_rating"
                                                name="security_rating"
                                                type="text"
                                                className="bond-input"
                                                value={
                                                  formikProps.values
                                                    .security_rating || ""
                                                }
                                                onChange={(e) =>
                                                  setFormikInitialData(
                                                    (prevState: any) => ({
                                                      ...prevState,
                                                      security_rating:
                                                        e.target.value, // Update the specific field with the input value
                                                    })
                                                  )
                                                }
                                              />
                                            </div>
                                          </Grid>
                                          <Grid item xs={4}>
                                            {/* <div className="d-grid">
                                              <label
                                                htmlFor="bond_issuer_details"
                                                className="bond-label"
                                              >
                                                Upload Rating Certificate
                                              </label>
                                              <div
                                                {...getRootProps({
                                                  onClick: (event) => {
                                                    setUploadFeild((e) => ({
                                                      ...e,
                                                      feild_type:
                                                        "bond_issuer_documents",
                                                    }));
                                                  },
                                                })}
                                              >
                                                <input {...getInputProps()} />
                                                {
                                                  <label className="bond-input-upload-file">
                                                    Upload Rating Certificate
                                                  </label>
                                                }
                                              </div>
                                            </div> */}
                                          </Grid>
                                          <Grid
                                            direction="row"
                                            item
                                            xs={1}
                                            justifyContent="center"
                                            alignItems="center"
                                          ></Grid>
                                        </Grid>
                                      </>
                                    </div>
                                  </div>
                                </div>
                                <div className="row">
                                  <div
                                    className="card"
                                    style={{
                                      borderTopLeftRadius: "10px",
                                      borderTopRightRadius: "10px",
                                      paddingTop: "10px",
                                      paddingBottom: "20px",
                                    }}
                                  >
                                    <div className="card-header">
                                      <h5 className="m-0 header">
                                        {" "}
                                        Key Highlights{" "}
                                      </h5>
                                    </div>
                                    <div className="card-body">
                                      <Editor
                                        editorState={editorState} // Controlled editor state
                                        onEditorStateChange={(
                                          newState: EditorState
                                        ) => {
                                          setEditorState(newState); // Update the state
                                          const rawContent = convertToRaw(
                                            newState.getCurrentContent()
                                          );
                                          const htmlContent =
                                            draftToHtml(rawContent); // Convert to HTML string
                                          setFormikInitialData(
                                            (prevState: any) => ({
                                              ...prevState,
                                              issuer: {
                                                ...prevState.issuer, // Preserve existing properties in issuer
                                                security_issuer_highlight:
                                                  htmlContent, // Update the specific field
                                              },
                                            })
                                          );
                                        }}
                                        toolbarClassName="toolbar_form-control"
                                        wrapperClassName="editor_form-control_feedDetail"
                                        editorClassName="editor_textFeild_form-control"
                                        placeholder="Write a Disclaimer..."
                                        toolbar={wysiwygToolbar}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </LocalizationProvider>
                            </Form>
                          );
                        }}
                      </Formik>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row text-end my-3 py-5 ">
              <div className="">
                <a className="cancel-btn mx-3" onClick={() => navigate(-1)}>
                  Cancel
                </a>
                <button
                  type="submit"
                  className="save-btn"
                  onClick={() => setOpenModal(true)}
                >
                  Update
                </button>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
      {/* <SimpleModal
        contents={view_content}
        handleOpen={() => handleClickOpen}
        openModal={open}
        handleClose={(e: any) => handleClose(e)}
      /> */}
      <ConfirmationModal
        contents="Are you sure you want to update the Bond ?"
        details_datas={formikInitialData}
        openModal={open_modal}
        handleClose={(e: any) => setOpenModal(false)}
        currentPage="update-bond-details"
      />
    </div>
  );
};

export default BondAdd;
