import React, { useState } from "react";
import "../Order-detail-comp/orderDetailCom.css";
import {
  getFormattedLocalDate,
  numberWithCommas,
  getDisplayData,
  getDownloadFile,
} from "../../common/Utilities";
import { Grid } from "@mui/material";

const BondDetailComp = ({ orderDetails, issuerData }: any) => {
  const [bondDetails, setBondDetails] = useState(orderDetails?.security || {});
  const [issuerDetails, setIssuerDetails] = useState(issuerData?.issuer || {});
  const [errors, setErrors] = useState<{ [key: string]: string }>({});

  const validateField = (key: string, value: any) => {
    let error = "";
    if (key.includes("date") && value) {
      if (isNaN(Date.parse(value))) {
        error = "Invalid date format.";
      }
    } else if (
      key.includes("value") ||
      key.includes("amount") ||
      key.includes("rate")
    ) {
      if (isNaN(value)) {
        error = "Must be a number.";
      }
    }
    return error;
  };

  const handleInputChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >,
    section: string,
    key: string
  ) => {
    const value = e.target.value;
    const error = validateField(key, value);
    setErrors({ ...errors, [key]: error });

    if (section === "bond") {
      setBondDetails({ ...bondDetails, [key]: value });
    } else if (section === "issuer") {
      setIssuerDetails({ ...issuerDetails, [key]: value });
    }
  };

  const saveChanges = () => {
    const bondErrors = Object.entries(bondDetails).reduce(
      (acc, [key, value]) => {
        const error = validateField(key, value);
        if (error) acc[key] = error;
        return acc;
      },
      {} as { [key: string]: string }
    );

    const issuerErrors = Object.entries(issuerDetails).reduce(
      (acc, [key, value]) => {
        const error = validateField(key, value);
        if (error) acc[key] = error;
        return acc;
      },
      {} as { [key: string]: string }
    );

    if (Object.keys(bondErrors).length || Object.keys(issuerErrors).length) {
      setErrors({ ...bondErrors, ...issuerErrors });
      alert("Please fix validation errors before saving.");
      return;
    }

    console.log("Saved Bond Details:", bondDetails);
    console.log("Saved Issuer Details:", issuerDetails);
    alert("API is not intigrated ");
  };
  const renderField = (
    key: string,
    value: any,
    section: string,
    isTextarea?: boolean,
    options?: string[]
  ) => {
    const inputType = key.includes("date") ? "date" : "text";
    return (
      <Grid item xs={3}>
        <div key={key} className="editable-field">
          <label>{key?.replace(/_/g, " ")?.toUpperCase()}:</label>
          {options ? (
            <select
              value={value || ""}
              onChange={(e) => handleInputChange(e, section, key)}
            >
              <option value="">Select</option>
              {options?.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>
          ) : isTextarea ? (
            <textarea
              value={(value as string) || ""}
              onChange={(e) => handleInputChange(e, section, key)}
            />
          ) : (
            <input
              type={inputType}
              value={(value as string) || ""}
              onChange={(e) => handleInputChange(e, section, key)}
            />
          )}
          {errors[key] && <p className="error-text">{errors[key]}</p>}
        </div>
      </Grid>
    );
  };

  return (
    <div
      className="card"
      style={{
        borderTopLeftRadius: "10px",
        borderTopRightRadius: "10px",
        paddingTop: "10px",
      }}
    >
      <div className="card-body">
        <div className="row">
          <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
            <p className="detail-title">Security Name</p>
            <h6>{getDisplayData(bondDetails?.security_name)}</h6>
          </div>
          <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
            <p className="detail-title">ISIN</p>
            <h6>{getDisplayData(bondDetails?.security_isin)}</h6>
          </div>
          <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
            <p className="detail-title">Security Type</p>
            <h6>
              {getDisplayData(bondDetails?.security_type?.security_type_name)}
            </h6>
          </div>
          <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
            <p className="detail-title">Nature Of Instrument</p>
            <h6>{getDisplayData(bondDetails?.security_noi)}</h6>
          </div>
          {/* <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                      <p className="detail-title">
                                        Govt. Guarantee
                                      </p>
                                      <h6>
                                        {getDisplaySelectOption(
                                          bondDetails?.bond_govt_guranatee,
                                          "bond_govt_guranatee"
                                        )}
                                      </h6>
                                    </div> */}

          <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
            <p className="detail-title">Face Value</p>
            <h6>{numberWithCommas(bondDetails?.security_face_value)}</h6>
          </div>
          <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
            <p className="detail-title">Issue Price</p>
            <h6>{numberWithCommas(bondDetails?.security_issue_price)}</h6>
          </div>
          {/* <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                      <p className="detail-title">Price per Bond</p>
                                      <h6>
                                        {numberWithCommas(
                                          bondDetails?.bond_price_per_bond
                                        )}
                                      </h6>
                                    </div> */}
          <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
            <p className="detail-title">Issue Date</p>
            <h6>{getFormattedLocalDate(bondDetails?.security_issue_date)}</h6>
          </div>
          {/* <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                      <p className="detail-title">
                                        Interest Frequency
                                      </p>
                                      <h6>
                                        {getDisplayData(
                                          bondDetails?.security_interest_frequency
                                            ?.security_interest_frequency_name
                                        )}
                                      </h6>
                                    </div> */}
          {/* <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                      <p className="detail-title">
                                        Calc Int on Holiday
                                      </p>
                                      <h6>
                                        {getDisplaySelectOption(
                                          bondDetails?.bond_calc_int_on_holidays,
                                          "bond_calc_int_on_holidays"
                                        )}
                                      </h6>
                                    </div> */}
          {/* <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                      <p className="detail-title">
                                        Calc Int on Maturity
                                      </p>
                                      <h6>
                                        {getDisplaySelectOption(
                                          bondDetails?.bond_int_on_maturity,
                                          "bond_int_on_maturity"
                                        )}
                                      </h6>
                                    </div> */}
          <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
            <p className="detail-title">First Interest Date</p>
            <h6>
              {getFormattedLocalDate(bondDetails?.security_first_interest_date)}
            </h6>
          </div>

          <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
            <p className="detail-title">Book Closure Date</p>
            <h6>
              {getFormattedLocalDate(bondDetails?.security_book_closure_date)}
            </h6>
          </div>
          <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
            <p className="detail-title">Call Date</p>
            <h6>{getFormattedLocalDate(bondDetails?.security_call_date)}</h6>
          </div>
          <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
            <p className="detail-title">Put Date</p>
            <h6>{getFormattedLocalDate(bondDetails?.security_put_date)}</h6>
          </div>
          <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
            <p className="detail-title">Maturity Date</p>
            <h6>
              {getFormattedLocalDate(bondDetails?.security_maturity_date)}
            </h6>
          </div>
          <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
            <p className="detail-title">Cupon Rate</p>
            <h6
            // style={{
            //   textDecoration: "underline",
            //   cursor: "pointer",
            // }}
            // onClick={() =>
            //   getDownloadFile(bondDetails?.bond_logo)
            // }
            >
              {getDisplayData(bondDetails?.security_coupon_rate)}
            </h6>
          </div>

          <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
            <p className="detail-title">Norm Compound Flag</p>
            <h6>{getDisplayData(bondDetails?.security_norm_compound_flag)}</h6>
          </div>
          {/* <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                      <p className="detail-title">Exchange</p>
                                      <h6>
                                      {getDisplayData(bondDetails?.security_norm_compound_flag)}
                                      </h6>
                                    </div> */}
          {/* <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                      <p className="detail-title">Is Staggered</p>
                                      <h6>
                                        {getDisplayData(
                                          bondDetails?.security_staggered_status
                                        )}
                                      </h6>
                                    </div> */}
          {/* <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                      <p className="detail-title">Security Info Date</p>
                                      <h6>
                                      {getDisplayData(bondDetails?.security_staggered_status)}
                                      </h6>
                                    </div> */}
          {/* <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                      <p className="detail-title">Security Info Amount</p>
                                      <h6>
                                        {getDisplaySelectOption(
                                          bondDetails?.bond_security_type,
                                          "bond_security_type"
                                        )}
                                      </h6>
                                    </div>
                                    <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                      <p className="detail-title">Info Type</p>
                                      <h6>
                                        {getDisplaySelectOption(
                                          bondDetails?.bond_security_type,
                                          "bond_security_type"
                                        )}
                                      </h6>
                                    </div> */}

          {/* <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                      <p className="detail-title">ST</p>
                                      <h6>
                                        {getDisplayData(bondDetails?.bond_st)}
                                      </h6>
                                    </div> */}

          {/* <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                      <p className="detail-title">
                                        Security Type Code
                                      </p>
                                      <h6>
                                        {getDisplayData(
                                          bondDetails?.bond_security_type_code
                                        )}
                                      </h6>
                                    </div> */}
          {/* <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                      <p className="detail-title">Security Code</p>
                                      <h6>
                                        {getDisplayData(
                                          bondDetails?.bond_security_code
                                        )}
                                      </h6>
                                    </div> */}
          {/* <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                      <p className="detail-title">Guaranted By</p>
                                      <h6>
                                        {getDisplaySelectOption(
                                          bondDetails?.bond_guaranted_by,
                                          "bond_guaranted_by"
                                        )}
                                      </h6>
                                    </div> */}

          {/* <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                      <p className="detail-title">Interest Days</p>
                                      <h6>
                                        {getDisplayData(
                                          bondDetails?.bond_interest_days
                                        )}
                                      </h6>
                                    </div>
    
                                    <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                      <p className="detail-title">
                                        Interest Calculate by
                                      </p>
                                      <h6>N/A</h6>
                                    </div>
                                    <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                      <p className="detail-title">
                                        Book Closer Date
                                      </p>
                                      <h6>
                                        {getFormattedLocalDateTime(
                                          bondDetails?.bond_closer_date!
                                        )}
                                      </h6>
                                    </div>
                                    <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                      <p className="detail-title">
                                        DMAT Book Closer Date
                                      </p>
                                      <h6>
                                        {getFormattedLocalDateTime(
                                          bondDetails?.bond_dmat_book_closer_date!
                                        )}
                                      </h6>
                                    </div>
    
                                    <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                      <p className="detail-title">RBI Loan Code</p>
                                      <h6>
                                        {getDisplayData(
                                          bondDetails?.bond_rbi_loan_code
                                        )}
                                      </h6>
                                    </div>
    
                                    <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                      <p className="detail-title">First IP Date</p>
                                      <h6>
                                        {getFormattedLocalDateTime(
                                          bondDetails?.bond_ip_date
                                        )}
                                      </h6>
                                    </div> */}
        </div>
      </div>
      <br />
      <div
        className="card"
        style={{
          borderTopLeftRadius: "10px",
          borderTopRightRadius: "10px",
          paddingTop: "10px",
        }}
      >
        <div className="card-header">
          <h5 className="m-0 header">Issuer Details</h5>
        </div>
        <div className="card-body">
          <div className="row">
            <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
              <p className="detail-title">Issuer's Type</p>
              <h6>
                {getDisplayData(issuerData?.issuer?.security_issuer_types)}
              </h6>
            </div>
            <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
              <p className="detail-title">Issuer Name</p>
              <h6>
                {getDisplayData(
                  issuerData?.issuer?.security_issuer_type
                    ?.security_issuer_type_name
                )}
              </h6>
            </div>
            {/* <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                        <p className="detail-title">
                                          Total Annual Revenue
                                        </p>
                                        <h6>
                                          {getDisplayData(
                                            bondDetails?.issuer
                                              ?.security_issuer_annual_revenue
                                          )}
                                        </h6>
                                      </div> */}
            <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
              <p className="detail-title">Year of Inception</p>
              <h6>
                {getDisplayData(
                  issuerData?.issuer?.security_issuer_founded_year
                )}
              </h6>
            </div>
            <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
              <p className="detail-title">Industry</p>
              <h6>
                {getDisplayData(issuerData?.issuer?.security_issuer_industry)}
              </h6>
            </div>
            <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
              <p className="detail-title">Ownership</p>
              <h6>
                {getDisplayData(issuerData?.issuer?.security_issuer_ownership)}
              </h6>
            </div>
            <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
              <p className="detail-title">Head Office</p>
              <h6>
                {getDisplayData(
                  issuerData?.issuer?.security_issuer_head_office
                )}
              </h6>
            </div>
            <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
              <p className="detail-title">Business Sector</p>
              <h6>
                {getDisplayData(issuerData?.issuer?.security_issuer_sector)}
              </h6>
            </div>
            <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
              <p className="detail-title">Current MD/CEO</p>
              <h6>
                {getDisplayData(
                  issuerData?.issuer?.security_issuer_cnt_authority
                )}
              </h6>
            </div>
            <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
              <p className="detail-title">Tax Free</p>
              <h6>
                {getDisplayData(issuerData?.issuer?.security_issuer_tax_free)}
              </h6>
            </div>
            <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
              <p className="detail-title">Seniority</p>
              <h6>
                {getDisplayData(issuerData?.issuer?.security_issuer_seniority)}
              </h6>
            </div>
            <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
              <p className="detail-title">Original Mode of issue</p>
              <h6>{getDisplayData(issuerData?.issuer.security_issuer_moi)}</h6>
            </div>
            <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
              <p className="detail-title">Name of debenture trustee</p>
              <h6>
                {getDisplayData(
                  issuerData?.issuer.security_issuer_debenture_trustee
                )}
              </h6>
            </div>

            <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
              <p className="detail-title">Coupon Type</p>
              <h6>
                {getDisplayData(
                  issuerData?.issuer.security_issuer_coupoun_type
                )}
              </h6>
            </div>
            <div className="col-lg-3 col-md-4 col-sm-6 mb-4 col-sm-3 col-lg-3">
              <p className="detail-title">ISSUE DOCUMENTS</p>
              {issuerData?.issuer?.issuer_document?.map(
                (doc: any, index: number) => (
                  <div
                    key={doc.security_issuer_document_id}
                    style={{ marginBottom: "8px" }}
                  >
                    <h6
                      style={{
                        // textDecoration: "underline",
                        cursor: "pointer",
                        display: "inline-flex",
                        alignItems: "center",
                      }}
                      onClick={() =>
                        getDownloadFile(doc.security_issuer_document_file)
                      }
                    >
                      {doc.security_issuer_document_name || "Untitled Document"}
                      <img
                        alt="icon"
                        src="../../assets/images/download.svg"
                        style={{
                          width: "12px",
                          marginBottom: "2px",
                          marginLeft: "10px",
                        }}
                      />
                    </h6>
                  </div>
                )
              )}
            </div>

            {/* <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                        <p className="detail-title">
                                          Issuers Profile Link
                                        </p>
                                        <h6 style={{ textDecoration: "underline" }}>
                                          <img
                                            src="../../assets/images/link.svg"
                                            style={{
                                              width: "12px",
                                              marginBottom: "2px",
                                              paddingRight: "5px",
                                            }}
                                          />
                                          N/A
                                        </h6>
                                      </div>
                                      <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                        <p className="detail-title">
                                          Issuers Finance Details
                                        </p>
                                        <h6 style={{ textDecoration: "underline" }}>
                                          <img
                                            src="../../assets/images/link.svg"
                                            style={{
                                              width: "12px",
                                              marginBottom: "2px",
                                              paddingRight: "5px",
                                            }}
                                          />
                                          N/A
                                        </h6>
                                      </div> */}
            {/* <div className="col-lg-12 col-md-12 col-sm-12  mb-4 col-sm-12 col-lg-12">
                                        <p className="detail-title">Paragraph</p>
                                        <h6>
                                          {getDisplayData(
                                            bondDetails?.bond_issuer_details?.[0]
                                              ?.bond_issuer_about
                                          )}
                                        </h6>
                                      </div> */}
            {/* <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
              <p className="detail-title">Rating Agency Name</p>
              <h6>{getDisplayData(issuerData?.security_rating_agency)}</h6>
            </div>
            <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
              <p className="detail-title">Rating</p>
              <h6>{getDisplayData(issuerData?.security_rating)}</h6>
            </div> */}
          </div>
        </div>
      </div>
      <div
        className="card"
        style={{
          borderTopLeftRadius: "10px",
          borderTopRightRadius: "10px",
          paddingTop: "10px",
        }}
      >
        <div className="card-header">
          <h5 className="m-0 header">Rating Details</h5>
        </div>
        <div className="card-body">
          <div className="row">
            {/* <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                  <p className="detail-title">
                                    Total Annual Revenue
                                  </p>
                                  <h6>
                                    {getDisplayData(
                                      bondDetails?.issuer
                                        ?.security_issuer_annual_revenue
                                    )}
                                  </h6>
                                </div> */}
            {/* <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                  <p className="detail-title">Tax Free</p>
                                  <h6>N/A</h6>
                                </div>
                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                  <p className="detail-title">Seniority</p>
                                  <h6>N/A</h6>
                                </div> */}
            {/* <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                  <p className="detail-title">
                                    Name of debenture trustee
                                  </p>
                                  <h6>N/A</h6>
                                </div>

                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                  <p className="detail-title">Coupon Type</p>
                                  <h6>N/A</h6>
                                </div> */}
            {/* <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                  <p className="detail-title">
                                    Issuers Profile Link
                                  </p>
                                  <h6 style={{ textDecoration: "underline" }}>
                                    <img
                                      src="../../assets/images/link.svg"
                                      style={{
                                        width: "12px",
                                        marginBottom: "2px",
                                        paddingRight: "5px",
                                      }}
                                    />
                                    N/A
                                  </h6>
                                </div>
                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                  <p className="detail-title">
                                    Issuers Finance Details
                                  </p>
                                  <h6 style={{ textDecoration: "underline" }}>
                                    <img
                                      src="../../assets/images/link.svg"
                                      style={{
                                        width: "12px",
                                        marginBottom: "2px",
                                        paddingRight: "5px",
                                      }}
                                    />
                                    N/A
                                  </h6>
                                </div> */}
            {/* <div className="col-lg-12 col-md-12 col-sm-12  mb-4 col-sm-12 col-lg-12">
                                  <p className="detail-title">Paragraph</p>
                                  <h6>
                                    {getDisplayData(
                                      bondDetails?.bond_issuer_details?.[0]
                                        ?.bond_issuer_about
                                    )}
                                  </h6>
                                </div> */}
            <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
              <p className="detail-title">Rating Agency Name</p>
              <h6>{getDisplayData(bondDetails?.security_rating_agency)}</h6>
            </div>
            <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
              <p className="detail-title">Rating</p>
              <h6>{getDisplayData(bondDetails?.security_rating)}</h6>
            </div>
          </div>
        </div>
      </div>
      <div
        className="card"
        style={{
          borderTopLeftRadius: "10px",
          borderTopRightRadius: "10px",
          paddingTop: "10px",
        }}
      >
        <div className="card-header">
          <h5 className="m-0 header">Key Highlights</h5>
        </div>
        <div className="card-body">
          <div className="row">
            <div className="col-lg-3 col-md-4 col-sm-6 mb-4 col-sm-3 col-lg-3">
              <div
                dangerouslySetInnerHTML={{
                  __html: issuerData?.issuer?.security_issuer_highlight || "",
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BondDetailComp;
