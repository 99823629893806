import React from "react";
import {
  Modal,
  Box,
  Typography,
  TextField,
  MenuItem,
  Button,
} from "@mui/material";

// Define props interface
interface OrderDetailStatusProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  status: string;
  setStatus: (status: string) => void;
  handleSubmit: () => void;
  orderDetails: any;
}

const OrderDetailStatus: React.FC<OrderDetailStatusProps> = ({
  open,
  setOpen,
  status,
  setStatus,
  handleSubmit,
  orderDetails,
}) => {
  const handleClose = () => setOpen(false);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="account-status-modal-title"
      aria-describedby="account-status-modal-description"
    >
      <Box
        sx={{
          position: "absolute" as "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 500,
          bgcolor: "background.paper",
          borderRadius: "8px",
          boxShadow: 24,
        }}
      >
        <Box sx={{ p: 2 }}>
          <Typography
            id="account-status-modal-title"
            variant="h6"
            component="h2"
            sx={{ color: "#a27b1e" }}
            gutterBottom
          >
            Order Status
          </Typography>
        </Box>

        <hr />
        <Box sx={{ p: 2 }}>
          {orderDetails?.order_type_uid === "ORD_PRE" ? (
            <TextField
              select
              fullWidth
              label="Status"
              value={status}
              onChange={(e) => setStatus(e.target.value)}
              sx={{ mb: 2 }}
            >
              <MenuItem value="ACCEPT">Accept</MenuItem>
              <MenuItem value="CANCEL">Reject</MenuItem>
            </TextField>
          ) : (
            <TextField
              select
              fullWidth
              label="Status"
              value={status}
              onChange={(e) => setStatus(e.target.value)}
              sx={{ mb: 2 }}
            >
              <MenuItem value="ACCEPT">RFQ Accept</MenuItem>
              <MenuItem value="CANCEL">RFQ Reject</MenuItem>
            </TextField>
          )}

          <Box sx={{ display: "flex", justifyContent: "flex-end", gap: 2 }}>
            <Button
              variant="outlined"
              onClick={handleClose}
              sx={{
                color: "#a27b1e",
                border: "1px solid #a27b1e",
                "&:hover": {
                  color: "#916c1a",
                  border: "1px solid #916c1a",
                },
              }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              onClick={handleSubmit}
              sx={{
                background: "#a27b1e",
                "&:hover": {
                  background: "#916c1a",
                },
              }}
            >
              Submit
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default OrderDetailStatus;
