import moment from "moment";
import { Fragment, useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useAppStateAPI } from "../common/AppStateAPI";
import {
  Annual_income_status,
  Depository,
  Experience,
  Gender,
  GenderString,
  kraStatus,
  MarriedStatus,
  PageLinks,
  PersonVideVerifyStatus,
  Status,
  Yes_or_No,
  AccountStatus,
} from "../common/Constants";
import { APIData } from "../common/DataTypes";
import Footer from "../common/Footer";
import Header from "../common/Header";
import { InitialData } from "../common/InitialValue";
import { ServerAPI } from "../common/ServerAPI";
import {
  getDisplayData,
  getDisplaySelectOption,
  getDownloadFile,
  getDownloadURLExtension,
  getFormattedLocalDate,
  getFormattedLocalDateTime,
  numberWithCommas,
} from "../common/Utilities";
import AccountStatusModal from "../modals/AccountStatusModal";

const BondDetails = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const ISINNumber = location.state;

  const [bondDetails, setBondDetails] = useState<any>(InitialData.BondDetails);
  const { setShowPreloader } = useAppStateAPI();
  const [isin, setIsin] = useState();

  useEffect(() => {
    loadBondDetail(ISINNumber);
    // console.log(moment(documentProofDetails?.personal_details_updated_at).format("DD-MM-YYYY"));
  }, [ISINNumber]);

  const loadBondDetail = (isin_number: string) => {
    if (isin_number !== "") {
      setShowPreloader(true);
      ServerAPI.bondDetail(isin_number)
        .then((res) => {
          console.log(
            "🚀 ~ file: BondDetails.tsx:38 ~ ServerAPI.bondDetail ~ res:",
            res
          );
          // console.log(res);
          setBondDetails(res?.data[0]);
          setIsin(res?.bond_isin_number);
        })
        .finally(() => {
          setShowPreloader(false);
        });
    }
  };

  return (
    <div>
      <div id="layout-wrapper">
        <Header />
        <div className="main-content">
          <div className="page-content">
            <div className="container-fluid">
              <div className="row">
                <div className="col-12">
                  <div className="page-title-box d-sm-flex align-items-center justify-content-between mt-4 pt-4">
                    <div className="page-title-rightds">
                      <ol className="breadcrumb ">
                        {/* <li className="breadcrumb-item">
                          <a style={{ color: "#90929E" }}>Trustmony</a>
                        </li> */}
                        <li
                          className="breadcrumb-item"
                          onClick={() => navigate(PageLinks.BOND_LIST)}
                        >
                          Bonds Lists
                        </li>
                        <li className="breadcrumb-item active">
                          <b>Detail View</b>
                        </li>
                      </ol>
                    </div>
                    <div
                      className="cursor-pointer"
                      onClick={() => navigate(-1)}
                    >
                      <p className="mb-0 active">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="14"
                          height="18"
                          viewBox="0 0 51.303 50.003"
                        >
                          <path
                            id="Path_22590"
                            fill="#A27B1E"
                            data-name="Path 22590"
                            d="M-1748.422,907.993a2.738,2.738,0,0,1-3.882,0l-22.259-22.248a2.736,2.736,0,0,1,0-3.882L-1752.3,859.6a2.738,2.738,0,0,1,3.882,0l2.542,2.542a2.751,2.751,0,0,1-.046,3.928l-13.8,13.145h32.908a2.741,2.741,0,0,1,2.748,2.748v3.664a2.742,2.742,0,0,1-2.748,2.748h-32.908l13.8,13.144a2.732,2.732,0,0,1,.046,3.928Z"
                            transform="translate(1775.371 -858.797)"
                          />
                        </svg>
                        <span> Back </span>
                      </p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <div className="card">
                        <div className="card-header col-xl-12 w-100">
                          <div className="d-flex justify-content-between align-items-center">
                            <h5 className="my-3 header"> Bond Details </h5>
                            <div className="">
                              <a
                                className="cancel-btn_1 border-light quicksand-medium px-2 mx-3 py-2"
                                onClick={() =>
                                  navigate(PageLinks.BOND_ADD, {
                                    state: { id: ISINNumber, mode: "edit" },
                                  })
                                }
                              >
                                <img
                                  alt="icon"
                                  src="../../assets/images/edit.svg"
                                  style={{ marginBottom: "4px", width: "16px" }}
                                />{" "}
                                Edit
                              </a>
                            </div>
                          </div>
                          <div
                            className="card"
                            style={{
                              borderTopLeftRadius: "10px",
                              borderTopRightRadius: "10px",
                              paddingTop: "10px",
                            }}
                          >
                            <div className="card-body">
                              <div className="row">
                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                  <p className="detail-title">Security Name</p>
                                  <h6>
                                    {getDisplayData(bondDetails?.security_name)}
                                  </h6>
                                </div>
                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                  <p className="detail-title">ISIN</p>
                                  <h6>
                                    {getDisplayData(bondDetails?.security_isin)}
                                  </h6>
                                </div>
                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                  <p className="detail-title">Security Type</p>
                                  <h6>
                                    {getDisplayData(
                                      bondDetails?.security_type
                                        ?.security_type_name
                                    )}
                                  </h6>
                                </div>
                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                  <p className="detail-title">
                                    Nature Of Instrument
                                  </p>
                                  <h6>
                                    {getDisplayData(bondDetails?.security_noi)}
                                  </h6>
                                </div>
                                {/* <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                  <p className="detail-title">
                                    Govt. Guarantee
                                  </p>
                                  <h6>
                                    {getDisplaySelectOption(
                                      bondDetails?.bond_govt_guranatee,
                                      "bond_govt_guranatee"
                                    )}
                                  </h6>
                                </div> */}

                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                  <p className="detail-title">Face Value</p>
                                  <h6>
                                    {numberWithCommas(
                                      bondDetails?.security_face_value
                                    )}
                                  </h6>
                                </div>
                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                  <p className="detail-title">Issue Price</p>
                                  <h6>
                                    {numberWithCommas(
                                      bondDetails?.security_issue_price
                                    )}
                                  </h6>
                                </div>
                                {/* <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                  <p className="detail-title">Price per Bond</p>
                                  <h6>
                                    {numberWithCommas(
                                      bondDetails?.bond_price_per_bond
                                    )}
                                  </h6>
                                </div> */}
                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                  <p className="detail-title">Issue Date</p>
                                  <h6>
                                    {getFormattedLocalDate(
                                      bondDetails?.security_issue_date
                                    )}
                                  </h6>
                                </div>
                                {/* <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                  <p className="detail-title">
                                    Interest Frequency
                                  </p>
                                  <h6>
                                    {getDisplayData(
                                      bondDetails?.security_interest_frequency
                                        ?.security_interest_frequency_name
                                    )}
                                  </h6>
                                </div> */}
                                {/* <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                  <p className="detail-title">
                                    Calc Int on Holiday
                                  </p>
                                  <h6>
                                    {getDisplaySelectOption(
                                      bondDetails?.bond_calc_int_on_holidays,
                                      "bond_calc_int_on_holidays"
                                    )}
                                  </h6>
                                </div> */}
                                {/* <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                  <p className="detail-title">
                                    Calc Int on Maturity
                                  </p>
                                  <h6>
                                    {getDisplaySelectOption(
                                      bondDetails?.bond_int_on_maturity,
                                      "bond_int_on_maturity"
                                    )}
                                  </h6>
                                </div> */}
                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                  <p className="detail-title">
                                    First Interest Date
                                  </p>
                                  <h6>
                                    {getFormattedLocalDate(
                                      bondDetails?.security_first_interest_date
                                    )}
                                  </h6>
                                </div>

                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                  <p className="detail-title">
                                    Book Closure Date
                                  </p>
                                  <h6>
                                    {getFormattedLocalDate(
                                      bondDetails?.security_book_closure_date
                                    )}
                                  </h6>
                                </div>
                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                  <p className="detail-title">Put Date</p>
                                  <h6>
                                    {getFormattedLocalDate(
                                      bondDetails?.security_put_date
                                    )}
                                  </h6>
                                </div>
                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                  <p className="detail-title">Call Date</p>
                                  <h6>
                                    {getFormattedLocalDate(
                                      bondDetails?.security_call_date
                                    )}
                                  </h6>
                                </div>
                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                  <p className="detail-title">Maturity Date</p>
                                  <h6>
                                    {getFormattedLocalDate(
                                      bondDetails?.security_maturity_date
                                    )}
                                  </h6>
                                </div>
                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                  <p className="detail-title">Cupon Rate</p>
                                  <h6
                                  // style={{
                                  //   textDecoration: "underline",
                                  //   cursor: "pointer",
                                  // }}
                                  // onClick={() =>
                                  //   getDownloadFile(bondDetails?.bond_logo)
                                  // }
                                  >
                                    {getDisplayData(
                                      bondDetails?.security_coupon_rate
                                    )}
                                  </h6>
                                </div>

                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                  <p className="detail-title">
                                    Norm Compound Flag
                                  </p>
                                  <h6>
                                    {getDisplayData(
                                      bondDetails?.security_norm_compound_flag
                                    )}
                                  </h6>
                                </div>
                                {/* <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                  <p className="detail-title">Exchange</p>
                                  <h6>
                                  {getDisplayData(bondDetails?.security_norm_compound_flag)}
                                  </h6>
                                </div> */}
                                {/* <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                  <p className="detail-title">Is Staggered</p>
                                  <h6>
                                    {getDisplayData(
                                      bondDetails?.security_staggered_status
                                    )}
                                  </h6>
                                </div> */}
                                {/* <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                  <p className="detail-title">Security Info Date</p>
                                  <h6>
                                  {getDisplayData(bondDetails?.security_staggered_status)}
                                  </h6>
                                </div> */}
                                {/* <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                  <p className="detail-title">Security Info Amount</p>
                                  <h6>
                                    {getDisplaySelectOption(
                                      bondDetails?.bond_security_type,
                                      "bond_security_type"
                                    )}
                                  </h6>
                                </div>
                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                  <p className="detail-title">Info Type</p>
                                  <h6>
                                    {getDisplaySelectOption(
                                      bondDetails?.bond_security_type,
                                      "bond_security_type"
                                    )}
                                  </h6>
                                </div> */}

                                {/* <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                  <p className="detail-title">ST</p>
                                  <h6>
                                    {getDisplayData(bondDetails?.bond_st)}
                                  </h6>
                                </div> */}

                                {/* <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                  <p className="detail-title">
                                    Security Type Code
                                  </p>
                                  <h6>
                                    {getDisplayData(
                                      bondDetails?.bond_security_type_code
                                    )}
                                  </h6>
                                </div> */}
                                {/* <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                  <p className="detail-title">Security Code</p>
                                  <h6>
                                    {getDisplayData(
                                      bondDetails?.bond_security_code
                                    )}
                                  </h6>
                                </div> */}
                                {/* <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                  <p className="detail-title">Guaranted By</p>
                                  <h6>
                                    {getDisplaySelectOption(
                                      bondDetails?.bond_guaranted_by,
                                      "bond_guaranted_by"
                                    )}
                                  </h6>
                                </div> */}

                                {/* <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                  <p className="detail-title">Interest Days</p>
                                  <h6>
                                    {getDisplayData(
                                      bondDetails?.bond_interest_days
                                    )}
                                  </h6>
                                </div>

                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                  <p className="detail-title">
                                    Interest Calculate by
                                  </p>
                                  <h6>N/A</h6>
                                </div>
                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                  <p className="detail-title">
                                    Book Closer Date
                                  </p>
                                  <h6>
                                    {getFormattedLocalDateTime(
                                      bondDetails?.bond_closer_date!
                                    )}
                                  </h6>
                                </div>
                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                  <p className="detail-title">
                                    DMAT Book Closer Date
                                  </p>
                                  <h6>
                                    {getFormattedLocalDateTime(
                                      bondDetails?.bond_dmat_book_closer_date!
                                    )}
                                  </h6>
                                </div>

                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                  <p className="detail-title">RBI Loan Code</p>
                                  <h6>
                                    {getDisplayData(
                                      bondDetails?.bond_rbi_loan_code
                                    )}
                                  </h6>
                                </div>

                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                  <p className="detail-title">First IP Date</p>
                                  <h6>
                                    {getFormattedLocalDateTime(
                                      bondDetails?.bond_ip_date
                                    )}
                                  </h6>
                                </div> */}
                              </div>
                            </div>
                          </div>
                          <br />
                          <div
                            className="card"
                            style={{
                              borderTopLeftRadius: "10px",
                              borderTopRightRadius: "10px",
                              paddingTop: "10px",
                            }}
                          >
                            <div className="card-header">
                              <h5 className="m-0 header">Issuer Details</h5>
                            </div>
                            <div className="card-body">
                              <div className="row">
                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                  <p className="detail-title">Issuer's Type</p>
                                  <h6>
                                    {getDisplayData(
                                      bondDetails?.issuer?.security_issuer_types
                                    )}
                                  </h6>
                                </div>
                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                  <p className="detail-title">Issuer Name</p>
                                  <h6>
                                    {getDisplayData(
                                      bondDetails?.issuer?.security_issuer_type
                                        ?.security_issuer_type_name
                                    )}
                                  </h6>
                                </div>
                                {/* <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                  <p className="detail-title">
                                    Total Annual Revenue
                                  </p>
                                  <h6>
                                    {getDisplayData(
                                      bondDetails?.issuer
                                        ?.security_issuer_annual_revenue
                                    )}
                                  </h6>
                                </div> */}
                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                  <p className="detail-title">
                                    Year of Inception
                                  </p>
                                  <h6>
                                    {getDisplayData(
                                      bondDetails?.issuer
                                        ?.security_issuer_founded_year
                                    )}
                                  </h6>
                                </div>
                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                  <p className="detail-title">Industry</p>
                                  <h6>
                                    {getDisplayData(
                                      bondDetails?.issuer
                                        ?.security_issuer_industry
                                    )}
                                  </h6>
                                </div>
                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                  <p className="detail-title">Ownership</p>
                                  <h6>
                                    {getDisplayData(
                                      bondDetails?.issuer
                                        ?.security_issuer_ownership
                                    )}
                                  </h6>
                                </div>
                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                  <p className="detail-title">Head Office</p>
                                  <h6>
                                    {getDisplayData(
                                      bondDetails?.issuer
                                        ?.security_issuer_head_office
                                    )}
                                  </h6>
                                </div>
                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                  <p className="detail-title">
                                    Business Sector
                                  </p>
                                  <h6>
                                    {getDisplayData(
                                      bondDetails?.issuer
                                        ?.security_issuer_sector
                                    )}
                                  </h6>
                                </div>
                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                  <p className="detail-title">Current MD/CEO</p>
                                  <h6>
                                    {getDisplayData(
                                      bondDetails?.issuer
                                        ?.security_issuer_cnt_authority
                                    )}
                                  </h6>
                                </div>
                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                  <p className="detail-title">Tax Free</p>
                                  <h6>
                                    {getDisplayData(
                                      bondDetails?.issuer
                                        ?.security_issuer_tax_free
                                    )}
                                  </h6>
                                </div>
                                {/* <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                  <p className="detail-title">Tax Free</p>
                                  <h6>N/A</h6>
                                </div>
                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                  <p className="detail-title">Seniority</p>
                                  <h6>N/A</h6>
                                </div> */}
                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                  <p className="detail-title">Seniority</p>
                                  {getDisplayData(
                                    bondDetails?.issuer
                                      ?.security_issuer_seniority
                                  )}
                                </div>
                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                  <p className="detail-title">
                                    Original Mode of issue
                                  </p>
                                  <h6>
                                    {getDisplayData(
                                      bondDetails?.issuer?.security_issuer_moi
                                    )}
                                  </h6>
                                </div>
                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                  <p className="detail-title">Exchange</p>
                                  <h6>
                                    {getDisplayData(
                                      bondDetails?.issuer?.exchange_id
                                    )}
                                  </h6>
                                </div>
                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                  <p className="detail-title">
                                    Name of debenture trustee
                                  </p>
                                  <h6>
                                    {" "}
                                    {getDisplayData(
                                      bondDetails?.issuer
                                        ?.security_issuer_debenture_trustee
                                    )}
                                  </h6>
                                </div>
                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                  <p className="detail-title">Coupon Type</p>
                                  {getDisplayData(
                                    bondDetails?.issuer
                                      ?.security_issuer_coupoun_type === "1"
                                      ? "Fixed"
                                      : ""
                                  )}
                                </div>
                                {/* <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                  <p className="detail-title">
                                    Name of debenture trustee
                                  </p>
                                  <h6>N/A</h6>
                                </div>

                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                  <p className="detail-title">Coupon Type</p>
                                  <h6>N/A</h6>
                                </div> */}
                                <div className="col-lg-3 col-md-4 col-sm-6 mb-4 col-sm-3 col-lg-3">
                                  <p className="detail-title">
                                    ISSUE DOCUMENTS
                                  </p>
                                  {bondDetails?.issuer?.issuer_document?.map(
                                    (doc: any, index: number) => (
                                      <div
                                        key={doc.security_issuer_document_id}
                                        style={{ marginBottom: "8px" }}
                                      >
                                        <h6
                                          style={{
                                            cursor: "pointer",
                                            display: "inline-flex",
                                            alignItems: "center",
                                          }}
                                          onClick={() =>
                                            getDownloadFile(
                                              doc.security_issuer_document_file
                                            )
                                          }
                                        >
                                          {doc.security_issuer_document_name ||
                                            "Untitled Document"}
                                          <img
                                            alt="icon"
                                            src="../../assets/images/download.svg"
                                            style={{
                                              width: "12px",
                                              marginBottom: "2px",
                                              marginLeft: "10px",
                                            }}
                                          />
                                        </h6>
                                      </div>
                                    )
                                  )}
                                </div>

                                {/* <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                  <p className="detail-title">
                                    Issuers Profile Link
                                  </p>
                                  <h6 style={{ textDecoration: "underline" }}>
                                    <img
                                      src="../../assets/images/link.svg"
                                      style={{
                                        width: "12px",
                                        marginBottom: "2px",
                                        paddingRight: "5px",
                                      }}
                                    />
                                    N/A
                                  </h6>
                                </div>
                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                  <p className="detail-title">
                                    Issuers Finance Details
                                  </p>
                                  <h6 style={{ textDecoration: "underline" }}>
                                    <img
                                      src="../../assets/images/link.svg"
                                      style={{
                                        width: "12px",
                                        marginBottom: "2px",
                                        paddingRight: "5px",
                                      }}
                                    />
                                    N/A
                                  </h6>
                                </div> */}
                                {/* <div className="col-lg-12 col-md-12 col-sm-12  mb-4 col-sm-12 col-lg-12">
                                  <p className="detail-title">Paragraph</p>
                                  <h6>
                                    {getDisplayData(
                                      bondDetails?.bond_issuer_details?.[0]
                                        ?.bond_issuer_about
                                    )}
                                  </h6>
                                </div> */}
                              </div>
                            </div>
                          </div>

                          {/* <div
                            className="card"
                            style={{
                              borderTopLeftRadius: "10px",
                              borderTopRightRadius: "10px",
                              paddingTop: "10px",
                            }}
                          >
                            <div className="card-header">
                              <h5 className="m-0 header">Issuer Documents</h5>
                            </div>
                            <div className="card-body">
                              <div className="row">
                                <div className="col-lg-3 col-md-4 col-sm-6 mb-4 col-sm-3 col-lg-3">
                                  
                                </div>
                              </div>
                            </div>
                          </div> */}
                          {/* <div
                            className="card"
                            style={{
                              borderTopLeftRadius: "10px",
                              borderTopRightRadius: "10px",
                              paddingTop: "10px",
                            }}
                          >
                            <div className="card-header">
                              <h5 className="m-0 header">
                                Issuer Debt Quality
                              </h5>
                            </div>
                            <div className="card-body">
                              <div className="row">
                                {bondDetails?.security_debt_quality?.map(
                                  (item: any) => {
                                    console.log("item ", item);

                                    return (
                                      <>
                                        {item?.security_id && (
                                          <div className="col-lg-4 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                            <p className="detail-title">
                                              Security
                                            </p>
                                            <h6>
                                              {getDisplayData(
                                                item?.security_id
                                              )}
                                            </h6>
                                          </div>
                                        )}
                                        {item?.debt_quality
                                          ?.debt_quality_name && (
                                          <div className="col-lg-4 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                            <p className="detail-title">
                                              Listed
                                            </p>
                                            <h6>
                                              {getDisplayData(
                                                item?.debt_quality
                                                  ?.debt_quality_name
                                              )}
                                            </h6>
                                          </div>
                                        )}
                                        {item?.debt_quality_description && (
                                          <div className="col-lg-4 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                            <p className="detail-title">
                                              Seniority
                                            </p>
                                            <h6>
                                              <div
                                                dangerouslySetInnerHTML={{
                                                  __html:
                                                    item?.debt_quality_description,
                                                }}
                                              />
                                            </h6>
                                          </div>
                                        )}
                                      </>
                                    );
                                  }
                                )}
                              </div>
                            </div>
                          </div> */}

                          <br />
                          <div
                            className="card"
                            style={{
                              borderTopLeftRadius: "10px",
                              borderTopRightRadius: "10px",
                              paddingTop: "10px",
                            }}
                          >
                            <div className="card-header">
                              <h5 className="m-0 header">Rating Details</h5>
                            </div>
                            <div className="card-body">
                              <div className="row">
                                {/* <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                  <p className="detail-title">
                                    Total Annual Revenue
                                  </p>
                                  <h6>
                                    {getDisplayData(
                                      bondDetails?.issuer
                                        ?.security_issuer_annual_revenue
                                    )}
                                  </h6>
                                </div> */}
                                {/* <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                  <p className="detail-title">Tax Free</p>
                                  <h6>N/A</h6>
                                </div>
                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                  <p className="detail-title">Seniority</p>
                                  <h6>N/A</h6>
                                </div> */}
                                {/* <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                  <p className="detail-title">
                                    Name of debenture trustee
                                  </p>
                                  <h6>N/A</h6>
                                </div>

                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                  <p className="detail-title">Coupon Type</p>
                                  <h6>N/A</h6>
                                </div> */}
                                {/* <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                  <p className="detail-title">
                                    Issuers Profile Link
                                  </p>
                                  <h6 style={{ textDecoration: "underline" }}>
                                    <img
                                      src="../../assets/images/link.svg"
                                      style={{
                                        width: "12px",
                                        marginBottom: "2px",
                                        paddingRight: "5px",
                                      }}
                                    />
                                    N/A
                                  </h6>
                                </div>
                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                  <p className="detail-title">
                                    Issuers Finance Details
                                  </p>
                                  <h6 style={{ textDecoration: "underline" }}>
                                    <img
                                      src="../../assets/images/link.svg"
                                      style={{
                                        width: "12px",
                                        marginBottom: "2px",
                                        paddingRight: "5px",
                                      }}
                                    />
                                    N/A
                                  </h6>
                                </div> */}
                                {/* <div className="col-lg-12 col-md-12 col-sm-12  mb-4 col-sm-12 col-lg-12">
                                  <p className="detail-title">Paragraph</p>
                                  <h6>
                                    {getDisplayData(
                                      bondDetails?.bond_issuer_details?.[0]
                                        ?.bond_issuer_about
                                    )}
                                  </h6>
                                </div> */}
                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                  <p className="detail-title">
                                    Rating Agency Name
                                  </p>
                                  <h6>
                                    {getDisplayData(
                                      bondDetails?.security_rating_agency
                                    )}
                                  </h6>
                                </div>
                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                  <p className="detail-title">Rating</p>
                                  <h6>
                                    {getDisplayData(
                                      bondDetails?.security_rating
                                    )}
                                  </h6>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            className="card"
                            style={{
                              borderTopLeftRadius: "10px",
                              borderTopRightRadius: "10px",
                              paddingTop: "10px",
                            }}
                          >
                            <div className="card-header">
                              <h5 className="m-0 header">Key Highlights</h5>
                            </div>
                            <div className="card-body">
                              <div className="row">
                                <div className="col-lg-3 col-md-4 col-sm-6 mb-4 col-sm-3 col-lg-3">
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html:
                                        bondDetails?.issuer
                                          ?.security_issuer_highlight || "",
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          {/* <div className="card">
                            <div className="card-header">
                              <h5 className="m-0 header">Category Details</h5>
                            </div>
                            <div className="card-body">
                              <div className="row">
                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                  <p className="detail-title">
                                    I - Institutional
                                  </p>
                                  <h6>
                                    {getDisplayData(
                                      bondDetails?.bond_category_institutional
                                    )}
                                    %
                                  </h6>
                                </div>
                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                  <p className="detail-title">
                                    II - Non-Institutiona1
                                  </p>
                                  <h6>
                                    {getDisplayData(
                                      bondDetails?.bond_category_non_institutional
                                    )}
                                    %
                                  </h6>
                                </div>
                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                  <p className="detail-title">III - HNI</p>
                                  <h6>
                                    {getDisplayData(
                                      bondDetails?.bond_category_hni
                                    )}
                                    %
                                  </h6>
                                </div>
                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                  <p className="detail-title">IV - Retail</p>
                                  <h6>
                                    {getDisplayData(
                                      bondDetails?.bond_category_retail
                                    )}
                                    %
                                  </h6>
                                </div>
                              </div>
                            </div>
                          </div> */}
                          <br />
                          {/* <div className="row">
                            <div className="col-lg-6 col-md-6 col-sm-6  mb-4 col-sm-6 col-lg-6">
                              <div className="card">
                                <div className="card-header">
                                  <h5 className="m-0 header">
                                    Maturity Details
                                  </h5>
                                </div>
                                <div className="card-body">
                                  <div className="row">
                                    <div className="col-lg-6 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                      <p className="detail-title">
                                        Maturity Date
                                      </p>
                                      <h6>
                                        {getDisplayData(
                                          bondDetails?.bond_maturity_date
                                        )}
                                      </h6>
                                    </div>
                                    <div className="col-lg-6 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                      <p className="detail-title">
                                        Maturity Amount
                                      </p>
                                      <h6>
                                        {numberWithCommas(
                                          bondDetails?.bond_maturity_amount
                                        )}
                                      </h6>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-6  mb-4 col-sm-6 col-lg-6">
                              <div className="card">
                                <div className="card-header">
                                  <h5 className="m-0 header">Coupon Details</h5>
                                </div>
                                <div className="card-body">
                                  <div className="row">
                                    <div className="col-lg-6 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                      <p className="detail-title">
                                        Coupon Date
                                      </p>
                                      <h6>
                                        {getDisplayData(
                                          bondDetails?.bond_coupon_date
                                        )}
                                      </h6>
                                    </div>
                                    <div className="col-lg-6 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                      <p className="detail-title">Coupon On</p>
                                      <h6>
                                        {numberWithCommas(
                                          bondDetails?.bond_coupon_on
                                        )}
                                      </h6>
                                    </div>
                                    <div className="col-lg-6 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                      <p className="detail-title">
                                        Coupon Rate
                                      </p>
                                      <h6>
                                        {getDisplayData(
                                          bondDetails?.bond_coupon_rate
                                        )}
                                      </h6>
                                    </div>
                                    <div className="col-lg-6 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                      <p className="detail-title">
                                        Coupon Type
                                      </p>
                                      <h6>
                                        {numberWithCommas(
                                          bondDetails?.bond_coupon_amount
                                        )}
                                      </h6>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div> */}
                          <br />
                          {/* <div className="row">
                            <div className="col-lg-6 col-md-6 col-sm-6  mb-4 col-sm-6 col-lg-6">
                              <div className="card">
                                <div className="card-header">
                                  <h5 className="m-0 header">Call Details</h5>
                                </div>
                                <div className="card-body">
                                  {bondDetails?.bond_call_details !=
                                    undefined &&
                                  bondDetails?.bond_call_details.length > 0 ? (
                                    bondDetails?.bond_call_details.map(
                                      (
                                        call: APIData.BondCallDetails,
                                        index: number
                                      ) => {
                                        return (
                                          <div className="row">
                                            <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                              <p className="detail-title">
                                                Call Date
                                              </p>
                                              <h6>
                                                {getDisplayData(
                                                  call.bond_call_detail_datetime
                                                )}
                                              </h6>
                                            </div>
                                            <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                              <p className="detail-title">
                                                Call Amount
                                              </p>
                                              <h6>
                                                {numberWithCommas(
                                                  call?.bond_call_detail_amount
                                                )}
                                              </h6>
                                            </div>
                                          </div>
                                        );
                                      }
                                    )
                                  ) : (
                                    <h5>No Data Found</h5>
                                  )}
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-6  mb-4 col-sm-6 col-lg-6">
                              <div className="card">
                                <div className="card-header">
                                  <h5 className="m-0 header">Put Details</h5>
                                </div>
                                <div className="card-body">
                        
                                  {bondDetails?.bond_put_details != undefined &&
                                  bondDetails?.bond_put_details.length > 0 ? (
                                    bondDetails?.bond_put_details.map(
                                      (
                                        put: APIData.BondPutDetails,
                                        index: number
                                      ) => {
                                        return (
                                          <div className="row">
                                            <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                              <p className="detail-title">
                                                Call Date
                                              </p>
                                              <h6>
                                                {getDisplayData(
                                                  put.bond_put_detail_datetime
                                                )}
                                              </h6>
                                            </div>
                                            <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                              <p className="detail-title">
                                                Call Amount
                                              </p>
                                              <h6>
                                                {numberWithCommas(
                                                  put.bond_put_detail_amount
                                                )}
                                              </h6>
                                            </div>
                                          </div>
                                        );
                                      }
                                    )
                                  ) : (
                                    <h5>No Data Found</h5>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div> */}
                          <br />
                          {/* <div className="card">
                            <div className="card-header">
                              <h5 className="m-0 header">
                                Interest Calculate by Yearly
                              </h5>
                            </div>
                            <div className="card-body">
                              <div className="table-rep-plugin">
                                <div
                                  className="table-responsive "
                                  data-pattern="priority-columns"
                                >
                                  <table
                                    id="tech-companies-1"
                                    className="table table table-bordered"
                                  >
                                    <thead className="city-table">
                                      <tr>
                                        <th className="text-center">Sr.No</th>
                                        <th className="text-center">
                                          Interest Date
                                        </th>
                                        <th className="text-center">
                                          Interest Rate
                                        </th>
                                        <th className="text-center">
                                          Interest Amount
                                        </th>
                                        <th className="text-center">
                                          No. of Days
                                        </th>
                                      </tr>
                                    </thead>
                                    {bondDetails?.bond_cashflow_details !==
                                      undefined &&
                                    bondDetails?.bond_cashflow_details?.length >
                                      0 ? (
                                      <tbody>
                                        {bondDetails?.bond_cashflow_details?.map(
                                          (
                                            cash: APIData.BondCashflowDetails,
                                            index: number
                                          ) => {
                                            return (
                                              <tr>
                                                <td className="text-center">
                                                  {index + 1}
                                                </td>
                                                <td className="text-center">
                                                  {getFormattedLocalDateTime(
                                                    cash?.bond_cashflow_date
                                                  )}
                                                </td>
                                                <td className="text-center">
                                                  {getDisplayData(
                                                    cash?.bond_cashflow_type
                                                  )}
                                                  %
                                                </td>
                                                <td className="text-center">
                                                  ₹{" "}
                                                  {numberWithCommas(
                                                    cash?.bond_cashflow_amount
                                                  )}
                                                </td>
                                                <td className="text-center">
                                                  {getDisplayData(
                                                    cash?.bond_cashflow_days
                                                  )}
                                                </td>
                                              </tr>
                                            );
                                          }
                                        )}
                                      </tbody>
                                    ) : (
                                      <h5>No Data Found</h5>
                                    )}
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div> */}
                          <br />
                          {/* <div className="card">
                            <div className="card-header">
                              <h5 className="m-0 header">
                                Bond's Rating Details
                              </h5>
                            </div>
                            {bondDetails?.bond_rating_details?.map((rating) => (
                              <div className="card-body">
                                <div className="row">
                                  <div className="col-lg-6 col-md-4 col-sm-6  mb-4 col-sm-3">
                                    <p className="detail-title">
                                      Rating Agency
                                    </p>
                                    <h6>
                                      {getDisplayData(
                                        rating?.bond_rating_agency
                                      )}
                                    </h6>
                                  </div>
                                  <div className="col-lg-6 col-md-4 col-sm-6  mb-4 col-sm-3">
                                    <p className="detail-title">
                                      Bond’s Rating Certification
                                    </p>
                                    <h6
                                      style={{
                                        textDecoration: "underline",
                                        cursor: "pointer",
                                      }}
                                      onClick={() =>
                                        getDownloadFile(bondDetails?.bond_logo)
                                      }
                                    >
                                      {getDownloadURLExtension(
                                        rating?.bond_rating_certificate
                                      )}{" "}
                                      <img
                                        alt="icon"
                                        src="../../assets/images/download.svg"
                                        style={{
                                          width: "12px",
                                          marginBottom: "2px",
                                        }}
                                      />
                                    </h6>
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div> */}
                          <br />
                          {/* <div className="card">
                            <div className="card-header">
                              <h5 className="m-0 header">
                                Investment Purpose Categories
                              </h5>
                            </div>
                            <div className="card-body">
                              <div className="container text-center">
                                <div className="row row-cols-8">
                                  {bondDetails?.bond_brand_tags != undefined &&
                                  bondDetails?.bond_brand_tags.length > 0 ? (
                                    bondDetails?.bond_brand_tags.map(
                                      (
                                        tags: APIData.BondBrandTags,
                                        index: number
                                      ) => {
                                        return (
                                          <div
                                            className={`col-3 brand-tag${
                                              tags.bond_brand_tag_available !=
                                                null &&
                                              tags.bond_brand_tag_available != 0
                                                ? "-active"
                                                : ""
                                            } p-1 mx-2 my-2 fs-6 text-center d-flex align-items-center justify-content-center`}
                                          >
                                            {getDisplayData(
                                              tags.brand_tag_name
                                            )}
                                          </div>
                                        );
                                      }
                                    )
                                  ) : (
                                    <h5>No Data Found</h5>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div> */}
                          <br />
                          {/* <div className="card">
                            <div className="card-header">
                              <h5 className="m-0 header">
                                Eligible Investors Details
                              </h5>
                            </div>
                            <div className="card-body">
                              <div className="container text-center">
                                <div className="row row-cols-12 ">
                                  {bondDetails?.bond_eligible_investors !=
                                    undefined &&
                                  bondDetails?.bond_eligible_investors.length >
                                    0
                                    ? bondDetails?.bond_eligible_investors.map(
                                        (
                                          inv: APIData.BondEligibleInvestors,
                                          index: number
                                        ) => {
                                          return (
                                            <div
                                              className={`col-3 brand-tag${
                                                inv.eligible_investors_available !=
                                                  null &&
                                                inv.eligible_investors_available !=
                                                  0
                                                  ? "-active"
                                                  : ""
                                              } p-1 mx-2 my-2 fs-6 text-center d-flex align-items-center justify-content-center`}
                                            >
                                              {getDisplayData(
                                                inv.eligible_investor_name
                                              )}
                                            </div>
                                          );
                                        }
                                      )
                                    : ""}
                                </div>
                              </div>
                            </div>
                          </div>{" "} */}
                          <br />
                          {/* <div className="card">
                            <div className="card-header">
                              <h5 className="m-0 header">About This Bond</h5>
                            </div>
                            <div className="card-body">
                              <div className="container text-center">
                                <div className="row row-cols-12 ">
                                  <h6>
                                    {getDisplayData(
                                      bondDetails?.bond_about_this_ipo
                                    )}
                                  </h6>
                                </div>
                              </div>
                            </div>
                          </div> */}
                          <br />
                          {/* <div className="card">
                            <div className="card-header">
                              <h5 className="m-0 header">Key Highlights</h5>
                            </div>
                            <div className="card-body">
                              <ul>
                                <li>
                                  {getDisplayData(
                                    bondDetails?.bond_key_highlights
                                  )}
                                </li>
                              </ul>
                            </div>
                          </div> */}
                          <br />

                          <br />
                          {/* <div className="row">
                                                        <div className="card" style={{ borderTopLeftRadius: "10px", borderTopRightRadius: "10px", paddingTop: "10px", paddingBottom: "20px" }}>
                                                            <div className="card-header d-flex justify-content-between align-items-center">
                                                                <div className='d-flex justify-content-start'>
                                                                    <h5 className="m-0 header"> Specific Terms for Each Series of NCDs Details </h5>
                                                                </div>
                                                                <div className='d-flex justify-content-end align-items-center '>
                                                                    <div className="form-check form-switch mx-2">
                                                                        <label className="form-check-label" htmlFor="flexSwitchCheckChecked">Disable/Enable</label>
                                                                        <input className="form-check-input " type="checkbox" role="switch" id="flexSwitchCheckChecked" checked />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="card-body">
                                                                <div className="table-responsive " data-pattern="priority-columns">
                                                                    <table id="tech-companies-1" className="table table table-bordered">
                                                                        <thead className="city-table">
                                                                            <tr>
                                                                                <th>Sr. No</th>
                                                                                <th>Series 1</th>
                                                                                <th>Series 2</th>
                                                                                <th>Series 3</th>
                                                                                <th>Series 4</th>
                                                                                <th>Series 5</th>
                                                                                <th>Series 6</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            <tr >
                                                                                <td>1</td>
                                                                                <td>Frequency of Interest Payment</td>
                                                                                <td>Monthly</td>
                                                                                <td>Cumulative</td>
                                                                                <td>Monthly</td>
                                                                                <td>Cumulative</td>
                                                                                <td>Monthly</td>
                                                                            </tr>
                                                                            <tr >
                                                                                <td>2</td>
                                                                                <td>Tenor</td>
                                                                                <td>Monthly</td>
                                                                                <td>Cumulative</td>
                                                                                <td>Monthly</td>
                                                                                <td>Cumulative</td>
                                                                                <td>Monthly</td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div> */}
                          {/* <div className="card-body">
                            <div
                              className="table-responsive "
                              data-pattern="priority-columns"
                            >
                              <table
                                id="tech-companies-1"
                                className="table table table-bordered"
                              >
                                <thead className="city-table">
                                  <tr>
                                    <th></th>
                                    <th>Name</th>
                                    {bondDetails?.bond_ncd_series?.map(
                                      (
                                        res: APIData.BondNcdSeries,
                                        index: number
                                      ) => {
                                        return <th>Series{index + 1}</th>;
                                      }
                                    )}
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    <td>1</td>
                                    <td>Frequency of Interest Payment</td>
                                    {bondDetails?.bond_ncd_series?.map(
                                      (
                                        feq: APIData.BondNcdSeries,
                                        inx: number
                                      ) => {
                                        return (
                                          <td>
                                            {getDisplaySelectOption(
                                              feq.bond_ncd_frequency_of_interest_payment,
                                              "bond_ncd_frequency_of_interest_payment"
                                            )}
                                          </td>
                                        );
                                      }
                                    )}
                                  </tr>
                                  <tr>
                                    <td>2</td>
                                    <td>Tenor</td>
                                    {bondDetails?.bond_ncd_series?.map(
                                      (
                                        feq: APIData.BondNcdSeries,
                                        inx: number
                                      ) => {
                                        return <td>{feq?.bond_ncd_tenor}</td>;
                                      }
                                    )}
                                  </tr>
                                  <tr>
                                    <td>3</td>
                                    <td>Coupon (% per annum)</td>
                                    {bondDetails?.bond_ncd_series?.map(
                                      (
                                        feq: APIData.BondNcdSeries,
                                        inx: number
                                      ) => {
                                        return <td>{feq.bond_ncd_coupon}</td>;
                                      }
                                    )}
                                  </tr>
                                  <tr>
                                    <td>4</td>
                                    <td>Effective Yield (% per annum)</td>
                                    {bondDetails?.bond_ncd_series?.map(
                                      (
                                        feq: APIData.BondNcdSeries,
                                        inx: number
                                      ) => {
                                        return (
                                          <td>
                                            {feq?.bond_ncd_effective_yield}
                                          </td>
                                        );
                                      }
                                    )}
                                  </tr>
                                  <tr>
                                    <td>5</td>
                                    <td>
                                      Redemption Amount (₹ / NCD) on Maturity
                                    </td>
                                    {bondDetails?.bond_ncd_series?.map(
                                      (
                                        feq: APIData.BondNcdSeries,
                                        inx: number
                                      ) => {
                                        return (
                                          <td>
                                            {feq?.bond_ncd_redemption_amount}
                                          </td>
                                        );
                                      }
                                    )}
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                          <div className="row">
                            <div
                              className="card"
                              style={{
                                borderTopLeftRadius: "10px",
                                borderTopRightRadius: "10px",
                                paddingTop: "10px",
                                paddingBottom: "20px",
                              }}
                            >
                              <div className="card-header">
                                <h5 className="m-0 header"> Disclaimer </h5>
                              </div>
                              <div className="card-body">
                                <h6>
                                  {getDisplayData(bondDetails?.bond_disclaimer)}
                                </h6>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-lg-6 col-md-6 col-sm-6  mb-4 col-sm-6 col-lg-6">
                              <div className="card">
                                <div className="card-header">
                                  <h5 className="m-0 header">ASBA Details</h5>
                                </div>
                                <div className="card-body">
                                  <div className="row">
                                    <h6 style={{ textDecoration: "underline" }}>
                                      <img
                                        src="../../assets/images/download.svg"
                                        style={{
                                          width: "12px",
                                          marginBottom: "2px",
                                          paddingRight: "5px",
                                        }}
                                      />
                                      Document.pdf
                                    </h6>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-6  mb-4 col-sm-6 col-lg-6">
                              <div className="card">
                                <div className="card-header">
                                  <h5 className="m-0 header">
                                    Terms & Conditions Link
                                  </h5>
                                </div>
                                <div className="card-body">
                                  <div className="row">
                                    <h6 style={{ textDecoration: "underline" }}>
                                      <img
                                        src="../../assets/images/link.svg"
                                        style={{
                                          width: "12px",
                                          marginBottom: "2px",
                                          paddingRight: "5px",
                                        }}
                                      />
                                      https://www.dummylink.org/dummy.html
                                    </h6>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
      {/* <AccountStatusModal userId={userId} handleOpen={() => handleClickOpen} openModal={open} handleClose={(e: any) => handleClose(e)} /> */}
      {/* <SimpleModal contents={res.suspended_remarks} handleOpen={() => handleClickOpen} openModal={open} handleClose={(e: any) => handleClose(e)} /> */}
    </div>
  );
};

export default BondDetails;
