export enum PageLinks {
  LOGIN = "/login",
  DASHBOARD = "/dashboard",
  CITIES = "/cities",
  USERS = "/users",
  USERSDETAILS = "/users-details",
  IPVVERIFY = "/ipv-verification",
  PANVERIFY = "/pan-verification",
  SUSPENDED_USER_LIST = "/suspended-user-list",
  ALL_USERS_LOGS = "/all-users-logs",
  ISIN_ORDER_HISTORY = "/isin-order-history",
  INDIVIDUAL_USER_LOGS = "/user-logs",
  BOND_LIST = "/bond-list",
  POLICY_LIST = "/policy-list",
  TERMS_LIST = "/terms-list",
  DP_NAME = "/dp_name",
  ADD_DP_NAME = "/dp_name-add",
  BOND_ADD = "/bond-add",
  IPO_ADD = "/ipo-add",
  BOND_DETAIL = "/bond-detail",
  IPO_DETAIL = "/ipo-detail",
  BROKER_DETAIL = "/broker-detail",
  IPO_FORM_LIST = "/ipo-form-list",
  IPO_FORM_ADD = "/ipo-form-add",
  DEALER_LIST = "/dealers-list",
  DEALER_ADD = "/dealers-add",
  DEALER_Detail = "/dealer-detail",
  DISTRIBUTOR_LIST = "/distributor-list",
  HOLIDAY_LIST = "/holiday-list",
  IPO_CATEGORY_LIST = "/ipo-category-list",
  SETTLEMENT_LIST = "/settlement-list",
  DISTRIBUTOR_ADD = "/distributor-add",
  DISTRIBUTOR_TYPE_LIST = "/distributor-type-list",
  DISTRIBUTOR_TYPE_ADD = "/distributor-type-add",
  DISTRIBUTOR_SUB_TYPE_LIST = "/distributor-sub-type-list",
  DISTRIBUTOR_SUB_TYPE_ADD = "/distributor-sub-type-add",
  DISTRIBUTOR_CATEGORY_LIST = "/distributor-category-list",
  DISTRIBUTOR_CATEGORY_ADD = "/distributor-category-add",
  BRAND_TAG_LIST = "/brand-tag-list",
  BRAND_TAG_ADD = "/brand-tag-add",
  ELIGIBLE_INVENTOR_LIST = "/eligible-investor-list",
  ELIGIBLE_INVENTOR_ADD = "/eligible-investor-add",
  BROKER_LIST = "/broker-list",
  BROKER_ADD = "/broker-add",
  ASBA_LIST = "/ASBA-list",
  ASBA_ADD = "/ASBA-add",
  SGB_LIST = "/SGB-list",
  SGB_ADD = "/SGB-add",
  SGB_DETAIL = "/SGB-detail",
  DISTRIBUTOR_DETAIL = "/distributor-detail",
  IPO_FORM_ALLOCATION = "/ipo-form-allocation",
  BOND_BROKERAGE_CATEGORIE_LIST = "/bond-brokerage-categorie-list",
  BOND_BROKERAGE_CATEGORIE_ADD = "/bond-brokerage-categorie-add",
  CUSTOMER_BOOK = "/customer-book",
  CUSTOMER_BOOK_DETAIL_VIEW = "/customer-book-detail-view",
  BOND_SUBCRIBE_LIST = "/bond-subcribe-list",
  TRUSTMONEY_UERS = "/trust_money_users",
  BOND_PRICE_DETAIL_VIEW = "/bond_price_detail_view",
  USER_ORDER_DETAIL_VIEW = "/user-order-detail-view",
  IPO_ORDER_DETAIL_VIEW = "/ipo-order-detail-view",
  BOND_ORDER_DETAIL_VIEW = "/bond-order-detail-view",
  SGB_ORDER_DETAIL_VIEW = "/sgb-order-detail-view",
  DISTRIBUTOR_TYPE_EDIT = "/distributor-type-Edit",
  API_STATUS = "/api-status",
  ISINORDERCUSTDETAILS = "/cust/:id/:batch_no",
  ISINORDERDETAILS = "/isindetails/:id/:batch_no",
}

export enum Endpoints {
  login = "signin",
  verify_otp = "signin/verify",
  usersList = "user",
  userXl = "user/dl",
  user_details = "user",
  remark_list = "master/remarks",
  verify_ipv = "admin_ipv_details",
  update_ipv_status = "admin_ipv_details",
  Broker_list = "broker-list",
  DP_Name = "master/depository-participant",
  Distribut_list = "distributors-list",
  suspended_list = "suspension_users_data_details",
  ASBA_list = "asba-location-list",
  LogXl = "user-logs-xl",
  update_account_status = "user",
  suspension_status_update = "user",
  suspension_status_active = "user",
  all_users_logs = "user-log",
  individual_user_logs = "user-log",
  user_logs_module_dropdown = "user_logs_module",
  isin_order_history = "orders/isin",
  isin_order_history_specific = "orders/isin/specific",
  order_history_specific_cust = "orders",
  dealers = "dealer",
  setDealerStatus = "dealers/set-status",
  setDistributorStatus = "distributor-types",
  SetBroker = "broker-status",
  setASBA = "asba-location-status",
  setDistributorSubStatus = "distributor-sub-types",
  setDistributorCatStatus = "distributor-categories",
  BrangTag = "brand-tags",
  EligibleTagsList = "eligible-investors",
  BrokerageList = "bond-brokerage-categories",
  dealerLoginDetails = "dealers/login-details",
  bond = "securities",
  policyList = "master/privacy-policy",
  termsList = "master/terms-condition",
  holiday = "security-holiday-calendar",
  ipoCategories = "securities/category",
  ipoBrokerageCategories = "securities/ipo-category",
  deleteIpoCategories = "securities/category",
  settlementCalendar = "security-settlement-calendar",
  BrokerListID = "broker",
  DistributListID = "distributors",
  ifsc_code = "kyc/ifsc-detail",
  bondDetail = "securities",
  brandTags = "brand-tags",
  segmentlist = "master/distributor-segment",
  companieslist = "master/distributor-companies",
  emplist = "master/distributor-empanelment",
  eligibleInvestor = "eligible-investors",
  apistatus = "api-status",
  IPO = "securities",
  IPO_CATEGORY = "securities/ipo-category",
  DocumentUpload = "asba-file",
  populateBond = "populate_bonds",
  populateSGB = "populate_sgb",
  getSpecificSGB = "specific_sgb_details",
  ipoFormAllocation = "ipo-form-allocations",
  Distributor_type = "distributor-types",
  ASBA_type = "asba-location",
  Broker_type = "broker",
  Distribut_type = "distributors",
  Put_ASBA_List = "asba-location",
  PutBroker_List = "broker",
  PutDistribut_List = "distributors",
  Distributor_sub_type = "distributor-sub-types",
  DistributorCategory = "distributor-categories",
  stateList = "master/state",
  BrokerageCategory = "bond-brokerage-categories",
  BondRatingList = "bond_rating_list",
  loadGender = "master/gender",
  CustomerOrderList = "orders",
  ViewInsights = "orders/insights",
  SpecificOrderList = "specific_orders_id",
  BondSubcribe = "bond-subscribes",
  CustomerEnquiryList = "tm-dealer/bond-enquiry",
  ExcelUploadForBondDetails = "securities-listing/file",
  ExcelUploadForBSESettlementCalendar = "security-settlement-calendar?exchange_id=1",
  ExcelUploadForNSESettlementCalendar = "security-settlement-calendar?exchange_id=2",
  ExcelUploadForISINUpload = "securities/sync",
  ExcelUploadForPolicyUpload = "master/privacy-policy",
  ExcelUploadForTermsUpload = "master/terms-condition",
  syncHolidayApi = "security-holiday-calendar",
  SecurityIssuerUpload = "securities/issuer-upload",
  SecuritiesInventory = "securities-listing",
  IpoCategory = "securities/category",
  SecuritiesAddInventory = "securities-listing",
  ActiveBondList = "securities-listing",
  ActiveBondListMaster = "securities",
  BondDetailsUpdate = "admin/securities",
  AllBondList = "securities",
  PriceChangeLogs = "securities-price_history",
  SpecificOrder = "orders",
  settlement_date = "security-settlement",
  Yields = "security-yield-type",
  aidcc = "security-ai-dcc",
  YeildPriceCalculator = "securities-price-yield",
  Calculater = "customer/bond-enquiry",
}

export const Strings = {
  ValidationRequired: "Required",
  ValidationSelect: "Please Select",
  ValidationEmail: "Not a Valid Email",
  ValidationNumber: "Not a Valid Numeric",
  ValidationMax: (max: number) => "Max " + max + " Characters",
  ErrorAPIFailed: "Error Occurred while processing the request",
  // ValidationInPercentage: 'Required Feild'
};

export const wysiwygToolbar = {
  options: ["inline", "list", "link", "emoji", "history"],
  inline: {
    options: ["bold", "italic", "underline", "strikethrough"],
  },
};

export const NCDDetailsList = [
  {
    header: [
      // {
      //     name: 'Sr.No'
      // },
      // {
      //     name: 'Name'
      // },
      {
        name: "Serial 1",
      },
    ],
    content: [
      {
        name: "Frequency of Interest Payment",
        id: 1,
      },
      {
        name: "Minimum Application (₹)",
        id: 1,
      },
      {
        name: "In Multiples of thereafter (₹)",
        id: 1,
      },
      {
        name: "Face Value/ Issue Price of NCDs (`/ NCD) (₹)",
        id: 1,
      },
      {
        name: "Tenor",
        id: 1,
      },
      {
        name: "Coupon (% per annum)",
        id: 1,
      },
      {
        name: "Effective Yield (% per annum)",
        id: 1,
      },
      {
        name: "Mode of Interest Payment",
        id: 1,
      },
      {
        name: "Redemption Amount (₹ / NCD) on Maturity",
        id: 1,
      },
      {
        name: "Put and Call Option",
        id: 1,
      },
      {
        name: "Type Here",
        id: 1,
      },
      {
        name: "Type Here",
        id: 1,
      },
      {
        name: "Type Here",
        id: 1,
      },
    ],
  },
];
export const ShortTermList = [
  {
    header: [
      {
        name: "Sr.No",
      },
      {
        name: "Name",
      },
      {
        name: "Series 1 (36 Months)",
      },
      {
        name: "Series 2 (36 Months)",
      },
      {
        name: "Series 3 (36 Months)",
      },
      {
        name: "Series 4 (36 Months)",
      },
      {
        name: "Series 5 (36 Months)",
      },
      {
        name: "Series 6 (36 Months)",
      },
      // {
      //   name: "Action",
      // },
    ],
    content: [
      {
        name: "Cat I - Institutional Investor",
        // series_Value: 'Monthly'
      },
      {
        name: "Cat II - Institutional Investor",
        // series_Value: 'Monthly'
      },
      {
        name: "Cat III - Institutional Investor",
        // series_Value: 'Monthly'
      },
      {
        name: "Cat IV - Institutional Investor",
        // series_Value: 'Monthly'
      },
    ],
  },
];

export const APIResult = {
  SUCCESS: true,
  FAILURE: false,
};

export const Status = {
  VERIFY: 1,
  NOT_VERIFY: 0,
};

export const Gender = {
  MALE: 1,
  FEMALE: 2,
  OTHER: 3,
};
export const GenderString = {
  MALE: "Male",
  FEMALE: "Female",
  OTHER: "Others",
};
export const MarriedStatus = {
  SINGLE: 1,
  MARRIED: 2,
};
export const Annual_income_status = {
  below_one: 1,
  one_to_five: 2,
  five_to_ten: 3,
  ten_to_twentyfive: 4,
  above_twntyfive: 5,
};

export const AccountStatus = {
  Register: 0,
  Active: 1,
  Reject: 2,
  Suspend: 3,
  Close: 4,
};

export const Experience = {
  below_one: 1,
  one_to_two: 2,
  two_to_five: 3,
  five_to_ten: 4,
  ten_to_twenty: 5,
  twenty_to_twentyfive: 6,
  above_twentyfive: 7,
};

export const PersonVideVerifyStatus = {
  VERIFY: 1,
  NOT_VERIFY: 0,
  REJECT: 2,
};

export const kraStatus = {
  YES: 1,
  NO: 0,
};

export const BondType = {
  IPO: 1,
  ADminBond: 2,
  ExternalAPIBond: 3,
  SGB: 4,
};
export const BondTypes = {
  IPO: 1,
  ADminBond: 2,
  SGB: 3,
};
export const OrderType = {
  IPO: 1,
  ADminBond: 2,
  SGB: 3,
};
export const Yes_or_No = {
  YES: 1,
  NO: 0,
};

export const Depository = {
  CSDL: 1,
  NSDL: 2,
};

export const DealerStatus = {
  Active: 1,
  Disabled: 0,
};

export const SGBUpcomingStatus = {
  Active: 1,
  Disabled: 0,
};

export const NCDStatus = {
  Active: 1,
  Disabled: 0,
};

export const DistributorTypeStatus = {
  Active: 1,
  Disabled: 0,
};
export const DistributorSubTypeStatus = {
  Active: 1,
  Disabled: 0,
};
export const DistributorCategoryStatus = {
  Active: 1,
  Disabled: 0,
};
export const BrandTagStatus = {
  Active: 1,
  Disabled: 0,
};
export const EligibleTagStatus = {
  Active: 1,
  Disabled: 0,
};
export const BondIPOStatus = {
  Active: 1,
  Disabled: 0,
};
export const ASBAStatus = {
  Active: 1,
  Disabled: 0,
};

// export const CustomerOrderStatus = {
//     Success: 20,
//     Pending: 30,
//     UnderProcessing: 40
// }
export const CustomerOrderStatus = {
  OrderPlaced: 1,
  OrderModified: 2,
  OrderCancelled: 3,
  OrderAccepted: 4,
  OrderRejected: 5,
};
export const PaymentStatus = {
  Processing: 1,
  Done: 2,
  Failed: 3,
};
export const OpratStatus = {
  Success: 1,
  Failed: 2,
};
export const ApiStatus = {
  Success: 200,
};
export const DealerActiveBondStatus = {
  UnPublish: 0,
  Publish: 1,
};
export const DealerActiveBondStatusNew: any = {
  UnPublish: false,
  Publish: true,
};
export const ProjectConfig = {
  EntriesPerPage: 10,
};

export const API = {
  BaseUrl: "https://v2-trust-api.trustmony.com/api/v2/admin/", // Dev url
  //  BaseUrl: "https://api.trustmony.com/admin/api/v1/", //Live url
  DealerBaseUrl: "https://v2-trust-api.trustmony.com/api/v2/admin/", // Dev url
  // DealerBaseUrl: "https://trust-api.trustmony.com/api/v1/", // Dev url
  BankBaseUrl: "https://v2-trust-api.trustmony.com/api/v2/admin/", // Dev url
  // BankBaseUrl: "https://trust-ekyc-api.trustmony.com/api/v1/", // Dev url
  MasterUrl: "https://v2-trust-api.trustmony.com/api/v2/master/",
  EndPoint: Endpoints,
  Result: APIResult,
};
