import Pagination from "@mui/material/Pagination";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
// import DatePicker from 'react-date-picker';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppStateAPI } from "../common/AppStateAPI";
import {
  Depository,
  DealerStatus,
  DistributorTypeStatus,
  PageLinks,
} from "../common/Constants";
import Footer from "../common/Footer";
import DataTable from "react-data-table-component";
import Header from "../common/Header";
import {
  getDisplayData,
  getDealerStatus,
  getFormattedLocalDateTime,
} from "../common/Utilities";
import { ServerAPI } from "../common/ServerAPI";
import ConfirmationModal from "../modals/Confirmation-modal";
import SimpleModal from "../modals/SimpleModal";
import { APIData } from "../common/DataTypes";

var startSerialNo = 0;
const BrokerList: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const passing_value = location.state;
  const [preData, setPreData] = useState(passing_value);
  const [brokerId, setBrokerId] = useState(0);

  // const [value, onChange] = useState(new Date());
  const [toData, setToData] = useState<null | Date>();
  const [fromDate, setFromDate] = useState<null | Date>();
  const [open, setOpen] = useState(false);
  const [open_modal, setOpenModal] = useState(false);
  const [Brokerlist, setBrokerlist] = useState<any>([]);
  const [totalCount, setTotalCount] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [user_id, setUserId] = useState();
  const [view_content, setViewContent] = useState();
  const [search_text, setSearchText] = useState("");
  const { setShowPreloader } = useAppStateAPI();
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const pageCount = Math.ceil(totalCount / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const currPage = currentPage ? currentPage : 1;
  startSerialNo = currPage * 10 - 10;
  // const [selectedItems, setSelectedItems] = React.useState<any[]>([]);

  let columns: any = [
    {
      name: "Sr.No",
      width: "90px",
      cell: (row: any, index: any) => startSerialNo + index + 1, //RDT provides index by default
    },
    {
      name: "Bank Code ",
      selector: (row: any) => getDisplayData(row.broker_name),
      sortable: true,
    },
    {
      name: "Bank Name",
      selector: (row: any) => getDisplayData(row.broker_nse_code),
      sortable: true,
      width: "400px",
      wrap: true,
    },
    {
      name: "Location Short Name",
      selector: (row: any) => getDisplayData(row.broker_bse_code),
      sortable: true,
      width: "150px",
      wrap: true,
    },
    {
      name: "Location Full Name",
      selector: (row: any) => getDisplayData(row.advisory_name),
      sortable: true,
      width: "150px",
      wrap: true,
    },
    {
      name: "Location Full Name",
      selector: (row: any) => getDealerStatus(row.broker_status),
      sortable: true,
      width: "150px",
      wrap: true,
    },
    {
      name: "Action",
      cell: (row: any) => [
        <td className="city-action-rable-row d-flex">
          <i
            className="fas fa-eye table-icons pe-1"
            onClick={() =>
              navigate(PageLinks.BROKER_DETAIL, {
                state: { id: row.broker_id },
              })
            }
          ></i>

          <div className="form-check form-switch mx-2">
            <input
              className="form-check-input "
              type="checkbox"
              role="switch"
              id="dealerStatus"
              onChange={(e) => {
                e.target.checked == true
                  ? (row.broker_status = 1)
                  : (row.broker_status = 0);
                SetBroker(
                  e.target.checked == true
                    ? DealerStatus.Active
                    : DealerStatus.Disabled,
                  row.broker_id
                );
                setBrokerlist([...Brokerlist]);
              }}
              checked={
                row.broker_status == DistributorTypeStatus.Active ? true : false
              }
            />
          </div>
        </td>,
      ],
    },
  ];
  const customStyles = {
    headCells: {
      style: {
        backgroundColor: "#A27B1E",
        color: "white",
        fontWeight: "bold",
      },
    },
  };

  const handlePageChange = (page: any) => {
    setCurrentPage(page);
    setPageNumber(page);
  };
  const SetBroker = (status: number, brokerID: number) => {
    setShowPreloader(true);
    ServerAPI.SetBroker(status, brokerID)
      .then((res) => {
        if (res["message"] != undefined) {
          toast.success(res["message"]);
          console.log(res);
        }
      })
      .finally(() => {
        setShowPreloader(false);
      });
  };
  useEffect(() => {
    Broker_list();
  }, [pageNumber, search_text, toData, fromDate, preData, open]);

  const Broker_list = () => {
    setShowPreloader(true);
    ServerAPI.BrokerList(pageNumber, search_text, toData, fromDate)
      .then((res) => {
        setBrokerlist(res.data);
        setTotalCount(res.data.length);
        setBrokerId(res.data.broker_id);
      })
      .finally(() => {
        setShowPreloader(false);
      });
  };

  const handleClickOpen = () => {};

  const handleClose = (e: any) => {
    setOpen(e);
    // suspended_list();
  };

  const handleClose_modal = (e: any) => {
    setOpenModal(e);
    Broker_list();
  };

  const activate_user = (id: any) => {
    setOpenModal(true);
    setUserId(id);
  };

  const view_more_content = (e: any) => {
    setViewContent(e);
    setOpen(true);
  };

  const CustomInput = React.forwardRef((props: any, ref: any) => {
    return (
      <div className="datePickerStyle">
        <label onClick={props.onClick} ref={ref}>
          {props.value || props.placeholder}
        </label>
        <img
          src="../../assets/images/calender-icon.svg"
          onClick={props.onClick}
          ref={ref}
        />
      </div>
    );
  });
  const resetfilter = () => {
    setFromDate(null);
    setToData(null);
  };

  return (
    // <div>
    <div>
      <div id="layout-wrapper">
        <Header />
        <div className="main-content py-4">
          <div className="page-content">
            <div className="container-fluid">
              <div className="row">
                <div className="col-12">
                  <div className="page-title-box d-sm-flex align-items-center justify-content-between pt-4 pb-0">
                    <div className="page-title-right">
                      <ol className="breadcrumb ">
                        {/* <li className="breadcrumb-item"><a style={{ color: "#90929E" }}>Trustmony</a></li> */}
                        <li className="breadcrumb-item">
                          <a> Broker List </a>
                        </li>
                      </ol>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <div className="card">
                    <div className="card-body col-sm-12 col-md-12 col-lg-12 w-100">
                      <div className="row" style={{ paddingBottom: "10px" }}>
                        <div className="col-xl-4">
                          <div className="d-flex justify-content-start align-items-center">
                            <h4 className="card-title text-end p-2 m-0">
                              Search :
                            </h4>
                            <form className="app-search card-title p-2 m-0">
                              <div className="position-relative">
                                <input
                                  type="text"
                                  className="select-style-search"
                                  onChange={(e) =>
                                    setSearchText(e.target.value)
                                  }
                                  // placeholder="Bank Code, Bank Name, Locati..."
                                />
                              </div>
                            </form>
                          </div>
                        </div>

                        <div className="col-xl-8 text-end">
                          <div className="d-flex justify-content-end align-items-center">
                            <form
                              className="app-search card-title p-2 m-0 w-100"
                              style={{ fontSize: "0.78rem" }}
                            >
                              <a
                                className="text-white"
                                onClick={() =>
                                  navigate(PageLinks.BROKER_ADD, {
                                    state: { mode: "add" },
                                  })
                                }
                              >
                                <div className="position-relative btn-suspend">
                                  <i
                                    className="fa fa-plus-circle mx-2"
                                    aria-hidden="true"
                                  ></i>
                                  Add New
                                </div>
                              </a>
                            </form>
                            <form className="app-search card-title p-2 m-0 w-100">
                              <div className="position-relative">
                                <DatePicker
                                  selected={fromDate}
                                  closeOnScroll={true}
                                  onChange={(date: Date) => setFromDate(date)}
                                  placeholderText="DD/MM/YYYY"
                                  className="date-picker-style"
                                  peekNextMonth
                                  showMonthDropdown
                                  showYearDropdown
                                  dropdownMode="select"
                                  customInput={<CustomInput />}
                                />
                              </div>
                            </form>
                            <h4 className="card-title p-2 m-0">TO</h4>
                            <form className="app-search card-title p-2 m-0 w-100">
                              <div className="position-relative">
                                <DatePicker
                                  selected={toData}
                                  closeOnScroll={true}
                                  onChange={(date: Date) => setToData(date)}
                                  placeholderText="DD/MM/YYYY"
                                  className="date-picker-style"
                                  peekNextMonth
                                  showMonthDropdown
                                  showYearDropdown
                                  dropdownMode="select"
                                  customInput={<CustomInput />}
                                />
                              </div>
                            </form>
                            <button
                              onClick={resetfilter}
                              className=" btn-suspend text-white"
                            >
                              Reset
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="table-rep-plugin">
                        <div
                          className="table-responsive "
                          data-pattern="priority-columns"
                        >
                          {/* <table id="tech-companies-1" className="table table table-bordered">
                                                        <thead className="city-table">
                                                            <tr>
                                                                <th>Sr.No</th>
                                                                <th >Broker Name</th>
                                                                <th >NSE Broker Code</th>
                                                                <th>BSE Broker Code</th>
                                                                <th >Advisory Name</th>
                                                                <th>Status</th>
                                                                <th data-priority="3" className="">Action</th>
                                                            </tr>
                                                        </thead>
                                                        {
                                                            Brokerlist.length > 0 ?
                                                                <>
                                                                    {
                                                                        Brokerlist.map((res: any, i: any) => (
                                                                            <tbody key={i}>
                                                                                <tr >
                                                                                    <td> {i + 1} </td>
                                                                                    <td>{res.broker_name}</td>
                                                                                    <td>{res.broker_nse_code}</td>
                                                                                    <td>{res.broker_bse_code}</td>
                                                                                    <td>{res.advisory_name != null && res.advisory_name !== "null" ? <>{res.advisory_name}</> : "N/A"}</td>
                                                                                    <td>{res.broker_status != null && res.broker_status !== "null" ? <>{res.broker_status}</> : "N/A"}</td>

                                                                                    <td className="city-action-rable-row ">
                                                                                        <i className="fas fa-eye table-icons pe-1" onClick={() => navigate(PageLinks.USERSDETAILS, { state: res.user_specific_id })}></i>

                                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" className='pb-2 cursor-pointer' height="22" viewBox="0 0 73.281 58.625" onClick={() => activate_user(res.user_specific_id)} >
                                                                                            <path id="Path_22594" data-name="Path 22594" d="M-1807.294,904.684v2.931a5.5,5.5,0,0,1-5.5,5.5h-40.3a5.5,5.5,0,0,1-5.5-5.5v-2.931a15.394,15.394,0,0,1,15.39-15.389c3.3,0,4.866,1.832,10.259,1.832s6.962-1.832,10.259-1.832A15.393,15.393,0,0,1-1807.294,904.684Zm-5.5,0a9.909,9.909,0,0,0-9.893-9.893c-1.683,0-4.339,1.832-10.259,1.832-5.874,0-8.588-1.832-10.259-1.832a9.911,9.911,0,0,0-9.894,9.893v2.931h40.3Zm-36.641-33.709a16.494,16.494,0,0,1,16.489-16.489,16.493,16.493,0,0,1,16.488,16.489,16.493,16.493,0,0,1-16.488,16.488A16.493,16.493,0,0,1-1849.431,870.975Zm5.5,0a11.01,11.01,0,0,0,10.993,10.992,11.01,11.01,0,0,0,10.992-10.992,11.01,11.01,0,0,0-10.992-10.993A11.011,11.011,0,0,0-1843.935,870.975Zm58.282,3.641-14.839,14.919a1.168,1.168,0,0,1-1.672,0l-8.45-8.507a1.2,1.2,0,0,1,0-1.683l2.2-2.2a1.169,1.169,0,0,1,1.672,0l5.428,5.462,11.8-11.874a1.169,1.169,0,0,1,1.672,0l2.187,2.2A1.2,1.2,0,0,1-1785.653,874.616Z" transform="translate(1858.591 -854.486)" />
                                                                                        </svg>
                                                                                    </td>
                                                                                </tr>

                                                                            </tbody>

                                                                        ))
                                                                    }
                                                                </>
                                                                : <tbody>
                                                                    <tr className='py-4'>
                                                                        <td className='text-center py-5' colSpan={11}>No Data Found.</td>
                                                                    </tr>
                                                                </tbody>
                                                        } */}
                          {/* <>
                                                            <tbody>
                                                                <tr >
                                                                    <td>01</td>
                                                                    <td colSpan={3}>Axis Bank Limited</td>
                                                                    <td colSpan={3}>25480</td>
                                                                    <td>3284</td>
                                                                    <td colSpan={3}>Axis (UTIB00403118)</td>
                                                                    <td>Active</td>

                                                                    <td className="city-action-rable-row d-flex">
                                                                        <i className="fas fa-eye table-icons pe-1 my-auto" onClick={() => navigate(PageLinks.DEALER_Detail, { state: res.dealer_id })}></i>
                                                                        <div className="form-check form-switch mx-2">
                                                                            <input className="form-check-input " type="checkbox" role="switch" id="flexSwitchCheckChecked" checked />
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </> */}
                          {/* </table> */}
                          <DataTable
                            data={Brokerlist}
                            columns={columns}
                            noHeader
                            defaultSortFieldId={"broker_id"}
                            sortIcon={<i className="fas fa-sort"></i>}
                            defaultSortAsc={true}
                            highlightOnHover
                            dense
                            customStyles={customStyles}
                          />
                        </div>
                      </div>
                      <div className="row mt-3">
                        <div className="col-sm-12 col-md-5">
                          {totalCount > 10 ? (
                            <div
                              className="dataTables_info"
                              id="datatable_info"
                              role="status"
                              aria-live="polite"
                            >
                              Showing {Brokerlist.length} of {totalCount}{" "}
                              entries
                            </div>
                          ) : (
                            <div
                              className="dataTables_info"
                              id="datatable_info"
                              role="status"
                              aria-live="polite"
                            >
                              Showing {totalCount == 0 ? 0 : totalCount} entries
                            </div>
                          )}
                        </div>
                        <div className="col-sm-12 col-md-7">
                          <div
                            className="dataTables_paginate paging_simple_numbers float-end mb-3"
                            id="datatable_paginate"
                          >
                            <Pagination
                              count={Math.ceil(totalCount / 10)}
                              onChange={(event: any, value: any) => {
                                setPageNumber(Number(value));
                              }}
                              variant="outlined"
                              color="primary"
                              shape="rounded"
                              className="pagination-style"
                            />
                          </div>
                        </div>
                      </div>

                      {/* </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
      <SimpleModal
        contents={view_content}
        handleOpen={() => handleClickOpen}
        openModal={open}
        handleClose={(e: any) => handleClose(e)}
      />
      <ConfirmationModal
        contents="Are you sure you want to activate the user/account?"
        details_datas={user_id}
        openModal={open_modal}
        handleClose={(e: any) => handleClose_modal(e)}
        currentPage="suspend-user-list"
      />
    </div>
  );
};

export default BrokerList;
