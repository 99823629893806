import Pagination from "@mui/material/Pagination";
import moment from "moment";
import React, { useEffect, useState } from "react";
// import DatePicker from 'react-date-picker';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppStateAPI } from "../common/AppStateAPI";
import { Depository, PageLinks } from "../common/Constants";
import Footer from "../common/Footer";
import DataTable from "react-data-table-component";
import Header from "../common/Header";
import { ServerAPI } from "../common/ServerAPI";
import {
  getDisplayData,
  getDealerStatus,
  getFormattedLocalDateTime,
} from "../common/Utilities";
import ConfirmationModal from "../modals/Confirmation-modal";
import SimpleModal from "../modals/SimpleModal";

var startSerialNo = 0;
const DistributorList: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const passing_value = location.state;
  const [preData, setPreData] = useState(passing_value);

  // const [value, onChange] = useState(new Date());
  const [toData, setToData] = useState<null | Date>();
  const [fromDate, setFromDate] = useState<null | Date>();
  const [open, setOpen] = useState(false);
  const [open_modal, setOpenModal] = useState(false);
  const [Distlist, setDistlist] = useState<any>([]);
  const [userList, setUserList] = useState<any[]>([]);
  const [totalCount, setTotalCount] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [user_id, setUserId] = useState();
  const [view_content, setViewContent] = useState();
  const [search_text, setSearchText] = useState("");
  const { setShowPreloader } = useAppStateAPI();
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const pageCount = Math.ceil(totalCount / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const currPage = currentPage ? currentPage : 1;
  startSerialNo = currPage * 10 - 10;
  // const [selectedItems, setSelectedItems] = React.useState<any[]>([]);

  const customStyles = {
    headCells: {
      style: {
        backgroundColor: "#A27B1E",
        color: "white",
        fontWeight: "bold",
      },
    },
  };

  let columns: any = [
    {
      name: "Sr.No",
      width: "90px",
      cell: (row: any, index: any) => startSerialNo + index + 1, //RDT provides index by default
    },
    {
      name: "Distributor Name",
      selector: (row: any) => getDisplayData(row.distributor_name),
      sortable: true,
      width: "400px",
      wrap: true,
    },
    {
      name: "Unique Code",
      selector: (row: any) => getDisplayData(row.distributor_unique_code),
      sortable: true,
    },
    {
      name: "PAN Number",
      selector: (row: any) => getDisplayData(row.distributor_pan_number),
      sortable: true,
    },
    {
      name: "Distributor Type",
      selector: (row: any) => getDisplayData(row.distributor_type_name),
      sortable: true,
    },
    {
      name: "Sub. Type",
      selector: (row: any) => getDisplayData(row.distributor_sub_type_name),
      sortable: true,
    },
    {
      name: "Category",
      selector: (row: any) => getDisplayData(row.distributor_category_name),
      sortable: true,
    },
    {
      name: "Incorporation Date",
      selector: (row: any) => getDisplayData(row.distributor_dob),
      sortable: true,
    },
    {
      name: "Bank Name",
      selector: (row: any) => getDisplayData(row.distributor_bank_account_name),
      sortable: true,
    },
    {
      name: "DP Name",
      selector: (row: any) =>
        getDisplayData(row.distributor_demat_account_dp_name),
      sortable: true,
    },
    {
      name: "Company Name",
      selector: (row: any) => getDisplayData(row.compaines_name),
      sortable: true,
    },
    {
      name: "Is Prospective",
      selector: (row: any) => getDisplayData(row.distributor_prospective),
      sortable: true,
    },
    {
      name: "Action",
      cell: (row: any) => [
        <td className="city-action-rable-row ">
          <i
            className="fas fa-eye table-icons pe-1"
            onClick={() =>
              navigate(PageLinks.DISTRIBUTOR_DETAIL, {
                state: { distributor_id: row.distributor_id },
              })
            }
          ></i>
        </td>,
      ],
    },
  ];
  const handlePageChange = (page: any) => {
    setCurrentPage(page);
    setPageNumber(page);
  };

  useEffect(() => {
    Distribut_list();
  }, [pageNumber, search_text, toData, fromDate, preData, open]);

  const Distribut_list = () => {
    setShowPreloader(true);
    ServerAPI.Distributlist(pageNumber, search_text, toData, fromDate)
      .then((res) => {
        setDistlist(res.data);
        setTotalCount(res.data.length);
      })
      .finally(() => {
        setShowPreloader(false);
      });
  };

  const handleClickOpen = () => {};

  const handleClose = (e: any) => {
    setOpen(e);
    // suspended_list();
  };

  const handleClose_modal = (e: any) => {
    setOpenModal(e);
    Distribut_list();
  };

  const activate_user = (id: any) => {
    setOpenModal(true);
    setUserId(id);
  };

  const view_more_content = (e: any) => {
    setViewContent(e);
    setOpen(true);
  };

  const CustomInput = React.forwardRef((props: any, ref: any) => {
    return (
      <div className="datePickerStyle">
        <label onClick={props.onClick} ref={ref}>
          {props.value || props.placeholder}
        </label>
        <img
          src="../../assets/images/calender-icon.svg"
          onClick={props.onClick}
          ref={ref}
        />
      </div>
    );
  });
  const resetfilter = () => {
    setFromDate(null);
    setToData(null);
  };
  return (
    // <div>
    <div>
      <div id="layout-wrapper">
        <Header />
        <div className="main-content py-4">
          <div className="page-content">
            <div className="container-fluid">
              <div className="row">
                <div className="col-12">
                  <div className="page-title-box d-sm-flex align-items-center justify-content-between pt-4 pb-0">
                    <div className="page-title-right">
                      <ol className="breadcrumb ">
                        {/* <li className="breadcrumb-item"><a style={{ color: "#90929E" }}>Trustmony</a></li> */}
                        <li className="breadcrumb-item">
                          <a> Distributor List </a>
                        </li>
                      </ol>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <div className="card">
                    <div className="card-body col-sm-12 col-md-12 col-lg-12 w-100">
                      <div className="row" style={{ paddingBottom: "10px" }}>
                        <div className="col-xl-7">
                          <div className="d-flex justify-content-start align-items-center">
                            <h3 className="card-title text-end p-2 m-0">
                              Distributor List
                            </h3>
                            <h4 className="card-title text-end p-2 m-0">
                              Search :
                            </h4>
                            <form className="app-search card-title p-2 m-0">
                              <div className="position-relative">
                                <input
                                  type="text"
                                  className="select-style-search"
                                  onChange={(e) =>
                                    setSearchText(e.target.value)
                                  }
                                  // placeholder="Distributor Name, Unique Code ..."
                                />
                              </div>
                            </form>
                          </div>
                        </div>

                        <div className="col-xl-5 text-end">
                          <div className="d-flex justify-content-end align-items-center">
                            <form
                              className="app-search card-title p-2 m-0 w-50 text-center"
                              style={{ fontSize: "0.78rem" }}
                            >
                              <a
                                className="text-white"
                                onClick={() =>
                                  navigate(PageLinks.DISTRIBUTOR_ADD, {
                                    state: { mode: "add" },
                                  })
                                }
                              >
                                <div className="position-relative btn-suspend">
                                  <i
                                    className="fa fa-plus-circle mx-2"
                                    aria-hidden="true"
                                  ></i>
                                  Add
                                </div>
                              </a>
                            </form>
                            <form className="app-search card-title p-2 m-0 w-100">
                              <div className="position-relative">
                                <DatePicker
                                  selected={fromDate}
                                  closeOnScroll={true}
                                  onChange={(date: Date) => setFromDate(date)}
                                  placeholderText="DD/MM/YYYY"
                                  className="date-picker-style"
                                  peekNextMonth
                                  showMonthDropdown
                                  showYearDropdown
                                  dropdownMode="select"
                                  customInput={<CustomInput />}
                                />
                              </div>
                            </form>
                            <h4 className="card-title p-2 m-0">TO</h4>
                            <form className="app-search card-title p-2 m-0 w-100">
                              <div className="position-relative">
                                <DatePicker
                                  selected={toData}
                                  closeOnScroll={true}
                                  onChange={(date: Date) => setToData(date)}
                                  placeholderText="DD/MM/YYYY"
                                  className="date-picker-style"
                                  peekNextMonth
                                  showMonthDropdown
                                  showYearDropdown
                                  dropdownMode="select"
                                  customInput={<CustomInput />}
                                />
                              </div>
                            </form>
                            <button
                              onClick={resetfilter}
                              className=" btn-suspend text-white"
                            >
                              Reset
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="table-rep-plugin">
                        <div
                          className="table-responsive "
                          data-pattern="priority-columns"
                        >
                          {/* <table id="tech-companies-1" className="table table table-bordered">
                                                        <thead className="city-table">
                                                            <tr>
                                                                <th>Sr.No</th>
                                                                <th>Distributor Name</th>
                                                                <th>Unique Code</th>
                                                                <th>PAN Number</th>
                                                                <th>Distributor Type</th>
                                                                <th>Sub. Type</th>
                                                                <th>Category</th>
                                                                <th>Incorporation Date</th>
                                                                <th>Bank Name</th>
                                                                <th>DP Name</th>
                                                                <th>Company Name</th>
                                                                <th>Is Prospective</th>
                                                                <th colSpan={3} data-priority="3" className="">Action</th>
                                                            </tr>
                                                        </thead>
                                                        {
                                                            userList.length > 0 ?
                                                                <>
                                                                    {
                                                                        userList.map((res: any, i: any) => (
                                                                            <tbody key={i}>
                                                                                <tr >
                                                                                    <td> {i + 1} </td>
                                                                                    <td>{res.customer_id}</td>
                                                                                    <td>{res.users_created_at != null && moment(res.users_created_at).format("DD-MM-YYYY")}</td>
                                                                                    <td>{res.users_created_at != null && moment(res.users_created_at).format("hh:mm A")}</td>
                                                                                    <td>{res.fname}{" "} {res.mname}{" "} {res.lname}</td>
                                                                                    <td>{res.suspended_start_date != null && res.suspended_start_date !== "null" ? <>{res.suspended_start_date}</> : "N/A"}</td>
                                                                                    <td>{res.suspended_end_date != null && res.suspended_end_date !== "null" ? <>{res.suspended_end_date}</> : "N/A"}</td>
                                                                                    <td>{res.account_status_reason != null && res.account_status_reason !== "null" ? <>{res.account_status_reason}</> : "N/A"}</td>
                                                                                    <td>{res.account_status_remarks != null && res.account_status_remarks !== "null" ? <p className='m-0 text-content'>{res.account_status_remarks}</p> : "N/A"} {res.account_status_remarks.length > 80 && <span className='text-red cursor-pointer' onClick={(e) => view_more_content(res.account_status_remarks)} >(View more)</span>}</td>

                                                                                    <td className="city-action-rable-row ">
                                                                                        <i className="fas fa-eye table-icons pe-1" onClick={() => navigate(PageLinks.DISTRIBUTOR_ADD)}></i>

                                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" className='pb-2 cursor-pointer' height="22" viewBox="0 0 73.281 58.625" onClick={() => activate_user(res.user_specific_id)} >
                                                                                            <path id="Path_22594" data-name="Path 22594" d="M-1807.294,904.684v2.931a5.5,5.5,0,0,1-5.5,5.5h-40.3a5.5,5.5,0,0,1-5.5-5.5v-2.931a15.394,15.394,0,0,1,15.39-15.389c3.3,0,4.866,1.832,10.259,1.832s6.962-1.832,10.259-1.832A15.393,15.393,0,0,1-1807.294,904.684Zm-5.5,0a9.909,9.909,0,0,0-9.893-9.893c-1.683,0-4.339,1.832-10.259,1.832-5.874,0-8.588-1.832-10.259-1.832a9.911,9.911,0,0,0-9.894,9.893v2.931h40.3Zm-36.641-33.709a16.494,16.494,0,0,1,16.489-16.489,16.493,16.493,0,0,1,16.488,16.489,16.493,16.493,0,0,1-16.488,16.488A16.493,16.493,0,0,1-1849.431,870.975Zm5.5,0a11.01,11.01,0,0,0,10.993,10.992,11.01,11.01,0,0,0,10.992-10.992,11.01,11.01,0,0,0-10.992-10.993A11.011,11.011,0,0,0-1843.935,870.975Zm58.282,3.641-14.839,14.919a1.168,1.168,0,0,1-1.672,0l-8.45-8.507a1.2,1.2,0,0,1,0-1.683l2.2-2.2a1.169,1.169,0,0,1,1.672,0l5.428,5.462,11.8-11.874a1.169,1.169,0,0,1,1.672,0l2.187,2.2A1.2,1.2,0,0,1-1785.653,874.616Z" transform="translate(1858.591 -854.486)" />
                                                                                        </svg>
                                                                                    </td>
                                                                                </tr>

                                                                            </tbody>

                                                                        ))
                                                                    }
                                                                </>
                                                                : <tbody>
                                                                    <tr className='py-4'>
                                                                        <td className='text-center py-5' colSpan={11}>No Data Found.</td>
                                                                    </tr>
                                                                </tbody>
                                                        } */}
                          {/* <>
                                                            <tbody>
                                                                <tr >
                                                                    <td>01</td>
                                                                    <td>Sandeep Baweja</td>
                                                                    <td>30-08-2022</td>
                                                                    <td>DC0085</td>
                                                                    <td>AHAPB0963G</td>
                                                                    <td>Individual</td>
                                                                    <td>Individual</td>
                                                                    <td>Platinum</td>
                                                                    <td>29 Mar 1979</td>
                                                                    <td>HDFC Bank</td>
                                                                    <td></td>
                                                                    <td>Trust Financial</td>
                                                                    <td>No</td>
                                                                    <td className="city-action-rable-row d-flex">
                                                                        <i className="fas fa-eye table-icons pe-1 my-auto"></i>
                                                                    </td>
                                                                </tr>
                                                                <tr >
                                                                    <td>01</td>
                                                                    <td>Sandeep Baweja</td>
                                                                    <td>30-08-2022</td>
                                                                    <td>DC0085</td>
                                                                    <td>AHAPB0963G</td>
                                                                    <td>Individual</td>
                                                                    <td>Individual</td>
                                                                    <td>Platinum</td>
                                                                    <td>29 Mar 1979</td>
                                                                    <td>HDFC Bank</td>
                                                                    <td></td>
                                                                    <td>Trust Financial</td>
                                                                    <td>No</td>
                                                                    <td className="city-action-rable-row d-flex">
                                                                        <i className="fas fa-eye table-icons pe-1 my-auto"></i>
                                                                    </td>
                                                                </tr>
                                                                <tr >
                                                                    <td>01</td>
                                                                    <td>Sandeep Baweja</td>
                                                                    <td>30-08-2022</td>
                                                                    <td>DC0085</td>
                                                                    <td>AHAPB0963G</td>
                                                                    <td>Individual</td>
                                                                    <td>Individual</td>
                                                                    <td>Platinum</td>
                                                                    <td>29 Mar 1979</td>
                                                                    <td>HDFC Bank</td>
                                                                    <td></td>
                                                                    <td>Trust Financial</td>
                                                                    <td>No</td>
                                                                    <td className="city-action-rable-row d-flex">
                                                                        <i className="fas fa-eye table-icons pe-1 my-auto"></i>
                                                                    </td>
                                                                </tr>
                                                                <tr >
                                                                    <td>01</td>
                                                                    <td>Sandeep Baweja</td>
                                                                    <td>30-08-2022</td>
                                                                    <td>DC0085</td>
                                                                    <td>AHAPB0963G</td>
                                                                    <td>Individual</td>
                                                                    <td>Individual</td>
                                                                    <td>Platinum</td>
                                                                    <td>29 Mar 1979</td>
                                                                    <td>HDFC Bank</td>
                                                                    <td></td>
                                                                    <td>Trust Financial</td>
                                                                    <td>No</td>
                                                                    <td className="city-action-rable-row d-flex">
                                                                        <i className="fas fa-eye table-icons pe-1 my-auto"></i>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </> */}
                          {/* </table> */}
                          <DataTable
                            data={Distlist}
                            columns={columns}
                            noHeader
                            defaultSortFieldId={"broker_id"}
                            sortIcon={<i className="fas fa-sort"></i>}
                            defaultSortAsc={true}
                            highlightOnHover
                            dense
                            customStyles={customStyles}
                          />
                        </div>
                      </div>
                      <div className="row mt-3">
                        <div className="col-sm-12 col-md-5">
                          {totalCount > 10 ? (
                            <div
                              className="dataTables_info"
                              id="datatable_info"
                              role="status"
                              aria-live="polite"
                            >
                              Showing {userList.length} of {totalCount} entries
                            </div>
                          ) : (
                            <div
                              className="dataTables_info"
                              id="datatable_info"
                              role="status"
                              aria-live="polite"
                            >
                              Showing {totalCount == 0 ? 0 : totalCount} entries
                            </div>
                          )}
                        </div>
                        <div className="col-sm-12 col-md-7">
                          <div
                            className="dataTables_paginate paging_simple_numbers float-end mb-3"
                            id="datatable_paginate"
                          >
                            <Pagination
                              count={Math.ceil(totalCount / 10)}
                              onChange={(event: any, value: any) => {
                                setPageNumber(Number(value));
                              }}
                              variant="outlined"
                              color="primary"
                              shape="rounded"
                              className="pagination-style"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
      <SimpleModal
        contents={view_content}
        handleOpen={() => handleClickOpen}
        openModal={open}
        handleClose={(e: any) => handleClose(e)}
      />
      <ConfirmationModal
        contents="Are you sure you want to activate the user/account?"
        details_datas={user_id}
        openModal={open_modal}
        handleClose={(e: any) => handleClose_modal(e)}
        currentPage="suspend-user-list"
      />
    </div>
  );
};

export default DistributorList;
