import Pagination from "@mui/material/Pagination";
import moment from "moment";
import React, { useEffect, useState } from "react";
// import DatePicker from 'react-date-picker';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppStateAPI } from "../common/AppStateAPI";
import { Depository, PageLinks } from "../common/Constants";
import { APIData } from "../common/DataTypes";
import Footer from "../common/Footer";
import Header from "../common/Header";
import { ServerAPI } from "../common/ServerAPI";
import ConfirmationModal from "../modals/Confirmation-modal";
import SimpleModal from "../modals/SimpleModal";

const IPOFormList: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const passing_value = location.state;
  const [preData, setPreData] = useState(passing_value);

  // const [value, onChange] = useState(new Date());
  const [toData, setToData] = useState<null | Date>();
  const [fromDate, setFromDate] = useState<null | Date>();
  const [open, setOpen] = useState(false);
  const [open_modal, setOpenModal] = useState(false);
  const [userList, setUserList] = useState<any[]>([]);
  const [totalCount, setTotalCount] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [user_id, setUserId] = useState();
  const [view_content, setViewContent] = useState();
  const [search_text, setSearchText] = useState("");
  const { setShowPreloader } = useAppStateAPI();

  // const [selectedItems, setSelectedItems] = React.useState<any[]>([]);

  useEffect(() => {
    suspended_list();
  }, [pageNumber, search_text, toData, fromDate, preData, open]);

  const suspended_list = () => {
    setShowPreloader(true);
    ServerAPI.formAllocationList(pageNumber, search_text, toData, fromDate)
      .then((res) => {
        setUserList(res.data);
        setTotalCount(res.total_count);
      })
      .finally(() => {
        setShowPreloader(false);
      });
  };

  const handleClickOpen = () => {};

  const handleClose = (e: any) => {
    setOpen(e);
    // suspended_list();
  };

  const handleClose_modal = (e: any) => {
    setOpenModal(e);
    suspended_list();
  };

  const activate_user = (id: any) => {
    setOpenModal(true);
    setUserId(id);
  };

  const view_more_content = (e: any) => {
    setViewContent(e);
    setOpen(true);
  };

  const CustomInput = React.forwardRef((props: any, ref: any) => {
    return (
      <div className="datePickerStyle">
        <label onClick={props.onClick} ref={ref}>
          {props.value || props.placeholder}
        </label>
        <img
          src="../../assets/images/calender-icon.svg"
          onClick={props.onClick}
          ref={ref}
        />
      </div>
    );
  });

  return (
    // <div>
    <div>
      <div id="layout-wrapper">
        <Header />
        <div className="main-content py-4">
          <div className="page-content">
            <div className="container-fluid">
              <div className="row">
                <div className="col-12">
                  <div className="page-title-box d-sm-flex align-items-center justify-content-between pt-4 pb-0">
                    {/* <h4 className="mb-sm-0 font-size-18 "> Suspend Users</h4> */}
                    <div className="page-title-right">
                      <ol className="breadcrumb ">
                        {/* <li className="breadcrumb-item">
                          <a style={{ color: "#90929E" }}>Trustmony</a>
                        </li> */}
                        <li className="breadcrumb-item">
                          <a> Masters </a>
                        </li>
                        <li className="breadcrumb-item active ">
                          IPO Form Master
                        </li>
                      </ol>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <div className="card">
                    <div className="card-body col-sm-12 col-md-12 col-lg-12 w-100">
                      <div className="row" style={{ paddingBottom: "10px" }}>
                        <div className="col-xl-5">
                          <div className="d-flex justify-content-start align-items-center">
                            <h4 className="card-title text-end p-2 m-0">
                              Search :
                            </h4>
                            <form className="app-search card-title p-2 m-0">
                              <div className="position-relative">
                                <input
                                  type="text"
                                  className="select-style-search"
                                  onChange={(e) =>
                                    setSearchText(e.target.value)
                                  }
                                />
                              </div>
                            </form>
                          </div>
                        </div>

                        <div className="col-xl-7 text-end">
                          <div className="d-flex justify-content-end align-items-center">
                            <form
                              className="app-search card-title p-2 m-0 w-100 text-center"
                              style={{ fontSize: "0.78rem" }}
                            >
                              <a
                                className="text-white"
                                onClick={() =>
                                  navigate(PageLinks.IPO_FORM_ALLOCATION)
                                }
                              >
                                <div className="position-relative btn-suspend">
                                  <i
                                    className="fa fa-plus-circle mx-2"
                                    aria-hidden="true"
                                  ></i>
                                  Allocate Dealers
                                </div>
                              </a>
                            </form>
                            <form
                              className="app-search card-title p-2 m-0 w-50 text-center"
                              style={{ fontSize: "0.78rem" }}
                            >
                              <a
                                className="text-white"
                                onClick={() => navigate(PageLinks.IPO_FORM_ADD)}
                              >
                                <div className="position-relative btn-suspend">
                                  <i
                                    className="fa fa-plus-circle mx-2"
                                    aria-hidden="true"
                                  ></i>
                                  Add
                                </div>
                              </a>
                            </form>
                            <form className="app-search card-title p-2 m-0 w-100">
                              <div className="position-relative">
                                <DatePicker
                                  selected={toData}
                                  onChange={(date: Date) => setToData(date)}
                                  placeholderText="DD/MM/YYYY"
                                  className="date-picker-style"
                                  customInput={<CustomInput />}
                                />
                              </div>
                            </form>
                            <h4 className="card-title p-2 m-0">TO</h4>
                            <form className="app-search card-title p-2 m-0 w-100">
                              <div className="position-relative">
                                <DatePicker
                                  selected={toData}
                                  onChange={(date: Date) => setToData(date)}
                                  placeholderText="DD/MM/YYYY"
                                  className="date-picker-style"
                                  customInput={<CustomInput />}
                                />
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                      <div className="table-rep-plugin">
                        <div
                          className="table-responsive "
                          data-pattern="priority-columns"
                        >
                          <table
                            id="tech-companies-1"
                            className="table table table-bordered"
                          >
                            <thead className="city-table">
                              <tr>
                                <th>Sr.No</th>
                                <th>Created Date</th>
                                {/* <th>Last Updated</th> */}
                                <th colSpan={3}>IPO Name</th>
                                <th>Application Start No.</th>
                                <th>Application End No.</th>
                                <th>Total No. Of App. Forms</th>
                                <th>Status</th>
                                <th colSpan={3} data-priority="3" className="">
                                  Action
                                </th>
                              </tr>
                            </thead>
                            {userList.length > 0 ? (
                              <>
                                {userList.map(
                                  (
                                    res: APIData.IPOFormAllocationList,
                                    i: number
                                  ) => (
                                    <tbody key={i}>
                                      <tr>
                                        <td> {i + 1} </td>
                                        <td>
                                          {res.bond_form_allocation_created_at}
                                        </td>
                                        {/* <td>{res.users_created_at != null && moment(res.users_created_at).format("DD-MM-YYYY")}</td> */}
                                        <td colSpan={3}>{res.bond_name}</td>
                                        <td>
                                          {res.bond_form_allocation_form_end}
                                        </td>
                                        <td>
                                          {res.bond_form_allocation_form_end}
                                        </td>
                                        <td>
                                          {res.bond_form_allocation_form_total}
                                        </td>
                                        <td>Enable</td>

                                        <td
                                          className="city-action-rable-row "
                                          colSpan={3}
                                        >
                                          <i
                                            className="fas fa-eye table-icons pe-1"
                                            // onClick={() => navigate(PageLinks.USERSDETAILS, { state: res.user_specific_id })}
                                          ></i>

                                          {/* <svg xmlns="http://www.w3.org/2000/svg" width="20" className='pb-2 cursor-pointer' height="22" viewBox="0 0 73.281 58.625"
                                                                                        // onClick={() => activate_user(res.user_specific_id)} 
                                                                                        >
                                                                                            <path id="Path_22594" data-name="Path 22594" d="M-1807.294,904.684v2.931a5.5,5.5,0,0,1-5.5,5.5h-40.3a5.5,5.5,0,0,1-5.5-5.5v-2.931a15.394,15.394,0,0,1,15.39-15.389c3.3,0,4.866,1.832,10.259,1.832s6.962-1.832,10.259-1.832A15.393,15.393,0,0,1-1807.294,904.684Zm-5.5,0a9.909,9.909,0,0,0-9.893-9.893c-1.683,0-4.339,1.832-10.259,1.832-5.874,0-8.588-1.832-10.259-1.832a9.911,9.911,0,0,0-9.894,9.893v2.931h40.3Zm-36.641-33.709a16.494,16.494,0,0,1,16.489-16.489,16.493,16.493,0,0,1,16.488,16.489,16.493,16.493,0,0,1-16.488,16.488A16.493,16.493,0,0,1-1849.431,870.975Zm5.5,0a11.01,11.01,0,0,0,10.993,10.992,11.01,11.01,0,0,0,10.992-10.992,11.01,11.01,0,0,0-10.992-10.993A11.011,11.011,0,0,0-1843.935,870.975Zm58.282,3.641-14.839,14.919a1.168,1.168,0,0,1-1.672,0l-8.45-8.507a1.2,1.2,0,0,1,0-1.683l2.2-2.2a1.169,1.169,0,0,1,1.672,0l5.428,5.462,11.8-11.874a1.169,1.169,0,0,1,1.672,0l2.187,2.2A1.2,1.2,0,0,1-1785.653,874.616Z" transform="translate(1858.591 -854.486)" />
                                                                                        </svg> */}
                                        </td>
                                      </tr>
                                    </tbody>
                                  )
                                )}
                              </>
                            ) : (
                              <tbody>
                                <tr className="py-4">
                                  <td className="text-center py-5" colSpan={11}>
                                    No Data Found.
                                  </td>
                                </tr>
                              </tbody>
                            )}
                            {/* <>
                                                            <tbody>
                                                                <tr >
                                                                    <td>01</td>
                                                                    <td>30-08-2022</td>
                                                                    <td>30-08-2022</td>
                                                                    <td colSpan={3}>Muthoot Finance Limited</td>
                                                                    <td>0000001</td>
                                                                    <td>1000000</td>
                                                                    <td>10,00,000</td>
                                                                    <td>Enabled</td>

                                                                    <td className="city-action-rable-row d-flex">
                                                                        <i className="fas fa-eye table-icons pe-1 my-auto"></i>
                                                                        <div className="form-check form-switch mx-2">
                                                                            <input className="form-check-input " type="checkbox" role="switch" id="flexSwitchCheckChecked" checked />
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </> */}
                          </table>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-sm-12 col-md-5">
                          {totalCount > 10 ? (
                            <div
                              className="dataTables_info"
                              id="datatable_info"
                              role="status"
                              aria-live="polite"
                            >
                              Showing {userList.length} of {totalCount} entries
                            </div>
                          ) : (
                            <div
                              className="dataTables_info"
                              id="datatable_info"
                              role="status"
                              aria-live="polite"
                            >
                              Showing {totalCount == 0 ? 0 : totalCount} entries
                            </div>
                          )}
                        </div>
                        <div className="col-sm-12 col-md-7">
                          <div
                            className="dataTables_paginate paging_simple_numbers float-end mb-3"
                            id="datatable_paginate"
                          >
                            <Pagination
                              count={Math.ceil(totalCount / 10)}
                              onChange={(event: any, value: any) => {
                                setPageNumber(Number(value));
                              }}
                              variant="outlined"
                              color="primary"
                              shape="rounded"
                              className="pagination-style"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
      <SimpleModal
        contents={view_content}
        handleOpen={() => handleClickOpen}
        openModal={open}
        handleClose={(e: any) => handleClose(e)}
      />
      <ConfirmationModal
        contents="Are you sure you want to activate the user/account?"
        details_datas={user_id}
        openModal={open_modal}
        handleClose={(e: any) => handleClose_modal(e)}
        currentPage="suspend-user-list"
      />
    </div>
  );
};

export default IPOFormList;
