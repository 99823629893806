import Pagination from "@mui/material/Pagination";
import moment from "moment";
import React, { useEffect, useState } from "react";
import DatePicker from "react-date-picker";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useAppStateAPI } from "../common/AppStateAPI";
import { APIData } from "../common/DataTypes";
import Footer from "../common/Footer";
import Header from "../common/Header";
import { ServerAPI } from "../common/ServerAPI";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
// import ConfirmationModal from '../modals/Confirmation-modal';
// import SimpleModal from '../modals/SimpleModal';

const ISINOrderHistory: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const userId = location.state;

  // const [value, onChange] = useState(new Date());
  const [toData, setToData] = useState<null | Date>();
  const [fromDate, setFromDate] = useState<null | Date>();
  const [userLogList, setUserlogList] = useState<any[]>([]);
  const [totalCount, setTotalCount] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [search_text, setSearchText] = useState("");
  const [select_module, setSelectModule] = useState<any>(null);
  const [orderList, setOrderList] = useState<any[]>([]);
  const { setShowPreloader } = useAppStateAPI();

  // useEffect(() => {
  //   setShowPreloader(true);
  //   ServerAPI.UserLogs(
  //     pageNumber,
  //     userId,
  //     search_text,
  //     toData,
  //     fromDate,
  //     select_module
  //   )
  //     .then((res) => {
  //       setUserlogList(res.datas);
  //       setTotalCount(res.total_pages);
  //     })
  //     .finally(() => {
  //       setShowPreloader(false);
  //     });
  // }, [pageNumber, search_text, toData, fromDate, select_module, userId]);

  useEffect(() => {
    ServerAPI.ISINOrderHistory().then((res: any) => {
      setOrderList(res.data);
    });
  }, []);

  // const data = [
  //   {
  //     batchNo: "#72",
  //     uploadedDate: "2024-12-17 10:40:02",
  //     uploadedBy: "Dharmesh",
  //     uploadedISIN: 12,
  //     items: [
  //       {
  //         ISIN: "INE0TGS07016",
  //         quantity: 10,
  //         available: 10,
  //         sold: 0,
  //         ordersSold: 0,
  //         preOrder: {
  //           total: 0,
  //           pending: 0,
  //           sold: 0,
  //         },
  //       },
  //       {
  //         ISIN: "INE540P07343",
  //         quantity: 5,
  //         available: 5,
  //         sold: 0,
  //         ordersSold: 0,
  //         preOrder: {
  //           total: 0,
  //           pending: 0,
  //           sold: 0,
  //         },
  //       },
  //     ],
  //   },
  //   {
  //     batchNo: "#71",
  //     uploadedDate: "2024-12-13 05:34:03",
  //     uploadedBy: "Komal Mehta",
  //     uploadedISIN: 12,
  //     items: [
  //       {
  //         ISIN: "INE0TGS07016",
  //         quantity: 10,
  //         available: 10,
  //         sold: 0,
  //         ordersSold: 0,
  //         preOrder: {
  //           total: 0,
  //           pending: 0,
  //           sold: 0,
  //         },
  //       },
  //     ],
  //   },
  // ];

  return (
    // <div>
    <div>
      <div id="layout-wrapper">
        <Header />
        <div className="main-content py-4">
          <div className="page-content">
            <div className="container-fluid">
              <div className="row">
                <div className="col-12">
                  <div className="page-title-box d-sm-flex align-items-center justify-content-between pt-4 pb-0">
                    <div className="page-title-right">
                      <ol className="breadcrumb "></ol>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <div className="card">
                    <div className="card-body col-sm-12 col-md-12 col-lg-12 w-100">
                      <div>
                        {orderList?.map((batch, index) => (
                          <Accordion key={index}>
                            <AccordionSummary
                              expandIcon={<ExpandMoreIcon />}
                              sx={{
                                display: "flex",
                                justifyContent: "space-between", // Ensure proper spacing between items
                                alignItems: "center",
                                flexWrap: "wrap", // Allow wrapping if content overflows
                              }}
                            >
                              <Typography
                                sx={{ flex: "1 1 auto", textAlign: "left" }}
                              >
                                Batch No:{" "}
                                <span
                                  style={{
                                    border: "1px solid gray",
                                    padding: "5px",
                                    borderRadius: "5px",
                                  }}
                                >
                                  #{batch?.batch_no}
                                </span>
                              </Typography>
                              <Typography
                                sx={{ flex: "1 1 auto", textAlign: "left" }}
                              >
                                Uploaded Date:{" "}
                                <span
                                  style={{
                                    border: "1px solid #A27B1E",
                                    borderRadius: "5px",
                                    color: "#A27B1E",
                                    padding: "5px",
                                    width: "100px", // Fixed width
                                    height: "30px", // Fixed height
                                    display: "inline-flex", // Ensures alignment
                                    justifyContent: "center", // Horizontally centers the text
                                    alignItems: "center", // Vertically centers the text
                                    textAlign: "center", // Keeps the text aligned in the center
                                  }}
                                >
                                  {batch?.uploaded_date
                                    ? batch.uploaded_date
                                    : "--"}
                                </span>
                              </Typography>
                              <Typography
                                sx={{ flex: "1 1 auto", textAlign: "left" }}
                              >
                                Uploaded ISIN:
                                <span
                                  style={{
                                    border: "1px solid #9C73DF",
                                    borderRadius: "5px",
                                    color: "#9C73DF",
                                    padding: "5px",
                                    minWidth: "40px", // Minimum width for short content
                                    height: "30px", // Fixed height
                                    display: "inline-flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    textAlign: "center",
                                    marginLeft: "5px",
                                  }}
                                >
                                  {batch?.uploaded_isin
                                    ? batch.uploaded_isin
                                    : "--"}
                                </span>
                              </Typography>
                              <Typography
                                sx={{ flex: "1 1 auto", textAlign: "left" }}
                              >
                                Uploaded By:{" "}
                                <span
                                  style={{
                                    border: "1px solid #2F80ED",
                                    borderRadius: "5px",
                                    color: "#2F80ED",
                                    padding: "5px",
                                    width: "100px", // Set a fixed width
                                    height: "30px", // Set a fixed height
                                    display: "inline-flex", // Use inline-flex for proper alignment
                                    justifyContent: "center", // Center horizontally
                                    alignItems: "center", // Center vertically
                                    textAlign: "center", // Ensure text alignment
                                  }}
                                >
                                  {batch?.uploaded_by
                                    ? batch.uploaded_by
                                    : "--"}
                                </span>
                              </Typography>
                            </AccordionSummary>

                            <AccordionDetails>
                              <Table
                                sx={{
                                  border: "1px solid #ddd", // Add border around the table
                                  borderCollapse: "collapse", // Ensure borders do not double up
                                }}
                              >
                                <TableHead>
                                  <TableRow
                                    sx={{
                                      backgroundColor: "#A27B1E", // Light background for the header
                                      borderBottom: "1px solid #ddd", // Border between header and body
                                    }}
                                  >
                                    <TableCell
                                      sx={{
                                        border: "1px solid #ddd",
                                        color: "white",
                                      }}
                                    >
                                      #
                                    </TableCell>
                                    <TableCell
                                      sx={{
                                        border: "1px solid #ddd",
                                        color: "white",
                                      }}
                                    >
                                      ISIN
                                    </TableCell>
                                    <TableCell
                                      sx={{
                                        border: "1px solid #ddd",
                                        color: "white",
                                      }}
                                    >
                                      Total Quantity Uploaded
                                    </TableCell>
                                    <TableCell
                                      sx={{
                                        border: "1px solid #ddd",
                                        color: "white",
                                      }}
                                    >
                                      Available Quantity
                                    </TableCell>
                                    <TableCell
                                      sx={{
                                        border: "1px solid #ddd",
                                        color: "white",
                                      }}
                                    >
                                      Total Sold Quantity
                                    </TableCell>
                                    <TableCell
                                      sx={{
                                        border: "1px solid #ddd",
                                        color: "white",
                                      }}
                                    >
                                      Orders Sold
                                    </TableCell>
                                    <TableCell
                                      sx={{
                                        border: "1px solid #ddd",
                                        color: "white",
                                      }}
                                    >
                                      Pre Order
                                    </TableCell>
                                    <TableCell
                                      sx={{
                                        border: "1px solid #ddd",
                                        color: "white",
                                      }}
                                    >
                                      Info
                                    </TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {/* {JSON.stringify(batch?.insights)} */}
                                  {batch?.insights.length > 0 &&
                                    batch?.insights?.map(
                                      (item: any, idx: any) => (
                                        <TableRow key={idx}>
                                          <TableCell
                                            sx={{ border: "1px solid #ddd" }}
                                          >
                                            {idx + 1}
                                          </TableCell>
                                          <TableCell
                                            sx={{ border: "1px solid #ddd" }}
                                          >
                                            {item?.security_isin}
                                          </TableCell>
                                          <TableCell
                                            sx={{ border: "1px solid #ddd" }}
                                          >
                                            {/* {JSON.stringify(
                                              item.total_uploaded_quantity
                                            )} */}
                                            {item.total_uploaded_quantity ==
                                            null
                                              ? "--"
                                              : item.total_uploaded_quantity}
                                          </TableCell>
                                          <TableCell
                                            sx={{ border: "1px solid #ddd" }}
                                          >
                                            {item.total_available_quantity ==
                                            null
                                              ? "--"
                                              : item.total_available_quantity}
                                          </TableCell>
                                          <TableCell
                                            sx={{ border: "1px solid #ddd" }}
                                          >
                                            {item.total_sold_quantity == null
                                              ? "--"
                                              : item.total_sold_quantity}
                                          </TableCell>
                                          <TableCell
                                            sx={{ border: "1px solid #ddd" }}
                                          >
                                            {item.total_sold_order == null
                                              ? "--"
                                              : item.total_sold_order}
                                          </TableCell>
                                          <TableCell
                                            sx={{ border: "1px solid #ddd" }}
                                          >
                                            <div>
                                              <div>
                                                <span>Total : </span>
                                                <span>
                                                  {item.total_pre_order == null
                                                    ? "--"
                                                    : item.total_pre_order}
                                                </span>
                                              </div>
                                              <div className="mt-2">
                                                <span>
                                                  Pending :{" "}
                                                  {item.pending_pre_order ==
                                                  null ? (
                                                    "--"
                                                  ) : (
                                                    <span
                                                      style={{
                                                        border:
                                                          "1px solid #FF2D2D",
                                                        borderRadius: "5px",
                                                        width: "30px", // Set a fixed width
                                                        height: "30px", // Set a fixed height
                                                        display: "inline-flex", // Use inline-flex for proper alignment
                                                        justifyContent:
                                                          "center", // Center horizontally
                                                        alignItems: "center", // Center vertically
                                                        textAlign: "center", // Ensure the text is centered
                                                        color: "#FF2D2D",
                                                      }}
                                                    >
                                                      {item.pending_pre_order}
                                                    </span>
                                                  )}
                                                </span>
                                                <span
                                                  style={{ marginLeft: "5px" }}
                                                >
                                                  Sold :
                                                  {item.sold_pre_order ==
                                                  null ? (
                                                    "--"
                                                  ) : (
                                                    <span
                                                      style={{
                                                        border:
                                                          "1px solid #27AC5F",
                                                        borderRadius: "5px",
                                                        width: "30px", // Set a fixed width
                                                        height: "30px", // Set a fixed height
                                                        display: "inline-flex", // Use inline-flex for proper alignment
                                                        justifyContent:
                                                          "center", // Center horizontally
                                                        alignItems: "center", // Center vertically
                                                        textAlign: "center", // Ensure the text is centered
                                                        color: "#27AC5F",
                                                        marginLeft: "3px",
                                                      }}
                                                    >
                                                      {item.sold_pre_order}
                                                    </span>
                                                  )}
                                                </span>
                                              </div>
                                            </div>
                                          </TableCell>
                                          <TableCell
                                            sx={{ border: "1px solid #ddd" }}
                                          >
                                            <Link
                                              to={`/isindetails/${item?.batch_security_id}/${batch?.batch_no}`}
                                              style={{
                                                padding: "5px",
                                                borderRadius: "5px",
                                                backgroundColor: "#232020",
                                                color: "#FFFFFF",
                                              }}
                                            >
                                              View Details
                                            </Link>
                                          </TableCell>
                                        </TableRow>
                                      )
                                    )}
                                </TableBody>
                              </Table>
                            </AccordionDetails>
                          </Accordion>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default ISINOrderHistory;
