import Pagination from "@mui/material/Pagination";
import moment from "moment";
import React, { useEffect, useState } from "react";
// import DatePicker from 'react-date-picker';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppStateAPI } from "../common/AppStateAPI";
import DataTable from "react-data-table-component";
import {
  Depository,
  DistributorCategoryStatus,
  DealerStatus,
  PageLinks,
} from "../common/Constants";
import { APIData } from "../common/DataTypes";
import { toast } from "react-hot-toast";
import Footer from "../common/Footer";
import Header from "../common/Header";
import { ServerAPI } from "../common/ServerAPI";
import { getDisplayData, getFormattedLocalDateTime } from "../common/Utilities";
import ConfirmationModal from "../modals/Confirmation-modal";
import SimpleModal from "../modals/SimpleModal";

var startSerialNo = 0;
const DistributorCategoryList: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const passing_value = location.state;
  const [preData, setPreData] = useState(passing_value);

  // const [value, onChange] = useState(new Date());
  const [toData, setToData] = useState<null | Date>();
  const [fromDate, setFromDate] = useState<null | Date>();
  const [open, setOpen] = useState(false);
  const [open_modal, setOpenModal] = useState(false);
  const [userList, setUserList] = useState<any[]>([]);
  const [totalCount, setTotalCount] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [search_text, setSearchText] = useState("");
  const { setShowPreloader } = useAppStateAPI();
  const [distributorCategoryList, setDistributorCategoryList] = useState<
    APIData.DistributorCategoryList[]
  >([]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const pageCount = Math.ceil(totalCount / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const currPage = currentPage ? currentPage : 1;
  startSerialNo = currPage * 10 - 10;
  const [editingRowId, setEditingRowId] = useState(null);
  const [editvalue, setEditValue] = useState<any>();
  const customStyles = {
    headCells: {
      style: {
        backgroundColor: "#A27B1E",
        color: "white",
        fontWeight: "bold",
      },
    },
  };
  const handleEdit = (row: any) => {
    setEditValue({
      distributor_category_name: row?.distributor_category_name,
      distributor_category_status: row?.distributor_category_status,
    });
    setEditingRowId(row?.distributor_category_id);
  };
  const handleSave = () => {
    // setShowPreloader(true);
    ServerAPI.putDistributorCategory(
      editingRowId,
      editvalue?.distributor_category_name,
      editvalue?.distributor_category_status
    )
      .then((res) => {
        toast.success(res.message);
        distributorCategorylist();
      })
      .finally(() => {
        setEditingRowId(null);

        // setShowPreloader(false);
      });
  };

  let columns: any = [
    {
      name: "Sr.No",
      width: "90px",
      cell: (row: any, index: any) => startSerialNo + index + 1, //RDT provides index by default
    },
    {
      name: "Created Date",
      selector: (row: any) =>
        getFormattedLocalDateTime(row.distributor_category_created_at),
      sortable: true,
      format: (row: any) =>
        moment(row.distributor_category_created_at).format(
          "DD-MM-YYYY hh:mm A"
        ),
    },
    {
      name: "Last Updated",
      selector: (row: any) =>
        getFormattedLocalDateTime(row.distributor_category_updated_at),
      sortable: true,
      format: (row: any) =>
        moment(row.distributor_category_updated_at).format(
          "DD-MM-YYYY hh:mm A"
        ),
    },
    {
      name: "Distributor Category",
      selector: (row: any) => getDisplayData(row.distributor_category_name),
      sortable: true,
      cell: (row: any) =>
        editingRowId === row.distributor_category_id ? (
          <input
            type="text"
            defaultValue={row.distributor_category_name}
            onChange={(e) =>
              handleNameChange(row, "distributor_category_name", e.target.value)
            }
            style={{ width: "100%" }}
          />
        ) : (
          <span>{getDisplayData(row.distributor_category_name)}</span>
        ),
    },
    {
      name: "Action",
      cell: (row: any) => [
        <td className="city-action-rable-row  d-flex ">
          {editingRowId === row.distributor_category_id ? (
            <button onClick={() => handleSave()} className="edit-btn">
              {" "}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="22"
                height="22"
                viewBox="0 0 83.267 83.267"
              >
                <path
                  id="Path_27433"
                  data-name="Path 27433"
                  d="M-826.7,1049.441v49.833a8.921,8.921,0,0,1-8.921,8.923h-65.425a8.923,8.923,0,0,1-8.921-8.923v-65.423a8.922,8.922,0,0,1,8.921-8.921h49.835a8.9,8.9,0,0,1,6.308,2.612l15.59,15.593A8.889,8.889,0,0,1-826.7,1049.441Zm-24.443-11.316-.648-.648a2.228,2.228,0,0,0-1.578-.652h-42.474a2.231,2.231,0,0,0-2.23,2.23v19.33a2.231,2.231,0,0,0,2.23,2.23h43.121a2.23,2.23,0,0,0,2.23-2.23V1039.7A2.227,2.227,0,0,0-851.145,1038.125Zm-5.3,46.28a11.9,11.9,0,0,0-11.9-11.9,11.894,11.894,0,0,0-11.9,11.9,11.894,11.894,0,0,0,11.9,11.9A11.9,11.9,0,0,0-856.44,1084.405Z"
                  transform="translate(909.969 -1024.93)"
                />
              </svg>
            </button>
          ) : (
            <button onClick={() => handleEdit(row)} className="edit-btn">
              {" "}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="22"
                height="22"
                viewBox="0 0 83.267 83.267"
              >
                <path
                  id="Path_27431"
                  data-name="Path 27431"
                  d="M-1029.882,1033.83l18.052,18.052a1.956,1.956,0,0,1,0,2.767l-42.708,42.707-19.761,3.464a3.908,3.908,0,0,1-4.521-4.521l3.447-19.76,42.71-42.708A1.971,1.971,0,0,1-1029.882,1033.83Zm-34.692,44.92h-5.9l-1.838,10.491,5.058,5.058,10.49-1.838v-5.9h-7.806Zm9.092-5.871,25.047-25.046a2.271,2.271,0,0,0,0-3.221,2.269,2.269,0,0,0-3.221,0l-25.046,25.047a2.267,2.267,0,0,0,0,3.22A2.27,2.27,0,0,0-1055.482,1072.879Zm50.076-24.674a1.95,1.95,0,0,1-2.764,0l-18.053-18.052a1.955,1.955,0,0,1,0-2.766l7.5-7.5a7.823,7.823,0,0,1,11.042,0l9.775,9.775a7.794,7.794,0,0,1,0,11.042Z"
                  transform="translate(1078.882 -1017.61)"
                />
              </svg>
            </button>
          )}
          <div className="form-check form-switch mx-2">
            <input
              className="form-check-input "
              type="checkbox"
              role="switch"
              id="dealerStatus"
              onChange={(e) => {
                e.target.checked == true
                  ? (row.distributor_category_status = 1)
                  : (row.distributor_category_status = 0);
                setDistributorCatStatus(
                  e.target.checked == true
                    ? DealerStatus.Active
                    : DealerStatus.Disabled,
                  row.distributor_category_id
                );
                setDistributorCategoryList([...distributorCategoryList]);
              }}
              checked={
                row.distributor_category_status ==
                DistributorCategoryStatus.Active
                  ? true
                  : false
              }
            />
          </div>
        </td>,
      ],
    },
  ];
  const handlePageChange = (page: any) => {
    setCurrentPage(page);
    setPageNumber(page);
  };
  useEffect(() => {
    distributorCategorylist();
  }, [pageNumber, search_text, toData, fromDate]);

  const handleNameChange = (row: any, key: string, value: any) => {
    setEditValue({
      ...editvalue,
      [key]: value,
      distributor_category_status: row?.distributor_category_status,
    });
    // setData(updatedData);
  };
  const distributorCategorylist = () => {
    setShowPreloader(true);
    ServerAPI.getDistributorCategoryList(
      pageNumber,
      search_text,
      toData,
      fromDate
    )
      .then((res) => {
        setDistributorCategoryList(res.distributor_types);
        setTotalCount(res.total_count);
      })
      .finally(() => {
        setShowPreloader(false);
      });
  };

  const setDistributorCatStatus = (status: number, DistCatID: number) => {
    setShowPreloader(true);
    ServerAPI.setDistributorCatStatus(status, DistCatID)
      .then((res) => {
        if (res["message"] != undefined) {
          toast.success(res["message"]);
          console.log(res);
        }
      })
      .finally(() => {
        setShowPreloader(false);
      });
  };
  const CustomInput = React.forwardRef((props: any, ref: any) => {
    return (
      <div className="datePickerStyle">
        <label onClick={props.onClick} ref={ref}>
          {props.value || props.placeholder}
        </label>
        <img
          src="../../assets/images/calender-icon.svg"
          onClick={props.onClick}
          ref={ref}
        />
      </div>
    );
  });
  const resetfilter = () => {
    setFromDate(null);
    setToData(null);
  };
  return (
    <div>
      <div id="layout-wrapper">
        <Header />
        <div className="main-content py-4">
          <div className="page-content">
            <div className="container-fluid">
              <div className="row">
                <div className="col-12">
                  <div className="page-title-box d-sm-flex align-items-center justify-content-between pt-4 pb-0">
                    <div className="page-title-right">
                      <ol className="breadcrumb ">
                        {/* <li className="breadcrumb-item"><a style={{ color: "#90929E" }}>Trustmony</a></li> */}
                        <li className="breadcrumb-item">
                          <a> Masters </a>
                        </li>
                        <li className="breadcrumb-item">
                          <a> Distributor Category </a>
                        </li>
                      </ol>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <div className="card">
                    <div className="card-body col-sm-12 col-md-12 col-lg-12 w-100">
                      <div className="row" style={{ paddingBottom: "10px" }}>
                        <div className="col-xl-7">
                          <div className="d-flex justify-content-start align-items-center">
                            <h4 className="card-title text-end p-2 m-0">
                              Search :
                            </h4>
                            <form className="app-search card-title p-2 m-0">
                              <div className="position-relative">
                                <input
                                  type="text"
                                  className="select-style-search"
                                  onChange={(e) =>
                                    setSearchText(e.target.value)
                                  }
                                  // placeholder="Created Date, Last Updated, Di..."
                                />
                              </div>
                            </form>
                          </div>
                        </div>

                        <div className="col-xl-5 text-end">
                          <div className="d-flex justify-content-end align-items-center">
                            <form
                              className="app-search card-title p-2 m-0 w-50 text-center"
                              style={{ fontSize: "0.78rem" }}
                            >
                              <div className="position-relative btn-suspend">
                                <a
                                  className="text-white"
                                  onClick={() =>
                                    navigate(
                                      PageLinks.DISTRIBUTOR_CATEGORY_ADD,
                                      { state: { mode: "add" } }
                                    )
                                  }
                                >
                                  <i
                                    className="fa fa-plus-circle mx-2"
                                    aria-hidden="true"
                                  ></i>
                                  Add
                                </a>
                              </div>
                            </form>
                            <form className="app-search card-title p-2 m-0 w-100">
                              <div className="position-relative">
                                <DatePicker
                                  selected={fromDate}
                                  closeOnScroll={true}
                                  onChange={(date: Date) => setFromDate(date)}
                                  placeholderText="DD/MM/YYYY"
                                  className="date-picker-style"
                                  peekNextMonth
                                  showMonthDropdown
                                  showYearDropdown
                                  dropdownMode="select"
                                  customInput={<CustomInput />}
                                />
                              </div>
                            </form>
                            <h4 className="card-title p-2 m-0">TO</h4>
                            <form className="app-search card-title p-2 m-0 w-100">
                              <div className="position-relative">
                                <DatePicker
                                  selected={toData}
                                  closeOnScroll={true}
                                  onChange={(date: Date) => setToData(date)}
                                  placeholderText="DD/MM/YYYY"
                                  className="date-picker-style"
                                  peekNextMonth
                                  showMonthDropdown
                                  showYearDropdown
                                  dropdownMode="select"
                                  customInput={<CustomInput />}
                                />
                              </div>
                            </form>
                            <button
                              onClick={resetfilter}
                              className=" btn-suspend text-white"
                            >
                              Reset
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="table-rep-plugin">
                        <div
                          className="table-responsive "
                          data-pattern="priority-columns"
                        >
                          {/* <table id="tech-companies-1" className="table table table-bordered">
                                                        <thead className="city-table">
                                                            <tr>
                                                                <th>Sr.No</th>
                                                                <th>Created Date</th>
                                                                <th>Last Updated</th>
                                                                <th >Distributor Category</th>
                                                                <th>Action</th>
                                                            </tr>
                                                        </thead>
                                                        {
                                                            distributorCategoryList != undefined && distributorCategoryList.length > 0 ?
                                                                <>
                                                                    {
                                                                        distributorCategoryList.map((res: APIData.DistributorCategoryList, i: number) => (
                                                                            <tbody key={i}>
                                                                                <tr >
                                                                                    <td> {i + 1} </td>
                                                                                    <td>{getFormattedLocalDateTime(res.distributor_category_created_at)}</td>
                                                                                    <td>{getFormattedLocalDateTime(res.distributor_category_updated_at)}</td>
                                                                                    <td rowSpan={2}>{getDisplayData(res.distributor_category_name)}</td>

                                                                                    <td className="city-action-rable-row  d-flex ">
                                                                                        <img src='../../assets/images/edit-icon.svg' style={{ width: '6%', margin: "0px 5px" }}
                                                                                        onClick={() => navigate(PageLinks.DISTRIBUTOR_CATEGORY_ADD, { state: { mode: 'edit' , distributor_category_id: res.distributor_category_id ,distributor_category_name: res.distributor_category_name, distributor_category_status: res.distributor_category_status } })}
                                                                                        ></img>
                                                                                        <div className="form-check form-switch mx-2">
                                                                                            <input className="form-check-input " type="checkbox" role="switch" id="dealerStatus"
                                                                                                onChange={(e) => {
                                                                                                    e.target.checked == true ? res.distributor_category_status = 1 : res.distributor_category_status = 0;
                                                                                                    setDistributorCatStatus(e.target.checked == true ? DealerStatus.Active : DealerStatus.Disabled,res.distributor_category_id);
                                                                                                    setDistributorCategoryList([...distributorCategoryList]);
                                                                                                }}
                                                                                                checked={res.distributor_category_status == DistributorCategoryStatus.Active ? true : false}
                                                                                            />
                                                                                        </div>
                                                                                    </td>
                                                                                </tr>

                                                                            </tbody>

                                                                        ))
                                                                    }
                                                                </>
                                                                : <tbody>
                                                                    <tr className='py-4'>
                                                                        <td className='text-center py-5' colSpan={11}>No Data Found.</td>
                                                                    </tr>
                                                                </tbody>
                                                        }
                                                    </table> */}
                          <DataTable
                            data={distributorCategoryList}
                            columns={columns}
                            noHeader
                            defaultSortFieldId={"bond_name"}
                            sortIcon={<i className="fas fa-sort"></i>}
                            defaultSortAsc={true}
                            highlightOnHover
                            dense
                            customStyles={customStyles}
                          />
                        </div>
                      </div>
                      <div className="row mt-3">
                        <div className="col-sm-12 col-md-5">
                          {totalCount > 10 ? (
                            <div
                              className="dataTables_info"
                              id="datatable_info"
                              role="status"
                              aria-live="polite"
                            >
                              Showing {userList.length} of {totalCount} entries
                            </div>
                          ) : (
                            <div
                              className="dataTables_info"
                              id="datatable_info"
                              role="status"
                              aria-live="polite"
                            >
                              Showing {totalCount == 0 ? 0 : totalCount} entries
                            </div>
                          )}
                        </div>
                        <div className="col-sm-12 col-md-7">
                          <div
                            className="dataTables_paginate paging_simple_numbers float-end mb-3"
                            id="datatable_paginate"
                          >
                            <Pagination
                              count={Math.ceil(totalCount / 10)}
                              onChange={(event: any, value: any) => {
                                setPageNumber(Number(value));
                              }}
                              variant="outlined"
                              color="primary"
                              shape="rounded"
                              className="pagination-style"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default DistributorCategoryList;
