import React, { useEffect, useState } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Link, useNavigate } from "react-router-dom";
import { PageLinks } from "../common/Constants";
import OtpInput from 'react-otp-input';
import { InitialData } from "../common/InitialValue";
import { APIData } from "../common/DataTypes";
import { Formik } from "formik";
import { ServerAPI } from "../common/ServerAPI";
import { useAppStateAPI } from "../common/AppStateAPI";
import Countdown from 'react-countdown';
import { toast } from "react-hot-toast";
import { getIsLogin, persistLoginDetail } from "../common/Utilities";



const Login: React.FC = () => {

    const [otpmodel, setOtpModel] = useState(false);
    const [otp, setOtp] = useState('');
    const [mobile_number, setMobile_number] = useState(InitialData.Login);
    const navigate = useNavigate();
    const { setShowPreloader, setActiveColor } = useAppStateAPI();
    const [otpResend, setOTPResend] = useState(Date.now() + 30000);
    const [resendOtp, setResendOtp] = useState(false);

    useEffect(() => {
        getIsLogin().then(status => {
            if (status == true) {
                navigate(PageLinks.ALL_USERS_LOGS);
            }
        })
    }, [])

    const validate = (values: APIData.Login) => {
        let errors: any = {};
        if (values.mobile_number === "") {
            errors.mobile_number = "Mobile Number is required"
        }
        return errors;
    }

    const handleChange = (code: any) => {
        setOtp(code);

    }

    const otp_submit = () => {
        if (otp.length === 6) {
            setShowPreloader(true);

            ServerAPI.verifyOtp(otp, mobile_number).then((res) => {
                setActiveColor("Users");
                toast.success(res.message);
                localStorage.setItem('refresh_token', res?.data[0]?.refresh_token);
                localStorage.setItem('token', res?.data[0]?.access_token);
                localStorage.setItem('user_name', res?.data[0]?.admin?.admin_fullname);
                persistLoginDetail(true);
                navigate(PageLinks.USERS);
            }).finally(() => {
                setShowPreloader(false)
            })

        }
    }

    const disableButton = () => {
        setTimeout(function () {
            setResendOtp(false);
        }, 30000);
    }

    return (
        <div>
            <div className="auth-page">
                <div className="container-fluid p-0">
                    <div className="row g-0">
                        <div className="col-xxl-4 col-lg-5 col-md-5">
                            <section className="subscribe-letter-area pt-20 pb-20">
                                {/* <div className="line-shape d-none d-lg-inline-block">
                                    <img className="img-fluid" src="assets/images/Intersection_4.svg" alt="" />
                                </div> */}
                                <div className="auth-full-page-content d-flex p-4">
                                    <div className="w-100">
                                        <div className="d-flex flex-column " style={{ height: "96%" }}>
                                            <div className="text-center">
                                                <a className="d-block auth-logo">
                                                    <img src="assets/images/logo/header_logo_one.jpg" alt="logo" className="logo-img" />
                                                </a>
                                            </div>
                                            {!otpmodel ?
                                                <div className="auth-content mt-5">
                                                    <div className="text-center">
                                                        <h5 className="mb-0">Welcome Back !</h5>
                                                        <p className="text-muted mt-2">Sign in to continue to Your Account.</p>
                                                    </div>
                                                    <div className="mt-4 pt-2 text-center">
                                                        <Formik initialValues={mobile_number} validate={validate} enableReinitialize onSubmit={(values, { setSubmitting }) => {
                                                            if (values.mobile_number.length === 10) {

                                                                setMobile_number(values);
                                                                ServerAPI.Login(values).then((res) => {
                                                                    setOtpModel(true);
                                                                    toast.success(res.message);
                                                                    localStorage.setItem('hashkey', res?.data[0]?.verification_hash);
                                                                }).catch((err) => {
                                                                    setOtpModel(false);

                                                                })
                                                            }
                                                        }}
                                                        >
                                                            {({
                                                                values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting,                                /* and other goodies */
                                                            }) => (

                                                                <form className="login-form quote-form mb-20 col-lg-12" onSubmit={handleSubmit}>

                                                                    <div className="login-form quote-form mb-20 ">
                                                                        {/* <div className="email-input input_field pl-0">
                                                                            <label className="input-title">Mobile Number *</label>
                                                                            <input type="text" placeholder="Enter Mobile Number" maxLength={10}
                                                                                name="mobile_number" />
                                                                        </div> */}

                                                                        <div className="email-input input_field pl-0">
                                                                            <label className={errors.mobile_number && touched.mobile_number ? "error" : "input-title"}>Mobile Number *</label>
                                                                            <input type="text" placeholder="Enter Mobile Number"
                                                                                onChange={handleChange}
                                                                                onBlur={handleBlur}
                                                                                name="mobile_number"
                                                                                maxLength={10}
                                                                                value={values.mobile_number}
                                                                                className={errors.mobile_number && touched.mobile_number ? "input-error mobile_number" : "mobile_number"} />
                                                                            {errors.mobile_number && touched.mobile_number && (
                                                                                <span className="error">{errors.mobile_number}</span>
                                                                            )}
                                                                        </div>

                                                                    </div>
                                                                    <div className="mt-5">
                                                                        <button className={values.mobile_number.length === 10 ? "btn btn-primary w-100 waves-effect waves-light continue-btn_1 theme_btn primary_btn sub-btn wow fadeInUp2 valid_btn animated" : "btn btn-primary w-100 waves-effect waves-light continue-btn_1 theme_btn primary_btn sub-btn wow fadeInUp2  animated"} type="submit">Continue</button>
                                                                    </div>
                                                                </form>

                                                            )}
                                                        </Formik>
                                                    </div>
                                                </div>
                                                :
                                                <div className="auth-content mt-5">
                                                    <div className="text-center">
                                                        <h5 className="mb-0">OTP Verification</h5>
                                                        <p className="text-muted mt-2">Enter the 6 digits, One Time Password sent on your number</p>
                                                        <p className="review-text text-center">
                                                            <Link to={PageLinks.LOGIN} className="otp-content-text mobile_number">+91 {mobile_number.mobile_number} <i className="far fa-edit"></i></Link>
                                                        </p>
                                                    </div>

                                                    <form className="login-form quote-form mb-20 col-lg-12" >
                                                        <div className="email-input input_field otp-input p-0">
                                                            {/* <div className="row">
                                                                    <div className="col-2">
                                                                        <div className="mb-3">
                                                                            <label htmlFor="digit1-input" className="visually-hidden">Dight 1</label>
                                                                            <input type="text" className="form-control form-control-lg text-center" maxLength={1} id="digit1-input" />
                                                                        </div>
                                                                    </div>

                                                                    <div className="col-2">
                                                                        <div className="mb-3">
                                                                            <label htmlFor="digit2-input" className="visually-hidden">Dight 2</label>
                                                                            <input type="text" className="form-control form-control-lg text-center" maxLength={1} id="digit2-input" />
                                                                        </div>
                                                                    </div>

                                                                    <div className="col-2">
                                                                        <div className="mb-3">
                                                                            <label htmlFor="digit3-input" className="visually-hidden">Dight 3</label>
                                                                            <input type="text" className="form-control form-control-lg text-center" maxLength={1} id="digit3-input" />
                                                                        </div>
                                                                    </div>

                                                                    <div className="col-2">
                                                                        <div className="mb-3">
                                                                            <label htmlFor="digit4-input" className="visually-hidden">Dight 4</label>
                                                                            <input type="text" className="form-control form-control-lg text-center" maxLength={1} id="digit4-input" />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-2">
                                                                        <div className="mb-3">
                                                                            <label htmlFor="digit4-input" className="visually-hidden">Dight 4</label>
                                                                            <input type="text" className="form-control form-control-lg text-center" maxLength={1} id="digit4-input" />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-2">
                                                                        <div className="mb-3">
                                                                            <label htmlFor="digit4-input" className="visually-hidden">Dight 4</label>
                                                                            <input type="text" className="form-control form-control-lg text-center" maxLength={1} id="digit4-input" />
                                                                        </div>
                                                                    </div>
                                                                </div> */}
                                                            <div className=" my-2  col-lg-12">
                                                                <OtpInput
                                                                    value={otp}
                                                                    onChange={handleChange}
                                                                    numInputs={6}
                                                                    separator={<span style={{ width: "8px" }}></span>}
                                                                    isInputNum={true}
                                                                    shouldAutoFocus={true}
                                                                    inputStyle={{
                                                                        border: "1px solid lightgray",
                                                                        borderRadius: "8px",
                                                                        width: '100%',
                                                                        height: "45px",
                                                                        fontSize: "12px",
                                                                        color: "#000",
                                                                        fontWeight: "400",
                                                                        caretColor: "blue",
                                                                        // marginRight: "15px",
                                                                        background: "#FFFFFF"
                                                                    }}
                                                                    className="otp-style"
                                                                    focusStyle={{
                                                                        border: "1px solid #CFD3DB",
                                                                        outline: "none"
                                                                    }}
                                                                />

                                                                <label className="input-title otp-content-text d-flex resend-style py-2 ">Didn't get OTP?
                                                                    <div className="text-end">
                                                                        {resendOtp == true ?
                                                                            <Countdown
                                                                                date={otpResend}
                                                                                intervalDelay={0}
                                                                                renderer={(props) => <p className="review-text otp-counter mt-3">Get in&nbsp;{props.seconds}&nbsp;Seconds</p>}
                                                                            />
                                                                            :
                                                                            <a className="resend_otp_btn  theme_btn" data-wow-delay="0.5s" onClick={(e: any) => {
                                                                                e.preventDefault();
                                                                                setResendOtp(true);
                                                                                disableButton();
                                                                                setOTPResend(Date.now() + 30000);
                                                                            }}>Resend OTP</a>
                                                                        }
                                                                    </div>
                                                                </label>
                                                                <div className="divider_150"></div>
                                                                <div className=" my-4 col-lg-12">
                                                                    <button type="button" onClick={() => otp_submit()} disabled={otp.length === 6 ? false : true} className={otp.length === 6 ? "btn btn-primary w-100 waves-effect waves-light continue-btn theme_btn primary_btn sub-btn wow fadeInUp2 valid_btn animated" : "primary_btn_disabled w-100"} data-wow-delay="0.5s" >Verify</button>
                                                                </div>

                                                            </div>

                                                        </div>

                                                    </form>

                                                </div>
                                            }
                                        </div>
                                        <div className="text-center footer-copyrights">
                                            <p className="mb-0">© 2021-2022  Trust Capital Pvt ltd &nbsp;| &nbsp; U72123MH1234PTC123456</p>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>
                        <div className="col-xxl-8 col-lg-7 col-md-7">
                            <div className="auth-bg pt-md-5 p-4 d-flex">
                                <div className="bg-overlay login-page-right-content"></div>
                                <div className="row justify-content-center align-items-center">
                                    <div className="row justify-content-center align-items-center">
                                        <div className="col-xl-7">
                                            <div className="p-0 p-sm-4 px-xl-0">
                                                <div id="reviewcarouselIndicators" className="carousel slide" data-bs-ride="carousel">
                                                    {/* <div className="carousel-indicators carousel-indicators-rounded justify-content-start ms-0 mb-0">
                                                        <button type="button" data-bs-target="#reviewcarouselIndicators" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                                                        <button type="button" data-bs-target="#reviewcarouselIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
                                                        <button type="button" data-bs-target="#reviewcarouselIndicators" data-bs-slide-to="2" aria-label="Slide 3"></button>
                                                    </div> */}
                                                    {/* <div className="carousel-inner">
                                                        <div className="carousel-item active">
                                                            <div className="testi-contain text-white">
                                                                <i className="bx bxs-quote-alt-left text-success display-6"></i>

                                                                <h4 className="mt-4 fw-medium lh-base text-white">“I feel confident
                                                                    imposing change
                                                                    on myself. It's a lot more progressing fun than looking back.
                                                                    That's why
                                                                    I ultricies enim
                                                                    at malesuada nibh diam on tortor neaded to throw curve balls.”
                                                                </h4>
                                                                <div className="mt-4 pt-3 pb-5">
                                                                    <div className="d-flex align-items-start">
                                                                        <div className="flex-shrink-0">
                                                                            <img src="assets/images/users/avatar-1.jpg" className="avatar-md img-fluid rounded-circle" alt="..." />
                                                                        </div>
                                                                        <div className="flex-grow-1 ms-3 mb-4">
                                                                            <h5 className="font-size-18 text-white">Richard Drews
                                                                            </h5>
                                                                            <p className="mb-0 text-white-50">Web Designer</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="carousel-item">
                                                            <div className="testi-contain text-white">
                                                                <i className="bx bxs-quote-alt-left text-success display-6"></i>

                                                                <h4 className="mt-4 fw-medium lh-base text-white">“Our task must be to
                                                                    free ourselves by widening our circle of compassion to embrace
                                                                    all living
                                                                    creatures and
                                                                    the whole of quis consectetur nunc sit amet semper justo. nature
                                                                    and its beauty.”</h4>
                                                                <div className="mt-4 pt-3 pb-5">
                                                                    <div className="d-flex align-items-start">
                                                                        <div className="flex-shrink-0">
                                                                            <img src="assets/images/users/avatar-2.jpg" className="avatar-md img-fluid rounded-circle" alt="..." />
                                                                        </div>
                                                                        <div className="flex-grow-1 ms-3 mb-4">
                                                                            <h5 className="font-size-18 text-white">Rosanna French
                                                                            </h5>
                                                                            <p className="mb-0 text-white-50">Web Developer</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="carousel-item">
                                                            <div className="testi-contain text-white">
                                                                <i className="bx bxs-quote-alt-left text-success display-6"></i>

                                                                <h4 className="mt-4 fw-medium lh-base text-white">“I've learned that
                                                                    people will forget what you said, people will forget what you
                                                                    did,
                                                                    but people will never forget
                                                                    how donec in efficitur lectus, nec lobortis metus you made them
                                                                    feel.”</h4>
                                                                <div className="mt-4 pt-3 pb-5">
                                                                    <div className="d-flex align-items-start">
                                                                        <img src="assets/images/users/avatar-3.jpg" className="avatar-md img-fluid rounded-circle" alt="..." />
                                                                        <div className="flex-1 ms-3 mb-4">
                                                                            <h5 className="font-size-18 text-white">Ilse R. Eaton</h5>
                                                                            <p className="mb-0 text-white-50">Manager
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div> */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}
export default Login;