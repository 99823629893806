import { Field, Form, Formik, FormikProps } from 'formik';
import React, { useEffect, useRef, useState } from 'react';
import "react-datepicker/dist/react-datepicker.css";
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useAppStateAPI } from '../common/AppStateAPI';
import { Depository, NCDDetailsList, PageLinks, Strings, wysiwygToolbar } from '../common/Constants';
import Footer from '../common/Footer';
import Header from '../common/Header';
import { ServerAPI } from '../common/ServerAPI';
import * as Yup from 'yup';
import { Grid, Input, SvgIcon } from '@mui/material';
import FormField from './form_items/FormField';
import { APIData, FormDataTypes } from '../common/DataTypes';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { InitialData } from '../common/InitialValue';
import { toast } from 'react-hot-toast';
import { frameSelectOptions } from '../common/Utilities';

const IPOFormAdd: React.FC = () => {
    const navigate = useNavigate();
    const [user_id, setUserId] = useState()
    const { setShowPreloader } = useAppStateAPI();
    const [formikInitialData, setFormikInitialData] = useState<APIData.IPOFormAllocation>(InitialData.IPOFormAllocation);
    const formikRef = useRef<FormikProps<APIData.IPOFormAllocation>>(null);
    const [bondOptions, setBondOptions] = useState<FormDataTypes.SelectOption[]>([]);
    const [applicationStartSerialNo, setapplicationStartSerialNo] = useState(0);
    const [applicationEndSerialNo, setapplicationEndSerialNo] = useState(0);
    const [nextSeriesStartNo, setNextSeriesStartNo] = useState<number>(0);
    const [totalBalanceSeries, setTotalBalanceSeries] = useState<number>(0);

    useEffect(() => {
        loadIPOList();
    }, [])

    const loadIPOList = () => {
        setShowPreloader(true);
        ServerAPI.getIPOList().then((res) => {
            try {
                // console.log(res);
                let ipoOptions = frameSelectOptions(res.bonds_list, 'bond_id', 'bond_name');
                setBondOptions(ipoOptions);
            } catch (error) {
                toast.error(res.error)
            }
        }).finally(() => {
            setShowPreloader(false)
        })
    }

    useEffect(() => {
        console.log(formikInitialData)
        // setFormikInitialData(value => ({
        //     ...value,
        //     bond_form_allocation_form_balance:
        //         formikInitialData?.bond_form_allocation_form_balance != undefined ?
        //             formikInitialData?.bond_form_allocation_form_balance - (formikInitialData.bond_form_allocation_online_single_page - formikInitialData.bond_form_allocation_online_two_page - formikInitialData.bond_form_allocation_online_abridged)
        //             : 0
        // }));
    }, [formikInitialData])


    useEffect(() => {
        console.log(nextSeriesStartNo)
    }, [nextSeriesStartNo])

    const handleApplicationStartSeries = (e: any) => {
        e.preventDefault();
        setapplicationStartSerialNo(e.target.value);
        setNextSeriesStartNo(parseInt(e.target.value));

    }

    const handleApplicationEndSeries = (e: any) => {
        e.preventDefault();
        setapplicationEndSerialNo(e.target.value);
    }

    const handleSinglePageApplicationSeries = (e: any) => {
        e.preventDefault();
        if (formikInitialData.bond_form_allocation_form_start != 0 && formikInitialData.bond_form_allocation_form_end != 0) {
            setFormikInitialData(value => ({ ...value, bond_form_allocation_online_single_start: nextSeriesStartNo }));
            setFormikInitialData(value => ({ ...value, bond_form_allocation_online_single_end: nextSeriesStartNo + parseInt(e.target.value) }));
            setNextSeriesStartNo(nextSeriesStartNo + parseInt(e.target.value) + 1);
            setFormikInitialData(value => ({
                ...value,
                bond_form_allocation_form_balance:
                    formikInitialData?.bond_form_allocation_form_balance != undefined ?
                        formikInitialData?.bond_form_allocation_form_balance - formikInitialData.bond_form_allocation_online_single_page
                        : 0
            }));
        } else {
            toast.error('Please Enter Start and End Series value');
            setFormikInitialData(({ ...formikInitialData, bond_form_allocation_online_single_page: 0 }))
        }
    }

    const handleTwoPageApplicationSeries = (e: any) => {
        e.preventDefault();
        if (formikInitialData.bond_form_allocation_form_start != 0 && formikInitialData.bond_form_allocation_form_end != 0) {
            setFormikInitialData(value => ({ ...value, bond_form_allocation_online_two_start: nextSeriesStartNo }));
            setFormikInitialData(value => ({ ...value, bond_form_allocation_online_two_end: nextSeriesStartNo + parseInt(e.target.value) }));
            setNextSeriesStartNo(nextSeriesStartNo + parseInt(e.target.value) + 1);
            setFormikInitialData(value => ({
                ...value,
                bond_form_allocation_form_balance:
                    formikInitialData?.bond_form_allocation_form_balance != undefined ?
                        formikInitialData?.bond_form_allocation_form_balance - formikInitialData.bond_form_allocation_online_two_page
                        : 0
            }));
        } else {
            toast.error('Please Enter Start and End Series value');
            setFormikInitialData(({ ...formikInitialData, bond_form_allocation_online_two_page: 0 }))
        }
    }

    const handleAbbPageApplicationSeries = (e: any) => {
        e.preventDefault();
        if (formikInitialData.bond_form_allocation_form_start != 0 && formikInitialData.bond_form_allocation_form_end != 0) {
            setFormikInitialData(value => ({ ...value, bond_form_allocation_online_abridged_start: nextSeriesStartNo }));
            setFormikInitialData(value => ({ ...value, bond_form_allocation_online_abridged_end: nextSeriesStartNo + parseInt(e.target.value) }));
            setNextSeriesStartNo(nextSeriesStartNo + parseInt(e.target.value) + 1);
            setFormikInitialData(value => ({
                ...value,
                bond_form_allocation_form_balance:
                    formikInitialData?.bond_form_allocation_form_balance != undefined ?
                        formikInitialData?.bond_form_allocation_form_balance - formikInitialData.bond_form_allocation_online_abridged
                        : 0
            }));
        } else {
            toast.error('Please Enter Start and End Series value');
            setFormikInitialData(({ ...formikInitialData, bond_form_allocation_online_abridged: 0 }))
        }
    }

    // Dealer handler

    const handleSinglePageDealerApplicationSeries = (e: any) => {
        e.preventDefault();
        if (formikInitialData.bond_form_allocation_form_start != 0 && formikInitialData.bond_form_allocation_form_end != 0) {
            setFormikInitialData(value => ({ ...value, bond_form_allocation_dealer_single_start: nextSeriesStartNo }));
            setFormikInitialData(value => ({ ...value, bond_form_allocation_dealer_single_end: nextSeriesStartNo + parseInt(e.target.value) }));
            setNextSeriesStartNo(nextSeriesStartNo + parseInt(e.target.value) + 1);
            setFormikInitialData(value => ({
                ...value,
                bond_form_allocation_form_balance:
                    formikInitialData?.bond_form_allocation_form_balance != undefined ?
                        formikInitialData?.bond_form_allocation_form_balance - formikInitialData.bond_form_allocation_dealer_single_page
                        : 0
            }));
        } else {
            toast.error('Please Enter Start and End Series value');
            setFormikInitialData(({ ...formikInitialData, bond_form_allocation_dealer_single_page: 0 }))
        }
    }

    const handleTwoPageDealerApplicationSeries = (e: any) => {
        e.preventDefault();
        if (formikInitialData.bond_form_allocation_form_start != 0 && formikInitialData.bond_form_allocation_form_end != 0) {
            setFormikInitialData(value => ({ ...value, bond_form_allocation_dealer_two_start: nextSeriesStartNo }));
            setFormikInitialData(value => ({ ...value, bond_form_allocation_dealer_two_end: nextSeriesStartNo + parseInt(e.target.value) }));
            setNextSeriesStartNo(nextSeriesStartNo + parseInt(e.target.value) + 1);
            setFormikInitialData(value => ({
                ...value,
                bond_form_allocation_form_balance:
                    formikInitialData?.bond_form_allocation_form_balance != undefined ?
                        formikInitialData?.bond_form_allocation_form_balance - formikInitialData.bond_form_allocation_dealer_two_page
                        : 0
            }));
        } else {
            toast.error('Please Enter Start and End Series value');
            setFormikInitialData(({ ...formikInitialData, bond_form_allocation_dealer_two_page: 0 }))
        }
    }

    const handleAbbPageDealerApplicationSeries = (e: any) => {
        e.preventDefault();
        if (formikInitialData.bond_form_allocation_form_start != 0 && formikInitialData.bond_form_allocation_form_end != 0) {
            setFormikInitialData(value => ({ ...value, bond_form_allocation_dealer_abridged_start: nextSeriesStartNo }));
            setFormikInitialData(value => ({ ...value, bond_form_allocation_dealer_abridged_end: nextSeriesStartNo + parseInt(e.target.value) }));
            setNextSeriesStartNo(nextSeriesStartNo + parseInt(e.target.value) + 1);
            setFormikInitialData(value => ({
                ...value,
                bond_form_allocation_form_balance:
                    formikInitialData?.bond_form_allocation_form_balance != undefined ?
                        formikInitialData?.bond_form_allocation_form_balance - formikInitialData.bond_form_allocation_dealer_abridged
                        : 0
            }));
        } else {
            toast.error('Please Enter Start and End Series value');
            setFormikInitialData(({ ...formikInitialData, bond_form_allocation_dealer_abridged: 0 }))
        }
    }

    useEffect(() => {
        var totalCount: number = 0;
        totalCount = (applicationEndSerialNo) - (applicationStartSerialNo);
        setFormikInitialData(value => ({ ...value, bond_form_allocation_form_total: totalCount }));
        setFormikInitialData(value => ({ ...value, bond_form_allocation_form_balance: totalCount }));
    }, [applicationStartSerialNo, applicationEndSerialNo])

    // useEffect(() => {

    // }, [third])


    // useEffect(() => {
    //     var totalSeries: number = 0;
    //     for (let i = 0; i <= singlePageTotalCount; i++) {
    //         totalSeries = formikInitialData.bond_form_allocation_form_start + 1;
    //         console.log(totalSeries);
    //     }
    //     // setFormikInitialData(value => ({ ...value, bond_form_allocation_form_total: totalCount }));
    //     // setFormikInitialData(value => ({ ...value, bond_form_allocation_form_balance: totalCount }));
    // }, [singlePageTotalCount])


    const yupSchema = Yup.object().shape({
        bond_id: Yup.string().notOneOf(["0"], Strings.ValidationSelect).required(Strings.ValidationRequired),
        bond_form_allocation_form_start: Yup.string().required('Application Start No is required'),
        bond_form_allocation_form_end: Yup.string().required('Application End No is required'),
    });

    const submitData = (data: any, setSubmitting: any) => {
        console.log(data);
        setShowPreloader(true);
        ServerAPI.postFormAllocationAdd(data).then((res) => {
            toast.success(res.message);
            console.log(res);
            // navigate(PageLinks.BOND_DETAIL, { state: res.bond_isin_number });
            navigate(-1);
            setSubmitting(false);
        }).finally(() => {
            setShowPreloader(false);
        })
    }


    return (
        <div>
            <div id="layout-wrapper">
                <Header />
                <div className="main-content py-4">
                    <div className="page-content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-12">
                                    <div className="page-title-box d-sm-flex align-items-center justify-content-between pt-4 pb-0">
                                        <div className="page-title-right">
                                            <ol className="breadcrumb ">
                                                {/* <li className="breadcrumb-item"><a style={{ color: "#90929E" }}>Trustmony</a></li> */}
                                                <li className="breadcrumb-item"><a> Master </a></li>
                                                <li className="breadcrumb-item">IPO Form Master</li>
                                                <li className="breadcrumb-item active ">Add Form Allocation</li>
                                            </ol>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <div className="card">
                                        <div className="card-body col-sm-12 col-md-12 col-lg-12 w-100">
                                            <Formik
                                                validationSchema={yupSchema}
                                                enableReinitialize={true}
                                                validateOnChange={false}
                                                initialValues={formikInitialData}
                                                innerRef={formikRef}
                                                onSubmit={(values, { setSubmitting }) => {
                                                    console.log(values);
                                                    submitData(values, setSubmitting);
                                                }}
                                            >{(formikProps) => {
                                                return (
                                                    <Form onSubmit={formikProps.handleSubmit}>
                                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                            <div className="row">
                                                                <div className="card" style={{ borderTopLeftRadius: "10px", borderTopRightRadius: "10px", paddingTop: "10px", paddingBottom: "20px" }}>
                                                                    <div className="card-header">
                                                                        <h5 className="m-0 header"> Add Form Allocation </h5>
                                                                    </div>
                                                                    <div className="card-body">
                                                                        <Grid direction="row" container spacing={2}>
                                                                            {/* <div className='d-grid'>
                                                                                    <label htmlFor="Bond Name" className='bond-label'>IPO Name</label>
                                                                                    <input id="Bond Name" name="lastName" type="number" className='bond-input'
                                                                                    value={formikProps.values?.bond_id}
                                                                                    onChange={e => setFormikInitialData(({ ...formikInitialData, bond_id: parseInt(e.target.value) }))}
                                                                                    />
                                                                                </div> */}
                                                                            <Grid item xs={6}>
                                                                                <div className='d-grid'>
                                                                                    <label htmlFor="bond_id" className='bond-label'>IPO Name</label>
                                                                                    <select id="bond_id" name="bond_id"
                                                                                        value={formikProps.values.bond_id}
                                                                                        onChange={e => setFormikInitialData(value => ({ ...value, bond_id: parseInt(e.target.value) }))}
                                                                                        className='bond-input-select'
                                                                                    >
                                                                                        {bondOptions !== undefined && !bondOptions.some(bondOptions => bondOptions.value === "0") && (
                                                                                            <option value="0">Select</option>
                                                                                        )}
                                                                                        {bondOptions && bondOptions.map((option, index) => {
                                                                                            return (<option key={'option-' + index} value={option.value}>{option.label}</option>)
                                                                                        })}
                                                                                    </select>
                                                                                </div>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </div>
                                                                    <div className="card-body">
                                                                        <Grid direction="row" container spacing={2}>
                                                                            <Grid item xs={3}>
                                                                                <div className='d-grid'>
                                                                                    <label htmlFor="bond_form_allocation_form_start" className='bond-label'>Application Start No.</label>
                                                                                    <input id="bond_form_allocation_form_start" name="bond_form_allocation_form_start" type="number" className='bond-input'
                                                                                        value={formikProps.values?.bond_form_allocation_form_start}
                                                                                        onChange={e => setFormikInitialData(({ ...formikInitialData, bond_form_allocation_form_start: parseInt(e.target.value) }))}
                                                                                        onBlur={e => handleApplicationStartSeries(e)}
                                                                                    />
                                                                                </div>
                                                                            </Grid>
                                                                            <Grid item xs={3}>
                                                                                <div className='d-grid'>
                                                                                    <label htmlFor="bond_form_allocation_form_end" className='bond-label'>Application End No.</label>
                                                                                    <input id="bond_form_allocation_form_end" name="bond_form_allocation_form_end" type="number" className='bond-input'
                                                                                        value={formikProps.values?.bond_form_allocation_form_end}
                                                                                        onChange={e => setFormikInitialData(({ ...formikInitialData, bond_form_allocation_form_end: parseInt(e.target.value) }))}
                                                                                        onBlur={e => handleApplicationEndSeries(e)}
                                                                                    />
                                                                                </div>
                                                                            </Grid>
                                                                            <Grid item xs={3}>
                                                                                <div className='d-grid'>
                                                                                    <label htmlFor="bond_form_allocation_form_total" className='bond-label'>Total No. Of Application Forms</label>
                                                                                    <input id="bond_form_allocation_form_total" name="bond_form_allocation_form_total" type="number" className='bond-input-disabled' disabled
                                                                                        value={formikProps.values?.bond_form_allocation_form_total}
                                                                                    // onChange={e => setFormikInitialData(({ ...formikInitialData, bond_form_allocation_form_total: parseInt(e.target.value) }))}
                                                                                    />
                                                                                </div>
                                                                            </Grid>
                                                                            <Grid item xs={3}>
                                                                                <div className='d-grid'>
                                                                                    <label htmlFor="Bond Name" className='bond-label'>Balance Application Forms (Online + Dealer)</label>
                                                                                    <input id="Bond Name" name="lastName" type="number" className='bond-input-disabled' disabled
                                                                                        value={formikProps.values?.bond_form_allocation_form_balance ? formikProps.values?.bond_form_allocation_form_balance : 0}
                                                                                    // onChange={e => setFormikInitialData(({ ...formikInitialData, bond_form_allocation_form_balance: parseInt(e.target.value) }))}
                                                                                    />
                                                                                </div>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </div>
                                                                    <div className="card-body">
                                                                        <div className="card-header px-0" style={{ borderBottom: 'none' }}>
                                                                            <h5 className="m-0 header">For Online</h5>
                                                                        </div>
                                                                        <div className='row'>
                                                                            <div className='col-lg-2 d-flex align-items-center my-auto'>
                                                                                <div className="form-check mt-4 fs-5 ">
                                                                                    <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                                                                    <label className="form-check-label fw-bolde" htmlFor="flexCheckDefault" style={{ fontWeight: "800" }}>
                                                                                        Single Page form
                                                                                    </label>
                                                                                </div>
                                                                            </div>
                                                                            <div className='col-lg-10 d-flex align-items-center py-2'>
                                                                                <Grid direction="row" container spacing={2}>
                                                                                    <Grid item xs={3}>
                                                                                        <div className='d-grid'>
                                                                                            <label htmlFor="bond_form_allocation_online_total" className='bond-label'>No. Of Application Forms</label>
                                                                                            <input id="bond_form_allocation_online_total" name="bond_form_allocation_online_total" type="number" className='bond-input'
                                                                                                value={formikProps.values?.bond_form_allocation_online_single_page}
                                                                                                onChange={e => {
                                                                                                    setFormikInitialData(({ ...formikInitialData, bond_form_allocation_online_single_page: parseInt(e.target.value) }))
                                                                                                }}
                                                                                                onBlur={e =>
                                                                                                    handleSinglePageApplicationSeries(e)
                                                                                                }
                                                                                            />
                                                                                        </div>
                                                                                    </Grid>
                                                                                    <Grid item xs={3}>
                                                                                        <div className='d-grid'>
                                                                                            <label htmlFor="bond_form_allocation_online_start" className='bond-label'>Application Start No.</label>
                                                                                            <input id="bond_form_allocation_online_start" name="bond_form_allocation_online_start" type="number" disabled className='bond-input-disabled'
                                                                                                value={formikProps.values?.bond_form_allocation_online_single_start}
                                                                                            // onChange={e => setFormikInitialData(({ ...formikInitialData, bond_form_allocation_online_single_start: parseInt(e.target.value) }))}
                                                                                            />
                                                                                        </div>
                                                                                    </Grid>
                                                                                    <Grid item xs={3}>
                                                                                        <div className='d-grid'>
                                                                                            <label htmlFor="bond_form_allocation_online_end" className='bond-label'>Application End No.</label>
                                                                                            <input id="bond_form_allocation_online_end" name="bond_form_allocation_online_end" type="number" disabled className='bond-input-disabled'
                                                                                                value={formikProps.values?.bond_form_allocation_online_single_end}
                                                                                            // onChange={e => setFormikInitialData(({ ...formikInitialData, bond_form_allocation_online_single_end: parseInt(e.target.value) }))}
                                                                                            />
                                                                                        </div>
                                                                                    </Grid>
                                                                                    {/* <Grid item xs={3}>
                                                                                        <div className='d-grid'>
                                                                                            <label htmlFor="Bond Name" className='bond-label'>Balance Application</label>
                                                                                            <input id="Bond Name" name="lastName" type="text" disabled className='bond-input-disabled'
                                                                                            // onChange={formikProps.handleChange} value={formikProps.values.lastName}
                                                                                            />
                                                                                        </div>
                                                                                    </Grid> */}
                                                                                </Grid>
                                                                            </div>
                                                                        </div>
                                                                        <div className='row'>
                                                                            <div className='col-lg-2 d-flex align-items-center my-auto'>
                                                                                <div className="form-check mt-4 fs-5 ">
                                                                                    <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                                                                    <label className="form-check-label fw-bolde" htmlFor="flexCheckDefault" style={{ fontWeight: "800" }}>
                                                                                        Two Page form
                                                                                    </label>
                                                                                </div>
                                                                            </div>
                                                                            <div className='col-lg-10 d-flex align-items-center  py-2'>
                                                                                <Grid direction="row" container spacing={2}>
                                                                                    <Grid item xs={3}>
                                                                                        <div className='d-grid'>
                                                                                            <label htmlFor="Bond Name" className='bond-label'>No. Of Application Forms</label>
                                                                                            <input id="Bond Name" name="lastName" type="number" className='bond-input'
                                                                                                value={formikProps.values?.bond_form_allocation_online_two_page}
                                                                                                onChange={e => {
                                                                                                    setFormikInitialData(({ ...formikInitialData, bond_form_allocation_online_two_page: parseInt(e.target.value) }))
                                                                                                }}
                                                                                                onBlur={e =>
                                                                                                    handleTwoPageApplicationSeries(e)
                                                                                                }
                                                                                            />
                                                                                        </div>
                                                                                    </Grid>
                                                                                    <Grid item xs={3}>
                                                                                        <div className='d-grid'>
                                                                                            <label htmlFor="Bond Name" className='bond-label'>Application Start No.</label>
                                                                                            <input id="Bond Name" name="lastName" type="number" disabled className='bond-input-disabled'
                                                                                                value={formikProps.values?.bond_form_allocation_online_two_start}
                                                                                            // onChange={e => setFormikInitialData(({ ...formikInitialData, bond_form_allocation_online_two_page: parseInt(e.target.value) }))}
                                                                                            />
                                                                                        </div>
                                                                                    </Grid>
                                                                                    <Grid item xs={3}>
                                                                                        <div className='d-grid'>
                                                                                            <label htmlFor="Bond Name" className='bond-label'>Application End No.</label>
                                                                                            <input id="Bond Name" name="lastName" type="number" disabled className='bond-input-disabled'
                                                                                                value={formikProps.values?.bond_form_allocation_online_two_end}
                                                                                            // onChange={e => setFormikInitialData(({ ...formikInitialData, bond_form_allocation_online_two_page: parseInt(e.target.value) }))}
                                                                                            />
                                                                                        </div>
                                                                                    </Grid>
                                                                                    {/* <Grid item xs={3}>
                                                                                        <div className='d-grid'>
                                                                                            <label htmlFor="Bond Name" className='bond-label'>Balance Application</label>
                                                                                            <input id="Bond Name" name="lastName" type="text" disabled className='bond-input-disabled' />
                                                                                        </div>
                                                                                    </Grid> */}
                                                                                </Grid>
                                                                            </div>
                                                                        </div>
                                                                        <div className='row'>
                                                                            <div className='col-lg-2 d-flex align-items-center my-auto'>
                                                                                <div className="form-check mt-4 fs-5 ">
                                                                                    <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                                                                    <label className="form-check-label fw-bolde" htmlFor="flexCheckDefault" style={{ fontWeight: "800" }}>
                                                                                        Form with Abridged Prospectus
                                                                                    </label>
                                                                                </div>
                                                                            </div>
                                                                            <div className='col-lg-10 d-flex align-items-center  py-2'>
                                                                                <Grid direction="row" container spacing={2}>
                                                                                    <Grid item xs={3}>
                                                                                        <div className='d-grid'>
                                                                                            <label htmlFor="Bond Name" className='bond-label'>No. Of Application Forms</label>
                                                                                            <input id="Bond Name" name="lastName" type="number" className='bond-input'
                                                                                                value={formikProps.values?.bond_form_allocation_online_abridged}
                                                                                                onChange={e => {
                                                                                                    setFormikInitialData(({ ...formikInitialData, bond_form_allocation_online_abridged: parseInt(e.target.value) }))
                                                                                                }}
                                                                                                onBlur={e => {
                                                                                                    handleAbbPageApplicationSeries(e)
                                                                                                }}
                                                                                            />
                                                                                        </div>
                                                                                    </Grid>
                                                                                    <Grid item xs={3}>
                                                                                        <div className='d-grid'>
                                                                                            <label htmlFor="Bond Name" className='bond-label'>Application Start No.</label>
                                                                                            <input id="Bond Name" name="lastName" type="number" disabled className='bond-input-disabled'
                                                                                                value={formikProps.values?.bond_form_allocation_online_abridged_start}
                                                                                            />
                                                                                        </div>
                                                                                    </Grid>
                                                                                    <Grid item xs={3}>
                                                                                        <div className='d-grid'>
                                                                                            <label htmlFor="Bond Name" className='bond-label'>Application End No.</label>
                                                                                            <input id="Bond Name" name="lastName" type="number" disabled className='bond-input-disabled'
                                                                                                value={formikProps.values?.bond_form_allocation_online_abridged_end}
                                                                                            />
                                                                                        </div>
                                                                                    </Grid>
                                                                                    {/* <Grid item xs={3}>
                                                                                        <div className='d-grid'>
                                                                                            <label htmlFor="Bond Name" className='bond-label'>Balance Application</label>
                                                                                            <input id="Bond Name" name="lastName" type="text" disabled className='bond-input-disabled' />
                                                                                        </div>
                                                                                    </Grid> */}
                                                                                </Grid>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="card-body">
                                                                        <div className="card-header px-0" style={{ borderBottom: 'none' }}>
                                                                            <h5 className="m-0 header">For Dealers</h5>
                                                                        </div>
                                                                        <div className='row'>
                                                                            <div className='col-lg-2 d-flex align-items-center my-auto'>
                                                                                <div className="form-check mt-4 fs-5 ">
                                                                                    <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                                                                    <label className="form-check-label fw-bolde" htmlFor="flexCheckDefault" style={{ fontWeight: "800" }}>
                                                                                        Single Page form
                                                                                    </label>
                                                                                </div>
                                                                            </div>
                                                                            <div className='col-lg-10 d-flex align-items-center  py-2'>
                                                                                <Grid direction="row" container spacing={2}>
                                                                                    <Grid item xs={3}>
                                                                                        <div className='d-grid'>
                                                                                            <label htmlFor="Bond Name" className='bond-label'>No. Of Application Forms</label>
                                                                                            <input id="Bond Name" name="lastName" type="text" className='bond-input'
                                                                                                value={formikProps.values?.bond_form_allocation_dealer_single_page}
                                                                                                onChange={e => {
                                                                                                    setFormikInitialData(({ ...formikInitialData, bond_form_allocation_dealer_single_page: parseInt(e.target.value) }))
                                                                                                }}
                                                                                                onBlur={e =>
                                                                                                    handleSinglePageDealerApplicationSeries(e)
                                                                                                }
                                                                                            />
                                                                                        </div>
                                                                                    </Grid>
                                                                                    <Grid item xs={3}>
                                                                                        <div className='d-grid'>
                                                                                            <label htmlFor="Bond Name" className='bond-label'>Application Start No.</label>
                                                                                            <input id="Bond Name" name="lastName" type="text" disabled className='bond-input-disabled'
                                                                                                value={formikProps.values?.bond_form_allocation_dealer_single_start}
                                                                                            />
                                                                                        </div>
                                                                                    </Grid>
                                                                                    <Grid item xs={3}>
                                                                                        <div className='d-grid'>
                                                                                            <label htmlFor="Bond Name" className='bond-label'>Application End No.</label>
                                                                                            <input id="Bond Name" name="lastName" type="text" disabled className='bond-input-disabled'
                                                                                                value={formikProps.values?.bond_form_allocation_dealer_single_end}
                                                                                            />
                                                                                        </div>
                                                                                    </Grid>
                                                                                    {/* <Grid item xs={3}>
                                                                                        <div className='d-grid'>
                                                                                            <label htmlFor="Bond Name" className='bond-label'>Balance Application</label>
                                                                                            <input id="Bond Name" name="lastName" type="text" disabled className='bond-input-disabled' />
                                                                                        </div>
                                                                                    </Grid> */}
                                                                                </Grid>
                                                                            </div>
                                                                        </div>
                                                                        <div className='row'>
                                                                            <div className='col-lg-2 d-flex align-items-center my-auto'>
                                                                                <div className="form-check mt-4 fs-5 ">
                                                                                    <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                                                                    <label className="form-check-label fw-bolde" htmlFor="flexCheckDefault" style={{ fontWeight: "800" }}>
                                                                                        Two Page form
                                                                                    </label>
                                                                                </div>
                                                                            </div>
                                                                            <div className='col-lg-10 d-flex align-items-center py-2'>
                                                                                <Grid direction="row" container spacing={2}>
                                                                                    <Grid item xs={3}>
                                                                                        <div className='d-grid'>
                                                                                            <label htmlFor="Bond Name" className='bond-label'>No. Of Application Forms</label>
                                                                                            <input id="Bond Name" name="lastName" type="text" className='bond-input'
                                                                                                value={formikProps.values?.bond_form_allocation_dealer_two_page}
                                                                                                onChange={e => {
                                                                                                    setFormikInitialData(({ ...formikInitialData, bond_form_allocation_dealer_two_page: parseInt(e.target.value) }))
                                                                                                }}
                                                                                                onBlur={e =>
                                                                                                    handleTwoPageDealerApplicationSeries(e)
                                                                                                }
                                                                                            />
                                                                                        </div>
                                                                                    </Grid>
                                                                                    <Grid item xs={3}>
                                                                                        <div className='d-grid'>
                                                                                            <label htmlFor="Bond Name" className='bond-label'>Application Start No.</label>
                                                                                            <input id="Bond Name" name="lastName" type="text" disabled className='bond-input-disabled'
                                                                                                value={formikProps.values?.bond_form_allocation_dealer_two_start}
                                                                                            />
                                                                                        </div>
                                                                                    </Grid>
                                                                                    <Grid item xs={3}>
                                                                                        <div className='d-grid'>
                                                                                            <label htmlFor="Bond Name" className='bond-label'>Application End No.</label>
                                                                                            <input id="Bond Name" name="lastName" type="text" disabled className='bond-input-disabled'
                                                                                                value={formikProps.values?.bond_form_allocation_dealer_two_end}
                                                                                            />
                                                                                        </div>
                                                                                    </Grid>
                                                                                    {/* <Grid item xs={3}>
                                                                                        <div className='d-grid'>
                                                                                            <label htmlFor="Bond Name" className='bond-label'>Balance Application</label>
                                                                                            <input id="Bond Name" name="lastName" type="text" disabled className='bond-input-disabled' />
                                                                                        </div>
                                                                                    </Grid> */}
                                                                                </Grid>
                                                                            </div>
                                                                        </div>
                                                                        <div className='row'>
                                                                            <div className='col-lg-2 d-flex align-items-center my-auto'>
                                                                                <div className="form-check mt-4 fs-5 ">
                                                                                    <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                                                                    <label className="form-check-label fw-bolde" htmlFor="flexCheckDefault" style={{ fontWeight: "800" }}>
                                                                                        Form with Abridged Prospectus
                                                                                    </label>
                                                                                </div>
                                                                            </div>
                                                                            <div className='col-lg-10 d-flex align-items-center py-2'>
                                                                                <Grid direction="row" container spacing={2}>
                                                                                    <Grid item xs={3}>
                                                                                        <div className='d-grid'>
                                                                                            <label htmlFor="Bond Name" className='bond-label'>No. Of Application Forms</label>
                                                                                            <input id="Bond Name" name="lastName" type="text" className='bond-input'
                                                                                                value={formikProps.values?.bond_form_allocation_dealer_abridged}
                                                                                                onChange={e => {
                                                                                                    setFormikInitialData(({ ...formikInitialData, bond_form_allocation_dealer_abridged: parseInt(e.target.value) }))
                                                                                                }}
                                                                                                onBlur={e =>
                                                                                                    handleAbbPageDealerApplicationSeries(e)
                                                                                                }
                                                                                            />
                                                                                        </div>
                                                                                    </Grid>
                                                                                    <Grid item xs={3}>
                                                                                        <div className='d-grid'>
                                                                                            <label htmlFor="Bond Name" className='bond-label'>Application Start No.</label>
                                                                                            <input id="Bond Name" name="lastName" type="text" disabled className='bond-input-disabled'
                                                                                                value={formikProps.values?.bond_form_allocation_dealer_abridged_start}
                                                                                            />
                                                                                        </div>
                                                                                    </Grid>
                                                                                    <Grid item xs={3}>
                                                                                        <div className='d-grid'>
                                                                                            <label htmlFor="Bond Name" className='bond-label'>Application End No.</label>
                                                                                            <input id="Bond Name" name="lastName" type="text" disabled className='bond-input-disabled'
                                                                                                value={formikProps.values?.bond_form_allocation_dealer_abridged_end}
                                                                                            />
                                                                                        </div>
                                                                                    </Grid>
                                                                                    {/* <Grid item xs={3}>
                                                                                        <div className='d-grid'>
                                                                                            <label htmlFor="Bond Name" className='bond-label'>Balance Application</label>
                                                                                            <input id="Bond Name" name="lastName" type="text" disabled className='bond-input-disabled' />
                                                                                        </div>
                                                                                    </Grid> */}
                                                                                </Grid>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <br />
                                                            </div>

                                                            <div className='row text-end my-3 py-5 '>
                                                                <div className=''>
                                                                    <a className='cancel-btn mx-3' onClick={() => navigate(-1)}>Cancel</a>
                                                                    <button type='submit' className='save-btn'>
                                                                        Save
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </LocalizationProvider>
                                                    </Form>
                                                )
                                            }}
                                            </Formik>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </div>
    );
}

export default IPOFormAdd;

