import Pagination from "@mui/material/Pagination";
import moment from "moment";
import React, { useEffect, useState } from "react";
import "./customerOrder.css";
// import DatePicker from 'react-date-picker';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import DataTable from "react-data-table-component";
import { toast } from "react-hot-toast";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppStateAPI } from "../common/AppStateAPI";
import * as Utilities from "../common/Utilities";
import {
  BondType,
  BondTypes,
  OrderType,
  Depository,
  PageLinks,
} from "../common/Constants";
import { APIData } from "../common/DataTypes";
import Footer from "../common/Footer";
import Header from "../common/Header";
import { InitialData } from "../common/InitialValue";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { ServerAPI } from "../common/ServerAPI";
import {
  getDealerStatus,
  getDisplayData,
  getPaymentStatus,
  getDownloadFile,
  getFormattedLocalDateTime,
  getOrderStatus,
  getOrderTypeFilter,
  numberWithCommas,
} from "../common/Utilities";
import ConfirmationModal from "../modals/Confirmation-modal";
import SimpleModal from "../modals/SimpleModal";

var startSerialNo = 0;
const CustomerOrderList: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const passing_value = location.state;
  const [preData, setPreData] = useState(passing_value);
  const [bondSelectOption, setBondSelectOption] = useState(0);

  // const [value, onChange] = useState(new Date());
  const [toData, setToData] = useState<null | Date>();
  const [fromDate, setFromDate] = useState<null | Date>();
  const [open, setOpen] = useState(false);
  const [open_modal, setOpenModal] = useState(false);
  const [orderList, setOrderList] = useState<APIData.CustomerOrderList[]>([]);
  const [totalCount, setTotalCount] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [user_id, setUserId] = useState();
  const [view_content, setViewContent] = useState();
  const [search_text, setSearchText] = useState("");
  const { setShowPreloader } = useAppStateAPI();
  const [tabIndex, setTabIndex] = useState(0);
  const searchref = React.createRef();
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const pageCount = Math.ceil(totalCount / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const currPage = currentPage ? currentPage : 1;
  startSerialNo = currPage * 10 - 10;

  const statusColors = [
    "accepted",
    "cancelled",
    "expired",
    "failed",
    "pending",
    "rfq-accepted",
    "rfq-expired",
    "rfq-rejected",
    "settled",
  ];

  let columns: any = [
    {
      name: "Sr.No",
      width: "90px",
      cell: (row: any, index: any) => startSerialNo + index + 1, //RDT provides index by default
    },

    {
      name: "Order No",
      selector: (row: any) => (
        <b className={`ord-title`}>{getDisplayData(row.order_number)}</b>
      ),
      sortable: true,
      width: "300px",
    },
    {
      name: "Order Date & Time",
      selector: (row: any) => row.order_datetime,
      sortable: true,
      width: "200px",
      format: (row: any) =>
        moment(row.order_datetime).format("DD-MM-YYYY,  hh:mm A"),
    },
    {
      name: "Customer Name",
      selector: (row: any) =>
        getDisplayData(row?.user?.personal?.personal_fullname),
      sortable: true,
      width: "200px",
      wrap: true,
    },

    {
      name: "Bond / IPO Name",
      selector: (row: any) => getDisplayData(row?.security?.security_name),
      sortable: true,
      width: "400px",
    },
    {
      name: "ISIN Number",
      selector: (row: any) => getDisplayData(row.security?.security_isin),
      sortable: true,
      width: "200px",
    },
    {
      name: "Coupon Rate (%)",
      selector: (row: any) =>
        getDisplayData(row.security?.security_coupon_rate),
      sortable: true,
      width: "300px",
    },
    {
      name: "Maturity Date",
      selector: (row: any) =>
        getFormattedLocalDateTime(row.security?.security_maturity_date),
      sortable: true,
      width: "200px",
    },
    {
      name: "Price",
      selector: (row: any) => `₹ ${numberWithCommas(row.order_price)}`,
      sortable: true,
    },
    {
      name: "Exchange",
      selector: (row: any) => (
        <b className={`${row.exchange?.exchange_name}-color`}>
          {getDisplayData(row.exchange?.exchange_name)}
        </b>
      ),
      sortable: true,
      width: "300px",
    },
    {
      name: "No. Of Quantity",
      selector: (row: any) => getDisplayData(row?.order_quantity),
      sortable: true,
      width: "300px",
    },
    {
      name: "Total Amount",
      selector: (row: any) => `₹ ${numberWithCommas(row?.order_value)}`,
      sortable: true,
      width: "300px",
    },

    {
      name: "Payment Status",
      selector: (row: any) => (
        <b
          className={`${
            row?.payment?.payment_status_uid === "TNX_SUC"
              ? "success-capsule"
              : row?.payment?.payment_status_uid === "TNX_REQ" ||
                row?.payment?.payment_status_uid === "TNX_REQ"
              ? "warning-capsule"
              : "failed-capsule"
          }`}
        >
          {getDisplayData(row?.payment?.payment_status)}
        </b>
      ),
      sortable: true,
      width: "200px",
    },
    {
      name: "Order Status",
      selector: (row: any) => (
        <b
          className={`${
            row?.order_status_uid === "ORD_RFQ_ACC" ||
            row?.order_status_uid === "ORD_ACC" ||
            row?.order_status_uid === "ORD_RFQ_ACC"
              ? "success-capsule"
              : "failed-capsule"
          }`}
        >
          {getDisplayData(row?.order_status)}
        </b>
      ),
      sortable: true,
      width: "200px",
    },
    {
      name: "Action",
      cell: (row: any) => [
        <td className="city-action-rable-row ">
          <i
            className="fas fa-eye table-icons pe-1"
            onClick={() => {
              // console.log("hit ", BondTypes.IPO);
              // if (row.order_type === BondTypes.IPO) {
              if (bondSelectOption === 0) {
                navigate(PageLinks.IPO_ORDER_DETAIL_VIEW, {
                  state: {
                    type: 1,
                    // type: row.order_type,
                    id: row.order_id,
                    path: "customer_book",
                  },
                });
              } else if (bondSelectOption === 2) {
                // } else if (row.order_type === BondTypes.ADminBond) {
                navigate(PageLinks.BOND_ORDER_DETAIL_VIEW, {
                  state: {
                    type: 2,
                    id: row.order_id,
                    path: "customer_book",
                  },
                });
              } else if (bondSelectOption === 1) {
                // } else if (row.order_type === BondTypes.SGB) {
                navigate(PageLinks.SGB_ORDER_DETAIL_VIEW, {
                  state: {
                    type: 3,
                    // type: row.order_type,
                    id: row.order_id,
                    path: "customer_book",
                  },
                });
              } else if (bondSelectOption === 3) {
                if (row?.security?.security_type_id == 1) {
                  navigate(PageLinks.IPO_ORDER_DETAIL_VIEW, {
                    state: {
                      type: 1,
                      // type: row.order_type,
                      id: row.order_id,
                      path: "customer_book",
                    },
                  });
                } else if (row?.security?.security_type_id == 2) {
                  navigate(PageLinks.BOND_ORDER_DETAIL_VIEW, {
                    state: {
                      // type: row.order_type,
                      id: row.order_id,
                      path: "customer_book",
                    },
                  });
                } else if (row?.security?.security_type_id == 3) {
                  navigate(PageLinks.SGB_ORDER_DETAIL_VIEW, {
                    state: {
                      type: 3,
                      // type: row.order_type,
                      id: row.order_id,
                      path: "customer_book",
                    },
                  });
                }
              }
            }}
          ></i>
          {row.order_type != 4 ? (
            <img
              src="../../assets/images/download.svg"
              style={{ width: "15px", margin: "0px 5px", cursor: "pointer" }}
              onClick={() => getDownloadFile(row.order_pdf)}
            ></img>
          ) : (
            ""
          )}
        </td>,
      ],
    },
  ];

  const customStyles = {
    headCells: {
      style: {
        backgroundColor: "#A27B1E",
        color: "white",
        fontWeight: "bold",
      },
    },
  };
  const handlePageChange = (page: any) => {
    setCurrentPage(page);
    setPageNumber(page);
  };

  const [statusArray, setStatusArray] = useState([]);

  // const statusArray = [
  //   "Pending",
  //   "Accepted",
  //   "RFQ Accepted",
  //   "RFQ Rejected",
  //   "Rejected",
  //   "Settled",
  //   "Expired",
  // ];

  useEffect(() => {
    loadOrderList();
    ServerAPI.viewInsights()
      .then((res) => {
        try {
          setStatusArray(res.data[0]);
        } catch (error) {
          toast.error(res.error);
        }
      })
      .finally(() => {
        setShowPreloader(false);
      });
  }, [
    pageNumber,
    search_text,
    toData,
    fromDate,
    preData,
    open,
    bondSelectOption,
  ]);

  const loadOrderList = () => {
    setShowPreloader(true);
    ServerAPI.getCustomerOrderList(
      10,
      pageNumber,
      search_text,
      toData,
      fromDate,
      bondSelectOption
    )
      .then((res) => {
        try {
          setOrderList(res.data[0]?.orders);
          // console.log(
          //   "🚀 ~ file: CustomerOrderList.tsx:171 ~ .then ~ res:",
          //   res?.data?.[0]?.pagination
          // );
          setTotalCount(res?.data[0]?.pagination?.total_records);
        } catch (error) {
          toast.error(res.error);
        }
      })
      .finally(() => {
        setShowPreloader(false);
      });
  };

  const handleDownloadClick = async () => {
    const persistAuthString = await Utilities.getAuthString();
    try {
      const response = await fetch(
        `https://v2-trust-api.trustmony.com/api/v2/admin/orders/download`,
        {
          method: "GET",
          headers: {
            Authorization: persistAuthString,
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to fetch the file.");
      }

      // Retrieve Content-Disposition header to extract filename if provided
      const contentDisposition = response.headers.get("Content-Disposition");
      // console.log("Content-Disposition:", contentDisposition);

      let filename = "Sample.xlsx"; // Default filename
      if (contentDisposition) {
        const match = contentDisposition.match(/filename="(.+?)"/);
        if (match && match[1]) {
          filename = match[1];
        }
      }

      // Convert response to ArrayBuffer
      const arrayBuffer = await response.arrayBuffer();

      // Create a Blob from the ArrayBuffer
      const blob = new Blob([arrayBuffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = URL.createObjectURL(blob);

      // Trigger file download
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", filename); // Use the extracted or default filename
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {
      console.error("Error fetching the file:", error);
    } finally {
      // Cleanup or additional actions
    }
  };
  const resetfilter = () => {
    setFromDate(null);
    setToData(null);
  };
  const CustomInput = React.forwardRef((props: any, ref: any) => {
    return (
      <div className="datePickerStyle">
        <label onClick={props.onClick} ref={ref}>
          {props.value || props.placeholder}
        </label>
        <img
          src="../../assets/images/calender-icon.svg"
          onClick={props.onClick}
          ref={ref}
        />
      </div>
    );
  });

  return (
    <div>
      <div id="layout-wrapper">
        <Header />
        <div className="main-content py-4">
          <div className="page-content">
            <div className="container-fluid">
              <div className="row">
                <div className="col-12">
                  <div className="page-title-box d-sm-flex align-items-center justify-content-between pt-4 pb-0">
                    {/* <h4 className="mb-sm-0 font-size-18 "> Suspend Users</h4> */}
                    <div className="page-title-right">
                      <ol className="breadcrumb ">
                        <li className="breadcrumb-item">
                          <a style={{ color: "#90929E" }}>Users</a>
                        </li>
                        <li className="breadcrumb-item">
                          <a> Order Book </a>
                        </li>
                      </ol>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <div className="today-status-container">
                    <h4 className="today-status-heading">Today's Status</h4>
                    <hr></hr>
                    <div className="today-status-grid">
                      {statusArray &&
                        Object.entries(statusArray).map(([key, value], idx) => (
                          <div
                            key={key}
                            className={`status-item ${statusColors[idx]}`}
                          >
                            <span className="status-label">
                              {key
                                .replace(/_/g, " ")
                                .replace(/\b\w/g, (c) => c.toUpperCase())}
                            </span>
                            <span className="status-value">{value}</span>
                          </div>
                        ))}
                    </div>
                  </div>
                </div>
              </div>
              <Tabs
                selectedIndex={tabIndex}
                onSelect={(index) => {
                  setTabIndex(index);
                  setSearchText("");
                  setBondSelectOption(index);
                }}
                style={{ marginTop: "20px" }}
              >
                <TabList
                  className="Product_detail_tablist"
                  style={{ marginBottom: "0rem" }}
                >
                  <div className=" d-flex justify-content-between align-items-end">
                    <div>
                      <Tab className="product_detail_tab">IPO</Tab>
                      <Tab className="product_detail_tab">SGB</Tab>
                      <Tab className="product_detail_tab">BOND</Tab>
                      <Tab className="product_detail_tab">Pre Order</Tab>
                    </div>
                    <div>
                      <form className="app-search card-title p-2 m-0 w-100">
                        {/* <div
                          className="position-relative btn-suspend"
                          onClick={(e) => {
                            e.preventDefault();
                            handleDownloadClick();
                          }}
                        >
                          <a
                            className="text-white"
                            // onClick={() =>
                            //   navigate(PageLinks.USERS, { state: true })
                            // }
                          >
                            <i
                              className="fa fa-file-alt mx-2"
                              aria-hidden="true"
                            ></i>
                            Download
                          </a>
                        </div> */}
                      </form>
                    </div>
                  </div>
                </TabList>
                <TabPanel>
                  <div className="row">
                    <div className="col-12">
                      <div className="card">
                        <div className="card-body col-sm-12 col-md-12 col-lg-12 w-100">
                          {/* <div className="card-header col-xl-12 w-100"> */}
                          <div
                            className="row"
                            style={{ paddingBottom: "10px" }}
                          >
                            <div className="col-xl-4">
                              <div className="d-flex justify-content-start align-items-center">
                                <h4 className="card-title text-end p-2 m-0">
                                  Search :
                                </h4>
                                <form className="app-search card-title p-2 m-0">
                                  <div className="position-relative">
                                    <input
                                      type="text"
                                      className="select-style-search"
                                      onChange={(e) =>
                                        setSearchText(e.target.value)
                                      }
                                      // placeholder="Order Id, Order Date & Time..."
                                    />
                                  </div>
                                </form>
                              </div>
                            </div>

                            <div className="col-xl-8 text-end">
                              <div className="d-flex justify-content-end align-items-center">
                                {/* <form className="app-search card-title p-2 m-0 w-100">
                                  <div className="position-relative ">
                                    <select className="select-style" name="bond_type" onChange={(e) => setBondSelectOption(e.target.value)}>
                                      <option value="all">All</option>
                                      <option value="IPO">IPO</option>
                                      <option value="BOND">Bond</option>
                                      <option value="SGB">SGB</option>
                                    </select>
                                  </div>
                                </form> */}
                                <form className="app-search card-title p-2 m-0 w-100">
                                  <div className="position-relative">
                                    <DatePicker
                                      selected={fromDate}
                                      closeOnScroll={true}
                                      onChange={(date: Date) =>
                                        setFromDate(date)
                                      }
                                      placeholderText="DD/MM/YYYY"
                                      className="date-picker-style"
                                      peekNextMonth
                                      showMonthDropdown
                                      showYearDropdown
                                      dropdownMode="select"
                                      customInput={<CustomInput />}
                                    />
                                  </div>
                                </form>
                                <h4 className="card-title p-2 m-0">TO</h4>
                                <form className="app-search card-title p-2 m-0 w-100">
                                  <div className="position-relative">
                                    <DatePicker
                                      selected={toData}
                                      closeOnScroll={true}
                                      onChange={(date: Date) => setToData(date)}
                                      placeholderText="DD/MM/YYYY"
                                      className="date-picker-style"
                                      peekNextMonth
                                      showMonthDropdown
                                      showYearDropdown
                                      dropdownMode="select"
                                      customInput={<CustomInput />}
                                    />
                                  </div>
                                </form>
                                <button
                                  onClick={resetfilter}
                                  className=" btn-suspend text-white"
                                >
                                  Reset
                                </button>
                              </div>
                            </div>
                          </div>

                          <div className="table-rep-plugin">
                            <div
                              className="table-responsive "
                              data-pattern="priority-columns"
                            >
                              {/* <table id="tech-companies-1" className="table table table-bordered">
                            <thead className="city-table">
                              <tr>
                                <th>Sr.No</th>
                                <th>Order ID</th>
                                <th>Order Date & Time</th>
                                <th>Order Type</th>
                                <th>Customer Name</th>
                                <th>Bond / IPO Name</th>
                                <th>No. Of Quantity</th>
                                <th>Total Amount</th>
                                <th>Order Status</th>
                                <th colSpan={3} data-priority="3" className="">
                                  Action
                                </th>
                              </tr>
                            </thead>
                            {orderList != undefined && orderList.length > 0 ? (
                              <>
                                {orderList.map((res: APIData.CustomerOrderList, i: number) => (
                                  <tbody key={i}>
                                    <tr>
                                      <td> {i + 1} </td>
                                      <td>{getDisplayData(res.order_number)}</td> */}
                              {/* <td>{getFormattedLocalDateTime(res.order_datetime)}</td> */}
                              {/* <td>{res.order_datetime != null && moment(res.order_datetime).format("DD-MM-YYYY,  hh:mm A")}</td>
                                      <td>{getOrderTypeFilter(res.order_type)}</td>
                                      <td>{getDisplayData(res.customer_name)}</td>
                                      <td>{getDisplayData(res.bond_name)}</td>
                                      <td>{getDisplayData(res.order_quantity)}</td>
                                      <td>₹ {numberWithCommas(res.order_amount)}</td>
                                      <td>{getOrderStatus(res.order_status)}</td>
                                      <td className="city-action-rable-row "> */}
                              {/* <i
                                          className="fas fa-eye table-icons pe-1"
                                          // comment this section
                                          onClick={() => navigate(PageLinks.CUSTOMER_BOOK_DETAIL_VIEW, {
                                              state: { type: res.order_type, id: res.order_id }
                                          })}
                                          onClick={() => {
                                            if (res.order_type == BondTypes.IPO) {
                                              navigate(PageLinks.IPO_ORDER_DETAIL_VIEW, {
                                                state: { type: res.order_type, id: res.order_id, path: "customer_book" },
                                              });
                                            } else if (res.order_type == BondTypes.ADminBond) {
                                              navigate(PageLinks.BOND_ORDER_DETAIL_VIEW, {
                                                state: { type: res.order_type, id: res.order_id, path: "customer_book" },
                                              });
                                            } else if (res.order_type == BondTypes.SGB) {
                                              navigate(PageLinks.SGB_ORDER_DETAIL_VIEW, {
                                                state: { type: res.order_type, id: res.order_id, path: "customer_book" },
                                              });
                                            }
                                          }}
                                        ></i> */}
                              {/* {res.order_type != 4 ? <img src="../../assets/images/download.svg" style={{ width: "15px", margin: "0px 5px", cursor: "pointer" }} onClick={() => getDownloadFile(res.order_pdf)}></img> : ""}
                                      </td>
                                    </tr>
                                  </tbody>
                                ))}
                              </>
                            ) : (
                              <tbody>
                                <tr className="py-4">
                                  <td className="text-center py-5" colSpan={11}>
                                    No Data Found.
                                  </td>
                                </tr>
                              </tbody>
                            )}
                          </table> */}
                              <DataTable
                                data={orderList}
                                columns={columns}
                                noHeader
                                defaultSortFieldId={"customer_id"}
                                sortIcon={<i className="fas fa-sort"></i>}
                                defaultSortAsc={true}
                                highlightOnHover
                                dense
                                customStyles={customStyles}
                              />
                            </div>
                          </div>
                          <div className="row mt-3">
                            <div className="col-sm-12 col-md-5">
                              {totalCount > 10 ? (
                                <div
                                  className="dataTables_info"
                                  id="datatable_info"
                                  role="status"
                                  aria-live="polite"
                                >
                                  Showing {orderList?.length} of {totalCount}
                                  entries
                                </div>
                              ) : (
                                <div
                                  className="dataTables_info"
                                  id="datatable_info"
                                  role="status"
                                  aria-live="polite"
                                >
                                  Showing {totalCount === 0 ? 0 : totalCount}{" "}
                                  entries
                                </div>
                              )}
                            </div>
                            <div className="col-sm-12 col-md-7">
                              <div
                                className="dataTables_paginate paging_simple_numbers float-end mb-3"
                                id="datatable_paginate"
                              >
                                <Pagination
                                  count={Math.ceil(totalCount / 10)}
                                  onChange={(event: any, value: any) => {
                                    setPageNumber(Number(value));
                                    setCurrentPage(Number(value));
                                  }}
                                  variant="outlined"
                                  color="primary"
                                  shape="rounded"
                                  className="pagination-style"
                                />
                              </div>
                            </div>
                          </div>

                          {/* </div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </TabPanel>
                <TabPanel>
                  <div className="row">
                    <div className="col-12">
                      <div className="card">
                        <div className="card-body col-sm-12 col-md-12 col-lg-12 w-100">
                          {/* <div className="card-header col-xl-12 w-100"> */}
                          <div
                            className="row"
                            style={{ paddingBottom: "10px" }}
                          >
                            <div className="col-xl-4">
                              <div className="d-flex justify-content-start align-items-center">
                                <h4 className="card-title text-end p-2 m-0">
                                  Search :
                                </h4>
                                <form className="app-search card-title p-2 m-0">
                                  <div className="position-relative">
                                    <input
                                      type="text"
                                      className="select-style-search"
                                      onChange={(e) =>
                                        setSearchText(e.target.value)
                                      }
                                      // placeholder="Order Id, Order Date & Time..."
                                    />
                                  </div>
                                </form>
                              </div>
                            </div>

                            <div className="col-xl-8 text-end">
                              <div className="d-flex justify-content-end align-items-center">
                                {/* <form className="app-search card-title p-2 m-0 w-100">
                                  <div className="position-relative ">
                                    <select className="select-style" name="bond_type" onChange={(e) => setBondSelectOption(e.target.value)}>
                                      <option value="all">All</option>
                                      <option value="IPO">IPO</option>
                                      <option value="BOND">Bond</option>
                                      <option value="SGB">SGB</option>
                                    </select>
                                  </div>
                                </form> */}
                                <form className="app-search card-title p-2 m-0 w-100">
                                  <div className="position-relative">
                                    <DatePicker
                                      selected={fromDate}
                                      closeOnScroll={true}
                                      onChange={(date: Date) =>
                                        setFromDate(date)
                                      }
                                      placeholderText="DD/MM/YYYY"
                                      className="date-picker-style"
                                      peekNextMonth
                                      showMonthDropdown
                                      showYearDropdown
                                      dropdownMode="select"
                                      customInput={<CustomInput />}
                                    />
                                  </div>
                                </form>
                                <h4 className="card-title p-2 m-0">TO</h4>
                                <form className="app-search card-title p-2 m-0 w-100">
                                  <div className="position-relative">
                                    <DatePicker
                                      selected={toData}
                                      closeOnScroll={true}
                                      onChange={(date: Date) => setToData(date)}
                                      placeholderText="DD/MM/YYYY"
                                      className="date-picker-style"
                                      peekNextMonth
                                      showMonthDropdown
                                      showYearDropdown
                                      dropdownMode="select"
                                      customInput={<CustomInput />}
                                    />
                                  </div>
                                </form>

                                <button
                                  onClick={resetfilter}
                                  className=" btn-suspend text-white"
                                >
                                  Reset
                                </button>
                              </div>
                            </div>
                          </div>

                          <div className="table-rep-plugin">
                            <div
                              className="table-responsive "
                              data-pattern="priority-columns"
                            >
                              {/* <table id="tech-companies-1" className="table table table-bordered">
                            <thead className="city-table">
                              <tr>
                                <th>Sr.No</th>
                                <th>Order ID</th>
                                <th>Order Date & Time</th>
                                <th>Order Type</th>
                                <th>Customer Name</th>
                                <th>Bond / IPO Name</th>
                                <th>No. Of Quantity</th>
                                <th>Total Amount</th>
                                <th>Order Status</th>
                                <th colSpan={3} data-priority="3" className="">
                                  Action
                                </th>
                              </tr>
                            </thead>
                            {orderList != undefined && orderList.length > 0 ? (
                              <>
                                {orderList.map((res: APIData.CustomerOrderList, i: number) => (
                                  <tbody key={i}>
                                    <tr>
                                      <td> {i + 1} </td>
                                      <td>{getDisplayData(res.order_number)}</td> */}
                              {/* <td>{getFormattedLocalDateTime(res.order_datetime)}</td> */}
                              {/* <td>{res.order_datetime != null && moment(res.order_datetime).format("DD-MM-YYYY,  hh:mm A")}</td>
                                      <td>{getOrderTypeFilter(res.order_type)}</td>
                                      <td>{getDisplayData(res.customer_name)}</td>
                                      <td>{getDisplayData(res.bond_name)}</td>
                                      <td>{getDisplayData(res.order_quantity)}</td>
                                      <td>₹ {numberWithCommas(res.order_amount)}</td>
                                      <td>{getOrderStatus(res.order_status)}</td>
                                      <td className="city-action-rable-row "> */}
                              {/* <i
                                          className="fas fa-eye table-icons pe-1"
                                          // comment this section
                                          onClick={() => navigate(PageLinks.CUSTOMER_BOOK_DETAIL_VIEW, {
                                              state: { type: res.order_type, id: res.order_id }
                                          })}
                                          onClick={() => {
                                            if (res.order_type == BondTypes.IPO) {
                                              navigate(PageLinks.IPO_ORDER_DETAIL_VIEW, {
                                                state: { type: res.order_type, id: res.order_id, path: "customer_book" },
                                              });
                                            } else if (res.order_type == BondTypes.ADminBond) {
                                              navigate(PageLinks.BOND_ORDER_DETAIL_VIEW, {
                                                state: { type: res.order_type, id: res.order_id, path: "customer_book" },
                                              });
                                            } else if (res.order_type == BondTypes.SGB) {
                                              navigate(PageLinks.SGB_ORDER_DETAIL_VIEW, {
                                                state: { type: res.order_type, id: res.order_id, path: "customer_book" },
                                              });
                                            }
                                          }}
                                        ></i> */}
                              {/* {res.order_type != 4 ? <img src="../../assets/images/download.svg" style={{ width: "15px", margin: "0px 5px", cursor: "pointer" }} onClick={() => getDownloadFile(res.order_pdf)}></img> : ""}
                                      </td>
                                    </tr>
                                  </tbody>
                                ))}
                              </>
                            ) : (
                              <tbody>
                                <tr className="py-4">
                                  <td className="text-center py-5" colSpan={11}>
                                    No Data Found.
                                  </td>
                                </tr>
                              </tbody>
                            )}
                          </table> */}
                              <DataTable
                                data={orderList}
                                columns={columns}
                                noHeader
                                defaultSortFieldId={"customer_id"}
                                sortIcon={<i className="fas fa-sort"></i>}
                                defaultSortAsc={true}
                                highlightOnHover
                                dense
                                customStyles={customStyles}
                              />
                            </div>
                          </div>
                          <div className="row mt-3">
                            <div className="col-sm-12 col-md-5">
                              {totalCount > 10 ? (
                                <div
                                  className="dataTables_info"
                                  id="datatable_info"
                                  role="status"
                                  aria-live="polite"
                                >
                                  Showing {orderList.length} of {totalCount}{" "}
                                  entries
                                </div>
                              ) : (
                                <div
                                  className="dataTables_info"
                                  id="datatable_info"
                                  role="status"
                                  aria-live="polite"
                                >
                                  Showing {totalCount === 0 ? 0 : totalCount}{" "}
                                  entries
                                </div>
                              )}
                            </div>
                            <div className="col-sm-12 col-md-7">
                              <div
                                className="dataTables_paginate paging_simple_numbers float-end mb-3"
                                id="datatable_paginate"
                              >
                                <Pagination
                                  count={Math.ceil(totalCount / 10)}
                                  onChange={(event: any, value: any) => {
                                    setPageNumber(Number(value));
                                    setCurrentPage(Number(value));
                                  }}
                                  variant="outlined"
                                  color="primary"
                                  shape="rounded"
                                  className="pagination-style"
                                />
                              </div>
                            </div>
                          </div>

                          {/* </div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </TabPanel>
                <TabPanel>
                  <div className="row">
                    <div className="col-12">
                      <div className="card">
                        <div className="card-body col-sm-12 col-md-12 col-lg-12 w-100">
                          {/* <div className="card-header col-xl-12 w-100"> */}
                          <div
                            className="row"
                            style={{ paddingBottom: "10px" }}
                          >
                            <div className="col-xl-4">
                              <div className="d-flex justify-content-start align-items-center">
                                <h4 className="card-title text-end p-2 m-0">
                                  Search :
                                </h4>
                                <form className="app-search card-title p-2 m-0">
                                  <div className="position-relative">
                                    <input
                                      type="text"
                                      className="select-style-search"
                                      onChange={(e) =>
                                        setSearchText(e.target.value)
                                      }
                                      // placeholder="Order Id, Order Date & Time..."
                                    />
                                  </div>
                                </form>
                              </div>
                            </div>

                            <div className="col-xl-8 text-end">
                              <div className="d-flex justify-content-end align-items-center">
                                {/* <form className="app-search card-title p-2 m-0 w-100">
                                  <div className="position-relative ">
                                    <select className="select-style" name="bond_type" onChange={(e) => setBondSelectOption(e.target.value)}>
                                      <option value="all">All</option>
                                      <option value="IPO">IPO</option>
                                      <option value="BOND">Bond</option>
                                      <option value="SGB">SGB</option>
                                    </select>
                                  </div>
                                </form> */}
                                <form className="app-search card-title p-2 m-0 w-100">
                                  <div className="position-relative">
                                    <DatePicker
                                      selected={fromDate}
                                      closeOnScroll={true}
                                      onChange={(date: Date) =>
                                        setFromDate(date)
                                      }
                                      placeholderText="DD/MM/YYYY"
                                      className="date-picker-style"
                                      peekNextMonth
                                      showMonthDropdown
                                      showYearDropdown
                                      dropdownMode="select"
                                      customInput={<CustomInput />}
                                    />
                                  </div>
                                </form>
                                <h4 className="card-title p-2 m-0">TO</h4>
                                <form className="app-search card-title p-2 m-0 w-100">
                                  <div className="position-relative">
                                    <DatePicker
                                      selected={toData}
                                      closeOnScroll={true}
                                      onChange={(date: Date) => setToData(date)}
                                      placeholderText="DD/MM/YYYY"
                                      className="date-picker-style"
                                      peekNextMonth
                                      showMonthDropdown
                                      showYearDropdown
                                      dropdownMode="select"
                                      customInput={<CustomInput />}
                                    />
                                  </div>
                                </form>

                                <button
                                  onClick={resetfilter}
                                  className=" btn-suspend text-white"
                                >
                                  Reset
                                </button>
                              </div>
                            </div>
                          </div>

                          <div className="table-rep-plugin">
                            <div
                              className="table-responsive "
                              data-pattern="priority-columns"
                            >
                              {/* <table id="tech-companies-1" className="table table table-bordered">
                            <thead className="city-table">
                              <tr>
                                <th>Sr.No</th>
                                <th>Order ID</th>
                                <th>Order Date & Time</th>
                                <th>Order Type</th>
                                <th>Customer Name</th>
                                <th>Bond / IPO Name</th>
                                <th>No. Of Quantity</th>
                                <th>Total Amount</th>
                                <th>Order Status</th>
                                <th colSpan={3} data-priority="3" className="">
                                  Action
                                </th>
                              </tr>
                            </thead>
                            {orderList != undefined && orderList.length > 0 ? (
                              <>
                                {orderList.map((res: APIData.CustomerOrderList, i: number) => (
                                  <tbody key={i}>
                                    <tr>
                                      <td> {i + 1} </td>
                                      <td>{getDisplayData(res.order_number)}</td> */}
                              {/* <td>{getFormattedLocalDateTime(res.order_datetime)}</td> */}
                              {/* <td>{res.order_datetime != null && moment(res.order_datetime).format("DD-MM-YYYY,  hh:mm A")}</td>
                                      <td>{getOrderTypeFilter(res.order_type)}</td>
                                      <td>{getDisplayData(res.customer_name)}</td>
                                      <td>{getDisplayData(res.bond_name)}</td>
                                      <td>{getDisplayData(res.order_quantity)}</td>
                                      <td>₹ {numberWithCommas(res.order_amount)}</td>
                                      <td>{getOrderStatus(res.order_status)}</td>
                                      <td className="city-action-rable-row "> */}
                              {/* <i
                                          className="fas fa-eye table-icons pe-1"
                                          // comment this section
                                          onClick={() => navigate(PageLinks.CUSTOMER_BOOK_DETAIL_VIEW, {
                                              state: { type: res.order_type, id: res.order_id }
                                          })}
                                          onClick={() => {
                                            if (res.order_type == BondTypes.IPO) {
                                              navigate(PageLinks.IPO_ORDER_DETAIL_VIEW, {
                                                state: { type: res.order_type, id: res.order_id, path: "customer_book" },
                                              });
                                            } else if (res.order_type == BondTypes.ADminBond) {
                                              navigate(PageLinks.BOND_ORDER_DETAIL_VIEW, {
                                                state: { type: res.order_type, id: res.order_id, path: "customer_book" },
                                              });
                                            } else if (res.order_type == BondTypes.SGB) {
                                              navigate(PageLinks.SGB_ORDER_DETAIL_VIEW, {
                                                state: { type: res.order_type, id: res.order_id, path: "customer_book" },
                                              });
                                            }
                                          }}
                                        ></i> */}
                              {/* {res.order_type != 4 ? <img src="../../assets/images/download.svg" style={{ width: "15px", margin: "0px 5px", cursor: "pointer" }} onClick={() => getDownloadFile(res.order_pdf)}></img> : ""}
                                      </td>
                                    </tr>
                                  </tbody>
                                ))}
                              </>
                            ) : (
                              <tbody>
                                <tr className="py-4">
                                  <td className="text-center py-5" colSpan={11}>
                                    No Data Found.
                                  </td>
                                </tr>
                              </tbody>
                            )}
                          </table> */}
                              <DataTable
                                data={orderList}
                                columns={columns}
                                noHeader
                                defaultSortFieldId={"customer_id"}
                                sortIcon={<i className="fas fa-sort"></i>}
                                defaultSortAsc={true}
                                highlightOnHover
                                dense
                                customStyles={customStyles}
                              />
                            </div>
                          </div>
                          <div className="row mt-3">
                            <div className="col-sm-12 col-md-5">
                              {totalCount > 10 ? (
                                <div
                                  className="dataTables_info"
                                  id="datatable_info"
                                  role="status"
                                  aria-live="polite"
                                >
                                  Showing {orderList.length} of {totalCount}{" "}
                                  entries
                                </div>
                              ) : (
                                <div
                                  className="dataTables_info"
                                  id="datatable_info"
                                  role="status"
                                  aria-live="polite"
                                >
                                  Showing {totalCount === 0 ? 0 : totalCount}{" "}
                                  entries
                                </div>
                              )}
                            </div>
                            <div className="col-sm-12 col-md-7">
                              <div
                                className="dataTables_paginate paging_simple_numbers float-end mb-3"
                                id="datatable_paginate"
                              >
                                <Pagination
                                  count={Math.ceil(totalCount / 10)}
                                  onChange={(event: any, value: any) => {
                                    setPageNumber(Number(value));
                                    setCurrentPage(Number(value));
                                  }}
                                  variant="outlined"
                                  color="primary"
                                  shape="rounded"
                                  className="pagination-style"
                                />
                              </div>
                            </div>
                          </div>

                          {/* </div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </TabPanel>
                <TabPanel>
                  <div className="row">
                    <div className="col-12">
                      <div className="card">
                        <div className="card-body col-sm-12 col-md-12 col-lg-12 w-100">
                          {/* <div className="card-header col-xl-12 w-100"> */}
                          <div
                            className="row"
                            style={{ paddingBottom: "10px" }}
                          >
                            <div className="col-xl-4">
                              <div className="d-flex justify-content-start align-items-center">
                                <h4 className="card-title text-end p-2 m-0">
                                  Search :
                                </h4>
                                <form className="app-search card-title p-2 m-0">
                                  <div className="position-relative">
                                    <input
                                      type="text"
                                      className="select-style-search"
                                      onChange={(e) =>
                                        setSearchText(e.target.value)
                                      }
                                      // placeholder="Order Id, Order Date & Time..."
                                    />
                                  </div>
                                </form>
                              </div>
                            </div>

                            <div className="col-xl-8 text-end">
                              <div className="d-flex justify-content-end align-items-center">
                                {/* <form className="app-search card-title p-2 m-0 w-100">
                                  <div className="position-relative ">
                                    <select className="select-style" name="bond_type" onChange={(e) => setBondSelectOption(e.target.value)}>
                                      <option value="all">All</option>
                                      <option value="IPO">IPO</option>
                                      <option value="BOND">Bond</option>
                                      <option value="SGB">SGB</option>
                                    </select>
                                  </div>
                                </form> */}
                                <form className="app-search card-title p-2 m-0 w-100">
                                  <div className="position-relative">
                                    <DatePicker
                                      selected={fromDate}
                                      closeOnScroll={true}
                                      onChange={(date: Date) =>
                                        setFromDate(date)
                                      }
                                      placeholderText="DD/MM/YYYY"
                                      className="date-picker-style"
                                      peekNextMonth
                                      showMonthDropdown
                                      showYearDropdown
                                      dropdownMode="select"
                                      customInput={<CustomInput />}
                                    />
                                  </div>
                                </form>
                                <h4 className="card-title p-2 m-0">TO</h4>
                                <form className="app-search card-title p-2 m-0 w-100">
                                  <div className="position-relative">
                                    <DatePicker
                                      selected={toData}
                                      closeOnScroll={true}
                                      onChange={(date: Date) => setToData(date)}
                                      placeholderText="DD/MM/YYYY"
                                      className="date-picker-style"
                                      peekNextMonth
                                      showMonthDropdown
                                      showYearDropdown
                                      dropdownMode="select"
                                      customInput={<CustomInput />}
                                    />
                                  </div>
                                </form>

                                <button
                                  onClick={resetfilter}
                                  className=" btn-suspend text-white"
                                >
                                  Reset
                                </button>
                              </div>
                            </div>
                          </div>

                          <div className="table-rep-plugin">
                            <div
                              className="table-responsive "
                              data-pattern="priority-columns"
                            >
                              {/* <table id="tech-companies-1" className="table table table-bordered">
                            <thead className="city-table">
                              <tr>
                                <th>Sr.No</th>
                                <th>Order ID</th>
                                <th>Order Date & Time</th>
                                <th>Order Type</th>
                                <th>Customer Name</th>
                                <th>Bond / IPO Name</th>
                                <th>No. Of Quantity</th>
                                <th>Total Amount</th>
                                <th>Order Status</th>
                                <th colSpan={3} data-priority="3" className="">
                                  Action
                                </th>
                              </tr>
                            </thead>
                            {orderList != undefined && orderList.length > 0 ? (
                              <>
                                {orderList.map((res: APIData.CustomerOrderList, i: number) => (
                                  <tbody key={i}>
                                    <tr>
                                      <td> {i + 1} </td>
                                      <td>{getDisplayData(res.order_number)}</td> */}
                              {/* <td>{getFormattedLocalDateTime(res.order_datetime)}</td> */}
                              {/* <td>{res.order_datetime != null && moment(res.order_datetime).format("DD-MM-YYYY,  hh:mm A")}</td>
                                      <td>{getOrderTypeFilter(res.order_type)}</td>
                                      <td>{getDisplayData(res.customer_name)}</td>
                                      <td>{getDisplayData(res.bond_name)}</td>
                                      <td>{getDisplayData(res.order_quantity)}</td>
                                      <td>₹ {numberWithCommas(res.order_amount)}</td>
                                      <td>{getOrderStatus(res.order_status)}</td>
                                      <td className="city-action-rable-row "> */}
                              {/* <i
                                          className="fas fa-eye table-icons pe-1"
                                          // comment this section
                                          onClick={() => navigate(PageLinks.CUSTOMER_BOOK_DETAIL_VIEW, {
                                              state: { type: res.order_type, id: res.order_id }
                                          })}
                                          onClick={() => {
                                            if (res.order_type == BondTypes.IPO) {
                                              navigate(PageLinks.IPO_ORDER_DETAIL_VIEW, {
                                                state: { type: res.order_type, id: res.order_id, path: "customer_book" },
                                              });
                                            } else if (res.order_type == BondTypes.ADminBond) {
                                              navigate(PageLinks.BOND_ORDER_DETAIL_VIEW, {
                                                state: { type: res.order_type, id: res.order_id, path: "customer_book" },
                                              });
                                            } else if (res.order_type == BondTypes.SGB) {
                                              navigate(PageLinks.SGB_ORDER_DETAIL_VIEW, {
                                                state: { type: res.order_type, id: res.order_id, path: "customer_book" },
                                              });
                                            }
                                          }}
                                        ></i> */}
                              {/* {res.order_type != 4 ? <img src="../../assets/images/download.svg" style={{ width: "15px", margin: "0px 5px", cursor: "pointer" }} onClick={() => getDownloadFile(res.order_pdf)}></img> : ""}
                                      </td>
                                    </tr>
                                  </tbody>
                                ))}
                              </>
                            ) : (
                              <tbody>
                                <tr className="py-4">
                                  <td className="text-center py-5" colSpan={11}>
                                    No Data Found.
                                  </td>
                                </tr>
                              </tbody>
                            )}
                          </table> */}
                              <DataTable
                                data={orderList}
                                columns={columns}
                                noHeader
                                defaultSortFieldId={"customer_id"}
                                sortIcon={<i className="fas fa-sort"></i>}
                                defaultSortAsc={true}
                                highlightOnHover
                                dense
                                customStyles={customStyles}
                              />
                            </div>
                          </div>
                          <div className="row mt-3">
                            <div className="col-sm-12 col-md-5">
                              {totalCount > 10 ? (
                                <div
                                  className="dataTables_info"
                                  id="datatable_info"
                                  role="status"
                                  aria-live="polite"
                                >
                                  Showing {orderList.length} of {totalCount}{" "}
                                  entries
                                </div>
                              ) : (
                                <div
                                  className="dataTables_info"
                                  id="datatable_info"
                                  role="status"
                                  aria-live="polite"
                                >
                                  Showing {totalCount === 0 ? 0 : totalCount}{" "}
                                  entries
                                </div>
                              )}
                            </div>
                            <div className="col-sm-12 col-md-7">
                              <div
                                className="dataTables_paginate paging_simple_numbers float-end mb-3"
                                id="datatable_paginate"
                              >
                                <Pagination
                                  count={Math.ceil(totalCount / 10)}
                                  onChange={(event: any, value: any) => {
                                    setPageNumber(Number(value));
                                    setCurrentPage(Number(value));
                                  }}
                                  variant="outlined"
                                  color="primary"
                                  shape="rounded"
                                  className="pagination-style"
                                />
                              </div>
                            </div>
                          </div>

                          {/* </div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </TabPanel>
              </Tabs>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default CustomerOrderList;
