import moment from "moment";
import { type } from "os";
import { Fragment, useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useAppStateAPI } from "../common/AppStateAPI";
import {
  Annual_income_status,
  Depository,
  Experience,
  Gender,
  GenderString,
  kraStatus,
  MarriedStatus,
  PageLinks,
  PersonVideVerifyStatus,
  Status,
  Yes_or_No,
  AccountStatus,
} from "../common/Constants";
import {
  APIData,
  FormDataTypes,
  FormDefaultConstants,
} from "../common/DataTypes";
import Footer from "../common/Footer";
import Header from "../common/Header";
import { InitialData } from "../common/InitialValue";
import { ServerAPI } from "../common/ServerAPI";
import {
  getDisplayData,
  getDisplaySelectOption,
  getDownloadFile,
  getDownloadURLExtension,
  getFormattedLocalDate,
  getFormattedLocalDateTime,
  getURLExtension,
  numberWithCommas,
} from "../common/Utilities";
import AccountStatusModal from "../modals/AccountStatusModal";
import ImagePreviewModal from "../modals/ImagePreviewModal";

const IPODetails = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const customerId = location.state;
  // const [customerId,setCustomerid] = useState(customerId)
  const [userId, setUserId] = useState();
  const [personalDetails, setPersonlaDetails] =
    useState<APIData.personalDetail>();
  const [FACTADetails, setFACTADetails] = useState<APIData.FactaDetails[]>([]);
  const [bankDetails, setBankDetails] = useState<APIData.BankDetailsDatas[]>(
    []
  );
  const [Cvl_kraAddresDetails, setCvl_kraAddressDetails] =
    useState<APIData.AddressDatas_cvl_kra>();
  const [digilocker_aAddresDetails, setDigilockerAddressDetails] =
    useState<APIData.Digilocker_address_data>();
  const [existing_demat_details, setExistingDematDetails] = useState<
    APIData.ExistingDematAccountDetails[]
  >([]);
  const [new_demat_details, setNewDematDetails] = useState<
    APIData.NewDematAccountDetails[]
  >([]);
  const [suspended_data, setSuspendedData] = useState({});
  const [account_status_detail, setAccount_status_detail] =
    useState<APIData.AccountStatusDetails>();
  const [nominee_details, setNomineeDetails] =
    useState<APIData.NomineeDetails>();
  const [open, setOpen] = useState(false);
  const [documentDetails, setDocumentDetails] = useState<any>({});
  const [documentProofDetails, setDocumentProofDetails] =
    useState<APIData.personalDetail>();
  const [rows, setRows] = useState<any[]>([]);
  const { setShowPreloader } = useAppStateAPI();
  const [previewOpen, setPreviewOpen] = useState(false);
  const [IPODetails, setIPODetails] = useState<APIData.IPODetailView>(
    InitialData.IPODetailView
  );
  const [categoryList, setCategoryList] = useState<any[]>([]);

  useEffect(() => {
    loadIPODetail(customerId);
    loadIntitalIPOBrokerage(customerId);
    ServerAPI.ipoCategoryListForIPOAdd("", customerId)
      .then((res) => {
        try {
          setCategoryList(res.data[0].categories);
          // setTotalCount(res.data[0].pagination.total_records);
        } catch (error) {
          // toast.error(res.error);
        }
      })
      .finally(() => {
        setShowPreloader(false);
      });
  }, [customerId]);

  const loadIPODetail = (customerId: string) => {
    if (customerId !== "") {
      setShowPreloader(true);
      ServerAPI.IPODetail(customerId)
        .then((res) => {
          const transformedData = transformResponseToIPODetailView(res.data[0]); // Assuming `data` is an array
          setIPODetails(transformedData);
        })
        .finally(() => {
          setShowPreloader(false);
        });
    }
  };
  const loadIntitalIPOBrokerage = (security_id: any) => {
    ServerAPI.ipBrokerageDetails("", security_id)
      .then((res) => {
        try {
          const transformedRows = res.data.map((item: any) => ({
            security_category_id: item.security_category_id,
            category_id: item.category.category_id,
            security_id: item.security_id,
            series: item.security_category_series.map((seriesItem: any) => ({
              security_series_id: seriesItem.security_series_id,
              security_category_series_value:
                seriesItem.security_category_series_value,
            })),
          }));

          setRows(transformedRows);
        } catch (error) {
          // toast.error(res.error);
        }
      })
      .finally(() => {
        setShowPreloader(false);
      });
  };
  const toRoman = (num: number): string => {
    const romanNumerals = [
      "I",
      "II",
      "III",
      "IV",
      "V",
      "VI",
      "VII",
      "VIII",
      "IX",
      "X",
      "XI",
      "XII",
      "XIII",
      "XIV",
      "XV",
      "XVI",
      "XVII",
      "XVIII",
      "XIX",
      "XX",
    ];
    return romanNumerals[num] || num.toString(); // Fallback for larger numbers
  };
  const transformResponseToIPODetailView = (
    data: any
  ): APIData.IPODetailView => {
    return {
      bond_rating_agency: data?.rating[0]?.security_rating_agency,
      bond_rating: data?.rating[0]?.security_rating_name,
      series: data.series || null,
      issuer: data.issuer || "",
      security_types: data.security_types || "",
      security_rating_agency: data.security_rating_agency || "",
      security_rating: data.security_rating || "",
      bond_id: data.security_id || 0,
      bond_isin_number: data.security_isin || "",
      bond_issuer_name: data.issuer?.security_issuer_name || "",
      bond_asba_details:
        data.security_asba_start + " - " + data.security_asba_end || "",
      bond_name: data.security_name || "",
      bond_nature_of_instrument: data.security_type?.security_type_id || 0,
      bond_security_type: data.security_type_id || 0,
      bond_st: data.security_staggered_status ? 1 : 0,
      bond_security_code: data.security_script_id || "",
      bond_guaranted_by: data.security_Guarantee
        ? "Guaranteed"
        : "Not Guaranteed",
      bond_face_value: data.security_face_value || "",
      bond_issue_price: data.security_issue_price || "",
      bond_price_per_bond: data.security_series_price || "",
      bond_issue_date: data.security_issue_date || "",
      bond_ip_date: data.security_first_interest_date || "",
      bond_interest_frequency: data.security_interest_frequency || "",
      bond_interest_type: data.security_interest_payment_frequency_id || 0,
      bond_coupon_on: data.security_coupon_rate || "",
      bond_coupon_rate: data.security_series_coupon_rate || "",
      bond_interest_days: data.security_sell_windows || 0,
      bond_closer_date: data.security_close_at || "",
      bond_dmat_book_closer_date: data.security_book_closure_date || "",
      bond_rbi_loan_code: data.security_rbi_notice || "",
      bond_calc_int_on_holidays: data.security_norm_compound_flag ? 1 : 0,
      bond_int_on_maturity: data.security_int_app_money ? 1 : 0,
      bond_exchange: data.exchange || "",
      bond_listing: data.listing ? 1 : 0,
      bond_minimum_application: data.security_minimum_application || "",
      bond_issue_size: data.security_issue_price || "",
      bond_logo: data.security_logo || "",
      bond_type: data.security_type_id || 0,
      bond_maturity_date: data.security_maturity_date || "",
      bond_maturity_amount: data.security_series_redemption_amount || "",
      bond_coupon_date: data.security_coupon_date || "",
      bond_coupon_amount: data.security_series_coupon_rate || "",
      bond_key_highlights: data.security_highlight || "",
      bond_disclaimer: data.security_disclaimer || "",
      bond_term_condition_link: data.security_term_condition || "",
      bond_category_institutional: "",
      bond_category_non_institutional: "",
      bond_category_hni: "",
      bond_category_retail: "",
      bond_about_this_ipo: data.security_about || "",
      bond_ncd_status: data.security_ncd_available ? 1 : 0,
      bond_brokerage_details_status: data.security_brokerage_structure ? 1 : 0,
      bond_issue_status: data.security_issue_status || 0,
      bond_effort_basis: data.security_effort_basis || 0,
      bond_registrar_name: data.registrar_id || 0,
      bond_trustee_name: data.trustee_id || 0,
      bond_our_status: 0,
      bond_int_on_app_money: data.security_int_app_money || "",
      bond_int_on_refund_money: data.security_int_refund_money || "",
      bond_early_bid_incentive: data.security_early_bird_incentive || "",
      bond_other_incentive: data.security_other_incentive || "",
      bond_e_form_incentive: data.security_form_incentive || "",
      bond_opening_date: data.security_open_at || "",
      bond_closing_date: data.security_close_at || "",
      bond_allotment_date: data.security_allot_at || "",
      bond_deposit: "",
      bond_green_shoe: data.security_green_shoe ? 1 : 0,
      bond_green_shoe_size: data.security_green_shoe_size || "",
      bond_final_issue_amount: data.security_final_issue_amount || "",
      bond_procurement_amount: data.security_procurement_amount || "",
      bond_gst: parseFloat(data.security_gst || "0"),
      bond_script_id: data.security_script_id || "",
      bond_arranger_name: data.security_arranger_name || "",
      bond_upi_app_no_series_1: data.security_upi_start || "",
      bond_upi_app_no_series_2: data.security_upi_end || "",
      bond_asba_app_no_series_1: data.security_asba_start || "",
      bond_asba_app_no_series_2: data.security_asba_end || "",
      bond_ipo_sell_windows_days: data.security_sell_windows || 0,
      bond_category_instructions: "",
      bond_sub_category_code: "",
      bond_app_no_series: "",
      bond_series_instructions: "",
      bond_product_note: "",
      bond_listing_circle: "",
      bond_brokerage_structure: data.security_brokerage_structure || "",
      bonds_yeild: data.security_yield || "",
      bond_depository: data.depository_id || 0,

      bond_call_details: data.callDetails || [],
      bond_put_details: data.putDetails || [],
      bond_rating_details: data.rating || [],
      bond_issuer_details: [],
      bond_brand_tags: [],
      bond_eligible_investors: [],
      bond_ncd_available: [],
      bond_ncd_series: data.series || [],
      bond_brokerage_details: [],
    };
  };

  // const back_page = () => {
  //     navigate(PageLinks.USERS)
  // }
  const handleClickOpen = () => {};

  const handleClose = (e: any) => {
    setOpen(e);
    // user_details_datas()
    // setUserId(customerId);
  };

  const handlePreviewClose = (e: any) => {
    setOpen(e);
  };

  const status_update = (e: any) => {
    setOpen(true);
    setUserId(e);
  };

  return (
    <div>
      <div id="layout-wrapper">
        <Header />
        <div className="main-content">
          <div className="page-content">
            <div className="container-fluid">
              <div className="row">
                <div className="col-12">
                  <div className="page-title-box d-sm-flex align-items-center justify-content-between mt-4 pt-4">
                    <div className="page-title-rightds">
                      <ol className="breadcrumb ">
                        {/* <li className="breadcrumb-item"><a style={{ color: "#90929E" }}>Trustmony</a></li> */}
                        <li className="breadcrumb-item ">Bonds</li>
                        <li className="breadcrumb-item active">
                          <b>IPO Detail View</b>
                        </li>
                      </ol>
                    </div>
                    <div
                      className="cursor-pointer"
                      onClick={() => navigate(-1)}
                    >
                      <p className="mb-0 active">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="14"
                          height="18"
                          viewBox="0 0 51.303 50.003"
                        >
                          <path
                            id="Path_22590"
                            fill="#A27B1E"
                            data-name="Path 22590"
                            d="M-1748.422,907.993a2.738,2.738,0,0,1-3.882,0l-22.259-22.248a2.736,2.736,0,0,1,0-3.882L-1752.3,859.6a2.738,2.738,0,0,1,3.882,0l2.542,2.542a2.751,2.751,0,0,1-.046,3.928l-13.8,13.145h32.908a2.741,2.741,0,0,1,2.748,2.748v3.664a2.742,2.742,0,0,1-2.748,2.748h-32.908l13.8,13.144a2.732,2.732,0,0,1,.046,3.928Z"
                            transform="translate(1775.371 -858.797)"
                          />
                        </svg>
                        <span> Back </span>
                      </p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <div className="card">
                        <div className="card-header col-xl-12 w-100">
                          <div className="d-flex justify-content-between align-items-center">
                            <h5 className="my-3 header">IPO Bond Details </h5>
                            <div className="">
                              <a
                                className="cancel-btn_1 border-light quicksand-medium px-2 mx-3 py-2"
                                onClick={() =>
                                  navigate(PageLinks.IPO_ADD, {
                                    state: { id: customerId, mode: "edit" },
                                  })
                                }
                              >
                                <img
                                  src="../../assets/images/edit.svg"
                                  style={{ marginBottom: "4px", width: "16px" }}
                                />{" "}
                                Edit
                              </a>
                            </div>
                          </div>

                          <div
                            className="card"
                            style={{
                              borderTopLeftRadius: "10px",
                              borderTopRightRadius: "10px",
                              paddingTop: "10px",
                            }}
                          >
                            {/* <div className="card-header">
                                                            <h5 className="m-0 header"> Personal Details </h5>
                                                        </div> */}
                            <div className="card-body">
                              <div className="row">
                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                  <p className="detail-title">Issuer Name</p>
                                  <h6>
                                    {getDisplayData(
                                      IPODetails?.bond_issuer_name
                                    )}
                                  </h6>
                                </div>
                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                  <p className="detail-title">Bond Name</p>
                                  <h6>
                                    {getDisplayData(IPODetails?.bond_name)}
                                  </h6>
                                </div>
                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                  <p className="detail-title">
                                    Nature of Instrument
                                  </p>
                                  <h6>
                                    {getDisplaySelectOption(
                                      IPODetails?.bond_nature_of_instrument,
                                      "bond_nature_of_instrument"
                                    )}
                                  </h6>
                                </div>
                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                  <p className="detail-title">ISIN Number</p>
                                  <h6>
                                    {getDisplayData(
                                      IPODetails?.bond_isin_number
                                    )}
                                  </h6>
                                </div>

                                {/* <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                  <p className="detail-title">Our Status</p>
                                  <h6>
                                    {getDisplaySelectOption(
                                      IPODetails?.bond_our_status,
                                      "bond_our_status"
                                    )}
                                  </h6>
                                </div> */}
                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                  <p className="detail-title">Listing</p>
                                  <h6>BSE</h6>
                                </div>
                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                  <p className="detail-title">Opening Date</p>
                                  <h6>
                                    {getFormattedLocalDate(
                                      IPODetails?.bond_opening_date
                                    )}
                                  </h6>
                                </div>
                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                  <p className="detail-title">Closing Date</p>
                                  <h6>
                                    {getFormattedLocalDate(
                                      IPODetails?.bond_closing_date
                                    )}
                                  </h6>
                                </div>
                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                  <p className="detail-title">Yeild (%)</p>
                                  <h6>
                                    {getDisplayData(IPODetails?.bonds_yeild)}
                                  </h6>
                                </div>
                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                  <p className="detail-title">Face Value (₹)</p>
                                  <h6>
                                    {getDisplayData(
                                      IPODetails?.bond_face_value
                                    )}
                                  </h6>
                                </div>
                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                  <p className="detail-title">Depository</p>
                                  <h6>NSDL</h6>
                                </div>
                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                  <p className="detail-title">Script ID</p>
                                  <h6>
                                    {getDisplayData(IPODetails?.bond_script_id)}
                                  </h6>
                                </div>

                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                  <p className="detail-title">Return upto</p>
                                  <h6>
                                    {getDisplayData(IPODetails?.bond_coupon_on)}
                                  </h6>
                                </div>
                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                  <p className="detail-title">
                                    Minimum Application
                                  </p>
                                  <h6>
                                    {getDisplayData(
                                      IPODetails?.bond_minimum_application
                                    )}
                                  </h6>
                                </div>
                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                  <p className="detail-title">Issue Price</p>
                                  <h6>
                                    {getDisplayData(
                                      IPODetails?.bond_issue_size
                                    )}
                                  </h6>
                                </div>
                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                  <p className="detail-title">Bond Logo</p>
                                  <h6
                                    style={{
                                      textDecoration: "underline",
                                      cursor: "pointer",
                                    }}
                                    onClick={() =>
                                      getDownloadFile(IPODetails?.bond_logo)
                                    }
                                  >
                                    {getDownloadURLExtension(
                                      IPODetails?.bond_logo
                                    )}
                                  </h6>
                                </div>

                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                  <p className="detail-title">Security Type</p>
                                  <h6>
                                    {getDisplaySelectOption(
                                      IPODetails?.bond_security_type,
                                      "bond_security_type"
                                    )}
                                  </h6>
                                </div>
                                {/* <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                  <p className="detail-title">Issue Status</p>
                                  <h6>
                                    {getDisplaySelectOption(
                                      IPODetails?.bond_issue_status,
                                      "bond_issue_status"
                                    )}
                                  </h6>
                                </div> */}

                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                  <p className="detail-title">Effort Basis</p>
                                  <h6>
                                    {getDisplaySelectOption(
                                      IPODetails?.bond_effort_basis,
                                      "bond_effort_basis"
                                    )}
                                  </h6>
                                </div>
                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                  <p className="detail-title">Registrar</p>
                                  <h6>
                                    {getDisplaySelectOption(
                                      IPODetails?.bond_registrar_name,
                                      "bond_registrar_name"
                                    )}
                                  </h6>
                                </div>
                                {/* <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                  <p className="detail-title">Trustee</p>
                                  <h6>
                                    {getDisplayData(
                                      IPODetails?.bond_trustee_name
                                    )}
                                  </h6>
                                </div> */}

                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                  <p className="detail-title">
                                    Intrest on App Money
                                  </p>
                                  <h6>
                                    {getDisplayData(
                                      IPODetails?.bond_int_on_app_money
                                    )}
                                  </h6>
                                </div>
                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                  <p className="detail-title">
                                    Intrest on Refund Money
                                  </p>
                                  <h6>
                                    {getDisplayData(
                                      IPODetails?.bond_int_on_refund_money
                                    )}
                                  </h6>
                                </div>
                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                  <p className="detail-title">
                                    Early Bird Incentive
                                  </p>
                                  <h6>
                                    {getDisplayData(
                                      IPODetails?.bond_early_bid_incentive
                                    )}
                                  </h6>
                                </div>
                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                  <p className="detail-title">
                                    Other Incentive
                                  </p>
                                  <h6>
                                    {getDisplayData(
                                      IPODetails?.bond_other_incentive
                                    )}
                                  </h6>
                                </div>
                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                  <p className="detail-title">
                                    E-Form Incentive
                                  </p>
                                  <h6>
                                    {getDisplayData(
                                      IPODetails?.bond_e_form_incentive
                                    )}
                                  </h6>
                                </div>

                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                  <p className="detail-title">Allotment Date</p>
                                  <h6>
                                    {getFormattedLocalDateTime(
                                      IPODetails?.bond_allotment_date
                                    )}
                                  </h6>
                                </div>

                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                  <p className="detail-title">Green Shoe</p>
                                  <h6>
                                    {getDisplaySelectOption(
                                      IPODetails?.bond_green_shoe,
                                      "bond_green_shoe"
                                    )}
                                  </h6>
                                </div>
                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                  <p className="detail-title">
                                    Green Shoe Size
                                  </p>
                                  <h6>
                                    {getDisplayData(
                                      IPODetails?.bond_green_shoe_size
                                    )}
                                  </h6>
                                </div>
                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                  <p className="detail-title">
                                    Final Issue Amount (₹)
                                  </p>
                                  <h6>
                                    {getDisplayData(
                                      IPODetails?.bond_final_issue_amount
                                    )}
                                  </h6>
                                </div>
                                {/* <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                  <p className="detail-title">
                                    Procurement Amount (₹)
                                  </p>
                                  <h6>
                                    {getDisplayData(
                                      IPODetails?.bond_procurement_amount
                                    )}
                                  </h6>
                                </div> */}
                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                  <p className="detail-title">GST</p>
                                  <h6>
                                    {getDisplaySelectOption(
                                      IPODetails?.bond_gst,
                                      "bond_gst"
                                    )}
                                    %
                                  </h6>
                                </div>

                                {/* <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                  <p className="detail-title">
                                    Arranger Name (if any)
                                  </p>
                                  <h6>
                                    {getDisplayData(
                                      IPODetails?.bond_arranger_name
                                    )}
                                  </h6>
                                </div> */}
                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                  <p className="detail-title">
                                    UPI App No Series (For Trust)
                                  </p>
                                  <h6>
                                    {`${IPODetails?.bond_upi_app_no_series_1}  -  ${IPODetails?.bond_upi_app_no_series_2}`}
                                  </h6>
                                </div>
                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                  <p className="detail-title">
                                    ASBA App No Series (For Trust)
                                  </p>
                                  <h6>
                                    {`${IPODetails?.bond_asba_app_no_series_1}  -  ${IPODetails?.bond_asba_app_no_series_2}`}
                                  </h6>
                                </div>

                                {/* <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                  <p className="detail-title">
                                    Category Instruction
                                  </p>
                                  <h6
                                    style={{
                                      textDecoration: "underline",
                                      cursor: "pointer",
                                    }}
                                    onClick={() =>
                                      getDownloadFile(
                                        IPODetails?.bond_category_instructions
                                      )
                                    }
                                  >
                                    {getDownloadURLExtension(
                                      IPODetails?.bond_category_instructions
                                    )}
                                  </h6>
                                </div> */}

                                {/* <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                  <p className="detail-title">
                                    Sub Category Code
                                  </p>
                                  <h6
                                    style={{
                                      textDecoration: "underline",
                                      cursor: "pointer",
                                    }}
                                    onClick={() =>
                                      getDownloadFile(
                                        IPODetails?.bond_sub_category_code
                                      )
                                    }
                                  >
                                    {getDownloadURLExtension(
                                      IPODetails?.bond_sub_category_code
                                    )}
                                  </h6>
                                </div> */}
                                {/* <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                  <p className="detail-title">
                                    App No Series (Distributor)
                                  </p>
                                  <h6
                                    style={{
                                      textDecoration: "underline",
                                      cursor: "pointer",
                                    }}
                                    onClick={() =>
                                      getDownloadFile(
                                        IPODetails?.bond_app_no_series
                                      )
                                    }
                                  >
                                    {getDownloadURLExtension(
                                      IPODetails?.bond_app_no_series
                                    )}
                                  </h6>
                                </div> */}
                                {/* <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                  <p className="detail-title">
                                    Series Instruction
                                  </p>
                                  <h6
                                    style={{
                                      textDecoration: "underline",
                                      cursor: "pointer",
                                    }}
                                    onClick={() =>
                                      getDownloadFile(
                                        IPODetails?.bond_series_instructions
                                      )
                                    }
                                  >
                                    {getDownloadURLExtension(
                                      IPODetails?.bond_series_instructions
                                    )}
                                  </h6>
                                </div> */}
                                {/* <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                  <p className="detail-title">Product Note</p>
                                  <h6
                                    style={{
                                      textDecoration: "underline",
                                      cursor: "pointer",
                                    }}
                                    onClick={() =>
                                      getDownloadFile(
                                        IPODetails?.bond_product_note
                                      )
                                    }
                                  >
                                    {getDownloadURLExtension(
                                      IPODetails?.bond_product_note
                                    )}
                                  </h6>
                                </div> */}
                                {/* <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                  <p className="detail-title">Listing Circle</p>
                                  <h6
                                    style={{
                                      textDecoration: "underline",
                                      cursor: "pointer",
                                    }}
                                    onClick={() =>
                                      getDownloadFile(
                                        IPODetails?.bond_listing_circle
                                      )
                                    }
                                  >
                                    {getDownloadURLExtension(
                                      IPODetails?.bond_listing_circle
                                    )}
                                  </h6>
                                </div> */}
                              </div>
                            </div>
                          </div>
                          {/* <br /> */}

                          {/* <div className="card">
                            <div className="card-header">
                              <h5 className="m-0 header">Category Details</h5>
                            </div>
                            <div className="card-body">
                              <div className="row">
                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                  <p className="detail-title">
                                    I - Institutional
                                  </p>
                                  <h6>
                                    {getDisplayData(
                                      IPODetails?.bond_category_institutional
                                    )}
                                    %
                                  </h6>
                                </div>
                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                  <p className="detail-title">
                                    II - Non-Institutiona1
                                  </p>
                                  <h6>
                                    {getDisplayData(
                                      IPODetails?.bond_category_non_institutional
                                    )}
                                    %
                                  </h6>
                                </div>
                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                  <p className="detail-title">III - HNI</p>
                                  <h6>
                                    {getDisplayData(
                                      IPODetails?.bond_category_hni
                                    )}
                                    %
                                  </h6>
                                </div>
                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                  <p className="detail-title">IV - Retail</p>
                                  <h6>
                                    {getDisplayData(
                                      IPODetails?.bond_category_retail
                                    )}
                                    %
                                  </h6>
                                </div>
                              </div>
                            </div>
                          </div> */}
                          <br />

                          {/* <div className="row">
                            <div className="col-lg-6 col-md-6 col-sm-6  mb-4 col-sm-6 col-lg-6">
                              <div className="card">
                                <div className="card-header">
                                  <h5 className="m-0 header">
                                    Maturity Details
                                  </h5>
                                </div>
                                <div className="card-body">
                                  <div className="row">
                                    <div className="col-lg-6 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                      <p className="detail-title">
                                        Maturity Date
                                      </p>
                                      <h6>
                                        {getDisplayData(
                                          IPODetails?.bond_maturity_date
                                        )}
                                      </h6>
                                    </div>
                                    <div className="col-lg-6 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                      <p className="detail-title">
                                        Maturity Amount
                                      </p>
                                      <h6>
                                        {numberWithCommas(
                                          IPODetails?.bond_maturity_amount
                                        )}
                                      </h6>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-6  mb-4 col-sm-6 col-lg-6">
                              <div className="card">
                                <div className="card-header">
                                  <h5 className="m-0 header">Coupon Details</h5>
                                </div>
                                <div className="card-body">
                                  <div className="row">
                                    <div className="col-lg-6 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                      <p className="detail-title">
                                        Coupon Date
                                      </p>
                                      <h6>
                                        {getDisplayData(
                                          IPODetails?.bond_coupon_date
                                        )}
                                      </h6>
                                    </div>
                                    <div className="col-lg-6 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                      <p className="detail-title">
                                        Coupon Amount
                                      </p>
                                      <h6>
                                        {numberWithCommas(
                                          IPODetails?.bond_coupon_amount
                                        )}
                                      </h6>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div> */}
                          <br />

                          {/* <div className="row">
                            <div className="col-lg-6 col-md-6 col-sm-6  mb-4 col-sm-6 col-lg-6">
                              <div className="card">
                                <div className="card-header">
                                  <h5 className="m-0 header">Call Details</h5>
                                </div>
                                <div className="card-body">
                                  <div className="row">
                                    {IPODetails?.bond_call_details !=
                                      undefined &&
                                    IPODetails?.bond_call_details.length > 0 ? (
                                      IPODetails?.bond_call_details.map(
                                        (
                                          call: APIData.BondCallDetails,
                                          index: number
                                        ) => {
                                          return (
                                            <div className="row">
                                              <div className="col-lg-6 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                <p className="detail-title">
                                                  Call Date
                                                </p>
                                                <h6>
                                                  {getDisplayData(
                                                    call.bond_call_detail_datetime
                                                  )}
                                                </h6>
                                              </div>
                                              <div className="col-lg-6 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                <p className="detail-title">
                                                  Call Amount
                                                </p>
                                                <h6>
                                                  {numberWithCommas(
                                                    call.bond_call_detail_amount
                                                  )}
                                                </h6>
                                              </div>
                                            </div>
                                          );
                                        }
                                      )
                                    ) : (
                                      <h5>No Data Found</h5>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-6  mb-4 col-sm-6 col-lg-6">
                              <div className="card">
                                <div className="card-header">
                                  <h5 className="m-0 header">Put Details</h5>
                                </div>
                                <div className="card-body">
                                  <div className="row">
                                    {IPODetails?.bond_put_details !=
                                      undefined &&
                                    IPODetails?.bond_put_details.length > 0 ? (
                                      IPODetails?.bond_put_details.map(
                                        (
                                          put: APIData.BondPutDetails,
                                          index: number
                                        ) => {
                                          return (
                                            <div className="row">
                                              <div className="col-lg-6 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                <p className="detail-title">
                                                  Call Date
                                                </p>
                                                <h6>
                                                  {getDisplayData(
                                                    put.bond_put_detail_datetime
                                                  )}
                                                </h6>
                                              </div>
                                              <div className="col-lg-6 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                <p className="detail-title">
                                                  Call Amount
                                                </p>
                                                <h6>
                                                  {numberWithCommas(
                                                    put.bond_put_detail_amount
                                                  )}
                                                </h6>
                                              </div>
                                            </div>
                                          );
                                        }
                                      )
                                    ) : (
                                      <h5>No Data Found</h5>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div> */}
                          <br />

                          {/* <div className="card">
                                                        <div className="card-header">
                                                            <h5 className="m-0 header">Interest Calculate by Yearly</h5>
                                                        </div>
                                                        <div className="card-body">
                                                            <div className="table-rep-plugin">
                                                                <div className="table-responsive " data-pattern="priority-columns">
                                                                    <table id="tech-companies-1" className="table table table-bordered">
                                                                        <thead className="city-table">
                                                                            <tr>
                                                                                <th className="text-center">Sr.No</th>
                                                                                <th className="text-center">Interest Date</th>
                                                                                <th className="text-center">Interest Rate</th>
                                                                                <th className="text-center">Interest Amount</th>
                                                                                <th className="text-center">No. of Days</th>
                                                                                <th className="text-center"></th>
                                                                                <th className="text-center"></th>
                                                                                <th className="text-center"></th>
                                                                                <th className="text-center"></th>
                                                                            </tr>
                                                                        </thead>


                                                                        <tbody>
                                                                            <tr>
                                                                                <td className="text-center">01</td>
                                                                                <td className="text-center">14 / 12 / 2022</td>
                                                                                <td className="text-center">9.45%</td>
                                                                                <td className="text-center">₹ 1,00,000</td>
                                                                                <td className="text-center">365</td>
                                                                            </tr>

                                                                            <tr>
                                                                                <td className="text-center">02</td>
                                                                                <td className="text-center">14 / 12 / 2022</td>
                                                                                <td className="text-center">9.45%</td>
                                                                                <td className="text-center">₹ 1,00,000</td>
                                                                                <td className="text-center">365</td>
                                                                            </tr>

                                                                            <tr>
                                                                                <td className="text-center">03</td>
                                                                                <td className="text-center">14 / 12 / 2022</td>
                                                                                <td className="text-center">9.45%</td>
                                                                                <td className="text-center">₹ 1,00,000</td>
                                                                                <td className="text-center">365</td>
                                                                            </tr>

                                                                            <tr>
                                                                                <td className="text-center">04</td>
                                                                                <td className="text-center">14 / 12 / 2022</td>
                                                                                <td className="text-center">9.45%</td>
                                                                                <td className="text-center">₹ 1,00,000</td>
                                                                                <td className="text-center">365</td>
                                                                            </tr>

                                                                            <tr>
                                                                                <td className="text-center">05</td>
                                                                                <td className="text-center">14 / 12 / 2022</td>
                                                                                <td className="text-center">9.45%</td>
                                                                                <td className="text-center">₹ 1,00,000</td>
                                                                                <td className="text-center">365</td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                    <br /> */}

                          {/* <div className="card">
                                                        <div className="card-header">
                                                            <h5 className="m-0 header">Bond's Rating Details</h5>
                                                        </div>
                                                        <div className="card-body">
                                                            <div className="card-header px-0" style={{ borderBottom: 'none' }}>
                                                                <h5 className="m-0 header">CRISIL</h5>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-lg-2 col-md-4 col-sm-6  mb-4 col-sm-3">
                                                                    <p className="detail-title">Short Rating</p>
                                                                    <h6>Lorem ipsum</h6>
                                                                </div>
                                                                <div className="col-lg-2 col-md-4 col-sm-6  mb-4 col-sm-3">
                                                                    <p className="detail-title">Long Rating</p>
                                                                    <h6>Lorem ipsum</h6>
                                                                </div>
                                                                <div className="col-lg-2 col-md-4 col-sm-6  mb-4 col-sm-3">
                                                                    <p className="detail-title">Rating Agency</p>
                                                                    <h6>Lorem ipsum</h6>
                                                                </div>
                                                                <div className="col-lg-2 col-md-4 col-sm-6  mb-4 col-sm-3">
                                                                    <p className="detail-title">Bond’s Rating image</p>
                                                                    <h6 style={{ textDecoration: "underline",cursor:"pointer" }}>AAA.png <img src="../../assets/images/download.svg" style={{ width: "18px", marginBottom: "2px" }} /></h6>
                                                                </div>
                                                                <div className="col-lg-2 col-md-4 col-sm-6  mb-4 col-sm-3">
                                                                    <p className="detail-title">Rationale Document</p>
                                                                    <h6 style={{ textDecoration: "underline",cursor:"pointer" }}>Test.Pdf <img src="../../assets/images/download.svg" style={{ width: "18px", marginBottom: "2px" }} /></h6>
                                                                </div>
                                                            </div>
                                                            <div className="card-header px-0" style={{ borderBottom: 'none' }}>
                                                                <h5 className="m-0 header">CRISIL</h5>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-lg-2 col-md-4 col-sm-6  mb-4 col-sm-3">
                                                                    <p className="detail-title">Short Rating</p>
                                                                    <h6>Lorem ipsum</h6>
                                                                </div>
                                                                <div className="col-lg-2 col-md-4 col-sm-6  mb-4 col-sm-3">
                                                                    <p className="detail-title">Long Rating</p>
                                                                    <h6>Lorem ipsum</h6>
                                                                </div>
                                                                <div className="col-lg-2 col-md-4 col-sm-6  mb-4 col-sm-3">
                                                                    <p className="detail-title">Rating Agency</p>
                                                                    <h6>Lorem ipsum</h6>
                                                                </div>
                                                                <div className="col-lg-2 col-md-4 col-sm-6  mb-4 col-sm-3">
                                                                    <p className="detail-title">Bond’s Rating image</p>
                                                                    <h6 style={{ textDecoration: "underline",cursor:"pointer" }}>AAA.png <img src="../../assets/images/download.svg" style={{ width: "18px", marginBottom: "2px" }} /></h6>
                                                                </div>
                                                                <div className="col-lg-2 col-md-4 col-sm-6  mb-4 col-sm-3">
                                                                    <p className="detail-title">Rationale Document</p>
                                                                    <h6 style={{ textDecoration: "underline",cursor:"pointer" }}>Test.Pdf <img src="../../assets/images/download.svg" style={{ width: "18px", marginBottom: "2px" }} /></h6>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <br /> */}
                          <div
                            className="card"
                            style={{
                              borderTopLeftRadius: "10px",
                              borderTopRightRadius: "10px",
                              paddingTop: "10px",
                              paddingBottom: "20px",
                            }}
                          >
                            <div className="card-header">
                              <h5 className="m-0 header"> Disclaimer </h5>
                            </div>
                            <div className="card-body">
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: getDisplayData(
                                    IPODetails.bond_disclaimer
                                  ),
                                }}
                              />
                            </div>
                          </div>
                          <div className="card">
                            <div className="card-header">
                              <h5 className="m-0 header">
                                IPO's Rating Details
                              </h5>
                            </div>
                            <div className="card-body">
                              <div className="row">
                                <div className="col-lg-6 col-md-4 col-sm-6  mb-4 col-sm-3">
                                  <p className="detail-title">Rating Agency</p>
                                  <h6>
                                    {/* {JSON.stringify(IPODetails. bond_rating_agency)} */}
                                    {getDisplayData(
                                      IPODetails?.bond_rating_agency
                                    )}
                                  </h6>
                                </div>
                                <div className="col-lg-6 col-md-4 col-sm-6  mb-4 col-sm-3">
                                  <p className="detail-title">Rating</p>
                                  <h6>
                                    {/* {JSON.stringify(IPODetails)} */}
                                    {getDisplayData(IPODetails?.bond_rating)}
                                  </h6>
                                </div>
                                {/* <div className="col-lg-2 col-md-4 col-sm-6  mb-4 col-sm-3">
                                                                                    <p className="detail-title">Bond Rating Image</p>
                                                                                    <h6>{getDownloadFile(rat.)}</h6>
                                                                                </div> */}
                                {/* <div className="col-lg-6 col-md-4 col-sm-6  mb-4 col-sm-3">
                                          <p className="detail-title">
                                            Bond’s Rating Certification
                                          </p>
                                          <h6
                                            style={{
                                              textDecoration: "underline",
                                              cursor: "pointer",
                                            }}
                                            onClick={() =>
                                              getDownloadFile(
                                                IPODetails?.bond_product_note
                                              )
                                            }
                                          >
                                            {getDownloadURLExtension(
                                              rat?.bond_rating_certificate
                                            )}{" "}
                                            <img
                                              src="../../assets/images/download.svg"
                                              style={{
                                                width: "12px",
                                                marginBottom: "2px",
                                              }}
                                            />
                                          </h6>
                                        </div> */}
                              </div>
                            </div>
                          </div>
                          <br />

                          {/* <div className="card">
                                                        <div className="card-header">
                                                            <h5 className="m-0 header">Investment Purpose Categories</h5>
                                                        </div>
                                                        <div className="card-body">
                                                            <div className="container text-center">
                                                                <div className="row row-cols-6">
                                                                    <div className="col brand-tag-active p-1 mx-2 my-2 fs-6 text-center d-flex align-items-center justify-content-center">Earn higher fixed income than FDs</div>
                                                                    <div className="col brand-tag-active p-1  mx-2 my-2 fs-6 text-center d-flex align-items-center justify-content-center">Pledge for F&O</div>
                                                                    <div className="col brand-tag p-1  mx-2 my-2 fs-6 text-center d-flex align-items-center justify-content-center">Invest for short term</div>
                                                                    <div className="col brand-tag-active p-1  mx-2 my-2 fs-6 text-center d-flex align-items-center justify-content-center">Invest in NRI eligible bonds</div>
                                                                    <div className="col brand-tag p-1 mx-2 my-2 fs-6 text-center d-flex align-items-center justify-content-center">Get tax exemption (HUF)</div>
                                                                    <div className="col brand-tag p-1 mx-2 my-2 fs-6 text-center d-flex align-items-center justify-content-center">Save capital gains tax</div>
                                                                    <div className="col brand-tag p-1 mx-2 my-2 fs-6 text-center d-flex align-items-center justify-content-center">Get tax free returns</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <br />

                                                    <div className="card">
                                                        <div className="card-header">
                                                            <h5 className="m-0 header">Eligible Investors Details</h5>
                                                        </div>
                                                        <div className="card-body">
                                                            <div className="container text-center">
                                                                <div className="row row-cols-8 ">
                                                                    <div className="col brand-tag-active p-1 mx-2 my-2 fs-6 text-center d-flex align-items-center justify-content-center">Individual</div>
                                                                    <div className="col brand-tag-active p-1  mx-2 my-2 fs-6 text-center d-flex align-items-center justify-content-center">HUF</div>
                                                                    <div className="col brand-tag p-1  mx-2 my-2 fs-6 text-center d-flex align-items-center justify-content-center">Corporate</div>
                                                                    <div className="col brand-tag-active p-1  mx-2 my-2 fs-6 text-center d-flex align-items-center justify-content-center">Trust</div>
                                                                    <div className="col brand-tag p-1 mx-2 my-2 fs-6 text-center d-flex align-items-center justify-content-center">Instition</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <br /> */}
                          {/* 
                          <div className="card">
                            <div className="card-header">
                              <h5 className="m-0 header">
                                Investment Purpose Categories
                              </h5>
                            </div>
                            <div className="card-body">
                              <div className="container text-center">
                                <div className="row row-cols-12">
                                  {IPODetails.bond_brand_tags != undefined &&
                                  IPODetails.bond_brand_tags.length > 0
                                    ? IPODetails.bond_brand_tags.map(
                                        (
                                          tags: APIData.BondBrandTags,
                                          index: number
                                        ) => {
                                          return (
                                            <div
                                              className={`col-3 brand-tag${
                                                tags.bond_brand_tag_available !=
                                                  null &&
                                                tags.bond_brand_tag_available !=
                                                  0
                                                  ? "-active"
                                                  : ""
                                              } p-1 mx-2 my-2 fs-6 text-center d-flex align-items-center justify-content-center`}
                                            >
                                              {getDisplayData(
                                                tags.brand_tag_name
                                              )}
                                            </div>
                                          );
                                        }
                                      )
                                    : ""}
                                </div>
                              </div>
                            </div>
                          </div> */}
                          <br />
                          {/* 
                          <div className="card">
                            <div className="card-header">
                              <h5 className="m-0 header">
                                Eligible Investors Details
                              </h5>
                            </div>
                            <div className="card-body">
                              <div className="container text-center">
                                <div className="row row-cols-12 ">
                                  {IPODetails.bond_eligible_investors !=
                                    undefined &&
                                  IPODetails.bond_eligible_investors.length > 0
                                    ? IPODetails.bond_eligible_investors.map(
                                        (
                                          inv: APIData.BondEligibleInvestors,
                                          index: number
                                        ) => {
                                          return (
                                            <div
                                              className={`col-3 brand-tag${
                                                inv.eligible_investors_available !=
                                                  null &&
                                                inv.eligible_investors_available !=
                                                  0
                                                  ? "-active"
                                                  : ""
                                              } p-1 mx-2 my-2 fs-6 text-center d-flex align-items-center justify-content-center`}
                                            >
                                              {getDisplayData(
                                                inv.eligible_investor_name
                                              )}
                                            </div>
                                          );
                                        }
                                      )
                                    : ""}
                                </div>
                              </div>
                            </div>
                          </div> */}
                          <br />
                          <div
                            className="card"
                            style={{
                              borderTopLeftRadius: "10px",
                              borderTopRightRadius: "10px",
                              paddingTop: "10px",
                              paddingBottom: "20px",
                            }}
                          >
                            <div className="card-header">
                              <h5 className="m-0 header">
                                {" "}
                                Issuer Key Highlights{" "}
                              </h5>
                            </div>
                            <div className="card-body">
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: getDisplayData(
                                    IPODetails?.issuer
                                      ?.security_issuer_highlight
                                  ),
                                }}
                              />
                            </div>
                          </div>
                          <br />

                          {/* <div className="card">
                            <div className="card-header">
                              <h5 className="m-0 header">Key Highlights</h5>
                            </div>
                            <div className="card-body">
                              <ul>
                                <li>
                                  {getDisplayData(
                                    IPODetails.bond_key_highlights
                                  )}
                                </li>
                              </ul>
                            </div>
                          </div> */}
                          <br />

                          <div
                            className="card"
                            style={{
                              borderTopLeftRadius: "10px",
                              borderTopRightRadius: "10px",
                              paddingTop: "10px",
                            }}
                          >
                            <div className="card-header">
                              <h5 className="m-0 header">Issuer Details</h5>
                            </div>
                            <div className="card-body">
                              <div className="row">
                                {/* <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                  <p className="detail-title">Issuer's Type</p>
                                  <h6>
                                    {getDisplaySelectOption(
                                      IPODetails.bond_issuer_details?.[0]
                                        ?.bond_issuer_type ?? "N/A",
                                      "bond_issuers_type"
                                    )}
                                  </h6>
                                </div> */}
                                <div className="col-lg-3 col-md-4 col-sm-6 mb-4">
                                  <p className="detail-title">
                                    Total Annual Revenue
                                  </p>
                                  <h6>
                                    {getDisplayData(
                                      IPODetails?.issuer
                                        ?.security_issuer_annual_revenue ??
                                        "N/A"
                                    )}
                                  </h6>
                                </div>
                                <div className="col-lg-3 col-md-4 col-sm-6 mb-4">
                                  <p className="detail-title">
                                    Year of Inception
                                  </p>
                                  <h6>
                                    {getDisplayData(
                                      IPODetails?.issuer
                                        ?.security_issuer_founded_year ?? "N/A"
                                    )}
                                  </h6>
                                </div>
                                <div className="col-lg-3 col-md-4 col-sm-6 mb-4">
                                  <p className="detail-title">Industry</p>
                                  <h6>
                                    {getDisplayData(
                                      IPODetails?.issuer
                                        ?.security_issuer_industry ?? "N/A"
                                    )}
                                  </h6>
                                </div>
                                <div className="col-lg-3 col-md-4 col-sm-6 mb-4">
                                  <p className="detail-title">Head Office</p>
                                  <h6>
                                    {getDisplayData(
                                      IPODetails?.issuer
                                        ?.security_issuer_head_office ?? "N/A"
                                    )}
                                  </h6>
                                </div>

                                <div className="col-lg-3 col-md-4 col-sm-6 mb-4">
                                  <p className="detail-title">Type of Issuer</p>
                                  <h6>
                                    {getDisplayData(
                                      IPODetails?.security_types ?? "N/A"
                                    )}
                                  </h6>
                                </div>
                                {/* <div className="col-lg-3 col-md-4 col-sm-6 mb-4">
                                  <p className="detail-title">Series</p>
                                  <h6>
                                    {getDisplayData(
                                      IPODetails?.bond_issuer_details?.[0]
                                        ?.bond_issuer_series ?? "N/A"
                                    )}
                                  </h6>
                                </div>
                                <div className="col-lg-3 col-md-4 col-sm-6 mb-4">
                                  <p className="detail-title">Tenor (years)</p>
                                  <h6>
                                    {getDisplayData(
                                      IPODetails?.bond_issuer_details?.[0]
                                        ?.bond_issuer_tenor ?? "N/A"
                                    )}
                                  </h6>
                                </div>
                                <div className="col-lg-3 col-md-4 col-sm-6 mb-4">
                                  <p className="detail-title">Coupon Type</p>
                                  <h6>
                                    {getDisplaySelectOption(
                                      IPODetails?.bond_issuer_details?.[0]
                                        ?.bond_issuer_coupon_type ?? "N/A",
                                      "bond_issuer_coupon_type"
                                    )}
                                  </h6>
                                </div>

                                <div className="col-lg-3 col-md-4 col-sm-6 mb-4">
                                  <p className="detail-title">
                                    Frequency of Interest
                                  </p>
                                  <h6>
                                    {getDisplaySelectOption(
                                      IPODetails?.bond_issuer_details?.[0]
                                        ?.bond_issuer_frequency_of_interest ??
                                        "N/A",
                                      "bond_issuer_frequency_of_interest"
                                    )}
                                  </h6>
                                </div>
                                <div className="col-lg-3 col-md-4 col-sm-6 mb-4">
                                  <p className="detail-title">First IP Date</p>
                                  <h6>
                                    {getDisplayData(
                                      IPODetails?.bond_issuer_details?.[0]
                                        ?.bond_issuer_first_ip_date ?? "N/A"
                                    )}
                                  </h6>
                                </div>
                                <div className="col-lg-3 col-md-4 col-sm-6 mb-4">
                                  <p className="detail-title">IP Date</p>
                                  <h6>
                                    {getDisplayData(
                                      IPODetails?.bond_issuer_details?.[0]
                                        ?.bond_issuer_ip_dates ?? "N/A"
                                    )}
                                  </h6>
                                </div>
                                <div className="col-lg-3 col-md-4 col-sm-6 mb-4">
                                  <p className="detail-title">
                                    Effective Yield
                                  </p>
                                  <h6>
                                    {getDisplayData(
                                      IPODetails?.bond_issuer_details?.[0]
                                        ?.bond_issuer_effective_yield ?? "N/A"
                                    )}
                                  </h6>
                                </div>
                                <div className="col-lg-3 col-md-4 col-sm-6 mb-4">
                                  <p className="detail-title">
                                    Issuers Profile Link
                                  </p>
                                  <h6>
                                    {getDisplayData(
                                      IPODetails?.bond_issuer_details?.[0]
                                        ?.bond_issuer_profile_link ?? "N/A"
                                    )}
                                  </h6>
                                </div>
                                <div className="col-lg-3 col-md-4 col-sm-6 mb-4">
                                  <p className="detail-title">
                                    Issuers Finance Details
                                  </p>
                                  <h6>
                                    {getDisplayData(
                                      IPODetails?.bond_issuer_details?.[0]
                                        ?.bond_issuer_finance_details ?? "N/A"
                                    )}
                                  </h6>
                                </div> */}
                                <div className="col-lg-3 col-md-4 col-sm-6 mb-4">
                                  <p className="detail-title">
                                    Issue Documents
                                  </p>
                                  {IPODetails?.issuer?.issuer_document?.length >
                                  0 ? (
                                    IPODetails.issuer.issuer_document.map(
                                      (doc: any, index: number) => (
                                        <h6
                                          key={index}
                                          style={{
                                            textDecoration: "underline",
                                            cursor: "pointer",
                                          }}
                                          onClick={() =>
                                            getDownloadFile(
                                              doc.security_issuer_document_file
                                            )
                                          }
                                        >
                                          {getDownloadURLExtension(
                                            doc.security_issuer_document_file
                                          )}
                                        </h6>
                                      )
                                    )
                                  ) : (
                                    <p>No documents available.</p>
                                  )}
                                </div>

                                {/* <div className="col-lg-12 col-md-12 col-sm-12 mb-4">
                                  <p className="detail-title">Paragraph</p>
                                  <h6>
                                    {getDisplayData(
                                      IPODetails?.bond_issuer_details?.[0]
                                        ?.bond_issuer_about ?? "N/A"
                                    )}
                                  </h6>
                                </div> */}
                              </div>
                            </div>
                          </div>
                          <br />

                          {/* <div className="row">
                                                        <div className="card" style={{ borderTopLeftRadius: "10px", borderTopRightRadius: "10px", paddingTop: "10px", paddingBottom: "20px" }}>
                                                            <div className="card-header d-flex justify-content-between align-items-center">
                                                                <div className='d-flex justify-content-start'>
                                                                    <h5 className="m-0 header"> Specific Terms for Each Series of NCDs Details </h5>
                                                                </div>
                                                                <div className='d-flex justify-content-end align-items-center '>
                                                                    <div className="form-check form-switch mx-2">
                                                                        <label className="form-check-label" htmlFor="flexSwitchCheckChecked">Disable/Enable</label>
                                                                        <input className="form-check-input " type="checkbox" role="switch" id="flexSwitchCheckChecked" checked />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="card-body">
                                                                <div className="table-responsive " data-pattern="priority-columns">
                                                                    <table id="tech-companies-1" className="table table table-bordered">
                                                                        <thead className="city-table">
                                                                            <tr>
                                                                                <th>Sr. No</th>
                                                                                <th>Series 1</th>
                                                                                <th>Series 2</th>
                                                                                <th>Series 3</th>
                                                                                <th>Series 4</th>
                                                                                <th>Series 5</th>
                                                                                <th>Series 6</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            <tr >
                                                                                <td>1</td>
                                                                                <td>Frequency of Interest Payment</td>
                                                                                <td>Monthly</td>
                                                                                <td>Cumulative</td>
                                                                                <td>Monthly</td>
                                                                                <td>Cumulative</td>
                                                                                <td>Monthly</td>
                                                                            </tr>
                                                                            <tr >
                                                                                <td>2</td>
                                                                                <td>Tenor</td>
                                                                                <td>Monthly</td>
                                                                                <td>Cumulative</td>
                                                                                <td>Monthly</td>
                                                                                <td>Cumulative</td>
                                                                                <td>Monthly</td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div> */}
                          <div className="card-body">
                            <div
                              className="table-responsive"
                              data-pattern="priority-columns"
                            >
                              <table
                                id="tech-companies-1"
                                className="table table-bordered"
                              >
                                <thead className="city-table">
                                  <tr>
                                    <th></th>
                                    <th>Name</th>
                                    {IPODetails?.series?.map(
                                      (res: any, index: number) => (
                                        <th key={index}>
                                          Series{" "}
                                          {res.security_series_name ||
                                            `#${index + 1}`}
                                        </th>
                                      )
                                    )}
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    <td>1</td>
                                    <td>Frequency of Interest Payment</td>
                                    {IPODetails?.series?.map(
                                      (series: any, index: number) => (
                                        <td key={index}>
                                          {series.series_payout
                                            ?.series_payout_name || "N/A"}
                                        </td>
                                      )
                                    )}
                                  </tr>
                                  <tr>
                                    <td>2</td>
                                    <td>Minimum Application (₹)</td>
                                    {IPODetails?.series?.map(
                                      (series: any, index: number) => (
                                        <td key={index}>
                                          {series.security_series_min_qty ||
                                            "N/A"}
                                        </td>
                                      )
                                    )}
                                  </tr>
                                  <tr>
                                    <td>3</td>
                                    <td>In Multiples of thereafter (₹)</td>
                                    {IPODetails?.series?.map(
                                      (series: any, index: number) => (
                                        <td key={index}>
                                          {series.security_series_mul_qty ||
                                            "N/A"}
                                        </td>
                                      )
                                    )}
                                  </tr>
                                  <tr>
                                    <td>4</td>
                                    <td>Face Value/ Issue Price of NCDs (₹)</td>
                                    {IPODetails?.series?.map(
                                      (series: any, index: number) => (
                                        <td key={index}>
                                          {series.security_series_price ||
                                            "N/A"}
                                        </td>
                                      )
                                    )}
                                  </tr>
                                  <tr>
                                    <td>5</td>
                                    <td>Tenor</td>
                                    {IPODetails?.series?.map(
                                      (series: any, index: number) => (
                                        <td key={index}>
                                          {series.security_series_tenure ||
                                            "N/A"}
                                        </td>
                                      )
                                    )}
                                  </tr>
                                  <tr>
                                    <td>6</td>
                                    <td>Coupon (% per annum)</td>
                                    {IPODetails?.series?.map(
                                      (series: any, index: number) => (
                                        <td key={index}>
                                          {series.security_series_coupon_rate ||
                                            "N/A"}
                                        </td>
                                      )
                                    )}
                                  </tr>
                                  <tr>
                                    <td>7</td>
                                    <td>Effective Yield (% per annum)</td>
                                    {IPODetails?.series?.map(
                                      (series: any, index: number) => (
                                        <td key={index}>
                                          {series.security_series_yield ||
                                            "N/A"}
                                        </td>
                                      )
                                    )}
                                  </tr>
                                  <tr>
                                    <td>8</td>
                                    <td>Mode of Issuer</td>
                                    {IPODetails?.series?.map(
                                      (series: any, index: number) => (
                                        <td key={index}>
                                          {series.security_series_mode_of_issuer ||
                                            "N/A"}
                                        </td>
                                      )
                                    )}
                                  </tr>
                                  <tr>
                                    <td>9</td>
                                    <td>
                                      Redemption Amount (₹ / NCD) on Maturity
                                    </td>
                                    {IPODetails?.series?.map(
                                      (series: any, index: number) => (
                                        <td key={index}>
                                          {series.security_series_redemption_amount ||
                                            "N/A"}
                                        </td>
                                      )
                                    )}
                                  </tr>
                                  <tr>
                                    <td>10</td>
                                    <td>Put and Call Option</td>
                                    {IPODetails?.series?.map(
                                      (series: any, index: number) => (
                                        <td key={index}>
                                          {series.security_series_call_put
                                            ? {
                                                1: "Put",
                                                2: "Call",
                                                3: "Both",
                                              }[
                                                series.security_series_call_put as
                                                  | 1
                                                  | 2
                                                  | 3
                                              ] || "N/A"
                                            : "N/A"}
                                        </td>
                                      )
                                    )}
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>

                          <div className="row">
                            <div
                              className="card"
                              style={{
                                borderTopLeftRadius: "10px",
                                borderTopRightRadius: "10px",
                                paddingTop: "10px",
                                paddingBottom: "20px",
                              }}
                            >
                              <div className="card-header d-flex justify-content-between align-items-center">
                                <div className="d-flex justify-content-start">
                                  <h5 className="m-0 header">
                                    Brokerage Details
                                  </h5>
                                </div>
                              </div>

                              <div className="card-body">
                                {rows.length > 0 ? (
                                  <div
                                    className="table-responsive"
                                    data-pattern="priority-columns"
                                  >
                                    <table
                                      id="tech-companies-1"
                                      className="table table-bordered"
                                    >
                                      <thead className="city-table">
                                        <tr>
                                          <th>S.No</th>
                                          <th style={{ minWidth: "500px" }}>
                                            Name
                                          </th>
                                          {IPODetails?.series?.map(
                                            (res: any, index: number) => (
                                              <th key={index}>
                                                Series {toRoman(index)} (
                                                {res?.bond_ncd_tenor} MTS)
                                              </th>
                                            )
                                          )}
                                          {/* <th>Action</th> */}
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {rows.map((row, index) => (
                                          <tr key={index}>
                                            <td>{index + 1}</td>
                                            <td>
                                              {/* Display the category name instead of a select field */}
                                              <span>
                                                {
                                                  categoryList.find(
                                                    (category: any) =>
                                                      category.category_id ===
                                                      row.category_id
                                                  )?.category_name
                                                }
                                              </span>
                                            </td>
                                            {row.series.map(
                                              (s: any, seriesIndex: number) => (
                                                <td key={seriesIndex}>
                                                  {/* Display the value instead of an input field */}
                                                  <span>
                                                    {
                                                      s.security_category_series_value
                                                    }
                                                  </span>
                                                </td>
                                              )
                                            )}
                                            {/* <td>
                    Optional: Show an empty cell or specific text for "Action"
                    <span>-</span>
                  </td> */}
                                          </tr>
                                        ))}
                                      </tbody>
                                    </table>
                                  </div>
                                ) : (
                                  <div className="text-center py-5">
                                    No Data Found.
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>

                          {/* <div className="row">
                        
                          </div> */}

                          {/* <div className="row">
                            <div className="col-lg-6 col-md-6 col-sm-6  mb-4 col-sm-6 col-lg-6">
                              <div className="card">
                                <div className="card-header">
                                  <h5 className="m-0 header">ASBA Details</h5>
                                </div>
                                <div className="card-body">
                                  <div className="row">
                                    <h6
                                      style={{
                                        textDecoration: "underline",
                                        cursor: "pointer",
                                      }}
                                      onClick={() =>
                                        getDownloadFile(
                                          IPODetails?.bond_asba_details
                                        )
                                      }
                                    >
                                      <img
                                        src="../../assets/images/download.svg"
                                        style={{
                                          width: "18px",
                                          marginBottom: "2px",
                                          paddingRight: "5px",
                                        }}
                                      />
                                      {getDownloadURLExtension(
                                        IPODetails.bond_asba_details
                                      )}
                                    </h6>
                                  </div>
                                </div>
                              </div>
                            </div>
                            
                          </div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
      <AccountStatusModal
        userId={userId}
        handleOpen={() => handleClickOpen}
        openModal={open}
        handleClose={(e: any) => handleClose(e)}
      />
      {/* <SimpleModal contents={res.suspended_remarks} handleOpen={() => handleClickOpen} openModal={open} handleClose={(e: any) => handleClose(e)} /> */}
      <ImagePreviewModal
        userId={userId}
        handleOpen={() => handleClickOpen}
        openModal={previewOpen}
        handleClose={(e: any) => handleClose(e)}
      />
    </div>
  );
};

export default IPODetails;
