import Pagination from "@mui/material/Pagination";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
// import DatePicker from 'react-date-picker';
import DatePicker from "react-datepicker";
import DataTable from "react-data-table-component";
import "react-datepicker/dist/react-datepicker.css";
import { toast } from "react-hot-toast";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppStateAPI } from "../common/AppStateAPI";
import * as Utilities from "../common/Utilities";
import {
  BondType,
  DealerActiveBondStatus,
  DealerActiveBondStatusNew,
  Depository,
  PageLinks,
} from "../common/Constants";
import { APIData } from "../common/DataTypes";
import Footer from "../common/Footer";
import Header from "../common/Header";
import { InitialData } from "../common/InitialValue";
import { ServerAPI } from "../common/ServerAPI";
import {
  getDealerStatus,
  getDisplayData,
  getDownloadFile,
  getFormattedLocalDate,
  getFormattedLocalDateTime,
  getOrderStatus,
  getOrderTypeFilter,
  numberWithCommas,
} from "../common/Utilities";
import ConfirmationModal from "../modals/Confirmation-modal";
import SimpleModal from "../modals/SimpleModal";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import BondEnquiryDetailPopup from "../modals/BondEnquiryDetailPopup";
import { PublishOutlined } from "@mui/icons-material";
import { cp } from "fs";
import SecuritiesInventory from "../modals/SecuritiesInventory";

var startSerialNo = 0;
var startSerialNoActiveBonds = 0;
const TrustMonyUsers: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const passing_value = location.state;
  const [preData, setPreData] = useState(passing_value);
  const [bondSelectOption, setBondSelectOption] = useState("all");
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [buttonText, setButtonText] = useState("Upload Today Price");

  // const [value, onChange] = useState(new Date());
  const [toData, setToData] = useState<null | Date>();
  const [fromDate, setFromDate] = useState<null | Date>();
  const [open, setOpen] = useState(false);
  const [open_modal, setOpenModal] = useState(false);
  const [open_modals, setOpenModals] = useState(false);
  const [orderList, setOrderList] = useState<APIData.CustomerOrderList[]>([]);
  const [totalCount, setTotalCount] = useState(0);
  const [totalCountActiveBonds, setTotalCountActiveBonds] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageNumberActiveBonds, setPageNumberActiveBonds] = useState(1);
  const [user_id, setUserId] = useState();
  const [view_content, setViewContent] = useState();
  const [search_text, setSearchText] = useState("");
  const [active_search_text, setActiveSearchText] = useState("");
  const [all_bond_search_text, setAllBondSearchText] = useState("");
  const { setShowPreloader, activeDealerTab, setActiveDealerTab } =
    useAppStateAPI();
  const searchref = React.createRef();
  const [userId, setuserId] = useState(0);
  const [all_datas, setAllDatas] = useState({
    id: 0,
    ISIN_number: "",
    publish_unpublish: 0,
    listingID: 0,
  });
  const [customerEnqurieList, setcustomerEnqurieList] = useState<
    APIData.CustomerEnquire[]
  >([]);
  const [customerEnqurieData, setcustomerEnqurieData] =
    useState<APIData.CustomerEnquire>(InitialData.CustomerEnquire);
  const [activeBondList, setActiveBondList] = useState<
    APIData.ActiveBondList[]
  >([]);
  const [InventoryAdd, setInventoryAdd] = useState<APIData.ActiveBondList[]>(
    []
  );
  const [confirmContent, setConfirmContent] = useState("");
  const [confirmPage, setconfirmPage] = useState("");
  const [allBondList, setAllBondList] = useState<APIData.AllBondList[]>([]);
  const [tabIndex, setTabIndex] = useState(0);
  const [activeBondBg, setactiveBondBg] = useState(false);
  const [activeBondListingBondID, setActiveBondListingBondID] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentPageActiveBonds, setCurrentPageActiveBond] = useState(1);
  const itemsPerPage = 10;
  const pageCount = Math.ceil(totalCount / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const currPage = currentPage ? currentPage : 1;
  startSerialNo = currPage * 10 - 10;

  const currPageActiveBonds = currentPageActiveBonds
    ? currentPageActiveBonds
    : 1;
  startSerialNoActiveBonds = currPageActiveBonds * 10 - 10;

  let columns: any = [
    {
      name: "Sr.No",
      width: "90px",
      cell: (row: any, index: any) => startSerialNo + index + 1, //RDT provides index by default
    },

    {
      name: "Cust. ID",
      selector: (row: any) =>
        row?.customer_id == null || row?.customer_id == ""
          ? "N/A"
          : row?.customer_id,
      sortable: true,
      width: "200px",
    },
    {
      name: "Request date",
      selector: (row: any) => row?.customer_bond_enquire_created_at,
      sortable: true,
      width: "200px",
      format: (row: any) =>
        moment(row?.customer_bond_enquire_created_at).format(
          "DD-MM-YYYY,  hh:mm A"
        ),
    },

    {
      name: "Customer Name",
      selector: "customer_name",
      sortable: true,
      width: "200px",
      wrap: true,
    },
    {
      name: "Mobile Number",
      selector: (row: any) =>
        row?.customer_mobile_number !== null &&
        row?.customer_mobile_number !== ""
          ? row?.customer_mobile_number
          : "N/A",
      sortable: true,
    },
    {
      name: "Email ID",
      selector: (row: any) => getDisplayData(row?.customer_email_id),
      sortable: true,
      width: "200px",
      wrap: true,
    },
    {
      name: "Bond Name",
      selector: (row: any) => getDisplayData(row?.bond_name),
      sortable: true,
      width: "400px",
      wrap: true,
    },
    {
      name: "ISIN Number",
      selector: (row: any) => getDisplayData(row?.bond_isin_number),
      sortable: true,
    },
    {
      name: "Price (₹)",
      selector: (row: any) =>
        numberWithCommas(row?.customer_bond_enquire_price),
      sortable: true,
      width: "200px",
    },
    // {
    //   name: "Yield (%)",
    //   selector: (row: any) => getDisplayData(row?.customer_bond_enquire_yield),
    //   sortable: true,
    // },
    {
      name: "Qty",
      selector: (row: any) =>
        getDisplayData(row?.customer_bond_enquire_quantity),
      sortable: true,
    },
    {
      name: "Settlement Date",
      selector: (row: any) => getDisplayData(row?.settlement_date_name),
      sortable: true,
    },
    {
      name: "Order Type",
      selector: (row: any) => getDisplayData(row?.buy_sell_name),
      sortable: true,
    },
    {
      name: "Action",
      selector: (row: any) => row?.customer_bond_enquire_status,
      cell: (row: any) => [
        <div className="city-action-rable-row ">
          {row?.customer_bond_enquire_status === 1 ? (
            <div>Added</div>
          ) : (
            <div
              onClick={() => {
                handleClickOpen();
                setcustomerEnqurieData(row);
              }}
            >
              <img
                src="../../assets/images/add.svg"
                style={{ width: "15px", margin: "0px 5px", cursor: "pointer" }}
              />
              <br />
              <span style={{ cursor: "pointer" }}>Add</span>
            </div>
          )}
        </div>,
      ],
    },
  ];
  let columns1: any = [
    {
      name: "Sr.No",
      width: "90px",
      cell: (row: any, index: any) => startSerialNoActiveBonds + index + 1, //RDT provides index by default
    },

    {
      name: "Bond Name",
      selector: (row: any) => getDisplayData(row?.security?.security_name),
      sortable: true,
      width: "400px",
      wrap: true,
    },
    {
      name: "ISIN Number",
      selector: (row: any) => getDisplayData(row?.security?.security_isin),
      sortable: true,
      width: "200px",
    },
    {
      name: "Price (₹)",
      selector: (row: any) =>
        numberWithCommas(row?.listing_settlement[0]?.security_price),
      sortable: true,
      width: "200px",
    },
    {
      name: "Start Date",
      selector: (row: any) => getFormattedLocalDate(row?.security_start_dt),
      sortable: true,
      width: "200px",
    },
    {
      name: "End Date",
      selector: (row: any) => getFormattedLocalDate(row?.security_end_dt),
      sortable: true,
      width: "200px",
    },
    {
      name: "Qty",
      selector: (row: any) => getDisplayData(row?.security_inventory_qty),
      sortable: true,
    },
    // {
    //   name: "Yield (%)",
    //   selector: (row: any) =>
    //     getDisplayData(row?.listing_settlement[0]?.security_yield),
    //   sortable: true,
    //   width: "200px",
    // },
    {
      name: "Order Type",
      selector: (row: any) => getDisplayData(row?.trade?.trade_name),
      sortable: true,
      width: "200px",
    },
    {
      name: "Action",
      width: "200px",
      selector: (row: any) => row?.bond_listing_publish_unpublish,
      cell: (row: any) => [
        <div className="city-action-rable-row d-flex align-item-center">
          <i
            className="fas fa-eye table-icons pe-1"
            onClick={() =>
              navigate(PageLinks.BOND_PRICE_DETAIL_VIEW, {
                state: {
                  bondISINNumber: row?.security?.security_isin,
                  publishMode: row?.security?.security_status,
                  bondID: row?.security_id,
                  ListingID: row?.security_listing_id,
                  data: row,
                },
              })
            }
          ></i>
          {row?.security_status === true ? (
            <div
              onClick={() => {
                handlePublishConfirmationOpen(
                  DealerActiveBondStatusNew?.UnPublish,
                  row
                );
              }}
              style={{ cursor: "pointer" }}
            >
              <img
                alt="publish"
                src="../../assets/images/publish.svg"
                style={{ width: "15px", margin: "0px 5px", cursor: "pointer" }}
              />
              <span>Published </span>
            </div>
          ) : (
            <div
              onClick={() => {
                handlePublishConfirmationOpen(
                  DealerActiveBondStatusNew?.Publish,
                  row
                );
              }}
              style={{ cursor: "pointer" }}
            >
              <img
                alt="icon"
                src="../../assets/images/un_publish.svg"
                style={{ width: "15px", margin: "0px 5px", cursor: "pointer" }}
              />
              <span style={{ cursor: "pointer" }}>Un Published</span>
            </div>
          )}
        </div>,
      ],
    },
  ];
  let columns2: any = [
    {
      name: "Sr.No",
      width: "90px",
      cell: (row: any, index: any) => startSerialNo + index + 1, //RDT provides index by default
    },

    {
      name: "Bond Name",
      width: "400px",
      wrap: true,
      selector: (row: any) => getDisplayData(row?.security_name),
      sortable: true,
    },
    {
      name: "Issuer Name",
      selector: (row: any) => getDisplayData(row?.issuer?.security_issuer_name),
      sortable: true,
      width: "400px",
      wrap: true,
    },
    // {
    //   name: "Instrument Type",
    //   selector: (row: any) => numberWithCommas(row?.bond_nature_of_instrument),
    //   sortable: true,
    //   width: "200px",
    //   wrap: true,
    // },
    {
      name: "ISIN Number",
      selector: (row: any) => getDisplayData(row?.security_isin),
      sortable: true,
      width: "200px",
    },

    {
      name: "Face Value",
      selector: (row: any) => numberWithCommas(row?.security_face_value),
      sortable: true,
      width: "200px",
    },
    {
      name: "Maturity Date",
      selector: (row: any) => getDisplayData(row?.security_maturity_date),
      sortable: true,
      width: "200px",
    },
    {
      name: "Call Date",
      selector: (row: any) => getDisplayData(row?.security_call_date),
      sortable: true,
      width: "200px",
    },
    {
      name: "Action",
      selector: (row: any) => row?.listing,
      cell: (row: any) => [
        <td className="city-action-rable-row ">
          {row?.listing === true ? (
            <div>Added</div>
          ) : (
            <div
              onClick={(e: any) => {
                e.preventDefault();
                handleClickOpenModel();
                setInventoryAdd(row);
              }}
            >
              <img
                alt="icone"
                src="../../assets/images/add.svg"
                style={{ width: "15px", margin: "0px 5px", cursor: "pointer" }}
              />
              <span style={{ cursor: "pointer" }}>Add</span>
            </div>
          )}
        </td>,
      ],
    },
  ];
  const handlePageChange = (page: any) => {
    setCurrentPage(page);
    setPageNumber(page);
  };
  const tabReload = (value: any) => {
    if (value == "active_bonds") {
      setactiveBondBg(true);
      setTabIndex(1);
      // loadActiveBondList();
      setTimeout(() => {
        setactiveBondBg(false);
      }, 5000);
    } else if (value == "all_bonds") {
      setTabIndex(2);
    } else {
      setTabIndex(0);
    }
  };
  const resetfilter = () => {
    setFromDate(null);
    setToData(null);
  };
  // useEffect(() => {
  //   loadCustomerEnquiryList();
  // }, [pageNumber, search_text, toData, fromDate, preData, open]);

  useEffect(() => {
    loadBondList();
  }, [pageNumber, all_bond_search_text, toData, fromDate]);

  useEffect(() => {
    loadActiveBondList();
  }, [pageNumberActiveBonds, toData, fromDate, active_search_text]);

  // useEffect(() => {
  //   if (all_bond_search_text != "") {
  //     loadBondList();
  //   }
  // }, [pageNumberActiveBonds, toData, fromDate, all_bond_search_text]);

  useEffect(() => {
    loadCustomerEnquiryList();
    loadActiveBondList();
    loadBondList();
  }, []);

  const loadCustomerEnquiryList = () => {
    setShowPreloader(true);
    ServerAPI.getCustomerEnquiryList(pageNumber, search_text, toData, fromDate)
      .then((res) => {
        try {
          setcustomerEnqurieList(res.data);
          setTotalCount(res.total_records);
        } catch (error) {
          toast.error(res.error);
        }
      })
      .finally(() => {
        setShowPreloader(false);
      });
  };

  const loadActiveBondList = () => {
    console.log("qwerewrtetetet", pageNumberActiveBonds);

    setShowPreloader(true);
    ServerAPI.getActiveBondList(
      pageNumberActiveBonds,
      active_search_text,
      toData,
      fromDate,
      null
    )
      .then((res) => {
        try {
          setActiveBondList(res.data[0]?.listing);
          setTotalCountActiveBonds(res.data[0].pagination.total_records);
        } catch (error) {
          toast.error(res.error);
        }
      })
      .finally(() => {
        setShowPreloader(false);
      });
  };

  const loadBondList = () => {
    setShowPreloader(true);
    ServerAPI.getAllBondList(pageNumber, all_bond_search_text, toData, fromDate)
      .then((res) => {
        try {
          setAllBondList(res.data[0]?.securities);
          setTotalCount(res.data[0]?.pagination.total_records);
        } catch (error) {
          toast.error(res.error);
        }
      })
      .finally(() => {
        setShowPreloader(false);
      });
  };

  const CustomInput = React.forwardRef((props: any, ref: any) => {
    return (
      <div className="datePickerStyle">
        <label onClick={props.onClick} ref={ref}>
          {props.value || props.placeholder}
        </label>
        <img
          src="../../assets/images/calender-icon.svg"
          onClick={props.onClick}
          ref={ref}
        />
      </div>
    );
  });

  /*customer enquiry model*/
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = (e: any) => {
    setOpen(e);
  };
  const handleClickOpenModel = () => {
    setOpenModals(true);
  };
  const handleCloseModel = (e: any) => {
    setOpenModals(e);
  };

  /*active bond confirm popup*/
  const handleConfirmationOpen = (bondID: number, isinNumber: string) => {
    setOpenModal(true);
    setConfirmContent(
      "Are you sure you want to add the bond to the active list?"
    );
    setconfirmPage("update-bond-active-mode");
    setAllDatas({
      id: bondID,
      ISIN_number: isinNumber,
      publish_unpublish: 0,
      listingID: 0,
    });
  };
  const handleConfirmationClosed = (e: any) => {
    setOpenModal(e);
  };

  /*pulish or un publish confirm popup*/
  const handlePublishConfirmationOpen = (
    mode: number,
    listing_data: number
  ) => {
    setOpenModal(true);
    setConfirmContent(
      `Are you sure you want to ${
        mode == DealerActiveBondStatus.Publish ? "publish" : "Un Publish"
      } the Bond?`
    );
    setconfirmPage("update-bond-publish-mode");
    setAllDatas({
      id: 0,
      ISIN_number: "",
      publish_unpublish: mode,
      listingID: listing_data,
    });
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files && files.length > 0) {
      const file = files[0];
      const validMimeTypes = [
        "application/vnd.ms-excel", // .xls
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", // .xlsx
      ];

      if (!validMimeTypes.includes(file.type)) {
        toast.error("Unsupported file format. Please upload an Excel file.");
        setSelectedFile(null); // Reset selected file if invalid
        setButtonText("Upload Today Price");
        event.target.value = ""; // Reset file input
        return;
      }

      // If valid file, update state and button text
      setSelectedFile(file);
      setButtonText("Submit");
      console.log("Selected file:", file);
    }

    // Reset file input to allow reselecting the same file
    event.target.value = "";
  };
  const handleUploadClick = () => {
    if (selectedFile) {
      // Perform API call
      setShowPreloader(true);
      ServerAPI.uploadExcelForBondDailyUpdate(selectedFile)
        .then((res) => {
          if (res.status === 200) {
            // Handle success
            toast.success(res.message);
            setSelectedFile(null); // Clear the selected file
            setButtonText("Upload Today Price");
          } else {
            // Handle success
            toast.error(res.message);
          }
        })
        .finally(() => {
          setShowPreloader(false);
        });
    } else {
      // Open dialog
      fileInputRef.current?.click();
    }
  };

  const customStyles = {
    headCells: {
      style: {
        backgroundColor: "#A27B1E",
        color: "white",
        fontWeight: "bold",
      },
    },
  };

  const handleDownloadClick = async () => {
    const persistAuthString = await Utilities.getAuthString();
    try {
      const response = await fetch(
        `https://v2-trust-api.trustmony.com/api/v2/admin/securities-listing/file`,
        {
          method: "GET",
          headers: {
            Authorization: persistAuthString,
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to fetch the file.");
      }

      // Retrieve Content-Disposition header to extract filename if provided
      const contentDisposition = response.headers.get("Content-Disposition");
      console.log("Content-Disposition:", contentDisposition);

      let filename = "Sample.xlsx"; // Default filename
      if (contentDisposition) {
        const match = contentDisposition.match(/filename="(.+?)"/);
        if (match && match[1]) {
          filename = match[1];
        }
      }

      // Convert response to ArrayBuffer
      const arrayBuffer = await response.arrayBuffer();

      // Create a Blob from the ArrayBuffer
      const blob = new Blob([arrayBuffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = URL.createObjectURL(blob);

      // Trigger file download
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", filename); // Use the extracted or default filename
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {
      console.error("Error fetching the file:", error);
    } finally {
      // Cleanup or additional actions
    }
  };

  const removeSelectedFile = () => {
    setSelectedFile(null);
    setButtonText("Upload Today Price");
  };

  return (
    <div>
      <div id="layout-wrapper">
        <Header />
        <div className="main-content py-4">
          <div className="page-content">
            <div className="container-fluid">
              <div className="row">
                <div className="col-12">
                  <div className="page-title-box d-sm-flex align-items-center justify-content-between pt-4 pb-0">
                    {/* <h4 className="mb-sm-0 font-size-18 "> Suspend Users</h4> */}
                    <div className="page-title-right">
                      <ol className="breadcrumb ">
                        <li className="breadcrumb-item">
                          <a style={{ color: "#90929E" }}>Users</a>
                        </li>
                        {/* <li className="breadcrumb-item">
                          <a style={{ color: "#90929E" }}>Trust Mony Users</a>
                        </li> */}
                        <li className="breadcrumb-item">
                          <a>Dealers</a>
                        </li>
                      </ol>
                    </div>
                  </div>
                </div>
              </div>
              <Tabs
                selectedIndex={tabIndex}
                onSelect={(index) => {
                  setTabIndex(index);
                  if (index == 1) {
                    loadActiveBondList();
                  }
                }}
              >
                <TabList
                  className="Product_detail_tablist"
                  style={{ marginBottom: "0rem" }}
                >
                  <div className=" d-flex justify-content-between align-items-end">
                    <div>
                      <Tab className="product_detail_tab">
                        Customer Enquiries
                      </Tab>
                      <Tab className="product_detail_tab">Active Bonds</Tab>
                      <Tab className="product_detail_tab">All Bonds</Tab>
                    </div>
                    {tabIndex === 1 && (
                      <div>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "1rem",
                          }}
                        >
                          {/* File Review Section */}
                          {selectedFile && (
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                gap: "0.5rem",
                              }}
                            >
                              <span
                                style={{ color: "#A27B1E", fontWeight: "bold" }}
                              >
                                {selectedFile.name}
                              </span>
                              <button
                                onClick={removeSelectedFile}
                                style={{
                                  background: "transparent",
                                  border: "none",
                                  color: "red",
                                  fontSize: "1.2rem",
                                  cursor: "pointer",
                                }}
                                title="Remove File"
                              >
                                ✖
                              </button>
                            </div>
                          )}

                          {/* Upload Button */}
                          <button
                            className="xl_sheet mb-2"
                            style={{
                              backgroundColor: "#A27B1E",
                              color: "white",
                            }}
                            onClick={handleUploadClick}
                          >
                            {buttonText}{" "}
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="15"
                              height="15"
                              viewBox="0 0 84.227 95.107"
                              className="xsl_icon"
                              fill="white"
                            >
                              <defs>
                                <clipPath id="clip-path">
                                  <rect
                                    id="Rectangle_3009"
                                    data-name="Rectangle 3009"
                                    width="84.227"
                                    height="95.107"
                                  />
                                </clipPath>
                              </defs>
                              <g
                                id="Group_10650"
                                data-name="Group 10650"
                                transform="translate(-0.667 0.247)"
                              >
                                <g
                                  id="Group_10649"
                                  data-name="Group 10649"
                                  transform="translate(0.667 -0.247)"
                                  clipPath="url(#clip-path)"
                                >
                                  <path
                                    id="Path_21458"
                                    data-name="Path 21458"
                                    d="M78.9,34.019H76.614V23a1.791,1.791,0,0,0-.019-.208,1.83,1.83,0,0,0-.443-1.207L57.814.638C57.808.632,57.8.631,57.8.625a1.851,1.851,0,0,0-.371-.311c-.04-.027-.08-.049-.122-.073a1.956,1.956,0,0,0-.368-.155c-.035-.009-.066-.023-.1-.032A1.87,1.87,0,0,0,56.409,0H11.344a3.733,3.733,0,0,0-3.73,3.73V34.018H5.331A5.331,5.331,0,0,0,0,39.349V67.071A5.332,5.332,0,0,0,5.331,72.4H7.614V91.377a3.734,3.734,0,0,0,3.73,3.73h61.54a3.735,3.735,0,0,0,3.73-3.73V72.4H78.9a5.332,5.332,0,0,0,5.33-5.33V39.35a5.329,5.329,0,0,0-5.33-5.331M11.344,3.73h43.2V22.812a1.865,1.865,0,0,0,1.865,1.865H72.883v9.343H11.344ZM53.192,61.185v5.409H35.4V38.109h6.468V61.185ZM7.381,66.593l8.242-14.411L7.676,38.109h7.4l2.493,5.2c.846,1.732,1.48,3.128,2.157,4.734h.083c.678-1.818,1.226-3.086,1.944-4.734l2.409-5.2h7.354l-8.031,13.9,8.454,14.58H24.5L21.92,61.438c-1.056-1.987-1.733-3.465-2.535-5.114H19.3c-.592,1.649-1.309,3.129-2.2,5.114l-2.367,5.156H7.381Zm65.5,23.775H11.344V72.4h61.54V90.367Zm-8.916-23.35a18.641,18.641,0,0,1-8.07-1.734l1.308-5.325a16.452,16.452,0,0,0,7.144,1.776c2.959,0,4.522-1.226,4.522-3.085,0-1.776-1.352-2.79-4.776-4.016-4.733-1.649-7.819-4.269-7.819-8.412,0-4.861,4.057-8.579,10.779-8.579a17.48,17.48,0,0,1,7.268,1.437l-1.437,5.2a13.531,13.531,0,0,0-5.959-1.353c-2.789,0-4.141,1.267-4.141,2.748,0,1.817,1.6,2.621,5.282,4.016,5.029,1.859,7.4,4.478,7.4,8.494,0,4.777-3.678,8.834-11.5,8.834"
                                  />
                                </g>
                              </g>
                            </svg>
                          </button>
                          <a
                            href="#"
                            // className="xl_sheet mb-2"
                            style={{
                              display: "inline-flex", // Adjusted for a typical inline behavior for links
                              alignItems: "center",
                              textDecoration: "underline", // Ensures the underline appears
                              color: "#A27B1E",
                              fontWeight: "bold",
                            }}
                            onClick={(e) => {
                              e.preventDefault();
                              handleDownloadClick();
                            }}
                          >
                            Download Sample Excel
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="15"
                              height="15"
                              viewBox="0 0 84.227 95.107"
                              className="xsl_icon"
                              fill="#A27B1E"
                              style={{ marginLeft: "8px" }}
                            >
                              <defs>
                                <clipPath id="clip-path">
                                  <rect
                                    id="Rectangle_3009"
                                    data-name="Rectangle 3009"
                                    width="84.227"
                                    height="95.107"
                                  />
                                </clipPath>
                              </defs>
                              <g
                                id="Group_10650"
                                data-name="Group 10650"
                                transform="translate(-0.667 0.247)"
                              >
                                <g
                                  id="Group_10649"
                                  data-name="Group 10649"
                                  transform="translate(0.667 -0.247)"
                                  clipPath="url(#clip-path)"
                                >
                                  <path
                                    id="Path_21458"
                                    data-name="Path 21458"
                                    d="M78.9,34.019H76.614V23a1.791,1.791,0,0,0-.019-.208,1.83,1.83,0,0,0-.443-1.207L57.814.638C57.808.632,57.8.631,57.8.625a1.851,1.851,0,0,0-.371-.311c-.04-.027-.08-.049-.122-.073a1.956,1.956,0,0,0-.368-.155c-.035-.009-.066-.023-.1-.032A1.87,1.87,0,0,0,56.409,0H11.344a3.733,3.733,0,0,0-3.73,3.73V34.018H5.331A5.331,5.331,0,0,0,0,39.349V67.071A5.332,5.332,0,0,0,5.331,72.4H7.614V91.377a3.734,3.734,0,0,0,3.73,3.73h61.54a3.735,3.735,0,0,0,3.73-3.73V72.4H78.9a5.332,5.332,0,0,0,5.33-5.33V39.35a5.329,5.329,0,0,0-5.33-5.331M11.344,3.73h43.2V22.812a1.865,1.865,0,0,0,1.865,1.865H72.883v9.343H11.344ZM53.192,61.185v5.409H35.4V38.109h6.468V61.185ZM7.381,66.593l8.242-14.411L7.676,38.109h7.4l2.493,5.2c.846,1.732,1.48,3.128,2.157,4.734h.083c.678-1.818,1.226-3.086,1.944-4.734l2.409-5.2h7.354l-8.031,13.9,8.454,14.58H24.5L21.92,61.438c-1.056-1.987-1.733-3.465-2.535-5.114H19.3c-.592,1.649-1.309,3.129-2.2,5.114l-2.367,5.156H7.381Zm65.5,23.775H11.344V72.4h61.54V90.367Zm-8.916-23.35a18.641,18.641,0,0,1-8.07-1.734l1.308-5.325a16.452,16.452,0,0,0,7.144,1.776c2.959,0,4.522-1.226,4.522-3.085,0-1.776-1.352-2.79-4.776-4.016-4.733-1.649-7.819-4.269-7.819-8.412,0-4.861,4.057-8.579,10.779-8.579a17.48,17.48,0,0,1,7.268,1.437l-1.437,5.2a13.531,13.531,0,0,0-5.959-1.353c-2.789,0-4.141,1.267-4.141,2.748,0,1.817,1.6,2.621,5.282,4.016,5.029,1.859,7.4,4.478,7.4,8.494,0,4.777-3.678,8.834-11.5,8.834"
                                  />
                                </g>
                              </g>
                            </svg>
                          </a>
                        </div>

                        {/* Hidden file input */}
                        <input
                          type="file"
                          ref={fileInputRef}
                          style={{ display: "none" }}
                          onChange={handleFileChange}
                        />
                      </div>
                    )}
                  </div>
                  {tabIndex === 1 && (
                    <div
                      className="info-container mt-3"
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      {/* Info Icon */}
                      <div style={{ marginRight: "8px" }}>
                        <i
                          className="fas fa-info-circle"
                          style={{ fontSize: "20px", color: "#A27B1E" }}
                        ></i>
                      </div>
                      {/* Text */}
                      <p
                        style={{
                          margin: 0,
                          fontSize: "14px",
                          color: "#A27B1E",
                        }}
                      >
                        <strong>Note*</strong> YTC or Price (T+1), Minimum Qty,
                        Maximum Qty, Multiplier Qty, ISIN, Settlement (T+1), and
                        Distribution/Trading fields are mandatory in the price
                        upload Excel file.
                      </p>
                    </div>
                  )}
                </TabList>
                <TabPanel>
                  <div className="row">
                    <div className="col-12">
                      <div className="card">
                        <div className="card-body col-sm-12 col-md-12 col-lg-12 w-100">
                          <div
                            className="row"
                            style={{ paddingBottom: "10px" }}
                          >
                            <div className="col-xl-8">
                              <div className="d-flex justify-content-start align-items-center">
                                <h4 className="card-title text-end p-2 m-0">
                                  Search :
                                </h4>
                                <form className="app-search card-title p-2 m-0">
                                  <div className="position-relative">
                                    <input
                                      type="text"
                                      className="select-style-search"
                                      onChange={(e) =>
                                        setSearchText(e.target.value)
                                      }
                                      // placeholder="Bond Name, ISIN Name, Price..."
                                    />
                                  </div>
                                </form>
                              </div>
                            </div>

                            <div className="col-xl-4 text-end">
                              <div className="d-flex justify-content-end align-items-center">
                                <form className="app-search card-title p-2 m-0 w-100">
                                  <div className="position-relative">
                                    <DatePicker
                                      selected={fromDate}
                                      closeOnScroll={true}
                                      onChange={(date: Date) =>
                                        setFromDate(date)
                                      }
                                      placeholderText="DD/MM/YYYY"
                                      className="date-picker-style"
                                      peekNextMonth
                                      showMonthDropdown
                                      showYearDropdown
                                      dropdownMode="select"
                                      customInput={<CustomInput />}
                                    />
                                  </div>
                                </form>
                                <h4 className="card-title p-2 m-0">TO</h4>
                                <form className="app-search card-title p-2 m-0 w-100">
                                  <div className="position-relative">
                                    <DatePicker
                                      selected={toData}
                                      closeOnScroll={true}
                                      onChange={(date: Date) => setToData(date)}
                                      placeholderText="DD/MM/YYYY"
                                      className="date-picker-style"
                                      peekNextMonth
                                      showMonthDropdown
                                      showYearDropdown
                                      dropdownMode="select"
                                      customInput={<CustomInput />}
                                    />
                                  </div>
                                </form>

                                <button
                                  onClick={resetfilter}
                                  className=" btn-suspend text-white"
                                >
                                  Reset
                                </button>
                              </div>
                            </div>
                          </div>

                          <div className="table-rep-plugin">
                            <div
                              className="table-responsive "
                              data-pattern="priority-columns"
                            >
                              <DataTable
                                data={customerEnqurieList}
                                columns={columns}
                                noHeader
                                defaultSortFieldId={"customer_id"}
                                sortIcon={<i className="fas fa-sort"></i>}
                                defaultSortAsc={true}
                                highlightOnHover
                                dense
                                customStyles={customStyles}
                              />
                            </div>
                          </div>
                          {/* <div className="row mt-3">
                            <div className="col-sm-12 col-md-5">
                              {totalCount > 10 ? (
                                <div
                                  className="dataTables_info"
                                  id="datatable_info"
                                  role="status"
                                  aria-live="polite"
                                >
                                  Showing {customerEnqurieList.length} of{" "}
                                  {totalCount} entries
                                </div>
                              ) : (
                                <div
                                  className="dataTables_info"
                                  id="datatable_info"
                                  role="status"
                                  aria-live="polite"
                                >
                                  Showing {totalCount == 0 ? 0 : totalCount}{" "}
                                  entries
                                </div>
                              )}
                            </div>
                            <div className="col-sm-12 col-md-7">
                              <div
                                className="dataTables_paginate paging_simple_numbers float-end mb-3"
                                id="datatable_paginate"
                              >
                                <Pagination
                                  count={Math.ceil(totalCount / 10)}
                                  onChange={(event: any, value: any) => {
                                    setPageNumber(Number(value));
                                    setCurrentPage(Number(value));
                                  }}
                                  variant="outlined"
                                  color="primary"
                                  shape="rounded"
                                  className="pagination-style"
                                />
                              </div>
                            </div>
                          </div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </TabPanel>
                <TabPanel>
                  <div className="row mt-4">
                    <div className="col-12">
                      <div className="card">
                        <div className="card-body col-sm-12 col-md-12 col-lg-12 w-100">
                          <div
                            className="row"
                            style={{ paddingBottom: "10px" }}
                          >
                            <div className="col-xl-8">
                              <div className="d-flex justify-content-start align-items-center">
                                <h4 className="card-title text-end p-2 m-0">
                                  Search :
                                </h4>
                                <form className="app-search card-title p-2 m-0">
                                  <div className="position-relative">
                                    <input
                                      type="text"
                                      className="select-style-search"
                                      onChange={(e) =>
                                        setActiveSearchText(e.target.value)
                                      }
                                      // placeholder="Bond Name, ISIN Name, Price..."
                                    />
                                  </div>
                                </form>
                              </div>
                            </div>

                            {/* <div className="col-xl-4 text-end">
                              <div className="d-flex justify-content-end align-items-center">
                                <form className="app-search card-title p-2 m-0 w-100">
                                  <div className="position-relative">
                                    <DatePicker selected={fromDate} onChange={(date: Date) => setFromDate(date)} placeholderText="DD/MM/YYYY" className="date-picker-style" customInput={<CustomInput />} />
                                  </div>
                                </form>
                                <h4 className="card-title p-2 m-0">TO</h4>
                                <form className="app-search card-title p-2 m-0 w-100">
                                  <div className="position-relative">
                                    <DatePicker selected={toData} onChange={(date: Date) => setToData(date)} placeholderText="DD/MM/YYYY" className="date-picker-style" customInput={<CustomInput />} />
                                  </div>
                                </form>
                              </div>
                            </div> */}
                          </div>

                          <div className="table-rep-plugin">
                            <div
                              className="table-responsive "
                              data-pattern="priority-columns"
                            >
                              {/* <table id="tech-companies-1" className="table table table-bordered">
                                <thead className="city-table">
                                  <tr>
                                    <th>Sr.No</th>
                                    <th>Bond Name</th>
                                    <th>ISIN Number</th>
                                    <th>Start Date</th>
                                    <th>End Date</th>
                                    <th>Price (₹)</th>
                                    <th>Qty</th>
                                    <th>Yeild (%)</th>
                                    <th>Order Type</th>
                                    <th colSpan={3} data-priority="3" className="">
                                      Action
                                    </th>
                                  </tr>
                                </thead>
                                {activeBondList != undefined && activeBondList.length > 0 ? (
                                  <>
                                    {activeBondList.map((res: APIData.ActiveBondList, i: number) => (
                                      <tbody key={i}>
                                        <tr style={{ backgroundColor: `${res.bond_listing_bond_id === activeBondListingBondID && activeBondBg == true ? "#FFE1BE" : ""}` }}>
                                          <td> {i + 1} </td>
                                          <td>{getDisplayData(res.bond_name)}</td>
                                          <td>{getDisplayData(res.bond_isin_number)}</td>
                                          <td>{getFormattedLocalDate(res.bond_listing_start_date)}</td>
                                          <td>{getFormattedLocalDate(res.bond_listing_end_date)}</td>
                                          <td>₹ {numberWithCommas(res.bond_listing_price)}</td>
                                          <td>{getDisplayData(res.bond_listing_quantity)}</td>
                                          <td>{getDisplayData(res.bond_listing_yield)}</td>
                                          <td>{getDisplayData(res.buy_sell_name)}</td>
                                          <td className="city-action-rable-row d-flex align-item-center">
                                            <i className="fas fa-eye table-icons pe-1" onClick={() => navigate(PageLinks.BOND_PRICE_DETAIL_VIEW,{state:{bondISINNumber:res.bond_isin_number,publishMode:res.bond_listing_publish_unpublish,bondID:res.bond_id,ListingID:res.bond_listing_id}})}></i>
                                            {res.bond_listing_publish_unpublish === 0 || res.bond_listing_publish_unpublish === null ? (
                                              <div
                                                onClick={() => {
                                                  handlePublishConfirmationOpen(DealerActiveBondStatus.Publish, res.bond_listing_id,res.bond_listing_bond_id);
                                                }}
                                                style={{ cursor: "pointer" }}
                                              >
                                                <img src="../../assets/images/publish.svg" style={{ width: "15px", margin: "0px 5px", cursor: "pointer" }} />
                                                <span>Publish </span>
                                              </div>
                                            ) : (
                                              <div
                                                onClick={() => {
                                                  handlePublishConfirmationOpen(DealerActiveBondStatus.UnPublish, res.bond_listing_id,res.bond_listing_bond_id);
                                                }}
                                                style={{ cursor: "pointer" }}
                                              >
                                                <img src="../../assets/images/un_publish.svg" style={{ width: "15px", margin: "0px 5px", cursor: "pointer" }} />
                                                <span style={{ cursor: "pointer" }}>Un Publish</span>
                                              </div>
                                            )}
                                          </td>
                                        </tr>
                                      </tbody>
                                    ))}
                                  </>
                                ) : (
                                  <tbody>
                                    <tr className="py-4">
                                      <td className="text-center py-5" colSpan={11}>
                                        No Data Found.
                                      </td>
                                    </tr>
                                  </tbody>
                                )}
                              </table> */}
                              <DataTable
                                data={activeBondList}
                                columns={columns1}
                                noHeader
                                defaultSortFieldId={"customer_id"}
                                sortIcon={<i className="fas fa-sort"></i>}
                                defaultSortAsc={true}
                                highlightOnHover
                                dense
                                customStyles={customStyles}
                              />
                            </div>
                          </div>
                          <div className="row mt-3">
                            <div className="col-sm-12 col-md-5">
                              {totalCountActiveBonds > 10 ? (
                                <div
                                  className="dataTables_info"
                                  id="datatable_info"
                                  role="status"
                                  aria-live="polite"
                                >
                                  Showing {activeBondList.length} of{" "}
                                  {totalCountActiveBonds} entries
                                </div>
                              ) : (
                                <div
                                  className="dataTables_info"
                                  id="datatable_info"
                                  role="status"
                                  aria-live="polite"
                                >
                                  Showing{" "}
                                  {totalCountActiveBonds === 0
                                    ? 0
                                    : totalCountActiveBonds}{" "}
                                  entries
                                </div>
                              )}
                            </div>
                            <div className="col-sm-12 col-md-7">
                              <div
                                className="dataTables_paginate paging_simple_numbers float-end mb-3"
                                id="datatable_paginate"
                              >
                                <Pagination
                                  count={Math.ceil(totalCountActiveBonds / 10)}
                                  onChange={(event: any, value: any) => {
                                    setPageNumberActiveBonds(Number(value));
                                    setCurrentPageActiveBond(Number(value));
                                  }}
                                  variant="outlined"
                                  color="primary"
                                  shape="rounded"
                                  className="pagination-style"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </TabPanel>
                <TabPanel>
                  <div className="row">
                    <div className="col-12">
                      <div className="card">
                        <div className="card-body col-sm-12 col-md-12 col-lg-12 w-100">
                          <div
                            className="row"
                            style={{ paddingBottom: "10px" }}
                          >
                            <div className="col-xl-8">
                              <div className="d-flex justify-content-start align-items-center">
                                <h4 className="card-title text-end p-2 m-0">
                                  Search :
                                </h4>
                                <form className="app-search card-title p-2 m-0">
                                  <div className="position-relative">
                                    <input
                                      type="text"
                                      className="select-style-search"
                                      onChange={(e) =>
                                        setAllBondSearchText(e.target.value)
                                      }
                                      // placeholder="Bond Name, ISIN Name, Price..."
                                    />
                                  </div>
                                </form>
                              </div>
                            </div>
                          </div>

                          <div className="table-rep-plugin">
                            <div
                              className="table-responsive "
                              data-pattern="priority-columns"
                            >
                              {/* <table id="tech-companies-1" className="table table table-bordered">
                                <thead className="city-table">
                                  <tr>
                                    <th>Sr.No</th>
                                    <th>Bond Name</th>
                                    <th>ISIN Name</th>
                                    <th>Instrument Type</th>
                                    <th>ISIN Number</th>
                                    <th>Face Value</th>
                                    <th>Maturity Date</th>
                                    <th>Call Date</th>
                                    <th colSpan={3} data-priority="3" className="">
                                      Action
                                    </th>
                                  </tr>
                                </thead>
                                {allBondList != undefined && allBondList.length > 0 ? (
                                  <>
                                    {allBondList.map((res: APIData.AllBondList, i: number) => (
                                      <tbody key={i}>
                                        <tr>
                                          <td> {i + 1} </td>
                                          <td>{getDisplayData(res.bond_name)}</td>
                                          <td>{getDisplayData(res.bond_issuer_name)}</td>
                                          <td>{getDisplayData(res.bond_nature_of_instrument)}</td>
                                          <td>{getDisplayData(res.bond_isin_number)}</td>
                                          <td>₹ {numberWithCommas(res.bond_face_value)}</td>
                                          <td>{getDisplayData(res.bond_maturity_date)}</td>
                                          <td>{getDisplayData(res.call_date)}</td>
                                          <td className="city-action-rable-row ">
                                            {res.bond_listing_status != null || res.bond_listing_status == 0 ? (
                                              <div>Added</div>
                                            ) : (
                                              <div
                                                onClick={() => {
                                                  handleConfirmationOpen(res.bond_id, res.bond_isin_number);
                                                }}
                                              >
                                                <img src="../../assets/images/add.svg" style={{ width: "15px", margin: "0px 5px", cursor: "pointer" }} />
                                                <span style={{ cursor: "pointer" }}>Add</span>
                                              </div>
                                            )}
                                          </td>
                                        </tr>
                                      </tbody>
                                    ))}
                                  </>
                                ) : (
                                  <tbody>
                                    <tr className="py-4">
                                      <td className="text-center py-5" colSpan={11}>
                                        No Data Found.
                                      </td>
                                    </tr>
                                  </tbody>
                                )}
                              </table> */}
                              <DataTable
                                data={allBondList}
                                columns={columns2}
                                noHeader
                                defaultSortFieldId={"customer_id"}
                                sortIcon={<i className="fas fa-sort"></i>}
                                defaultSortAsc={true}
                                highlightOnHover
                                dense
                                customStyles={customStyles}
                              />
                            </div>
                          </div>
                          <div className="row mt-3">
                            <div className="col-sm-12 col-md-5">
                              {totalCount > 10 ? (
                                <div
                                  className="dataTables_info"
                                  id="datatable_info"
                                  role="status"
                                  aria-live="polite"
                                >
                                  Showing {allBondList?.length} of {totalCount}{" "}
                                  entries
                                </div>
                              ) : (
                                <div
                                  className="dataTables_info"
                                  id="datatable_info"
                                  role="status"
                                  aria-live="polite"
                                >
                                  Showing {totalCount === 0 ? 0 : totalCount}{" "}
                                  entries
                                </div>
                              )}
                            </div>
                            <div className="col-sm-12 col-md-7">
                              <div
                                className="dataTables_paginate paging_simple_numbers float-end mb-3"
                                id="datatable_paginate"
                              >
                                <Pagination
                                  count={Math.ceil(totalCount / 10)}
                                  onChange={(event: any, value: any) => {
                                    setPageNumber(Number(value));
                                    setCurrentPage(Number(value));
                                  }}
                                  variant="outlined"
                                  color="primary"
                                  shape="rounded"
                                  className="pagination-style"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </TabPanel>
              </Tabs>
            </div>
          </div>
        </div>
        <Footer />
      </div>
      <SecuritiesInventory
        handleOpen={() => handleClickOpenModel}
        openModal={open_modals}
        handleClose={(e: any) => handleCloseModel(e)}
        loadData={InventoryAdd}
        listReload={loadBondList}
      />
      <BondEnquiryDetailPopup
        customerData={customerEnqurieData}
        handleOpen={() => handleClickOpen}
        openModal={open}
        handleClose={(e: any) => handleClose(e)}
        listReload={loadCustomerEnquiryList}
      />
      <ConfirmationModal
        contents={confirmContent}
        details_datas={all_datas}
        currentPage={confirmPage}
        openModal={open_modal}
        handleClose={(e: any) => handleConfirmationClosed(e)}
        activeBondListingBondID={activeBondListingBondID}
        user_id={userId}
        listReload={(e: any) => {
          console.log(e);
          loadActiveBondList();
          loadBondList();
          tabReload(e);
        }}
      />
    </div>
  );
};

export default TrustMonyUsers;
