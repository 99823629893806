import Pagination from "@mui/material/Pagination";
import moment from "moment";
import React, { useEffect, useState } from "react";
// import DatePicker from 'react-date-picker';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppStateAPI } from "../common/AppStateAPI";
import DataTable from "react-data-table-component";
import {
  Depository,
  EligibleTagStatus,
  DealerStatus,
  PageLinks,
} from "../common/Constants";
import { APIData } from "../common/DataTypes";
import { toast } from "react-hot-toast";
import Footer from "../common/Footer";
import Header from "../common/Header";
import { ServerAPI } from "../common/ServerAPI";
import {
  getDisplayData,
  getFormattedLocalDateTime,
  getAPIStatus,
} from "../common/Utilities";
import ConfirmationModal from "../modals/Confirmation-modal";
import SimpleModal from "../modals/SimpleModal";

var startSerialNo = 0;
const ApiStatus: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [apistatus, setApistatus] = useState<any[]>([]);
  const [totalCount, setTotalCount] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [search_text, setSearchText] = useState("");
  const { setShowPreloader } = useAppStateAPI();
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const pageCount = Math.ceil(totalCount / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const currPage = currentPage ? currentPage : 1;
  startSerialNo = currPage * 10 - 10;

  let columns: any = [
    {
      name: "Sr.No",
      cell: (row: any, index: any) => startSerialNo + index + 1, //RDT provides index by default
      width: "90px",
    },
    {
      name: "Service Name",
      selector: (row: any) => getDisplayData(row.api_name),
      sortable: true,
      width: "400px",
      wrap: true,
    },
    {
      name: "Last Updated",
      selector: (row: any) => getAPIStatus(row.endpoint_status),
      sortable: true,
    },
  ];

  const customStyles = {
    headCells: {
      style: {
        backgroundColor: "#A27B1E",
        color: "white",
        fontWeight: "bold",
      },
    },
  };

  useEffect(() => {
    APiStatus();
  }, [pageNumber]);

  const APiStatus = () => {
    setShowPreloader(true);
    ServerAPI.APiStatus(pageNumber)
      .then((res) => {
        setApistatus(res.data);
        setTotalCount(res.total_records);
      })
      .finally(() => {
        setShowPreloader(false);
      });
  };
  return (
    <div>
      <div id="layout-wrapper">
        <Header />
        <div className="main-content py-4">
          <div className="page-content">
            <div className="container-fluid">
              <div className="row">
                <div className="col-12">
                  <div className="page-title-box d-sm-flex align-items-center justify-content-between pt-4 pb-0">
                    <div className="page-title-right">
                      <ol className="breadcrumb ">
                        {/* <li className="breadcrumb-item"><a style={{ color: "#90929E" }}>Trustmony</a></li> */}
                        <li className="breadcrumb-item">
                          <a> Masters </a>
                        </li>
                        <li className="breadcrumb-item">
                          <a> Api Status </a>
                        </li>
                      </ol>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <div className="card">
                    <div className="card-body col-sm-12 col-md-12 col-lg-12 w-100">
                      <div className="table-rep-plugin">
                        <div
                          className="table-responsive "
                          data-pattern="priority-columns"
                        >
                          {/* <table id="tech-companies-1" className="table table table-bordered">
                                                        <thead className="city-table">
                                                            <tr>
                                                                <th>Sr.No</th>
                                                                <th>Created Date</th>
                                                                <th>Last Updated</th>
                                                                <th colSpan={5} data-priority="3" className="">Eligible Investor Name</th>
                                                                <th colSpan={3} data-priority="3" className="">Action</th>
                                                            </tr>
                                                        </thead>
                                                        {
                                                            apistatus != undefined && apistatus.length > 0 ?
                                                                <>
                                                                    {
                                                                        apistatus.map((res: APIData.EligibleTagsList, i: number) => (
                                                                            <tbody key={i}>
                                                                                <tr >
                                                                                    <td> {i + 1} </td>
                                                                                    <td>{getFormattedLocalDateTime(res.eligible_investor_created_at)}</td>
                                                                                    <td>{getFormattedLocalDateTime(res.eligible_investor_updated_at)}</td>
                                                                                    <td colSpan={5}>{getDisplayData(res.eligible_investor_name)}</td>

                                                                                    <td className="city-action-rable-row  d-flex ">
                                                                                        <img src='../../assets/images/edit-icon.svg' style={{ width: '6%', margin: "0px 5px" }}
                                                                                            onClick={() => navigate(PageLinks.ELIGIBLE_INVENTOR_ADD, { state: { mode: 'edit', data: res } })}
                                                                                        ></img>
                                                                                        <div className="form-check form-switch mx-2">
                                                                                            <input className="form-check-input " type="checkbox" role="switch" id="dealerStatus"
                                                                                                onChange={(e) => {
                                                                                                    e.target.checked == true ? res.eligible_investor_status = 1 : res.eligible_investor_status = 0;
                                                                                                    EligibleTagList(e.target.checked == true ? DealerStatus.Active : DealerStatus.Disabled, res.eligible_investor_id);
                                                                                                    setApistatus([...apistatus]);
                                                                                                }}
                                                                                                checked={res.eligible_investor_status == EligibleTagStatus.Active ? true : false}
                                                                                            />
                                                                                        </div>
                                                                                    </td>
                                                                                </tr>

                                                                            </tbody>

                                                                        ))
                                                                    }
                                                                </>
                                                                : <tbody>
                                                                    <tr className='py-4'>
                                                                        <td className='text-center py-5' colSpan={11}>No Data Found.</td>
                                                                    </tr>
                                                                </tbody>
                                                        }

                                                    </table> */}
                          <DataTable
                            data={apistatus}
                            columns={columns}
                            noHeader
                            defaultSortFieldId={"api_name"}
                            sortIcon={<i className="fas fa-sort"></i>}
                            defaultSortAsc={true}
                            highlightOnHover
                            dense
                            customStyles={customStyles}
                          />
                        </div>
                      </div>
                      <div className="row mt-3">
                        <div className="col-sm-12 col-md-5">
                          {totalCount > 10 ? (
                            <div
                              className="dataTables_info"
                              id="datatable_info"
                              role="status"
                              aria-live="polite"
                            >
                              Showing {apistatus.length} of {totalCount} entries
                            </div>
                          ) : (
                            <div
                              className="dataTables_info"
                              id="datatable_info"
                              role="status"
                              aria-live="polite"
                            >
                              Showing {totalCount == 0 ? 0 : totalCount} entries
                            </div>
                          )}
                        </div>
                        <div className="col-sm-12 col-md-7">
                          <div
                            className="dataTables_paginate paging_simple_numbers float-end mb-3"
                            id="datatable_paginate"
                          >
                            <Pagination
                              count={Math.ceil(totalCount / 10)}
                              onChange={(event: any, value: any) => {
                                setPageNumber(Number(value));
                                setCurrentPage(Number(value));
                              }}
                              variant="outlined"
                              color="primary"
                              shape="rounded"
                              className="pagination-style"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default ApiStatus;
