import React, { useEffect, useState } from "react";
import Footer from "../common/Footer";
import DataTable from "react-data-table-component";
import Header from "../common/Header";
import { ServerAPI } from "../common/ServerAPI";
import toast from "react-hot-toast";
import { getFormattedLocalDateTime, getFormattedLocalDateTime2 } from "../common/Utilities";

const TermsConditionList = () => {
  const [termsDataList, setTermsDataList] = useState([]);
  const [totalCount, setTotalCount] = useState(null);
  const [uploadedFile, setUploadedFile] = useState(null);
  const [previewURL, setPreviewURL] = useState(null);

  const fetchTermsList = () => {
    ServerAPI.termsList().then((res) => {
      try {
        setTermsDataList(res?.data);
        setTotalCount(res?.data[0]?.pagination?.total_records);
      } catch (error) {
        toast.error(res.error);
      }
    });
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setUploadedFile(file);
      setPreviewURL(URL.createObjectURL(file));
      e.target.value = null;
    }
  };

  const handleFileUpload = () => {
    if (!uploadedFile) {
      toast.error("Please select a file to upload.");
      return;
    }
    ServerAPI.uploadExcelForTerms(uploadedFile)
      .then((res) => {
        if (res?.status === 200) {
          toast.success(res?.message);
          fetchTermsList();
          setUploadedFile(null);
          setPreviewURL(null);
        } else {
          toast.error(res?.message);
        }
      })
      .catch((err) => {
        toast.error("File upload failed. Please try again.");
      });
  };

  const handleFileRemove = () => {
    setUploadedFile(null);
    setPreviewURL(null);
  };

  const customStyles = {
    headCells: {
      style: {
        backgroundColor: "#A27B1E",
        color: "white",
        fontWeight: "bold",
      },
    },
  };

  useEffect(() => {
    fetchTermsList();
  }, []);

  const columns = [
    {
      name: "S.No",
      selector: (row, index) => index + 1,
      sortable: false,
      width: "70px",
    },
    {
      name: "Terms and Conditions",
      selector: (row) => (
        <a
          href={`${row.term_condition_file}`}
          download={row.term_condition_file}
          target="_blank"
          rel="noopener noreferrer"
          style={{ color: "#007bff", textDecoration: "underline" }}
        >
          Download
        </a>
      ),
      sortable: true,
    },
    {
      name: "Status",
      selector: (row) => (row.term_condition_status ? "Active" : "Inactive"),
      sortable: false,
    },
    {
      name: "Version",
      selector: (row) => row.term_condition_version,
      sortable: true,
    },
    {
      name: "Date",
      selector: (row) =>
        getFormattedLocalDateTime2(row.term_condition_created_at),
      sortable: true,
      width: "200px",
    },
  ];

  return (
    <div>
      <div id="layout-wrapper">
        <Header />
        <div className="main-content py-4">
          <div className="page-content">
            <div className="container-fluid">
              <div className="row">
                <div className="col-12">
                  <div className="page-title-box d-sm-flex align-items-center justify-content-between pt-4 pb-0">
                    <div className="page-title-right">
                      <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                          <div>Master</div>
                        </li>
                        <li className="breadcrumb-item active">
                          Terms and Conditions Master
                        </li>
                      </ol>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <div className="card">
                    <div className="card-body col-sm-12 col-md-12 col-lg-12 w-100">
                      <div>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "end",
                            gap: "1rem",
                          }}
                        >
                          <input
                            type="file"
                            accept=".pdf"
                            onChange={handleFileChange}
                            style={{ display: "none" }}
                            id="file-upload"
                          />
                          <label
                            htmlFor="file-upload"
                            className="btn"
                            style={{
                              backgroundColor: "#A27B1E",
                              color: "white",
                              borderRadius: "5px",
                              marginBottom: "2px",
                            }}
                          >
                            Choose File
                            <i
                              className="fa fa-upload mx-1"
                              aria-hidden="true"
                            ></i>
                          </label>
                        </div>
                      </div>

                      {previewURL && (
                        <div
                          style={{
                            marginTop: "1rem",
                            border: "1px solid #ccc",
                            padding: "1rem",
                            borderRadius: "5px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          <div>
                            <h6>File Preview:</h6>
                            <a
                              href={previewURL}
                              target="_blank"
                              rel="noopener noreferrer"
                              style={{ marginRight: "1rem" }}
                            >
                              Open Preview
                            </a>
                          </div>
                          <div className="d-flex">
                            <button className="btn" onClick={handleFileRemove}>
                              <i
                                className="fa fa-trash mx-1"
                                aria-hidden="true"
                              ></i>
                            </button>
                            <button
                              className="btn"
                              onClick={handleFileUpload}
                              disabled={!uploadedFile}
                              style={{
                                backgroundColor: "#A27B1E",
                                color: "white",
                                borderRadius: "5px",
                              }}
                            >
                              Upload File
                              <i
                                className="fa fa-upload mx-1"
                                aria-hidden="true"
                              ></i>
                            </button>
                          </div>
                        </div>
                      )}

                      <div className="table-rep-plugin">
                        <div
                          className="table-responsive"
                          data-pattern="priority-columns"
                        >
                          <DataTable
                            data={termsDataList}
                            columns={columns}
                            noHeader
                            defaultSortFieldId={"customer_id"}
                            sortIcon={<i className="fas fa-sort"></i>}
                            defaultSortAsc={true}
                            highlightOnHover
                            dense
                            customStyles={customStyles}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default TermsConditionList;
