import Pagination from "@mui/material/Pagination";
import moment from "moment";
import React, { useEffect, useState } from "react";
// import DatePicker from 'react-date-picker';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useNavigate } from "react-router-dom";
import { useAppStateAPI } from "../common/AppStateAPI";
import { PageLinks } from "../common/Constants";
import { APIData } from "../common/DataTypes";
import Footer from "../common/Footer";
import Header from "../common/Header";
import DataTable from "react-data-table-component";
import { ServerAPI } from "../common/ServerAPI";
import { getDownloadFileXsl, getIsLogin } from "../common/Utilities";
import {
  getDisplayData,
  getFormattedLocalDateTime,
  numberWithCommas,
} from "../common/Utilities";
import ConfirmationModal from "../modals/Confirmation-modal";
import SimpleModal from "../modals/SimpleModal";
import { saveAs } from "file-saver";

var startSerialNo = 0;
const AllUsersLogs: React.FC = () => {
  const [toData, setToData] = useState<null | Date>();
  const [fromDate, setFromDate] = useState<null | Date>();
  const [userLogList, setUserlogList] = useState<any[]>([]);
  const [totalCount, setTotalCount] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [search_text, setSearchText] = useState("");
  const [select_module, setSelectModule] = useState<any>(null);
  const [module_list, setModuleList] = useState<any[]>([]);
  const { setShowPreloader } = useAppStateAPI();
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const pageCount = Math.ceil(totalCount / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const currPage = currentPage ? currentPage : 1;
  startSerialNo = currPage * 10 - 10;

  let columns: any = [
    {
      name: "Sr.No",
      cell: (row: any, index: any) => startSerialNo + index + 1, //RDT provides index by default
      width: "90px",
    },

    {
      name: "Cust. ID",
      selector: (row: any) => getDisplayData(row.user.user_cust_id),
      sortable: true,
      width: "200px",
    },
    {
      name: "Customer Email",
      selector: (row: any) => getDisplayData(row.user.user_email_address),
      sortable: true,
      width: "200px",
      wrap: true,
    },
    {
      name: "Mobile Number",
      selector: (row: any) => getDisplayData(row.user.user_mobile_number),
      sortable: true,
      width: "200px",
    },

    {
      name: "Updated Date & Time",
      selector: (row: any) =>
        getFormattedLocalDateTime(row.user_log_created_at),
      sortable: true,
      format: (row: any) =>
        moment(row.user_log_created_at).format("DD-MM-YYYY hh:mm A"),
      width: "200px",
    },
    {
      name: "IP Address",
      selector: (row: any) => getDisplayData(row.user_log_ip),
      sortable: true,
      width: "200px",
    },
    {
      name: "Platform",
      selector: (row: any) => getDisplayData(row.user_log_platform),
      sortable: true,
    },
    {
      name: "Module",
      selector: (row: any) => getDisplayData(row.user_log_module),
      sortable: true,
      width: "200px",
    },
    {
      name: "Method",
      selector: (row: any) => getDisplayData(row.user_log_method),
      sortable: true,
    },
    {
      name: "Status",
      selector: (row: any) =>
        getDisplayData(
          row.user_log_status == "200 OK" ? (
            <td style={{ color: "green" }}>Success</td>
          ) : (
            <td style={{ color: "red" }}>Failed</td>
          )
        ),
      sortable: true,
    },
  ];

  const customStyles = {
    headCells: {
      style: {
        backgroundColor: "#A27B1E",
        color: "white",
        fontWeight: "bold",
      },
    },
  };
  const handlePageChange = (page: any) => {
    setCurrentPage(page);
    setPageNumber(page);
  };
  useEffect(() => {
    getIsLogin().then((status) => {
      console.log(status);
      if (!status) {
        navigate(PageLinks.LOGIN);
      }
    });
  }, []);
  const resetfilter = () => {
    setFromDate(null);
    setToData(null);
  };
  useEffect(() => {
    setShowPreloader(true);

    ServerAPI.AllUserLogs(
      pageNumber,
      search_text,
      toData,
      fromDate,
      select_module
    )
      .then((res) => {
        setUserlogList(res?.data[0]?.logs);
        setTotalCount(res?.data[0]?.pagination?.total_records);
      })
      .finally(() => {
        setShowPreloader(false);
      });
  }, [pageNumber, search_text, toData, fromDate, select_module]);

  const handelClickXsl = () => {
    setShowPreloader(true);
    getDownloadFileXsl(
      "https://trust-api.trustmony.com/admin/api/v1/user-logs-xl"
    );
    setShowPreloader(false);
  };

  const CustomInput = React.forwardRef((props: any, ref: any) => {
    return (
      <div className="datePickerStyle">
        <label onClick={props.onClick} ref={ref}>
          {props.value || props.placeholder}
        </label>
        <img
          src="../../assets/images/calender-icon.svg"
          onClick={props.onClick}
          ref={ref}
        />
      </div>
    );
  });

  return (
    <div>
      <div id="layout-wrapper">
        <Header />
        <div className="main-content py-4">
          <div className="page-content">
            <div className="container-fluid">
              <div className="row">
                <div className="col-12">
                  <div className="page-title-box d-sm-flex align-items-center justify-content-between pt-4 pb-0">
                    <div className="page-title-right">
                      <ol className="breadcrumb ">
                        <li className="breadcrumb-item active ">User Logs</li>
                      </ol>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <div className="card">
                    <div className="card-body col-sm-12 col-md-12 col-lg-12 w-100">
                      <div className="row" style={{ paddingBottom: "10px" }}>
                        <div className="col-xl-5">
                          <div className="d-flex justify-content-start align-items-center">
                            <h4 className="card-heading p-2 m-0">User Logs</h4>
                            <h4 className="card-title text-end p-2 m-0">
                              Search :
                            </h4>
                            <form className="app-search card-title p-2 m-0">
                              <div className="position-relative">
                                <input
                                  type="text"
                                  className="select-style-search"
                                  onChange={(e) =>
                                    setSearchText(e.target.value)
                                  }
                                  placeholder="Cust. ID, Customer Name, Mobile ..."
                                />
                              </div>
                            </form>
                          </div>
                        </div>

                        <div className="col-xl-7 text-end">
                          <div className="d-flex justify-content-end align-items-center">
                            {/* <form className="app-search card-title p-2 m-0 w-100">
                              <div className="position-relative ">
                                <select
                                  className="select-style"
                                  name="user_status"
                                  onChange={(e) =>
                                    setSelectModule(e.target.value)
                                  }
                                >
                                  {module_list !== undefined &&
                                    module_list.map((res: any, i: any) => (
                                      <option
                                        key={i}
                                        id={res.user_logs_module_id}
                                        value={res.user_logs_module_name}
                                      >
                                        {res.user_logs_module_name}
                                      </option>
                                    ))}
                                </select>
                              </div>
                            </form> */}
                            <button
                              className="xl_sheet"
                              onClick={handelClickXsl}
                            >
                              Download{" "}
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="15"
                                height="15"
                                viewBox="0 0 84.227 95.107"
                                className="xsl_icon"
                              >
                                <defs>
                                  <clipPath id="clip-path">
                                    <rect
                                      id="Rectangle_3009"
                                      data-name="Rectangle 3009"
                                      width="84.227"
                                      height="95.107"
                                    />
                                  </clipPath>
                                </defs>
                                <g
                                  id="Group_10650"
                                  data-name="Group 10650"
                                  transform="translate(-0.667 0.247)"
                                >
                                  <g
                                    id="Group_10649"
                                    data-name="Group 10649"
                                    transform="translate(0.667 -0.247)"
                                    clip-path="url(#clip-path)"
                                  >
                                    <path
                                      id="Path_21458"
                                      data-name="Path 21458"
                                      d="M78.9,34.019H76.614V23a1.791,1.791,0,0,0-.019-.208,1.83,1.83,0,0,0-.443-1.207L57.814.638C57.808.632,57.8.631,57.8.625a1.851,1.851,0,0,0-.371-.311c-.04-.027-.08-.049-.122-.073a1.956,1.956,0,0,0-.368-.155c-.035-.009-.066-.023-.1-.032A1.87,1.87,0,0,0,56.409,0H11.344a3.733,3.733,0,0,0-3.73,3.73V34.018H5.331A5.331,5.331,0,0,0,0,39.349V67.071A5.332,5.332,0,0,0,5.331,72.4H7.614V91.377a3.734,3.734,0,0,0,3.73,3.73h61.54a3.735,3.735,0,0,0,3.73-3.73V72.4H78.9a5.332,5.332,0,0,0,5.33-5.33V39.35a5.329,5.329,0,0,0-5.33-5.331M11.344,3.73h43.2V22.812a1.865,1.865,0,0,0,1.865,1.865H72.883v9.343H11.344ZM53.192,61.185v5.409H35.4V38.109h6.468V61.185ZM7.381,66.593l8.242-14.411L7.676,38.109h7.4l2.493,5.2c.846,1.732,1.48,3.128,2.157,4.734h.083c.678-1.818,1.226-3.086,1.944-4.734l2.409-5.2h7.354l-8.031,13.9,8.454,14.58H24.5L21.92,61.438c-1.056-1.987-1.733-3.465-2.535-5.114H19.3c-.592,1.649-1.309,3.129-2.2,5.114l-2.367,5.156H7.381Zm65.5,23.775H11.344V72.4h61.54V90.367Zm-8.916-23.35a18.641,18.641,0,0,1-8.07-1.734l1.308-5.325a16.452,16.452,0,0,0,7.144,1.776c2.959,0,4.522-1.226,4.522-3.085,0-1.776-1.352-2.79-4.776-4.016-4.733-1.649-7.819-4.269-7.819-8.412,0-4.861,4.057-8.579,10.779-8.579a17.48,17.48,0,0,1,7.268,1.437l-1.437,5.2a13.531,13.531,0,0,0-5.959-1.353c-2.789,0-4.141,1.267-4.141,2.748,0,1.817,1.6,2.621,5.282,4.016,5.029,1.859,7.4,4.478,7.4,8.494,0,4.777-3.678,8.834-11.5,8.834"
                                    />
                                  </g>
                                </g>
                              </svg>
                            </button>

                            <form className="app-search card-title p-2 m-0 w-100">
                              <div className="position-relative">
                                <DatePicker
                                  selected={fromDate}
                                  onChange={(date: Date) => setFromDate(date)}
                                  placeholderText="DD/MM/YYYY"
                                  className="date-picker-style"
                                  peekNextMonth
                                  showMonthDropdown
                                  showYearDropdown
                                  dropdownMode="select"
                                  customInput={<CustomInput />}
                                />
                              </div>
                            </form>
                            <h4 className="card-title p-2 m-0">TO</h4>
                            <form className="app-search card-title p-2 m-0 w-100">
                              <div className="position-relative">
                                <DatePicker
                                  selected={toData}
                                  onChange={(date: Date) => setToData(date)}
                                  placeholderText="DD/MM/YYYY"
                                  className="date-picker-style"
                                  peekNextMonth
                                  showMonthDropdown
                                  showYearDropdown
                                  dropdownMode="select"
                                  customInput={<CustomInput />}
                                />
                              </div>
                            </form>
                            <button
                              onClick={resetfilter}
                              className=" btn-suspend text-white"
                            >
                              Reset
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="table-rep-plugin">
                        <div
                          className="table-responsive "
                          data-pattern="priority-columns"
                        >
                          <DataTable
                            data={userLogList}
                            columns={columns}
                            noHeader
                            defaultSortFieldId={"user_customer_id"}
                            sortIcon={<i className="fas fa-sort"></i>}
                            defaultSortAsc={true}
                            highlightOnHover
                            dense
                            customStyles={customStyles}
                          />
                        </div>
                      </div>
                      <div className="row mt-3">
                        <div className="col-sm-12 col-md-5">
                          {totalCount > 10 ? (
                            <div
                              className="dataTables_info"
                              id="datatable_info"
                              role="status"
                              aria-live="polite"
                            >
                              Showing {userLogList?.length} of {totalCount}{" "}
                              entries
                            </div>
                          ) : (
                            <div
                              className="dataTables_info"
                              id="datatable_info"
                              role="status"
                              aria-live="polite"
                            >
                              Showing {totalCount == 0 ? 0 : totalCount} entries
                            </div>
                          )}
                        </div>
                        <div className="col-sm-12 col-md-7">
                          <div
                            className="dataTables_paginate paging_simple_numbers float-end mb-3"
                            id="datatable_paginate"
                          >
                            <Pagination
                              count={Math.ceil(totalCount / 10)}
                              onChange={(event: any, value: any) => {
                                setPageNumber(Number(value));
                                setCurrentPage(Number(value));
                              }}
                              variant="outlined"
                              color="primary"
                              shape="rounded"
                              className="pagination-style"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default AllUsersLogs;
