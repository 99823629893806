import React from "react";
import "./orderDetailCom.css";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import PhoneAndroidOutlinedIcon from "@mui/icons-material/PhoneAndroidOutlined";
import {
  getFormattedLocalDate,
  numberWithCommas,
} from "../../common/Utilities";

const OrderDetailComp = ({ orderDetails }: any) => {
  return (
    <div className="order-detail-comp-container">
      <h4 className="today-status-heading">Order Book Details</h4>

      <div className="today-status-container">
        <div className="order-detail-head-1">
          <div className="d-flex">
            <img
              src={
                orderDetails?.security?.security_logo ||
                "/assets/images/default-icon.jpg"
              }
              alt="Logo"
              className="order-detail-img mt-3"
            />
            <div>
              <h3 className="m-3">
                {orderDetails?.security?.issuer?.security_issuer_name || "N/A"}
              </h3>
              <div style={{ marginLeft: "25px" }}>
                <span className="order-detail-buy-color">
                  {" "}
                  {orderDetails?.trade?.trade_name || "N/A"}
                </span>{" "}
                <span className="order-detail-buy-color">
                  / {orderDetails?.exchange?.exchange_name || "N/A"}
                </span>
              </div>
            </div>
          </div>
          {/* <div className="order-detail-orderNo">
            <p>{orderDetails?.security?.security_isin || "N/A"}</p>
          </div> */}
        </div>
        <div className="order-detail-buy-bse"></div>
        {/* <hr></hr> */}
        <div className="today-status-grid">
          {/* {statusArray &&
            Object.entries(statusArray).map(([key, value], idx) => (
              <div key={key} className={`status-item ${statusColors[idx]}`}>
                <span className="status-label">
                  {key
                    .replace(/_/g, " ")
                    .replace(/\b\w/g, (c) => c.toUpperCase())}
                </span>
                <span className="status-value">{value}</span>
              </div>
            ))} */}
        </div>
      </div>
      {orderDetails?.security?.security_type_id == 1 ? (
        <div className="card mt-4">
          <div className="card-header">
            <div className="d-flex" style={{ justifyContent: "space-between" }}>
              <h4>Order Details</h4>

              <div>
                {orderDetails?.order_status == "Order Accepted" && (
                  <span
                    className="rfq-status"
                    style={{
                      background: "#27AC5F",
                      padding: "5px",
                      borderRadius: "5px",
                      color: "#FFFFFF",
                    }}
                  >
                    {orderDetails?.order_status}
                  </span>
                )}

                {orderDetails?.order_status == "Order Pending" && (
                  <span
                    className="rfq-status"
                    style={{
                      background: "#FF0000",
                      padding: "5px",
                      borderRadius: "5px",
                      color: "#FFFFFF",
                    }}
                  >
                    {orderDetails?.order_status}
                  </span>
                )}
                {orderDetails?.order_status == "Order Expired" && (
                  <span
                    className="rfq-status"
                    style={{
                      background: "#FF0000",
                      padding: "5px",
                      borderRadius: "5px",
                      color: "#FFFFFF",
                    }}
                  >
                    {orderDetails?.order_status}
                  </span>
                )}
                {orderDetails?.order_status == "Order Failed" && (
                  <span
                    className="rfq-status"
                    style={{
                      background: "#FF0000",
                      padding: "5px",
                      borderRadius: "5px",
                      color: "#FFFFFF",
                    }}
                  >
                    {orderDetails?.order_status}
                  </span>
                )}
              </div>
            </div>
          </div>
          <div className="card-body">
            <div className="row">
              <>
                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                  <p className="detail-title">Deal Reference Number</p>
                  <h6>{orderDetails?.order_number || "NA"}</h6>
                </div>
                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                  <p className="detail-title">Purchase Date</p>
                  <h6>
                    {getFormattedLocalDate(orderDetails?.order_datetime) ||
                      "NA"}
                  </h6>
                </div>
                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                  <p className="detail-title">Settlement Date</p>
                  <h6>
                    {getFormattedLocalDate(
                      orderDetails?.order_settlement_date
                    ) || "NA"}
                  </h6>
                </div>
                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                  <p className="detail-title">Maturity Date</p>
                  <h6>
                    {getFormattedLocalDate(
                      orderDetails?.security?.security_maturity_date
                    ) || "NA"}
                  </h6>
                </div>
                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                  <p className="detail-title">No of Units</p>
                  <h6>{orderDetails?.order_quantity || "NA"}</h6>
                </div>
                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                  <p className="detail-title">Stamp Duty</p>
                  <h6>
                    {orderDetails?.order_stamp_duty != null
                      ? `₹ ${orderDetails?.order_stamp_duty}`
                      : "N/A"}
                  </h6>
                </div>
                {/* <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                  <p className="detail-title">Price</p>
                  <h6>
                    ₹ {numberWithCommas(orderDetails?.order_price) || "NA"}
                  </h6>
                </div> */}
                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                  <p className="detail-title">Total Purchase Consideration</p>
                  <h6>
                    ₹ {numberWithCommas(orderDetails?.order_value) || "NA"}
                  </h6>
                </div>
                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                  <p className="detail-title">Total Amount Payable</p>
                  <h6>
                    ₹ {numberWithCommas(orderDetails?.order_value) || "NA"}
                  </h6>
                </div>

                <div className="col-sm-12 col-md-12 col-lg-12"></div>
              </>
            </div>
          </div>
        </div>
      ) : (
        <>
          <div className="d-flex gap-1">
            <div className="col-lg-6 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
              {" "}
              <div className="card mt-4">
                <div className="card-header">
                  <div
                    className="d-flex"
                    style={{ justifyContent: "space-between" }}
                  >
                    <h4>Order Details</h4>

                    <div>
                      {orderDetails?.order_status == "Order Accepted" && (
                        <span
                          className="rfq-status"
                          style={{
                            background: "#27AC5F",
                            padding: "5px",
                            borderRadius: "5px",
                            color: "#FFFFFF",
                          }}
                        >
                          {orderDetails?.order_status}
                        </span>
                      )}

                      {orderDetails?.order_status == "Order Pending" && (
                        <span
                          className="rfq-status"
                          style={{
                            background: "#FF0000",
                            padding: "5px",
                            borderRadius: "5px",
                            color: "#FFFFFF",
                          }}
                        >
                          {orderDetails?.order_status}
                        </span>
                      )}
                      {orderDetails?.order_status == "Order Expired" && (
                        <span
                          className="rfq-status"
                          style={{
                            background: "#FF0000",
                            padding: "5px",
                            borderRadius: "5px",
                            color: "#FFFFFF",
                          }}
                        >
                          {orderDetails?.order_status}
                        </span>
                      )}
                      {orderDetails?.order_status == "Order Failed" && (
                        <span
                          className="rfq-status"
                          style={{
                            background: "#FF0000",
                            padding: "5px",
                            borderRadius: "5px",
                            color: "#FFFFFF",
                          }}
                        >
                          {orderDetails?.order_status}
                        </span>
                      )}
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  <div className="row">
                    <>
                      <div className="col-lg-4 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                        <p className="detail-title">Deal Reference Number</p>
                        <h6>{orderDetails?.order_number || "NA"}</h6>
                      </div>
                      <div className="col-lg-4 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                        <p className="detail-title">Purchase Date</p>
                        <h6>
                          {getFormattedLocalDate(
                            orderDetails?.order_datetime
                          ) || "NA"}
                        </h6>
                      </div>
                      <div className="col-lg-4 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                        <p className="detail-title">Settlement Date</p>
                        <h6>
                          {getFormattedLocalDate(
                            orderDetails?.order_settlement_date
                          ) || "NA"}
                        </h6>
                      </div>
                      <div className="col-lg-4 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                        <p className="detail-title">Maturity Date</p>
                        <h6>
                          {getFormattedLocalDate(
                            orderDetails?.security?.security_maturity_date
                          ) || "NA"}
                        </h6>
                      </div>
                      <div className="col-lg-4 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                        <p className="detail-title">No of Units</p>
                        <h6>{orderDetails?.order_quantity || "NA"}</h6>
                      </div>
                      <div className="col-lg-4 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                        <p className="detail-title">Stamp Duty</p>
                        <h6>
                          {orderDetails?.order_stamp_duty != null
                            ? `₹ ${orderDetails?.order_stamp_duty}`
                            : "N/A"}
                        </h6>
                      </div>
                      <div className="col-lg-4 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                        <p className="detail-title">Price</p>
                        <h6>
                          ₹{" "}
                          {numberWithCommas(orderDetails?.order_price) || "NA"}
                        </h6>
                      </div>
                      <div className="col-lg-4 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                        <p className="detail-title">
                          Total Purchase Consideration
                        </p>
                        <h6>
                          ₹{" "}
                          {numberWithCommas(orderDetails?.order_value) || "NA"}
                        </h6>
                      </div>
                      <div className="col-lg-4 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                        <p className="detail-title">Total Amount Payable</p>
                        <h6>
                          ₹{" "}
                          {numberWithCommas(orderDetails?.order_value) || "NA"}
                        </h6>
                      </div>

                      <div className="col-sm-12 col-md-12 col-lg-12"></div>
                    </>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
              {" "}
              <div className="card mt-4">
                <div className="card-header">
                  <div
                    className="d-flex"
                    style={{ justifyContent: "space-between" }}
                  >
                    <h4>RFQ Order Details</h4>

                    <div>
                      {orderDetails?.order_status == "RFQ Order Accepted" && (
                        <span
                          className="rfq-status"
                          style={{
                            background: "#27AC5F",
                            padding: "5px",
                            borderRadius: "5px",
                            color: "#FFFFFF",
                          }}
                        >
                          {orderDetails?.order_status}
                        </span>
                      )}
                      {orderDetails?.order_status == "RFQ Order Rejected" && (
                        <span
                          className="rfq-status"
                          style={{
                            background: "#FF0000",
                            padding: "5px",
                            borderRadius: "5px",
                            color: "#FFFFFF",
                          }}
                        >
                          {orderDetails?.order_status}
                        </span>
                      )}
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  <div className="row">
                    <>
                      <div className="col-lg-4 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                        <p className="detail-title">RFQ Settlement</p>
                        <h6>{orderDetails?.exchange?.exchange_name || "NA"}</h6>
                      </div>
                      <div className="col-lg-4 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                        <p className="detail-title">RFQ Order Number</p>
                        <h6>{orderDetails?.order_rfq_number || "NA"}</h6>
                      </div>
                      <div className="col-lg-4 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                        <p className="detail-title">Accured Intrest</p>
                        <h6>
                          {numberWithCommas(orderDetails?.order_interest) ||
                            "NA"}
                        </h6>
                      </div>
                      <div className="col-lg-4 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                        <p className="detail-title">RFQ Deal date & time</p>
                        <h6>
                          {getFormattedLocalDate(
                            orderDetails?.order_deal_date
                          ) || "NA"}
                        </h6>
                      </div>
                      <div className="col-lg-4 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                        <p className="detail-title">Yield</p>
                        <h6>
                          {numberWithCommas(orderDetails?.order_yield) || "NA"}
                        </h6>
                      </div>
                      <div className="col-lg-4 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                        <p className="detail-title">Stamp Duty</p>
                        <h6>
                          {orderDetails?.order_stamp_duty != null
                            ? `₹ ${orderDetails?.order_stamp_duty}`
                            : "N/A"}
                        </h6>
                      </div>
                      <div className="col-lg-4 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                        <p className="detail-title">
                          Total Purchase Consideration
                        </p>
                        <h6>
                          ₹{" "}
                          {numberWithCommas(orderDetails?.order_value) || "NA"}
                        </h6>
                      </div>
                      <div className="col-lg-4 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                        <p className="detail-title">Total Amount Payable</p>
                        <h6>
                          ₹{" "}
                          {numberWithCommas(orderDetails?.order_value) || "NA"}
                        </h6>
                      </div>

                      <div className="col-sm-12 col-md-12 col-lg-12"></div>
                    </>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="card">
            <div className="card-header">
              <div
                className="d-flex"
                style={{ justifyContent: "space-between" }}
              >
                <h4>User Details</h4>
              </div>
            </div>
            <div className="card-body">
              <div className="row">
                <>
                  <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                    <p className="detail-title">User Name</p>
                    <h6>{orderDetails?.user?.personal?.personal_fullname}</h6>
                  </div>
                  <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                    <p className="detail-title">Email ID</p>
                    <h6>{orderDetails?.user?.user_email_address || "NA"}</h6>
                  </div>
                  <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                    <p className="detail-title">Mobile No</p>
                    <h6>
                      +91 {orderDetails?.user?.user_mobile_number || "NA"}
                    </h6>
                  </div>
                  <div className="col-sm-12 col-md-12 col-lg-12"></div>
                </>
              </div>
            </div>
          </div>
        </>
      )}

      {/* <div className="my-4">
        <hr />
      </div> */}
      {/* <div className="card">
        <div className="card-header">
          <h4>User Details</h4>
        </div>
        <div className="order-detail-cust-info-container m-3">
          <div className="order-detail-cust-info">
            <div className="order-circle">
              <p className="mt-3">
                {orderDetails?.user?.personal?.personal_fullname?.slice(0, 1)}
              </p>
            </div>
            <h4>{orderDetails?.user?.personal?.personal_fullname}</h4>
          </div>
          <div
            className="order-detail-cust-info"
            style={{ marginLeft: "10px" }}
          >
            <EmailOutlinedIcon
              fontSize="small"
              sx={{
                color: "#C5942B",
              }}
            />
            <p className="mt-3">
              {orderDetails?.user?.user_email_address || "NA"}
            </p>
          </div>
          <div className="order-detail-cust-info" style={{ marginLeft: "9px" }}>
            <PhoneAndroidOutlinedIcon
              fontSize="small"
              sx={{
                color: "#C5942B",
              }}
            />
            <p className="mt-3">
              +91 {orderDetails?.user?.user_mobile_number || "NA"}
            </p>
          </div>
        </div>
      </div> */}

      {/* <div className="my-4">
        <hr />
      </div> */}
      {orderDetails?.payment?.payment_mode?.payment_mode_uid == "UPI" ? (
        <div className="card">
          <div className="card-header">
            <h4>Razorpay Payment Details</h4>
          </div>
          <div className="card-body">
            <div className="row">
              <>
                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                  <p className="detail-title">Payment Order Number</p>
                  <h6>{orderDetails?.order_number || "NA"}</h6>
                </div>
                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                  <p className="detail-title">Payment Pay ID</p>
                  <h6>{orderDetails?.payment?.payment_rp_id || "NA"}</h6>
                </div>
                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                  <p className="detail-title">Payment Date</p>
                  <h6>
                    {getFormattedLocalDate(
                      orderDetails?.payment?.payment_created_at
                    ) || "NA"}
                  </h6>
                </div>
                <div className="col-sm-12 col-md-12 col-lg-12"></div>
              </>
            </div>
          </div>
        </div>
      ) : (
        <div className="card">
          <div className="card-header">
            <h4>Bank Details</h4>
          </div>
          <div className="card-body">
            <div className="row">
              <>
                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                  <p className="detail-title">Bank Name</p>
                  <h6>
                    {orderDetails?.payment?.bank?.bank_provider_name || "NA"}
                  </h6>
                </div>
                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                  <p className="detail-title">Account Number</p>
                  <h6>
                    {orderDetails?.payment?.bank?.bank_account_number || "NA"}
                  </h6>
                </div>
                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                  <p className="detail-title">IFSC Code</p>
                  <h6>
                    {orderDetails?.payment?.bank?.bank_ifsc_number || "NA"}
                  </h6>
                </div>
                <div className="col-sm-12 col-md-12 col-lg-12"></div>
              </>
            </div>
          </div>
        </div>
      )}
      <div className="card">
        <div className="card-header">
          <h5 className="m-0 header">Price Details</h5>
        </div>
        <div className="card-body">
          <div className="table-rep-plugin">
            <div className="table-responsive" data-pattern="priority-columns">
              <table id="tech-companies-1" className="table table-bordered">
                <thead className="city-table">
                  <tr>
                    <th className="text-center">S.No</th>
                    <th className="text-center">Series</th>
                    <th className="text-center">Returns</th>
                    <th className="text-center">Tenure</th>
                    <th className="text-center">Series Price</th>
                    <th className="text-center">Quantity</th>
                    <th className="text-center">Total Price</th>
                  </tr>
                </thead>
                <tbody>
                  {orderDetails?.order_series.map((val: any, index: any) => (
                    <tr key={`bank-${index}`}>
                      <td className="text-center">{index + 1}</td>
                      <td className="text-center">{val?.order_series_name}</td>
                      <td className="text-center">{val?.order_series_yield}</td>
                      <td className="text-center">
                        {val?.order_series_tenure}
                      </td>
                      <td className="text-center">{val?.order_series_price}</td>
                      <td className="text-center">
                        {val?.order_series_quantity}
                      </td>
                      <td className="text-center">
                        {val?.order_series_redemption_amount}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="card">
        <div className="card-header">
          <h4>Payment Details</h4>
        </div>
        <div className="order-detail-cust-info-container m-3">
          <div className="d-flex gap-3">
            {orderDetails?.payment?.payment_mode?.payment_mode_uid != "UPI" ? (
              <div className="w-50 my-3">
                <h4>Bank Details</h4>
                <p className="mt-2">
                  Bank Name:{" "}
                  {`${orderDetails?.payment?.bank?.bank_provider_name || "NA"}`}
                </p>
                <p>
                  Accout Numner:{" "}
                  {`${
                    orderDetails?.payment?.bank?.bank_account_number || "NA"
                  }`}
                </p>
                <p>
                  IFSC Code:{" "}
                  {`${orderDetails?.payment?.bank?.bank_ifsc_number || "NA"}`}
                </p>
              </div>
            ) : (
              <div className="w-50 my-3">
                <h4>Razorpay Payment Details</h4>
                <p className="mt-2">
                  Payment Order Number:{" "}
                  <span className="text-danger">{`${
                    orderDetails?.order_number || "NA"
                  }`}</span>
                </p>
                <p>
                  Payment Pay ID:{" "}
                  <span className="text-danger">{`${
                    orderDetails?.payment?.payment_rp_id || "NA"
                  }`}</span>
                </p>
                <p>
                  Payment Date:{" "}
                  <span className="text-danger">{`${
                    getFormattedLocalDate(
                      orderDetails?.payment?.payment_created_at
                    ) || "NA"
                  }`}</span>
                </p>
              </div>
            )}
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default OrderDetailComp;
