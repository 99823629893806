import React, { useEffect, useRef, useState } from "react";
import { Formik, Form, FormikProps } from "formik";
import * as Yup from "yup";
import { useNavigate, useLocation } from "react-router-dom";
import { Grid, TextField, Switch } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import toast from "react-hot-toast";
import { ServerAPI } from "../common/ServerAPI";
import { PageLinks } from "../common/Constants";
import { useAppStateAPI } from "../common/AppStateAPI";
import Header from "../common/Header";
import Footer from "../common/Footer";

// Validation Schema
const yupSchema = Yup.object().shape({
  depository_participant_name: Yup.string().required("DP Name is required"),
});

const AddDPName: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { setShowPreloader } = useAppStateAPI();
  const formikRef = useRef<FormikProps<any>>(null);

  // State for Formik Initial Values
  const [formikInitialData, setFormikInitialData] = useState({
    depository_participant_name: "",
    depository_participant_status: false,
  });

  useEffect(() => {
    if (location?.state?.mode === "edit") {
      setFormikInitialData(location?.state?.data);
    }
  }, [location]);

  const submitData = (data: any, setSubmitting: any) => {
    console.log("Submitted Data:", data);

    setShowPreloader(true);
    const apiCall =
      location?.state?.mode === "edit"
        ? ServerAPI.putDPName
        : ServerAPI.postDPName;

    apiCall(data)
      .then((res) => {
        toast.success(res.message);
        navigate(PageLinks.DP_NAME, { state: res.id });
        setSubmitting(false);
      })
      .finally(() => setShowPreloader(false));
  };

  return (
    <div id="layout-wrapper">
      <Header />
      <div className="main-content py-4">
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-sm-flex align-items-center justify-content-between pt-4 pb-0">
                  <div className="page-title-right">
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item">
                        {location?.state?.mode === "edit" ? "Edit" : "Add"} DP
                        Name
                      </li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>

            {/* Form Card */}
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-body col-sm-12 col-md-12 col-lg-12 w-100">
                    <Formik
                      validationSchema={yupSchema}
                      enableReinitialize={true}
                      validateOnChange={false}
                      initialValues={formikInitialData}
                      innerRef={formikRef}
                      onSubmit={(values, { setSubmitting }) =>
                        submitData(values, setSubmitting)
                      }
                    >
                      {(formikProps) => (
                        <Form onSubmit={formikProps.handleSubmit}>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <div className="row">
                              <div
                                className="card"
                                style={{
                                  borderTopLeftRadius: "10px",
                                  borderTopRightRadius: "10px",
                                  paddingTop: "10px",
                                  paddingBottom: "20px",
                                }}
                              >
                                <div className="card-header d-flex justify-content-between">
                                  <h5 className="m-0 header">
                                    {location?.state?.mode === "edit"
                                      ? "Edit"
                                      : "Add"}{" "}
                                    DP Name
                                  </h5>
                                  <div className="form-check form-switch mx-2">
                                    <label
                                      className="form-check-label"
                                      htmlFor="statusToggle"
                                    >
                                      Disable/Enable
                                    </label>

                                    <Switch
                                      id="statusToggle"
                                      checked={
                                        formikProps.values
                                          .depository_participant_status ||
                                        false
                                      }
                                      onChange={(e) =>
                                        formikProps.setFieldValue(
                                          "depository_participant_status",
                                          e.target.checked
                                        )
                                      }
                                    />
                                  </div>
                                </div>

                                <div className="card-body">
                                  <Grid container spacing={2}>
                                    <Grid item xs={4}>
                                      <div className="d-grid">
                                        <label
                                          htmlFor="dp_name"
                                          className="bond-label"
                                        >
                                          DP Name
                                        </label>
                                        <TextField
                                          id="dp_name"
                                          name="depository_participant_name"
                                          type="text"
                                          className="bond-input"
                                          value={
                                            formikProps.values
                                              .depository_participant_name
                                          }
                                          onChange={formikProps?.handleChange}
                                          fullWidth
                                        />
                                        {formikProps.errors
                                          ?.depository_participant_name && (
                                          <div style={{ color: "#FF0000" }}>
                                            {String(
                                              formikProps.errors
                                                .depository_participant_name
                                            )}
                                          </div>
                                        )}
                                      </div>
                                    </Grid>
                                  </Grid>
                                </div>
                              </div>
                            </div>

                            {/* Form Buttons */}
                            <div className="row text-end my-3 py-5">
                              <div>
                                <a
                                  className="cancel-btn mx-3"
                                  onClick={() => navigate(-1)}
                                >
                                  Cancel
                                </a>
                                <button type="submit" className="save-btn">
                                  {location?.state?.mode === "edit"
                                    ? "Update"
                                    : "Save"}
                                </button>
                              </div>
                            </div>
                          </LocalizationProvider>
                        </Form>
                      )}
                    </Formik>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default AddDPName;
