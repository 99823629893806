import Pagination from "@mui/material/Pagination";
import { Field, FieldArray, Form, Formik, FormikProps } from "formik";
import moment from "moment";
import React, {
  Fragment,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
// import DatePicker from 'react-date-picker';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useAppStateAPI } from "../common/AppStateAPI";
import * as Utilities from "../common/Utilities";
import {
  BondIPOStatus,
  Depository,
  NCDDetailsList,
  NCDStatus,
  PageLinks,
  ShortTermList,
  Strings,
  wysiwygToolbar,
} from "../common/Constants";
import Footer from "../common/Footer";
import Header from "../common/Header";
import { ServerAPI } from "../common/ServerAPI";
import ConfirmationModal from "../modals/Confirmation-modal";
import SimpleModal from "../modals/SimpleModal";
import * as Yup from "yup";
import { Grid, Input, SvgIcon } from "@mui/material";
import FormField from "./form_items/FormField";
import { APIData, FormDataTypes } from "../common/DataTypes";
import dayjs, { Dayjs } from "dayjs";
import TextField from "@mui/material/TextField";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import {
  EditorState,
  convertToRaw,
  convertFromRaw,
  convertFromHTML,
  ContentState,
} from "draft-js";
import draftToHtml from "draftjs-to-html";
import { InitialData } from "../common/InitialValue";
import BondDetails from "./BondDetails";
import toast from "react-hot-toast";
import {
  DropZone,
  frameSelectOptions,
  getURLExtension,
  localStringToIST,
} from "../common/Utilities";
import { useDropzone } from "react-dropzone";
import { saveAs } from "file-saver";

var dmp: string = "";
var dmpIndex: number = 0;

const IPOAdd: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const passing_value = location.state;
  const [preData, setPreData] = useState(passing_value);

  // const [value, onChange] = useState(new Date());
  const [toData, setToData] = useState<null | Date>();
  const [fromDate, setFromDate] = useState<null | Date>();
  const [open, setOpen] = useState(false);
  const [open_modal, setOpenModal] = useState(false);
  const [userList, setUserList] = useState<any[]>([]);
  const [totalCount, setTotalCount] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [user_id, setUserId] = useState();
  const [view_content, setViewContent] = useState();
  const [search_text, setSearchText] = useState("");
  const { setShowPreloader } = useAppStateAPI();
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [removedDocuments, setRemovedDocuments] = useState<string[]>([]);
  const [formikInitialData, setFormikInitialData] = useState<APIData.IPOAdd>(
    InitialData.IPOAdd
  );
  const [shortTermList, setShortTermList] = useState<any[]>(ShortTermList);

  const [addDisclamer, setAddDisclamer] = useState<any | undefined>(
    EditorState.createEmpty()
  );
  const [addDisclamerText, setAddDisclamerText] = useState("");
  const [addKeyHeighlights, setAddKeyHeighlights] = useState<any | undefined>(
    EditorState.createEmpty()
  );
  const [addIssuerAbout, setAddIssuerAbout] = useState<any | undefined>(
    EditorState.createEmpty()
  );
  const fileInputRefLogo = useRef<HTMLInputElement>(null);
  const fileInputRefForm = useRef<HTMLInputElement>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const fileInputRefIssuerDoc = useRef<HTMLInputElement>(null);
  const formikRef = useRef<FormikProps<APIData.IPOAdd>>(null);
  console.log("🚀 ~ file: IPOAdd.tsx:66 ~ formikRef:", formikRef);
  const [uploadFeild, setUploadFeild] = useState<{
    feild_type: string;
    inx: number;
  }>({ feild_type: "", inx: 0 });
  const [bondID, setBondID] = useState(0);
  const [selectedBrandTag, setSelectedBrandTag] = useState<
    APIData.BondBrandTags[]
  >([]);
  const [bondRatingOptions, setBondRatingOptions] = useState<
    FormDataTypes.SelectOption[]
  >([]);
  const [dynamicText, setDynamicText] = useState("");
  const [Highlight, setHighlight] = useState("");
  const [rows, setRows] = useState<any[]>([]);

  const handleAddRow = (series: any) => {
    if (!categoryList || categoryList.length === 0) {
      toast.error("Please add a category for this IPO"); // Display error if categoryList is empty
      return;
    }

    if (rows.length >= categoryList.length) {
      toast.error("Categories for this IPO Exist"); // Prevent adding more rows than the category list length
      return;
    }

    setRows((prevRows) => [
      ...prevRows,
      {
        category_id: "",
        security_id: location?.state?.id,
        series: series?.map((item: any) => ({
          security_series_id: item?.bond_ncd_series_id,
          security_category_series_value: "",
        })),
      },
    ]);
  };

  // Handler for deleting a row
  const handleDeleteRow = (index: number) => {
    const itemToDelete = rows[index];

    // Check if the item has the 'security_category_id' key
    if (itemToDelete.hasOwnProperty("security_category_id")) {
      // console.log();
      ServerAPI.deletIPOBrokerageCategory(itemToDelete.security_category_id)
        .then((res) => {
          try {
            toast.success(res.message);
            loadIntitalIPOBrokerage(location?.state?.id);
          } catch (error) {
            toast.error(res.error);
          }
        })
        .finally(() => {
          setShowPreloader(false);
        });
    } else {
      // If the key doesn't exist, delete the row
      setRows((prevRows) => prevRows.filter((_, i) => i !== index));
    }
  };
  useEffect(() => {
    dmp = uploadFeild.feild_type;
    dmpIndex = uploadFeild.inx;
  }, [uploadFeild]);

  // const onDisclamerEditorStateChange = (editorState: any) => {
  //   const rawContentState = convertToRaw(editorState.getCurrentContent());
  //   const htmlDescription = draftToHtml(rawContentState);
  //   const htmlResult = htmlDescription.replace(/(<([^>]+)>)/gi, "");
  //   setAddDisclamer(editorState);
  //   // setAddDisclamerText(htmlResult);
  //   setFormikInitialData({ ...formikInitialData, bond_disclaimer: htmlResult });
  // };
  const onDisclamerEditorStateChange = (editorState: any) => {
    const rawContentState = convertToRaw(editorState.getCurrentContent());
    const htmlDescription = draftToHtml(rawContentState); // Convert to HTML string

    setAddDisclamer(editorState); // Set editor state
    setFormikInitialData({
      ...formikInitialData,
      bond_disclaimer: htmlDescription, // Store the HTML string with formatting
    });
  };
  useEffect(() => {
    // Fetch your existing data here and convert it to ContentState
    // const existingData = {
    //   blocks: [
    //     {
    //       key: "abcde",
    //       text: dynamicText,
    //       type: "unstyled",
    //       depth: 0,
    //       inlineStyleRanges: [],
    //       entityRanges: [],
    //       data: {},
    //     },
    //   ],
    //   entityMap: {},
    // };
    // const contentState = convertFromRaw(existingData);

    const initialHTMLContent = dynamicText ?? "";

    // Convert HTML to Draft.js ContentState
    const blocksFromHTML = convertFromHTML(initialHTMLContent);
    const contentState = ContentState.createFromBlockArray(
      blocksFromHTML.contentBlocks
    );

    // Set the converted contentState as the initial editorState
    setAddDisclamer(EditorState.createWithContent(contentState));
    setFormikInitialData({ ...formikInitialData });
  }, [dynamicText]); // Empty dependency array, runs only once on mount

  // const onKeyHeighlightsEditorStateChange = (editorState: any) => {
  //   const rawContentState = convertToRaw(editorState.getCurrentContent());
  //   const htmlDescription = draftToHtml(rawContentState);
  //   const htmlResult = htmlDescription.replace(/(<([^>]+)>)/gi, "");
  //   setAddKeyHeighlights(editorState);
  //   setFormikInitialData({
  //     ...formikInitialData,
  //     bond_key_highlights: htmlResult,
  //   });
  // };
  const onKeyHeighlightsEditorStateChange = (editorState: any) => {
    const rawContentState = convertToRaw(editorState.getCurrentContent());
    const htmlDescription = draftToHtml(rawContentState); // Convert to HTML string

    setAddKeyHeighlights(editorState); // Set the editor state
    setFormikInitialData({
      ...formikInitialData,
      bond_key_highlights: htmlDescription, // Store the full HTML string
    });
  };

  const handleFileChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    uploadDocFor: any
  ) => {
    // console.log("sdffsdfsf", uploadDocFor);

    const files = event.target.files;

    if (files && files.length > 0) {
      const file = files[0];

      // Log the selected file details
      setSelectedFile(file);
      handleUploadClick(file, uploadDocFor);
    }

    // Reset file input to allow reselecting the same file
    event.target.value = "";
  };

  const handleUploadClick = (file: File | null, uploadDocFor: any) => {
    // console.log("sdfsfsdfsd", uploadDocFor);

    if (file) {
      // Perform API call
      setShowPreloader(true);

      ServerAPI.uploadIssuerFiles(file)
        .then((res) => {
          if (res.status === 200) {
            // Handle success
            console.log("File uploaded successfully, URL:", res.data[0].url);
            if (uploadDocFor === "issuerDoc") {
              console.log(formikInitialData.bond_security_document);

              // Access the first (and only) issuer details
              const issuerDetails = formikInitialData.bond_issuer_details[0];

              // Ensure bond_issuer_documents is an array (default to empty array if undefined)
              const bondIssuerDocuments =
                issuerDetails?.bond_issuer_documents || [];

              // Add the new document to the array
              const updatedIssuerDocuments = [
                ...bondIssuerDocuments,
                {
                  security_issuer_document_name: null,
                  security_issuer_document_file: res.data[0].url, // Add the new document URL
                  security_issuer_document_id: null,
                  security_issuer_id: null,
                  security_issuer_document_status: false,
                },
              ];

              // Update bond_issuer_documents for the first (and only) item in bond_issuer_details
              setFormikInitialData((prevState: any) => ({
                ...prevState,
                bond_issuer_details: [
                  {
                    ...issuerDetails, // Spread the current first item
                    bond_issuer_documents: updatedIssuerDocuments, // Update bond_issuer_documents with the new document
                  },
                ],
              }));
            }
            if (uploadDocFor === "security_document") {
              const updatedIssuerDocuments = [
                {
                  security_document_name: null,
                  security_document_file: res.data[0].url, // Replace the document URL for the 0th position
                },
              ];
              setFormikInitialData((prevState: any) => ({
                ...prevState,
                bond_security_document: updatedIssuerDocuments,
              }));
            }
            if (uploadDocFor == "security_logo") {
              setFormikInitialData((prevState) => ({
                ...prevState,
                bond_logo: res.data[0].url,
              }));
            }
            if (uploadDocFor == "security_form") {
              setFormikInitialData((prevState) => ({
                ...prevState,
                bond_form: res.data[0].url,
              }));
            }
            // if (uploadDocFor == "security_document") {
            //   setFormikInitialData((prevState) => ({
            //     ...prevState,
            //     bond_logo: res.data[0].url,
            //   }));
            // }
            if (uploadDocFor == "security_logo") {
              setFormikInitialData((prevState) => ({
                ...prevState,
                bond_logo: res.data[0].url,
              }));
            }
            if (uploadDocFor == "rating_certificate") {
              setFormikInitialData((prevState) => ({
                ...prevState,
                security_logo: res.data[0].url,
              }));
            }

            // Get existing documents and filter out the removed ones

            setSelectedFile(null); // Clear the selected file
            toast.success("File uploaded successfully.");
          } else {
            // Handle error responses
            toast.error(
              res.message || "An error occurred while uploading the file."
            );
          }
        })
        .catch((err) => {
          // Handle exceptions
          const errorMessage = err?.message || "Upload failed.";
          toast.error(errorMessage);
        })
        .finally(() => {
          setShowPreloader(false);
        });
    } else {
    }
  };
  useEffect(() => {
    // Fetch your existing data here and convert it to ContentState
    const initialHTMLContent = Highlight ?? "";

    // Convert HTML to Draft.js ContentState
    const blocksFromHTML = convertFromHTML(initialHTMLContent);
    const contentState = ContentState.createFromBlockArray(
      blocksFromHTML.contentBlocks
    );

    // Set the converted contentState as the initial editorState
    setAddKeyHeighlights(EditorState.createWithContent(contentState));
    setFormikInitialData({ ...formikInitialData });
  }, [Highlight]); // Empty dependency array, runs only once on mount

  const onIssuerAboutEditorStateChange = (editorState: any) => {
    const rawContentState = convertToRaw(editorState.getCurrentContent());
    const htmlDescription = draftToHtml(rawContentState);
    const htmlResult = htmlDescription.replace(/(<([^>]+)>)/gi, "");
    setAddIssuerAbout(editorState);
    // setFormikInitialData(({...formikInitialData, bond_issuer_details.: htmlResult}));
  };

  function DateIcon() {
    return (
      <SvgIcon viewBox="0 0 72.872 83.283" style={{ padding: "2px" }}>
        <path
          id="Path_27181"
          data-name="Path 27181"
          d="M-1078.15,955.569v7.808h-72.872v-7.808a7.809,7.809,0,0,1,7.808-7.808h7.807v-7.808a2.61,2.61,0,0,1,2.6-2.6h5.205a2.61,2.61,0,0,1,2.6,2.6v7.808h20.82v-7.808a2.609,2.609,0,0,1,2.6-2.6h5.2a2.609,2.609,0,0,1,2.6,2.6v7.808h7.808A7.81,7.81,0,0,1-1078.15,955.569Zm-72.872,13.013h72.872v44.243a7.811,7.811,0,0,1-7.808,7.809h-57.256a7.81,7.81,0,0,1-7.808-7.809Zm10.41,18.869a1.957,1.957,0,0,0,1.952,1.952h6.506a1.957,1.957,0,0,0,1.952-1.952v-6.507a1.957,1.957,0,0,0-1.952-1.952h-6.506a1.957,1.957,0,0,0-1.952,1.952Zm0,20.82a1.957,1.957,0,0,0,1.952,1.952h6.506a1.957,1.957,0,0,0,1.952-1.952v-6.506a1.958,1.958,0,0,0-1.952-1.952h-6.506a1.958,1.958,0,0,0-1.952,1.952Zm20.821-20.82a1.957,1.957,0,0,0,1.952,1.952h6.506a1.957,1.957,0,0,0,1.952-1.952v-6.507a1.957,1.957,0,0,0-1.952-1.952h-6.506a1.957,1.957,0,0,0-1.952,1.952Zm0,20.82a1.957,1.957,0,0,0,1.952,1.952h6.506a1.957,1.957,0,0,0,1.952-1.952v-6.506a1.958,1.958,0,0,0-1.952-1.952h-6.506a1.958,1.958,0,0,0-1.952,1.952Zm20.82-20.82a1.957,1.957,0,0,0,1.952,1.952h6.506a1.957,1.957,0,0,0,1.952-1.952v-6.507a1.957,1.957,0,0,0-1.952-1.952h-6.506a1.957,1.957,0,0,0-1.952,1.952Zm0,20.82a1.957,1.957,0,0,0,1.952,1.952h6.506a1.957,1.957,0,0,0,1.952-1.952v-6.506a1.958,1.958,0,0,0-1.952-1.952h-6.506a1.958,1.958,0,0,0-1.952,1.952Z"
          transform="translate(1151.022 -937.351)"
        />
      </SvgIcon>
    );
  }
  // const [selectedItems, setSelectedItems] = React.useState<any[]>([]);
  const [openingDate, setOpeningDate] = React.useState<Dayjs | null>(
    dayjs(moment().format("YYYY-MM-DDTHH:mm:ss"))
  );
  const [closingDate, setClosingDate] = React.useState<Dayjs | null>(
    dayjs(moment().format("YYYY-MM-DDTHH:mm:ss"))
  );
  const [allotmentDate, setAllotmentDate] = React.useState<Dayjs | null>(
    dayjs(moment().format("YYYY-MM-DDTHH:mm:ss"))
  );
  const [firstIPDate, setFirstIPDate] = React.useState<Dayjs | null>(
    dayjs(moment().format("YYYY-MM-DDTHH:mm:ss"))
  );
  const [maturityDate, setMaturityDate] = React.useState<Dayjs | null>(
    dayjs(moment().format("YYYY-MM-DDTHH:mm:ss"))
  );
  const [couponDate, setCouponDate] = React.useState<Dayjs | null>(
    dayjs(moment().format("YYYY-MM-DDTHH:mm:ss"))
  );

  const handleChangeMaturityDate = (newValue: Dayjs | null) => {
    setMaturityDate(newValue);
    setFormikInitialData({
      ...formikInitialData,
      bond_maturity_date: newValue
        ? newValue?.format("YYYY-MM-DD").toString()
        : "",
    });
  };
  const handleChangeCouponDate = (newValue: Dayjs | null) => {
    setCouponDate(newValue);
    setFormikInitialData({
      ...formikInitialData,
      bond_coupon_date: newValue
        ? newValue?.format("YYYY-MM-DD").toString()
        : "",
    });
  };

  const handleChangeOpeningDate = (newValue: Dayjs | null) => {
    setOpeningDate(newValue);
    setFormikInitialData({
      ...formikInitialData,
      bond_opening_date: newValue
        ? newValue?.format("YYYY-MM-DD").toString()
        : "",
    });
  };
  // const handleChangeClosingDate = (newValue: Dayjs | null) => {
  //   const close_date: any = handleCalculateDay(
  //     newValue?.format("YYYY-MM-DD").toString()
  //   );
  //   if (close_date != undefined && close_date == "true") {
  //     setClosingDate(newValue);
  //     setFormikInitialData({
  //       ...formikInitialData,
  //       bond_closing_date: newValue
  //         ? newValue?.format("YYYY-MM-DD").toString()
  //         : "",
  //     });
  //   } else {
  //     toast.error("Please select date greater than opening date");
  //   }
  // };

  const handleChangeAllotmentDate = (newValue: Dayjs | null) => {
    const allo_date: any = handleCalculateAllocateDate(
      newValue?.format("YYYY-MM-DD").toString()
    );
    if (allo_date != undefined && allo_date == "true") {
      setAllotmentDate(newValue);
      setFormikInitialData({
        ...formikInitialData,
        bond_allotment_date: newValue
          ? newValue?.format("YYYY-MM-DD").toString()
          : "",
      });
    } else {
      toast.error("Please select date greater than closing date");
    }
  };

  const handleChangeFirstIPDate = (newValue: Dayjs | null) => {
    setFirstIPDate(newValue);
    setFormikInitialData({
      ...formikInitialData,
      bond_ip_date: newValue ? newValue?.format("YYYY-MM-DD").toString() : "",
    });
  };
  const [categoryList, setCategoryList] = useState<any[]>([]);
  useEffect(() => {
    if (location.state.mode == "edit") {
      loadIntitalIPO(location.state.id);

      loadIntitalIPOBrokerage(location.state.id);
      ServerAPI.ipoCategoryListForIPOAdd("", location.state.id)
        .then((res) => {
          try {
            setCategoryList(res.data[0].categories);
            // setTotalCount(res.data[0].pagination.total_records);
          } catch (error) {
            toast.error(res.error);
          }
        })
        .finally(() => {
          setShowPreloader(false);
        });
    }
  }, [location.state.mode]);

  useEffect(() => {
    console.log(formikInitialData);
  }, [formikInitialData]);

  const loadIntitalIPOBrokerage = (security_id: any) => {
    ServerAPI.ipBrokerageDetails("", security_id)
      .then((res) => {
        try {
          const transformedRows = res.data.map((item: any) => ({
            security_category_id: item.security_category_id,
            category_id: item.category.category_id,
            security_id: item.security_id,
            series: item.security_category_series.map((seriesItem: any) => ({
              security_series_id: seriesItem.security_series_id,
              security_category_series_value:
                seriesItem.security_category_series_value,
            })),
          }));

          setRows(transformedRows);
        } catch (error) {
          // toast.error(res.error);
        }
      })
      .finally(() => {
        setShowPreloader(false);
      });
  };
  const loadBondRatingList = () => {
    setShowPreloader(true);
    ServerAPI.getBondRatingList()
      .then((res) => {
        try {
          let Options = frameSelectOptions(
            res.message,
            "bond_rating_list_id",
            "bond_rating_name"
          );
          setBondRatingOptions(Options);
        } catch (error) {
          toast.error(res.error);
        }
      })
      .finally(() => {
        setShowPreloader(false);
      });
  };

  // const loadIntitalIPO = (id: number) => {
  //   if (id) {
  //     setShowPreloader(true);
  //     ServerAPI.IPODetail(id)
  //       .then((res) => {
  //         setBondID(res?.data[0]?.security_id);

  //           setFormikInitialData((e) => ({
  //             ...res,
  //           }));
  //       })
  //       .finally(() => {
  //         setShowPreloader(false);
  //       });
  //   }
  // };
  const loadIntitalIPO = (id: number) => {
    if (id) {
      setShowPreloader(true);
      ServerAPI.IPODetail(id)
        .then((res) => {
          const apiData = res?.data?.[0];

          if (apiData) {
            // Map the API response to the Formik initial values
            const formikInitialData: APIData.IPOAdd = {
              bond_id: apiData.security_id || 0,
              bond_security_document: apiData.security_document || [],
              bond_call_date: apiData.security_call_date || "",
              bond_put_date: apiData.security_put_date || "",
              bond_isin_number: apiData.security_isin || "",
              bond_issuer_name: apiData.issuer?.security_issuer_name || "",
              bond_asba_details: apiData.security_asba_start || "",
              bond_name: apiData.security_name || "",
              bond_nature_of_instrument: apiData.security_type_id || 0,
              bond_security_type: apiData.security_type_id || 0,
              bond_st: parseInt(apiData.security_status) || 0,
              bond_security_code: apiData.security_script_id || "",
              bond_guaranted_by: apiData.security_Guarantee ? "Yes" : "No",
              bond_face_value: apiData.security_face_value || "",
              bond_issue_price: apiData.security_issue_price || "",
              bond_price_per_bond: apiData.security_price_per_bond || "",
              bond_issue_date: apiData.security_issue_date || "",
              bond_form_start_number:
                apiData.application?.application_start || "",
              bond_form_end_number: apiData.application?.application_end || "",
              bond_status: apiData.security_status ? 1 : 0,
              bond_ip_date: apiData.security_first_interest_date || "",
              bond_interest_frequency:
                apiData.security_interest_payment_frequency || "",
              bond_interest_type: apiData.security_interest_frequency_id || 0,
              bond_coupon_on: apiData.security_coupon_rate || "",
              bond_coupon_rate: apiData.security_coupon_rate || "",
              bond_interest_days:
                apiData.security_interest_payment_frequency || "",
              bond_closer_date: apiData.security_close_at || "",
              bond_dmat_book_closer_date:
                apiData.security_book_closure_date || "",
              bond_rbi_loan_code: apiData.security_rbi_notice || "",
              bond_calc_int_on_holidays:
                apiData.security_norm_compound_flag || 0,
              bond_int_on_maturity: apiData.security_maturity_date || "",
              bond_exchange: apiData.exchange_id || "",
              bond_listing: apiData.listing ? 1 : 0,
              bond_minimum_application:
                apiData.security_minimum_application || "",
              bond_issue_size: apiData.security_issue_price || "",
              bond_logo: apiData.security_logo || "",
              bond_form: apiData.security_form || "",
              bond_type: apiData.security_type_id || 0,
              bond_maturity_date: apiData.security_maturity_date || "",
              bond_maturity_amount: apiData.security_maturity_amount || "",
              bond_coupon_date: apiData.security_coupon_rate || "",
              bond_coupon_amount: apiData.security_coupon_amount || "",
              bond_key_highlights:
                apiData?.issuer?.security_issuer_highlight || "",
              bond_disclaimer: apiData.security_disclaimer || "",
              bond_term_condition_link: apiData.security_term_condition || "",
              bond_category_institutional:
                apiData.security_category_instructions || "",
              bond_category_non_institutional: "",
              bond_category_hni: "",
              bond_category_retail: "",
              bond_about_this_ipo: apiData.security_about || "",
              bond_ncd_status: apiData.security_staggered_status ? 1 : 0,
              bond_brokerage_details_status: 0,
              bond_issue_status: apiData.security_issue_status || 0,
              bond_effort_basis: parseInt(apiData.security_effort_basis) || 0,
              bond_registrar_name: apiData.registrar_id || 0,
              bond_trustee_name: apiData.trustee_id || 0,
              bond_our_status: 0,
              bond_int_on_app_money: apiData.security_int_app_money || "",
              bond_int_on_refund_money: apiData.security_int_refund_money || "",
              bond_early_bid_incentive:
                apiData.security_early_bird_incentive || "",
              bond_other_incentive: apiData.security_other_incentive || "",
              bond_e_form_incentive: apiData.security_form_incentive || "",
              bond_opening_date: apiData.security_open_at || "",
              bond_closing_date: apiData.security_close_at || "",
              bond_allotment_date: apiData.security_allot_at || "",
              bond_deposit: apiData.security_deposit || "",
              bond_green_shoe: apiData.security_green_shoe ? 1 : 0,
              bond_green_shoe_size: apiData.security_green_shoe_size || "",
              bond_final_issue_amount:
                apiData.security_final_issue_amount || "",
              bond_procurement_amount:
                apiData.security_procurement_amount || "",
              bond_gst: parseInt(apiData.security_gst) || 0,
              bond_script_id: apiData.security_script_id || "",
              bond_arranger_name: apiData.security_arranger_name || "",
              bond_upi_app_no_series_1: apiData.security_upi_start,
              bond_upi_app_no_series_2: apiData.security_upi_end,
              bond_asba_app_no_series_1: apiData.security_asba_start,
              bond_asba_app_no_series_2: apiData.security_asba_end,
              bond_ipo_sell_windows_days: apiData.security_sell_windows || "",
              bond_category_instructions:
                apiData.security_category_instructions || "",
              bond_sub_category_code: "",
              bond_app_no_series: "",
              bond_series_instructions: "",
              bond_product_note: "",
              bond_listing_circle: "",
              bond_brokerage_structure: "",
              bonds_yeild: apiData.security_yield || "",

              bond_call_details: [],
              bond_put_details: [],
              bond_rating_details:
                apiData.rating.map((rating: any) => ({
                  bond_rating_agency: rating.security_rating_agency,
                  bond_rating_certificate: rating.security_rating_note, // This will depend on your data mapping logic
                  bond_rating: rating.rating_id,
                  bond_rating_id: rating.security_rating_id,
                })) || [],
              bond_issuer_details: [
                {
                  bond_issuer_mode: apiData?.issuer?.security_issuer_moi,
                  bond_issuer_id: apiData.bond_issuer_id,
                  bond_issuer_type: apiData.issuer.security_issuer_types,
                  bond_issuer_total_annual_revenue:
                    apiData.issuer.security_issuer_annual_revenue,
                  bond_issuer_year_of_inception:
                    apiData.issuer.security_issuer_founded_year,
                  bond_issuer_industry: apiData.issuer.security_issuer_industry,
                  bond_issuer_head_office:
                    apiData.issuer.security_issuer_head_office,
                  bond_type_of_issuer: apiData.issuer.security_issuer_types,
                  bond_issuer_finance_details:
                    apiData.bond_issuer_finance_details,
                  bond_issuer_documents: apiData?.issuer?.issuer_document?.map(
                    (doc: any) => ({
                      security_issuer_document_id:
                        doc.security_issuer_document_id,
                      security_issuer_document_name:
                        doc.security_issuer_document_name,
                      security_issuer_document_file:
                        doc.security_issuer_document_file,
                    })
                  ), // Default to an empty array if null
                  bond_issuer_authority:
                    apiData.issuer.security_issuer_cnt_authority,
                  bond_issuer_profile_link: apiData.bond_issuer_profile_link,
                  bond_issuer_about: apiData.bond_issuer_about,
                  bond_issuer_series: apiData.bond_issuer_series,
                  bond_issuer_tenor: apiData.bond_issuer_tenor,
                  bond_issuer_coupon_type: apiData.bond_issuer_coupon_type,
                  bond_issuer_frequency_of_interest:
                    apiData.bond_issuer_frequency_of_interest,
                  bond_issuer_first_ip_date: apiData.bond_issuer_first_ip_date,
                  bond_issuer_ip_dates: apiData.bond_issuer_ip_dates,
                  bond_issuer_effective_yield:
                    apiData.bond_issuer_effective_yield,
                  bond_id: apiData.bond_id,
                  // bond_issuer_isin_number: apiData.bond_issuer_isin_number || ''
                },
              ],
              bond_brand_tags: [],
              bond_eligible_investors: [],
              bond_ncd_available: [],
              bond_ncd_series:
                apiData.series.map((series: any) => ({
                  bond_ncd_series_id: series.security_series_id,
                  bond_ncd_series_number: series.security_series_number,
                  bond_ncd_minimum_application:
                    series.security_series_min_qty || 0, // Default to 0 if null
                  bond_ncd_frequency_of_interest_payment:
                    series?.series_payout?.series_payout_id || 1, // Adjust based on what this should be
                  bond_ncd_in_multiple_terms_of_thereafter:
                    series.security_series_mul_qty || 1, // Default to 1 if null
                  bond_ncd_issue_price_of_ncd: series.security_series_price, // Adjust as needed
                  bond_ncd_tenor: series.security_series_tenure || 0, // Default to 0 if null
                  bond_ncd_coupon: series.security_series_coupon_rate, // Adjust as needed
                  bond_ncd_effective_yield: series.security_series_yield, // Adjust as needed
                  bond_ncd_mode_of_interest_payment:
                    series.security_series_mode_of_issuer, // Adjust as needed
                  bond_ncd_redemption_amount:
                    series.security_series_redemption_amount, // Adjust as needed
                  bond_ncd_put_and_option: series.security_series_call_put || 0, // Default to 0 if null
                })) || [],
              bond_brokerage_details: [],
            };

            setFormikInitialData(formikInitialData);
            setBondID(apiData.security_id);
          }
          setDynamicText(apiData?.security_disclaimer);
          setHighlight(apiData?.issuer?.security_issuer_highlight);
        })
        .finally(() => {
          setShowPreloader(false);
        });
    }
  };

  const loadBrandTags = () => {
    setShowPreloader(true);
    ServerAPI.getBrandTagsList()
      .then((res) => {
        if (formikRef.current?.values) {
          var currValues: APIData.IPOAdd = { ...formikRef.current.values };
          if (currValues.bond_brand_tags.length == 0) {
            res.brand_tags.map((tags: APIData.BondBrandTags, index: number) => {
              currValues.bond_brand_tags.push({
                bond_brand_tag_available: tags.bond_brand_tag_available,
                brand_tag_id: tags.brand_tag_id,
                brand_tag_name: tags.brand_tag_name,
                bond_brand_tag_id: null,
                brand_tag_status: tags.brand_tag_status,
              });
            });
            setFormikInitialData({ ...formikInitialData });
          }
        }
        // if (selectedBrandTag && selectedBrandTag.length == 0) {
        //     res.brand_tags.map((tags: APIData.BondBrandTags, index: number) => {
        //         selectedBrandTag.push({
        //             brand_tag_id: null,
        //             brand_tag_name: tags.brand_tag_name,
        //             bond_brand_tag_available: tags.bond_brand_tag_available ? tags.bond_brand_tag_available : 0
        //         })
        //     })
        //     setSelectedBrandTag([...selectedBrandTag]);
        // }
      })
      .finally(() => {
        setShowPreloader(false);
      });
  };

  const loadEligibleInvestor = () => {
    setShowPreloader(true);
    ServerAPI.getEligibleInvestorList()
      .then((res) => {
        if (formikRef.current?.values) {
          var currValues: APIData.IPOAdd = { ...formikRef.current.values };
          if (currValues.bond_eligible_investors.length == 0) {
            res.eligible_investors.map(
              (inv: APIData.BondEligibleInvestors, index: number) => {
                currValues.bond_eligible_investors.push({
                  eligible_investors_available:
                    inv.eligible_investors_available,
                  eligible_investor_id: inv.eligible_investor_id,
                  eligible_investor_name: inv.eligible_investor_name,
                  bond_eligible_investor_id: null,
                  eligible_investor_status: inv.eligible_investor_status,
                });
              }
            );
            setFormikInitialData({ ...formikInitialData });
          }
        }
      })
      .finally(() => {
        setShowPreloader(false);
      });
  };

  const updateBrandTags = (checked: boolean, index: number) => {
    console.log(checked);
    console.log(index);
    if (formikRef.current?.values) {
      var currValues: APIData.IPOAdd = { ...formikRef.current.values };
      currValues.bond_brand_tags.map((tags: APIData.BondBrandTags) => {
        if (
          currValues.bond_brand_tags.filter((e, i) => i == index).length == 1
        ) {
          currValues.bond_brand_tags[index].bond_brand_tag_available =
            checked == true ? 1 : null;
          // currValues.bond_brand_tags[index].brand_tag_checked = !checked
        }
      });
      setFormikInitialData({ ...formikInitialData });
    }
    // if (selectedBrandTag) {
    //     selectedBrandTag.map((tags: APIData.BondBrandTags, inx: number) => {
    //         selectedBrandTag[index].brand_tag_id = checked == true ? index : null;
    //     })
    //     setSelectedBrandTag([...selectedBrandTag]);
    // }
  };
  const updateEligibleTags = (checked: boolean, index: number) => {
    console.log(checked);
    if (formikRef.current?.values) {
      var currValues: APIData.IPOAdd = { ...formikRef.current.values };
      currValues.bond_eligible_investors.map(
        (tags: APIData.BondEligibleInvestors) => {
          if (
            currValues.bond_eligible_investors.filter((e, i) => i == index)
              .length == 1
          ) {
            currValues.bond_eligible_investors[
              index
            ].eligible_investors_available = checked == true ? 1 : null;
            // currValues.bond_brand_tags[index].brand_tag_checked = !checked
          }
        }
      );
      setFormikInitialData({ ...formikInitialData });
    }
  };

  useEffect(() => {
    // suspenist();
  }, [pageNumber, search_text, toData, fromDate, preData, open]);

  const suspended_list = () => {
    setShowPreloader(true);
    ServerAPI.SuspentedUserList(pageNumber, search_text, toData, fromDate)
      .then((res) => {
        setUserList(res.datas);
        setTotalCount(res.total_count);
      })
      .finally(() => {
        setShowPreloader(false);
      });
  };

  const onUpdateNCDAvailable = (checked: boolean, type: string) => {
    if (formikRef.current?.values) {
      var currValues: APIData.IPOAdd = { ...formikRef.current.values };
      if (type == "bond_ncd_frequency_of_interest_payment") {
        currValues.bond_ncd_available[0].bond_ncd_frequency_of_interest_payment =
          checked == true ? 1 : 0;
      } else if (type == "bond_ncd_minimum_application") {
        currValues.bond_ncd_available[0].bond_ncd_minimum_application =
          checked == true ? 1 : 0;
      } else if (type == "bond_ncd_in_multiple_terms_of_thereafter") {
        currValues.bond_ncd_available[0].bond_ncd_in_multiple_terms_of_thereafter =
          checked == true ? 1 : 0;
      } else if (type == "bond_ncd_issue_price_of_ncd") {
        currValues.bond_ncd_available[0].bond_ncd_issue_price_of_ncd =
          checked == true ? 1 : 0;
      } else if (type == "bond_ncd_tenor") {
        currValues.bond_ncd_available[0].bond_ncd_tenor =
          checked == true ? 1 : 0;
      } else if (type == "bond_ncd_coupon") {
        currValues.bond_ncd_available[0].bond_ncd_coupon =
          checked == true ? 1 : 0;
      } else if (type == "bond_ncd_effective_yield") {
        currValues.bond_ncd_available[0].bond_ncd_effective_yield =
          checked == true ? 1 : 0;
      } else if (type == "bond_ncd_mode_of_interest_payment") {
        currValues.bond_ncd_available[0].bond_ncd_mode_of_interest_payment =
          checked == true ? 1 : 0;
      } else if (type == "bond_ncd_redemption_amount") {
        currValues.bond_ncd_available[0].bond_ncd_redemption_amount =
          checked == true ? 1 : 0;
      } else if (type == "bond_ncd_put_and_option") {
        currValues.bond_ncd_available[0].bond_ncd_put_and_option =
          checked == true ? 1 : 0;
      }
    }
    setFormikInitialData((e) => ({ ...e, currValues }));
  };

  const onUpdateNCDSeries = (value: any, type: string, index: number) => {
    if (formikRef.current?.values) {
      var currValues: APIData.IPOAdd = { ...formikRef.current.values };
      if (type == "bond_ncd_frequency_of_interest_payment") {
        currValues.bond_ncd_series[
          index
        ].bond_ncd_frequency_of_interest_payment = value;
      } else if (type == "bond_ncd_minimum_application") {
        currValues.bond_ncd_series[index].bond_ncd_minimum_application = value;
      } else if (type == "bond_ncd_in_multiple_terms_of_thereafter") {
        currValues.bond_ncd_series[
          index
        ].bond_ncd_in_multiple_terms_of_thereafter = value;
      } else if (type == "bond_ncd_issue_price_of_ncd") {
        currValues.bond_ncd_series[index].bond_ncd_issue_price_of_ncd = value;
      } else if (type == "bond_ncd_tenor") {
        currValues.bond_ncd_series[index].bond_ncd_tenor = value;
      } else if (type == "bond_ncd_coupon") {
        currValues.bond_ncd_series[index].bond_ncd_coupon = value;
      } else if (type == "bond_ncd_effective_yield") {
        currValues.bond_ncd_series[index].bond_ncd_effective_yield = value;
      } else if (type == "bond_ncd_mode_of_interest_payment") {
        currValues.bond_ncd_series[index].bond_ncd_mode_of_interest_payment =
          value;
      } else if (type == "bond_ncd_redemption_amount") {
        currValues.bond_ncd_series[index].bond_ncd_redemption_amount = value;
      } else if (type == "bond_ncd_put_and_option") {
        currValues.bond_ncd_series[index].bond_ncd_put_and_option = value;
      }
    }
    setFormikInitialData({ ...formikInitialData });
  };

  const onClearNCDSeries = () => {
    setFormikInitialData({ ...formikInitialData, bond_ncd_series: [] });
  };

  const updateNDCSeriesList = () => {
    let initial_data = JSON.stringify(InitialData.BondNcdSeries);
    if (formikRef.current?.values) {
      var currValues: APIData.IPOAdd = { ...formikRef.current.values };
      if (currValues.bond_ncd_series.length >= 10) {
        toast.error("Max 10 series value is allowed");
      } else {
        if (
          currValues.bond_ncd_series.filter(
            (e) =>
              e.bond_ncd_frequency_of_interest_payment == "" ||
              e.bond_ncd_frequency_of_interest_payment == null
          ).length != 0
        ) {
          toast.error("Please fill ncd frequency of interest payment");
        } else if (
          currValues.bond_ncd_series.filter(
            (e) => e.bond_ncd_minimum_application == 0
          ).length != 0
        ) {
          toast.error("Please fill minimum application");
        } else if (
          currValues.bond_ncd_series.filter(
            (e) => e.bond_ncd_in_multiple_terms_of_thereafter == 0
          ).length != 0
        ) {
          toast.error("Please fill multiple thereafter");
        } else if (
          currValues.bond_ncd_series.filter(
            (e) =>
              e.bond_ncd_issue_price_of_ncd == "" ||
              e.bond_ncd_issue_price_of_ncd == null
          ).length != 0
        ) {
          toast.error("Please fill ncd issue price of ncd");
        } else if (
          currValues.bond_ncd_series.filter((e) => e.bond_ncd_tenor == 0)
            .length != 0
        ) {
          toast.error("Please fill tenor");
        } else if (
          currValues.bond_ncd_series.filter(
            (e) => e.bond_ncd_coupon == "" || e.bond_ncd_coupon == null
          ).length != 0
        ) {
          toast.error("Please fill ncd coupon");
        } else if (
          currValues.bond_ncd_series.filter(
            (e) =>
              e.bond_ncd_effective_yield == "" ||
              e.bond_ncd_effective_yield == null
          ).length != 0
        ) {
          toast.error("Please fill ncd effective yeild");
        } else if (
          currValues.bond_ncd_series.filter(
            (e) => e.bond_ncd_mode_of_interest_payment == ""
          ).length != 0
        ) {
          toast.error("Please fill interest payment");
        } else if (
          currValues.bond_ncd_series.filter(
            (e) =>
              e.bond_ncd_redemption_amount == "" ||
              e.bond_ncd_redemption_amount == null
          ).length != 0
        ) {
          toast.error("Please fill ncd redemption amount");
        } else if (
          currValues.bond_ncd_series.filter(
            (e) => e.bond_ncd_put_and_option == 0
          ).length != 0
        ) {
          toast.error("Please fill put and call options");
        } else {
          currValues.bond_ncd_series.push(JSON.parse(initial_data));
        }
      }
    }
    setFormikInitialData({ ...formikInitialData });
  };

  const updateShortTermList = () => {
    // let updatedList = NDCList;
    // setNDCList(prev => ({ ...prev, header: [...prev, { name: 'Serial 2' }] }))
  };

  const handleClickOpen = () => {};

  const handleClose = (e: any) => {
    setOpen(e);
  };

  const handleClose_modal = (e: any) => {
    setOpenModal(e);
    // suspended_list();
  };

  const activate_user = (id: any) => {
    setOpenModal(true);
    setUserId(id);
  };

  const view_more_content = (e: any) => {
    setViewContent(e);
    setOpen(true);
  };

  const CustomInput = React.forwardRef((props: any, ref: any) => {
    return (
      <div className="datePickerInputFeildStyle">
        <label onClick={props.onClick} ref={ref}>
          {props.value || props.placeholder}
        </label>
        <img
          src="../../assets/images/calender-icon.svg"
          onClick={props.onClick}
          ref={ref}
        />
      </div>
    );
  });
  const CustomInputDisabled = React.forwardRef((props: any, ref: any) => {
    return (
      <div className="datePickerInputFeildStyle-disabled">
        <label onClick={props.onClick} ref={ref}>
          {props.value || props.placeholder}
        </label>
        <img
          src="../../assets/images/calender-icon.svg"
          onClick={props.onClick}
          ref={ref}
        />
      </div>
    );
  });

  /* Document upload */
  // const onDrop = useCallback((acceptedFiles: File[], fileRejections: any[]) => {
  //     acceptedFiles.map((file: any) => {
  //         console.log(file)
  //         loadUploadedfile(file);
  //     });
  //     fileRejections.forEach((file: any) => {
  //         file.errors.forEach((err: any) => {
  //             if (err.code === "dimension") {
  //                 toast.error(`Error: ${err.message}`);
  //             }
  //         });
  //     });

  // }, [])
  // const { getRootProps, getInputProps } = DropZone(onDrop);
  /* Exce */
  const onDrop = useCallback((acceptedFiles: File[], fileRejections: any[]) => {
    acceptedFiles.map((file: any) => {
      loadUploadedfile(file);
    });
    fileRejections.forEach((file: any) => {
      file.errors.forEach((err: any) => {
        if (err.code === "dimension") {
          toast.error(`Error: ${err.message}`);
        }
      });
    });
  }, []);
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    multiple: true,
  });

  const loadUploadedfile = (file: any) => {
    if (location.state.id == null) {
      toast.error("Please Save IPO before ASBA upload");
    } else {
      setShowPreloader(true);
      ServerAPI.setUploadedFile(file, location.state.id)
        .then((response) => {
          toast.success(response.message);

          if (response.filename != undefined && response.filename != "") {
            console.log(dmp);
            if (formikRef.current?.values) {
              var currValues: APIData.IPOAdd = formikRef.current.values;
              if (dmp != undefined && dmp == "bond_asba_details") {
                currValues.bond_asba_details = response.filename;
              } else if (dmp != undefined && dmp == "bond_logo") {
                currValues.bond_logo = response.filename;
              } else if (
                dmp != undefined &&
                dmp == "bond_category_instructions"
              ) {
                currValues.bond_category_instructions = response.filename;
              } else if (dmp != undefined && dmp == "bond_sub_category_code") {
                currValues.bond_sub_category_code = response.filename;
              } else if (dmp != undefined && dmp == "bond_app_no_series") {
                currValues.bond_app_no_series = response.filename;
              } else if (
                dmp != undefined &&
                dmp == "bond_series_instructions"
              ) {
                currValues.bond_series_instructions = response.filename;
              } else if (dmp != undefined && dmp == "bond_product_note") {
                currValues.bond_product_note = response.filename;
              } else if (dmp != undefined && dmp == "bond_listing_circle") {
                currValues.bond_listing_circle = response.filename;
              } else if (
                dmp != undefined &&
                dmp == "bond_brokerage_structure"
              ) {
                currValues.bond_brokerage_structure = response.filename;
              } else if (
                dmp !== undefined &&
                dmp == "bond_rating_certificate"
              ) {
                currValues.bond_rating_details[
                  dmpIndex
                ].bond_rating_certificate = response.filename;
              } else if (dmp !== undefined && dmp == "bond_issuer_documents") {
                currValues.bond_issuer_details[0].bond_issuer_documents =
                  response.filename;
              }
              setFormikInitialData((e) => ({ ...e, currValues }));
            }
          }
        })
        .finally(() => {
          setShowPreloader(false);
        });
    }

    const addBonCallDetail = () => {
      if (formikRef.current?.values) {
        var currValues: APIData.IPOAdd = deepCopyIPO(formikRef.current.values);
        if (
          currValues.bond_call_details.filter(
            (e) =>
              e.bond_call_detail_amount == "" ||
              e.bond_call_detail_amount == null
          ).length == 0
        ) {
          currValues.bond_call_details.push({
            bond_call_detail_id: 0,
            bond_call_detail_datetime: moment().format("YYYY-MM-DD"),
            bond_call_detail_amount: "",
          });
        } else {
          toast.error("Please fill call amount feild");
        }
        setFormikInitialData(currValues);
      }
    };
  };

  const removeBonCallDetail = (removeIndex: any) => {
    if (formikRef.current?.values) {
      var currValues: APIData.IPOAdd = deepCopyIPO(formikRef.current.values);
      let paramValues = currValues.bond_call_details.filter(
        (paramValue, index) => index !== removeIndex
      );
      setFormikInitialData({
        ...formikInitialData,
        bond_call_details: paramValues,
      });
    }
  };

  const addBondPutDetail = () => {
    if (formikRef.current?.values) {
      var currValues: APIData.IPOAdd = deepCopyIPO(formikRef.current.values);
      if (
        currValues.bond_put_details.filter(
          (e) =>
            e.bond_put_detail_amount == "" || e.bond_put_detail_amount == null
        ).length == 0
      ) {
        currValues.bond_put_details.push({
          bond_put_detail_id: 0,
          bond_put_detail_datetime: moment().format("YYYY-MM-DD"),
          bond_put_detail_amount: "",
        });
      } else {
        toast.error("Please fill put amount feild");
      }
      setFormikInitialData(currValues);
    }
  };

  const removeBondPutDetail = (removeIndex: any) => {
    if (formikRef.current?.values) {
      var currValues: APIData.IPOAdd = deepCopyIPO(formikRef.current.values);
      let paramValues = currValues.bond_put_details.filter(
        (paramValue, index) => index !== removeIndex
      );
      setFormikInitialData({
        ...formikInitialData,
        bond_put_details: paramValues,
      });
    }
  };

  const addBondRating = () => {
    if (formikRef.current?.values) {
      var currValues: APIData.IPOAdd = deepCopyIPO(formikRef.current.values);
      if (
        currValues.bond_rating_details.filter(
          (e) =>
            e.bond_rating_agency == "" ||
            e.bond_rating == 0 ||
            e.bond_rating_certificate == ""
        ).length == 0
      ) {
        currValues.bond_rating_details.push({
          bond_rating_agency: "",
          bond_rating: 0,
          bond_rating_certificate: "",
          bond_rating_id: "",
        });
      } else {
        toast.error("Please fill rating feild");
      }
      setFormikInitialData(currValues);
    }
  };

  const removeBondRating = (removeIndex: any) => {
    if (formikRef.current?.values) {
      var currValues: APIData.IPOAdd = deepCopyIPO(formikRef.current.values);
      let paramValues = currValues.bond_rating_details.filter(
        (paramValue, index) => index !== removeIndex
      );
      setFormikInitialData({
        ...formikInitialData,
        bond_rating_details: paramValues,
      });
    }
  };

  const handleRemoveNCDseries = (removeIndex: number) => {
    if (formikRef.current?.values) {
      var currValues: APIData.IPOAdd = deepCopyIPO(formikRef.current.values);
      let paramValues = currValues.bond_ncd_series.filter(
        (paramValue, index) => index !== removeIndex
      );
      console.log(paramValues);
      if (paramValues.length != 0) {
        setFormikInitialData((e) => ({ ...e, bond_ncd_series: paramValues }));
      } else {
        toast.error("Series can not be empty");
      }
    }
  };

  const deepCopyIPO = (IPO: APIData.IPOAdd) => {
    let returnIPOAdd: APIData.IPOAdd = { ...IPO };
    if (IPO.bond_call_details !== undefined) {
      returnIPOAdd.bond_call_details = [...IPO.bond_call_details];
    } else {
      returnIPOAdd.bond_call_details = [];
    }
    if (IPO.bond_put_details !== undefined) {
      returnIPOAdd.bond_put_details = [...IPO.bond_put_details];
    } else {
      returnIPOAdd.bond_put_details = [];
    }
    if (IPO.bond_brand_tags !== undefined) {
      returnIPOAdd.bond_brand_tags = [...IPO.bond_brand_tags];
    } else {
      returnIPOAdd.bond_brand_tags = [];
    }
    if (IPO.bond_ncd_series !== undefined) {
      returnIPOAdd.bond_ncd_series = [...IPO.bond_ncd_series];
    } else {
      returnIPOAdd.bond_ncd_series = [];
    }
    return returnIPOAdd;
  };

  const handleCalculateDay = (value: any, type: "opening" | "closing") => {
    const selectedDateTime = new Date(value); // Convert selected value to Date object
    const openingDateTime = formikInitialData.bond_opening_date
      ? new Date(formikInitialData.bond_opening_date)
      : null;
    const closingDateTime = formikInitialData.bond_closing_date
      ? new Date(formikInitialData.bond_closing_date)
      : null;

    if (type === "opening" && closingDateTime) {
      if (selectedDateTime.getTime() >= closingDateTime.getTime()) {
        toast.error(
          "Opening date and time must be before the closing date and time"
        );
        return false;
      }
    }

    if (type === "closing" && openingDateTime) {
      if (selectedDateTime.getTime() <= openingDateTime.getTime()) {
        toast.error(
          "Closing date and time must be after the opening date and time"
        );
        return false;
      }
    }

    return true;
  };

  const handleCalculateAllocateDate = (value: any) => {
    if (formikInitialData.bond_closing_date != "") {
      var allocate_date = new Date(value);
      var close_date = new Date(formikInitialData.bond_closing_date);
      if (allocate_date.getTime() > close_date.getTime()) {
        return "true";
      } else {
        return "false";
      }
    } else {
      toast.error("Please select the close date");
    }
  };
  const handleDownload = async () => {
    const persistAuthString = await Utilities.getAuthString();
    const myHeaders = new Headers();
    myHeaders.append("Authorization", persistAuthString);

    const requestOptions: RequestInit = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    // Fetch the binary Excel file
    const response = await fetch(
      "https://v2-trust-api.trustmony.com/api/v2/admin/asba-file",
      requestOptions
    );

    // Check if the response is ok
    if (!response.ok) {
      console.error("Failed to fetch file:", response.statusText);
      return;
    }

    // Get the binary data as a Blob
    const blob = await response.blob();

    // Create a URL for the Blob
    const fileURL = URL.createObjectURL(blob);

    // Create an anchor element to trigger the download
    const link = document.createElement("a");
    link.href = fileURL;

    // Set the file name and extension for the Excel file (e.g., .xlsx)
    link.download = "Asba_Bank_Details.xlsx"; // Change the filename if needed

    // Trigger the download by programmatically clicking the link
    link.click();

    // Clean up by revoking the Blob URL
    URL.revokeObjectURL(fileURL);
  };
  // const yupSchema = Yup.object().shape({
  //   bond_issuer_name: Yup.string()
  //     .nullable() // Allows `null` without triggering a type error
  //     .required("Issuer Name is required"),
  //   bond_face_value: Yup.string()
  //     .nullable() // Allows `null` without triggering a type error
  //     .required("Face is required"),
  //   bond_script_id: Yup.string()
  //     .nullable() // Allows `null` without triggering a type error
  //     .required("Script Id is required"),
  //   bond_coupon_on: Yup.number()
  //     .nullable() // Allows `null` without triggering a type error
  //     .required("Return upto is required"),
  //   bond_issue_size: Yup.number()
  //     .nullable() // Allows `null` without triggering a type error
  //     .required("Issue Price is required"),
  //   bond_logo: Yup.string()
  //     .nullable() // Allows `null` without triggering a type error
  //     .required("Security Logo is required"),
  //   bond_form: Yup.string()
  //     .nullable() // Allows `null` without triggering a type error
  //     .required("Security Form is required"),

  //   bond_name: Yup.string()
  //     .nullable() // Allows `null` without triggering a type error
  //     .required("Bond Name is required"),

  //   bond_form_start_number: Yup.number()
  //     .typeError("Application Start is required")
  //     .min(1, "Min value 1.")
  //     .required("Application Start is required"),

  //   bond_form_end_number: Yup.number()
  //     .typeError("Application End is required")
  //     .min(1, "Min value 1.")
  //     .required("Application End is required"),

  //   bond_opening_date: Yup.date()
  //     .nullable() // Allows `null` without triggering a type error
  //     .typeError("Opening Date is required")
  //     .required("Opening Date is required"),
  //   bond_closing_date: Yup.date()
  //     .nullable() // Allows `null` without triggering a type error
  //     .typeError("Closing Date is required")
  //     .required("Closing Date is required"),
  //   bond_issuer_details: Yup.array().of(
  //     Yup.object().shape({
  //       bond_issuer_mode: Yup.string()
  //         .nullable() // Allows `null` without triggering a type error
  //         .required("Mode Of Issue is required"),
  //     })
  //   ),
  // });

  // const submitData = (data: any, setSubmitting: any) => {
  //   console.log(data);
  // };

  const yupSchema = Yup.object().shape({
    bond_issuer_name: Yup.string()
      .nullable()
      .required("Issuer Name is required"),
    bond_face_value: Yup.string().nullable().required("Face is required"),
    bond_script_id: Yup.string().nullable().required("Script Id is required"),
    bond_coupon_on: Yup.number().nullable().required("Return upto is required"),
    bond_issue_size: Yup.number()
      .nullable()
      .required("Issue Price is required"),
    bond_logo: Yup.string().nullable().required("Security Logo is required"),
    bond_form: Yup.string().nullable().required("Security Form is required"),
    bond_name: Yup.string().nullable().required("Bond Name is required"),
    bond_form_start_number: Yup.number()
      .typeError("Application Start is required")
      .min(1, "Min value 1.")
      .required("Application Start is required"),
    bond_form_end_number: Yup.number()
      .typeError("Application End is required")
      .min(1, "Min value 1.")
      .required("Application End is required"),
    bond_opening_date: Yup.date()
      .nullable()
      .typeError("Opening Date is required")
      .required("Opening Date is required"),
    bond_closing_date: Yup.date()
      .nullable()
      .typeError("Closing Date is required")
      .required("Closing Date is required"),
    bond_issuer_details: Yup.array().of(
      Yup.object().shape({
        bond_issuer_mode: Yup.string()
          .nullable()
          .required("Mode Of Issue is required"),
      })
    ),

    // UPI Start and End Validation
    bond_upi_app_no_series_1: Yup.number()
      .typeError("UPI Start No. is required")
      .required("UPI Start No. is required")
      .test(
        "upi-start-in-range",
        "UPI Start No. must be within Application Start and End range",
        function (value: any) {
          const { bond_form_start_number, bond_form_end_number } = this.parent;
          return (
            value >= bond_form_start_number && value <= bond_form_end_number
          );
        }
      ),
    bond_upi_app_no_series_2: Yup.number()
      .typeError("UPI End No. is required")
      .required("UPI End No. is required")
      .test(
        "upi-end-in-range",
        "UPI End No. must be within Application Start and End range",
        function (value: any) {
          const { bond_form_start_number, bond_form_end_number } = this.parent;
          return (
            value >= bond_form_start_number && value <= bond_form_end_number
          );
        }
      )
      .test(
        "upi-end-after-start",
        "UPI End No. must be greater than or equal to UPI Start No.",
        function (value: any) {
          const { bond_upi_app_no_series_1 } = this.parent;
          return value >= bond_upi_app_no_series_1;
        }
      ),

    // ASBA Start and End Validation
    bond_asba_app_no_series_1: Yup.number()
      .typeError("ASBA Start No. is required")
      .required("ASBA Start No. is required")
      .test(
        "asba-start-in-range",
        "ASBA Start No. must be within Application Start and End range",
        function (value: any) {
          const { bond_form_start_number, bond_form_end_number } = this.parent;
          return (
            value >= bond_form_start_number && value <= bond_form_end_number
          );
        }
      )
      .test(
        "asba-start-after-upi",
        "ASBA Start No. must start exactly where UPI End No. ends",
        function (value: any) {
          const { bond_upi_app_no_series_2 } = this.parent;
          return value === bond_upi_app_no_series_2 + 1;
        }
      ),
    bond_asba_app_no_series_2: Yup.number()
      .typeError("ASBA App End No. is required")
      .required("ASBA App End No. is required")
      .test(
        "asba-end-equal-to-form-end",
        "ASBA App End No. must be exactly equal to Application End No.",
        function (value: any) {
          const { bond_form_end_number } = this.parent;
          return value === bond_form_end_number;
        }
      ),
  });

  const submitData = (data: any, setSubmitting: any) => {
    if (
      !data?.bond_upi_app_no_series_1 ||
      !data?.bond_asba_app_no_series_1 ||
      !data?.bond_upi_app_no_series_2 ||
      !data?.bond_asba_app_no_series_2
    ) {
      toast.error("(UPI and ASBA Start/End Series) are required.");
    } else if (
      data?.bond_upi_app_no_series_1 === data?.bond_asba_app_no_series_1
    ) {
      toast.error("UPI & ASBA Start Series should not be the same.");
    } else if (
      data?.bond_upi_app_no_series_2 === data?.bond_asba_app_no_series_2
    ) {
      toast.error("UPI & ASBA End Series should not be the same.");
    } else {
      console.log("sdnsjdfsdfsdf", data);

      if (location.state.mode == "edit") {
        const categoryIds = rows.map((row) => Number(row.category_id));
        const hasDuplicates = categoryIds.length != new Set(categoryIds).size;

        if (hasDuplicates) {
          toast.error("Duplicates Category not allowed");
          setSubmitting(false); // Stop submission if duplicates are found
          return; // Exit the function
        } else {
          const structuredPayload = mapToIPOAdd(data);
          // console.log(JSON.stringify(structuredPayload));

          setShowPreloader(true);
          ServerAPI.putIPO(structuredPayload)
            .then((res) => {
              try {
                toast.success(res.message);
                // navigate(PageLinks.BOND_LIST, { state: res.id });
                setSubmitting(false);
                if (rows.length > 0) {
                  ServerAPI.addOrUpdateBrokerageDetails(rows);
                  // loadIntitalIPOBrokerage(location?.state.id);
                }
                if (res.message) {
                  window.location.reload();
                }
              } catch (error) {
                toast.success(res.errors);
              }
            })
            .finally(() => {
              //   window.location.reload();
              setShowPreloader(false);
            });
        }
      } else {
        const structuredPayload = mapToIPOAdd(data);
        ServerAPI.postIPO(structuredPayload)
          .then((res) => {
            try {
              toast.success(res.message);
              // navigate(PageLinks.BOND_LIST, { state: res.id });
              navigate(PageLinks.IPO_ADD, {
                state: { mode: "edit", id: res?.data[0]?.security_id },
              });
              setSubmitting(false);
              if (res.message) {
                window.location.reload();
              }
            } catch (error) {
              toast.success(res.errors);
            }
          })
          .finally(() => {
            // window.location.reload();
            setShowPreloader(false);
          });
      }
    }
  };
  const saveAsDraft = (data: any) => {
    if (location.state.mode == "edit") {
      const categoryIds = rows.map((row) => Number(row.category_id));
      const hasDuplicates = categoryIds.length != new Set(categoryIds).size;

      if (hasDuplicates) {
        toast.error("Duplicates Category not allowed");
        // setSubmitting(false); // Stop submission if duplicates are found
        return; // Exit the function
      } else {
        const structuredPayload = mapToIPOAddAsDraf(data);
        // console.log(JSON.stringify(structuredPayload));

        setShowPreloader(true);
        ServerAPI.putIPO(structuredPayload)
          .then((res) => {
            try {
              toast.success(res.message);
              // navigate(PageLinks.BOND_LIST, { state: res.id });
              // setSubmitting(false);
              if (rows.length > 0) {
                ServerAPI.addOrUpdateBrokerageDetails(rows);
                // loadIntitalIPOBrokerage(location?.state.id);
              }
              if (res.message) {
                window.location.reload();
              }
            } catch (error) {
              toast.success(res.errors);
            }
          })
          .finally(() => {
            //   window.location.reload();
            setShowPreloader(false);
          });
      }
    } else {
      const structuredPayload = mapToIPOAddAsDraf(data);
      ServerAPI.postIPO(structuredPayload)
        .then((res) => {
          try {
            toast.success(res.message);
            // navigate(PageLinks.BOND_LIST, { state: res.id });
            navigate(PageLinks.IPO_ADD, {
              state: { mode: "edit", id: res?.data[0]?.security_id },
            });
            // setSubmitting(false);
            if (res.message) {
              window.location.reload();
            }
          } catch (error) {
            toast.success(res.errors);
          }
        })
        .finally(() => {
          // window.location.reload();
          setShowPreloader(false);
        });
    }
  };

  const toRoman = (num: number): string => {
    const romanNumerals = [
      "I",
      "II",
      "III",
      "IV",
      "V",
      "VI",
      "VII",
      "VIII",
      "IX",
      "X",
      "XI",
      "XII",
      "XIII",
      "XIV",
      "XV",
      "XVI",
      "XVII",
      "XVIII",
      "XIX",
      "XX",
    ];
    return romanNumerals[num] || num.toString(); // Fallback for larger numbers
  };
  const mapToIPOAdd = (values: any) => {
    // Utility function to handle null values
    const toNull = (value: any) =>
      value === undefined || value === null || value === 0 || value === ""
        ? null
        : value;

    // Construct the application object dynamically, excluding null fields
    const application = {
      application_id: toNull(values?.application_id),
      application_start: toNull(values?.bond_form_start_number),
      application_end: toNull(values?.bond_form_end_number),
    };

    // Remove any key with null value
    const filteredApplication = Object.fromEntries(
      Object.entries(application).filter(([_, value]) => value !== null)
    );

    return {
      security_id: toNull(values?.bond_id),
      security_name: toNull(values?.bond_name),
      security_isin: toNull(values?.bond_isin_number),
      security_logo: toNull(values?.bond_logo),
      security_form: toNull(values?.bond_form),
      security_noi: toNull(values?.bond_nature_of_instrument),
      security_status: values?.bond_status == 1 || null,
      exchange_id: toNull(values?.bond_exchange),
      security_open_at: toNull(values?.bond_opening_date),
      security_close_at: toNull(values?.bond_closing_date),
      security_yield: toNull(values?.bonds_yeild),
      security_face_value: toNull(values?.bond_face_value),
      depository_id: toNull(values?.bond_deposit),
      security_script_id: toNull(values?.bond_script_id),
      security_sell_windows: toNull(values?.bond_ipo_sell_windows_days),
      security_coupon_rate: toNull(values?.bond_coupon_on),
      security_minimum_application: toNull(values?.bond_minimum_application),
      security_issue_price: toNull(values?.bond_issue_size),
      security_allot_at: toNull(values?.bond_allotment_date),
      security_document: toNull(values?.bond_security_document),
      rating: [
        {
          rating_id: values?.bond_rating_details?.[0]?.bond_rating,
          // security_rating_name: values?.bond_rating_details?.[0]?.bond_rating,
          security_rating_agency:
            values?.bond_rating_details?.[0]?.bond_rating_agency,
          security_rating_note: "", // Static value as per your requirements
          security_rating_id:
            values?.bond_rating_details?.[0]?.bond_rating_id || null,
        },
      ],
      security_type_id: 1,
      // security_highlight: toNull(values?.bond_key_highlights),
      security_types: values?.bond_type === 1 ? "Public" : "Private",
      security_issue_status:
        values?.bond_issue_status === 1 ? "Yet To Open" : "Open",
      trustee_id: 1,
      registrar_id: 1,
      security_green_shoe: values?.bond_green_shoe === 1 || null,
      security_green_shoe_size: toNull(values?.bond_green_shoe_size),
      // security_call_date: toNull(values?.bond_call_details?.[0]?.call_date),
      // security_put_date: toNull(values?.bond_put_details?.[0]?.put_date),
      security_maturity_date: toNull(values?.bond_maturity_date),
      security_effort_basis: toNull(values?.bond_effort_basis),
      security_early_bird_incentive: toNull(values?.bond_early_bid_incentive),
      security_other_incentive: toNull(values?.bond_other_incentive),
      security_form_incentive: toNull(values?.bond_e_form_incentive),
      security_final_issue_amount: toNull(values?.bond_final_issue_amount),
      security_procurement_amount: toNull(values?.bond_procurement_amount),
      security_arranger_name: toNull(values?.bond_arranger_name),
      security_gst: toNull(values?.bond_gst),
      security_about: toNull(values?.bond_about_this_ipo),
      security_disclaimer: toNull(values?.bond_disclaimer),
      security_upi_start: toNull(values?.bond_upi_app_no_series_1),
      security_upi_end: toNull(values?.bond_upi_app_no_series_2),
      security_asba_start: toNull(values?.bond_asba_app_no_series_1),
      security_call_date: toNull(values?.bond_call_date), //Optional None
      security_put_date: toNull(values?.bond_put_date), //Optional None
      security_asba_end: toNull(values?.bond_asba_app_no_series_2),
      application: filteredApplication, // Use the filtered application object here
      series: values?.bond_ncd_series?.map((series: any, index: any) => ({
        security_series_id: toNull(series?.bond_ncd_series_id),
        security_series_name: `Series ${toRoman(index)}`,
        security_series_number: 1,
        security_series_min_qty: toNull(series?.bond_ncd_minimum_application),
        security_series_mul_qty: toNull(
          series?.bond_ncd_in_multiple_terms_of_thereafter
        ),
        security_series_price: toNull(series?.bond_ncd_issue_price_of_ncd),
        security_series_tenure: toNull(series?.bond_ncd_tenor),
        security_series_coupon_rate: toNull(series?.bond_ncd_coupon),
        security_series_redemption_amount: toNull(
          series?.bond_ncd_redemption_amount
        ),
        security_series_yield: toNull(series?.bond_ncd_effective_yield),
        security_series_mode_of_issuer:
          series?.bond_ncd_mode_of_interest_payment || "Public",
        security_series_call_put: toNull(series?.bond_ncd_put_and_option),
        series_payout_id: toNull(
          series?.bond_ncd_frequency_of_interest_payment
        ),
      })),
      issuer: {
        security_issuer_name: toNull(values?.bond_issuer_name),
        security_issuer_logo: toNull(
          values?.bond_issuer_details?.[0]?.issuer_logo
        ),
        security_issuer_annual_revenue: toNull(
          values?.bond_issuer_details?.[0]?.bond_issuer_total_annual_revenue
        ),
        security_issuer_industry: toNull(
          values?.bond_issuer_details?.[0]?.bond_issuer_industry
        ),
        security_issuer_founded_year: toNull(
          values?.bond_issuer_details?.[0]?.bond_issuer_year_of_inception
        ),
        security_issuer_head_office: toNull(
          values?.bond_issuer_details?.[0]?.bond_issuer_head_office
        ),
        security_issuer_types: toNull(
          values?.bond_issuer_details?.[0]?.bond_issuer_type
        ),
        security_issuer_cnt_authority: toNull(
          values?.bond_issuer_details?.[0]?.bond_issuer_authority
        ),
        security_issuer_highlight: toNull(values?.bond_key_highlights),
        issuer_document: toNull(
          values?.bond_issuer_details?.[0]?.bond_issuer_documents
        ),
        security_issuer_moi: toNull(
          values?.bond_issuer_details?.[0]?.bond_issuer_mode
        ),
      },
    };
  };
  const mapToIPOAddAsDraf = (values: any) => {
    // Utility function to handle null values
    const toNull = (value: any) =>
      value === undefined || value === null || value === 0 || value === ""
        ? null
        : value;

    // Construct the application object dynamically, excluding null fields
    const application = {
      application_id: toNull(values?.application_id),
      application_start: toNull(values?.bond_form_start_number),
      application_end: toNull(values?.bond_form_end_number),
    };

    // Remove any key with null value
    const filteredApplication = Object.fromEntries(
      Object.entries(application).filter(([_, value]) => value !== null)
    );

    return {
      security_id: toNull(values?.bond_id),
      security_name: toNull(values?.bond_name),
      security_isin: toNull(values?.bond_isin_number),
      security_logo: toNull(values?.bond_logo),
      security_form: toNull(values?.bond_form),
      security_noi: toNull(values?.bond_nature_of_instrument),
      security_status: false,
      exchange_id: toNull(values?.bond_exchange),
      security_open_at: toNull(values?.bond_opening_date),
      security_close_at: toNull(values?.bond_closing_date),
      security_yield: toNull(values?.bonds_yeild),
      security_face_value: toNull(values?.bond_face_value),
      depository_id: toNull(values?.bond_deposit),
      security_script_id: toNull(values?.bond_script_id),
      security_sell_windows: toNull(values?.bond_ipo_sell_windows_days),
      security_coupon_rate: toNull(values?.bond_coupon_on),
      security_minimum_application: toNull(values?.bond_minimum_application),
      security_issue_price: toNull(values?.bond_issue_size),
      security_allot_at: toNull(values?.bond_allotment_date),
      security_document: toNull(values?.bond_security_document),
      rating: [
        {
          rating_id: values?.bond_rating_details?.[0]?.bond_rating,
          // security_rating_name: values?.bond_rating_details?.[0]?.bond_rating,
          security_rating_agency:
            values?.bond_rating_details?.[0]?.bond_rating_agency,
          security_rating_note: "", // Static value as per your requirements
          security_rating_id:
            values?.bond_rating_details?.[0]?.bond_rating_id || null,
        },
      ],
      security_type_id: 1,
      security_types: values?.bond_type === 1 ? "Public" : "Private",
      security_issue_status:
        values?.bond_issue_status === 1 ? "Yet To Open" : "Open",
      trustee_id: 1,
      registrar_id: 1,
      security_green_shoe: values?.bond_green_shoe === 1 || null,
      security_green_shoe_size: toNull(values?.bond_green_shoe_size),
      // security_call_date: toNull(values?.bond_call_details?.[0]?.call_date),
      // security_put_date: toNull(values?.bond_put_details?.[0]?.put_date),
      security_maturity_date: toNull(values?.bond_maturity_date),
      security_effort_basis: toNull(values?.bond_effort_basis),
      security_early_bird_incentive: toNull(values?.bond_early_bid_incentive),
      security_other_incentive: toNull(values?.bond_other_incentive),
      security_form_incentive: toNull(values?.bond_e_form_incentive),
      security_final_issue_amount: toNull(values?.bond_final_issue_amount),
      security_procurement_amount: toNull(values?.bond_procurement_amount),
      security_arranger_name: toNull(values?.bond_arranger_name),
      security_gst: toNull(values?.bond_gst),
      security_about: toNull(values?.bond_about_this_ipo),
      security_disclaimer: toNull(values?.bond_disclaimer),
      security_upi_start: toNull(values?.bond_upi_app_no_series_1),
      security_upi_end: toNull(values?.bond_upi_app_no_series_2),
      security_asba_start: toNull(values?.bond_asba_app_no_series_1),
      security_call_date: toNull(values?.bond_call_date), //Optional None
      security_put_date: toNull(values?.bond_put_date), //Optional None
      security_asba_end: toNull(values?.bond_asba_app_no_series_2),
      application: filteredApplication, // Use the filtered application object here
      series: values?.bond_ncd_series?.map((series: any, index: any) => ({
        security_series_id: toNull(series?.bond_ncd_series_id),
        security_series_name: `Series ${toRoman(index)}`,
        security_series_number: 1,
        security_series_min_qty: toNull(series?.bond_ncd_minimum_application),
        security_series_mul_qty: toNull(
          series?.bond_ncd_in_multiple_terms_of_thereafter
        ),
        security_series_price: toNull(series?.bond_ncd_issue_price_of_ncd),
        security_series_tenure: toNull(series?.bond_ncd_tenor),
        security_series_coupon_rate: toNull(series?.bond_ncd_coupon),
        security_series_redemption_amount: toNull(
          series?.bond_ncd_redemption_amount
        ),
        security_series_yield: toNull(series?.bond_ncd_effective_yield),
        security_series_mode_of_issuer:
          series?.bond_ncd_mode_of_interest_payment || "Public",
        security_series_call_put: toNull(series?.bond_ncd_put_and_option),
        series_payout_id: toNull(
          series?.bond_ncd_frequency_of_interest_payment
        ),
      })),
      issuer: {
        security_issuer_name: toNull(values?.bond_issuer_name),
        security_issuer_logo: toNull(
          values?.bond_issuer_details?.[0]?.issuer_logo
        ),
        security_issuer_annual_revenue: toNull(
          values?.bond_issuer_details?.[0]?.bond_issuer_total_annual_revenue
        ),
        security_issuer_industry: toNull(
          values?.bond_issuer_details?.[0]?.bond_issuer_industry
        ),
        security_issuer_founded_year: toNull(
          values?.bond_issuer_details?.[0]?.bond_issuer_year_of_inception
        ),
        security_issuer_head_office: toNull(
          values?.bond_issuer_details?.[0]?.bond_issuer_head_office
        ),
        security_issuer_types: toNull(
          values?.bond_issuer_details?.[0]?.bond_issuer_type
        ),
        security_issuer_cnt_authority: toNull(
          values?.bond_issuer_details?.[0]?.bond_issuer_authority
        ),
        security_issuer_highlight: toNull(values?.bond_key_highlights),
        issuer_document: toNull(
          values?.bond_issuer_details?.[0]?.bond_issuer_documents
        ),
        security_issuer_moi: toNull(
          values?.bond_issuer_details?.[0]?.bond_issuer_mode
        ),
      },
    };
  };

  return (
    <div>
      <div id="layout-wrapper">
        <Header />
        <div className="main-content py-4">
          <div className="page-content">
            <div className="container-fluid">
              <div className="row">
                <div className="col-12">
                  <div className="page-title-box d-sm-flex align-items-center justify-content-between pt-4 pb-0">
                    <div className="page-title-right">
                      <ol className="breadcrumb ">
                        {/* <li className="breadcrumb-item"><a style={{ color: "#90929E" }}>Trustmony</a></li> */}
                        <li className="breadcrumb-item">
                          <a> Master </a>
                        </li>
                        <li className="breadcrumb-item active ">
                          {location.state?.mode != undefined &&
                          location.state.mode == "edit"
                            ? "Edit"
                            : "Add"}{" "}
                          IPO Bond
                        </li>
                      </ol>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-12">
                  <div className="card">
                    <div className="card-body col-sm-12 col-md-12 col-lg-12 w-100">
                      <Formik
                        validationSchema={yupSchema}
                        enableReinitialize={true}
                        validateOnChange={false}
                        initialValues={formikInitialData}
                        innerRef={formikRef}
                        onSubmit={(values, { setSubmitting }) => {
                          values.bond_type = 1;
                          values.bond_id =
                            location.state.mode == "edit" ? bondID : 0;
                          if (
                            values.bond_maturity_date ==
                            moment().format("YYYY-MM-DD")
                          ) {
                            toast.error(
                              "Please select the date greater than today for bond maturity date"
                            );
                            setSubmitting(true);
                          } else if (
                            values.bond_closing_date ==
                            moment().format("YYYY-MM-DD")
                          ) {
                            toast.error(
                              "Please select the closing date greater than opening date"
                            );
                            setSubmitting(true);
                          } else {
                            submitData(values, setSubmitting);
                          }
                        }}
                      >
                        {(formikProps: any) => {
                          // { console.log(formikProps.handleSubmit) }
                          return (
                            <Form onSubmit={formikProps.handleSubmit}>
                              <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <div className="row">
                                  <div
                                    className="card"
                                    style={{
                                      borderTopLeftRadius: "10px",
                                      borderTopRightRadius: "10px",
                                      paddingTop: "10px",
                                      paddingBottom: "20px",
                                    }}
                                  >
                                    <div className="card-header d-flex justify-content-between">
                                      <h5 className="m-0 header">
                                        {" "}
                                        {location.state?.mode != undefined &&
                                        location.state.mode == "edit"
                                          ? "Edit"
                                          : "Add"}{" "}
                                        New IPO Bond{" "}
                                      </h5>
                                      <div className="form-check form-switch mx-2">
                                        <label
                                          className="form-check-label"
                                          htmlFor="distributorSubTypeChecked"
                                        >
                                          Disable/Enable
                                        </label>
                                        <input
                                          className="form-check-input "
                                          type="checkbox"
                                          role="switch"
                                          id="distributorSubTypeChecked"
                                          onChange={(e) => {
                                            setFormikInitialData({
                                              ...formikInitialData,
                                              bond_status:
                                                e.target.checked == true
                                                  ? 1
                                                  : 0,
                                            });
                                          }}
                                          checked={
                                            formikProps.values?.bond_status ==
                                            BondIPOStatus.Active
                                              ? true
                                              : false
                                          }
                                        />
                                      </div>
                                    </div>
                                    <div className="card-body">
                                      <Grid
                                        direction="row"
                                        container
                                        spacing={2}
                                      >
                                        <Grid item xs={4}>
                                          <div className="d-grid">
                                            <label
                                              htmlFor="bond_issuer_name"
                                              className="bond-label"
                                            >
                                              Issuer Name
                                              <span
                                                style={{
                                                  color: "red",
                                                  fontSize: "16px",
                                                }}
                                              >
                                                *
                                              </span>
                                            </label>
                                            <input
                                              id="bond_issuer_name"
                                              name="bond_issuer_name"
                                              type="text"
                                              className="bond-input"
                                              onChange={(e) =>
                                                setFormikInitialData({
                                                  ...formikInitialData,
                                                  bond_issuer_name:
                                                    e.target.value,
                                                })
                                              }
                                              value={
                                                formikProps.values
                                                  ?.bond_issuer_name
                                              }
                                            />
                                            {Array.isArray(
                                              formikProps.errors
                                                .bond_issuer_name
                                            ) ? (
                                              formikProps.errors.bond_issuer_name.map(
                                                (error: any, index: any) => (
                                                  <div
                                                    key={index}
                                                    style={{ color: "#FF0000" }}
                                                  >
                                                    {error}
                                                  </div>
                                                )
                                              )
                                            ) : typeof formikProps.errors
                                                .bond_issuer_name ===
                                              "string" ? (
                                              <div style={{ color: "#FF0000" }}>
                                                {
                                                  formikProps.errors
                                                    .bond_issuer_name
                                                }
                                              </div>
                                            ) : null}
                                          </div>
                                        </Grid>
                                        <Grid item xs={4}>
                                          <div className="d-grid">
                                            <label
                                              htmlFor="bond_name"
                                              className="bond-label"
                                            >
                                              Bond Name
                                              <span
                                                style={{
                                                  color: "red",
                                                  fontSize: "16px",
                                                }}
                                              >
                                                *
                                              </span>
                                            </label>
                                            <input
                                              id="bond_name"
                                              name="bond_name"
                                              type="text"
                                              className="bond-input"
                                              onChange={(e) => {
                                                setFormikInitialData({
                                                  ...formikInitialData,
                                                  bond_name: e.target.value,
                                                });
                                                console.log(e.target.value);
                                              }}
                                              value={
                                                formikProps.values?.bond_name
                                              }
                                            />
                                            {Array.isArray(
                                              formikProps.errors.bond_name
                                            ) ? (
                                              formikProps.errors.bond_name.map(
                                                (error: any, index: any) => (
                                                  <div
                                                    key={index}
                                                    style={{ color: "#FF0000" }}
                                                  >
                                                    {error}
                                                  </div>
                                                )
                                              )
                                            ) : typeof formikProps.errors
                                                .bond_name === "string" ? (
                                              <div style={{ color: "#FF0000" }}>
                                                {formikProps.errors.bond_name}
                                              </div>
                                            ) : null}
                                          </div>
                                        </Grid>
                                        <Grid item xs={4}>
                                          <div className="d-grid">
                                            <label
                                              htmlFor="bond_form_start_number"
                                              className="bond-label"
                                            >
                                              Application Start No.
                                              <span
                                                style={{
                                                  color: "red",
                                                  fontSize: "16px",
                                                }}
                                              >
                                                *
                                              </span>
                                            </label>
                                            <input
                                              id="bond_form_start_number"
                                              name="bond_form_start_number"
                                              type="number"
                                              className="bond-input"
                                              onChange={(e) => {
                                                setFormikInitialData({
                                                  ...formikInitialData,
                                                  bond_form_start_number:
                                                    parseInt(e.target.value),
                                                });
                                                console.log(e.target.value);
                                              }}
                                              value={
                                                formikProps.values
                                                  ?.bond_form_start_number
                                              }
                                            />
                                            {typeof formikProps.errors
                                              .bond_form_start_number ===
                                            "string" ? (
                                              <div style={{ color: "#FF0000" }}>
                                                {
                                                  formikProps.errors
                                                    .bond_form_start_number
                                                }
                                              </div>
                                            ) : null}
                                          </div>
                                        </Grid>
                                        <Grid item xs={4}>
                                          <div className="d-grid">
                                            <label
                                              htmlFor="bond_form_end_number"
                                              className="bond-label"
                                            >
                                              Application End No.
                                              <span
                                                style={{
                                                  color: "red",
                                                  fontSize: "16px",
                                                }}
                                              >
                                                *
                                              </span>
                                            </label>
                                            <input
                                              id="bond_form_end_number"
                                              name="bond_form_end_number"
                                              type="number"
                                              className="bond-input"
                                              onChange={(e) => {
                                                setFormikInitialData({
                                                  ...formikInitialData,
                                                  bond_form_end_number:
                                                    parseInt(e.target.value),
                                                });
                                                console.log(e.target.value);
                                              }}
                                              value={
                                                formikProps.values
                                                  ?.bond_form_end_number
                                              }
                                            />
                                            {typeof formikProps.errors
                                              .bond_form_end_number ===
                                            "string" ? (
                                              <div style={{ color: "#FF0000" }}>
                                                {
                                                  formikProps.errors
                                                    .bond_form_end_number
                                                }
                                              </div>
                                            ) : null}
                                          </div>
                                        </Grid>
                                        <Grid item xs={4}>
                                          <div className="d-grid">
                                            <label
                                              htmlFor="Nature of Instrument"
                                              className="bond-label"
                                            >
                                              Nature of Instrument
                                            </label>
                                            <select
                                              id="Nature of Instrument"
                                              name="bond_nature_of_instrument"
                                              onChange={(e) => {
                                                setFormikInitialData({
                                                  ...formikInitialData,
                                                  bond_nature_of_instrument:
                                                    parseInt(e.target.value),
                                                });
                                              }}
                                              value={
                                                formikProps.values
                                                  .bond_nature_of_instrument
                                              }
                                              className="bond-input-select"
                                            >
                                              <option value={0}>Select</option>
                                              <option value={1}>Taxable</option>
                                              <option value={2}>
                                                Tax Free
                                              </option>
                                            </select>
                                          </div>
                                        </Grid>

                                        <Grid item xs={4}>
                                          <div className="d-grid">
                                            <label
                                              htmlFor="bond_isin_number"
                                              className="bond-label"
                                            >
                                              ISIN Number
                                            </label>
                                            <input
                                              id="bond_isin_number"
                                              name="bond_isin_number"
                                              type="text"
                                              className="bond-input bond-input-disabled"
                                              value={
                                                formikProps.values
                                                  ?.bond_isin_number
                                              }
                                              onChange={(e) =>
                                                setFormikInitialData({
                                                  ...formikInitialData,
                                                  bond_isin_number:
                                                    e.target.value,
                                                })
                                              }
                                            />
                                            {/* <FormField formik={formikProps} fieldProps={{ fieldType: "textbox", label: "ISIN Number", name: "bond_isin_number" }} /> */}
                                          </div>
                                        </Grid>

                                        {/* <Grid item xs={4}>
                                          <div className="d-grid">
                                            <label
                                              htmlFor="bond_status"
                                              className="bond-label"
                                            >
                                              Our Status
                                            </label>
                                            <select
                                              id="bond_status"
                                              name="bond_status"
                                              onChange={(e) => {
                                                setFormikInitialData({
                                                  ...formikInitialData,
                                                  bond_status: parseInt(
                                                    e.target.value
                                                  ),
                                                });
                                              }}
                                              value={
                                                formikProps.values.bond_status
                                              }
                                              className="bond-input-select"
                                            >
                                              <option value={0}>Select</option>
                                              <option value={1}>Open</option>
                                              <option value={2}>Close</option>
                                            </select>
                                          </div>
                                        </Grid> */}
                                        <Grid item xs={4}>
                                          <div className="d-grid">
                                            <label
                                              htmlFor="bond_listing"
                                              className="bond-label"
                                            >
                                              Listing
                                            </label>
                                            <select
                                              id="bond_listing"
                                              name="bond_listing"
                                              onChange={(e) => {
                                                setFormikInitialData({
                                                  ...formikInitialData,
                                                  bond_listing: parseInt(
                                                    e.target.value
                                                  ),
                                                });
                                              }}
                                              value={
                                                formikProps.values.bond_listing
                                              }
                                              className="bond-input-select"
                                            >
                                              {/* <option value={0}>Select</option> */}
                                              <option value={1}>BSE</option>
                                              <option value={2}>NSE</option>
                                              <option value={3}>
                                                BSE & NSE
                                              </option>
                                            </select>
                                          </div>
                                        </Grid>

                                        <Grid item xs={4}>
                                          <div className="d-grid">
                                            <label
                                              htmlFor="Bond Name"
                                              className="bond-label"
                                            >
                                              Opening Date
                                              <span
                                                style={{
                                                  color: "red",
                                                  fontSize: "16px",
                                                }}
                                              >
                                                *
                                              </span>
                                            </label>
                                            {/* <DesktopDatePicker
                                                                                        inputFormat="DD/MM/YYYY"
                                                                                        value={openingDate}
                                                                                        onChange={handleChangeOpeningDate}
                                                                                        renderInput={(params: any) => <TextField {...params} />}
                                                                                        className="date-picker-input-style"
                                                                                        components={{
                                                                                            OpenPickerIcon: DateIcon
                                                                                        }}
                                                                                    /> */}
                                            <DatePicker
                                              selected={
                                                formikProps.values
                                                  .bond_opening_date
                                                  ? new Date(
                                                      formikProps.values.bond_opening_date
                                                    )
                                                  : null
                                              }
                                              dateFormat="dd/MM/yyyy h:mm aa" // 12-hour format with AM/PM
                                              onChange={(date: Date | null) => {
                                                if (date) {
                                                  const isValid =
                                                    handleCalculateDay(
                                                      moment(date).format(
                                                        "YYYY-MM-DD HH:mm:ss"
                                                      ),
                                                      "opening"
                                                    );
                                                  if (!isValid) return;

                                                  setFormikInitialData({
                                                    ...formikInitialData,
                                                    bond_opening_date: moment(
                                                      date
                                                    ).format(
                                                      "YYYY-MM-DD HH:mm:ss"
                                                    ), // Store in full format
                                                  });

                                                  formikProps.setFieldValue(
                                                    "bond_opening_date",
                                                    moment(date).toISOString()
                                                  );
                                                } else {
                                                  setFormikInitialData({
                                                    ...formikInitialData,
                                                    bond_opening_date: null,
                                                  });
                                                  formikProps.setFieldValue(
                                                    "bond_opening_date",
                                                    null
                                                  );
                                                }
                                              }}
                                              placeholderText="DD/MM/YYYY HH:mm"
                                              className="date-picker-style"
                                              showTimeSelect // Enables time selection
                                              timeFormat="h:mm aa" // 12-hour format with AM/PM
                                              timeIntervals={15} // Optional: specify time intervals (e.g., every 15 minutes)
                                              timeCaption="Time"
                                              dropdownMode="select"
                                              showYearDropdown // Enables year selection dropdown
                                              scrollableYearDropdown // Makes the year dropdown scrollable
                                              customInput={<CustomInput />}
                                            />
                                          </div>
                                          {typeof formikProps.errors
                                            .bond_opening_date === "string" ? (
                                            <div style={{ color: "#FF0000" }}>
                                              {
                                                formikProps.errors
                                                  .bond_opening_date
                                              }
                                            </div>
                                          ) : null}
                                        </Grid>
                                        <Grid item xs={4}>
                                          <div className="d-grid">
                                            <label
                                              htmlFor="Bond Name"
                                              className="bond-label"
                                            >
                                              Closing Date
                                              <span
                                                style={{
                                                  color: "red",
                                                  fontSize: "16px",
                                                }}
                                              >
                                                *
                                              </span>
                                            </label>
                                            {/* <DesktopDatePicker
                                                                                        inputFormat="DD/MM/YYYY"
                                                                                        value={closingDate}
                                                                                        onChange={handleChangeClosingDate}
                                                                                        renderInput={(params: any) => <TextField {...params} />}
                                                                                        className="date-picker-input-style"
                                                                                        components={{
                                                                                            OpenPickerIcon: DateIcon
                                                                                        }}
                                                                                    /> */}
                                            <DatePicker
                                              selected={
                                                formikProps.values
                                                  .bond_closing_date
                                                  ? new Date(
                                                      formikProps.values.bond_closing_date
                                                    )
                                                  : null
                                              }
                                              dateFormat="dd/MM/yyyy h:mm aa"
                                              closeOnScroll={true}
                                              onChange={(date: Date | null) => {
                                                if (date) {
                                                  const isValid =
                                                    handleCalculateDay(
                                                      moment(date).format(
                                                        "YYYY-MM-DD HH:mm:ss"
                                                      ),
                                                      "closing"
                                                    );
                                                  if (!isValid) return;

                                                  setFormikInitialData({
                                                    ...formikInitialData,
                                                    bond_closing_date: moment(
                                                      date
                                                    ).format(
                                                      "YYYY-MM-DD HH:mm:ss"
                                                    ), // Store in full format
                                                  });

                                                  formikProps.setFieldValue(
                                                    "bond_closing_date",
                                                    moment(date).toISOString()
                                                  );
                                                } else {
                                                  setFormikInitialData({
                                                    ...formikInitialData,
                                                    bond_closing_date: null,
                                                  });
                                                  formikProps.setFieldValue(
                                                    "bond_closing_date",
                                                    null
                                                  );
                                                }
                                              }}
                                              placeholderText="DD/MM/YYYY HH:mm"
                                              className="date-picker-style"
                                              showTimeSelect // Enables time selection
                                              timeFormat="h:mm aa" // 12-hour format with AM/PM
                                              timeIntervals={15} // Optional: specify time intervals (e.g., every 15 minutes)
                                              timeCaption="Time"
                                              dropdownMode="select"
                                              customInput={<CustomInput />}
                                            />
                                          </div>
                                          {typeof formikProps.errors
                                            .bond_closing_date === "string" ? (
                                            <div style={{ color: "#FF0000" }}>
                                              {
                                                formikProps.errors
                                                  .bond_closing_date
                                              }
                                            </div>
                                          ) : null}
                                        </Grid>

                                        <Grid item xs={4}>
                                          <div className="d-grid">
                                            <label
                                              htmlFor="bonds_yeild"
                                              className="bond-label"
                                            >
                                              Yeild
                                            </label>
                                            <input
                                              id="bonds_yeild"
                                              name="bonds_yeild"
                                              type="string"
                                              className="bond-input"
                                              onChange={(e) =>
                                                setFormikInitialData({
                                                  ...formikInitialData,
                                                  bonds_yeild: e.target.value,
                                                })
                                              }
                                              value={
                                                formikProps.values?.bonds_yeild
                                              }
                                            />
                                          </div>
                                        </Grid>
                                        <Grid item xs={4}>
                                          <div className="d-grid">
                                            <label
                                              htmlFor="bond_face_value"
                                              className="bond-label"
                                            >
                                              Face Value (₹){" "}
                                              <span
                                                style={{
                                                  color: "red",
                                                  fontSize: "16px",
                                                }}
                                              >
                                                *
                                              </span>
                                            </label>
                                            <input
                                              id="bond_face_value"
                                              name="bond_face_value"
                                              type="number"
                                              className="bond-input"
                                              value={
                                                formikProps.values
                                                  ?.bond_face_value
                                              }
                                              onChange={(e) =>
                                                setFormikInitialData({
                                                  ...formikInitialData,
                                                  bond_face_value:
                                                    e.target.value,
                                                })
                                              }
                                            />
                                            {typeof formikProps.errors
                                              .bond_face_value === "string" ? (
                                              <div style={{ color: "#FF0000" }}>
                                                {
                                                  formikProps.errors
                                                    .bond_face_value
                                                }
                                              </div>
                                            ) : null}
                                          </div>
                                        </Grid>
                                        <Grid item xs={4}>
                                          <div className="d-grid">
                                            <label
                                              htmlFor="bond_face_value"
                                              className="bond-label"
                                            >
                                              Mode Of Issue{" "}
                                              <span
                                                style={{
                                                  color: "red",
                                                  fontSize: "16px",
                                                }}
                                              >
                                                *
                                              </span>
                                            </label>
                                            <input
                                              id="bond_face_value"
                                              name="bond_face_value"
                                              type="text"
                                              className="bond-input"
                                              value={
                                                formikInitialData
                                                  ?.bond_issuer_details[0]
                                                  ?.bond_issuer_mode
                                              }
                                              onChange={(e) =>
                                                setFormikInitialData({
                                                  ...formikInitialData,
                                                  bond_issuer_details: [
                                                    {
                                                      ...formikInitialData
                                                        .bond_issuer_details[0],
                                                      bond_issuer_mode:
                                                        e.target.value, // Update the bond_issuer_mode
                                                    },
                                                  ],
                                                })
                                              }
                                            />
                                            {formikProps.errors
                                              .bond_issuer_details?.[0]
                                              ?.bond_issuer_mode ? (
                                              <div style={{ color: "#FF0000" }}>
                                                {
                                                  formikProps.errors
                                                    .bond_issuer_details[0]
                                                    .bond_issuer_mode
                                                }
                                              </div>
                                            ) : null}
                                          </div>
                                        </Grid>
                                        <Grid item xs={4}>
                                          <div className="d-grid">
                                            <label
                                              htmlFor="exchange_id"
                                              className="bond-label"
                                            >
                                              Exchange
                                            </label>
                                            <select
                                              id="bond_exchange"
                                              name="bond_exchange"
                                              className="bond-input-select"
                                              value={
                                                formikProps.values
                                                  ?.bond_exchange
                                              }
                                              onChange={(e) =>
                                                setFormikInitialData({
                                                  ...formikInitialData,
                                                  bond_exchange: e.target.value,
                                                })
                                              }
                                            >
                                              <option value="" disabled>
                                                select exchange
                                              </option>
                                              <option value="1">BSE</option>
                                              <option value="2">NSE</option>
                                              <option value="3">
                                                BSE & NSE
                                              </option>
                                            </select>
                                          </div>
                                        </Grid>
                                        <Grid item xs={4}>
                                          <div className="d-grid">
                                            <label
                                              htmlFor="bond_deposit"
                                              className="bond-label"
                                            >
                                              Depository
                                            </label>
                                            <select
                                              id="bond_deposit"
                                              name="bond_deposit"
                                              onChange={(e) => {
                                                setFormikInitialData({
                                                  ...formikInitialData,
                                                  bond_deposit: e.target.value,
                                                });
                                              }}
                                              value={
                                                formikProps.values.bond_deposit
                                              }
                                              className="bond-input-select bond-input-disabled"
                                              disabled
                                            >
                                              <option value={1}>NSDL</option>
                                              <option value={2}>CDSL</option>
                                              <option value={3} selected>
                                                NSDL & CDSL
                                              </option>
                                            </select>
                                          </div>
                                        </Grid>

                                        <Grid item xs={4}>
                                          <div className="d-grid">
                                            <label
                                              htmlFor="Issuer Name"
                                              className="bond-label"
                                            >
                                              Script ID
                                              <span
                                                style={{
                                                  color: "red",
                                                  fontSize: "16px",
                                                }}
                                              >
                                                *
                                              </span>
                                            </label>
                                            <input
                                              id="Issuer Name"
                                              name="firstName"
                                              type="text"
                                              className="bond-input"
                                              onChange={(e) =>
                                                setFormikInitialData({
                                                  ...formikInitialData,
                                                  bond_script_id:
                                                    e.target.value,
                                                })
                                              }
                                              value={
                                                formikProps.values
                                                  ?.bond_script_id
                                              }
                                            />
                                          </div>
                                          {typeof formikProps.errors
                                            .bond_script_id === "string" ? (
                                            <div style={{ color: "#FF0000" }}>
                                              {
                                                formikProps.errors
                                                  .bond_script_id
                                              }
                                            </div>
                                          ) : null}
                                        </Grid>

                                        <Grid item xs={4}>
                                          <div
                                            className="d-grid"
                                            style={{ marginTop: "7px" }}
                                          >
                                            <label
                                              htmlFor="Issuer Name"
                                              className="bond-label"
                                            >
                                              IPO Sell Window Days
                                            </label>
                                            <input
                                              id="Issuer Name"
                                              name="firstName"
                                              type="number"
                                              className="bond-input"
                                              onChange={(e) =>
                                                setFormikInitialData({
                                                  ...formikInitialData,
                                                  bond_ipo_sell_windows_days:
                                                    parseInt(e.target.value),
                                                })
                                              }
                                              value={
                                                formikProps.values
                                                  ?.bond_ipo_sell_windows_days
                                              }
                                            />
                                          </div>
                                        </Grid>
                                        <Grid item xs={4}>
                                          <div className="d-grid">
                                            <label
                                              htmlFor="Issuer Name"
                                              className="bond-label"
                                            >
                                              Return upto (%)
                                              <span
                                                style={{
                                                  color: "red",
                                                  fontSize: "16px",
                                                }}
                                              >
                                                *
                                              </span>
                                            </label>
                                            <input
                                              id="Issuer Name"
                                              name="firstName"
                                              type="number"
                                              className="bond-input"
                                              onChange={(e) =>
                                                setFormikInitialData({
                                                  ...formikInitialData,
                                                  bond_coupon_on:
                                                    e.target.value,
                                                })
                                              }
                                              value={
                                                formikProps.values
                                                  ?.bond_coupon_on
                                              }
                                            />
                                          </div>
                                          {typeof formikProps.errors
                                            .bond_coupon_on === "string" ? (
                                            <div style={{ color: "#FF0000" }}>
                                              {
                                                formikProps.errors
                                                  .bond_coupon_on
                                              }
                                            </div>
                                          ) : null}
                                        </Grid>
                                        <Grid item xs={4}>
                                          <div className="d-grid">
                                            <label
                                              htmlFor="Issuer Name"
                                              className="bond-label"
                                            >
                                              Minimum Application (₹)
                                            </label>
                                            <input
                                              id="Issuer Name"
                                              name="firstName"
                                              type="text"
                                              className="bond-input"
                                              onChange={(e) =>
                                                setFormikInitialData({
                                                  ...formikInitialData,
                                                  bond_minimum_application:
                                                    e.target.value,
                                                })
                                              }
                                              value={
                                                formikProps.values
                                                  ?.bond_minimum_application
                                              }
                                            />
                                          </div>
                                        </Grid>
                                        <Grid item xs={4}>
                                          <div className="d-grid">
                                            <label
                                              htmlFor="Issuer Name"
                                              className="bond-label"
                                            >
                                              Issue Price
                                              <span
                                                style={{
                                                  color: "red",
                                                  fontSize: "16px",
                                                }}
                                              >
                                                *
                                              </span>
                                            </label>
                                            <input
                                              id="Issuer Name"
                                              name="bond_issue_size"
                                              type="number"
                                              className="bond-input"
                                              onChange={(e) =>
                                                setFormikInitialData({
                                                  ...formikInitialData,
                                                  bond_issue_size:
                                                    e.target.value,
                                                })
                                              }
                                              value={
                                                formikProps.values
                                                  ?.bond_issue_size
                                              }
                                            />
                                          </div>
                                          {typeof formikProps.errors
                                            .bond_issue_size === "string" ? (
                                            <div
                                              style={{
                                                color: "#FF0000",
                                              }}
                                            >
                                              {
                                                formikProps.errors
                                                  .bond_issue_size
                                              }
                                            </div>
                                          ) : null}
                                        </Grid>
                                        <Grid item xs={4}>
                                          <div className="d-grid">
                                            <label
                                              htmlFor="bond_issuer_details"
                                              className="bond-label"
                                            >
                                              Security Logo
                                              <span
                                                style={{
                                                  color: "red",
                                                  fontSize: "16px",
                                                }}
                                              >
                                                *
                                              </span>
                                            </label>
                                            <div>
                                              <label
                                                className="bond-input-upload-file"
                                                onClick={() =>
                                                  fileInputRefLogo.current?.click()
                                                } // Trigger file dialog on click
                                              >
                                                Upload Security Logo
                                              </label>
                                              <input
                                                type="file"
                                                ref={fileInputRefLogo}
                                                style={{ display: "none" }}
                                                onChange={(event) =>
                                                  handleFileChange(
                                                    event,
                                                    "security_logo"
                                                  )
                                                } // Attach the handleFileChange function
                                              />
                                            </div>
                                            {formikInitialData?.bond_logo !=
                                              null &&
                                              formikInitialData?.bond_logo !=
                                                "" && (
                                                <Grid item xs={4}>
                                                  <div
                                                    className="d-grid mt-4"
                                                    style={{ width: "200px" }}
                                                  >
                                                    <label
                                                      htmlFor={`view_logo`}
                                                      className="bond-label mr-5"
                                                    >
                                                      View Logo
                                                      <i
                                                        className="fas fa-eye table-icons pe-1"
                                                        style={{
                                                          marginLeft: "10px",
                                                        }}
                                                        onClick={() => {
                                                          if (
                                                            formikInitialData?.bond_logo
                                                          ) {
                                                            const link =
                                                              document.createElement(
                                                                "a"
                                                              );
                                                            link.href =
                                                              formikInitialData?.bond_logo;
                                                            link.download = ""; // Optional: You can set a custom file name here
                                                            link.click();
                                                          }
                                                        }}
                                                      ></i>
                                                      {/* <i
                                                        className="fas fa-trash table-icons"
                                                        style={{
                                                          cursor: "pointer",
                                                          color: "red",
                                                        }}
                                                        onClick={() => {
                                                          setFormikInitialData(
                                                            (
                                                              prevState: any
                                                            ) => ({
                                                              ...prevState,
                                                              bond_logo: null,
                                                            })
                                                          );
                                                        }}
                                                      ></i> */}
                                                    </label>
                                                  </div>
                                                </Grid>
                                              )}
                                          </div>
                                          {typeof formikProps.errors
                                            .bond_logo === "string" ? (
                                            <div
                                              style={{
                                                color: "#FF0000",
                                              }}
                                            >
                                              {formikProps.errors.bond_logo}
                                            </div>
                                          ) : null}
                                        </Grid>
                                        <Grid item xs={4}>
                                          <div className="d-grid">
                                            <label
                                              htmlFor="bond_issuer_details"
                                              className="bond-label"
                                            >
                                              Security Form
                                              <span
                                                style={{
                                                  color: "red",
                                                  fontSize: "16px",
                                                }}
                                              >
                                                *
                                              </span>
                                            </label>
                                            <div>
                                              <label
                                                className="bond-input-upload-file"
                                                onClick={() =>
                                                  fileInputRefForm.current?.click()
                                                } // Trigger file dialog on click
                                              >
                                                Upload Security Form
                                              </label>
                                              <input
                                                type="file"
                                                ref={fileInputRefForm}
                                                style={{ display: "none" }}
                                                onChange={(event) =>
                                                  handleFileChange(
                                                    event,
                                                    "security_form"
                                                  )
                                                } // Attach the handleFileChange function
                                              />
                                            </div>
                                            {formikInitialData?.bond_form !=
                                              null &&
                                              formikInitialData?.bond_form !=
                                                "" && (
                                                <Grid item xs={4}>
                                                  <div
                                                    className="d-grid mt-4"
                                                    style={{ width: "200px" }}
                                                  >
                                                    <label
                                                      htmlFor={`view_form`}
                                                      className="bond-label mr-5"
                                                    >
                                                      View Form
                                                      <i
                                                        className="fas fa-eye table-icons pe-1"
                                                        style={{
                                                          marginLeft: "10px",
                                                        }}
                                                        onClick={() => {
                                                          if (
                                                            formikInitialData?.bond_form
                                                          ) {
                                                            const link =
                                                              document.createElement(
                                                                "a"
                                                              );
                                                            link.href =
                                                              formikInitialData?.bond_form;
                                                            link.download = ""; // Optional: You can set a custom file name here
                                                            link.click();
                                                          }
                                                        }}
                                                      ></i>
                                                      {/* <i
                                                        className="fas fa-trash table-icons"
                                                        style={{
                                                          cursor: "pointer",
                                                          color: "red",
                                                        }}
                                                        onClick={() => {
                                                          setFormikInitialData(
                                                            (
                                                              prevState: any
                                                            ) => ({
                                                              ...prevState,
                                                              bond_form: null,
                                                            })
                                                          );
                                                        }}
                                                      ></i> */}
                                                    </label>
                                                  </div>
                                                </Grid>
                                              )}
                                          </div>
                                          {typeof formikProps.errors
                                            .bond_form === "string" ? (
                                            <div style={{ color: "#FF0000" }}>
                                              {formikProps.errors.bond_form}
                                            </div>
                                          ) : null}
                                        </Grid>

                                        {/* <Grid item xs={4}>
                                          <div className="d-grid">
                                            <label
                                              htmlFor="Issuer Name"
                                              className="bond-label"
                                            >
                                              Upload Bond Logo
                                            </label>
                                            <div {...getRootProps()}>
                                              <input {...getInputProps()} />
                                              {
                                                <label
                                                  className="bond-input-upload-file"
                                                  onClick={() =>
                                                    setUploadFeild((e) => ({
                                                      ...e,
                                                      feild_type: "bond_logo",
                                                    }))
                                                  }
                                                >
                                                  {getURLExtension(
                                                    formikRef.current?.values
                                                      .bond_logo,
                                                    "Upload Bond Logo"
                                                  )}
                                                </label>
                                              }
                                            </div>
                                          </div>
                                        </Grid> */}
                                        {/* <Grid item xs={4}>
                                                                                <div className='d-grid'>
                                                                                    <label htmlFor="Issuer Name" className='bond-label'>Upload Category Instruction</label>
                                                                                    <div {...getRootProps()}>
                                                                                        <input {...getInputProps()} disabled={true} />
                                                                                        {
                                                                                            <label className="bond-input-upload-file" onClick={() => setUploadFeild(e => ({ ...e, feild_type: 'bond_category_instructions' }))}>{getURLExtension(formikRef.current?.values.bond_category_instructions, 'Upload Category Instruction')}</label>
                                                                                        }
                                                                                    </div>
                                                                                </div>
                                                                            </Grid>
                                                                            <Grid item xs={4}>
                                                                                <div className='d-grid'>
                                                                                    <label htmlFor="Issuer Name" className='bond-label'>Upload Sub Category Code</label>
                                                                                    <div {...getRootProps()}>
                                                                                        <input {...getInputProps()} disabled={true} />
                                                                                        {
                                                                                            <label className="bond-input-upload-file" onClick={() => setUploadFeild(e => ({ ...e, feild_type: 'bond_sub_category_code' }))}>{getURLExtension(formikRef.current?.values.bond_sub_category_code, 'Upload Sub Category Code')}</label>
                                                                                        }
                                                                                    </div>
                                                                                </div>
                                                                            </Grid>
                                                                            <Grid item xs={4}>
                                                                                <div className='d-grid'>
                                                                                    <label htmlFor="Issuer Name" className='bond-label'>App No Series (Distributor)</label>
                                                                                    <div {...getRootProps({ onClick: event => { setUploadFeild(e => ({ ...e, feild_type: 'bond_app_no_series' })) } })}>
                                                                                        <input {...getInputProps()} disabled={true} />
                                                                                        {
                                                                                            <label className="bond-input-upload-file">{getURLExtension(formikRef.current?.values.bond_app_no_series, 'App No Series (Distributor)')}</label>
                                                                                        }
                                                                                    </div>
                                                                                </div>
                                                                            </Grid>
                                                                            <Grid item xs={4}>
                                                                                <div className='d-grid'>
                                                                                    <label htmlFor="Issuer Name" className='bond-label'>Upload Series Instruction</label>
                                                                                    <div {...getRootProps({ onClick: event => { setUploadFeild(e => ({ ...e, feild_type: 'bond_series_instructions' })) } })}>
                                                                                        <input {...getInputProps()} disabled={true} />
                                                                                        {
                                                                                            <label className="bond-input-upload-file">{getURLExtension(formikRef.current?.values.bond_series_instructions, 'Upload Series Instruction')}</label>
                                                                                        }
                                                                                    </div>
                                                                                </div>
                                                                            </Grid> */}
                                        {/* <Grid item xs={4}>
                                          <div className="d-grid">
                                            <label
                                              htmlFor="Issuer Name"
                                              className="bond-label"
                                            >
                                              Upload Product Note
                                            </label>
                                            <div
                                              {...getRootProps({
                                                onClick: (event) => {
                                                  setUploadFeild((e) => ({
                                                    ...e,
                                                    feild_type:
                                                      "bond_product_note",
                                                  }));
                                                },
                                              })}
                                            >
                                              <input {...getInputProps()} />
                                              {
                                                <label className="bond-input-upload-file">
                                                  {getURLExtension(
                                                    formikRef.current?.values
                                                      .bond_product_note,
                                                    "Upload Product Note"
                                                  )}
                                                </label>
                                              }
                                            </div>
                                          </div>
                                        </Grid> */}
                                        {/* <Grid item xs={4}>
                                                                                <div className='d-grid'>
                                                                                    <label htmlFor="Issuer Name" className='bond-label'>Upload Listing Circle</label>
                                                                                    <div {...getRootProps({ onClick: event => { setUploadFeild(e => ({ ...e, feild_type: 'bond_listing_circle' })) } })}>
                                                                                        <input {...getInputProps()} disabled={true} />
                                                                                        {
                                                                                            <label className="bond-input-upload-file">{getURLExtension(formikRef.current?.values.bond_listing_circle, 'Upload Listing Circle')}</label>
                                                                                        }
                                                                                    </div>
                                                                                </div>
                                                                            </Grid>
                                                                            <Grid item xs={4}>
                                                                                <div className='d-grid'>
                                                                                    <label htmlFor="Issuer Name" className='bond-label'>Upload Brokerage Structure</label>
                                                                                    <div {...getRootProps({ onClick: event => { setUploadFeild(e => ({ ...e, feild_type: 'bond_brokerage_structure' })) } })}>
                                                                                        <input {...getInputProps()} disabled={true} />
                                                                                        {
                                                                                            <label className="bond-input-upload-file">{getURLExtension(formikRef.current?.values.bond_brokerage_structure, 'Upload Brokerage Structure')}</label>
                                                                                        }
                                                                                    </div>
                                                                                </div>
                                                                            </Grid> */}
                                        {/* <Grid item xs={12}>
                                                                                <div className='d-grid'>
                                                                                    <label htmlFor="Issuer Name" className='bond-label'>About this</label>
                                                                                    <input id="Issuer Name" name="firstName" type="text" className='bond-input' onChange={e => setFormikInitialData(({ ...formikInitialData, bond_about_this_ipo: e.target.value }))} value={formikProps.values?.bond_about_this_ipo} />
                                                                                </div>
                                                                            </Grid> */}

                                        <Grid item xs={4}>
                                          <div className="d-grid">
                                            <label
                                              htmlFor="bond_security_type"
                                              className="bond-label"
                                            >
                                              Security Type
                                            </label>
                                            <select
                                              id="bond_security_type"
                                              name="email"
                                              disabled={true}
                                              onChange={(e) => {
                                                setFormikInitialData({
                                                  ...formikInitialData,
                                                  bond_security_type: parseInt(
                                                    e.target.value
                                                  ),
                                                });
                                              }}
                                              value={
                                                formikProps.values
                                                  .bond_security_type
                                              }
                                              className="bond-input-select bond-input-disabled"
                                            >
                                              <option value={0}>Select</option>
                                              <option value={1}>
                                                Private Sector Bond (Non
                                                Convertible Debentures)
                                              </option>
                                              <option value={2}>
                                                PSU Bond/ Government Security{" "}
                                              </option>
                                              <option value={3}>
                                                Fixed Deposit
                                              </option>
                                              <option value={4}>
                                                54 EC Capital Gains Bonds
                                              </option>
                                              <option value={5}>
                                                RBI Sovereign Gold BondFloating
                                                Rate Savings Bonds
                                              </option>
                                              <option value={6}>
                                                Corporate Fixed Deposit
                                              </option>
                                            </select>
                                          </div>
                                        </Grid>
                                        <Grid item xs={4}>
                                          <div className="d-grid">
                                            <label
                                              htmlFor="bond_issue_status"
                                              className="bond-label"
                                            >
                                              Issue Status
                                            </label>
                                            <select
                                              id="bond_issue_status"
                                              name="bond_issue_status"
                                              disabled={true}
                                              onChange={(e) => {
                                                setFormikInitialData({
                                                  ...formikInitialData,
                                                  bond_issue_status: parseInt(
                                                    e.target.value
                                                  ),
                                                });
                                              }}
                                              value={
                                                formikProps.values
                                                  .bond_issue_status
                                              }
                                              className="bond-input-select bond-input-disabled"
                                            >
                                              <option value="0">Select</option>
                                              <option value="1">
                                                Yet to Open
                                              </option>
                                              <option value="2">Open</option>
                                              <option value="3">Closed</option>
                                            </select>
                                            {formikProps.errors.bond_status ? (
                                              <div style={{ color: "#FF0000" }}>
                                                {formikProps.errors.bond_status}
                                              </div>
                                            ) : null}
                                          </div>
                                        </Grid>
                                        <Grid item xs={4}>
                                          <div className="d-grid">
                                            <label
                                              htmlFor="bond_effort_basis"
                                              className="bond-label"
                                            >
                                              Effort Basis
                                            </label>
                                            <select
                                              id="bond_effort_basis"
                                              name="bond_effort_basis"
                                              disabled={true}
                                              onChange={(e) => {
                                                setFormikInitialData({
                                                  ...formikInitialData,
                                                  bond_effort_basis: parseInt(
                                                    e.target.value
                                                  ),
                                                });
                                              }}
                                              value={
                                                formikProps.values
                                                  .bond_effort_basis
                                              }
                                              className="bond-input-select bond-input-disabled"
                                            >
                                              <option value={0}>Select</option>
                                              <option value={1}>
                                                Effort Basis 1
                                              </option>
                                              <option value={2}>
                                                Effort Basis 2
                                              </option>
                                              <option value={3}>
                                                Effort Basis 3
                                              </option>
                                            </select>
                                          </div>
                                        </Grid>
                                        <Grid item xs={4}>
                                          <div className="d-grid">
                                            <label
                                              htmlFor="bond_registrar_name"
                                              className="bond-label"
                                            >
                                              Registrar
                                            </label>
                                            <select
                                              id="bond_registrar_name"
                                              name="bond_registrar_name"
                                              disabled={true}
                                              onChange={(e) => {
                                                setFormikInitialData({
                                                  ...formikInitialData,
                                                  bond_registrar_name: parseInt(
                                                    e.target.value
                                                  ),
                                                });
                                              }}
                                              value={
                                                formikProps.values
                                                  .bond_registrar_name
                                              }
                                              className="bond-input-select bond-input-disabled"
                                            >
                                              <option value={0}>Select</option>
                                              <option value={1}>
                                                Each Issue will have a separate
                                                Registrar
                                              </option>
                                            </select>
                                          </div>
                                        </Grid>
                                        <Grid item xs={4}>
                                          <div className="d-grid">
                                            <label
                                              htmlFor="bond_trustee_name"
                                              className="bond-label"
                                            >
                                              Trustee
                                            </label>
                                            <select
                                              id="bond_trustee_name"
                                              name="bond_trustee_name"
                                              disabled={true}
                                              onChange={(e) => {
                                                setFormikInitialData({
                                                  ...formikInitialData,
                                                  bond_trustee_name: parseInt(
                                                    e.target.value
                                                  ),
                                                });
                                              }}
                                              value={
                                                formikProps.values
                                                  .bond_trustee_name
                                              }
                                              className="bond-input-select bond-input-disabled"
                                            >
                                              <option value={0}>Select</option>
                                              <option value={1}>
                                                Each Issue will have a separate
                                                Trustee
                                              </option>
                                            </select>
                                          </div>
                                        </Grid>
                                        <Grid item xs={4}>
                                          <div className="d-grid">
                                            <label
                                              htmlFor="Issuer Name"
                                              className="bond-label"
                                            >
                                              Intrest on App Money
                                            </label>
                                            <input
                                              id="Issuer Name"
                                              name="firstName"
                                              type="text"
                                              disabled={true}
                                              className="bond-input bond-input-disabled"
                                              onChange={(e) =>
                                                setFormikInitialData({
                                                  ...formikInitialData,
                                                  bond_int_on_app_money:
                                                    e.target.value,
                                                })
                                              }
                                              value={
                                                formikProps.values
                                                  ?.bond_int_on_app_money
                                              }
                                            />
                                            {/* {formikProps.errors.bond_int_on_app_money ? (<div style={{ color: "#FF0000" }}>{formikProps.errors.bond_int_on_app_money}</div>) : null} */}
                                          </div>
                                        </Grid>
                                        <Grid item xs={4}>
                                          <div className="d-grid">
                                            <label
                                              htmlFor="Bond Name"
                                              className="bond-label"
                                            >
                                              Intrest on Refund Money
                                            </label>
                                            <input
                                              id="Bond Name"
                                              name="lastName"
                                              type="text"
                                              disabled={true}
                                              className="bond-input bond-input-disabled"
                                              onChange={(e) =>
                                                setFormikInitialData({
                                                  ...formikInitialData,
                                                  bond_int_on_refund_money:
                                                    e.target.value,
                                                })
                                              }
                                              value={
                                                formikProps.values
                                                  ?.bond_int_on_refund_money
                                              }
                                            />
                                            {/* {formikProps.errors.bond_int_on_refund_money ? (<div style={{ color: "#FF0000" }}>{formikProps.errors.bond_int_on_refund_money}</div>) : null} */}
                                          </div>
                                        </Grid>
                                        <Grid item xs={4}>
                                          <div className="d-grid">
                                            <label
                                              htmlFor="Issuer Name"
                                              className="bond-label"
                                            >
                                              Early Bird Incentive
                                            </label>
                                            <input
                                              id="Issuer Name"
                                              name="firstName"
                                              type="text"
                                              className="bond-input"
                                              onChange={(e) =>
                                                setFormikInitialData({
                                                  ...formikInitialData,
                                                  bond_early_bid_incentive:
                                                    e.target.value,
                                                })
                                              }
                                              value={
                                                formikProps.values
                                                  ?.bond_early_bid_incentive
                                              }
                                            />
                                            {/* {formikProps.errors.bond_early_bid_incentive ? (<div style={{ color: "#FF0000" }}>{formikProps.errors.bond_early_bid_incentive}</div>) : null} */}
                                          </div>
                                        </Grid>

                                        <Grid item xs={4}>
                                          <div className="d-grid">
                                            <label
                                              htmlFor="Bond Name"
                                              className="bond-label"
                                            >
                                              Other Incentive
                                            </label>
                                            <input
                                              id="Bond Name"
                                              name="lastName"
                                              type="text"
                                              className="bond-input"
                                              onChange={(e) =>
                                                setFormikInitialData({
                                                  ...formikInitialData,
                                                  bond_other_incentive:
                                                    e.target.value,
                                                })
                                              }
                                              value={
                                                formikProps.values
                                                  ?.bond_other_incentive
                                              }
                                            />
                                            {/* {formikProps.errors.bond_other_incentive ? (<div style={{ color: "#FF0000" }}>{formikProps.errors.bond_other_incentive}</div>) : null} */}
                                          </div>
                                        </Grid>
                                        <Grid item xs={4}>
                                          <div className="d-grid">
                                            <label
                                              htmlFor="Issuer Name"
                                              className="bond-label"
                                            >
                                              E-Form Incentive
                                            </label>
                                            <input
                                              id="Issuer Name"
                                              name="firstName"
                                              type="text"
                                              className="bond-input"
                                              onChange={(e) =>
                                                setFormikInitialData({
                                                  ...formikInitialData,
                                                  bond_e_form_incentive:
                                                    e.target.value,
                                                })
                                              }
                                              value={
                                                formikProps.values
                                                  ?.bond_e_form_incentive
                                              }
                                            />
                                            {/* {formikProps.errors.bond_e_form_incentive ? (<div style={{ color: "#FF0000" }}>{formikProps.errors.bond_e_form_incentive}</div>) : null} */}
                                          </div>
                                        </Grid>
                                        <Grid item xs={4}>
                                          <div className="d-grid">
                                            <label
                                              htmlFor="Bond Name"
                                              className="bond-label"
                                            >
                                              Allotment Date
                                            </label>
                                            <DatePicker
                                              selected={localStringToIST(
                                                formikProps.values
                                                  .bond_allotment_date
                                              )}
                                              dateFormat="dd/MM/yyyy"
                                              closeOnScroll={true}
                                              onChange={(date: Date) => {
                                                setFormikInitialData({
                                                  ...formikInitialData,
                                                  bond_allotment_date: moment(
                                                    date
                                                  )
                                                    .format("YYYY-MM-DD")
                                                    .toString(),
                                                });
                                              }}
                                              placeholderText="DD/MM/YYYY"
                                              peekNextMonth
                                              showMonthDropdown
                                              showYearDropdown
                                              dropdownMode="select"
                                              customInput={
                                                <CustomInputDisabled />
                                              }
                                              disabled={true}
                                            />
                                          </div>
                                        </Grid>
                                        <Grid item xs={4}>
                                          <div className="d-grid">
                                            <label
                                              htmlFor="Bond Name"
                                              className="bond-label"
                                            >
                                              Call Date
                                            </label>
                                            <DatePicker
                                              selected={localStringToIST(
                                                formikProps.values
                                                  .bond_call_date
                                              )}
                                              dateFormat="dd/MM/yyyy"
                                              closeOnScroll={true}
                                              onChange={(date: Date) => {
                                                setFormikInitialData({
                                                  ...formikInitialData,
                                                  bond_call_date: moment(date)
                                                    .format("YYYY-MM-DD")
                                                    .toString(),
                                                });
                                              }}
                                              placeholderText="DD/MM/YYYY"
                                              className="date-picker-style"
                                              peekNextMonth
                                              showMonthDropdown
                                              showYearDropdown
                                              dropdownMode="select"
                                              customInput={<CustomInput />}
                                            />
                                          </div>
                                        </Grid>
                                        <Grid item xs={4}>
                                          <div className="d-grid">
                                            <label
                                              htmlFor="Bond Name"
                                              className="bond-label"
                                            >
                                              Put Date
                                            </label>
                                            <DatePicker
                                              selected={localStringToIST(
                                                formikProps.values.bond_put_date
                                              )}
                                              dateFormat="dd/MM/yyyy"
                                              closeOnScroll={true}
                                              onChange={(date: Date) => {
                                                setFormikInitialData({
                                                  ...formikInitialData,
                                                  bond_put_date: moment(date)
                                                    .format("YYYY-MM-DD")
                                                    .toString(),
                                                });
                                              }}
                                              placeholderText="DD/MM/YYYY"
                                              className="date-picker-style"
                                              peekNextMonth
                                              showMonthDropdown
                                              showYearDropdown
                                              dropdownMode="select"
                                              customInput={<CustomInput />}
                                            />
                                          </div>
                                        </Grid>
                                        <Grid item xs={4}>
                                          <div className="d-grid">
                                            <label
                                              htmlFor="bond_green_shoe"
                                              className="bond-label"
                                            >
                                              Green Shoe
                                            </label>
                                            <select
                                              id="bond_green_shoe"
                                              name="bond_green_shoe"
                                              disabled={true}
                                              onChange={(e) => {
                                                setFormikInitialData({
                                                  ...formikInitialData,
                                                  bond_green_shoe: parseInt(
                                                    e.target.value
                                                  ),
                                                });
                                              }}
                                              value={
                                                formikProps.values
                                                  .bond_green_shoe
                                              }
                                              className="bond-input-select bond-input-disabled"
                                            >
                                              <option value={0}>Select</option>
                                              <option value={1}>Yes</option>
                                              <option value={2}>No</option>
                                            </select>
                                          </div>
                                        </Grid>
                                        <Grid item xs={4}>
                                          <div className="d-grid">
                                            <label
                                              htmlFor="Issuer Name"
                                              className="bond-label"
                                            >
                                              Green Shoe Size
                                            </label>
                                            <input
                                              id="Issuer Name"
                                              name="firstName"
                                              type="text"
                                              disabled={true}
                                              className="bond-input bond-input-disabled"
                                              onChange={(e) =>
                                                setFormikInitialData({
                                                  ...formikInitialData,
                                                  bond_green_shoe_size:
                                                    e.target.value,
                                                })
                                              }
                                              value={
                                                formikProps.values
                                                  ?.bond_green_shoe_size
                                              }
                                            />
                                          </div>
                                        </Grid>
                                        <Grid item xs={4}>
                                          <div className="d-grid">
                                            <label
                                              htmlFor="Issuer Name"
                                              className="bond-label"
                                            >
                                              Final Issue Amount (₹)
                                            </label>
                                            <input
                                              id="Issuer Name"
                                              name="firstName"
                                              type="text"
                                              disabled={true}
                                              className="bond-input bond-input-disabled"
                                              onChange={(e) =>
                                                setFormikInitialData({
                                                  ...formikInitialData,
                                                  bond_final_issue_amount:
                                                    e.target.value,
                                                })
                                              }
                                              value={
                                                formikProps.values
                                                  ?.bond_final_issue_amount
                                              }
                                            />
                                          </div>
                                        </Grid>
                                        <Grid item xs={4}>
                                          <div className="d-grid">
                                            <label
                                              htmlFor="Issuer Name"
                                              className="bond-label"
                                            >
                                              Procurement Amount (₹)
                                            </label>
                                            <input
                                              id="Issuer Name"
                                              name="firstName"
                                              type="text"
                                              disabled={true}
                                              className="bond-input bond-input-disabled"
                                              onChange={(e) =>
                                                setFormikInitialData({
                                                  ...formikInitialData,
                                                  bond_procurement_amount:
                                                    e.target.value,
                                                })
                                              }
                                              value={
                                                formikProps.values
                                                  ?.bond_procurement_amount
                                              }
                                            />
                                            {/* {formikProps.errors.bond_procurement_amount ? (<div style={{ color: "#FF0000" }}>{formikProps.errors.bond_procurement_amount}</div>) : null} */}
                                          </div>
                                        </Grid>
                                        <Grid item xs={4}>
                                          <div className="d-grid">
                                            <label
                                              htmlFor="bond_gst"
                                              className="bond-label"
                                            >
                                              GST
                                            </label>
                                            <select
                                              id="bond_gst"
                                              name="bond_gst"
                                              disabled={true}
                                              onChange={(e) => {
                                                setFormikInitialData({
                                                  ...formikInitialData,
                                                  bond_gst: parseInt(
                                                    e.target.value
                                                  ),
                                                });
                                              }}
                                              value={
                                                formikProps.values.bond_gst
                                              }
                                              className="bond-input-select bond-input-disabled"
                                            >
                                              <option value={0}>Select</option>
                                              <option value={1}>5%</option>
                                              <option value={2}>10%</option>
                                              <option value={3}>18%</option>
                                            </select>
                                          </div>
                                        </Grid>
                                        <Grid item xs={4}>
                                          <div className="row mt-2">
                                            <div className="d-grid">
                                              <label
                                                htmlFor="bond_arranger_name"
                                                className="bond-label"
                                              >
                                                Arranger Name (if any)
                                              </label>
                                              <input
                                                id="bond_arranger_name"
                                                name="bond_arranger_name"
                                                type="text"
                                                disabled={true}
                                                className="bond-input bond-input-disabled"
                                                onChange={(e) =>
                                                  setFormikInitialData({
                                                    ...formikInitialData,
                                                    bond_arranger_name:
                                                      e.target.value,
                                                  })
                                                }
                                                value={
                                                  formikProps.values
                                                    ?.bond_arranger_name
                                                }
                                              />
                                            </div>
                                          </div>
                                        </Grid>
                                        <Grid item xs={8}>
                                          <div className="row mt-1">
                                            <div className="col-6 col-md-6 col-lg-6 ">
                                              <label
                                                htmlFor="Issuer Name"
                                                className="bond-label"
                                              >
                                                UPI App No Series (For Trust)
                                                <span
                                                  style={{
                                                    color: "red",
                                                    fontSize: "16px",
                                                  }}
                                                >
                                                  *
                                                </span>
                                              </label>
                                              <div className="col-12 col-md-12 col-lg-12 d-flex">
                                                <div className="col-6 col-md-6 col-lg-6 mx-1">
                                                  <input
                                                    id="Issuer Name"
                                                    name="firstName"
                                                    type="text"
                                                    className="bond-input  w-100 "
                                                    placeholder="security upi start"
                                                    onChange={(e) =>
                                                      setFormikInitialData({
                                                        ...formikInitialData,
                                                        bond_upi_app_no_series_1:
                                                          e.target.value,
                                                      })
                                                    }
                                                    value={
                                                      formikProps.values
                                                        ?.bond_upi_app_no_series_1
                                                    }
                                                  />
                                                  {typeof formikProps.errors
                                                    .bond_upi_app_no_series_1 ===
                                                  "string" ? (
                                                    <div
                                                      style={{
                                                        color: "#FF0000",
                                                      }}
                                                    >
                                                      {
                                                        formikProps.errors
                                                          .bond_upi_app_no_series_1
                                                      }
                                                    </div>
                                                  ) : null}
                                                </div>

                                                <div className="col-6 col-md-6 col-lg-6 mx-1">
                                                  <input
                                                    id="Issuer Name"
                                                    name="firstName"
                                                    type="text"
                                                    placeholder="security upi end"
                                                    className="bond-input  w-100"
                                                    onChange={(e) =>
                                                      setFormikInitialData({
                                                        ...formikInitialData,
                                                        bond_upi_app_no_series_2:
                                                          e.target.value,
                                                      })
                                                    }
                                                    value={
                                                      formikProps.values
                                                        ?.bond_upi_app_no_series_2
                                                    }
                                                  />
                                                  {typeof formikProps.errors
                                                    .bond_upi_app_no_series_2 ===
                                                  "string" ? (
                                                    <div
                                                      style={{
                                                        color: "#FF0000",
                                                      }}
                                                    >
                                                      {
                                                        formikProps.errors
                                                          .bond_upi_app_no_series_2
                                                      }
                                                    </div>
                                                  ) : null}
                                                </div>
                                              </div>
                                            </div>
                                            <div className="col-6 col-md-6 col-lg-6 ">
                                              <label
                                                htmlFor="Issuer Name"
                                                className="bond-label"
                                              >
                                                ASBA App No Series (For Trust)
                                                <span
                                                  style={{
                                                    color: "red",
                                                    fontSize: "16px",
                                                  }}
                                                >
                                                  *
                                                </span>
                                              </label>
                                              <div className="col-12 col-md-12 col-lg-12 d-flex">
                                                <div className="col-6 col-md-6 col-lg-6 mx-1">
                                                  <input
                                                    id="Issuer Name"
                                                    name="firstName"
                                                    type="text"
                                                    placeholder="security asba start"
                                                    className="bond-input  w-100"
                                                    onChange={(e) =>
                                                      setFormikInitialData({
                                                        ...formikInitialData,
                                                        bond_asba_app_no_series_1:
                                                          e.target.value,
                                                      })
                                                    }
                                                    value={
                                                      formikProps.values
                                                        ?.bond_asba_app_no_series_1
                                                    }
                                                  />
                                                  {typeof formikProps.errors
                                                    .bond_asba_app_no_series_1 ===
                                                  "string" ? (
                                                    <div
                                                      style={{
                                                        color: "#FF0000",
                                                      }}
                                                    >
                                                      {
                                                        formikProps.errors
                                                          .bond_asba_app_no_series_1
                                                      }
                                                    </div>
                                                  ) : null}
                                                </div>
                                                <div className="col-6 col-md-6 col-lg-6 mx-1">
                                                  <input
                                                    id="Issuer Name"
                                                    name="firstName"
                                                    type="text"
                                                    placeholder="security asba end"
                                                    className="bond-input  w-100"
                                                    onChange={(e) =>
                                                      setFormikInitialData({
                                                        ...formikInitialData,
                                                        bond_asba_app_no_series_2:
                                                          e.target.value,
                                                      })
                                                    }
                                                    value={
                                                      formikProps.values
                                                        ?.bond_asba_app_no_series_2
                                                    }
                                                  />
                                                  {typeof formikProps.errors
                                                    .bond_asba_app_no_series_2 ===
                                                  "string" ? (
                                                    <div
                                                      style={{
                                                        color: "#FF0000",
                                                      }}
                                                    >
                                                      {
                                                        formikProps.errors
                                                          .bond_asba_app_no_series_2
                                                      }
                                                    </div>
                                                  ) : null}
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </Grid>
                                      </Grid>
                                    </div>
                                  </div>
                                  <br />
                                </div>
                                <div className="row">
                                  <div
                                    className="card"
                                    style={{
                                      borderTopLeftRadius: "10px",
                                      borderTopRightRadius: "10px",
                                      paddingTop: "10px",
                                      paddingBottom: "20px",
                                    }}
                                  >
                                    <div className="card-header">
                                      <h5 className="m-0 header">
                                        {" "}
                                        Disclaimer{" "}
                                      </h5>
                                    </div>
                                    <div className="card-body">
                                      <Editor
                                        editorState={addDisclamer}
                                        toolbarClassName="toolbar_form-control"
                                        wrapperClassName="editor_form-control_feedDetail"
                                        editorClassName="editor_textFeild_form-control"
                                        onEditorStateChange={(e: any) =>
                                          onDisclamerEditorStateChange(e)
                                        }
                                        placeholder="Write a Disclamer..."
                                        toolbar={wysiwygToolbar}
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="row">
                                  <div
                                    className="card"
                                    style={{
                                      borderTopLeftRadius: "10px",
                                      borderTopRightRadius: "10px",
                                      paddingTop: "10px",
                                      paddingBottom: "20px",
                                    }}
                                  >
                                    <div className="card-header">
                                      <h5 className="m-0 header">
                                        {" "}
                                        Add Issuer Details{" "}
                                      </h5>
                                    </div>
                                    <div className="card-body">
                                      {formikInitialData?.bond_issuer_details.map(
                                        (issuer_details, index) => {
                                          return (
                                            <>
                                              <Grid
                                                direction="row"
                                                container
                                                spacing={2}
                                              >
                                                <Grid item xs={4}>
                                                  <div className="d-grid">
                                                    <label
                                                      htmlFor="bond_total_annual_revenue"
                                                      className="bond-label"
                                                    >
                                                      Total Annual Revenue
                                                    </label>
                                                    <input
                                                      id="bond_total_annual_revenue"
                                                      name={`bond_issuer_details.${index}.bond_total_annual_revenue`}
                                                      type="string"
                                                      className="bond-input"
                                                      value={
                                                        issuer_details.bond_issuer_total_annual_revenue
                                                      }
                                                      onChange={(e) => {
                                                        issuer_details.bond_issuer_total_annual_revenue =
                                                          e.target.value;
                                                        setFormikInitialData({
                                                          ...formikInitialData,
                                                        });
                                                      }}
                                                    />
                                                    {/* {formikProps.errors
                                                      .bond_issuer_details !=
                                                      undefined &&
                                                    formikProps.errors
                                                      .bond_issuer_details
                                                      .length > 0 ? (
                                                      <div
                                                        style={{
                                                          color: "#FF0000",
                                                        }}
                                                      >
                                                        Issuer total annual
                                                        revenue is required
                                                      </div>
                                                    ) : null} */}
                                                  </div>
                                                </Grid>
                                                <Grid item xs={4}>
                                                  <div className="d-grid">
                                                    <label
                                                      htmlFor="bond_year_of_inception"
                                                      className="bond-label"
                                                    >
                                                      Year of Inception
                                                    </label>
                                                    <input
                                                      id="bond_year_of_inception"
                                                      name="bond_year_of_inception"
                                                      type="text"
                                                      className="bond-input"
                                                      value={
                                                        issuer_details.bond_issuer_year_of_inception
                                                      }
                                                      onChange={(e) => {
                                                        issuer_details.bond_issuer_year_of_inception =
                                                          e.target.value;
                                                        setFormikInitialData({
                                                          ...formikInitialData,
                                                        });
                                                      }}
                                                    />
                                                  </div>
                                                </Grid>
                                                <Grid item xs={4}>
                                                  <div className="d-grid">
                                                    <label
                                                      htmlFor="bond_industry"
                                                      className="bond-label"
                                                    >
                                                      Industry
                                                    </label>
                                                    <input
                                                      id="bond_industry"
                                                      name="bond_industry"
                                                      type="text"
                                                      className="bond-input"
                                                      value={
                                                        issuer_details.bond_issuer_industry
                                                      }
                                                      onChange={(e) => {
                                                        issuer_details.bond_issuer_industry =
                                                          e.target.value;
                                                        setFormikInitialData({
                                                          ...formikInitialData,
                                                        });
                                                      }}
                                                    />
                                                  </div>
                                                </Grid>
                                                <Grid item xs={4}>
                                                  <div className="d-grid">
                                                    <label
                                                      htmlFor="bond_head_office"
                                                      className="bond-label"
                                                    >
                                                      Head Office
                                                    </label>
                                                    <input
                                                      id="bond_head_office"
                                                      name="lastName"
                                                      type="text"
                                                      className="bond-input"
                                                      value={
                                                        issuer_details.bond_issuer_head_office
                                                      }
                                                      onChange={(e) => {
                                                        issuer_details.bond_issuer_head_office =
                                                          e.target.value;
                                                        setFormikInitialData({
                                                          ...formikInitialData,
                                                        });
                                                      }}
                                                    />
                                                  </div>
                                                </Grid>
                                                <Grid item xs={4}>
                                                  <div className="d-grid">
                                                    <label
                                                      htmlFor="bond_issuer_type"
                                                      className="bond-label"
                                                    >
                                                      Type of Issuer
                                                    </label>
                                                    <input
                                                      id="bond_issuer_type"
                                                      name="email"
                                                      type="text"
                                                      className="bond-input"
                                                      value={
                                                        issuer_details.bond_issuer_type
                                                      }
                                                      onChange={(e) => {
                                                        issuer_details.bond_issuer_type =
                                                          e.target.value;
                                                        setFormikInitialData({
                                                          ...formikInitialData,
                                                        });
                                                      }}
                                                    />
                                                  </div>
                                                </Grid>
                                                <Grid item xs={4}>
                                                  <div className="d-grid">
                                                    <label
                                                      htmlFor="bond_issuer_authority"
                                                      className="bond-label"
                                                    >
                                                      Current (MD/CEO)
                                                    </label>
                                                    <input
                                                      id="bond_issuer_authority"
                                                      name="email"
                                                      type="text"
                                                      className="bond-input"
                                                      value={
                                                        issuer_details.bond_issuer_authority
                                                      }
                                                      onChange={(e) => {
                                                        issuer_details.bond_issuer_authority =
                                                          e.target.value;
                                                        setFormikInitialData({
                                                          ...formikInitialData,
                                                        });
                                                      }}
                                                    />
                                                  </div>
                                                </Grid>
                                                {/* <Grid item xs={4}>
                                                  <div className="d-grid">
                                                    <label
                                                      htmlFor="bond_issuer_series"
                                                      className="bond-label"
                                                    >
                                                      Series
                                                    </label>
                                                    <input
                                                      id="bond_issuer_series"
                                                      name="bond_issuer_series"
                                                      type="text"
                                                      className="bond-input"
                                                      value={
                                                        issuer_details.bond_issuer_series
                                                      }
                                                      onChange={(e) => {
                                                        issuer_details.bond_issuer_series =
                                                          e.target.value;
                                                        setFormikInitialData({
                                                          ...formikInitialData,
                                                        });
                                                      }}
                                                    />
                                                  </div>
                                                </Grid> */}

                                                {/* <Grid item xs={4}>
                                                  <div className="d-grid">
                                                    <label
                                                      htmlFor="type_of_issuer"
                                                      className="bond-label"
                                                    >
                                                      Frequency of Interest
                                                    </label>
                                                    <select
                                                      id="type_of_issuer"
                                                      name="email"
                                                      onChange={
                                                        formikProps.handleChange
                                                      }
                                                      className="bond-input-select"
                                                    >
                                                      <option value={0}>
                                                        Select
                                                      </option>
                                                      <option value={1}>
                                                        Annual
                                                      </option>
                                                      <option value={2}>
                                                        Semi Annual
                                                      </option>
                                                      <option value={3}>
                                                        Quarterly
                                                      </option>
                                                      <option value={3}>
                                                        Monthly
                                                      </option>
                                                    </select>
                                                  </div>
                                                </Grid> */}

                                                {/* <Grid item xs={4}>
                                                  <div className="d-grid">
                                                    <label
                                                      htmlFor="bond_issuer_effective_yield"
                                                      className="bond-label"
                                                    >
                                                      Effective Yield
                                                    </label>
                                                    <input
                                                      id="bond_issuer_effective_yield"
                                                      name="bond_issuer_effective_yield"
                                                      type="text"
                                                      className="bond-input"
                                                      value={
                                                        issuer_details.bond_issuer_effective_yield
                                                      }
                                                      onChange={(e) => {
                                                        issuer_details.bond_issuer_effective_yield =
                                                          e.target.value;
                                                        setFormikInitialData({
                                                          ...formikInitialData,
                                                        });
                                                      }}
                                                    />
                                                  </div>
                                                </Grid> */}
                                                {/* <Grid item xs={4}>
                                                  <div className="d-grid">
                                                    <label
                                                      htmlFor="bond_issuers_profile_link"
                                                      className="bond-label"
                                                    >
                                                      Issuers Profile Link
                                                    </label>
                                                    <input
                                                      id="bond_issuers_profile_link"
                                                      name="bond_issuers_profile_link"
                                                      type="text"
                                                      className="bond-input-upload-link"
                                                      value={
                                                        issuer_details.bond_issuer_profile_link
                                                      }
                                                      onChange={(e) => {
                                                        issuer_details.bond_issuer_profile_link =
                                                          e.target.value;
                                                        setFormikInitialData({
                                                          ...formikInitialData,
                                                        });
                                                      }}
                                                    />
                                                  </div>
                                                </Grid> */}
                                                {/* <Grid item xs={4}>
                                                  <div className="d-grid">
                                                    <label
                                                      htmlFor="bond_issuers_finance_details"
                                                      className="bond-label"
                                                    >
                                                      Issuers Finance Details
                                                    </label>
                                                    <input
                                                      id="bond_issuers_finance_details"
                                                      name="bond_issuers_finance_details"
                                                      type="text"
                                                      className="bond-input-upload-link"
                                                      value={
                                                        issuer_details.bond_issuer_finance_details
                                                      }
                                                      onChange={(e) => {
                                                        issuer_details.bond_issuer_finance_details =
                                                          e.target.value;
                                                        setFormikInitialData({
                                                          ...formikInitialData,
                                                        });
                                                      }}
                                                    />
                                                  </div>
                                                </Grid> */}
                                                {/* <Grid item xs={4}>
                                                  <div className="d-grid">
                                                    <label
                                                      htmlFor="bond_issue_documents"
                                                      className="bond-label"
                                                    >
                                                      Upload ISSUE DOCUMENTS
                                                    </label>

                                                    <div
                                                      {...getRootProps({
                                                        onClick: (event) => {
                                                          setUploadFeild(
                                                            (e) => ({
                                                              ...e,
                                                              feild_type:
                                                                "bond_issuer_documents",
                                                            })
                                                          );
                                                        },
                                                      })}
                                                    >
                                                      <input
                                                        {...getInputProps()}
                                                      />
                                                      {
                                                        <label className="bond-input-upload-file">
                                                          {getURLExtension(
                                                            formikRef.current
                                                              ?.values
                                                              .bond_issuer_details[
                                                              index
                                                            ]
                                                              .bond_issuer_documents,
                                                            "Upload ISSUE DOCUMENTS"
                                                          )}
                                                        </label>
                                                      }
                                                    </div>
                                                  </div>
                                                </Grid> */}
                                                <Grid item xs={12}>
                                                  {/* <div className="d-grid">
                                                    <label
                                                      htmlFor="bond_issuer_about"
                                                      className="bond-label"
                                                    >
                                                      About issuer
                                                    </label>
                                                    <textarea
                                                      id="bond_issuer_about"
                                                      name="bond_issuer_about"
                                                      rows={4}
                                                      cols={50}
                                                      maxLength={100}
                                                      className="bond-input"
                                                      placeholder="100 words max*"
                                                      value={
                                                        issuer_details.bond_issuer_about
                                                      }
                                                      onChange={(e) => {
                                                        issuer_details.bond_issuer_about =
                                                          e.target.value;
                                                        setFormikInitialData({
                                                          ...formikInitialData,
                                                        });
                                                      }}
                                                    />
                                                  </div> */}

                                                  {/* <Editor
                                                  editorState={addIssuerAbout}
                                                  toolbarClassName="toolbar_form-control"
                                                  wrapperClassName="editor_form-control_feedDetail"
                                                  editorClassName="editor_textFeild_form-control"
                                                  onEditorStateChange={(e: any) => onIssuerAboutEditorStateChange(e)}
                                                  placeholder="Write a Comment..."
                                                  toolbar={wysiwygToolbar}
                                                  wrapperStyle={{ margin: "10px 0px" }}
                                                /> */}
                                                </Grid>

                                                {/* <Grid item xs={4}>
                                                  <div className="d-grid">
                                                    <label
                                                      htmlFor="issuer_type"
                                                      className="bond-label"
                                                    >
                                                      Issuer's Type
                                                    </label>
                                                    <select
                                                      id="issuer_type"
                                                      name="email"
                                                      //   onChange={e => { setFormikInitialData(({ ...formikInitialData, : parseInt(e.target.value) })); }}
                                                      //   value={formikProps.values.bond_gst}
                                                      className="bond-input-select bond-input-disabled"
                                                      disabled={true}
                                                    >
                                                      <option value="0">
                                                        Select
                                                      </option>
                                                      <option value="1">
                                                        Private Sector Corporate
                                                      </option>
                                                      <option value="2">
                                                        Public Sector
                                                        Undertaking
                                                      </option>
                                                      <option value="3">
                                                        Private Bank
                                                      </option>
                                                      <option value="4">
                                                        Public Sector Bank
                                                      </option>
                                                      <option value="5">
                                                        State Government
                                                      </option>
                                                      <option value="6">
                                                        Central Government
                                                      </option>
                                                    </select>
                                                  </div>
                                                </Grid> */}
                                                {/* <Grid item xs={4}>
                                                  <div className="d-grid">
                                                    <label
                                                      htmlFor="type_of_issuer"
                                                      className="bond-label"
                                                    >
                                                      Tenor (years)
                                                    </label>
                                                    <input
                                                      id="bond_issuer_series"
                                                      name="bond_issuer_series"
                                                      type="number"
                                                      disabled={true}
                                                      className="bond-input bond-input-disabled"
                                                      value={
                                                        issuer_details.bond_issuer_tenor
                                                      }
                                                      onChange={(e) => {
                                                        issuer_details.bond_issuer_tenor =
                                                          parseInt(
                                                            e.target.value
                                                          );
                                                        setFormikInitialData({
                                                          ...formikInitialData,
                                                        });
                                                      }}
                                                    />
                                                
                                                  </div>
                                                </Grid> */}
                                                {/* <Grid item xs={4}>
                                                  <div className="d-grid">
                                                    <label
                                                      htmlFor="type_of_issuer"
                                                      className="bond-label"
                                                    >
                                                      Coupon Type
                                                    </label>
                                                    <select
                                                      id="type_of_issuer"
                                                      name="email"
                                                      onChange={
                                                        formikProps.handleChange
                                                      }
                                                      className="bond-input-select bond-input-disabled"
                                                      disabled={true}
                                                    >
                                                      <option value={0}>
                                                        Select
                                                      </option>
                                                      <option value={1}>
                                                        Interest Rate to be paid
                                                        (cannot be part of Drop
                                                        Down Values)
                                                      </option>
                                                    </select>
                                                  </div>
                                                </Grid> */}
                                                {/* <Grid item xs={4}>
                                                  <div className="d-grid">
                                                    <label
                                                      htmlFor="Bond Name"
                                                      className="bond-label"
                                                    >
                                                      First IP Date
                                                    </label>
                                                   
                                                    <DatePicker
                                                      selected={localStringToIST(
                                                        issuer_details.bond_issuer_first_ip_date
                                                      )}
                                                      dateFormat="dd/MM/yyyy"
                                                      closeOnScroll={true}
                                                      onChange={(
                                                        date: Date
                                                      ) => {
                                                        issuer_details.bond_issuer_first_ip_date =
                                                          moment(date)
                                                            .format(
                                                              "YYYY-MM-DD"
                                                            )
                                                            .toString();
                                                        setFormikInitialData({
                                                          ...formikInitialData,
                                                        });
                                                      }}
                                                      placeholderText="DD/MM/YYYY"
                                                      className="date-picker-style bond-input-disabled"
                                                      peekNextMonth
                                                      showMonthDropdown
                                                      showYearDropdown
                                                      dropdownMode="select"
                                                      customInput={
                                                        <CustomInput />
                                                      }
                                                      disabled={true}
                                                    />
                                                  </div>
                                                </Grid> */}
                                                {/* <Grid item xs={4}>
                                                  <div className="d-grid">
                                                    <label
                                                      htmlFor="bond_issuers_profile_link"
                                                      className="bond-label"
                                                    >
                                                      IP Dates
                                                    </label>
                                                    <input
                                                      id="bond_issuers_profile_link"
                                                      name="bond_issuers_profile_link"
                                                      type="text"
                                                      disabled={true}
                                                      className="bond-input-upload-link bond-input-disabled"
                                                      // value={issuer_details.}
                                                      // onChange={e => { issuer_details.bond_issuer_profile_link = e.target.value; setFormikInitialData(({ ...formikInitialData })) }}
                                                    />
                                                  </div>
                                                </Grid> */}
                                              </Grid>
                                            </>
                                          );
                                        }
                                      )}
                                    </div>
                                  </div>
                                </div>
                                <div className="row">
                                  <div
                                    className="card"
                                    style={{
                                      borderTopLeftRadius: "10px",
                                      borderTopRightRadius: "10px",
                                      paddingTop: "10px",
                                      paddingBottom: "20px",
                                    }}
                                  >
                                    <div className="card-header d-flex justify-content-between">
                                      <h5 className="m-0 header">
                                        Issuer Documents
                                      </h5>
                                      <div>
                                        <label
                                          className="bond-input-upload-file"
                                          onClick={() =>
                                            fileInputRefIssuerDoc.current?.click()
                                          } // Trigger file dialog on click
                                        >
                                          Upload New Issuer Document
                                        </label>
                                        <input
                                          type="file"
                                          ref={fileInputRefIssuerDoc}
                                          style={{ display: "none" }}
                                          onChange={(event) =>
                                            handleFileChange(event, "issuerDoc")
                                          } // Attach the handleFileChange function
                                        />
                                      </div>
                                    </div>

                                    <div className="card-body">
                                      {formikProps?.values
                                        ?.bond_issuer_details[0]
                                        ?.bond_issuer_documents &&
                                        Array.isArray(
                                          formikProps.values
                                            .bond_issuer_details[0]
                                            .bond_issuer_documents
                                        ) && (
                                          <table className="table">
                                            <thead>
                                              <tr>
                                                <th>Document Name</th>
                                                {/* <th>Document Status</th> */}
                                                <th>Action</th>
                                              </tr>
                                            </thead>
                                            <tbody>
                                              {formikProps.values.bond_issuer_details[0]?.bond_issuer_documents.map(
                                                (doc: any, index: number) => (
                                                  <tr key={index}>
                                                    <td>
                                                      <input
                                                        id={`issuer_document_name_${index}`}
                                                        name="security_issuer_document_name"
                                                        className="form-control"
                                                        value={
                                                          doc.security_issuer_document_name ||
                                                          ""
                                                        }
                                                        onChange={(e) => {
                                                          const {
                                                            name,
                                                            value,
                                                          } = e.target;

                                                          formikProps.values.bond_issuer_details[0].bond_issuer_documents[
                                                            index
                                                          ] = {
                                                            ...formikProps
                                                              .values
                                                              .bond_issuer_details[0]
                                                              .bond_issuer_documents[
                                                              index
                                                            ],
                                                            [name]: value, // Update the name field
                                                          };

                                                          formikProps.setFieldValue(
                                                            "bond_issuer_details[0].bond_issuer_documents",
                                                            formikProps.values
                                                              .bond_issuer_details[0]
                                                              .bond_issuer_documents
                                                          );
                                                        }}
                                                      />
                                                    </td>

                                                    {/* <td>
                                                      <label className="switch">
                                                        <input
                                                          type="checkbox"
                                                          id={`security_issuer_document_status${index}`}
                                                          checked={
                                                            doc.security_issuer_document_status ||
                                                            false
                                                          }
                                                          onChange={(e) => {
                                                            const { checked } =
                                                              e.target;

                                                            formikProps.values.bond_issuer_details[0].bond_issuer_documents[
                                                              index
                                                            ] = {
                                                              ...formikProps
                                                                .values
                                                                .bond_issuer_details[0]
                                                                .bond_issuer_documents[
                                                                index
                                                              ],
                                                              security_issuer_document_status:
                                                                checked,
                                                            };

                                                            formikProps.setFieldValue(
                                                              "bond_issuer_details[0].bond_issuer_documents",
                                                              formikProps.values
                                                                .bond_issuer_details[0]
                                                                .bond_issuer_documents
                                                            );
                                                          }}
                                                        />
                                                        <span className="slider round"></span>
                                                      </label>
                                                    </td> */}

                                                    <td>
                                                      <i
                                                        className="fas fa-eye table-icons"
                                                        style={{
                                                          cursor: "pointer",
                                                          marginRight: "10px",
                                                        }}
                                                        onClick={() => {
                                                          if (
                                                            doc.security_issuer_document_file
                                                          ) {
                                                            const link =
                                                              document.createElement(
                                                                "a"
                                                              );
                                                            link.href =
                                                              doc.security_issuer_document_file;
                                                            link.download = ""; // Optional: set a custom file name
                                                            link.click();
                                                          }
                                                        }}
                                                      ></i>

                                                      <i
                                                        className="fas fa-trash table-icons"
                                                        style={{
                                                          cursor: "pointer",
                                                          color: "red",
                                                        }}
                                                        onClick={() => {
                                                          const issuerDocuments =
                                                            formikProps.values
                                                              .bond_issuer_details[0]
                                                              .bond_issuer_documents;

                                                          if (
                                                            issuerDocuments &&
                                                            issuerDocuments[
                                                              index
                                                            ]
                                                          ) {
                                                            setRemovedDocuments(
                                                              (prev) => [
                                                                ...prev,
                                                                issuerDocuments[
                                                                  index
                                                                ]
                                                                  .security_issuer_document_file,
                                                              ]
                                                            );

                                                            issuerDocuments.splice(
                                                              index,
                                                              1
                                                            );

                                                            formikProps.setFieldValue(
                                                              "issuer.issuer_document",
                                                              issuerDocuments
                                                            );
                                                          }
                                                        }}
                                                      ></i>
                                                    </td>
                                                  </tr>
                                                )
                                              )}
                                            </tbody>
                                          </table>
                                        )}
                                    </div>
                                  </div>
                                </div>
                                <div className="row">
                                  <div
                                    className="card"
                                    style={{
                                      borderTopLeftRadius: "10px",
                                      borderTopRightRadius: "10px",
                                      paddingTop: "10px",
                                      paddingBottom: "20px",
                                    }}
                                  >
                                    <div className="card-header d-flex justify-content-between">
                                      <h5 className="m-0 header">
                                        Security Document
                                      </h5>
                                      <div>
                                        <label
                                          className="bond-input-upload-file"
                                          onClick={() =>
                                            fileInputRef.current?.click()
                                          } // Trigger file dialog on click
                                        >
                                          Upload Security Document
                                        </label>
                                        <input
                                          type="file"
                                          ref={fileInputRef}
                                          style={{ display: "none" }}
                                          onChange={(event) =>
                                            handleFileChange(
                                              event,
                                              "security_document"
                                            )
                                          } // Attach the handleFileChange function
                                        />
                                      </div>
                                    </div>

                                    <div className="card-body">
                                      {formikProps?.values
                                        ?.bond_security_document &&
                                        Array.isArray(
                                          formikProps.values
                                            .bond_security_document
                                        ) && (
                                          <table className="table">
                                            <thead>
                                              <tr>
                                                <th>Document Name</th>
                                                {/* <th>Document Status</th> */}
                                                <th>Action</th>
                                              </tr>
                                            </thead>
                                            <tbody>
                                              {formikProps.values.bond_security_document.map(
                                                (doc: any, index: number) => (
                                                  <tr key={index}>
                                                    <td>
                                                      <input
                                                        id={`security_document_name_${index}`}
                                                        name={`security_document_name`}
                                                        className="form-control"
                                                        value={
                                                          doc.security_document_name ||
                                                          ""
                                                        }
                                                        onChange={(e) => {
                                                          // Update the bond_security_document array directly
                                                          const updatedBondSecurityDocuments =
                                                            [
                                                              ...formikProps
                                                                .values
                                                                .bond_security_document,
                                                            ];
                                                          updatedBondSecurityDocuments[
                                                            index
                                                          ] = {
                                                            ...updatedBondSecurityDocuments[
                                                              index
                                                            ],
                                                            security_document_name:
                                                              e.target.value,
                                                            // Update the value of security_document_name
                                                          };

                                                          console.log(
                                                            updatedBondSecurityDocuments
                                                          );
                                                          setFormikInitialData(
                                                            (prevState) => ({
                                                              ...prevState, // Preserve the rest of the state
                                                              bond_security_document:
                                                                updatedBondSecurityDocuments, // Update only the bond_security_document field
                                                            })
                                                          );
                                                          // // Update Formik's state
                                                          // formikProps.setFieldValue(
                                                          //   "bond_security_document",
                                                          //   updatedBondSecurityDocuments
                                                          // );
                                                        }}
                                                      />
                                                    </td>

                                                    <td>
                                                      <i
                                                        className="fas fa-eye table-icons"
                                                        style={{
                                                          cursor: "pointer",
                                                          marginRight: "10px",
                                                        }}
                                                        onClick={() => {
                                                          const link =
                                                            document.createElement(
                                                              "a"
                                                            );
                                                          link.href =
                                                            doc.security_document_file;
                                                          link.download =
                                                            "Security Document"; // Optional: set a custom file name
                                                          link.click();
                                                        }}
                                                      ></i>

                                                      <i
                                                        className="fas fa-trash table-icons"
                                                        style={{
                                                          cursor: "pointer",
                                                          color: "red",
                                                        }}
                                                        onClick={() => {
                                                          const bondSecurityDocuments =
                                                            [
                                                              ...(formikProps
                                                                .values
                                                                .bond_security_document ||
                                                                []),
                                                            ];

                                                          // Check if 0th index exists
                                                          if (
                                                            bondSecurityDocuments[
                                                              index
                                                            ]
                                                          ) {
                                                            // Add the document to removedDocuments
                                                            setRemovedDocuments(
                                                              (prev) => [
                                                                ...prev,
                                                                bondSecurityDocuments[
                                                                  index
                                                                ]
                                                                  .security_document_file,
                                                              ]
                                                            );

                                                            // Remove the document from the array
                                                            bondSecurityDocuments.splice(
                                                              index,
                                                              1
                                                            );

                                                            // Update the formik field for bond_security_document
                                                            formikProps.setFieldValue(
                                                              "bond_security_document",
                                                              bondSecurityDocuments
                                                            );
                                                          }
                                                        }}
                                                      ></i>
                                                    </td>
                                                  </tr>
                                                )
                                              )}
                                            </tbody>
                                          </table>
                                        )}
                                    </div>
                                  </div>
                                </div>
                                <div className="row">
                                  <div
                                    className="card"
                                    style={{
                                      borderTopLeftRadius: "10px",
                                      borderTopRightRadius: "10px",
                                      paddingTop: "10px",
                                      paddingBottom: "20px",
                                    }}
                                  >
                                    <div className="card-header d-flex justify-content-between">
                                      <h5 className="m-0 header">
                                        {" "}
                                        Bond's Rating Details{" "}
                                      </h5>
                                      {/* <div
                                        className="bond-add-btn"
                                        onClick={() => addBondRating()}
                                      >
                                        <a className="text-white">
                                          <i
                                            className="fa fa-plus-circle mx-2"
                                            aria-hidden="true"
                                          ></i>
                                          Add
                                        </a>
                                      </div> */}
                                    </div>
                                    <div className="card-body">
                                      {formikProps.values?.bond_rating_details?.map(
                                        (rating: any, index: any) => {
                                          return (
                                            <>
                                              <Grid
                                                direction="row"
                                                container
                                                spacing={2}
                                                justifyContent="center"
                                                alignItems="center"
                                              >
                                                <Grid item xs={3.5}>
                                                  <div className="d-grid">
                                                    <label
                                                      htmlFor="bond_crisil_short_rating"
                                                      className="bond-label"
                                                    >
                                                      Rating Agency Name
                                                    </label>
                                                    <input
                                                      id="bond_crisil_short_rating"
                                                      name="bond_crisil_short_rating"
                                                      type="text"
                                                      className="bond-input"
                                                      value={
                                                        rating.bond_rating_agency
                                                      }
                                                      onChange={(e) => {
                                                        rating.bond_rating_agency =
                                                          e.target.value;
                                                        setFormikInitialData({
                                                          ...formikInitialData,
                                                        });
                                                      }}
                                                    />
                                                  </div>
                                                </Grid>
                                                <Grid item xs={3.5}>
                                                  <div className="d-grid">
                                                    <label
                                                      htmlFor="bond_crisil_short_rating"
                                                      className="bond-label"
                                                    >
                                                      Rating
                                                    </label>
                                                    {/* <input
                                                      id="bond_id"
                                                      name="bond_id"
                                                      type="text"
                                                      className="bond-input"
                                                      value={rating.bond_rating}
                                                      onChange={(e) => {
                                                        rating.bond_rating =
                                                          e.target.value;
                                                        setFormikInitialData({
                                                          ...formikInitialData,
                                                        });
                                                      }}
                                                    /> */}
                                                    <select
                                                      id="bond_id"
                                                      name="bond_id"
                                                      value={rating.bond_rating}
                                                      onChange={(e) => {
                                                        rating.bond_rating =
                                                          e.target.value;
                                                        setFormikInitialData({
                                                          ...formikInitialData,
                                                        });
                                                      }}
                                                      className="bond-input-select"
                                                    >
                                                      <option
                                                        disabled
                                                        selected
                                                        value={0}
                                                      >
                                                        Select Rating
                                                      </option>
                                                      <option value={1}>
                                                        A
                                                      </option>
                                                      <option value={3}>
                                                        AA
                                                      </option>
                                                      <option value={6}>
                                                        AAA
                                                      </option>
                                                      <option value={7}>
                                                        BBB
                                                      </option>
                                                      {/* <option value={3}>
                                                          Yearly
                                                        </option>
                                                        <option value={4}>
                                                          Maturity
                                                        </option> */}
                                                    </select>
                                                  </div>
                                                </Grid>
                                                <Grid item xs={4}>
                                                  {/* <div className="d-grid">
                                                    <label
                                                      htmlFor="bond_rating_certificate"
                                                      className="bond-label"
                                                    >
                                                      Upload Rating Certificate
                                                    </label>
                                                    <div
                                                      {...getRootProps({
                                                        onClick: (event) => {
                                                          setUploadFeild({
                                                            feild_type:
                                                              "bond_rating_certificate",
                                                            inx: index,
                                                          });
                                                        },
                                                      })}
                                                    >
                                                      <input
                                                        {...getInputProps()}
                                                      />
                                                      {
                                                        <label className="bond-input-upload-file">
                                                          {getURLExtension(
                                                            formikRef.current
                                                              ?.values
                                                              .bond_rating_details[
                                                              index
                                                            ]
                                                              ?.bond_rating_certificate,
                                                            "Upload Rating Ceritificate"
                                                          )}
                                                        </label>
                                                      }
                                                    </div>
                                                  </div> */}
                                                </Grid>
                                                <Grid
                                                  direction="row"
                                                  item
                                                  xs={1}
                                                  justifyContent="center"
                                                  alignItems="center"
                                                >
                                                  <div className="d-flex justify-content-end">
                                                    {/* <div className="bond-add-btn-minus">
                                                      <a
                                                        onClick={() =>
                                                          removeBondRating(
                                                            index
                                                          )
                                                        }
                                                        style={{
                                                          cursor: "pointer",
                                                        }}
                                                      >
                                                        <span>-</span>
                                                      </a>
                                                    </div> */}
                                                  </div>
                                                </Grid>
                                                {/* <div className='d-flex align-items-center'>
                                                                                                <div className='bond-add-btn-minus'>
                                                                                                    <a onClick={() => removeBondRating(index)} style={{ cursor: "pointer" }}><span>-</span></a>
                                                                                                </div>
                                                                                            </div> */}
                                              </Grid>
                                            </>
                                          );
                                        }
                                      )}
                                    </div>
                                  </div>
                                </div>

                                {/* <div className="row">
                                  <div
                                    className="card"
                                    style={{
                                      borderTopLeftRadius: "10px",
                                      borderTopRightRadius: "10px",
                                      paddingTop: "10px",
                                      paddingBottom: "20px",
                                    }}
                                  >
                                    <div className="card-header d-flex justify-content-between">
                                      <h5 className="m-0 header">
                                        {" "}
                                        About This Bond
                                      </h5>
                                    </div>
                                    <div className="card-body">
                                      <div className="text-left">
                                        <div className="d-grid">
                                          <textarea
                                            id="bond_about_this_ipo"
                                            name="bond_about_this_ipo"
                                            rows={4}
                                            cols={50}
                                            // maxLength={100}
                                            // placeholder="100 words max*"
                                            className="bond-input"
                                            onChange={(e) =>
                                              setFormikInitialData({
                                                ...formikInitialData,
                                                bond_about_this_ipo:
                                                  e.target.value,
                                              })
                                            }
                                            value={
                                              formikProps.values
                                                ?.bond_about_this_ipo
                                            }
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div> */}
                                <div className="row">
                                  <div
                                    className="card"
                                    style={{
                                      borderTopLeftRadius: "10px",
                                      borderTopRightRadius: "10px",
                                      paddingTop: "10px",
                                      paddingBottom: "20px",
                                    }}
                                  >
                                    <div className="card-header">
                                      <h5 className="m-0 header">
                                        {" "}
                                        Add Key Highlights{" "}
                                      </h5>
                                    </div>
                                    <div className="card-body">
                                      <Editor
                                        editorState={addKeyHeighlights}
                                        toolbarClassName="toolbar_form-control"
                                        wrapperClassName="editor_form-control_feedDetail"
                                        editorClassName="editor_textFeild_form-control"
                                        onEditorStateChange={(e: any) =>
                                          onKeyHeighlightsEditorStateChange(e)
                                        }
                                        placeholder="Write a Key Highlights..."
                                        toolbar={wysiwygToolbar}
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="row text-end my-3 py-5 ">
                                  <div className="">
                                    <a
                                      className="cancel-btn mx-3"
                                      onClick={() =>
                                        saveAsDraft(formikProps?.values)
                                      }
                                    >
                                      Save as Draft
                                    </a>

                                    <button type="submit" className="save-btn">
                                      {/* {location.state.mode == "edit"
                                        ? "Update"
                                        : "Save"} */}
                                      Save & Continue
                                    </button>
                                  </div>
                                </div>
                                <Grid direction="row" container spacing={2}>
                                  <Grid item xs={4}>
                                    <div className="d-grid">
                                      <div className="d-flex justify-content-between">
                                        <label
                                          htmlFor="bond_asba_details"
                                          className="bond-label"
                                        >
                                          Upload ASBA Details
                                          {/* <span style={{color:'red',fontSize:'16px'}}>
                                                *
                                              </span> */}
                                        </label>
                                        <a onClick={handleDownload}>
                                          Sample Excel
                                          <img
                                            src="../../assets/images/download.svg"
                                            style={{
                                              width: "12px",
                                              marginBottom: "2px",
                                              cursor: "pointer",
                                              marginLeft: "4px",
                                            }}
                                          />
                                        </a>
                                      </div>
                                      <div
                                        {...getRootProps({
                                          onClick: (event) => {
                                            setUploadFeild((e) => ({
                                              ...e,
                                              feild_type: "bond_asba_details",
                                            }));
                                          },
                                        })}
                                      >
                                        <input
                                          {...getInputProps()}
                                          name="bond_asba_details"
                                        />
                                        {
                                          <label className="bond-input-upload-file">
                                            Upload ASBA Details
                                          </label>
                                        }
                                      </div>
                                    </div>
                                  </Grid>
                                </Grid>
                                <div className="row">
                                  <div
                                    className="card"
                                    style={{
                                      borderTopLeftRadius: "10px",
                                      borderTopRightRadius: "10px",
                                      paddingTop: "10px",
                                      paddingBottom: "20px",
                                    }}
                                  >
                                    <div className="card-header d-flex justify-content-between align-items-center">
                                      <div className="d-flex justify-content-start">
                                        <h5 className="m-0 header">
                                          {" "}
                                          Specific Terms for Each Series of NCDs
                                          Details{" "}
                                        </h5>
                                      </div>
                                      <div className="d-flex justify-content-end align-items-center ">
                                        <div className="form-check form-switch mx-2">
                                          <label
                                            className="form-check-label mx-1"
                                            htmlFor="flexSwitchCheckChecked"
                                          >
                                            Disable
                                          </label>
                                          <input
                                            className="form-check-input mx-0 mt-1 float-none"
                                            type="checkbox"
                                            role="switch"
                                            id="flexSwitchCheckChecked"
                                            onChange={(e) => {
                                              setFormikInitialData({
                                                ...formikInitialData,
                                                bond_ncd_status:
                                                  e.target.checked == true
                                                    ? 1
                                                    : 0,
                                              });
                                            }}
                                            checked={
                                              formikProps.values
                                                ?.bond_ncd_status ==
                                              NCDStatus.Active
                                                ? true
                                                : false
                                            }
                                          />
                                          <label
                                            className="form-check-label mx-1"
                                            htmlFor="flexSwitchCheckChecked"
                                          >
                                            Enable
                                          </label>
                                        </div>
                                        <div
                                          className="bond-add-btn"
                                          onClick={() => updateNDCSeriesList()}
                                        >
                                          <a className="text-white">
                                            <i
                                              className="fa fa-plus-circle mx-2"
                                              aria-hidden="true"
                                            ></i>
                                            Add
                                          </a>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="card-body">
                                      <div
                                        className="table-responsive "
                                        data-pattern="priority-columns"
                                      >
                                        <table
                                          id="tech-companies-1"
                                          className={
                                            formikProps.values
                                              ?.bond_ncd_status == 0
                                              ? "table table table-bordered disabled"
                                              : "table table table-bordered"
                                          }
                                        >
                                          <thead className="city-table">
                                            <tr>
                                              <th></th>
                                              <th>Name</th>
                                              {formikProps.values?.bond_ncd_series?.map(
                                                (
                                                  res: APIData.BondNcdSeries,
                                                  index: number
                                                ) => {
                                                  return (
                                                    <th>
                                                      Series {toRoman(index)}
                                                      <span
                                                        onClick={() => {
                                                          handleRemoveNCDseries(
                                                            index
                                                          );
                                                        }}
                                                      >
                                                        <img
                                                          src="../../assets/images/close-btn.svg"
                                                          className="ncd-series-close-btn"
                                                        ></img>
                                                      </span>
                                                    </th>
                                                  );
                                                }
                                              )}
                                            </tr>
                                          </thead>
                                          <tbody>
                                            <tr>
                                              <td>
                                                <input
                                                  className="form-check-input"
                                                  type="checkbox"
                                                  id="bond_ncd_frequency_of_interest_payment"
                                                  onChange={(check) => {
                                                    onUpdateNCDAvailable(
                                                      check.target.checked,
                                                      "bond_ncd_frequency_of_interest_payment"
                                                    );
                                                  }}
                                                  checked={
                                                    formikRef.current?.values
                                                      ?.bond_ncd_available[0]
                                                      ?.bond_ncd_frequency_of_interest_payment ==
                                                    0
                                                      ? false
                                                      : true
                                                  }
                                                />
                                              </td>
                                              <td>
                                                Frequency of Interest Payment
                                              </td>
                                              {formikProps.values?.bond_ncd_series?.map(
                                                (
                                                  feq: APIData.BondNcdSeries,
                                                  inx: number
                                                ) => {
                                                  return (
                                                    <td>
                                                      <select
                                                        id={
                                                          "bond_ncd_frequency_of_interest_payment" +
                                                          inx
                                                        }
                                                        name="bond_ncd_frequency_of_interest_payment"
                                                        style={{
                                                          width: "100%",
                                                        }}
                                                        onChange={(e) => {
                                                          feq.bond_ncd_frequency_of_interest_payment =
                                                            e.target.value;
                                                          console.log(
                                                            e.target.value
                                                          );
                                                          setFormikInitialData({
                                                            ...formikInitialData,
                                                          });
                                                        }}
                                                        value={
                                                          feq.bond_ncd_frequency_of_interest_payment
                                                        }
                                                        className="bond-input-select"
                                                      >
                                                        <option value={0}>
                                                          Select
                                                        </option>
                                                        <option value={1}>
                                                          Monthly
                                                        </option>
                                                        <option value={2}>
                                                          Quarterly
                                                        </option>
                                                        <option value={3}>
                                                          Half Yearly
                                                        </option>
                                                        <option value={4}>
                                                          Annually
                                                        </option>
                                                        <option value={5}>
                                                          Cumulative
                                                        </option>
                                                        {/* <option value={3}>
                                                          Yearly
                                                        </option>
                                                        <option value={4}>
                                                          Maturity
                                                        </option> */}
                                                      </select>
                                                      {formikProps.errors
                                                        .bond_ncd_series ? (
                                                        <div
                                                          style={{
                                                            color: "#FF0000",
                                                          }}
                                                        ></div>
                                                      ) : null}
                                                    </td>
                                                  );
                                                }
                                              )}
                                            </tr>
                                            <tr>
                                              <td>
                                                <input
                                                  className="form-check-input"
                                                  type="checkbox"
                                                  id="bond_ncd_minimum_application"
                                                  onChange={(check) => {
                                                    onUpdateNCDAvailable(
                                                      check.target.checked,
                                                      "bond_ncd_minimum_application"
                                                    );
                                                  }}
                                                  checked={
                                                    formikProps.values
                                                      ?.bond_ncd_available[0]
                                                      ?.bond_ncd_minimum_application ==
                                                    0
                                                      ? false
                                                      : true
                                                  }
                                                />
                                              </td>
                                              <td>Minimum Application (₹)</td>
                                              {formikProps.values?.bond_ncd_series?.map(
                                                (
                                                  feq: APIData.BondNcdSeries,
                                                  inx: number
                                                ) => {
                                                  return (
                                                    <td>
                                                      <input
                                                        id={
                                                          "bond_ncd_minimum_application" +
                                                          inx
                                                        }
                                                        name="bond_ncd_minimum_application"
                                                        type="number"
                                                        className="bond-input"
                                                        value={
                                                          feq.bond_ncd_minimum_application
                                                        }
                                                        onChange={(e) =>
                                                          // onUpdateNCDSeries(e.target.value, "bond_ncd_minimum_application", inx)
                                                          {
                                                            feq.bond_ncd_minimum_application =
                                                              parseInt(
                                                                e.target.value
                                                              );
                                                            setFormikInitialData(
                                                              {
                                                                ...formikInitialData,
                                                              }
                                                            );
                                                            console.log(
                                                              typeof e.target
                                                                .value
                                                            );
                                                          }
                                                        }
                                                      />
                                                    </td>
                                                  );
                                                }
                                              )}
                                            </tr>
                                            <tr>
                                              <td>
                                                <input
                                                  className="form-check-input"
                                                  type="checkbox"
                                                  id="bond_ncd_in_multiple_terms_of_thereafter"
                                                  onChange={(check) => {
                                                    onUpdateNCDAvailable(
                                                      check.target.checked,
                                                      "bond_ncd_in_multiple_terms_of_thereafter"
                                                    );
                                                  }}
                                                  checked={
                                                    formikProps.values
                                                      ?.bond_ncd_available[0]
                                                      ?.bond_ncd_in_multiple_terms_of_thereafter ==
                                                    0
                                                      ? false
                                                      : true
                                                  }
                                                />
                                              </td>
                                              <td>
                                                In Multiples of thereafter (₹)
                                              </td>
                                              {formikProps.values?.bond_ncd_series?.map(
                                                (
                                                  feq: APIData.BondNcdSeries,
                                                  inx: number
                                                ) => {
                                                  return (
                                                    <td>
                                                      <input
                                                        id={
                                                          "bond_ncd_in_multiple_terms_of_thereafter" +
                                                          inx
                                                        }
                                                        name="bond_ncd_in_multiple_terms_of_thereafter"
                                                        type="number"
                                                        className="bond-input"
                                                        value={
                                                          feq.bond_ncd_in_multiple_terms_of_thereafter
                                                        }
                                                        onChange={(e) => {
                                                          feq.bond_ncd_in_multiple_terms_of_thereafter =
                                                            parseInt(
                                                              e.target.value
                                                            );
                                                          setFormikInitialData({
                                                            ...formikInitialData,
                                                          });
                                                        }}
                                                      />
                                                    </td>
                                                  );
                                                }
                                              )}
                                            </tr>
                                            <tr>
                                              <td>
                                                <input
                                                  className="form-check-input"
                                                  type="checkbox"
                                                  id="bond_ncd_issue_price_of_ncd"
                                                  onChange={(check) => {
                                                    onUpdateNCDAvailable(
                                                      check.target.checked,
                                                      "bond_ncd_issue_price_of_ncd"
                                                    );
                                                  }}
                                                  checked={
                                                    formikProps.values
                                                      ?.bond_ncd_available[0]
                                                      ?.bond_ncd_issue_price_of_ncd ==
                                                    0
                                                      ? false
                                                      : true
                                                  }
                                                />
                                              </td>
                                              <td>
                                                Face Value/ Issue Price of NCDs
                                                (`/ NCD) (₹)
                                              </td>
                                              {formikProps.values?.bond_ncd_series?.map(
                                                (
                                                  feq: APIData.BondNcdSeries,
                                                  inx: number
                                                ) => {
                                                  return (
                                                    <td>
                                                      <input
                                                        id={
                                                          "bond_ncd_issue_price_of_ncd" +
                                                          inx
                                                        }
                                                        name="bond_ncd_issue_price_of_ncd"
                                                        type="number"
                                                        className="bond-input"
                                                        value={
                                                          feq.bond_ncd_issue_price_of_ncd
                                                        }
                                                        onChange={(e) => {
                                                          feq.bond_ncd_issue_price_of_ncd =
                                                            e.target.value.toString();
                                                          setFormikInitialData({
                                                            ...formikInitialData,
                                                          });
                                                        }}
                                                      />
                                                    </td>
                                                  );
                                                }
                                              )}
                                            </tr>
                                            <tr>
                                              <td>
                                                <input
                                                  className="form-check-input"
                                                  type="checkbox"
                                                  id="bond_ncd_tenor"
                                                  onChange={(check) => {
                                                    onUpdateNCDAvailable(
                                                      check.target.checked,
                                                      "bond_ncd_tenor"
                                                    );
                                                  }}
                                                  checked={
                                                    formikProps.values
                                                      ?.bond_ncd_available[0]
                                                      ?.bond_ncd_tenor == 0
                                                      ? false
                                                      : true
                                                  }
                                                />
                                              </td>
                                              <td>Tenure</td>
                                              {formikProps.values?.bond_ncd_series?.map(
                                                (
                                                  feq: APIData.BondNcdSeries,
                                                  inx: number
                                                ) => {
                                                  return (
                                                    <td>
                                                      <input
                                                        id={
                                                          "bond_ncd_tenor" + inx
                                                        }
                                                        name="bond_ncd_tenor"
                                                        type="number"
                                                        className="bond-input"
                                                        value={
                                                          feq.bond_ncd_tenor
                                                        }
                                                        onChange={(e) => {
                                                          feq.bond_ncd_tenor =
                                                            parseInt(
                                                              e.target.value
                                                            );
                                                          setFormikInitialData({
                                                            ...formikInitialData,
                                                          });
                                                        }}
                                                      />
                                                    </td>
                                                  );
                                                }
                                              )}
                                            </tr>
                                            <tr>
                                              <td>
                                                <input
                                                  className="form-check-input"
                                                  type="checkbox"
                                                  id="bond_ncd_coupon"
                                                  onChange={(check) => {
                                                    onUpdateNCDAvailable(
                                                      check.target.checked,
                                                      "bond_ncd_coupon"
                                                    );
                                                  }}
                                                  checked={
                                                    formikProps.values
                                                      ?.bond_ncd_available[0]
                                                      ?.bond_ncd_coupon == 0
                                                      ? false
                                                      : true
                                                  }
                                                />
                                              </td>
                                              <td>Coupon (% per annum)</td>
                                              {formikProps.values?.bond_ncd_series?.map(
                                                (
                                                  feq: APIData.BondNcdSeries,
                                                  inx: number
                                                ) => {
                                                  return (
                                                    <td>
                                                      <input
                                                        id={
                                                          "bond_ncd_coupon" +
                                                          inx
                                                        }
                                                        name="bond_ncd_coupon"
                                                        type="number"
                                                        className="bond-input"
                                                        value={
                                                          feq.bond_ncd_coupon
                                                        }
                                                        onChange={(e) => {
                                                          feq.bond_ncd_coupon =
                                                            e.target.value.toString();
                                                          setFormikInitialData({
                                                            ...formikInitialData,
                                                          });
                                                        }}
                                                      />
                                                    </td>
                                                  );
                                                }
                                              )}
                                            </tr>
                                            <tr>
                                              <td>
                                                <input
                                                  className="form-check-input"
                                                  type="checkbox"
                                                  id="bond_ncd_effective_yield"
                                                  onChange={(check) => {
                                                    onUpdateNCDAvailable(
                                                      check.target.checked,
                                                      "bond_ncd_effective_yield"
                                                    );
                                                  }}
                                                  checked={
                                                    formikProps.values
                                                      ?.bond_ncd_available[0]
                                                      ?.bond_ncd_effective_yield ==
                                                    0
                                                      ? false
                                                      : true
                                                  }
                                                />
                                              </td>
                                              <td>
                                                Effective Yield (% per annum)
                                              </td>
                                              {formikProps.values?.bond_ncd_series?.map(
                                                (
                                                  feq: APIData.BondNcdSeries,
                                                  inx: number
                                                ) => {
                                                  return (
                                                    <td>
                                                      <input
                                                        id={
                                                          "bond_ncd_effective_yield" +
                                                          inx
                                                        }
                                                        name="bond_ncd_effective_yield"
                                                        type="number"
                                                        className="bond-input"
                                                        value={
                                                          feq.bond_ncd_effective_yield
                                                        }
                                                        onChange={(e) => {
                                                          feq.bond_ncd_effective_yield =
                                                            e.target.value.toString();
                                                          setFormikInitialData({
                                                            ...formikInitialData,
                                                          });
                                                        }}
                                                      />
                                                    </td>
                                                  );
                                                }
                                              )}
                                            </tr>
                                            <tr>
                                              <td>
                                                <input
                                                  className="form-check-input"
                                                  type="checkbox"
                                                  id="bond_ncd_mode_of_interest_payment"
                                                  onChange={(check) => {
                                                    onUpdateNCDAvailable(
                                                      check.target.checked,
                                                      "bond_ncd_mode_of_interest_payment"
                                                    );
                                                  }}
                                                  checked={
                                                    formikProps.values
                                                      ?.bond_ncd_available[0]
                                                      ?.bond_ncd_mode_of_interest_payment ==
                                                    0
                                                      ? false
                                                      : true
                                                  }
                                                />
                                              </td>
                                              <td>Mode of Issuer</td>
                                              {formikProps.values?.bond_ncd_series?.map(
                                                (
                                                  feq: APIData.BondNcdSeries,
                                                  inx: number
                                                ) => {
                                                  return (
                                                    <td>
                                                      {/* <input id={"bond_ncd_mode_of_interest_payment" + inx} name="bond_ncd_mode_of_interest_payment" type="number" className='bond-input'
                                                                                                            value={feq.bond_ncd_mode_of_interest_payment}
                                                                                                            onChange={e => { feq.bond_ncd_mode_of_interest_payment = e.target.value; setFormikInitialData(({ ...formikInitialData })); }}
                                                                                                        /> */}
                                                      <select
                                                        id={
                                                          "bond_ncd_mode_of_interest_payment" +
                                                          inx
                                                        }
                                                        name="bond_ncd_mode_of_interest_payment"
                                                        style={{
                                                          width: "100%",
                                                        }}
                                                        onChange={(e) => {
                                                          console.log(
                                                            typeof e.target
                                                              .value
                                                          );
                                                          console.log(
                                                            e.target.value
                                                          );
                                                          feq.bond_ncd_mode_of_interest_payment =
                                                            e.target.value;
                                                          setFormikInitialData({
                                                            ...formikInitialData,
                                                          });
                                                        }}
                                                        value={
                                                          feq.bond_ncd_mode_of_interest_payment
                                                        }
                                                        className="bond-input-select"
                                                      >
                                                        <option
                                                          value={"Public"}
                                                        >
                                                          Public
                                                        </option>
                                                        <option
                                                          value={"Private"}
                                                        >
                                                          Private
                                                        </option>
                                                      </select>
                                                    </td>
                                                  );
                                                }
                                              )}
                                            </tr>
                                            <tr>
                                              <td>
                                                <input
                                                  className="form-check-input"
                                                  type="checkbox"
                                                  id="bond_ncd_redemption_amount"
                                                  onChange={(check) => {
                                                    onUpdateNCDAvailable(
                                                      check.target.checked,
                                                      "bond_ncd_redemption_amount"
                                                    );
                                                  }}
                                                  checked={
                                                    formikProps.values
                                                      ?.bond_ncd_available[0]
                                                      ?.bond_ncd_redemption_amount ==
                                                    0
                                                      ? false
                                                      : true
                                                  }
                                                />
                                              </td>
                                              <td>
                                                Redemption Amount (₹ / NCD) on
                                                Maturity
                                              </td>
                                              {formikProps.values?.bond_ncd_series?.map(
                                                (
                                                  feq: APIData.BondNcdSeries,
                                                  inx: number
                                                ) => {
                                                  return (
                                                    <td>
                                                      <input
                                                        id={
                                                          "bond_ncd_redemption_amount" +
                                                          inx
                                                        }
                                                        name="bond_ncd_redemption_amount"
                                                        type="number"
                                                        className="bond-input"
                                                        value={
                                                          feq.bond_ncd_redemption_amount
                                                        }
                                                        onChange={(e) => {
                                                          feq.bond_ncd_redemption_amount =
                                                            e.target.value.toString();
                                                          setFormikInitialData({
                                                            ...formikInitialData,
                                                          });
                                                        }}
                                                      />
                                                    </td>
                                                  );
                                                }
                                              )}
                                            </tr>
                                            <tr>
                                              <td>
                                                <input
                                                  className="form-check-input"
                                                  type="checkbox"
                                                  id="bond_ncd_put_and_option"
                                                  onChange={(check) => {
                                                    onUpdateNCDAvailable(
                                                      check.target.checked,
                                                      "bond_ncd_put_and_option"
                                                    );
                                                  }}
                                                  checked={
                                                    formikProps.values
                                                      ?.bond_ncd_available[0]
                                                      ?.bond_ncd_put_and_option ==
                                                    0
                                                      ? false
                                                      : true
                                                  }
                                                />
                                              </td>
                                              <td>Put and Call Option</td>
                                              {formikProps.values?.bond_ncd_series?.map(
                                                (
                                                  feq: APIData.BondNcdSeries,
                                                  inx: number
                                                ) => {
                                                  return (
                                                    <td>
                                                      {/* <input id={"bond_ncd_put_and_option" + inx} name="bond_ncd_put_and_option" type="number" className='bond-input'
                                                                                                            value={feq.bond_ncd_put_and_option}
                                                                                                            onChange={e => { feq.bond_ncd_put_and_option = parseInt(e.target.value); setFormikInitialData(({ ...formikInitialData })); }}
                                                                                                        /> */}
                                                      <select
                                                        id={
                                                          "bond_ncd_put_and_option" +
                                                          inx
                                                        }
                                                        name="bond_ncd_put_and_option"
                                                        style={{
                                                          width: "100%",
                                                        }}
                                                        onChange={(e) => {
                                                          feq.bond_ncd_put_and_option =
                                                            parseInt(
                                                              e.target.value
                                                            );
                                                          setFormikInitialData({
                                                            ...formikInitialData,
                                                          });
                                                          console.log(
                                                            e.target.value
                                                          );
                                                        }}
                                                        value={
                                                          feq.bond_ncd_put_and_option
                                                        }
                                                        className="bond-input-select"
                                                      >
                                                        <option value={0}>
                                                          Select
                                                        </option>
                                                        <option value={1}>
                                                          Put
                                                        </option>
                                                        <option value={2}>
                                                          Call
                                                        </option>
                                                        <option value={3}>
                                                          Both
                                                        </option>
                                                      </select>
                                                    </td>
                                                  );
                                                }
                                              )}
                                            </tr>
                                          </tbody>
                                        </table>
                                      </div>
                                    </div>
                                    {/* <div className='row text-end my-3'>
                                                                        <div className=''>
                                                                            <a className='cancel-btn mx-3' onClick={() => onClearNCDSeries()} >Clear</a>
                                                                            <a className='save-btn'>
                                                                                Save
                                                                            </a>
                                                                        </div>
                                                                    </div> */}
                                  </div>
                                </div>
                                <div className="row text-end my-3 py-5 ">
                                  <div className="">
                                    <a
                                      className="cancel-btn mx-3"
                                      onClick={() =>
                                        saveAsDraft(formikProps?.values)
                                      }
                                    >
                                      Save as Draft
                                    </a>

                                    <button type="submit" className="save-btn">
                                      {/* {location.state.mode == "edit"
                                        ? "Update"
                                        : "Save"} */}
                                      Save & Continue
                                    </button>
                                  </div>
                                </div>
                                <div className="row">
                                  <div
                                    className="card"
                                    style={{
                                      borderTopLeftRadius: "10px",
                                      borderTopRightRadius: "10px",
                                      paddingTop: "10px",
                                      paddingBottom: "20px",
                                    }}
                                  >
                                    <div className="card-header d-flex justify-content-between align-items-center">
                                      <div className="d-flex justify-content-start">
                                        <h5 className="m-0 header">
                                          Brokerage Details
                                        </h5>
                                      </div>
                                      <div className="d-flex justify-content-end align-items-center ">
                                        <button
                                          className="bond-add-btn text-white"
                                          onClick={(e) => {
                                            e.preventDefault(); // Prevent form submission
                                            handleAddRow(
                                              formikProps?.values
                                                ?.bond_ncd_series
                                            );
                                          }}
                                        >
                                          <i
                                            className="fa fa-plus-circle mx-2"
                                            aria-hidden="true"
                                          ></i>
                                          Add
                                        </button>
                                      </div>
                                    </div>

                                    <div className="card-body">
                                      {rows.length > 0 ? (
                                        <div
                                          className="table-responsive"
                                          data-pattern="priority-columns"
                                        >
                                          <table
                                            id="tech-companies-1"
                                            className="table table-bordered"
                                          >
                                            <thead className="city-table">
                                              <tr>
                                                <th>S.No</th>
                                                <th
                                                  style={{ minWidth: "500px" }}
                                                >
                                                  Name
                                                </th>
                                                {formikProps?.values?.bond_ncd_series.map(
                                                  (res: any, index: number) => (
                                                    <th key={index}>
                                                      Series {toRoman(index)} (
                                                      {res?.bond_ncd_tenor} MTS)
                                                    </th>
                                                  )
                                                )}
                                                <th>Action</th>
                                              </tr>
                                            </thead>
                                            <tbody>
                                              {rows.map((row, index) => (
                                                <tr key={index}>
                                                  <td>{index + 1}</td>
                                                  <td>
                                                    <select
                                                      id={`security-${index}`}
                                                      name={`rows[${index}].security_id`}
                                                      value={row.category_id}
                                                      onChange={(e) => {
                                                        const { value } =
                                                          e.target;
                                                        setRows((prevRows) =>
                                                          prevRows.map((r, i) =>
                                                            i === index
                                                              ? {
                                                                  ...r,
                                                                  category_id:
                                                                    value,
                                                                }
                                                              : r
                                                          )
                                                        );
                                                      }}
                                                      className="bond-input-select"
                                                    >
                                                      <option value="" disabled>
                                                        Select Category
                                                      </option>
                                                      {categoryList.map(
                                                        (val: any) => (
                                                          <option
                                                            key={
                                                              val?.category_id
                                                            }
                                                            value={
                                                              val?.category_id
                                                            }
                                                          >
                                                            {val?.category_name}
                                                          </option>
                                                        )
                                                      )}
                                                    </select>
                                                  </td>
                                                  {row.series.map(
                                                    (
                                                      s: any,
                                                      seriesIndex: any
                                                    ) => (
                                                      <td key={seriesIndex}>
                                                        <input
                                                          type="number"
                                                          value={
                                                            s.security_category_series_value
                                                          } // Bind the input to the series' value directly
                                                          onChange={(e) => {
                                                            const { value } =
                                                              e.target;
                                                            setRows(
                                                              (prevRows) =>
                                                                prevRows.map(
                                                                  (r, i) =>
                                                                    i === index
                                                                      ? {
                                                                          ...r,
                                                                          series:
                                                                            r.series.map(
                                                                              (
                                                                                series: any,
                                                                                si: any
                                                                              ) =>
                                                                                si ===
                                                                                seriesIndex
                                                                                  ? {
                                                                                      ...series,
                                                                                      security_category_series_value:
                                                                                        value,
                                                                                    } // Update the value for the correct series
                                                                                  : series
                                                                            ),
                                                                        }
                                                                      : r
                                                                )
                                                            );
                                                          }}
                                                        />
                                                      </td>
                                                    )
                                                  )}
                                                  <td>
                                                    <i
                                                      className="fas fa-trash table-icons pe-1"
                                                      onClick={() =>
                                                        handleDeleteRow(index)
                                                      } // Delete handler
                                                      style={{
                                                        cursor: "pointer",
                                                        color: "black",
                                                      }}
                                                    ></i>
                                                  </td>
                                                </tr>
                                              ))}
                                            </tbody>
                                          </table>
                                        </div>
                                      ) : (
                                        <div className="text-center py-5">
                                          No Data Found.
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </div>

                                <div className="row text-end my-3 py-5 ">
                                  <div className="">
                                    <a
                                      className="cancel-btn mx-3"
                                      onClick={() => navigate(-1)}
                                    >
                                      Cancel
                                    </a>
                                    <button type="submit" className="save-btn">
                                      {location.state.mode == "edit"
                                        ? "Update"
                                        : "Save"}
                                    </button>
                                  </div>
                                </div>
                              </LocalizationProvider>
                            </Form>
                          );
                        }}
                      </Formik>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </div>
      <SimpleModal
        contents={view_content}
        handleOpen={() => handleClickOpen}
        openModal={open}
        handleClose={(e: any) => handleClose(e)}
      />
      <ConfirmationModal
        contents="Are you sure you want to activate the user/account?"
        details_datas={user_id}
        openModal={open_modal}
        handleClose={(e: any) => handleClose_modal(e)}
        currentPage="suspend-user-list"
      />
    </div>
  );
};

export default IPOAdd;
