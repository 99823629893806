import React from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import { useNavigate } from "react-router-dom";

const defaultRows = [
  {
    user_id: 1,
    personal_fullname: "SARFARAZ ANSARI",
    personal_pan: "AYOPA2175Z",
    pending_pre_order: 0,
    sold_pre_order: 0,
    total_pre_order: 0,
    total_sold_order: 9,
  },
];

function EnhancedTableIsin({ data = defaultRows,batch_no }) {
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("personal_fullname");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const rows = data || [];
  const navigate = useNavigate();

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows?.map((n) => n?.user_id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected?.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const visibleRows = React.useMemo(
    () =>
      [...rows]
        ?.sort((a, b) => {
          const valueA = a[orderBy] ?? "";
          const valueB = b[orderBy] ?? "";

          if (valueA < valueB) {
            return order === "asc" ? -1 : 1;
          }
          if (valueA > valueB) {
            return order === "asc" ? 1 : -1;
          }
          return 0;
        })
        ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage),
    [order, orderBy, page, rowsPerPage, rows]
  );

  return (
    <Box sx={{ width: "100%" }}>
      <Paper sx={{ width: "100%", mb: 2 }}>
        <Toolbar>
          <Typography
            sx={{ flex: "1 1 100%" }}
            variant="h6"
            id="tableTitle"
            component="div"
          >
            Customer Details
          </Typography>
        </Toolbar>
        <TableContainer>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size={dense ? "small" : "medium"}
          >
            <TableHead sx={{ backgroundColor: "#A27B1E"}}>
              <TableRow>
                <TableCell sx={{color:"white"}}>Sr.No</TableCell>
                <TableCell sx={{color:"white"}}>Customer Name</TableCell>
                <TableCell sx={{color:"white"}}> Customer PAN</TableCell>
                <TableCell  sx={{color:"white"}}>Pre-Order</TableCell>
                <TableCell sx={{color:"white"}}>Total Sold Order</TableCell>
                <TableCell sx={{color:"white"}}>Details</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {visibleRows.map((row,index) => {
                const isItemSelected = selected?.includes(row?.user_id);
                const labelId = `enhanced-table-checkbox-${row?.user_id}`;

                return (
                  <TableRow hover>
                    <TableCell sx={{ border: "1px solid #ddd" }}>{index+1}</TableCell>
                    <TableCell sx={{ border: "1px solid #ddd" }}>{row?.personal_fullname}</TableCell>
                    <TableCell sx={{ border: "1px solid #ddd" }}>{row?.personal_pan}</TableCell>
                    <TableCell
                                                               sx={{ border: "1px solid #ddd" }}
                                                             >
                                                               <div>
                                                                 <div>
                                                                   <span>Total : </span>
                                                                   <span>
                                                                     {row.total_pre_order == null
                                                                       ? "--"
                                                                       : row.total_pre_order}
                                                                   </span>
                                                                 </div>
                                                                 <div className="mt-2">
                                                                   <span>
                                                                     Pending :{" "}
                                                                     {row.pending_pre_order ==
                                                                     null ? (
                                                                       "--"
                                                                     ) : (
                                                                       <span
                                                                         style={{
                                                                           border:
                                                                             "1px solid #FF2D2D",
                                                                           borderRadius: "5px",
                                                                           width: "30px", // Set a fixed width
                                                                           height: "30px", // Set a fixed height
                                                                           display: "inline-flex", // Use inline-flex for proper alignment
                                                                           justifyContent:
                                                                             "center", // Center horizontally
                                                                           alignItems: "center", // Center vertically
                                                                           textAlign: "center", // Ensure the text is centered
                                                                           color: "#FF2D2D",
                                                                         }}
                                                                       >
                                                                         {row.pending_pre_order}
                                                                       </span>
                                                                     )}
                                                                   </span>
                                                                   <span
                                                                     style={{ marginLeft: "5px" }}
                                                                   >
                                                                     Sold :
                                                                     {row.sold_pre_order ==
                                                                     null ? (
                                                                       "--"
                                                                     ) : (
                                                                       <span
                                                                         style={{
                                                                           border:
                                                                             "1px solid #27AC5F",
                                                                           borderRadius: "5px",
                                                                           width: "30px", // Set a fixed width
                                                                           height: "30px", // Set a fixed height
                                                                           display: "inline-flex", // Use inline-flex for proper alignment
                                                                           justifyContent:
                                                                             "center", // Center horizontally
                                                                           alignItems: "center", // Center vertically
                                                                           textAlign: "center", // Ensure the text is centered
                                                                           color: "#27AC5F",
                                                                           marginLeft: "3px",
                                                                         }}
                                                                       >
                                                                         {row.sold_pre_order}
                                                                       </span>
                                                                     )}
                                                                   </span>
                                                                 </div>
                                                               </div>
                                                             </TableCell>
                    <TableCell sx={{ border: "1px solid #ddd" }}>{row?.total_sold_order}</TableCell>
                    <TableCell
                    sx={{ border: "1px solid #ddd" }}
                     
                    >
                       <i
                                className="fas fa-eye table-icons px-2"
                                onClick={() => navigate(`/cust/${row?.user_id}/${batch_no}`)}
                              ></i>
                    </TableCell>
                  </TableRow>
                );
              })}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: (dense ? 33 : 53) * emptyRows,
                  }}
                >
                  <TableCell colSpan={8} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rows?.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          sx={{
            '& .MuiTablePagination-toolbar': {
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-end', // Right-align all elements
              padding: 0,
              minHeight: 'auto',
            },
            '& .MuiTablePagination-spacer': {
              flex: 'none', // Disable default spacer behavior
            },
            '& .MuiTablePagination-actions': {
              marginLeft: 1, // Add small spacing between elements
            },
            '& .MuiTablePagination-selectLabel': {
              margin: 0,
              marginRight: 1, // Add spacing between label and dropdown
            },
            '& .MuiTablePagination-displayedRows': {
              margin: 0,
              marginLeft: 1, // Add spacing between dropdown and page info
            }
          }}
        />
        </div>
      </Paper>
      {/* <FormControlLabel
        control={<Switch checked={dense} onChange={handleChangeDense} />}
        label="Dense padding"
      /> */}
    </Box>
  );
}

export default EnhancedTableIsin;
