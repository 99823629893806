import React, { useEffect, useRef, useState } from "react";
import Header from "../common/Header";
import DatePicker from "react-date-picker";
import Footer from "../common/Footer";
import DataTable from "react-data-table-component";
import toast from "react-hot-toast";
import { useAppStateAPI } from "../common/AppStateAPI";
import { ServerAPI } from "../common/ServerAPI";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { getCustomFormattedLocalDate } from "../common/Utilities";

const SettlementList = () => {
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [selectedFileBse, setSelectedFileBse] = useState<File | null>(null);
  const { setShowPreloader } = useAppStateAPI();
  const [holidayList, setHolidayList] = useState<any[]>([]);
  const [tabIndex, setTabIndex] = useState(0);
  const [buttonText, setButtonText] = useState("Upload NSE Settlement");
  const [buttonTextBse, setButtonTextBse] = useState("Upload BSE Settlement");
  const fileInputRef = useRef<HTMLInputElement>(null);
  const fileInputRefBse = useRef<HTMLInputElement>(null);
  const customStyles = {
    headCells: {
      style: {
        backgroundColor: "#A27B1E",
        color: "white",
        fontSize: "14px",
      },
    },
  };

  const allColumns = [
    {
      name: "S.No",
      selector: (row: any, index: any) => index + 1,
      sortable: true,
    },
    {
      name: "Exchange Name",
      selector: (row: any) => row.exchange.exchange_name,
      sortable: true,
    },
    {
      name: "Settlement Date",
      selector: (row: any) =>
        getCustomFormattedLocalDate(row.security_settlement_calendar_date),
      sortable: true,
    },
    {
      name: "Transaction Date",
      selector: (row: any) =>
        getCustomFormattedLocalDate(row.security_settlement_tnx_date),
      sortable: true,
    },
    {
      name: "Settlement Number",
      selector: (row: any) => row.security_settlement_number,
      sortable: true,
    },
    {
      name: "Settlement Status",
      selector: (row: any) =>
        row.security_settlement_calendar_status ? "Active" : "Inactive",
      sortable: true,
    },
  ];

  // Filter out "Transaction Date" column if tabIndex is 1
  const columns =
    tabIndex === 1
      ? allColumns.filter((column) => column.name !== "Transaction Date")
      : allColumns;
  useEffect(() => {
    loadSettlementList();
  }, [tabIndex]);
  const loadSettlementList = () => {
    setShowPreloader(true);
    let exchange_id = 1;
    if (tabIndex == 0) {
      exchange_id = 1;
    }
    if (tabIndex == 1) {
      exchange_id = 2;
    }

    ServerAPI.settlementList(exchange_id)
      .then((res) => {
        try {
          setHolidayList(res);
        } catch (error) {
          toast.error(res.error);
        }
      })
      .finally(() => {
        setShowPreloader(false);
      });
  };

  const handleFileChangeBse = (event: React.ChangeEvent<HTMLInputElement>) => {
    console.log(event.target.files);

    const files = event.target.files;
    if (files && files.length > 0) {
      const file = files[0];
      const validMimeTypes = [
        "application/vnd.ms-excel", // .xls
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", // .xlsx
      ];

      if (!validMimeTypes.includes(file.type)) {
        toast.error("Unsupported file format. Please upload an Excel file.");
        setSelectedFileBse(null); // Reset selected file if invalid
        setButtonTextBse("Upload BSE Settlement");
        event.target.value = ""; // Reset file input
        return;
      }

      // If valid file, update state and button text
      setSelectedFileBse(file);
      setButtonTextBse("Submit");
      console.log("Selected file:", file);
    }

    // // Reset file input to allow reselecting the same file
    // event.target.value = "";
  };
  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    console.log(event.target.files);

    if (files && files.length > 0) {
      const file = files[0];
      const validMimeTypes = [
        "application/vnd.ms-excel", // .xls
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", // .xlsx
      ];

      if (!validMimeTypes.includes(file.type)) {
        toast.error("Unsupported file format. Please upload an Excel file.");
        setSelectedFile(null); // Reset selected file if invalid
        setButtonText("Upload NSE Settlement");
        event.target.value = ""; // Reset file input
        return;
      }

      // If valid file, update state and button text
      setSelectedFile(file);
      setButtonText("Submit");
      console.log("Selected file:", file);
    }

    // Reset file input to allow reselecting the same file
    event.target.value = "";
  };
  const handleUploadClickBse = () => {
    if (selectedFileBse) {
      // Perform API call
      setShowPreloader(true);
      ServerAPI.uploadExcelForBSESettlementCalendar(selectedFileBse)
        .then((res) => {
          if (res.status === 200) {
            // Handle success
            toast.success(res.message);
            setSelectedFileBse(null); // Clear the selected file
            setButtonTextBse("Upload BSE Settlement");
          } else {
            // Handle success
            toast.error(res.message);
          }
        })
        .finally(() => {
          setShowPreloader(false);
        });
    } else {
      // Open dialog
      fileInputRefBse.current?.click();
    }
  };
  const handleUploadClick = () => {
    console.log(selectedFile);

    if (selectedFile) {
      // Perform API call
      setShowPreloader(true);
      ServerAPI.uploadExcelForNSESettlementCalendar(selectedFile)
        .then((res) => {
          if (res.status === 200) {
            // Handle success
            toast.success(res.message);
            setSelectedFile(null); // Clear the selected file
            setButtonText("Upload NSE Settlement");
          } else {
            // Handle success
            toast.error(res.message);
          }
        })
        .finally(() => {
          setShowPreloader(false);
        });
    } else {
      // Open dialog
      fileInputRef.current?.click();
    }
  };
  const removeSelectedFile = () => {
    setSelectedFile(null);
    setButtonText("Upload NSE Settlement");
  };
  const removeSelectedFileBse = () => {
    setSelectedFileBse(null);
    setButtonTextBse("Upload BSE Settlement");
  };
  return (
    <div>
      <div id="layout-wrapper">
        <Header />
        <div className="main-content py-4">
          <div className="page-content">
            <div className="container-fluid">
              <div className="row">
                <div className="col-12">
                  <div className="page-title-box d-sm-flex align-items-center justify-content-between pt-4 pb-0">
                    {/* <h4 className="mb-sm-0 font-size-18 "> Suspend Users</h4> */}
                    <div className="page-title-right">
                      <ol className="breadcrumb ">
                        {/* <li className="breadcrumb-item">
                        <a style={{ color: "#90929E" }}>Trustmony</a>
                      </li> */}
                        <li className="breadcrumb-item">
                          <a> Master </a>
                        </li>
                        <li className="breadcrumb-item active ">
                          Settlement Master
                        </li>
                      </ol>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <div className="card">
                    <div className="card-body col-sm-12 col-md-12 col-lg-12 w-100">
                      {/* <div className="card-header col-xl-12 w-100"> */}
                      {/* <div
                        className="row d-flex align-items-center"
                        style={{ paddingBottom: "10px" }}
                      >
                        <div className="col-xl-4">
                          <div className="d-flex justify-content-start align-items-center">
                            <h4 className="card-title text-end p-2 m-0">
                              Search :
                            </h4>
                            <form className="app-search card-title p-2 m-0">
                              <div className="position-relative">
                                <input
                                  type="text"
                                  className="select-style-search"
                                  // onChange={(e) =>
                                  //   setSearchText(e.target.value)
                                  // }
                                  placeholder="search here..."
                                />
                              </div>
                            </form>
                          </div>
                        </div>
                      </div> */}
                      <Tabs
                        selectedIndex={tabIndex}
                        onSelect={(index) => {
                          setTabIndex(index);
                          //   if (index == 1) {
                          //     loadActiveBondList();
                          //   }
                        }}
                      >
                        <TabList
                          className="Product_detail_tablist"
                          style={{ marginBottom: "0rem" }}
                        >
                          <div className=" d-flex justify-content-between align-items-end">
                            <div>
                              <Tab className="product_detail_tab">BSE</Tab>
                              <Tab className="product_detail_tab">NSE</Tab>
                            </div>
                            {tabIndex == 0 && (
                              <div>
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: "1rem",
                                  }}
                                >
                                  {/* File Review Section */}
                                  {selectedFileBse && (
                                    <div
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                        gap: "0.5rem",
                                      }}
                                    >
                                      <span
                                        style={{
                                          color: "#A27B1E",
                                          fontWeight: "bold",
                                        }}
                                      >
                                        {selectedFileBse.name}
                                      </span>
                                      <button
                                        onClick={removeSelectedFileBse}
                                        style={{
                                          background: "transparent",
                                          border: "none",
                                          color: "red",
                                          fontSize: "1.2rem",
                                          cursor: "pointer",
                                        }}
                                        title="Remove File"
                                      >
                                        ✖
                                      </button>
                                    </div>
                                  )}

                                  {/* Upload Button */}
                                  <button
                                    className="xl_sheet mb-2"
                                    style={{
                                      backgroundColor: "#A27B1E",
                                      color: "white",
                                    }}
                                    onClick={handleUploadClickBse}
                                  >
                                    {buttonTextBse}{" "}
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="15"
                                      height="15"
                                      viewBox="0 0 84.227 95.107"
                                      className="xsl_icon"
                                      fill="white"
                                    >
                                      <defs>
                                        <clipPath id="clip-path">
                                          <rect
                                            id="Rectangle_3009"
                                            data-name="Rectangle 3009"
                                            width="84.227"
                                            height="95.107"
                                          />
                                        </clipPath>
                                      </defs>
                                      <g
                                        id="Group_10650"
                                        data-name="Group 10650"
                                        transform="translate(-0.667 0.247)"
                                      >
                                        <g
                                          id="Group_10649"
                                          data-name="Group 10649"
                                          transform="translate(0.667 -0.247)"
                                          clipPath="url(#clip-path)"
                                        >
                                          <path
                                            id="Path_21458"
                                            data-name="Path 21458"
                                            d="M78.9,34.019H76.614V23a1.791,1.791,0,0,0-.019-.208,1.83,1.83,0,0,0-.443-1.207L57.814.638C57.808.632,57.8.631,57.8.625a1.851,1.851,0,0,0-.371-.311c-.04-.027-.08-.049-.122-.073a1.956,1.956,0,0,0-.368-.155c-.035-.009-.066-.023-.1-.032A1.87,1.87,0,0,0,56.409,0H11.344a3.733,3.733,0,0,0-3.73,3.73V34.018H5.331A5.331,5.331,0,0,0,0,39.349V67.071A5.332,5.332,0,0,0,5.331,72.4H7.614V91.377a3.734,3.734,0,0,0,3.73,3.73h61.54a3.735,3.735,0,0,0,3.73-3.73V72.4H78.9a5.332,5.332,0,0,0,5.33-5.33V39.35a5.329,5.329,0,0,0-5.33-5.331M11.344,3.73h43.2V22.812a1.865,1.865,0,0,0,1.865,1.865H72.883v9.343H11.344ZM53.192,61.185v5.409H35.4V38.109h6.468V61.185ZM7.381,66.593l8.242-14.411L7.676,38.109h7.4l2.493,5.2c.846,1.732,1.48,3.128,2.157,4.734h.083c.678-1.818,1.226-3.086,1.944-4.734l2.409-5.2h7.354l-8.031,13.9,8.454,14.58H24.5L21.92,61.438c-1.056-1.987-1.733-3.465-2.535-5.114H19.3c-.592,1.649-1.309,3.129-2.2,5.114l-2.367,5.156H7.381Zm65.5,23.775H11.344V72.4h61.54V90.367Zm-8.916-23.35a18.641,18.641,0,0,1-8.07-1.734l1.308-5.325a16.452,16.452,0,0,0,7.144,1.776c2.959,0,4.522-1.226,4.522-3.085,0-1.776-1.352-2.79-4.776-4.016-4.733-1.649-7.819-4.269-7.819-8.412,0-4.861,4.057-8.579,10.779-8.579a17.48,17.48,0,0,1,7.268,1.437l-1.437,5.2a13.531,13.531,0,0,0-5.959-1.353c-2.789,0-4.141,1.267-4.141,2.748,0,1.817,1.6,2.621,5.282,4.016,5.029,1.859,7.4,4.478,7.4,8.494,0,4.777-3.678,8.834-11.5,8.834"
                                          />
                                        </g>
                                      </g>
                                    </svg>
                                  </button>
                                </div>

                                {/* Hidden file input */}
                                <input
                                  type="file"
                                  ref={fileInputRefBse}
                                  style={{ display: "none" }}
                                  onChange={handleFileChangeBse}
                                />
                              </div>
                            )}
                            {tabIndex == 1 && (
                              <div>
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: "1rem",
                                  }}
                                >
                                  {/* File Review Section */}
                                  {selectedFile && (
                                    <div
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                        gap: "0.5rem",
                                      }}
                                    >
                                      <span
                                        style={{
                                          color: "#A27B1E",
                                          fontWeight: "bold",
                                        }}
                                      >
                                        {selectedFile.name}
                                      </span>
                                      <button
                                        onClick={removeSelectedFile}
                                        style={{
                                          background: "transparent",
                                          border: "none",
                                          color: "red",
                                          fontSize: "1.2rem",
                                          cursor: "pointer",
                                        }}
                                        title="Remove File"
                                      >
                                        ✖
                                      </button>
                                    </div>
                                  )}

                                  {/* Upload Button */}
                                  <button
                                    className="xl_sheet mb-2"
                                    style={{
                                      backgroundColor: "#A27B1E",
                                      color: "white",
                                    }}
                                    onClick={handleUploadClick}
                                  >
                                    {buttonText}{" "}
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="15"
                                      height="15"
                                      viewBox="0 0 84.227 95.107"
                                      className="xsl_icon"
                                      fill="white"
                                    >
                                      <defs>
                                        <clipPath id="clip-path">
                                          <rect
                                            id="Rectangle_3009"
                                            data-name="Rectangle 3009"
                                            width="84.227"
                                            height="95.107"
                                          />
                                        </clipPath>
                                      </defs>
                                      <g
                                        id="Group_10650"
                                        data-name="Group 10650"
                                        transform="translate(-0.667 0.247)"
                                      >
                                        <g
                                          id="Group_10649"
                                          data-name="Group 10649"
                                          transform="translate(0.667 -0.247)"
                                          clipPath="url(#clip-path)"
                                        >
                                          <path
                                            id="Path_21458"
                                            data-name="Path 21458"
                                            d="M78.9,34.019H76.614V23a1.791,1.791,0,0,0-.019-.208,1.83,1.83,0,0,0-.443-1.207L57.814.638C57.808.632,57.8.631,57.8.625a1.851,1.851,0,0,0-.371-.311c-.04-.027-.08-.049-.122-.073a1.956,1.956,0,0,0-.368-.155c-.035-.009-.066-.023-.1-.032A1.87,1.87,0,0,0,56.409,0H11.344a3.733,3.733,0,0,0-3.73,3.73V34.018H5.331A5.331,5.331,0,0,0,0,39.349V67.071A5.332,5.332,0,0,0,5.331,72.4H7.614V91.377a3.734,3.734,0,0,0,3.73,3.73h61.54a3.735,3.735,0,0,0,3.73-3.73V72.4H78.9a5.332,5.332,0,0,0,5.33-5.33V39.35a5.329,5.329,0,0,0-5.33-5.331M11.344,3.73h43.2V22.812a1.865,1.865,0,0,0,1.865,1.865H72.883v9.343H11.344ZM53.192,61.185v5.409H35.4V38.109h6.468V61.185ZM7.381,66.593l8.242-14.411L7.676,38.109h7.4l2.493,5.2c.846,1.732,1.48,3.128,2.157,4.734h.083c.678-1.818,1.226-3.086,1.944-4.734l2.409-5.2h7.354l-8.031,13.9,8.454,14.58H24.5L21.92,61.438c-1.056-1.987-1.733-3.465-2.535-5.114H19.3c-.592,1.649-1.309,3.129-2.2,5.114l-2.367,5.156H7.381Zm65.5,23.775H11.344V72.4h61.54V90.367Zm-8.916-23.35a18.641,18.641,0,0,1-8.07-1.734l1.308-5.325a16.452,16.452,0,0,0,7.144,1.776c2.959,0,4.522-1.226,4.522-3.085,0-1.776-1.352-2.79-4.776-4.016-4.733-1.649-7.819-4.269-7.819-8.412,0-4.861,4.057-8.579,10.779-8.579a17.48,17.48,0,0,1,7.268,1.437l-1.437,5.2a13.531,13.531,0,0,0-5.959-1.353c-2.789,0-4.141,1.267-4.141,2.748,0,1.817,1.6,2.621,5.282,4.016,5.029,1.859,7.4,4.478,7.4,8.494,0,4.777-3.678,8.834-11.5,8.834"
                                          />
                                        </g>
                                      </g>
                                    </svg>
                                  </button>
                                  {/* <a
                                    href="#"
                                    // className="xl_sheet mb-2"
                                    style={{
                                      display: "inline-flex", // Adjusted for a typical inline behavior for links
                                      alignItems: "center",
                                      textDecoration: "underline", // Ensures the underline appears
                                      color: "#A27B1E",
                                      fontWeight: "bold",
                                    }}
                                    onClick={(e) => {
                                      e.preventDefault();
                                      handleDownloadClick();
                                    }}
                                  >
                                    Download Sample Excel NSE
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="15"
                                      height="15"
                                      viewBox="0 0 84.227 95.107"
                                      className="xsl_icon"
                                      fill="#A27B1E"
                                      style={{ marginLeft: "8px" }}
                                    >
                                      <defs>
                                        <clipPath id="clip-path">
                                          <rect
                                            id="Rectangle_3009"
                                            data-name="Rectangle 3009"
                                            width="84.227"
                                            height="95.107"
                                          />
                                        </clipPath>
                                      </defs>
                                      <g
                                        id="Group_10650"
                                        data-name="Group 10650"
                                        transform="translate(-0.667 0.247)"
                                      >
                                        <g
                                          id="Group_10649"
                                          data-name="Group 10649"
                                          transform="translate(0.667 -0.247)"
                                          clipPath="url(#clip-path)"
                                        >
                                          <path
                                            id="Path_21458"
                                            data-name="Path 21458"
                                            d="M78.9,34.019H76.614V23a1.791,1.791,0,0,0-.019-.208,1.83,1.83,0,0,0-.443-1.207L57.814.638C57.808.632,57.8.631,57.8.625a1.851,1.851,0,0,0-.371-.311c-.04-.027-.08-.049-.122-.073a1.956,1.956,0,0,0-.368-.155c-.035-.009-.066-.023-.1-.032A1.87,1.87,0,0,0,56.409,0H11.344a3.733,3.733,0,0,0-3.73,3.73V34.018H5.331A5.331,5.331,0,0,0,0,39.349V67.071A5.332,5.332,0,0,0,5.331,72.4H7.614V91.377a3.734,3.734,0,0,0,3.73,3.73h61.54a3.735,3.735,0,0,0,3.73-3.73V72.4H78.9a5.332,5.332,0,0,0,5.33-5.33V39.35a5.329,5.329,0,0,0-5.33-5.331M11.344,3.73h43.2V22.812a1.865,1.865,0,0,0,1.865,1.865H72.883v9.343H11.344ZM53.192,61.185v5.409H35.4V38.109h6.468V61.185ZM7.381,66.593l8.242-14.411L7.676,38.109h7.4l2.493,5.2c.846,1.732,1.48,3.128,2.157,4.734h.083c.678-1.818,1.226-3.086,1.944-4.734l2.409-5.2h7.354l-8.031,13.9,8.454,14.58H24.5L21.92,61.438c-1.056-1.987-1.733-3.465-2.535-5.114H19.3c-.592,1.649-1.309,3.129-2.2,5.114l-2.367,5.156H7.381Zm65.5,23.775H11.344V72.4h61.54V90.367Zm-8.916-23.35a18.641,18.641,0,0,1-8.07-1.734l1.308-5.325a16.452,16.452,0,0,0,7.144,1.776c2.959,0,4.522-1.226,4.522-3.085,0-1.776-1.352-2.79-4.776-4.016-4.733-1.649-7.819-4.269-7.819-8.412,0-4.861,4.057-8.579,10.779-8.579a17.48,17.48,0,0,1,7.268,1.437l-1.437,5.2a13.531,13.531,0,0,0-5.959-1.353c-2.789,0-4.141,1.267-4.141,2.748,0,1.817,1.6,2.621,5.282,4.016,5.029,1.859,7.4,4.478,7.4,8.494,0,4.777-3.678,8.834-11.5,8.834"
                                          />
                                        </g>
                                      </g>
                                    </svg>
                                  </a> */}
                                </div>

                                {/* Hidden file input */}
                                <input
                                  type="file"
                                  ref={fileInputRef}
                                  style={{ display: "none" }}
                                  onChange={handleFileChange}
                                />
                              </div>
                            )}
                          </div>
                        </TabList>
                        <TabPanel>
                          <div className="row">
                            <div className="col-12">
                              <div className="card">
                                <div className="card-body col-sm-12 col-md-12 col-lg-12 w-100">
                                  {/* <div
                                    className="row"
                                    style={{ paddingBottom: "10px" }}
                                  >
                                    <div className="col-xl-8">
                                      <div className="d-flex justify-content-start align-items-center">
                                        <h4 className="card-title text-end p-2 m-0">
                                          Search :
                                        </h4>
                                        <form className="app-search card-title p-2 m-0">
                                          <div className="position-relative">
                                            <input
                                              type="text"
                                              className="select-style-search"
                                              //   onChange={(e) =>
                                              //     setSearchText(e.target.value)
                                              //   }
                                              placeholder="Bond Name, ISIN Name, Price..."
                                            />
                                          </div>
                                        </form>
                                      </div>
                                    </div>
                                  </div> */}

                                  <div className="table-rep-plugin">
                                    <div
                                      className="table-responsive "
                                      data-pattern="priority-columns"
                                    >
                                      <DataTable
                                        data={holidayList}
                                        columns={columns}
                                        noHeader
                                        defaultSortFieldId="security_holiday_calendar_id"
                                        sortIcon={
                                          <i className="fas fa-sort"></i>
                                        }
                                        defaultSortAsc={true}
                                        highlightOnHover
                                        dense
                                        customStyles={customStyles}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </TabPanel>
                        <TabPanel>
                          <div className="row mt-4">
                            <div className="col-12">
                              <div className="card">
                                <div className="card-body col-sm-12 col-md-12 col-lg-12 w-100">
                                  <div className="table-rep-plugin">
                                    <div
                                      className="table-responsive "
                                      data-pattern="priority-columns"
                                    >
                                      <DataTable
                                        data={holidayList}
                                        columns={columns}
                                        noHeader
                                        defaultSortFieldId="security_holiday_calendar_id"
                                        sortIcon={
                                          <i className="fas fa-sort"></i>
                                        }
                                        defaultSortAsc={true}
                                        highlightOnHover
                                        dense
                                        customStyles={customStyles}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </TabPanel>
                      </Tabs>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default SettlementList;
