import Pagination from "@mui/material/Pagination";
import { Field, Form, Formik, FormikProps } from "formik";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
// import DatePicker from 'react-date-picker';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useAppStateAPI } from "../common/AppStateAPI";
import {
  Depository,
  NCDDetailsList,
  ASBAStatus,
  PageLinks,
  Strings,
  wysiwygToolbar,
} from "../common/Constants";
import Footer from "../common/Footer";
import Header from "../common/Header";
import { ServerAPI } from "../common/ServerAPI";
import ConfirmationModal from "../modals/Confirmation-modal";
import SimpleModal from "../modals/SimpleModal";
import * as Yup from "yup";
import { Alert, Grid, Input, SvgIcon } from "@mui/material";
import FormField from "./form_items/FormField";
import { APIData } from "../common/DataTypes";
import dayjs, { Dayjs } from "dayjs";
import TextField from "@mui/material/TextField";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import {
  EditorState,
  convertToRaw,
  convertFromHTML,
  ContentState,
} from "draft-js";
import draftToHtml from "draftjs-to-html";
import { InitialData } from "../common/InitialValue";
import { getIsLogin } from "../common/Utilities";
import toast from "react-hot-toast";

const ASBABankLocationAdd: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const passing_value = location.state;
  const [preData, setPreData] = useState(passing_value);

  // const [value, onChange] = useState(new Date());
  const [toData, setToData] = useState<null | Date>();
  const [fromDate, setFromDate] = useState<null | Date>();
  const [open, setOpen] = useState(false);
  const [open_modal, setOpenModal] = useState(false);
  const [userList, setUserList] = useState<any[]>([]);
  const [totalCount, setTotalCount] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [user_id, setUserId] = useState();
  const [view_content, setViewContent] = useState();
  const [search_text, setSearchText] = useState("");
  const { setShowPreloader } = useAppStateAPI();
  const [formikInitialData, setFormikInitialData] = useState<APIData.ASBAList>(
    InitialData.ASBAList
  );
  const [NDCList, setNDCList] = useState<any[]>(NCDDetailsList);

  const [addComments, setAddComments] = useState<any | undefined>(
    EditorState.createEmpty()
  );
  const [addComments_text, setAddComments_text] = useState("");
  const formikRef = useRef<FormikProps<APIData.ASBAList>>(null);

  const onEditorStateChange = (editorState: any) => {
    const rawContentState = convertToRaw(editorState.getCurrentContent());
    const htmlDescription = draftToHtml(rawContentState);
    const htmlResult = htmlDescription.replace(/(<([^>]+)>)/gi, "");
    setAddComments(editorState);
    setAddComments_text(htmlResult);
  };
  useEffect(() => {
    getIsLogin().then((status) => {
      if (!status) {
        navigate(PageLinks.LOGIN);
      }
    });
  }, []);
  function DateIcon() {
    return (
      <SvgIcon viewBox="0 0 72.872 83.283" style={{ padding: "2px" }}>
        <path
          id="Path_27181"
          data-name="Path 27181"
          d="M-1078.15,955.569v7.808h-72.872v-7.808a7.809,7.809,0,0,1,7.808-7.808h7.807v-7.808a2.61,2.61,0,0,1,2.6-2.6h5.205a2.61,2.61,0,0,1,2.6,2.6v7.808h20.82v-7.808a2.609,2.609,0,0,1,2.6-2.6h5.2a2.609,2.609,0,0,1,2.6,2.6v7.808h7.808A7.81,7.81,0,0,1-1078.15,955.569Zm-72.872,13.013h72.872v44.243a7.811,7.811,0,0,1-7.808,7.809h-57.256a7.81,7.81,0,0,1-7.808-7.809Zm10.41,18.869a1.957,1.957,0,0,0,1.952,1.952h6.506a1.957,1.957,0,0,0,1.952-1.952v-6.507a1.957,1.957,0,0,0-1.952-1.952h-6.506a1.957,1.957,0,0,0-1.952,1.952Zm0,20.82a1.957,1.957,0,0,0,1.952,1.952h6.506a1.957,1.957,0,0,0,1.952-1.952v-6.506a1.958,1.958,0,0,0-1.952-1.952h-6.506a1.958,1.958,0,0,0-1.952,1.952Zm20.821-20.82a1.957,1.957,0,0,0,1.952,1.952h6.506a1.957,1.957,0,0,0,1.952-1.952v-6.507a1.957,1.957,0,0,0-1.952-1.952h-6.506a1.957,1.957,0,0,0-1.952,1.952Zm0,20.82a1.957,1.957,0,0,0,1.952,1.952h6.506a1.957,1.957,0,0,0,1.952-1.952v-6.506a1.958,1.958,0,0,0-1.952-1.952h-6.506a1.958,1.958,0,0,0-1.952,1.952Zm20.82-20.82a1.957,1.957,0,0,0,1.952,1.952h6.506a1.957,1.957,0,0,0,1.952-1.952v-6.507a1.957,1.957,0,0,0-1.952-1.952h-6.506a1.957,1.957,0,0,0-1.952,1.952Zm0,20.82a1.957,1.957,0,0,0,1.952,1.952h6.506a1.957,1.957,0,0,0,1.952-1.952v-6.506a1.958,1.958,0,0,0-1.952-1.952h-6.506a1.958,1.958,0,0,0-1.952,1.952Z"
          transform="translate(1151.022 -937.351)"
        />
      </SvgIcon>
    );
  }

  // const [selectedItems, setSelectedItems] = React.useState<any[]>([]);

  const [value, setValue] = React.useState<Dayjs | null>(
    dayjs("2014-08-18T21:11:54")
  );

  const handleChange = (newValue: Dayjs | null) => {
    setValue(newValue);
  };

  useEffect(() => {
    ASBA_list();
  }, [pageNumber, search_text, toData, fromDate, preData, open]);

  useEffect(() => {
    if (location.state.mode == "edit") {
      setFormikInitialData(location.state);
    }
  }, [location]);

  const ASBA_list = () => {
    // setShowPreloader(true);
    // ServerAPI.ASBAlist(pageNumber, search_text, toData, fromDate).then((res) => {
    //     setASBAlist(res.data);
    //     setTotalCount(res.data.length);
    // }).finally(() => {
    //     setShowPreloader(false);
    // })
  };

  const updateNDCList = () => {
    let updatedList = NDCList;
    setNDCList((prev) => ({
      ...prev,
      header: [...prev, { name: "Serial 2" }],
    }));
  };

  useEffect(() => {
    console.log(NDCList);
  }, [NDCList]);

  const handleClickOpen = () => {};

  const handleClose = (e: any) => {
    setOpen(e);
  };

  const handleClose_modal = (e: any) => {
    setOpenModal(e);
    ASBA_list();
  };

  // const activate_user = (id: any) => {
  //     setOpenModal(true);
  //     setUserId(id);
  // }

  // const view_more_content = (e: any) => {
  //     setViewContent(e)
  //     setOpen(true)
  // }

  const CustomInput = React.forwardRef((props: any, ref: any) => {
    return (
      <div className="datePickerStyle">
        <label onClick={props.onClick} ref={ref}>
          {props.value || props.placeholder}
        </label>
        <img
          src="../../assets/images/calender-icon.svg"
          onClick={props.onClick}
          ref={ref}
        />
      </div>
    );
  });

  const yupSchema = Yup.object().shape({
    asba_bank_code: Yup.string().required("ASBA Bank Code is required"),
    asba_bank_name: Yup.string().required("ASBA Bank Name is required"),
    asba_loc_short_name: Yup.string().required("ASBA Short Name is required"),
    asba_loc_full_name: Yup.string().required("ASBA Full Name is required"),
  });

  const submitData = (data: any, setSubmitting: any) => {
    console.log(data);
    // if (location.state.mode == 'edit') {
    //     setShowPreloader(true);
    //     ServerAPI.putASBAList(data).then((res) => {
    //         toast.success(res.message);
    //         navigate(PageLinks.ASBA_LIST, { state: res.id });
    //         setSubmitting(false);
    //     }).finally(() => {
    //         setShowPreloader(false);
    //     })
    // } else {
    setShowPreloader(true);
    ServerAPI.postASBAList(data)
      .then((res) => {
        toast.success(res.message);
        navigate(PageLinks.ASBA_LIST, { state: res.id });
        setSubmitting(false);
      })
      .finally(() => {
        setShowPreloader(false);
      });
    // }
  };

  return (
    <div>
      <div id="layout-wrapper">
        <Header />
        <div className="main-content py-4">
          <div className="page-content">
            <div className="container-fluid">
              <div className="row">
                <div className="col-12">
                  <div className="page-title-box d-sm-flex align-items-center justify-content-between pt-4 pb-0">
                    <div className="page-title-right">
                      <ol className="breadcrumb ">
                        {/* <li className="breadcrumb-item"><a style={{ color: "#90929E" }}>Trustmony</a></li> */}
                        <li className="breadcrumb-item">
                          <a> ASBA Bank Location </a>
                        </li>
                        <li className="breadcrumb-item active "> Add </li>
                      </ol>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <div className="card">
                    <div className="card-body col-sm-12 col-md-12 col-lg-12 w-100">
                      <Formik
                        validationSchema={yupSchema}
                        enableReinitialize={true}
                        validateOnChange={false}
                        initialValues={formikInitialData}
                        innerRef={formikRef}
                        onSubmit={(values, { setSubmitting }) => {
                          submitData(values, setSubmitting);
                        }}
                      >
                        {(formikProps) => {
                          return (
                            <Form onSubmit={formikProps.handleSubmit}>
                              <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <div className="row">
                                  <div
                                    className="card"
                                    style={{
                                      borderTopLeftRadius: "10px",
                                      borderTopRightRadius: "10px",
                                      paddingTop: "10px",
                                      paddingBottom: "20px",
                                    }}
                                  >
                                    <div className="card-header d-flex justify-content-between">
                                      <h5 className="m-0 header">
                                        {" "}
                                        Add ASBA Bank Location{" "}
                                      </h5>
                                      <div className="form-check form-switch mx-2">
                                        <label
                                          className="form-check-label"
                                          htmlFor="flexSwitchCheckChecked"
                                        >
                                          Disable/Enable
                                        </label>
                                        <input
                                          className="form-check-input "
                                          type="checkbox"
                                          role="switch"
                                          id="flexSwitchCheckChecked"
                                          onChange={(e) => {
                                            setFormikInitialData({
                                              ...formikInitialData,
                                              asba_bank_status:
                                                e.target.checked == true
                                                  ? 1
                                                  : 0,
                                            });
                                          }}
                                          checked={
                                            formikProps.values
                                              ?.asba_bank_status ==
                                            ASBAStatus.Active
                                              ? true
                                              : false
                                          }
                                        />
                                      </div>
                                    </div>
                                    <div className="card-body">
                                      <Grid
                                        direction="row"
                                        container
                                        spacing={2}
                                      >
                                        <Grid item xs={4}>
                                          <div className="d-grid">
                                            <label
                                              htmlFor="Bond Name"
                                              className="bond-label"
                                            >
                                              Bank Code
                                            </label>
                                            <input
                                              id="asba_bank_code"
                                              name="asba_bank_code"
                                              type="text"
                                              className="bond-input"
                                              onChange={(e) =>
                                                setFormikInitialData({
                                                  ...formikInitialData,
                                                  asba_bank_code:
                                                    e.target.value,
                                                })
                                              }
                                              value={
                                                formikProps.values
                                                  .asba_bank_code
                                              }
                                            />
                                            {formikProps.errors
                                              .asba_bank_code ? (
                                              <div style={{ color: "#FF0000" }}>
                                                {
                                                  formikProps.errors
                                                    .asba_bank_code
                                                }
                                              </div>
                                            ) : null}
                                          </div>
                                        </Grid>
                                        <Grid item xs={4}>
                                          <div className="d-grid">
                                            <label
                                              htmlFor="Bond Name"
                                              className="bond-label"
                                            >
                                              Bank Name
                                            </label>
                                            <input
                                              id="asba_bank_name"
                                              name="asba_bank_name"
                                              type="text"
                                              className="bond-input"
                                              onChange={(e) =>
                                                setFormikInitialData({
                                                  ...formikInitialData,
                                                  asba_bank_name:
                                                    e.target.value,
                                                })
                                              }
                                              value={
                                                formikProps.values
                                                  .asba_bank_name
                                              }
                                            />
                                            {formikProps.errors
                                              .asba_bank_name ? (
                                              <div style={{ color: "#FF0000" }}>
                                                {
                                                  formikProps.errors
                                                    .asba_bank_name
                                                }
                                              </div>
                                            ) : null}
                                          </div>
                                        </Grid>
                                        <Grid item xs={4}>
                                          <div className="d-grid">
                                            <label
                                              htmlFor="Bond Name"
                                              className="bond-label"
                                            >
                                              Location Short Name
                                            </label>
                                            <input
                                              id="asba_loc_short_name"
                                              name="asba_loc_short_name"
                                              type="text"
                                              className="bond-input"
                                              onChange={(e) =>
                                                setFormikInitialData({
                                                  ...formikInitialData,
                                                  asba_loc_short_name:
                                                    e.target.value,
                                                })
                                              }
                                              value={
                                                formikProps.values
                                                  .asba_loc_short_name
                                              }
                                            />
                                            {formikProps.errors
                                              .asba_loc_short_name ? (
                                              <div style={{ color: "#FF0000" }}>
                                                {
                                                  formikProps.errors
                                                    .asba_loc_short_name
                                                }
                                              </div>
                                            ) : null}
                                          </div>
                                        </Grid>
                                        <Grid item xs={4}>
                                          <div className="d-grid">
                                            <label
                                              htmlFor="Bond Name"
                                              className="bond-label"
                                            >
                                              Location Full Name
                                            </label>
                                            <input
                                              id="asba_loc_full_name"
                                              name="asba_loc_full_name"
                                              type="text"
                                              className="bond-input"
                                              onChange={(e) =>
                                                setFormikInitialData({
                                                  ...formikInitialData,
                                                  asba_loc_full_name:
                                                    e.target.value,
                                                })
                                              }
                                              value={
                                                formikProps.values
                                                  .asba_loc_full_name
                                              }
                                            />
                                            {formikProps.errors
                                              .asba_loc_full_name ? (
                                              <div style={{ color: "#FF0000" }}>
                                                {
                                                  formikProps.errors
                                                    .asba_loc_full_name
                                                }
                                              </div>
                                            ) : null}
                                          </div>
                                        </Grid>
                                      </Grid>
                                    </div>
                                  </div>
                                  <br />
                                </div>

                                <div className="row text-end my-3 py-5 ">
                                  <div className="">
                                    <a
                                      className="cancel-btn mx-3"
                                      onClick={() => navigate(-1)}
                                    >
                                      Cancel
                                    </a>
                                    <button type="submit" className="save-btn">
                                      Save
                                    </button>
                                  </div>
                                </div>
                              </LocalizationProvider>
                            </Form>
                          );
                        }}
                      </Formik>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
      <SimpleModal
        contents={view_content}
        handleOpen={() => handleClickOpen}
        openModal={open}
        handleClose={(e: any) => handleClose(e)}
      />
      {/* <ConfirmationModal contents="Are you sure you want to activate the user/account?" details_datas={user_id} openModal={open_modal} handleClose={(e: any) => handleClose_modal(e)} currentPage="suspend-user-list" /> */}
    </div>
  );
};

export default ASBABankLocationAdd;
